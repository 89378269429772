import axios from 'axios'

// @Dependencies
// import { PersonAcademicTrainingInput } from '@eclass/api'
import { apiURL } from '../../../../Config/getDomainConfig'
import { axiosError } from '../../../Services'

// const PersonAcademicTraining = (input: PersonAcademicTrainingInput) => {
const PersonAcademicTraining = (input) => {
  const requestId = 'PersonAcademicTraining'
  return async (dispatch: any) => {
    const query = `
    mutation PersonAcademicTraining($PersonAcademicTrainingInput: PersonAcademicTrainingInput!) {
        PersonAcademicTraining(input: $PersonAcademicTrainingInput) {
          academicTraining {
            id
            institutionTypeId
            countryId
            institutionId
            otherInstitution
            studyType
            career
            promotion
            isPrivate
          }
          status {
            success
            name
            detail
            code
          }
        }
      }
    `

    const variables = {
      PersonAcademicTrainingInput: input,
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response) => response.data.data.PersonAcademicTraining)
      .catch((err) => axiosError(err, requestId, dispatch))
  }
}

export default PersonAcademicTraining
