// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { axiosError, getToken } from 'app/Services'
import { ResourceCommentInput } from '@eclass/api'

/**
 * Agrega un nuevo comentario en el recurso.
 *
 * @param {import('@eclass/api').ResourceCommentInput} input - Parámetros para buscar los comentarios.
 * @returns {Promise<import('@eclass/api').ResourceCommentPayload>}
 */
const addResourceComment = (input: ResourceCommentInput, file?: File) => {
  const requestId = 'addResourceComment'
  return async (dispatch: any) => {
    getToken()
    const comment = `
      id
      text
      created
      studentId
      parentId
      draft
      Student {
        id
        personId
        profileId
        Person {
          id
          fullName
          picture
        }
      }
      ResourceCommentAttacheds {
        id
        name
        extension
        path
      }
    `
    const query = `
      mutation ResourceCommentAdd($ResourceCommentInput: ResourceCommentInput!) {
        ResourceComment(input: $ResourceCommentInput) {
          status {
            name
            success
            code
            detail
          }
          comment {
            ${comment}
            Childrens {
              ${comment}
            }
          }
        }
      }
    `

    const variables = {
      ResourceCommentInput: input,
    }
    if (file) {
      input.file = {
        id: 0,
        // @ts-ignore
        type: 'ANSWER',
        name: file.name,
        file,
        size: file.size,
      }
      const form = new FormData()
      const operations = { query, variables }
      form.append('operations', JSON.stringify(operations))
      const map = {
        0: ['variables.ResourceCommentInput.file.file'],
      }
      form.append('map', JSON.stringify(map))
      form.append('0', file)
      const options: any = {
        url: `${apiURL}/graphql`,
        method: 'POST',
        data: form,
        headers: { 'Content-Type': 'multipart/form-data' },
      }
      return axios(options)
        .then((response) => response.data.data.ResourceComment)
        .catch((err: any) => axiosError(err, requestId, dispatch))
    } else {
      return axios
        .post(`${apiURL}/graphql`, { query, variables })
        .then((response) => response.data.data.ResourceComment)
        .catch((err: any) => axiosError(err, requestId, dispatch))
    }
  }
}

export default addResourceComment
