import React, { useRef } from 'react'

import { usePolifyIE } from './usePolifyIE'
import * as Style from './style'
import { ComponentsProps } from './types'

const LINE = 2
const LINE_MOBILE = 1
const BREAKPONT = 768

/**
 * Componente para truncar textos extensos
 * @param {string} text   Texto a truncar
 * @param {number} line  Número de lines visible en dektop {2}
 * @param {number} lineMobile Número de lines visible en mobile {1}
 * @param {number} breakPoint Ancho de viewport en que se pasa a mobile {768}
 * @example <Truncate text="texto extenso" />
 * @example <Truncate text="texto extenso" line={3} lineMobile={2} breakPoint={640} />
 */
export const Truncate = ({
  text,
  line = LINE,
  lineMobile = LINE_MOBILE,
  breakPoint = BREAKPONT,
  role,
  className,
}: ComponentsProps) => {
  const myref = useRef<HTMLParagraphElement>(null)

  const { polify, lineHeight } = usePolifyIE(myref, text)

  if (text === null) return null

  return (
    <Style.Wrapper
      className={`${className} ${!polify ? 'lineClamp' : 'polifyLineClamp'}`}
      ref={myref}
      role={role}
      line={line}
      lineHeight={lineHeight}
      lineMobile={lineMobile}
      breakPoint={breakPoint}
    >
      {text}
    </Style.Wrapper>
  )
}
