import { Input, Label } from 'reactstrap'
import { NewTooltip } from '@eclass/ui-kit'

import { ContentParser } from 'app/Components'
import { Option, Options } from '../../types'
import { EmojiScale } from './EmojiScale'

export const DesktopOptions = ({
  answer,
  changeOption,
  optionDisabled,
  options,
  selectedOption,
}: Options) => {
  return (
    <>
      <div className="Initial__Final__Scale">
        <div className="Initial__Scale">{options[0].tag}</div>
        <div className="Final__Scale">{options[options.length - 1].tag}</div>
      </div>
      <ul className="Options__List">
        {options &&
          options.length > 0 &&
          options.map(({ tag, value }: Option) => (
            <li className="Question__Option" key={`${answer.config?.id!}-option${value}`}>
              <Label check>
                <NewTooltip label={tag} placement="top">
                  <div
                    id={`Tooltip-${answer.config?.id!}-option${value}`}
                    className="Option__Tooltip__Input"
                  >
                    <Input
                      checked={`${selectedOption}` === value}
                      disabled={optionDisabled}
                      id={`${answer.config?.id!}-option${value}`}
                      name={`question-${answer.config?.id!}`}
                      type="radio"
                      onChange={() => changeOption(answer.config?.id, answer.state, value)}
                    />
                  </div>
                </NewTooltip>
                <div className="Option__Label">
                  {ContentParser({
                    content: value,
                    embeds: answer.embeds,
                  })}
                </div>
                <EmojiScale value={value} id={answer?.config?.principalItemId ?? 0} />
              </Label>
            </li>
          ))}
      </ul>
    </>
  )
}
