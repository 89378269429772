import { gql, useLazyQuery, LazyQueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const GET_QUESTION_LIST = gql`
  ${STATUS_FIELDS}
  query getQuestionList($QuestionListInput: QuestionListInput!) {
    getQuestionList(input: $QuestionListInput) {
      status {
        ...StatusFields
      }
      total
      pages
      Filters {
        showFilters
        showAboutCourse
        showAboutContent
        navigation {
          value
          text
          type
        }
        resources {
          value
          text
        }
      }
      Questions {
        details {
          id
          name
          href
          status {
            enabled
            name
            detail
          }
          indicators {
            name
            image
          }
        }
        Student {
          id
          personId
          Person {
            id
            fullName
            picture
          }
          Profile {
            id
            name
          }
        }
        created
      }
    }
  }
`

export type QuestionListPayload = Api.QuestionListPayload

export type QuestionListInput = Api.QuestionListInput

interface QuestionListPayloadApollo {
  getQuestionList: QuestionListPayload
}

interface QuestionListInputApollo {
  QuestionListInput: QuestionListInput
}

export const useLazyQueryGetQuestionList = (
  options?: LazyQueryHookOptions<QuestionListPayloadApollo, QuestionListInputApollo>
) => {
  return useLazyQuery<QuestionListPayloadApollo, QuestionListInputApollo>(
    GET_QUESTION_LIST,
    options
  )
}
