import { useSelector } from 'react-redux'

import { Store } from 'app/Views/types'

/* eslint-disable-next-line no-shadow */
export enum Profiles {
  ADMINISTRATOR = 'ADMINISTRATOR',
  EDICION = 'EDICION',
  REVISOR = 'REVISOR',
  ESTUDIANTE = 'ESTUDIANTE',
}

const ID_STUDENT = 4

/**
 * Devuelvo Perfil del usuario y si es editor (perfiles distinto a estudiante)
 */
export const useTypeProfile = () => {
  const {
    courses: { getCourseInfo: courseInfo },
  } = useSelector((state: Store) => state)

  const profileId = courseInfo?.Student?.profileId
  let profile = Profiles.ESTUDIANTE
  let isEditor = true

  switch (profileId) {
    case 1:
      profile = Profiles.ADMINISTRATOR
      break
    case 14:
      profile = Profiles.EDICION
      break
    case 26:
      profile = Profiles.REVISOR
      break
    default:
      break
  }

  if (profileId === ID_STUDENT) {
    isEditor = false
  }

  return { profile, isEditor }
}
