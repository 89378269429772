import ReactHtmlParser from 'react-html-parser'
import { ResourceContent } from '@eclass/api'

import { Types } from './types'
import * as Utils from './utils'
import { parseLatex } from './parseLatex'
import { parserTimeline } from './Modules/utils/parserTimeline'

const BASE_REGEX = /(___REPLACE_CONTENT_MODULE___.*?___REPLACE_CONTENT_MODULE___)/gm

export interface props {
  content: string
  embeds?: Types.Embeds
  other?: Types.Other
  data?: ResourceContent
  /** Evito cambiar el tipo de nodo por div.question */
  changeTypeNode?: boolean
}

/**
 * Parsea un string a componentes JSX.
 *
 * @param {String} content - Contenido.
 * @param {Types.Embeds} embeds - Embeds, componentes a parsear, las configuraciones vienen de la tabla `ContentModules` del API.
 * @param {Types.Other} other - Otras configuraciones adicionales del parser, como por ejemplo un custom handler para ciertos componentes.
 */
const ContentParser = ({ content, embeds, other, data, changeTypeNode = true }: props) => {
  const newValues = parserTimeline({ content, embeds, data })

  let ExerciseIndex = 0
  const setExerciseIndex = () => {
    ExerciseIndex++
  }

  /** Obtiene los <latex></latex> del contenido y los ordena/parsea. */
  const { content: newContent, latexes } = parseLatex(newValues.content)
  const components = Utils.getComponentsEmbeds(newValues.embeds!, latexes)

  let contentComplete = newContent

  // Esto cubre los casos donde el contenido cargado de las pregunta cloze, viene con problemas de visualización de los inputs
  if (other?.type === 'CLOZE') {
    // esto es por que el ___REPLACE_CLOZE___ no viene dentro de ninguna etiqueta html
    contentComplete = newContent.replace(
      /(___REPLACE_CLOZE___\d+___REPLACE_CLOZE___)/g,
      '<span>$1</span>'
    )
  }

  const parseContent = ReactHtmlParser(contentComplete, {
    decodeEntities: true,
    transform: Utils.removeContentEditable,
  })

  /**
   * Busca en los componentes parseados en react el nodo
   * - Si lo encuentra retorna el componente
   * - Si no lo encuentra retorna el nodo tal cual
   */
  const replaceNodeComponent = (node: any) => {
    const findComponent = components.find((c: any) => c.id === node)
    return findComponent ? findComponent.component : node
  }

  const parseOrder: any = []

  const traverseChildren = ({ recorrer, parent, otherInfo, exerciseIdx, setExerciseIdx }) => {
    recorrer.forEach((child, indexChild) => {
      if (typeof child !== 'object') {
        let miregex = BASE_REGEX

        if (other ? child.search(`REPLACE_${other.type}`) > 0 : false) {
          miregex = new RegExp(
            `(${Utils.REPLACE_REGEX}.*?${Utils.REPLACE_REGEX})|(___REPLACE_${
              other!.type
            }___.*?___REPLACE_${other!.type}___)`,
            'gm'
          )
        }
        const nodeChilds = child.split(miregex)
        nodeChilds.forEach((node: any, kNode: number) => {
          const nodeString = `${node}`
          if (nodeString.search('REPLACE_CONTENT_MODULE') > 0) {
            nodeChilds[kNode] = replaceNodeComponent(node)
          } else if (other && nodeString.search(`REPLACE_${other.type}`) > 0) {
            const renderHandler = other.handleRender(node, ExerciseIndex, other?.evaluations)
            if (renderHandler) {
              setExerciseIndex()
            }
            nodeChilds[kNode] = renderHandler || node
          } else {
            nodeChilds[kNode] = replaceNodeComponent(node)
          }
        })
        parent.props.children[indexChild] = nodeChilds
      } else if (child?.props.children && typeof child?.props.children.forEach === 'function') {
        traverseChildren({
          recorrer: child?.props.children,
          parent: parent.props.children[indexChild],
          otherInfo,
          exerciseIdx,
          setExerciseIdx,
        })
      }
    })
  }

  const traverseAndParse = (
    contentToParse: any,
    otherInfo: any,
    exerciseIdx: any,
    setExerciseIdx: any
  ) => {
    contentToParse.forEach((element: any, i: number) => {
      if (element?.props?.children && Utils.canReplace(element)) {
        traverseChildren({
          recorrer: element.props.children,
          parent: parseContent[i],
          otherInfo,
          exerciseIdx,
          setExerciseIdx,
        })
      } else if (!['style', 'hr'].includes(`${element.type}`)) {
        try {
          const nodeChilds = element.split(BASE_REGEX)
          nodeChilds.forEach((node: any, kNode: number) => {
            nodeChilds[kNode] = replaceNodeComponent(node)
          })
          parseContent[i] = <>{nodeChilds}</>
        } catch (err) {
          /**
           * Este caso se presenta cuando el elemento no es válido, por ejemplo: una carga de una imagen desde el PC,
           * cuya URL sería: "file:///C:/Users/Mario/AppData/Local/Temp/msohtmlclip1/01/clip_image002.jpg".
           *
           * @todo Podríamos implementar un mensaje estándar para los textos o tags que no se puedan renderizar,
           * asi evitamos el loop infinito de la página o falta de información.
           * `parseContent[i] = <>{i18n.t('ResourceErrorToShow')}</>`
           */
        }
      }
      parseOrder.push({ ...parseContent[i], key: i.toString() })
    })
  }

  traverseAndParse(parseContent, other, ExerciseIndex, setExerciseIndex)

  /**
   * Retorna todos los elementos JSX
   */
  return parseOrder.map((parse: any, kContent: number) => {
    /**
     * Busca todos los párrafos y les agrega la clase `question` si se pasó como extra el tipo TEST
     */
    if (other?.type !== 'TEST' && parse.type === 'p' && changeTypeNode) {
      parse.type = 'div'
      parse.props = {
        ...parse.props,
        className: 'question',
      }
    }
    const contentChilds = parse
    return {
      ...contentChilds,
      key: kContent,
    }
  })
}

export default ContentParser
