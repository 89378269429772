export const Microphone = (props) => {
  return (
    <g {...props}>
      <path
        d="M8 10C10.2 10 12 8.2 12 6V4C12 1.8 10.2 0 8 0C5.8 0 4 1.8 4 4V6C4 8.2 5.8 10 8 10Z"
        {...props}
      />
      <path
        d="M15.9 7.10005C16 6.60005 15.6 6.10005 15.1 6.00005C14.6 5.90005 14 6.30005 14 6.80005C13.5 9.80005 11 12 8.00002 12C5.00002 12 2.50002 9.80005 2.10002 6.90005C2.00002 6.30005 1.50002 5.90005 0.900021 6.00005C0.400021 6.10005 2.08467e-05 6.60005 0.100021 7.10005C0.600021 10.7 3.50002 13.4 7.00002 13.9V16H9.00002V13.9C12.5 13.5 15.4 10.7 15.9 7.10005Z"
        {...props}
      />
    </g>
  )
}
