// @External Dependencies
import { Fragment } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { vars } from '@eclass/ui-kit'
import { CloseButton } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { createGlobalStyle } from 'styled-components'

// @Dependencies
import { Icon } from '../Icons'
import { useTranslation } from 'react-i18next'

// @Component
const ModalBasic = ({
  error,
  className = '',
  header,
  subHeader,
  children,
  footer,
  toggle,
  isOpen,
  closeModal,
  iconHeader,
  type,
  fade = true,
  btnClose,
  keyboard,
  backdrop,
}) => {
  const { t } = useTranslation()
  const setBackdrop = () => {
    if (isOpen) {
      switch (type) {
        case 'full':
          return <ModalFull />

        case 'video':
          return <ModalVideo />

        case 'iframe':
          return <ModalIFrame />

        case 'info':
          return <ModalInfo />

        case 'float':
          return <ModalFloat />

        default:
          return <ModalRegular />
      }
    }
  }

  return (
    <Fragment>
      {setBackdrop()}
      <Modal
        isOpen={isOpen}
        fade={fade}
        toggle={toggle}
        className={`${className} modalV8-${type}`}
        keyboard={keyboard}
        backdrop={backdrop}
        trapFocus
        role="dialog"
      >
        <ModalGlobalStyles />
        <section>
          {header && (
            <ModalHeader>
              {iconHeader && <Icon name={iconHeader} fill={vars('colors-neutral-white')} />}
              {header}
              {subHeader && <p className="subHeader">{subHeader}</p>}
            </ModalHeader>
          )}
          <ModalBody>{error || children}</ModalBody>
          {footer && <ModalFooter>{footer}</ModalFooter>}
          {btnClose && (
            <CloseButton
              aria-label={t('CrossClose')}
              bg="transparent"
              border="1px solid transparent"
              className="close-modal"
              color="white"
              height="auto"
              onClick={closeModal}
              padding="0"
              position="absolute"
              right="20px"
              size="lg"
              top="20px"
              width="auto"
              zIndex="99"
              _focus={{
                outline: '2px solid',
              }}
              _hover="none"
            />
          )}
        </section>
      </Modal>
    </Fragment>
  )
}

// @Proptypes
ModalBasic.propTypes = {
  body: PropTypes.object,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  error: PropTypes.bool,
  header: PropTypes.any,
  footer: PropTypes.node,
  subHeader: PropTypes.string,
  toggle: PropTypes.func,
  closeModal: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  iconHeader: PropTypes.string,
  fade: PropTypes.bool,
  btnClose: PropTypes.bool,
  keyboard: PropTypes.bool,
  backdrop: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

ModalBasic.defaultProps = {
  btnClose: true,
  error: false,
  fade: false,
  keyboard: true,
  backdrop: true,
}

// @Export Component
export default ModalBasic

const ModalGlobalStyles = createGlobalStyle`
  .modal{
    border-radius: 5px;

    &.fade {
      transition: none;
    }

    &-dialog{
      max-width: 850px;
      width: 100%;

      &.withoutPadding{
        .modal-body {
          padding: 0;
        }
      }
    }
    &-content{
      border: none;
      box-shadow: none;
      border-radius: 10px;

      .modal-header {
        border-radius: 10px 10px 0 0;
        .modal-title {
          cursor: default;
          display: flex;
          align-items: center;
          text-align: center;
        }
      }

      .modal-body {
        border-radius: 0 0 10px 10px;
      }

      & > section{
        color: #777781;

        &.modal-disabled {
          &:after {
            content: '';
            background: rgba(255, 255, 255, 0.4);
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 10;
          }
        }

        .modal__message--error {
          margin-right: 1rem;
          color: red;
        }
        .subHeader {
          margin-bottom: 0;
        }

        .DraftEditor__component {
          margin-top: 15px;
        }

        & > div{
          padding: 30px;
          position: relative;
        }
      }
    }

    .workModal{
      margin: 0;
      max-width: max-content;
      padding: 0;

      .modal-content{
        border-radius: 0;
        max-width: max-content;
        min-height: 100vh;
        width: 100%;
        &>section{
          width: 100vw;
        }
      }

      .modal-header{
        border-radius: 0;
      }

      @media screen and (min-width: 768px) {
        margin: 1.75rem auto;
        padding: 0 16px;

        .modal-content{
          border-radius: 10px;
          min-height: auto;
          &>section{
            min-width: 720px;
            max-width: calc(100vw - 32px);
            width: 100%;
        }
        }
        .modal-header{
          border-radius: 10px 10px 0 0;
        }
      }
    }

    &-header{
      background: ${vars('colors-icon-deepSkyBlue')};
      border: none;
      border-bottom: 1px solid #dedee8;
      justify-content: center;
      padding: 20px 40px !important;

      h4{
        font-size: 1.7857rem;
      }

      button{
        margin-top: 5px;
        margin-right: 0;
        background-color: #c9c9d3;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        color: white;
        font-size: 20px;
        outline: none;
        opacity: 1;
        transition: all .2s ease;
        :hover{
          color: white;
          opacity: 0.8;
        }
      }

      .modal-title {
        color: white;
        font-size: 20px;
        font-weight: bold;
      }
    }

    &-body{
      color: ${vars('colors-neutral-darkCharcoal')};
      font-size: 14px;
      line-height: 25px;

      p {
        font-size: inherit;
        line-height: inherit;

        &:last-child{
          margin-bottom: 0;
        }
      }
    }

    &-footer{
      align-items: center;
      background-color: #f5f5fb;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-top: none;
      display: flex;
      justify-content: flex-end;
      span{
        margin-right: 1rem;
        &.left{
          position: absolute;
          left: 40px;
          top: 50%;
          transform: translateY(-50%)
        }
      }
      button:last-child{
        margin-right: 0;
      }
    }

    @media screen and (max-width: 990px) {
      .modal-dialog  {
        .close-modal {
          right: 24px;
          top: 22px;
        }
      }
    }

    @media screen and (max-width: 425px) {
      border-radius: 0 0 0 0;

      .modal-dialog{
        margin: 0;
      }
      .modal-title {
        font-size: 18px;
      }
      .modal-header{
        border-radius: 0 0 0 0;
      }
      .modal-body{
        padding: 20px 20px 30px;

      }
      .modal-footer{
        button {
          width: 50%;
          div {
            div {
              .Pressed__content {
                .button__container {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 425px){
    .modal-footer{
        button:first-child {
        width: 60%;
      }
      button:last-child {
        width: 40%;
      }
    }
  }
`

const ModalVideo = createGlobalStyle`
  .modal-backdrop {
    &.show {
      background: ${vars('colors-neutral-darkCharcoal')};
      opacity: 0.32;
    }
  }

  .modal-dialog {
    align-items: center;
    display: flex;

    .close-modal {
      position: fixed;
      right: 55px;
      top: 40px;
    }

    .modal-body {
      padding: 0;
    }

    video {
      display: block;
      width: 100%;
    }
  }
`

const ModalIFrame = createGlobalStyle`
  .modal-content {
    height: 100%;
  }
  .modal {
    border-radius: 0px;

  .modal-dialog {
    height: 100%;
      .modal-content {
        .Svg__Icon {
          opacity: 0.9;
          background-color: gray;
          border-radius: 5px;
          height: 45px;
          overflow: hidden;
          padding: 12px;
          width: 45px;
        }
        section {
          height: 100%;
          .modal-body, > div {
            height: 100%;
            padding: 0px;
            border-radius: 10px;
          }
        }
      }
    }
  }
`

const ModalInfo = createGlobalStyle`
  .modal {
    .modal-content {
      margin: 0 auto;
      width: 690px;
      border-radius: 10px !important;
      box-shadow: 0 5px 10px 0 rgba(199, 199, 199, 0.5);

      .modal-body {
          padding: 0;
          border: 1px solid ${vars('colors-neutral-silverSand')};
          border-radius: 10px 10px 10px 10px;
        .info-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            margin-top: 30px;
            margin-bottom: 15px;
          }
          .description-superior {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 11px;
            text-align: center;
          }
          .description-inferior {
            margin-bottom: 16px;
            font-size: 16px;
            text-align: center;
          }
          .description-final {
            margin-bottom: 16px;
            font-size: 16px;
          }
          .get-it {
            cursor: pointer;
            .firstButton{
              width: 50%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              border-left: 1px solid ${vars('colors-neutral-silverSand')};
            }
            .secondButton{
              border-left: 1px solid ${vars('colors-neutral-silverSand')};
              width: 50%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
            border-top: 1px solid ${vars('colors-neutral-silverSand')};
            color: ${vars('colors-icon-deepSkyBlue')};
            font-weight: bold;
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  .modal-backdrop {
    &.show {
      background: ${vars('colors-neutral-darkCharcoal')};
      opacity: 0.32;
    }
  }

  @media screen and (max-width: 425px) {
    .modal {
      margin-top: 10px;
      .modal-dialog {
        height: 100%;
        .modal-content {
          max-width: fit-content;
          margin: 10px;
        }
      }
    }
  }
`

const ModalFull = createGlobalStyle`
  .modal-backdrop {
    &.show {
      background: ${vars('colors-neutral-darkCharcoal')};
      opacity: 0.32;
    }
  }

  .modal-content {
    box-shadow: -1px 6px 11px 0 rgba(47, 47, 47, 0.09)!important;
    margin: 0 auto;
    width: 690px;
    border-radius: 10px;
  }


  @media screen and (max-width: 575px) {
    .modal-content .modal-header {
      border-radius: 0 !important;
    }
    .modal .modal-header {
      border-radius: 0 !important;
    }
    .modal{
      .modal-dialog {
        margin: 0;
        height: 100%;
        .modal-content {
          height: 100%;
          border-radius: 0 !important;
        }
      }
    }
  }

  .presencialModal {
    .modal-body {
      padding: 0;

     .monthClasses {
      &:not(:last-child) {
        margin-bottom: 60px;
      }

       .modale {
         background: ${vars('colors-neutral-white')};
         padding: 16px 20px;
         border-bottom: solid 1px ${vars('colors-neutral-platinum')};

         &:hover {
          background: ${vars('colors-neutral-cultured2')};
         }
       }
     }


    }

    @media screen and (max-width: 425px) {
          .modal-body {
            padding: 0 !important;
          }
        }

  }
`

const ModalRegular = createGlobalStyle`
  .modal-backdrop {
    &.show {
      background: ${vars('colors-neutral-darkCharcoal')};
      opacity: 0.32;
    }
  }

  .modal-content {
    border-radius: 10px;
    box-shadow: -1px 6px 11px 0 rgba(47, 47, 47, 0.09);
    margin: 0 auto;
    width: 690px;
  }


  @media screen and (max-width: 425px) {
    .modal-dialog {
      height: 100%;
    }
  }
`

const ModalFloat = createGlobalStyle`

  .modalV8-float.modal-dialog {
    margin: 60px auto 0;
    max-width: 590px;
    padding: 16px;

    > .modal-content{
      border-radius: 10px;
    }

    .modal-body{
      padding: 0;

      .modalFloat-content{
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 32px;
        text-align: center;

        & > h4 {
          font-weight: 700;
          font-size: 20px;
          line-height: 23px;
        }

        & > p {
          font-size: 16px;
          line-height: 28px;
        }
      }

      .modalFloat-buttons{
        border-top: ${vars('borders-light')};
        display: flex;
        width: 100%;

        & > button {
          background: transparent;
          border: none;
          color: ${vars('colors-main-deepSkyBlue')};
          font-size: 16px;
          font-weight:500;
          line-height: 21px;
          padding: 16px;
          width: 100%;

          &:not(:last-child){
            border-right: ${vars('borders-light')};
          }
        }
      }
    }

    @media screen and (min-width: 768px) {
      padding: 16px 0;
    }
  }
`
