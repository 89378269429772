import styled from 'styled-components/macro'

import { UIVars } from 'app/Components/UI'
import { vars } from '@eclass/ui-kit'

export const ClassDetails = styled.div`
  &.landscape {
    @media screen and (min-width: 768px) {
      display: flex;
      margin: 0 auto;
      width: 576px;

      > div {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      > .details {
        font-size: 16px
        border-left: 1px solid ${UIVars.setColors.backgroundGrey};
        border-top: 0;

        li {
          align-items: flex-start;
          display: flex;
        }
      }
    }
  }

  &.personalMeeting {
    > .details {
      border-top: none;
    }
  }

  > .details {
    p {
      margin-bottom: 0px;
    }
    margin-bottom: 0;
    padding-left: 0;

    > li {
      margin-left: 0;
      margin-bottom: 16px;
      list-style: none;
      line-height: 1.2;

      &:last-child {
        margin-bottom: 0;
      }

      &:after {
        display: block;
        content: '';
        clear: both;
      }

      > .detailicon {
        float: left;
        width: 26px;
      }

      > .detailinfo {
        margin-left: 1rem;
        float: left;
        width: 80%;
        overflow-wrap: break-word;

        p {
          font-size: 12px;
          text-transform: uppercase;
          font-weight: 500;
          color: ${vars('colors-neutral-spanishGrey')};
        }

        b {
          margin-top: 4px;
          font-size: 14px;
        }

        &.detaildate {
          text-transform: capitalize;
        }

        @media screen and (min-width: 768px) and (max-width: 992px) {
          width: 70%;
        }
      }

      b {
        display: block;
      }

      a {
        color: ${UIVars.setColors.primary};
        font-weight: 500;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
`
