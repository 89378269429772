import { gql, useQuery, QueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

export const TEST_CONTACT = gql`
  ${STATUS_FIELDS}
  query TestContact($TestContactInput: TestContactInput!) {
    TestContact(input: $TestContactInput) {
      status {
        ...StatusFields
      }
      checkAudio
      hasActiveDraft
      config {
        evaluationId
        hasTime
        time
        name
      }
      fields {
        name
        label
        placeholder
        type
        value
        required
        layout
        disabled
        options {
          label
          value
        }
      }
    }
  }
`

// @ts-ignore
export type TestContactPayload = Api.TestContactPayload
// @ts-ignore
export type TestContactInput = Api.TestContactInput

interface TestContactPayloadApollo {
  TestContact: TestContactPayload
}

interface TestContactPayloadInputApollo {
  TestContactInput: TestContactInput
}

export const useQueryTestContact = (
  options?: QueryHookOptions<TestContactPayloadApollo, TestContactPayloadInputApollo>
) => {
  return useQuery<TestContactPayloadApollo, TestContactPayloadInputApollo>(TEST_CONTACT, options)
}
