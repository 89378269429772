import { Box } from '@chakra-ui/react'
import { vars } from '@eclass/ui-kit'
import { ChevronNext, ChevronPrev } from 'app/Components/UI/Icons'

export const SliderCount = ({ sliderReference, slides }) => {
  const deactivateArrowIz = slides.active === 1
  const deactivateArrowDr = slides.active === slides.total

  const prev = () => {
    if (sliderReference) {
      sliderReference.current.slickPrev()
    }
  }

  const next = () => {
    if (sliderReference) {
      sliderReference.current.slickNext()
    }
  }

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      mt="1.5rem"
      mb="1.875rem"
      sx={{
        '.arrow': {
          alignItems: 'center',
          WebkitBoxPack: 'center',
          backgroundColor: 'rgb(1, 137, 255)',
          borderRadius: '50%',
          display: 'flex',
          height: '2.5rem',
          justifyContent: 'center',
          width: '2.5rem',
        },
        '.arrowLeft': {
          filter: deactivateArrowIz ? 'opacity(50%)' : 'none',
          cursor: deactivateArrowIz ? 'not-allowed' : 'pointer',
        },
        '.arrowRight': {
          filter: deactivateArrowDr ? 'opacity(50%)' : 'none',
          cursor: deactivateArrowDr ? 'not-allowed' : 'pointer',
        },
      }}
    >
      <Box backgroundColor="blue" className="arrow arrowLeft" onClick={() => prev()}>
        <ChevronPrev className="prev-icon" color="white" />
      </Box>
      <Box
        backgroundColor={vars('colors-neutral-platinum')}
        borderRadius="50px"
        fontWeight={700}
        ml="1.875rem"
        mr="1.875rem"
        p="0 1rem"
      >
        {slides.active}/{slides.total}
      </Box>

      <Box backgroundColor="blue" className="arrow arrowRight" onClick={() => next()}>
        <ChevronNext className="next-icon" color="white" />
      </Box>
    </Box>
  )
}
