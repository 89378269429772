import { vars } from '@eclass/ui-kit'

export const IconAudio = () => {
  return (
    <svg width={16} height={16} fill="none">
      <path
        fill={vars('colors-main-deepSkyBlue')}
        d="M8 0C3.589 0 0 3.589 0 8v5c0 1.654 1.346 3 3 3h1a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H2V8c0-3.309 2.691-6 6-6s6 2.691 6 6v1h-2a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1c1.654 0 3-1.346 3-3V8c0-4.411-3.589-8-8-8Z"
      />
    </svg>
  )
}
