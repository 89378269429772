// @External Dependencies
import React from 'react'
import { Progress } from 'reactstrap'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

// @Local Dependencies
import UIVars from './variables'

// @Component ProgressVertical
export default function ProgressVertical({ value }) {
  return (
    <Wrapper className="info">
      <div className="box-line">
        <div className="horizontal-line" />
        <div className="horizontal-line" />
        <div className="horizontal-line" />
        <div className="horizontal-line" />
      </div>
      <Progress alt="porcentaje de progreso" value={Number(value)} />
    </Wrapper>
  )
}

// @Styles component
const Wrapper = styled.div`
  margin-top: -43px;
  .box-line {
    position: relative;
    top: 44px;
    right: -40%;
    z-index: 2;
  }
  .horizontal-line {
    height: 2px;
    width: 60px;
    background-color: white;
    margin: 7px 0;
  }
  > .progress {
    background-color: ${UIVars.setColors.backgroundGrey};
    transform: rotate(-100grad);
    width: 43px;
    height: 43px;
    border-radius: 0;
    margin: auto;
    z-index: -1;
  }

  @media (max-width: 991px) {
    .box-line {
      right: -33%;
    }
  }

  @media (max-width: 767px) {
    .box-line {
      right: -42%;
    }
  }

  @media (max-width: 340px) {
    .box-line {
      right: -41%;
    }
  }
`

// @Proptypes
ProgressVertical.propTypes = {
  value: PropTypes.string.isRequired,
}

// @DefaultProps
ProgressVertical.defaultProps = {
  value: '0',
}
