/**
 * Actualizo la pregunta seleccionada en Levels y Answers
 * @param id {number}
 * @param levelId {number}
 * @param Evaluation {any}
 * @param setAnswer {function} tratamiento de los datos de la pregunta
 */
export const updateAnswer = ({ id, levelId, Evaluation, setAnswer }: any) => {
  // Actualizo datos de pregunta seleccionada en Levels,
  // recorriendo level seleccionado y sus stages
  const Stages = Evaluation!.Levels[levelId].Stages || []
  Evaluation!.Levels[levelId].Stages = Stages.map((stage: any) => {
    stage.Answers = forEachAnswer(stage.Answers)
    return stage
  })

  // Actualizo datos de pregunta seleccionada en Answers
  // @ts-ignore
  Evaluation!.Answers = forEachAnswer(Evaluation!.Answers)

  /**
   * Recorro preguntas y actualizo datos de la seleccionada con el metodo setAnswer
   * @param currentAnswer {any[]}
   */
  function forEachAnswer(list: any[]) {
    return list?.map((answer: any) => {
      if (answer?.config.id === id) {
        return setAnswer(answer)
      } else {
        return answer
      }
    })
  }

  return Evaluation
}
