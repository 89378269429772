import styled from 'styled-components/macro'

import { UIVars } from 'app/Components/UI'

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  font-family: 'Roboto';
  line-height: 17px;
  padding-left: 27px;
  position: relative;

  &.checked {
    color: ${UIVars.setColors.primary};
    font-weight: bold !important;
  }

  label {
    cursor: pointer;
    margin-bottom: 0;
  }

  input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
  }
  a {
    text-decoration: underline !important;
    color: ${UIVars.setColors.primary} !important;
  }
  .checkmark {
    background-color: ${UIVars.setColors.backgroundGreyThree};
    border-radius: 5px;
    height: 17px;
    left: 0;
    position: absolute;
    top: 0;
    width: 17px;
  }

  .checkmark:after {
    border: solid white;
    border-width: 0 1px 1px 0;
    height: 8px;
    left: 6px;
    top: 4px;
    transform: rotate(45deg);
    width: 5px;
  }

  input:checked ~ .checkmark {
    background-color: ${UIVars.setColors.primary};
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  p {
    font-size: 12px;
    margin-bottom: 0;
  }

  .checkmark:after {
    content: '';
    display: none;
    position: absolute;
  }
`
