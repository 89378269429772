import { testsStates } from '../testsStates'
import { stateQuestion } from './stateQuestion'

/**
 * Valido que no me encuentro en el feedback ni que la pregunta esta omitida.
 *
 * Se utiliza para no setear el valor de la pregunta, dejandola sin respuesta.
 *
 * Esto aplica para la vista del feedback
 * @param test {number} id del estado de la evaluación
 * @param question {number} id del estado de la pregunta
 */
export const valueEmpty = (test: number, question: number) => {
  return !(test === testsStates.EVALUATED && question === stateQuestion.OMITTED)
}
