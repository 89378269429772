import styled from 'styled-components/macro'

import { UIVars } from 'app/Components/UI'

type Props = {
  edit: boolean
  isDraft?: boolean
  words: number
}
export const Section = styled.section<Props>`
  background: ${UIVars.setColors.whiteThree};
  border: ${(props) =>
    props.edit || props.isDraft || props.words > 0 ? UIVars.borderEdit : UIVars.borderSolid};
  border-radius: 5px;

  > header {
    border-bottom: ${UIVars.borderSolid};
    padding: 8px 0;
  }
  div {
    section:not(.TextEditor) {
      article {
        margin-right: 10px;
      }
    }
  }
`

export const Editor = styled.div`
  padding: 16px 16px 0px;
  .rdw-editor-wrapper {
    border: none;
    border-radius: 0;
    .rdw-editor-toolbar {
      border: none;
      margin-left: -6px;
      padding-left: 0;
      padding-top: 0;
    }
    .DraftEditor-editorContainer {
      min-height: 0;
      padding: 0;
    }
    .public-DraftEditorPlaceholder-root {
      margin-left: 0;
      min-height: 0;
      padding: 0;
    }
    .public-DraftStyleDefault-ol,
    .public-DraftStyleDefault-ul,
    .public-DraftStyleDefault-block {
      line-height: 28px;
      margin: 0;
    }
    .public-DraftStyleDefault-ltr {
      a {
        color: ${UIVars.setColors.primary};
        cursor: pointer;
      }
    }
    .rdw-image-modal,
    .rdw-emoji-modal,
    .rdw-link-modal {
      border-radius: 5px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.46);
      padding: 30px;
    }
    .rdw-emoji-modal {
      padding: 15px;
    }
    .rdw-image-modal-upload-option {
      margin: 0;
      border: 1px dashed ${UIVars.setColors.veryLightPink};
      outline: none;
      outline-offset: 0;
      padding: 0;
    }
    .rdw-image-modal-upload-option-label {
      margin-bottom: 0;
      max-height: 200px;
      min-height: 94px;
      padding: 8px;
      img {
        object-fit: contain;
      }
    }
    .rdw-image-modal-spinner {
      padding-bottom: 5px;
    }
    .rdw-image-modal-btn-section,
    .rdw-link-modal-buttonsection {
      margin: 15px 0 0;
      button {
        border-radius: 4px;
        box-shadow: none;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        margin: 0;
        border-width: 0;
      }
      button:first-child {
        background: ${UIVars.setColors.primary};
        color: ${UIVars.setColors.whiteThree};
        margin-right: 25px;
        :disabled {
          background: ${UIVars.setColors.veryLightPink};
        }
      }
      button:last-child {
        background: ${UIVars.setColors.info};
        color: ${UIVars.setColors.primary};
      }
    }
    .rdw-link-modal {
      height: initial;
    }
    .rdw-link-modal-label {
      color: ${UIVars.setColors.blackThree};
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
      margin-bottom: 10px;
    }
    .rdw-link-modal-input {
      border: 1px solid ${UIVars.setColors.veryLightPink};
      border-radius: 5px;
      box-sizing: border-box;
      margin: 0 0 24px;
      padding: 1.1em 0.5em;
      &#linkTarget {
        margin-bottom: 0;
      }
    }
    .rdw-link-decorator-wrapper {
      img.rdw-link-decorator-icon {
        color: ${UIVars.setColors.primary};
        background: transparent !important;
        left: 0 !important;
        right: inherit !important;
        width: 100% !important;
        opacity: 0;
        padding: 0 !important;
      }
    }

    figure .rdw-image-center {
      justify-content: flex-start;
    }

    .rdw-image-imagewrapper img {
      height: 100%;
      max-height: 500px;
      max-width: 500px;
      object-fit: contain;
      object-position: left;
      width: 100%;
    }
  }
`

export const Footer = styled.footer`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 29px 24px 16px;

  &.notMinCharacters {
    justify-content: flex-end;
  }

  .editorActions-buttons {
    align-items: center;
    display: flex;
    gap: 26px;
    > button {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 560px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 24px;

    .editorActions-buttons {
      flex-direction: column-reverse;
      gap: 24px;
      width: 100%;
      > div,
      > div div,
      > div button {
        width: 100%;
      }
      > button {
        .button__container {
          padding: 0;
        }
      }
    }
  }
`
