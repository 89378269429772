export const InformationWelcome = (props) => {
  return (
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)">
        <circle cx={13} cy={13} r={13} {...props} />
        <path
          fill="#FFF"
          d="M10 10.75h4.314v7.07h1.336v2.541H10V17.82h1.447v-4.527H10V10.75zm1.299-3.099c0-.228.042-.443.125-.644a1.602 1.602 0 0 1 .872-.877c.201-.087.42-.13.654-.13.223 0 .433.043.631.13a1.664 1.664 0 0 1 1.002 1.521 1.664 1.664 0 0 1-1.002 1.521c-.198.087-.408.13-.63.13a1.635 1.635 0 0 1-1.179-.482 1.602 1.602 0 0 1-.348-.524 1.664 1.664 0 0 1-.125-.645z"
        />
      </g>
      <path
        {...props}
        fillRule="nonzero"
        d="M14 28C6.268 28 0 21.732 0 14S6.268 0 14 0s14 6.268 14 14-6.268 14-14 14zm0-2c6.627 0 12-5.373 12-12S20.627 2 14 2 2 7.373 2 14s5.373 12 12 12z"
      />
    </g>
  )
}
