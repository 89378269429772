import styled from 'styled-components/macro'

import { UIVars } from 'app/Components/UI'
import { calendarDate } from 'assets/svg'

export const Wrapper = styled.div`
  .react-datepicker__navigation {
    top: 10px;
  }

  input {
    background-image: url(${calendarDate});
    background-position: right 20px bottom 51%;
    background-repeat: no-repeat;
    border-radius: 5px;
    border: 1px solid ${UIVars.setColors.default};
    color: ${UIVars.setColors.black};
    font-size: 16px;
    font-style: normal;
    height: 48px;
    padding: 0.7143em 1.0714em;
    width: 100%;

    &:disabled {
      background: ${UIVars.setColors.whiteDisabled};
      border-color: ${UIVars.setColors.backgroundGrey};

      &::placeholder {
        color: ${UIVars.setColors.brownGreyTwo};
      }
    }
  }

  &.status-error {
    input {
      border: 1px ${UIVars.setColors.checkError} solid;
    }
  }
`
