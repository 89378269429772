// @External Dependencies
import React, { useState, useEffect } from 'react'

import { IconProps } from './types'
import { Icon } from 'app/Components/UI/Icons'
import { IconNames } from '../Icons/Icon/types'

const maxPercentage = 100
const minPercentage = 0

// @Component
export const IconCircularProgressBar = (props: IconProps) => {
  const { progress, visible, sizeIcon, color, name } = props
  const [iconName, setIconName] = useState<IconNames>('')
  const [viewBox, setvVewBox] = useState(sizeIcon)

  useEffect(() => {
    if (progress >= maxPercentage) {
      setIconName('done')
    } else if (progress > minPercentage) {
      setIconName('in-progress')
      const fixCenterSVG = 2
      setvVewBox(sizeIcon - fixCenterSVG)
    } else if (name?.includes('blocked')) {
      setIconName('blocked')
    } else {
      setIconName('opened')
    }
  }, [progress, sizeIcon])

  if (!visible) return null

  return (
    <Icon
      name={iconName}
      className="svg-icons"
      height={sizeIcon}
      width={sizeIcon}
      viewBox={viewBox}
      fill={color}
    />
  )
}
