import { useSelector } from 'react-redux'
import jwtDecode from 'jwt-decode'

import * as Types from 'app/Redux/types'
import { LoginToken } from 'app/Services/Auth/types'

/**
 * Valida si estoy suplantando
 */
export const useImpersonate = () => {
  const {
    loggedUser: { token },
  } = useSelector((state: Types.Store) => state)

  let impersonate = false

  if (token) {
    const tokenDecoded: LoginToken = jwtDecode(token)

    if (tokenDecoded?.readOnly) {
      impersonate = true
    }
  }

  return {
    impersonate,
  }
}
