import { useEffect, Dispatch, SetStateAction } from 'react'
import { Box } from '@chakra-ui/react'
import { BtnSecondary } from '@eclass/ui-kit'
import { useTranslation } from 'react-i18next'

import { Icon } from 'app/Components/UI/Icons'

export const CommentButton = ({
  isMobile = false,
  onOpen,
  page,
  setPage,
  total,
}: {
  isMobile?: boolean
  onOpen: () => void
  page?: number
  setPage?: Dispatch<SetStateAction<number>>
  total: number
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (page && page > 0 && setPage) {
      setPage(0)
    }
  }, [])

  return (
    <Box
      sx={{
        button: {
          fontSize: '14px',
        },
        svg: {
          mr: 0,
        },
      }}
    >
      <BtnSecondary onClick={onOpen} leftIcon={<Icon name="comment2" />} size="small">
        {total > 0 ? <>{total} </> : <></>}
        {!isMobile && t('ResourceComments')}
      </BtnSecondary>
    </Box>
  )
}
