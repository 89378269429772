import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  .Header {
    padding-bottom: 36px;
    display: inline-flex;
    .UploadHeader {
      display: flex;
      align-items: flex-start;
      div {
        .RoundedImage {
          margin: auto;
        }
      }
      h5 {
        font: bold 16px / 19px 'Roboto' !important;
        color: var(--black);
      }
      p {
        padding-top: 8px;
        font: 12px 'Roboto' !important;
        color: var(--black);
        width: 64%;
      }
    }
  }
`

export const FileZone = styled.div`
  margin-left: 30px;
  display: block;

  button {
    font-weight: 600;
    input {
      opacity: 0;
      position: absolute;
    }
  }
`
