import React, { MouseEvent } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

type Props = {
  children: any
  to: string
  delay?: number
  history?: any
  external?: boolean
  className?: string
  disabled?: boolean
  role?: string
  id?: string
}
const DelayLink = ({ children, to, delay, external, className, disabled, role, id }: Props) => {
  const history = useHistory()

  const handleClick = (e: MouseEvent) => {
    if (disabled) {
      e.preventDefault()
    } else {
      const newDelay = delay || (external ? 1000 : 50)
      e.preventDefault()
      setTimeout(() => {
        if (external) {
          window.open(to, '_blank')
        } else {
          history.push(to)
        }
      }, newDelay)
    }
  }

  if (external) {
    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
        className={className}
      >
        {children}
      </a>
    )
  }

  return (
    <Link id={id} to={to} onClick={handleClick} className={className} role={role}>
      {children}
    </Link>
  )
}

export default styled(DelayLink)`
  display: block;
  height: 100%;
`
