import React from 'react'
import { UIVars } from 'app/Components/UI'
import { Icon } from 'app/Components/UI/Icons'
import { SlideFlashProps } from './helpers/types'
import getIcon from './helpers/getIcon'
import styled from 'styled-components/macro'

export const SideFlash = ({
  children = 'Texto para mensaje flash. Lorem ipsum dolor sit amet',
  onClose,
  type = 'success',
  margin = 140,
  maxWidth = true,
  withShadow = true,
  toasted,
}: SlideFlashProps) => {
  return (
    <Wrapper
      margin={margin}
      maxWidth={maxWidth}
      withShadow={withShadow}
      toasted={toasted}
      className="alertContainer"
    >
      <div id="alert" className={`alert ${type}`}>
        <img className="image" src={getIcon(type)} alt={type} />
        <div className="message">{children}</div>
        {onClose ? <Icon onClick={onClose} className="close" name="close" fill="#566D82" /> : null}
      </div>
    </Wrapper>
  )
}
type WrapperProp = {
  margin: number
  maxWidth?: boolean
  withShadow?: boolean
  toasted?: boolean
}

const Wrapper = styled.div<WrapperProp>`
  ${({ margin }: WrapperProp) => ({
    marginTop: margin,
  })};

  .alert {
    align-items: center;
    border-radius: 10px;
    box-shadow: ${(props) => (props.withShadow ? '2px 2px 7px -1px rgba(0, 0, 0, 0.35)' : '')};
    display: flex;
    max-width: ${(props) => (props.maxWidth ? '540px' : '')};
    padding: 10px 10px 10px 20px;

    .image {
      height: 48px;
      margin-right: 15px;
      align-self: flex-start;
    }
    .message {
      flex-grow: 2;
      font-size: 1rem;
      line-height: 28px;
      margin-right: 30px;
      text-align: left;
      span {
        span {
          display: block;
          text-align: left !important;
        }
      }
    }
    .close {
      align-self: start;
      cursor: pointer;
      color: #566d82;
      opacity: 1;
      margin: 0;
    }
    &.success {
      background-color: ${UIVars.setColors.success};
    }
    &.info {
      background-color: ${UIVars.setColors.info};
    }
    &.warning {
      background-color: ${UIVars.setColors.warning};
      margin-right: 15px;
    }
    &.error {
      background-color: ${UIVars.setColors.danger};
    }
  }

  @media (max-width: 425px) {
    margin-top: ${(props) => (props.toasted ? '0px' : '')};
    line-height: ${(props) => (props.toasted ? '18px' : '')};
    width: ${(props) => (props.toasted ? '100vw' : '')};
    .alert {
      border-radius: ${(props) => (props.toasted ? '0px' : '')};
      &.warning {
        margin-right: 5px;
      }
    }
  }

  @media (max-width: 375px) {
    margin-top: 0px;
    .alert {
      border-radius: ${(props) => (props.toasted ? '0' : '10px')};
      box-shadow: ${(props) => (props.withShadow ? '2px 2px 7px -1px rgba(0, 0, 0, 0.35)' : '')};
      max-width: 540px;
      .message {
        text-align: left;
        margin-right: 0;
      }
      &.warning {
        margin-right: 0px;
      }
    }
  }
`
