import styled from 'styled-components/macro'
import { CssProps } from './types'

export const Wrapper = styled.p<CssProps>`
  overflow: hidden;
  &.lineClamp {
    display: -webkit-box;
    -webkit-line-clamp: ${({ line }: CssProps) => line};
    -webkit-box-orient: vertical;
  }
  &.polifyLineClamp {
    height: ${({ lineHeight, line }: CssProps) => lineHeight * line}px;
  }

  @media screen and (max-width: ${({ breakPoint }: CssProps) => breakPoint}px) {
    &.lineClamp {
      -webkit-line-clamp: ${({ lineMobile }: CssProps) => lineMobile};
    }
    &.polifyLineClamp {
      height: ${({ lineHeight, lineMobile }: CssProps) => lineHeight * lineMobile}px;
    }
  }
`
