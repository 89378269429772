// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { axiosError, getToken } from '../../../Services'

/**
 * @typedef {Object} FilterType
 * @property {string} search- Filtra por nombre de Curso o Diploma
 * @property {number} [year] - Filtra por Año los cursos
 * @property {number} campusId - Campus de los Cursos
 * @property {number} programId - Programa de los Cursos
 * @property {number} page - Limita los cursos por página
 */
/**
 * @typedef {Object} InputType
 * @property {number} [scheme] - Esquema de los Cursos (1: Cursos PlatBe, 2: Cursos v8)
 * @property {string} type - PAST | PRESENT | FUTURE | PRESENT_FUTURE
 * @property {Object} filters - Filtros de los cursos
 */
/**
 * Lista los cursos de una persona según los filtros
 *
 * Ocupamos esta query para Dashboard de Cursos y Registro Académico
 *
 * @param {*} input
 * @example
 * ```js
 *    const input = {
 *      type: 'ALL'
 *    }
 *    const students = await this.props.getCourseList(input)
 * ```
 * @returns
 */
const getCourseList = (input) => {
  const requestId = 'getCourseList'
  return async (dispatch) => {
    getToken()
    const query = `
      query getCourseList($CourseListInput: CourseListInput!) {
        getCourseList(input: $CourseListInput) {
          pages
          years
          total
          status {
            name
            code
            success
            detail
          }
          AcademicList {
            hasScorm
            hasDjPending
            id
            isMooc
            studentId
            courseId
            diplomaId
            productTypeId
            name
            showAverages
            CertificatesInfo{
              modalDescription
              modalTitle
            }
            information {
              href
              icon
              text
            }
            progress {
              finalGrade
              isApproved
            }
            status {
              id
              name
            }
            dates {
              start
              end
              deactivation
              completed
              days
              provisionalDateEnd
            }
            Diploma {
              id
              name
            }
            certificates {
              id
              name
              href
              isCustomized
            }
            Documents {
              id
              name
              href
            }
            Performance {
              weighing
              approved
              average {
                value
                valueFormatted
                status {
                  id
                  enabled
                  name
                  detail
                  style
                  date
                }
              }
              CalificationGroups {
                id
                name
                totalTest
                totalCompleted
                weighing
                StudentModality {
                  restrictive
                  criteria
                  qualification
                  weighing
                }
                average {
                  value
                  valueFormatted
                  status {
                    id
                    enabled
                    name
                    detail
                    style
                    date
                  }
                }
              }
            }
            courses {
              id
              courseId
              name
              dates {
                start
                end
                deactivation
                completed
                days
              }
              progress {
                finalGrade
                isApproved
              }
              status {
                id
                name
              }
              certificates {
                id
                name
                href
                isCustomized
              }
              Performance {
                weighing
                approved
                average {
                  value
                  valueFormatted
                  status {
                    id
                    enabled
                    name
                    detail
                    style
                    date
                  }
                }
                CalificationGroups {
                  id
                  name
                  totalTest
                  totalCompleted
                  weighing
                  StudentModality {
                    restrictive
                    criteria
                    qualification
                    weighing
                  }
                  average {
                    value
                    valueFormatted
                    status {
                      id
                      enabled
                      name
                      detail
                      style
                      date
                    }
                  }
                }
              }
            }
          }
        }
      }
    `

    const variables = {
      CourseListInput: {
        type: 'ALL',
        filter: {
          ...input,
          /**
           * Lógica de Campus y Programa se omite
           * hasta tener definido el Branding y Agrupación de Cursos
           */
          programId: 0,
          limit: 12,
        },
      },
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then((response) => {
        if (response.data.data.getCourseList?.status?.success) {
          dispatch({
            type: 'SET_PROGRAMS',
            payload: response.data.data.getCourseList.Programs,
          })
        }
        return response.data.data.getCourseList
      })
      .catch((err) => axiosError(err, requestId, dispatch))
  }
}

export default getCourseList
