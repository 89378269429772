/**
 * Setea la mediaquery para ver si es responsive o no la visualización
 * @param {boolean} keepTimeZone
 */
const setKeepTimeZone = (keepTimeZone) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_KEEPTIMEZONE',
      payload: keepTimeZone,
    })
  }
}

export default setKeepTimeZone
