import { useRef, useEffect, useState } from 'react'

/**
 * SetState que valida que tenga referencia a la hora de setear nuevo valor
 * @example [state, setState] = useSafeState<boolean>(false)
 */
export const useSafeState = <T = undefined>(initialState: T) => {
  const [state, setState] = useState<T>(initialState)
  const mountedRef = useRef(false)

  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  const safeSetState = (args: T) => {
    if (mountedRef.current) {
      setState(args)
    }
  }

  return [state, safeSetState] as const
}
