import React from 'react'

export const EmptyCourseListIcon = () => (
  <svg
    width="197"
    height="197"
    viewBox="0 0 197 197"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M98.5 197C152.9 197 197 152.9 197 98.5C197 44.1 152.9 0 98.5 0C44.1 0 0 44.1 0 98.5C0 152.9 44.1 197 98.5 197Z"
      fill="#F4F4F4"
    />
    <rect x="45" y="140" width="46" height="6" rx="3" fill="#0189FF" />
    <rect x="105" y="140" width="49" height="6" rx="3" fill="#0189FF" />
    <circle cx="38.5" cy="142.5" r="7.5" stroke="#0189FF" strokeWidth="6" />
    <circle cx="97.5" cy="142.5" r="7.5" stroke="#0189FF" strokeWidth="6" />
    <circle cx="158.5" cy="142.5" r="7.5" stroke="#0189FF" strokeWidth="6" />
    <rect x="6" y="54" width="52" height="63" rx="7" stroke="#1EBDAF" strokeWidth="6" />
    <path
      d="M3 61C3 55.4772 7.47715 51 13 51H51C56.5229 51 61 55.4772 61 61V95H3V61Z"
      fill="#1EBDAF"
    />
    <rect x="8" y="67" width="6" height="6" rx="3" fill="#08044F" />
    <rect x="18" y="67" width="33" height="6" rx="3" fill="#08044F" />
    <rect x="72" y="54" width="52" height="63" rx="7" stroke="#FF554D" strokeWidth="6" />
    <path
      d="M69 61C69 55.4772 73.4772 51 79 51H117C122.523 51 127 55.4772 127 61V95H69V61Z"
      fill="#FF554D"
    />
    <rect x="100" y="67" width="6" height="6" rx="3" fill="#08044F" />
    <rect x="75" y="67" width="21" height="6" rx="3" fill="#08044F" />
    <rect x="75" y="77" width="42" height="6" rx="3" fill="#08044F" />
    <rect x="138" y="54" width="52" height="63" rx="7" stroke="#FFBC27" strokeWidth="6" />
    <path
      d="M135 61C135 55.4772 139.477 51 145 51H183C188.523 51 193 55.4772 193 61V95H135V61Z"
      fill="#FFBC27"
    />
    <rect x="141" y="67" width="32" height="6" rx="3" fill="#08044F" />
  </svg>
)
