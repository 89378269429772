import crypto from 'crypto'
// import v4 from 'aws-signature-v4'
const v4 = require('./aws-signature-v4')

const key = 'AKIAYW4TD2RRQEIGNSJH'
const secret = 'EOsCqGlLNhtzOsOxV8bStyvBjUzeg+KuejPJnGQI'

export const createPresignedUrl = (amazon, sampleRate) => {
  const endpoint = 'transcribestreaming.' + amazon.region + '.amazonaws.com:8443'

  // get a preauthenticated URL that we can use to establish our WebSocket
  return v4.createPresignedURL(
    'GET',
    endpoint,
    '/stream-transcription-websocket',
    'transcribe',
    crypto.createHash('sha256').update('', 'utf8').digest('hex'),
    {
      key: key,
      secret: secret,
      sessionToken: '',
      protocol: 'wss',
      expires: 15,
      region: amazon.region,
      query:
        'language-code=' +
        amazon.languageCode +
        '&enable-partial-results-stabilization=true&partial-results-stability=low&media-encoding=pcm&sample-rate=' +
        sampleRate,
    }
  )
}
