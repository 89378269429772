import styled from 'styled-components/macro'

import { UIVars } from 'app/Components/UI'

export const GlobalWrapper = styled.div`
  &.error {
    .react-tel-input {
      .form-control,
      .flag-dropdown {
        border: solid 1px ${UIVars.setColors.checkError};
      }
    }
  }
`

export const Wrapper = styled.div`
  .react-tel-input {
    .form-control {
      color: ${UIVars.setColors.black};
    }
  }

  div[class$='feedback'] {
    margin-top: 4px;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  .invalid_feedback {
    color: ${UIVars.setColors.red};
  }
  .valid_feedback {
    color: ${UIVars.setColors.jadeGreen};
  }
`
