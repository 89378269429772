/**
 * Función para convertir la respuesta seleccionada en un array
 */
export const getArraySelected = (answer: string) => {
  type TArray = {
    configId: number
    response: string | number
  }
  const selected: TArray[] | TArray =
    answer && answer.includes('configId') ? JSON.parse(answer) : {}

  let arraySelected: TArray[] = []
  if (Array.isArray(selected)) {
    arraySelected = selected
  } else if (typeof selected === 'object' && !Array.isArray(selected)) {
    if (selected.configId >= 0) {
      arraySelected = [selected]
    }
  }

  return arraySelected
}
