import React, { useEffect, useState } from 'react'
import QRCode from 'qrcode.react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Md5 } from 'md5-typescript'
import * as Sentry from '@sentry/react'

import { UIVars } from 'app/Components/UI'
import { media, audio, video, file } from 'assets/svg'
import { Icon } from 'app/Components/UI/Icons'
import { Store } from 'app/Redux/types'
import { IDModules } from '../'
import { AlternativeContentProps } from '../types'

export const Footer = ({ config, typeModule }: AlternativeContentProps.Base) => {
  const { t } = useTranslation()
  const {
    courses: {
      getCourseInfo: { Course },
    },
  } = useSelector((state: Store) => state)

  const [src, setSrc] = useState('')
  const configType = {
    image: media,
    name: 'defecto',
    id: 0,
  }

  // El id es para poder cambiar el contexto de la traduccion.
  switch (typeModule) {
    case IDModules.AUDIO:
      configType.image = audio
      configType.name = t('PDFAudio')
      configType.id = 1
      break
    case IDModules.DESCARGAR_ARCHIVO:
      configType.image = file
      configType.name = t('PDFFile')
      configType.id = 2
      break
    case IDModules.IFRAME:
    case IDModules.IFRAME_MODAL:
      configType.image = media
      configType.name = t('PDFInteractive')
      configType.id = 3
      break
    case IDModules.VIDEO:
      configType.image = video
      configType.name = t('PDFVideo')
      configType.id = 4
      break
    default:
      break
  }

  useEffect(() => {
    // obtengo canvas del embed correspondiente
    const canvas = document.querySelector<HTMLCanvasElement>(`#qr${config.idEmbed} canvas`)
    if (canvas) {
      setSrc(canvas.toDataURL())
    }
  }, [config.idEmbed])

  if (typeModule === IDModules.IMAGENES) {
    return null
  }

  let urlPublic = ''

  // cuando es el contenido alternativo del header
  if (config.source?.url!) {
    urlPublic = config.source?.url
  } else {
    try {
      urlPublic = [
        document.location.origin,
        'qr',
        Course?.studentId,
        Course?.id,
        Md5.init(config.idEmbed),
      ].join('/')
    } catch (err) {
      // `Existe un problema de configuración en el recurso.`
      const error = new Error(`Error de configuración en el recurso: "${config.title}".`)
      Sentry.captureException(error, {
        extra: {
          config,
        },
      })
      throw error
    }
  }

  return (
    <div className="AlternativeContentPDF__footer" data-testid="AlternativeContent-Footer">
      <div className="text">
        <img
          src={configType.image}
          alt={`Icon ${configType.name}`}
          data-testid="AlternativeContent-icon"
        />
        <p>
          {t('PDFAlternativeContent', {
            context: `${configType.id}`,
            name: configType.name,
          })}
          <a href={urlPublic} target="_blank" rel="noreferrer noopener">
            {t('PDFOnlineVersion')}
            <Icon name="arrow-top-right" height={10} fill={UIVars.setColors.primary} />
          </a>
          {t('PDFQR')}
        </p>
      </div>
      <div className="qr" id={`qr${config.idEmbed}`}>
        <img src={src} alt="qrcode" data-testid="imgQR" />
        <QRCode value={urlPublic} />
      </div>
    </div>
  )
}
