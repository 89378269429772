export const LiveClassCheck = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" data-testid="LiveClassCheck">
      <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
      <path
        d="M37 27H34C33.4477 27 33 27.4477 33 28V29C33 29.5523 33.4477 30 34 30H37C37.5523 30 38 29.5523 38 29V28C38 27.4477 37.5523 27 37 27Z"
        fill="#FFBC27"
      />
      <path
        d="M44 22H34C33.4477 22 33 22.4477 33 23V24C33 24.5523 33.4477 25 34 25H44C44.5523 25 45 24.5523 45 24V23C45 22.4477 44.5523 22 44 22Z"
        fill="#1EBDAF"
      />
      <path
        d="M32.4271 17.2689C32.7677 17.6442 33.3703 17.6048 33.5701 17.1391C33.8503 16.4857 34 15.7516 34 15C34 14.2484 33.8503 13.5143 33.5701 12.8609C33.3703 12.3952 32.7677 12.3558 32.4271 12.7311C32.1775 13.006 32.1628 13.4148 32.304 13.7582C32.4609 14.1396 32.5445 14.5649 32.5445 15C32.5445 15.4351 32.4609 15.8604 32.304 16.2418C32.1628 16.5852 32.1775 16.994 32.4271 17.2689Z"
        fill="#0189FF"
      />
      <path
        d="M34.5191 19.3125C34.8588 19.6805 35.4461 19.676 35.7254 19.2603C36.5485 18.0354 37 16.5431 37 15C37 13.4569 36.5485 11.9646 35.7254 10.7397C35.4461 10.324 34.8588 10.3195 34.5191 10.6875C34.2244 11.0069 34.226 11.4943 34.464 11.8579C35.059 12.7664 35.3847 13.8649 35.3847 15C35.3847 16.1351 35.059 17.2336 34.464 18.1421C34.226 18.5057 34.2244 18.9931 34.5191 19.3125Z"
        fill="#0189FF"
      />
      <path
        d="M12.5729 12.7311C12.2323 12.3558 11.6297 12.3952 11.4299 12.8609C11.1497 13.5143 11 14.2484 11 15C11 15.7516 11.1497 16.4857 11.4299 17.1391C11.6297 17.6048 12.2323 17.6442 12.5729 17.2689C12.8225 16.994 12.8372 16.5852 12.696 16.2418C12.5391 15.8604 12.4555 15.4351 12.4555 15C12.4555 14.5649 12.5391 14.1396 12.696 13.7582C12.8372 13.4148 12.8225 13.006 12.5729 12.7311Z"
        fill="#0189FF"
      />
      <path
        d="M10.4809 10.6875C10.1412 10.3195 9.55388 10.324 9.27455 10.7397C8.45152 11.9646 8 13.4569 8 15C8 16.5431 8.45152 18.0354 9.27455 19.2603C9.55388 19.676 10.1412 19.6805 10.4809 19.3125C10.7756 18.9931 10.774 18.5057 10.536 18.1421C9.94105 17.2336 9.61528 16.1351 9.61528 15C9.61528 13.8649 9.94105 12.7664 10.536 11.8579C10.774 11.4943 10.7756 11.0069 10.4809 10.6875Z"
        fill="#0189FF"
      />
      <rect x="4" y="7" width="37" height="26" rx="1" stroke="#08044F" strokeWidth="2" />
      <path
        d="M18 14C18 12.8954 18.8954 12 20 12H25C26.1046 12 27 12.8954 27 14V19H18V14Z"
        fill="#FF554D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 22C25.5376 22 28 19.5376 28 16.5C28 13.4624 25.5376 11 22.5 11C19.4624 11 17 13.4624 17 16.5C17 19.5376 19.4624 22 22.5 22Z"
        stroke="#08044F"
        strokeWidth="2"
      />
      <path
        d="M29 27.539C27.4183 25.9766 25.166 25 22.6667 25C20.1673 25 17.9151 25.9766 16.3333 27.539"
        stroke="#08044F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M15 34L15 38" stroke="#08044F" strokeWidth="2" strokeLinecap="square" />
      <path d="M30 34L30 39" stroke="#08044F" strokeWidth="2" strokeLinecap="round" />
      <path d="M13 39L32 39" stroke="#08044F" strokeWidth="2" strokeLinecap="round" />
      <circle cx="35" cy="35" r="8" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 35C27 30.6 30.6 27 35 27C39.4 27 43 30.6 43 35C43 39.4 39.4 43 35 43C30.6 43 27 39.4 27 35ZM30.6 35L34 38.4L39.4 33L38 31.6L34 35.6L32 33.6L30.6 35Z"
        fill="#28A745"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45 35C45 40.5228 40.5228 45 35 45C29.4772 45 25 40.5228 25 35C25 29.4772 29.4772 25 35 25C40.5228 25 45 29.4772 45 35ZM35 43C39.4183 43 43 39.4183 43 35C43 30.5817 39.4183 27 35 27C30.5817 27 27 30.5817 27 35C27 39.4183 30.5817 43 35 43Z"
        fill="white"
      />
    </svg>
  )
}
