import { useTranslation } from 'react-i18next'

import { Props } from './types'
import { Button as Style } from './style'
import { BtnTertiary } from '@eclass/ui-kit'

export const Button = ({ onClick, unit, isMaterial }: Props.Button) => {
  const { t } = useTranslation()
  return (
    <Style data-testid="ButtonDownload" className="downloadButton">
      <BtnTertiary iconStatus="download" onClick={() => onClick(unit?.id!)}>
        {isMaterial || unit ? t('PDFTheseMaterials') : t('ResourcePDF')}
      </BtnTertiary>
    </Style>
  )
}
