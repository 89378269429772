import * as React from 'react'

export const SenceIconHeader = () => (
  <svg
    width="45"
    height="45"
    data-testid="SenceIcon"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA"></circle>
    <path fill="#1EBDAF" d="M21 9h9a1 1 0 011 1v4a1 1 0 01-1 1h-9V9z"></path>
    <path
      fill="#08044F"
      fillRule="evenodd"
      d="M30 11H18v2h12v-2zM18 9a2 2 0 00-2 2v2a2 2 0 002 2h12a2 2 0 002-2v-2a2 2 0 00-2-2H18zM7 13.358a1 1 0 011 1v23.553C8 38.512 8.488 39 9.09 39h17.67a1 1 0 110 2H9.09A3.09 3.09 0 016 37.91V14.359a1 1 0 011-1z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#0189FF"
      d="M31 17h-7a1 1 0 00-1 1v4a1 1 0 001 1h7a1 1 0 001-1v-4a1 1 0 00-1-1z"
    ></path>
    <path
      fill="#08044F"
      fillRule="evenodd"
      d="M16 20a3 3 0 116 0 3 3 0 01-6 0zm3-1a1 1 0 100 2 1 1 0 000-2zM16 28a3 3 0 116 0 3 3 0 01-6 0zm3-1a1 1 0 100 2 1 1 0 000-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FF554D"
      d="M29 25h-5a1 1 0 00-1 1v4a1 1 0 001 1h5a1 1 0 001-1v-4a1 1 0 00-1-1z"
    ></path>
    <path
      fill="#08044F"
      fillRule="evenodd"
      d="M15.086 7a1.09 1.09 0 00-1.09 1.09v23.82c0 .602.488 1.09 1.09 1.09H26.91a1 1 0 110 2H15.086a3.09 3.09 0 01-3.09-3.09V8.09A3.09 3.09 0 0115.087 5h17.808a3.09 3.09 0 013.09 3.09V24a1 1 0 11-2 0V8.09A1.09 1.09 0 0032.894 7H15.086z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FFBC27"
      fillRule="evenodd"
      d="M44 35a7 7 0 11-14 0 7 7 0 0114 0zm-8-4a1 1 0 112 0v5a1 1 0 11-2 0v-5zm1 7a1 1 0 100 2 1 1 0 000-2z"
      clipRule="evenodd"
    ></path>
  </svg>
)
