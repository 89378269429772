import { Box } from '@chakra-ui/react'
import { vars } from '@eclass/ui-kit'

export const Play = () => (
  <Box data-testid="podcastIcon-play" as="svg" w="16px" h="16px" fill="none" viewBox="-3 0 16 16">
    <path
      fill={vars('colors-neutral-white')}
      d="M12 7.999a.999.999 0 0 0-.427-.819l-10-7A1 1 0 0 0 0 .999V15a.999.999 0 0 0 1.573.819l10-7A.995.995 0 0 0 12 8.001v-.002c0 .001 0 .001 0 0Z"
    />
  </Box>
)
