import React from 'react'

import { Props } from './types'
import * as Style from './style'
import { Button, typeOfItem } from './'
import { getIdButtonNavigation } from './getIdButtonNavigation'

export const FooterNavigation = ({ data }: Props.FooterNavigation) => {
  let prev: any = {}
  let next: any = {}
  if (data.prev) {
    prev = {
      ...data.navigation.prev,
      ...data.prev,
    }
  }
  if (data.next) {
    next = {
      ...data.navigation.next,
      ...data.next,
    }
  }

  let layout = ''
  if (data.prev && data.next) {
    layout = 'both'
  } else if (data.prev) {
    layout = 'prev'
  } else if (data.next) {
    layout = 'next'
  }

  /** Función que agrega posición relativa al contenedor padre para poner el footer siempre al pie de página */
  const addContainerStyles = () => {
    const padre = document.getElementById('MainContainer')
    padre &&
      padre.setAttribute('style', 'position: relative; padding-bottom: 103px; min-height: 100vh')
  }

  React.useEffect(() => {
    addContainerStyles()
  }, [])

  return (
    <Style.Wrapper
      data-testid="FooterNavigation"
      role="FooterNavigation"
      className={`FooterNavigation layout-${layout}`}
    >
      {data.prev && (
        <Button
          prev
          statusStyle={prev.status && prev.status.style}
          type={typeOfItem(prev)}
          id={getIdButtonNavigation(typeOfItem(next), 'prev')}
          disabled={prev.disabled}
          href={prev.href}
          label={prev.label}
          title={prev.title}
        />
      )}
      {data.next && (
        <Button
          next
          statusStyle={next.status && next.status.style}
          type={typeOfItem(next)}
          id={getIdButtonNavigation(typeOfItem(next), 'next')}
          disabled={next.disabled}
          href={next.href}
          label={next.label}
          title={next.title}
        />
      )}
    </Style.Wrapper>
  )
}
