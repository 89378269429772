import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'app/Components/UI'
import { flashInfoBlue } from 'assets/svg'
import { Box, Button, Text } from '@chakra-ui/react'
import { vars } from '@eclass/ui-kit'
import { ModalStyleReset } from './ModalStyle'

type TimeInfoModalProps = {
  isOpen: boolean
  setIsOpen: any
}

export const TimeInfoModal = ({ isOpen, setIsOpen }: TimeInfoModalProps) => {
  const { t } = useTranslation()

  const toggleModal = (e: any) => {
    e.preventDefault()
    setIsOpen(!isOpen)
  }

  const styleButton = {
    backgroundColor: 'transparent',
    borderRadius: 0,
    color: vars('colors-main-deepSkyBlue'),
    display: 'block',
    fontWeight: '500',
    p: '1rem',
    variant: 'link',
    width: '100%',
    style: { textDecoration: 'none' },
  }

  const border = `1px solid ${vars('colors-neutral-platinum')}`

  return (
    <Modal
      type="info"
      isOpen={isOpen}
      toggle={toggleModal}
      closeModal={toggleModal}
      btnClose={false}
    >
      <ModalStyleReset />
      <Box textAlign="center" p="2rem">
        <img alt="warning" src={flashInfoBlue} />
        <Text mt="1.25rem" fontSize="20px!important" fontWeight="700">
          {t('LevelTestTimeFinish')}
        </Text>
        <Text mt="16px" fontSize="1rem!important" fontWeight="400">
          {t('LevelTestLessFive')}
        </Text>
      </Box>
      <Box as="footer" borderTop={border} display="flex" p="0">
        <Button {...styleButton} onClick={toggleModal} whiteSpace="pre-wrap">
          {t('LevelTestUnderstand')}
        </Button>
      </Box>
    </Modal>
  )
}
