import { serviceSentryErrors } from 'app/Services/useSentryErrors'
import axios from 'axios'

const TIMEOUT = 3000
const MAX_RETRIES = 2

/**
 * Función para enviar datos con reintento automático
 */
export const retryRequest = async (url, data, config = {}, retries = 0, lastError = null) => {
  const source = axios.CancelToken.source() // Crear una fuente de token de cancelación para poder cancelar la solicitud si es necesario

  const timeout = setTimeout(() => {
    source.cancel('Request timed out') // Cancelar la solicitud si supera el tiempo de espera
  }, TIMEOUT)

  try {
    const hasRetries = retries > 0 ? { retries } : {}
    const response = await axios({
      ...config,
      url,
      data: {
        ...data,
        variables: {
          ...data.variables,
          EvaluationAnswerInput: {
            ...data.variables.EvaluationAnswerInput,
            ...hasRetries,
          },
        },
      },
      cancelToken: source.token, // Pasar el token de cancelación a la solicitud
    })

    clearTimeout(timeout) // Limpiar el tiempo de espera si la solicitud se completa

    if (!response.data.data.EvaluationAnswer.status.success) {
      const refCode = response.data.data.EvaluationAnswer.status.refCode
      if (!refCode || (refCode && refCode.length === 0)) {
        // si el error no esta manejado en back, vuelvo a mandar petición
        return retry(url, data, config, retries, response.data)
      } else {
        return response.data.data.EvaluationAnswer
      }
    } else {
      // Si la respuesta es exitosa, devolver los datos
      return response.data.data.EvaluationAnswer
    }
  } catch (error) {
    clearTimeout(timeout) // Limpiar el tiempo de espera si hay un error
    // @ts-ignore
    if (axios.isCancel(error) && error.message === 'Request timed out') {
      // Si el error es debido a un tiempo de espera, intentar de nuevo
      return retry(url, data, config, retries, { message: 'Request timed out' })
    } else {
      // Si el error es de otro tipo, capturar el último error y intentar de nuevo
      // @ts-ignore
      return retry(url, data, config, retries, error.response ? error.response.data : error)
    }
  }
}

/**
 * Función para manejar el reintento de la solicitud
 */
const retry = async (url, data, config, retries, lastError) => {
  if (retries < MAX_RETRIES) {
    return retryRequest(url, data, config, retries + 1, lastError)
  } else {
    serviceSentryErrors({
      user: {
        params: data.variables.EvaluationAnswerInput,
        err: lastError,
      },
      context: 'CLOZE - Respuesta no guardada',
      extra: {
        message: 'Servidor no responde a petición',
      },
    })
    return Promise.resolve(lastError?.data?.data?.EvaluationAnswer)
  }
}
