import { FC, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setKeepTimeZone } from 'app/Redux/Actions'
import { useTranslation } from 'react-i18next'
import jwtDecode from 'jwt-decode'

import * as Redux from 'app/Redux/types'
import { TimeZoneInfoModal } from 'app/Views/Users/Profile/FormModal/TimeZoneInfo'
import { AlertNotification } from 'app/Components/UI/Alert'
import { LoginToken } from 'app/Services/Auth/types'

/**
 * Validao si el user tiene time zone, si no tiene levanto modal para que la registre
 *
 * Si esta suplantando nunca muestra el modal
 */

interface ModalTimeZoneProps {
  children?: React.ReactNode
}

export const ModalTimeZone: FC<ModalTimeZoneProps> = ({ children }) => {
  const { t } = useTranslation()
  const {
    loggedUser: { user, token },
    ui: { keepTimeZone },
  } = useSelector((state: Redux.Store) => state)

  const dispatch = useDispatch()

  const tokenDecode: LoginToken = jwtDecode(token!)
  const isSupplanting = !tokenDecode.entryId

  const hasTz = user?.timeZone !== ''

  const [isOpen, setIsOpen] = useState(true)
  const [alert, setAlert] = useState(false)
  const [change, setChange] = useState(true)

  const handleClose = () => {
    setIsOpen(!isOpen)
    setChange(!change)
    setAlert(true)
    setTimeout(() => {
      setAlert(false)
    }, 100)
  }

  // no change Time Zone
  const noHandleHold = () => {
    setIsOpen(!isOpen)
    dispatch(setKeepTimeZone(true))
  }

  if (isSupplanting) {
    return <>{children}</>
  }

  const resolvedOptions = Intl.DateTimeFormat().resolvedOptions()
  // validate time zone save in server with browser
  if (hasTz && !keepTimeZone && user?.timeZone !== resolvedOptions.timeZone) {
    return (
      <>
        <TimeZoneInfoModal
          isOpen={isOpen}
          toggleModal={noHandleHold}
          modalName={t('ProfileTimeZoneChange')}
          context="TO_CHANGE"
        />
        {children}
      </>
    )
  }

  return (
    <>
      {!hasTz ? (
        <>
          <TimeZoneInfoModal
            isOpen={isOpen}
            toggleModal={handleClose}
            modalName={t('ProfileTimeZoneCoursesListModal')}
            context="WITH_OUT"
          />
          {children}
        </>
      ) : (
        <>
          <AlertNotification message={t('ProfileSavedInfo')} show={alert} />
          {children}
        </>
      )}
    </>
  )
}
