import React from 'react'

import { Featured } from './Featured'
import { HTMLIFrame } from './Iframe'
import { Image } from './Image'
import { Slideshow } from './Slideshow'
import { Accordion } from './Accordion'
import { Audio } from './Audio'
import { DownloadFile } from 'app/Components/UI'
import { Appointment } from './Appointment'
import { ArticleVideo } from './ArticleVideo'
import { ContentModuleProps } from '../types'
import { useViewDownload } from 'app/Views/Resources/PDF'
import { AlternativeContent } from './AlternativeContent'
import { Voice } from './Voice/Voice'
import { ParallaxModule } from './Parallax'
import { Timeline } from './Timeline'

export const IDModules = {
  CITA: 1,
  DESTACADO: 2,
  CARRUSEL: 3,
  ACORDEON: 4,
  IMAGENES: 5,
  IFRAME: 6,
  // LIBRE: 7,
  AUDIO: 8,
  DESCARGAR_ARCHIVO: 9,
  IFRAME_MODAL: 10,
  VIDEO: 11,
  VOICE: 12,
  PARALLAX: 13,
  TIMELINE: 14,
}

const ContentModule = ({ config, embeds }: ContentModuleProps) => {
  const isViewDownload = useViewDownload()

  if (config?.configurations?.length === 0) {
    return null
  }

  const options = JSON.parse(config.configurations!)

  const needAlternativeContent = [
    IDModules.IFRAME,
    IDModules.AUDIO,
    IDModules.DESCARGAR_ARCHIVO,
    IDModules.IFRAME_MODAL,
    IDModules.IMAGENES,
    IDModules.VIDEO,
  ]
  const typeModule = config.typeId!

  if (isViewDownload && needAlternativeContent.includes(typeModule)) {
    return (
      <AlternativeContent
        config={{
          ...options,
          idEmbed: config.id,
        }}
        typeModule={typeModule}
      />
    )
  } else {
    switch (typeModule) {
      case IDModules.CITA:
        return <Appointment config={options} id={config.id!} />
      case IDModules.DESTACADO:
        return <Featured config={options} id={config.id!} />
      case IDModules.CARRUSEL:
        return <Slideshow config={options} id={config.id!} />
      case IDModules.ACORDEON:
        return <Accordion config={options} id={config.id!} />
      case IDModules.IMAGENES:
        return <Image config={options} id={config.id!} />
      case IDModules.IFRAME:
        return <HTMLIFrame config={options} id={config.id!} />
      case 7:
        return <p>{`__RENDER_MODULE____??____${config.typeId!}`}</p>
      case IDModules.AUDIO:
        return <Audio config={options} id={config.id!} />
      case IDModules.DESCARGAR_ARCHIVO:
        return <DownloadFile doc={options} id={config.id!} />
      case IDModules.IFRAME_MODAL:
        return <HTMLIFrame config={options} id={config.id!} />
      case IDModules.VIDEO:
        return <ArticleVideo config={options} id={config.id!} />
      case IDModules.VOICE:
        return <Voice config={options} id={config.id!} />
      case IDModules.PARALLAX:
        return <ParallaxModule config={options} id={config.id!} />
      case IDModules.TIMELINE:
        return <Timeline config={options} id={config.id!} embeds={embeds} />
      default:
        return null
    }
  }
}

export default ContentModule
