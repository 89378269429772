import { gql, useQuery, QueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const GET_COURSE_LATEST_ACTIVITY = gql`
  ${STATUS_FIELDS}
  query getCourseLatestActivity($CourseLatestActivityInput: CourseLatestActivityInput!) {
    getCourseLatestActivity(input: $CourseLatestActivityInput) {
      status {
        ...StatusFields
      }
      LatestActivity {
        callToAction {
          resources
          completedResources
          progress
          href
          courseName
          unitName
        }
        info {
          id
          enabled
          name
          style
        }
        studentId
        hasScorm
      }
    }
  }
`

export type GetCourseLatestActivityPayload = Api.CourseLatestActivityPayload

export type GetCourseLatestActivityInput = Api.CourseLatestActivityInput

export interface GetCourseLatestActivityPayloadApollo {
  getCourseLatestActivity: GetCourseLatestActivityPayload
}

interface GetCourseLatestActivityInputApollo {
  CourseLatestActivityInput: GetCourseLatestActivityInput
}

export const useQueryGetCourseLatestActivity = (
  options?: QueryHookOptions<
    GetCourseLatestActivityPayloadApollo,
    GetCourseLatestActivityInputApollo
  >
) => {
  return useQuery<GetCourseLatestActivityPayloadApollo, GetCourseLatestActivityInputApollo>(
    GET_COURSE_LATEST_ACTIVITY,
    options
  )
}
