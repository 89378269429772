// @External Dependencies
import { Container, Row } from 'reactstrap'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Wrapper from './Style'
import { Types, Props } from './types'
import { useMediaQuery } from '@chakra-ui/react'
import { HelpCenter } from '../../HelpCenter/HelpCenter'

export const Help = ({ title, children }: Props.FooterHelp) => {
  const [isMobile] = useMediaQuery('(max-width: 670px)')
  const { t } = useTranslation()
  const {
    courses: { getCourseInfo: courseInfo },
  } = useSelector((state: Types.Store) => state)

  let boxes: Types.Boxes[] = []
  if (courseInfo?.footerBoxes) {
    boxes = courseInfo?.footerBoxes
  }

  if (children || boxes.length > 0) {
    return (
      <Wrapper className="FooterHelp" data-testid="Help">
        <Container fluid={isMobile}>
          <h2>{title || t('CrossHelp')}</h2>
          {children || (
            <Row className="FooterHelp__section justify-content-md-center">
              <HelpCenter type="footer" data-testid="HelpBox" />
            </Row>
          )}
        </Container>
      </Wrapper>
    )
  }

  return null
}
