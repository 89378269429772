import React from 'react'

import EmbeddedExercise from './EmbeddedExercise'
import Types from './types'

/**
 * Reemplaza un node del `ContentParser` que haga match con ___REPLACE_TEST___ por un
 * ```js
 *  <EmbeddedExercise evaluation={evaluation} key={key} />
 * ```
 */
export const renderEmbeds = (node: string, key: number, embeds: Types.Evaluation[]) => {
  const REPLACE_REGEX = /___REPLACE_TEST___(.*?)___REPLACE_TEST___/gm
  const BASE_REGEX = '___REPLACE_TEST___'

  if (node.includes(BASE_REGEX)) {
    const nodeChilds = node.split(REPLACE_REGEX)
    if (node.split(REPLACE_REGEX)) {
      const embedId = Number(nodeChilds.filter((reg: any) => Number(reg)))
      const evaluation = embeds.find(({ config: { testId } }) => testId === embedId)

      if (evaluation) {
        return <EmbeddedExercise evaluation={evaluation} key={key} />
      }
    }
  }
  return null
}
