import { Box, useMediaQuery } from '@chakra-ui/react'
import { vars } from '@eclass/ui-kit'

import { PERFECT_LENGTH } from '../Horizontal'

interface props {
  active: number
  total: number
  reference: any
  visibleElements: number
}

/**
 * Dibuja la barra de avance de la navegación de los Hitos
 */
export const Progress = ({ active, total, reference, visibleElements }: props) => {
  const [isDesktop] = useMediaQuery('(min-width: 720px)')
  // en version mobile solo muestra de un hito
  visibleElements = isDesktop ? visibleElements : 1

  // ancho del wrapper que contiene el slider
  const widthWrapper = reference.current?.innerSlider.state.listWidth ?? 1000
  // ancho de cada hito
  const widthElement = widthWrapper / visibleElements

  // marca hasta el primer hito del slider
  const half = widthElement / 2
  let widthProgress = `${half}px`

  // casos para modificar el avance del progreso
  if (active === total - 1) {
    // si es el último selecciono toda la línea
    widthProgress = '100%'
  } else if (isDesktop && visibleElements < total && active > total - 4) {
    // si estoy en escritorio debo calcular la linea para el penúltimo y antepenultimo (esto aplica para cuando se muestran 4 hitos)
    const isPenultimate = active === total - 2
    const multiply = isPenultimate ? 2 : 1
    widthProgress = `${half + widthElement * multiply}px`
  } else if (isDesktop && total <= PERFECT_LENGTH && active + 1 > 1 && active + 1 < total) {
    // si esta en escritorio
    // si el total de hitos es menor o igual que el largo deseado (4)
    // si el activo no es ni el primero ni el último
    widthProgress = `${widthElement * active + half}px`
  }

  const baseColor = vars('colors-neutral-silverSand')
  const activeColor = vars('colors-icon-deepSkyBlue')

  const common = {
    height: '2px',
    left: 0,
    top: '62px',
  }

  const commonSeudo = {
    bg: activeColor,
    bottom: '-7px',
    content: '""',
    h: '16px',
    position: 'absolute',
  }

  return (
    <Box position="relative">
      <Box
        {...common}
        bg={baseColor}
        position="absolute"
        width="100%"
        _after={{
          ...commonSeudo,
          right: 0,
          w: active === total - 1 ? '2px' : 0,
        }}
      />
      <Box
        {...common}
        background={activeColor}
        position="absolute"
        transition="width .3s"
        w={widthProgress}
        _before={{
          ...commonSeudo,
          w: active + 1 > 1 ? 0 : '2px',
        }}
        left={0}
      />
    </Box>
  )
}
