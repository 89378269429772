import { ClassDateDetail } from './ClassDateDetails/ClassDateDetails'
import { ClassTeacher } from './TeacherBox/TeacherBox'
import { useData } from '../useData'
import * as Style from './style'

interface props {
  data: any
  /** diagramación de la vista de la información */
  layout?: 'portrait' | 'landscape'
  /** muestra el feedback del profesor */
  hasFeedback?: boolean
}

export const TeacherAndDetails = ({ data, layout = 'portrait', hasFeedback = false }: props) => {
  const { classDetails, teachers } = useData(data)

  const personalMeeting = classDetails.find((element: any) => element.description === 'Sala')

  return (
    <Style.ClassDetails
      data-testid="TeacherAndDetails"
      className={`${layout} ${personalMeeting ? 'personalMeeting' : 'TeacherAndDetail'}`}
    >
      <ClassTeacher teachers={teachers} hasFeedback={hasFeedback} />
      <ClassDateDetail data={classDetails} />
    </Style.ClassDetails>
  )
}
