import { Box } from '@chakra-ui/react'
import { vars, Icons } from '@eclass/ui-kit'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'

import { useImpersonate } from './'

export const BarImpersonate = () => {
  const { t } = useTranslation()
  const { impersonate } = useImpersonate()

  if (!impersonate) return <></>

  return (
    <Box
      as="section"
      bg={vars('colors-alert-pale')}
      display="grid"
      p="8px 16px"
      placeContent="center"
      w="100%"
    >
      <Box
        display="flex"
        gap="10px"
        sx={{
          svg: {
            mt: '5px',
          },
        }}
      >
        <Icons.TinyAlertWarning />
        <Box as="p" lineHeight="21px" m={0}>
          {ReactHtmlParser(t('CrossStudentAccount'))}
        </Box>
      </Box>
    </Box>
  )
}
