import { UIVars } from 'app/Components/UI'
import * as type from '../types'

export const Microphone = ({
  className = '',
  fill = UIVars.setColors.iconFill,
  height = 24,
  width = 20,
}: type.basic) => {
  return (
    <svg
      data-testid="icon-microphone"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={`0 0 ${width} ${height}`}
    >
      <path
        fill={fill}
        d="M10 16c3.3 0 6-2.7 6-6V6c0-3.3-2.7-6-6-6S4 2.7 4 6v4c0 3.3 2.7 6 6 6z"
      ></path>
      <path
        fill={fill}
        d="M20 10c0-.6-.4-1-1-1s-1 .4-1 1c0 4.4-3.6 8-8 8s-8-3.6-8-8c0-.6-.4-1-1-1s-1 .4-1 1c0 5.2 4 9.4 9 9.9V22H5c-.6 0-1 .4-1 1s.4 1 1 1h10c.6 0 1-.4 1-1s-.4-1-1-1h-4v-2.1c5-.5 9-4.7 9-9.9z"
      ></path>
    </svg>
  )
}
