import { useParams, useHistory } from 'react-router-dom'
import { RouteParams } from 'Config/routes/types'

const LOCKED_CODE = 423

/**
 * Hook que valida si la respuesta contiene el error 423 que indica si tiene prerrequisitos para redireccionar a la
 * se realizó de esta forma como dos métodos para poder retornar null en useTest y terminar la ejecución luego de la redirección
 */
export const usePrerequisitesAndRedirect = () => {
  const history = useHistory()
  const { courseId, resourceId, studentId, unitId, programId } = useParams<RouteParams>()

  /**
   * Método que valida si la respuesta contiene el error 423 que indica si tiene prerrequisitos
   */
  const hasPrerequisites = (response) => {
    if (!(courseId && resourceId && studentId && unitId && programId)) {
      throw new Error('No tiene los parámetros requeridos para ingresar a la vista prerrequisitos')
    }

    return !response?.status?.success && response?.status?.code === LOCKED_CODE
  }

  /**
   * Método que redirecciona a la vista de prerrequisitos
   */
  const redirectToPrerequisites = () => {
    return history.push(
      `/prerequisites/${programId}/${studentId}/${courseId}/${unitId}/${resourceId}`
    )
  }

  return {
    hasPrerequisites,
    redirectToPrerequisites,
  }
}
