import jwtDecode from 'jwt-decode'

/**
 * @typedef {Object} ExternalStatus
 * @property {boolean} success - .
 * @property {string} detail - .
 */

/**
 * Obtenemos desde la URL el `token` y `entry_id`
 * Validamos el token su fecha de expiración, si es válido lo asignamos al Store con el entry_id
 * @example
 * ```js
 *  const { status } = loginExternal()
 * ```
 * @returns {ExternalStatus} Retorna el estado del login externo.
 */
const loginExternal = () => {
  return (dispatch) => {
    const accessToken = new URL(document.location).searchParams.get('access_token')
    const status = {}
    if (accessToken) {
      /** @type {import('../../../Services/Auth/types').LoginToken} */
      const tokenDecoded = jwtDecode(accessToken)
      const currentTime = new Date().getTime() / 1000
      if (currentTime < tokenDecoded.exp) {
        dispatch({
          type: 'LOGIN_EXTERNAL',
          payload: {
            token: accessToken,
            portalId: tokenDecoded.portalId ? Number(tokenDecoded.portalId) : 0,
          },
        })

        localStorage.setItem('platform:apollo:token', accessToken)

        status.success = true
        status.detail = 'ACCESS_TOKEN_OK'
      } else {
        dispatch({
          type: 'LOGOUT',
          payload: {
            user: {},
          },
        })
        localStorage.removeItem('platform:apollo:token')

        status.success = false
        status.detail = 'ACCESS_TOKEN_EXPIRED'
      }
    } else {
      status.success = false
      status.detail = 'NO_ACCESS_TOKEN'
    }

    return { status }
  }
}

export default loginExternal
