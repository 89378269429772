import { gql } from '@apollo/client'

import * as Type from '../types'

export const ALL_GENDERS = gql`
  query allGenders {
    allGenders(orderBy: name_ASC) {
      name
      id
    }
  }
`

export interface ALL_GENDERS_TYPE {
  allGenders: Type.list[]
}
