export const EvaluationCheck = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" data-testid="EvaluationCheck">
      <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
      <path
        d="M21 9H30C30.5523 9 31 9.44772 31 10V14C31 14.5523 30.5523 15 30 15H21V9Z"
        fill="#1EBDAF"
      />
      <rect x="17" y="10" width="14" height="4" rx="1" stroke="#08044F" strokeWidth="2" />
      <path
        d="M26.7599 40H9.08941C7.93546 40 7 39.0646 7 37.9106V14.358"
        stroke="#08044F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M31 17H24C23.4477 17 23 17.4477 23 18V22C23 22.5523 23.4477 23 24 23H31C31.5523 23 32 22.5523 32 22V18C32 17.4477 31.5523 17 31 17Z"
        fill="#FFBC27"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 22C20.1046 22 21 21.1046 21 20C21 18.8954 20.1046 18 19 18C17.8954 18 17 18.8954 17 20C17 21.1046 17.8954 22 19 22Z"
        stroke="#08044F"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 30C20.1046 30 21 29.1046 21 28C21 26.8954 20.1046 26 19 26C17.8954 26 17 26.8954 17 28C17 29.1046 17.8954 30 19 30Z"
        stroke="#08044F"
        strokeWidth="2"
      />
      <path
        d="M29 25H24C23.4477 25 23 25.4477 23 26V30C23 30.5523 23.4477 31 24 31H29C29.5523 31 30 30.5523 30 30V26C30 25.4477 29.5523 25 29 25Z"
        fill="#FF554D"
      />
      <path
        d="M26.9102 34H15.0861C13.9322 34 12.9967 33.0645 12.9967 31.9106L12.9967 8.08941C12.9967 6.93546 13.9322 6 15.0861 6H32.8943C34.0483 6 34.9837 6.93546 34.9837 8.08941V20"
        stroke="#08044F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M32 25C32 23.8954 32.8954 23 34 23H36C37.1046 23 38 23.8954 38 25V27H32V25Z"
        fill="#0189FF"
      />
      <path
        d="M32 29H38V39.3043C38 39.6576 37.9064 40.0046 37.7287 40.3101L35.8643 43.5144C35.4787 44.1772 34.5213 44.1772 34.1357 43.5144L32.2713 40.3101C32.0936 40.0046 32 39.6576 32 39.3043V29Z"
        fill="#0189FF"
      />
      <circle cx="35" cy="35" r="8" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 35C27 30.6 30.6 27 35 27C39.4 27 43 30.6 43 35C43 39.4 39.4 43 35 43C30.6 43 27 39.4 27 35ZM30.6 35L34 38.4L39.4 33L38 31.6L34 35.6L32 33.6L30.6 35Z"
        fill="#28A745"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45 35C45 40.5228 40.5228 45 35 45C29.4772 45 25 40.5228 25 35C25 29.4772 29.4772 25 35 25C40.5228 25 45 29.4772 45 35ZM35 43C39.4183 43 43 39.4183 43 35C43 30.5817 39.4183 27 35 27C30.5817 27 27 30.5817 27 35C27 39.4183 30.5817 43 35 43Z"
        fill="white"
      />
    </svg>
  )
}
