import { useState, useEffect, useCallback } from 'react'

export const usePosition = () => {
  const [fixed, setFixed] = useState(false)

  const handleState = useCallback(() => {
    const updatedContainer = document.getElementById('Navigation')
    if (updatedContainer) {
      if (updatedContainer.getBoundingClientRect().y < 10) {
        !fixed && setFixed(true)
      } else {
        fixed && setFixed(false)
      }
    }
  }, [fixed])

  useEffect(() => {
    window.addEventListener('resize', handleState)
    window.addEventListener('scroll', handleState)
    return () => {
      window.addEventListener('resize', handleState)
      window.removeEventListener('scroll', handleState)
    }
  }, [handleState])

  return fixed ? 'fixed' : 'relative'
}
