import React from 'react'

export const MainMaterial = () => (
  <svg
    width="46"
    height="45"
    role="iconMainMaterial"
    viewBox="0 0 46 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="23.3323" cy="22.5" r="22.5" fill="#E0EEFA" />
    <rect x="13.8323" y="9" width="13" height="6" rx="2" fill="#1EBDAF" />
    <rect x="14.8323" y="10" width="11" height="8" rx="1" stroke="#08044F" strokeWidth="2" />
    <path
      d="M31.8323 18.875V25.8794C31.8323 26.4981 32.2803 27 32.8323 27C33.1523 27 33.4526 26.8281 33.6409 26.5388L35.8323 23.7875L38.023 26.5388C38.3486 27.0388 38.9738 27.1494 39.42 26.7856C39.679 26.575 39.8323 26.2381 39.8323 25.8794V18.875C39.8323 18.3777 39.6378 17.9008 39.2915 17.5492C38.9453 17.1975 38.4758 17 37.9861 17H33.6784C33.1888 17 32.7192 17.1975 32.373 17.5492C32.0268 17.9008 31.8323 18.3777 31.8323 18.875Z"
      fill="#FFBC27"
    />
    <path
      d="M21.8323 22H14.8323C14.28 22 13.8323 22.4477 13.8323 23V26C13.8323 26.5523 14.28 27 14.8323 27H21.8323C22.3846 27 22.8323 26.5523 22.8323 26V23C22.8323 22.4477 22.3846 22 21.8323 22Z"
      fill="#FF554D"
    />
    <path
      d="M31.8323 29H14.8323C14.28 29 13.8323 29.4477 13.8323 30V33C13.8323 33.5523 14.28 34 14.8323 34H31.8323C32.3846 34 32.8323 33.5523 32.8323 33V30C32.8323 29.4477 32.3846 29 31.8323 29Z"
      fill="#0189FF"
    />
    <line
      x1="29.8323"
      y1="14"
      x2="31.8323"
      y2="14"
      stroke="#08044F"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M35.8323 29V37C35.8323 38.1046 34.9368 39 33.8323 39H13.208C11.8959 39 10.8323 37.8975 10.8323 36.5375L10.8323 21V8C10.8323 6.89543 11.7277 6 12.8323 6H19.8323H31.8323H33.4565C34.7686 6 35.8323 7.10251 35.8323 8.46252V14"
      stroke="#08044F"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)
