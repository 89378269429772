// Internal reducers
import setAnswer from './answer'
import answered from './answered'
import answeredError from './answeredError'
import completed from './completed'
import finalizeTest from './finalizeTest'
import loadTest from './loadTest'
import review from './review'
import repeat from './repeat'
import { navigationLevel } from './navigationLevel'
import { updateMenu } from './updateMenu'
import embedResponse from './embedResponse'
import uncompleted from './uncompleted'
import { RequestStatus } from '@eclass/api'

export type Props = {
  type:
    | ''
    | 'ANSWER'
    | 'ANSWERED'
    | 'COMPLETED'
    | 'EMBED_RESPONSE'
    | 'ERROR_ANSWERED'
    | 'FINALIZE'
    | 'FINALIZE_NO_REPEAT'
    | 'LOAD_TEST'
    | 'REPEAT'
    | 'REVIEW'
    | 'UPDATE_MENU'
    | 'UNCOMPLETED'
    | 'NAVIGATION_LEVEL'

  payload?: any
  id?: number
  option?: number
  isEdit?: boolean
  /**
   * Se ocupa solo en el flujo de Ejercicios.
   */
  status?: RequestStatus
  skipped?: boolean
  isRespond?: boolean
  params?: any
  extra?: any
}

/**
 * Reducer del test
 */
const testReducer = (levelId: number, stageId: number, setAnswerQuestion: any) => {
  return (
    testState: any,
    {
      type,
      id = 0,
      option = 0,
      isEdit = false,
      payload,
      status,
      skipped = false,
      isRespond = false,
      params,
      extra,
    }: Props
  ): any => {
    switch (type) {
      /**
       * Cuando el test queda incompleto.
       */
      case 'UNCOMPLETED':
        return uncompleted(testState, levelId, stageId, isEdit)

      /**
       * Cuando se completa el test
       */
      case 'COMPLETED':
        return completed(testState, levelId, stageId, isEdit)

      /**
       * Cambia el estado de la prueba y permite revisarla
       */
      case 'REVIEW':
        return review(testState, levelId, stageId)

      /**
       * Cuando se cambia una pregunta, se dispara esta lógica.
       * - ANSWER: Primero va a guardar al API la respuesta.
       * - ANSWERED: Luego de guardar se actualiza el estado de la pregunta en el componente.
       * - ERROR_ANSWERED: Si ocurrió un error al guardar, entonces limpiamos la opción. (TODO: Agregar logica para distintos tipos de preguntas)
       */
      case 'ANSWER':
        return setAnswer(
          testState,
          levelId,
          stageId,
          id,
          option,
          setAnswerQuestion,
          skipped,
          isRespond
        )

      /**
       * Capturamos el evento de cuando se respondió.
       */
      case 'ANSWERED':
        return answered(testState, levelId, stageId, id, status!, skipped, params, extra)

      /**
       * Error cuando se respondió una pregunta.
       */
      case 'ERROR_ANSWERED':
        return answeredError(testState, levelId, stageId, id, params)

      /**
       * Carga el TEST.
       */
      case 'LOAD_TEST':
        return loadTest(payload!)

      /**
       * Finaliza un test.
       */
      case 'FINALIZE':
        return finalizeTest(testState)

      /**
       * Finaliza un test y no quiero repetir.
       */
      case 'FINALIZE_NO_REPEAT':
        return finalizeTest(testState, 1)

      /**
       * Cambiar el estado para que se repita.
       */
      case 'REPEAT':
        return repeat(testState)

      /**
       * Cambia el estado para que se evalúe la embed question
       */
      case 'EMBED_RESPONSE':
        return embedResponse(testState, payload!)

      /** Actualiza el menú de navegación de recursos / materiales. */
      case 'UPDATE_MENU':
        return updateMenu(testState, payload)

      /** 
        Si navego entre niveles, seteo el loading a false para casos donde el guardar
         no se alcanzo a completar a nivel front 
       */
      case 'NAVIGATION_LEVEL':
        return navigationLevel(testState, payload)

      default:
        return testState
    }
  }
}

export default testReducer
