export const Reading = (props) => {
  return (
    <g {...props} fillRule="nonzero">
      <path
        d="M5 7H1C0.4 7 0 6.6 0 6V2C0 1.4 0.4 1 1 1H5C5.6 1 6 1.4 6 2V6C6 6.6 5.6 7 5 7Z"
        fill="#B0CFE0"
      />
      <path d="M16 1H8V3H16V1Z" fill="#B0CFE0" />
      <path d="M16 5H8V7H16V5Z" fill="#B0CFE0" />
      <path d="M16 9H0V11H16V9Z" fill="#B0CFE0" />
      <path d="M16 13H0V15H16V13Z" fill="#B0CFE0" />
    </g>
  )
}
