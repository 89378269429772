import { testsStates } from '../../testsStates'

/**
 * Retorno clase omitted si la pregunta cumple los requerimientos
 * @param answer {any}
 * @param evaluationState {number}
 */
export const classOmitted = (skipped: boolean, evaluationState: number) => {
  let omitted = ''
  // si esta omitida y estoy en estado respondiendo(ANSWERING) o revision(TERMINATE)
  if (skipped && [testsStates.ANSWERING, testsStates.TERMINATE].includes(evaluationState)) {
    omitted = 'omitted'
  }
  return omitted
}
