export const Assitant = (props) => {
  return (
    <g {...props}>
      <path
        d="M3 4C4.10457 4 5 3.10457 5 2C5 0.89543 4.10457 0 3 0C1.89543 0 1 0.89543 1 2C1 3.10457 1.89543 4 3 4Z"
        fill="#0189FF"
      />
      <path
        d="M9 7C9.26522 7 9.51957 6.89464 9.70711 6.70711C9.89464 6.51957 10 6.26522 10 6C10 5.73478 9.89464 5.48043 9.70711 5.29289C9.51957 5.10536 9.26522 5 9 5H2C1.46957 5 0.960859 5.21071 0.585786 5.58579C0.210714 5.96086 0 6.46957 0 7L0 15C0 15.2652 0.105357 15.5196 0.292893 15.7071C0.48043 15.8946 0.734784 16 1 16C1.26522 16 1.51957 15.8946 1.70711 15.7071C1.89464 15.5196 2 15.2652 2 15V11H3V15C3 15.2652 3.10536 15.5196 3.29289 15.7071C3.48043 15.8946 3.73478 16 4 16C4.26522 16 4.51957 15.8946 4.70711 15.7071C4.89464 15.5196 5 15.2652 5 15V7H9Z"
        fill="#0189FF"
      />
      <path
        d="M15 1H7V3H14V10H7V12H10.382L12.105 15.447C12.2236 15.6844 12.4315 15.8649 12.6832 15.9489C12.9349 16.033 13.2096 16.0136 13.447 15.895C13.6844 15.7764 13.8649 15.5685 13.9489 15.3168C14.033 15.0651 14.0136 14.7904 13.895 14.553L12.618 12H15C15.2652 12 15.5196 11.8946 15.7071 11.7071C15.8946 11.5196 16 11.2652 16 11V2C16 1.73478 15.8946 1.48043 15.7071 1.29289C15.5196 1.10536 15.2652 1 15 1Z"
        fill="#0189FF"
      />
    </g>
  )
}
