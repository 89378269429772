import React from 'react'
import { useHistory } from 'react-router-dom'

import { Props } from './types'
import {
  AppliedQuestion,
  // ComplementaryMaterial,
  Evaluation,
  Exercise,
  MainMaterial,
  Poll,
  LiveClassIcon,
  PresentialIcon,
} from 'app/Components/UI/Icons'

export const HandleIcon = ({ type }: Props.HandleIcon) => {
  const {
    location: { pathname },
  } = useHistory()

  switch (type) {
    case 'isResource':
      return pathname.includes('resource') ? null : <MainMaterial />
    case 'isSetExercise':
      return <Exercise />
    case 'isPoll':
      return <Poll />
    case 'isApplied':
      return <AppliedQuestion />
    case 'isEvaluation':
      return <Evaluation />
    case 'isLiveclass':
      return <LiveClassIcon />
    case 'isPresencialClass':
      return <PresentialIcon />
    default:
      return null
  }
}
