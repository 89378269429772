import { Parallax as ParallarxLib } from 'react-parallax'
import { bgWelcomeHeader } from 'assets/img'
import Types from './types'
import * as Style from './style'

export const Parallax = ({
  children,
  background = bgWelcomeHeader,
  height = 330,
}: Types.ParallaxProps) => {
  return (
    <ParallarxLib bgImage={background} strength={500}>
      <Style.Children style={{ height: height }}>{children}</Style.Children>
    </ParallarxLib>
  )
}
