import { Box } from '@chakra-ui/react'

import { ImageZoom } from 'app/Components/ContentParser/Modules/Image/Zoom'

/**
 * Convierto las imagenes del contenido en ImageZoom
 */
export const LayoutImageZoom = ({ children }) => {
  if (children.length === 0) return null

  const isImg = (item) => {
    // quito dominio ya que ImageZoom ya se lo agrega
    const cleanDomain = item?.props.src ? item.props.src.replace('https://cdn.eclass.com/', '') : ''
    // Genero id aleatorio
    const id = Math.floor(Math.random() * 1000)
    return <ImageZoom config={{ image: cleanDomain }} id={id} key={id} />
  }

  return (
    <Box
      sx={{
        '.Article__image_zoom': {
          marginLeft: 0,
          marginRight: 0,
          maxW: '100%',
          transform: 'none',
          w: 'auto',
          a: {
            display: 'inline-block',
          },
        },
      }}
    >
      {children.map((item) => {
        if (item?.type === 'img') {
          return isImg(item)
        } else if (item?.type === 'p') {
          // Este caso es porque hay casos que las imagenes vienen dentro de un <p></p>
          return item.props.children.map((node) => {
            if (node?.type === 'img') {
              return isImg(node)
            } else {
              return node
            }
          })
        } else {
          return item
        }
      })}
    </Box>
  )
}
