// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { getToken, axiosError } from '../../../Services'

/**
 * Busca todos los tipos de trabajo ordenadas por nombre ascendente
 *
 * @example
 * ```js
 *    const response = await getJobTypes()
 * ```
 * @returns Retorna una Lista con los Tipos de Trabajo (id, name)
 */
const getJobTypes = () => {
  const requestId = 'getJobTypes'
  return async (dispatch) => {
    getToken()

    const query = `
      query allJobTypes {
        allJobTypes (
          orderBy: name_ASC
        ){
          name
          id
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then((response) => {
        const { allJobTypes } = response.data.data
        if (response.status === 200 && allJobTypes) {
          return {
            data: allJobTypes,
            status: {
              success: true,
              detail: 'Petición exitosa',
            },
          }
        } else {
          return {
            data: [],
            status: {
              success: false,
              detail: 'Error al buscar las Industrias.',
            },
          }
        }
      })
      .catch((err) => axiosError(err, requestId, dispatch))
  }
}

export default getJobTypes
