import { useDisclosure, Box, useMediaQuery } from '@chakra-ui/react'
import { BtnTertiary, NewTooltip, vars } from '@eclass/ui-kit'
import { useTranslation } from 'react-i18next'

import { Icon } from 'app/Components/UI/Icons'
import { TNames } from 'app/Components/UI/Icons/Icon/getPath'

export const FeedbackField = ({ answer }) => {
  const { isOpen, onToggle } = useDisclosure()
  const { t } = useTranslation()

  const [isMobile] = useMediaQuery('(max-width: 670px)')

  const status = {
    CORRECT: {
      title: t('ResourceClozeCorrect'),
      name: 'circularCheck',
      color: vars('colors-alert-jadeGreen'),
    },
    INCORRECT: {
      title: t('ResourceClozeIncorrect'),
      name: 'circularError',
      color: vars('colors-alert-red'),
    },
    PARTIALLY_CORRECT: {
      title: t('ResourceClozePartially'),
      name: 'warning',
      color: vars('colors-alert-orangeyYelow'),
    },
  }

  if (!status[answer?.status]) return <></>

  let answerStatus = `${answer.status}`
  // Fuerzo que si llega parcialmente correcto pero obtuvo el total de puntaje, se lo deje como correcto
  if (
    answer.status === 'PARTIALLY_CORRECT' &&
    answer.score.obtained > 0 &&
    answer.score.obtained === answer.score.total
  ) {
    answerStatus = 'CORRECT'
  }

  const config: { isOpen?: boolean } = {}

  if (isMobile) {
    config.isOpen = isOpen
  }

  return (
    <Box display="inline-block" data-testid="cloze-feedbackField">
      <NewTooltip
        {...config}
        sx={{
          bg: vars('colors-neutral-white'),
          p: 0,
          boxShadow: vars('shadows-sm'),
          '.chakra-tooltip__arrow': {
            bg: '#fff!important',
          },
          'main *:last-child': {
            m: 0,
          },
        }}
        label={
          <Box
            sx={{
              'header, main': {
                padding: '8px',
                p: {
                  color: vars('colors-neutral-darkCharcoal'),
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '19px',
                },
              },
            }}
          >
            <Box
              as="header"
              color={vars('colors-neutral-white')}
              bg={status[answerStatus].color}
              borderTopRadius={vars('radii-big')}
              fontWeight={700}
            >
              {status[answerStatus].title}
            </Box>
            <Box as="main">
              {answer.comment ? <p>{answer.comment}</p> : <></>}
              <p>
                <strong>{t('ResourceClozeAnswer')}</strong> {answer.correctAnswer}
              </p>
              <p>
                {t('ResourceClozePoints', {
                  value: answer.score.obtained,
                  total: answer.score.total,
                })}
              </p>
            </Box>
          </Box>
        }
        placement="top"
      >
        <Box
          display="inline-block"
          sx={{
            svg: {
              m: 0,
            },
          }}
        >
          <BtnTertiary
            onClick={onToggle}
            iconCustom={
              <Icon name={status[answerStatus].name as TNames} fill={status[answerStatus].color} />
            }
          >
            {t('ResourceClozeFeedback')}
          </BtnTertiary>
        </Box>
      </NewTooltip>
    </Box>
  )
}
