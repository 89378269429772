import { DropdownItem } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useMediaQuery } from '@chakra-ui/react'

import { Pressed, DelayLink } from 'app/Components/UI'
import { CoursesIcon, AcademicHistorialIcon, ProfileIcon } from 'app/Components/UI/Icons'
import * as ItemStyle from './ItemStyle'
import { HiddenBackground } from './HiddenBackground'
import { useShowProfile } from 'app/Services'
import { useImpersonate } from 'app/Components/Impersonate'

const MenuItemAvatar = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [isMobile] = useMediaQuery('(max-width: 480px)')
  const [isOpen, setIsOpen] = useState(false)
  const { showProfile } = useShowProfile()
  const { impersonate } = useImpersonate()

  useEffect(() => {
    const openDrop = document.getElementsByClassName('openDrop')
    setIsOpen(openDrop.length === 1 && isMobile)
  }, [])

  const onLogout = () => {
    history.push('/logout/inactive')
  }

  return (
    <>
      {isOpen && <HiddenBackground />}
      <DropdownItem>
        <Pressed dark>
          <DelayLink to="/courses">
            <ItemStyle.ItemName id="CrossMyActiveCourses">
              <CoursesIcon />
              {t('CrossMyActiveCourses')}
            </ItemStyle.ItemName>
          </DelayLink>
        </Pressed>
      </DropdownItem>

      {showProfile ? (
        <>
          <DropdownItem>
            <Pressed dark>
              <DelayLink to="/courses-past">
                <ItemStyle.ItemName id="CrossAcademicHistory">
                  <AcademicHistorialIcon />
                  {t('AcademicHistory')}
                </ItemStyle.ItemName>
              </DelayLink>
            </Pressed>
          </DropdownItem>
          {!impersonate && (
            <DropdownItem>
              <Pressed dark>
                <DelayLink to="/profile">
                  <ItemStyle.ItemName id="CrossMyProfile">
                    <ProfileIcon />
                    {t('CrossMyProfile')}
                  </ItemStyle.ItemName>
                </DelayLink>
              </Pressed>
            </DropdownItem>
          )}

          <ItemStyle.AvatarLogout>
            <DropdownItem className="logout" onClick={onLogout}>
              <Pressed dark>
                <ItemStyle.Logout>
                  <span>{t('CrossEndSession')}</span>
                </ItemStyle.Logout>
              </Pressed>
            </DropdownItem>
          </ItemStyle.AvatarLogout>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default MenuItemAvatar
