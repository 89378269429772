import { createContext } from 'react'

interface ContextProps {
  isProvisional: boolean
  status: boolean
  openModal: () => void
  closeModal: () => void
}

export const ProvisionalContext = createContext({} as ContextProps)
