export const ExerciseCheck = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" data-testid="ExerciseCheck">
      <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
      <path
        d="M26.7599 40H9.08941C7.93546 40 7 39.0646 7 37.9106V14.358"
        stroke="#08044F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M29 20H24C23.4477 20 23 20.4477 23 21V23C23 23.5523 23.4477 24 24 24H29C29.5523 24 30 23.5523 30 23V21C30 20.4477 29.5523 20 29 20Z"
        fill="#1EBDAF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 24C20.1046 24 21 23.1046 21 22C21 20.8954 20.1046 20 19 20C17.8954 20 17 20.8954 17 22C17 23.1046 17.8954 24 19 24Z"
        stroke="#08044F"
        strokeWidth="2"
      />
      <path
        d="M29 27H17C16.4477 27 16 27.4477 16 28V30C16 30.5523 16.4477 31 17 31H29C29.5523 31 30 30.5523 30 30V28C30 27.4477 29.5523 27 29 27Z"
        fill="#FF554D"
      />
      <path
        d="M34 7H14C13.4477 7 13 7.44772 13 8V12C13 12.5523 13.4477 13 14 13H34C34.5523 13 35 12.5523 35 12V8C35 7.44772 34.5523 7 34 7Z"
        fill="#FFBC27"
      />
      <path
        d="M26.9102 34H15.0861C13.9322 34 12.9967 33.0645 12.9967 31.9106L12.9967 8.08941C12.9967 6.93546 13.9322 6 15.0861 6H32.8943C34.0483 6 34.9837 6.93546 34.9837 8.08941V20"
        stroke="#08044F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M13 16H35" stroke="#08044F" strokeWidth="2" />
      <path
        d="M32 25C32 23.8954 32.8954 23 34 23H36C37.1046 23 38 23.8954 38 25V27H32V25Z"
        fill="#0189FF"
      />
      <path
        d="M32 29H38V39.3043C38 39.6576 37.9064 40.0046 37.7287 40.3101L35.8643 43.5144C35.4787 44.1772 34.5213 44.1772 34.1357 43.5144L32.2713 40.3101C32.0936 40.0046 32 39.6576 32 39.3043V29Z"
        fill="#0189FF"
      />
      <circle cx="35" cy="35" r="8" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 35C27 30.6 30.6 27 35 27C39.4 27 43 30.6 43 35C43 39.4 39.4 43 35 43C30.6 43 27 39.4 27 35ZM30.6 35L34 38.4L39.4 33L38 31.6L34 35.6L32 33.6L30.6 35Z"
        fill="#28A745"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45 35C45 40.5228 40.5228 45 35 45C29.4772 45 25 40.5228 25 35C25 29.4772 29.4772 25 35 25C40.5228 25 45 29.4772 45 35ZM35 43C39.4183 43 43 39.4183 43 35C43 30.5817 39.4183 27 35 27C30.5817 27 27 30.5817 27 35C27 39.4183 30.5817 43 35 43Z"
        fill="white"
      />
    </svg>
  )
}
