export const AcademicData = (props) => {
  return (
    <g {...props} fillRule="nonzero">
      <path
        d="M7 2.49998L1.4 0.0999838C1.1 -0.100016 0.7 -1.62125e-05 0.4 0.199984C0.2 0.399984 0 0.699984 0 0.999984V12C0 12.4 0.2 12.8 0.6 12.9L7 15.7V2.49998Z"
        id="Path"
      ></path>
      <path
        d="M15.6 0.199865C15.3 -0.000134885 15 -0.000134885 14.7 0.0998651L9 2.49987V15.6999L15.4 12.9999C15.8 12.7999 16 12.4999 16 12.0999V0.999865C16 0.699865 15.8 0.399865 15.6 0.199865Z"
        id="Shape"
      ></path>
    </g>
  )
}
