import { CEVReducer } from './types'

export const initialState: CEVReducer = {
  feedback: {
    /*
    questions: [],
    forceModal: false,
    sending: false
    config: {
      canAnswerFeedback: false
      checkFeedbackDate: "2022-05-08T23:25:00.000Z"
      hasFeedback: false
      unitHeadquarterId: 699228
    }
    */
  },
}
