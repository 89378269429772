import { gql, useMutation, MutationHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const EVALUATION_CONTACT = gql`
  ${STATUS_FIELDS}
  mutation EvaluationContact($EvaluationContactInput: EvaluationContactInput!) {
    EvaluationContact(input: $EvaluationContactInput) {
      status {
        ...StatusFields
      }
      token
    }
  }
`

// @ts-ignore
export type EvaluationContactPayload = Api.EvaluationContactPayload

// @ts-ignore
export type EvaluationContactInput = Api.EvaluationContactInput

interface EvaluationContactPayloadApollo {
  EvaluationContact: EvaluationContactPayload
}

interface EvaluationContactInputApollo {
  EvaluationContactInput: EvaluationContactInput
}

export const useMutationEvaluationContact = (
  options?: MutationHookOptions<EvaluationContactPayloadApollo, EvaluationContactInputApollo>
) => {
  return useMutation<EvaluationContactPayloadApollo, EvaluationContactInputApollo>(
    EVALUATION_CONTACT,
    options
  )
}
