import { useTranslation } from 'react-i18next'

import { Modal as UIModal, LoaderAsync, UIVars } from 'app/Components/UI'

// import { Modal } from '../types'
import { StyleDownload as Style } from './Style'

/**
 * Modal que se levanta el momento de iniciar la descarga de los distintos certificados de cursos como diplomas
 */

export const Modal = ({ isOpen }: any) => {
  const { t } = useTranslation()

  return (
    <>
      <UIModal className="modal-delete" isOpen={isOpen}>
        <Style.Wrapper />
        <Style.Message>
          <LoaderAsync fill={UIVars.setColors.primary} />
          <h4>{t('AcademicHistoryDownload')}</h4>
          <p>{t('AcademicHistoryWait')}</p>
        </Style.Message>
      </UIModal>
    </>
  )
}
