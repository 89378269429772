import { gql, useQuery, QueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const GET_COURSE_RESUME = gql`
  ${STATUS_FIELDS}
  query getCourseResume($CourseResumeInput: CourseResumeInput!) {
    getCourseResume(input: $CourseResumeInput) {
      status {
        ...StatusFields
      }
      info {
        partialState
        title
        subtitle
        dates {
          start
          end
          deactivation
          endExtended
          deactivationExtended
        }
        state
        label
        qualification
        indicators {
          type
          label
          configuration
        }
        academicFile
      }
    }
  }
`

const GET_COURSE_RESUME_ACADEMIC = gql`
  ${STATUS_FIELDS}
  query getCourseResume($CourseResumeInput: CourseResumeInput!) {
    getCourseResume(input: $CourseResumeInput) {
      status {
        ...StatusFields
      }
      info {
        partialState
        title
        subtitle
        dates {
          start
          end
          deactivation
          endExtended
          deactivationExtended
        }
        state
        label
        qualification
        indicators {
          type
          label
          configuration
        }
        grades {
          id
          name
          totalTest
          totalCompleted
          weighing
          glossRestriction
          restriction
          average
        }
      }
    }
  }
`

export type GetCourseResumePayload = Api.CourseResumePayload

export type GetCourseResumeInput = Api.CourseResumeInput

interface GetCourseResumePayloadApollo {
  getCourseResume: GetCourseResumePayload
}

export interface GetCourseResumeInputInternal {
  CourseResumeInput: GetCourseResumeInput
  context: 'course' | 'historial'
}

export interface GetCourseResumeInputApollo {
  CourseResumeInput: GetCourseResumeInput
}

export const useQueryGetCourseResume = (
  options?: QueryHookOptions<GetCourseResumePayloadApollo, GetCourseResumeInputInternal>
) => {
  const query =
    options?.variables?.context === 'historial' ? GET_COURSE_RESUME_ACADEMIC : GET_COURSE_RESUME

  return useQuery<GetCourseResumePayloadApollo, GetCourseResumeInputInternal>(query, options)
}
