import React from 'react'
import { useTranslation } from 'react-i18next'

import { Modal } from 'app/Components/UI'
import { modalInfo } from 'assets/svg'

export const InfoModal = ({ file, toggleModal }: any) => {
  const { t } = useTranslation()

  return (
    <Modal type="info" isOpen={file.rejectModal} toggle={toggleModal} closeModal={toggleModal}>
      <div className="info-content">
        <img alt="Icono de informacion" src={modalInfo} />
        <span className="description-superior">{file.rejectMessage.title}</span>
        <span className="description-inferior">{file.rejectMessage.message}</span>
        <div className="get-it" onClick={toggleModal}>
          <span>{t('ContentUnderstand')}</span>
        </div>
      </div>
    </Modal>
  )
}
