/**
 * @typedef {Object} Vars
 * @property {Object} setColors - Cambiar color
 */
const variables = {
  // Fuentes
  fontLora: `'Lora', serif`,
  fontRoboto: `'Roboto', serif`,
  // Sombra en nav de materiales y tests
  navShadow: '2px 0 20px 0 rgba(0, 0, 0, .2)',
  // Border
  borderRadius: '10px',
  borderSolid: '1px solid #c6c6c6',
  borderSolidLight: '1px solid #e8e8e8',
  borderEdit: '1px solid #0189ff',
  /**
   * Colores
   */
  setColors: {
    none: '',
    /** #0189ff */
    primary: '#0189ff',
    /** #e0eefa */
    info: '#e0eefa',
    /** #c9c9d3 */
    default: '#c9c9d3',
    /** #e9f6ec */
    success: '#e9f6ec',
    /** #fdeed6 */
    warning: '#fdeed6',
    /** #f9dfdf */
    danger: '#f9dfdf',
    /** #adadbd */
    active: '#adadbd',
    /** #9a9aa4 */
    text: '#9a9aa4',
    /** #b0cfe0 */
    icons: '#b0cfe0',
    /** #60798e */
    completed: '#60798e',
    /** #dedee8 */
    borderColor: '#dedee8',
    /** #90A1B0 */
    darkGrayishBlue: '#90A1B0',
    /** #D7D7D7 */
    lightGrey: '#D7D7D7',

    buttons: {
      /** #2cb26b */
      success: '#2cb26b',
    },
    /**
     * Transforma un hexadecimal a
     * ```css
     *  rgba(255,255,255,0.3)
     * ```
     */
    hexToRgbA: (hex, opacity) => hexToRgbA(hex, opacity),
    /** #2f2f2f */
    black: '#2f2f2f',
    /** #5c5c5c */
    brownishGrey: '#5c5c5c',
    /** #333333 */
    blackTwo: '#333',
    /** #2f2f2f */
    blackThree: '#2f2f2f',
    /** #444444 */
    blackFour: '#444',
    /** #4B4B4B */
    blackFive: '#4B4B4B',
    /** #797979 */
    brownGrey: '#797979',
    /** #808080 */
    brownThree: '#808080',
    /** #c6c6c6 */
    veryLightPink: '#c6c6c6',
    /** #999999 */
    brownGreyTwo: '#999999',
    /** #e8e8e8 */
    veryLightPinkTwo: '#e8e8e8',
    /** #f9dfdf */
    veryLightPinkThree: '#f9dfdf',
    /** #005580 */
    deepSeaBlue: '#005580',
    /** #0189ff */
    deepSkyBlue: '#0189ff',
    /** #e6e6e6 */
    backgroundGrey: '#e6e6e6',
    /** #f4f4f4 */
    white: '#f4f4f4',
    /** #f8f8f8 */
    whiteTwo: '#f8f8f8',
    /** #ffffff */
    whiteThree: '#fff',
    /** #e8e8e8 */
    whiteFour: '#e8e8e8',
    /** #d8d8d8 */
    whiteFive: '#d8d8d8',
    /** #f5f5f5 */
    whiteSix: '#f5f5f5',
    /** #f1f1f1 */
    whiteDisabled: '#f1f1f1',
    /** #cc0000 */
    red: '#c00',
    /** #28a745 */
    jadeGreen: '#28a745',
    /** #f5a623 */
    orangeyYellow: '#f5a623',
    /** #ef6c00 */
    orange: '#ef6c00',
    /** #e0eefa */
    veryLightBlue: '#e0eefa',
    /** #e9f6ec */
    ice: '#e9f6ec',
    /** #2488F7 */
    activeBlue: '#2488f7',
    /** #B0CFE0 */
    inactiveBlue: '#b0cfe0',
    /** #28A745 */
    checkGreen: '#28a745',
    /** #C00 */
    checkError: '#c00',
    /** #c3e7cb */
    lightMint: '#c3e7cb',
    /** #f0b5b5 */
    paleSalmon: '#f0b5b5',
    /** #CDE8FF */
    assitant: '#cde8ff',
    /** #BEE1FF */
    hoverBlock: '#bee1ff',
    /** #2196f3 */
    dodgerBlue: '#2196f3',
    /** #ececec */
    backgroundGreyTwo: '#ececec',
    /** #eee */
    backgroundGreyThree: '#eee',
    /** #D2DBED */
    blueThree: '#D2DBED',
    /** #ffffff */
    iconFill: '#fff',
  },
  inputOptionFontSize: '1rem',
  inputOptionFontSizeSm: '0.7143rem',
  inputOptionSize: '1.7143rem',
  inputOptionSizeSm: '1.2143rem',
  fontSize: 16,
  fontSizeMobile: 14,
  hSize: {
    normal: {
      h1: 40,
      h2: 36,
      h3: 32,
      h4: 28,
      h5: 24,
      h6: 20,
    },
    mobile: {
      h1: 36,
      h2: 32,
      h3: 28,
      h4: 24,
      h5: 20,
      h6: 18,
    },
  },
  pSize: {
    big: 18,
    medium: 16,
    small: 14,
    tiny: 12,
  },
  articleH: {
    normal: {
      // h1: 0,
      h2: 36,
      h3: 36,
      h4: 32,
      h5: 26,
      h6: 20,
    },
    mobile: {
      // h1: 0,
      h2: 28,
      h3: 28,
      h4: 26,
      h5: 23,
      // h6: 0,
    },
  },
  alertFontSize: {
    big: 24,
    tiny: 14,
  },
}

export default variables

/**
 * Pasa un color hexadecimal a rgba()
 *
 * @param {string} hex - color en hexadecimal
 * @param {number} opacity - opacidad
 * @example
 * ```js
 *   const color = hexToRgbA('FFAACC', 0,2)
 * ```
 * @returns {string} Color en `rgba(255, 0, 0, 0.2)`
 */
const hexToRgbA = (hex, opacity) => {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')'
  }
}
