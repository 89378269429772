import ReactHtmlParser from 'react-html-parser'

import { Props } from '../types'
import './style.scss'

/**
 * ## Función General
 * Lo que señala el autor(a) referenciado en el texto, o información de un artículo académico de forma textual.
 * ## Función metodológica
 * Este recurso se utiliza para que el/la estudiante reconozca una fuente directa al contenido que se está tratando en el artículo. Como segunda función, pueda referenciar otros contenidos o incluso, generar una hipertextualidad con el contenido.
 * @example
 *    <Appointment config={options} id={2} />
 */
export const Appointment = ({ config, id }: Props.Appointment) => {
  return (
    <section className="Article__quote" key={id} data-testid="quote">
      <div className="Article__quote__container" id={`quote_content_${id}`}>
        <div>
          {ReactHtmlParser(config.text)}
          {config.abstract && (
            <footer>
              {config.image && (
                <figure>
                  <img src={`//cdn.eclass.com/${config.image}`} alt="" />
                </figure>
              )}
              <div>{ReactHtmlParser(config.abstract)}</div>
            </footer>
          )}
        </div>
      </div>
    </section>
  )
}
