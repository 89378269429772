import { useTranslation } from 'react-i18next'

import { testsStates } from '../../testsStates'
import { stateQuestion } from '../../helpers/stateQuestion'
import { TinyAlert } from '@eclass/ui-kit'

type props = {
  answer: number
  evaluation: number
  isExercise?: boolean
}

/**
 * Componente tipo Label para el caso de omitir pregunta
 * este solo se muestra si estoy en el feedback y omití la respuesta
 * @param answer {number}
 * @param evaluation {number}
 * @param isExercise {boolean} valido si esta en la capa ejercitiva
 */
export const LabelOmitted = ({ answer, evaluation, isExercise }: props) => {
  const { t } = useTranslation()

  if (isExercise || !(answer === stateQuestion.OMITTED && evaluation === testsStates.EVALUATED)) {
    return null
  }

  return <TinyAlert status="info" text={t('ResourceQuestionOmitted')} />
}
