/** Devueldo segundos formateados en minutos:segundos */
export const formattedSeconds = (second) => {
  const minutes = Math.floor(second / 60)
    .toString()
    .padStart(2, '0')
  const seconds = Math.floor(second % 60)
    .toString()
    .padStart(2, '0')

  return { minutes, seconds }
}
