import ReactHtmlParser from 'react-html-parser'

import { Props } from '../types'
import './style.scss'

/**
 * ## Función General
 * Se utiliza para reforzar ideas fuerzas del artículo.
 *
 * ## Función Metodológica
 * Este recurso apela a la memoria visual del alumno, que este pueda recordar la información señalada en el destacado. Se reconocieron 2 tipos de destacados: de lectura continua y de lectura no continua.
 * @example
 *    <Featured config={options} id={4} />
 */
export const Featured = ({ config, id }: Props.Featured) => {
  let className = ''
  const isOutstanding = config.type === 'outstanding'

  if (isOutstanding) {
    className = 'Article__outstanding'
  } else if (Number(config.float) === 0) {
    className = 'Article__featured block'
  } else {
    className = `Article__featured float ${config.position}`
  }

  const showTitle = config.type === 'featured' && config.title.length > 0
  const backgroundColor = config.bg_color || '#f4f4f4'
  const textColor = config.text_color || '#60798e'

  return (
    <section className={className} key={id} data-testid="Featured">
      <div style={!isOutstanding ? { backgroundColor, color: textColor } : {}}>
        {showTitle && <h4>{config.title}</h4>}
        {ReactHtmlParser(config.text)}
      </div>
    </section>
  )
}
