import { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Types } from '../types'
/**
 * Agrega y edita datos de store.loggedUser.user y de store.profile
 * @example const { loading, data, setData, profile, setProfile, setAlert } = useData()
 */
export const useData = () => {
  const dispatch = useDispatch()
  const {
    loggedUser: { user: data },
  } = useSelector((state: Types.Store) => state)
  const [loading, setLoading] = useState(true)

  /**
   * Actualizo datos del usuario
   * @param payload
   */
  const setData = useCallback(
    (payload: any) => {
      dispatch({
        type: 'UPDATE_USER',
        payload,
      })
    },
    [dispatch]
  )
  /**
   * Actualizo datos de Alert
   * @param payload
   */
  const setAlert = useCallback(
    (payload: string) => {
      dispatch({
        type: 'PROFILE_ALERT',
        payload,
      })
    },
    [dispatch]
  )

  useEffect(() => {
    if (!loading) {
      setLoading(false)
    }
  }, [loading])

  return {
    data,
    loading,
    setData,
    setAlert,
  }
}
