// @External Dependencies
import React from 'react'
import styled from 'styled-components/macro'
import { ProductAttributes } from '@eclass/sequelize-models'

type Props = {
  product: ProductAttributes
  className?: string
}

/**
 * Wrapper del logo del branding
 * @example
 *    <ProductLogo product={product} />
 */
const ProductLogo = ({ product, className }: Props) => {
  if (!product.brandingLogo) {
    return <></>
  } else {
    return (
      <Wrapper className={`ProductLogo flex-center ${className}`}>
        <img src={product?.brandingLogo || ''} alt={product?.name || 'Logo'} />
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  margin: 32px 0px;
  img {
    max-height: 154px;
    max-width: 100%;
  }
`

export default ProductLogo
