import { gql } from '@apollo/client'

import * as Type from '../types'

export const ALL_INSTITUTION_TYPES = gql`
  query allInstitutionTypes {
    allInstitutionTypes {
      name
      id
    }
  }
`

export interface ALL_INSTITUTION_TYPES_TYPE {
  allInstitutionTypes: Type.list[]
}
