import axios from 'axios'

import { axiosError } from 'app/Services'
import { apiURL } from '../../../../Config/getDomainConfig'
import { EvaluationCheckRepetitionInput, EvaluationCheckRepetitionPayload } from '@eclass/api'

/**
 *  Obtiene los mensajes de alerta si un alumno tiene una evaluación que debe repetir
 *
 * @example
 *   await getEvaluationRepetition(inputs)
 */
const getEvaluationRepetition = (inputs: EvaluationCheckRepetitionInput) => {
  const requestId = 'getEvaluationRepetition'
  return async (dispatch: any): Promise<EvaluationCheckRepetitionPayload> => {
    const query = `query getEvaluationRepetition($EvaluationCheckRepetitionInput: EvaluationCheckRepetitionInput!) {
        getEvaluationRepetition(input: $EvaluationCheckRepetitionInput) {
          status {
            success
            name
            detail
            code
          }
          icon
          message
          button {
            name
            href
          }
        }
      }
     `

    const variables = {
      EvaluationCheckRepetitionInput: inputs,
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response) => {
        return response?.data?.data?.getEvaluationRepetition
      })
      .catch((err) => axiosError(err, requestId, dispatch))
  }
}

export default getEvaluationRepetition
