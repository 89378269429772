import { gql, useMutation, MutationHookOptions } from '@apollo/client'
// import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const WorkSend = gql`
  ${STATUS_FIELDS}
  mutation WorkSend($WorkSendInput: WorkSendInput!) {
    WorkSend(input: $WorkSendInput) {
      status {
        ...StatusFields
      }
      fileId
    }
  }
`

export type WorkSendPayload = Api.WorkSendPayload

export type WorkSendInput = Api.WorkSendInput

interface WorkSendPayloadApollo {
  WorkSend: WorkSendPayload
}

interface WorkSendInputApollo {
  WorkSendInput: WorkSendInput
}

export const useMutationWorkSend = (
  options?: MutationHookOptions<WorkSendPayloadApollo, WorkSendInputApollo>
) => {
  return useMutation<WorkSendPayloadApollo, WorkSendInputApollo>(WorkSend, options)
}
