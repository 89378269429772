import React from 'react'
import { useQuery } from '@apollo/client'

import * as Apollo from 'app/Apollo'
import { useSetSelect } from '../Common'
import { Types } from '../types'

/**
 * hooks que obtine los niveles de estudios
 * @example
 * const { studyLevels, status } = useStudyLevels()
 */
export const useStudyLevels = () => {
  const [state, setState] = React.useState<Types.select[]>([])
  const { setOption } = useSetSelect(state, 'studyType')

  const { data } = useQuery<Apollo.ALL_STUDY_LEVELS_TYPE>(Apollo.ALL_STUDY_LEVELS)

  const load = React.useCallback(() => {
    const list = data?.allStudyLevels.map((x) => {
      return {
        label: x.name,
        value: x.id,
      }
    })
    if (list) {
      setOption(list)
      setState(list)
    }
  }, [data])

  React.useEffect(() => {
    if (data && data?.allStudyLevels?.length > 0 && state.length === 0) {
      load()
    }
  }, [data, state])

  return { studyLevels: state }
}
