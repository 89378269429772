import React, { useEffect } from 'react'
import Pusher from 'pusher-js/with-encryption'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { RouteParams } from 'Config/routes/types'
import * as ReduxTypes from 'app/Redux/types'
import { NotificationPusher } from '../UI/ModalMedalAchieved/types'

export const useSnmSuscribe = () => {
  const { studentId, courseId } = useParams<RouteParams>()
  const {
    courses: { getCourseInfo },
    notification: { leaderboard: reduxLeaderboard },
  } = useSelector((store: ReduxTypes.Store) => store)

  const dispatch = useDispatch()

  const courseRankings = getCourseInfo?.CourseRankings
  const [snmData, setsnmData] = React.useState<NotificationPusher[]>()
  useEffect(() => {
    if (
      studentId &&
      courseRankings &&
      courseRankings.length &&
      !snmData &&
      !reduxLeaderboard &&
      getCourseInfo.Course?.id === Number(courseId)
    ) {
      const pusherAppKey = 'af007ef90956f890e989'
      const pusher = new Pusher(pusherAppKey, {
        cluster: 'us2',
      })

      dispatch({
        type: 'SET_NOTIFICATION_LEADERBOARD',
        payload: pusher,
      })
    } else if (!studentId && reduxLeaderboard) {
      reduxLeaderboard.disconnect()
      dispatch({
        type: 'SET_NOTIFICATION_LEADERBOARD',
        payload: undefined,
      })
    }
  }, [studentId, reduxLeaderboard, courseRankings, snmData])

  useEffect(() => {
    const canal = `v8_${studentId}`
    if (reduxLeaderboard && studentId) {
      const event = 'alumno'
      const channel = reduxLeaderboard.subscribe(canal)
      channel.bind(event, (data: any) => {
        setsnmData(data)
      })
    }
  }, [reduxLeaderboard])

  return { snmData }
}
