import { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Types } from '../types'
import { getPersonInfo } from 'app/Redux/Actions'
/**
 * Agrega y edita datos de store.loggedUser.user y de store.profile
 * @example const { loading, data, setData, profile, setProfile, setAlert } = useData()
 */
export const useData = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    loggedUser: { user: data },
    profile,
  } = useSelector((state: Types.Store) => state)
  const [loading, setLoading] = useState(true)

  /**
   * Actualizo datos del usuario
   * @param payload
   */
  const setData = useCallback(
    (payload: Types.PersonAttributes) => {
      dispatch({
        type: 'UPDATE_USER',
        payload,
      })
    },
    [dispatch]
  )
  /**
   * Actualizo datos de profile
   * @param payload
   */
  const setProfile = useCallback(
    (payload: Types.ProfileStore) => {
      dispatch({
        type: 'PROFILE_ADD',
        payload,
      })
    },
    [dispatch]
  )
  /**
   * Actualizo datos de Alert
   * @param payload
   */
  const setAlert = useCallback(
    (payload: string) => {
      dispatch({
        type: 'PROFILE_ALERT',
        payload,
      })
    },
    [dispatch]
  )
  /**
   * Busca la información extra del alumno y la guardo en la store
   */
  const getInfo = useCallback(async () => {
    const response = await dispatch<any>(getPersonInfo())

    if (response && response.status.success) {
      const { dataPersonalInfo, dataContactInfo, dataAcademyInfo } = response
      dispatch({
        type: 'MORE_USER_DATA',
        payload: {
          emailPrivate: dataContactInfo.private,
          documentNumber: dataPersonalInfo.documentNumber,
          documentNumberId: dataPersonalInfo.documentNumberId,
          nationId: dataPersonalInfo.nationId,
          professionalResume: dataAcademyInfo.professionalResume,
          phoneInfo: dataContactInfo.phoneInfo,
          address: dataContactInfo.addressInfo,
          academicTrainingInfo: dataAcademyInfo.academicTrainingInfo,
          jobInfo: dataAcademyInfo.jobInfo,
          studyLevelId: dataAcademyInfo.studyLevelId,
          birthday: new Date(dataPersonalInfo.birthday + 'T10:00:00'),
          timeZone: dataPersonalInfo.timeZone,
        },
      })
      setLoading(false)
    }
  }, [dispatch])

  useEffect(() => {
    if (loading && !data?.nationId) {
      getInfo()
    } else {
      setLoading(false)
    }
  }, [loading, getInfo, data, history])

  useEffect(() => {
    if (history.action === 'POP') {
      getInfo()
    }
  }, [history, getInfo])

  return {
    data,
    loading,
    setData,
    profile,
    setProfile,
    setAlert,
  }
}
