import { useRecorder } from './useRecorder'
import { Props } from '../types'
import { CommonVoiceRecognition } from './CommonVoiceRecognition'

export const AmazonVoiceRecognition = ({ config, id }: Props.Voice) => {
  const { browserSupport, onClick, state, redo, alertError, closedError, transcript } = useRecorder(
    { config, id }
  )

  return (
    <CommonVoiceRecognition
      browserSupport={browserSupport}
      onClick={onClick}
      state={state}
      redo={redo}
      alertError={alertError}
      closedError={closedError}
      transcript={transcript}
    />
  )
}
