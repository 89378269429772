import React, { useCallback } from 'react'

import { ContentParser } from 'app/Components'
import * as UI from 'app/Components/UI'
import * as Style from './Style'
import useExercise from 'app/Views/Exercise/Practice/useExercise'
import { COLOR } from '../../IconStatus'
import { Icon } from 'app/Components/UI/Icons'

export const TypeIn = (props: any) => {
  const { optionDisabled } = props
  const {
    state: { answers, answered, seeCorrect, hint, send },
    dispatch,
  } = useExercise()

  const question = JSON.parse(props.excercises.Answer.content)

  const handleRender = useCallback(
    (node: any, index: number) => {
      const regex = '___REPLACE_TYPE_IN'
      if (node.includes(regex)) {
        const handleInput = (e: any) => {
          dispatch({
            answerId: index,
            value: e.target.value,
          })
        }
        const answersSelect = answers[index]
        const name = `${regex}___${index}`
        let correct = answersSelect?.correct
        let color = correct ? COLOR.success : COLOR.error
        if (seeCorrect) {
          color = COLOR.success
          correct = true
        }

        if (answered || seeCorrect) {
          return (
            <Style.Validate color={color} key={name}>
              <Icon
                name={correct ? 'circularCheck' : 'circularError'}
                fill={color}
                height={20}
                width={20}
              />
              {seeCorrect ? (
                <ul>
                  {hint[index].map((option: any) => {
                    return <li key={option}>{option}</li>
                  })}
                </ul>
              ) : (
                <div>{answersSelect?.value}</div>
              )}
            </Style.Validate>
          )
        }

        return (
          <Style.Charts size={question.inputs[index].chars} key={name}>
            <UI.Input
              autocomplete="off"
              inputSize={12}
              size="xs"
              disabled={send.status || optionDisabled}
              name={name}
              value={answersSelect?.value}
              onChange={handleInput}
              noDelete
            />
          </Style.Charts>
        )
      }
      return null
    },
    [question.inputs, answers, dispatch, seeCorrect, answered, hint, send, optionDisabled]
  )

  return (
    <Style.Wrapper>
      {ContentParser({
        content: question.text,
        embeds: props.excercises.Answer.embeds,
        other: {
          type: 'TYPE_IN',
          handleRender,
        },
      })}
    </Style.Wrapper>
  )
}
