import React from 'react'

export const PresentialIcon = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="PresencialIcon"
    >
      <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
      <path
        d="M28 29C28 27.8954 28.8954 27 30 27H32C33.1046 27 34 27.8954 34 29V32H28V29Z"
        fill="#1EBDAF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 31C26 28.2386 28.2386 26 31 26C33.7614 26 36 28.2386 36 31C36 33.7614 33.7614 36 31 36C28.2386 36 26 33.7614 26 31ZM31 28C29.3431 28 28 29.3431 28 31C28 32.6569 29.3431 34 31 34C32.6569 34 34 32.6569 34 31C34 29.3431 32.6569 28 31 28Z"
        fill="#08044F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2005 41.3983C24.2057 38.7294 27.401 37 31 37C34.599 37 37.7943 38.7294 39.7995 41.3983C40.1312 41.8399 40.0422 42.4668 39.6007 42.7985C39.1591 43.1303 38.5322 43.0412 38.2005 42.5997C36.5568 40.4119 33.9434 39 31 39C28.0566 39 25.4432 40.4119 23.7995 42.5997C23.4677 43.0412 22.8409 43.1303 22.3993 42.7985C21.9578 42.4668 21.8688 41.8399 22.2005 41.3983Z"
        fill="#08044F"
      />
      <path
        d="M32 9H26C25.4477 9 25 9.44772 25 10V12C25 12.5523 25.4477 13 26 13H32C32.5523 13 33 12.5523 33 12V10C33 9.44772 32.5523 9 32 9Z"
        fill="#FF554D"
      />
      <path
        d="M41 15H26C25.4477 15 25 15.4477 25 16V18C25 18.5523 25.4477 19 26 19H41C41.5523 19 42 18.5523 42 18V16C42 15.4477 41.5523 15 41 15Z"
        fill="#0189FF"
      />
      <path
        d="M12 16C12 14.8954 12.8954 14 14 14H18C19.1046 14 20 14.8954 20 16V20H12V16Z"
        fill="#FFBC27"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C10 14.6863 12.6863 12 16 12C19.3137 12 22 14.6863 22 18C22 21.3137 19.3137 24 16 24C12.6863 24 10 21.3137 10 18ZM16 14C13.7909 14 12 15.7909 12 18C12 20.2091 13.7909 22 16 22C18.2091 22 20 20.2091 20 18C20 15.7909 18.2091 14 16 14Z"
        fill="#08044F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7027 27.8275C20.9328 26.0793 18.4277 25 15.6667 25C10.4048 25 6 28.9556 6 34C6 34.5523 6.44771 35 7 35C7.55228 35 8 34.5523 8 34C8 30.2079 11.3556 27 15.6667 27C17.9043 27 19.9037 27.8739 21.2973 29.2504C21.6902 29.6385 22.3233 29.6346 22.7114 29.2417C23.0996 28.8488 23.0957 28.2156 22.7027 27.8275Z"
        fill="#08044F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 8C6 6.34315 7.34314 5 9 5H37C38.6569 5 40 6.34315 40 8V20C40 21.6569 38.6569 23 37 23H28C27.4477 23 27 22.5523 27 22C27 21.4477 27.4477 21 28 21H37C37.5523 21 38 20.5523 38 20V8C38 7.44772 37.5523 7 37 7H9C8.44772 7 8 7.44772 8 8V17.7895C8 18.3418 7.55228 18.7895 7 18.7895C6.44772 18.7895 6 18.3418 6 17.7895V8Z"
        fill="#08044F"
      />
    </svg>
  )
}
