import React from 'react'

import { Wrapper } from './Style'
import { Icon } from 'app/Components/UI/Icons'

export const Loaded = ({ file, fileDispatch, optionDisabled, isMobile }: any) => {
  return (
    <Wrapper
      className={`FileZone Loaded ${isMobile ? 'isMobible' : ''} ${
        optionDisabled ? 'disabled' : ''
      }`}
    >
      <div className="Placeholder">
        <div className="file-info">
          <span className="filename">{file.name}</span>
          <span className="extension">({file.extension})</span>
        </div>
        {optionDisabled ? null : (
          <button className="trash" onClick={() => fileDispatch({ type: 'remove' })}>
            <Icon name="trash" fill="#fff" />
          </button>
        )}
      </div>
    </Wrapper>
  )
}
