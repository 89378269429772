import { Box } from '@chakra-ui/react'
import { vars } from '@eclass/ui-kit'

export const LabelIndex = ({ index, total }) => {
  return (
    <Box
      as="span"
      borderLeft="solid 1px"
      borderColor={vars('colors-neutral-gray')}
      color={vars('colors-neutral-gray')}
      height="16px"
      lineHeight="16px"
      m="0"
      minW="fit-content"
      paddingLeft="16px"
      title="label-index"
    >
      {index + 1} de {total}
    </Box>
  )
}
