import { EvaluationCreatePayload } from '@eclass/api'

import { updateAnswer } from './updateAnswer'
import { stateQuestion } from '../../helpers/stateQuestion'
import { fieldDisabled } from '../../Questions/QuestionTypes/Cloze/services/fieldDisabled'
/**
 * Cuando se cambia una pregunta, se dispara esta lógica.
 * - ANSWER: Primero va a guardar al API la respuesta.
 * - ANSWERED: Luego de guardar se actualiza el estado de la pregunta en el componente.
 * - ERROR_ANSWERED: Si ocurrió un error al guardar, entonces limpiamos la opción. (TODO: Agregar logica para distintos tipos de preguntas)
 */
const answer = (
  test: EvaluationCreatePayload,
  levelId: number,
  stageId: number,
  id: number,
  optionParams: any,
  setAnswerQuestion: any,
  skipped: boolean,
  isRespond: boolean
) => {
  const { option, ...cloze } = cleanOptions(
    optionParams,
    test.Evaluation?.Levels[levelId].Stages?.[stageId].Answers?.[0]
  )

  // Actualizo datos de pregunta seleccionada en Levels y Answer
  test.Evaluation! = updateAnswer({
    id,
    levelId,
    Evaluation: test.Evaluation!,
    setAnswer,
  })

  /**
   * Datos a actualizar en la pregunta
   * @param currentAnswer {any[]}
   */
  function setAnswer(currentAnswer: any) {
    // creo esto porque en el tipo archivo se cae
    let newAnswer = currentAnswer.answer
    if (currentAnswer.config.ItemType.id !== 8) {
      newAnswer = `${option}`
    }

    // // estado que tendra la pregunta, por defecto será 1,
    // // si se tiene que omitir se pasa a 4 y
    // // si no tiene nada en la respuesta se pasa a 0
    let state = stateQuestion.ANSWERED
    if (skipped) {
      state = stateQuestion.OMITTED
    } else {
      // aquí se debe poner el caso de que no esta omitida ni repondida,
      // para dejarlo en estado stateQuestion.INITIAL
    }

    // Cubre el caso cloze cuando no todos los campos estan respondidos
    if (cloze.isCloze) {
      state = stateQuestion.INITIAL
    }

    const answerQuestion = {
      ...currentAnswer,
      answer: newAnswer,
      option,
      loading: true,
      isRespond,
      state,
      skipped,
    }

    fieldDisabled({
      configClose: answerQuestion.config,
      answerRequest: answerQuestion.answer,
      disabled: true,
    })

    setAnswerQuestion(answerQuestion)
    return answerQuestion
  }

  return { ...test, optionSelect: option }
}
export default answer

/**
 * Cuando la pregunta es cloze las opciones van con un campo de mas, con este metodo se limpia
 */
const cleanOptions = (optionParams, item) => {
  let option = optionParams
  const isCloze = item.config?.ItemType?.id === 24
  let allAnsweredInCloze = false
  if (isCloze) {
    const { allAnswered, ...config } = JSON.parse(optionParams)
    allAnsweredInCloze = allAnswered || false
    option = JSON.stringify(config)
  }

  return {
    option,
    allAnsweredInCloze,
    isCloze,
  }
}
