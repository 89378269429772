import React from 'react'
import { useTranslation } from 'react-i18next'

import { Select, Input, CheckBox } from '../Common'
import { useDataAddress } from './useDataAddress'
import { useAddressTypes } from './useAddressTypes'
import * as Style from './style'
import Types from '../types'

/**
 * Campos con las direcciones
 */
export const Address = ({ profile = false, isSubmit = false }: Types.formBase) => {
  const { t } = useTranslation()
  const { countries, regions, locations, onChange } = useDataAddress()

  const AddressTypes = useAddressTypes()

  return (
    <Style.Wrapper className={`ProfileForms-Address ${profile ? 'profile' : ''}`}>
      {/* TIPO DIRECCIÓN */}
      {profile && (
        <Select
          name="addressTypeId"
          label={t('ProfileAddressType')}
          placeholder={t('ProfileSelectTypeAddress')}
          options={AddressTypes}
          disabled={isSubmit}
        />
      )}

      {/* PAÍS */}
      <Select
        name="countryId"
        label={t('ProfileCountry')}
        placeholder={t('ResourceSelect')}
        options={countries}
        onChange={onChange}
        disabled={isSubmit}
      />

      {/* REGIÓN */}
      <Select
        name="regionId"
        label={t('ProfileRegion')}
        placeholder={t('ResourceSelect')}
        options={regions}
        onChange={onChange}
        disabled={isSubmit}
      />

      {/* COMUNA */}
      <Select
        name="locationId"
        label={t('ProfileComuna')}
        placeholder={t('ResourceSelect')}
        options={locations}
        onChange={onChange}
        disabled={isSubmit}
      />

      {profile && (
        <React.Fragment>
          {/* DIRECCIÓN */}
          <Input
            name="address"
            type="NOVALIDATE"
            label={t('ProfileAddress')}
            placeholder={t('ProfileEnterAddress')}
            disabled={isSubmit}
          />

          {/* NÚMERO */}
          <Input
            name="number"
            type="ALPHANUMERIC"
            label={t('ProfileNumber')}
            placeholder={t('ProfileEnterStreetNumber')}
            disabled={isSubmit}
          />

          {/* VILLA/BLOCK/TORRRE */}
          <Input
            required={false}
            name="block"
            type="NOVALIDATE"
            label={t('ProfileBlock')}
            placeholder={t('ProfileEnterBlock')}
            disabled={isSubmit}
          />

          {/* DEPTO */}
          <Input
            required={false}
            name="apartment"
            type="NOVALIDATE"
            label={t('ProfileApartment')}
            placeholder={t('ProfileEnterAptNumber')}
            disabled={isSubmit}
          />

          <CheckBox name="private" text={t('ProfilePrivateInfo')} disabled={isSubmit} />
        </React.Fragment>
      )}
    </Style.Wrapper>
  )
}
