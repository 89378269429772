import React from 'react'
import { useQuery } from '@apollo/client'

import * as Apollo from 'app/Apollo'
import { useSetSelect } from '../Common'
import { Types } from '../types'

/**
 * hooks que obtine los niveles de estidios
 * @example
 * const { institutionsTypes } = useInstitutionsTypes()
 */
export const useInstitutionsTypes = (toCall: boolean = true) => {
  const [state, setState] = React.useState<Types.select[]>([])
  const { setOption } = useSetSelect(state, 'institutionTypeId')

  const { data } = useQuery<Apollo.ALL_INSTITUTION_TYPES_TYPE>(Apollo.ALL_INSTITUTION_TYPES)

  const load = React.useCallback(() => {
    const list = data?.allInstitutionTypes.map((x) => {
      return {
        label: x.name,
        value: x.id,
      }
    })
    if (list) {
      setOption(list)
      setState(list)
    }
  }, [data])

  React.useEffect(() => {
    if (data && data?.allInstitutionTypes?.length > 0 && state.length === 0) {
      load()
    }
  }, [data, state])

  return { institutionsTypes: state }
}
