import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { NewTooltip, UserWay } from '@eclass/ui-kit'

import { NewDropdown } from 'app/Components/UI'
import { LogoEclass } from 'app/Components/UI/Icons'
import { useLogic } from './useLogic'
import { Wrapper } from './Style'
import { HeaderProps } from './types'
import { Container } from 'app/Layouts'

/**
 * Componente cabecera de toda la aplicación.
 *
 * @example <Header config={config} />
 */
export const Header = ({ config }: HeaderProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { loading, logged, user, hasPermissions } = useLogic()
  const { t } = useTranslation()

  if (loading) {
    return null
  }

  const displayDropdown = (open: boolean) => {
    setIsOpen(open)
  }

  return (
    <Wrapper className="Header" id="MainHeader" data-testid="MainHeader">
      <Container size="xl">
        <div className="Header__topbar flex">
          <div className="flex">{hasPermissions && <LogoLink config={config} />}</div>
          <div className="buttonsContainer">
            <NewTooltip
              label="Accesibilidad"
              isDisabled={isOpen}
              m="0 32px 0 0"
              className="tooltip"
            >
              <div className="userWay">
                <UserWay />
              </div>
            </NewTooltip>
            {logged && hasPermissions && (
              <NewTooltip label={t('CrossHelpCenterTooltip')} isDisabled={isOpen} m="0 24px 0 0">
                <NewDropdown
                  displayDropdown={displayDropdown}
                  headerTitle={t('CrossHelp')}
                  dropdownType="helpCenter"
                  btnIcon="helpDropdown"
                  btnTitle=""
                />
              </NewTooltip>
            )}

            {logged && hasPermissions && (
              <NewTooltip label={t('CrossMyProfile')} isDisabled={isOpen}>
                <NewDropdown
                  displayDropdown={displayDropdown}
                  dropdownType="avatar"
                  userInfo={user}
                />
              </NewTooltip>
            )}
          </div>
        </div>
      </Container>
    </Wrapper>
  )
}

const LogoLink = ({ config }: HeaderProps) => {
  if (config.labelRedirect && config.urlRedirect) {
    return (
      <a href={config.urlRedirect}>
        <span className="label-logo">{config.labelRedirect}</span>
      </a>
    )
  } else if (config.showHeaderLogo) {
    return (
      <Link to="/courses" data-testid="logo-redirect">
        <LogoEclass />
      </Link>
    )
  }

  return null
}

export default Header
