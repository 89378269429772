import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import es from 'date-fns/locale/es'
import { subYears, isValid } from 'date-fns'

import { Label, handleRequired, TypeError } from '../'
import { Wrapper } from './style'

type props = {
  name: string
  placeholder?: string
  label?: string
  disabled?: boolean
  required?: boolean
}

const YEARS = 0

export const MyDate = ({ name, placeholder, label, disabled = false, required }: props) => {
  const { control, formState, errors } = useFormContext()

  const statusRequired = handleRequired(required)

  const error = errors[name]?.message
  const classStatus = error ? 'status-error' : ''

  return (
    <Wrapper className={`profile-${name} ${classStatus}`}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={{ required: statusRequired }}
        render={({ value, onChange }) => (
          <DatePicker
            autoComplete="off"
            selected={isValid(value) ? value : null}
            id="birthday"
            disabled={formState.isSubmitting || disabled}
            locale={es}
            dateFormat="dd-MM-yyyy"
            minDate={new Date('1900-01-01')}
            maxDate={subYears(new Date(), YEARS)}
            onChange={(newValue) => onChange(newValue)}
            placeholderText={placeholder || 'DD - MM - AAAA'}
            className="Birthday__Input"
          />
        )}
      />
      <TypeError error={error} />
    </Wrapper>
  )
}
