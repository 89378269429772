import styled from 'styled-components/macro'
import { UIVars } from 'app/Components/UI'

export const Context = styled.div`
  color: ${UIVars.setColors.brownGrey};
  display: flex;

  svg {
    margin-top: 5px;
  }

  span {
    line-height: 24px;
  }
`
