import timezones from 'timezones-list'

import { dateFormat, msgError } from './dateFormat'

interface tzCodesSelectProps {
  label: string
  value: string
  gmt: number
}

const tzCodesArr = timezones.reduce((acc: tzCodesSelectProps[], timezone) => {
  const date = new Date() // Dejo es new Date() ya que es solo para obtener zona horaria
  const GMT = dateFormat({
    date,
    format: 'z',
    timeZone: timezone.tzCode,
  })
  if (GMT !== msgError) {
    acc.push({
      label: `${GMT.toUpperCase()} ${timezone.tzCode.replace(/_/g, ' ')}`,
      value: timezone.tzCode,
      // obtengo el GMT actualizado al momento de ordenar las opciones (ejemplo: -0400)
      gmt: Number(
        dateFormat({
          date,
          format: {
            free: 'xxxx',
          },
          timeZone: timezone.tzCode,
        }).replace('+', '')
      ),
    })
  }
  return acc
}, [])

/**
 * tzCodes retorna array con nombres de zonas horarias.
 *
 * Estas van ordenadas por su GMT
 */
export const tzCodesSelect = tzCodesArr.sort((a, b) => {
  if (a.gmt > b.gmt) return 1
  if (a.gmt < b.gmt) return -1
  return 0
})
