import { Box } from '@chakra-ui/react'
import { FC } from 'react'

export const Text: FC = ({ children }) => {
  return (
    <Box fontSize="14px" fontWeight="500" lineHeight="16.41px" as="span">
      {children}
    </Box>
  )
}
