import * as React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'

import { Icon } from 'app/Components/UI/Icons'
import { cleanTags } from 'app/Services'
import { modalTypeToIcon } from '../utils'
import * as Style from './style'
import * as Types from './types'

export const Context = ({ type, name }: Types.Context) => {
  const { t } = useTranslation()

  const nameContext = (resourceName) => {
    if (!resourceName) {
      return t('QuestionYourCourse')
    }

    return cleanTags(resourceName)
  }

  return (
    <Style.Context>
      <Icon name={modalTypeToIcon(type)} fill="#B0CFE0" />
      <span>
        {ReactHtmlParser(
          t('QuestionAskingAbout', {
            name: nameContext(name),
          })
        )}
      </span>
    </Style.Context>
  )
}
