import React from 'react'

export const EmptyQuestions = () => {
  return (
    <svg
      width="198"
      height="197"
      viewBox="0 0 198 197"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M99 197C153.4 197 197.5 152.9 197.5 98.5C197.5 44.1 153.4 0 99 0C44.6 0 0.5 44.1 0.5 98.5C0.5 152.9 44.6 197 99 197Z"
          fill="#F4F4F4"
        />
        <path
          d="M73.5 63C73.5 45.3269 87.8269 31 105.5 31H163.5C181.173 31 195.5 45.3269 195.5 63V77C195.5 83.0751 190.575 88 184.5 88H82.5C77.5294 88 73.5 83.9706 73.5 79V63Z"
          fill="#FFBC27"
        />
        <rect x="3.5" y="90" width="111" height="57" fill="#FF554D" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5 88H110.5C113.814 88 116.5 90.6863 116.5 94V152.584C116.5 155.898 113.814 158.584 110.5 158.584H103.514C101.846 158.584 100.253 159.279 99.1179 160.502L89.6314 170.717C88.2184 172.238 85.8395 172.326 84.3179 170.913C83.5519 170.202 83.1167 169.204 83.1167 168.158V164.584C83.1167 161.271 80.4304 158.584 77.1167 158.584H6.5C3.18629 158.584 0.5 155.898 0.5 152.584V94C0.5 90.6863 3.18629 88 6.5 88Z"
          fill="#FF554D"
        />
        <path
          d="M38 130C41.0376 130 43.5 127.538 43.5 124.5C43.5 121.462 41.0376 119 38 119C34.9624 119 32.5 121.462 32.5 124.5C32.5 127.538 34.9624 130 38 130Z"
          fill="#08044F"
        />
        <path
          d="M59 130C62.0376 130 64.5 127.538 64.5 124.5C64.5 121.462 62.0376 119 59 119C55.9624 119 53.5 121.462 53.5 124.5C53.5 127.538 55.9624 130 59 130Z"
          fill="#08044F"
        />
        <path
          d="M79 130C82.0376 130 84.5 127.538 84.5 124.5C84.5 121.462 82.0376 119 79 119C75.9624 119 73.5 121.462 73.5 124.5C73.5 127.538 75.9624 130 79 130Z"
          fill="#08044F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M158.708 28.0731H109.292C87.8679 28.0731 70.5 45.441 70.5 66.8654C70.5 88.2897 87.8679 105.658 109.292 105.658H146.405C146.892 105.916 147.345 106.243 147.749 106.632L157.552 116.067C158.23 116.719 159.135 117.084 160.077 117.084C162.087 117.084 163.717 115.454 163.717 113.444V109.28C163.717 107.669 164.36 106.173 165.437 105.076C183.654 101.89 197.5 85.9948 197.5 66.8654C197.5 45.441 180.132 28.0731 158.708 28.0731Z"
          fill="#FFBC27"
        />
        <path
          d="M134 84C135.933 84 137.5 82.433 137.5 80.5C137.5 78.567 135.933 77 134 77C132.067 77 130.5 78.567 130.5 80.5C130.5 82.433 132.067 84 134 84Z"
          fill="#08044F"
        />
        <path
          d="M131.074 58.5714C131.762 57.5429 132.795 56.8571 134.172 56.8571C136.238 56.8571 137.615 58.2286 137.615 60.2857C137.615 61.3143 137.27 61.6571 135.549 62.6857C133.484 64.0571 130.73 66.1143 130.73 70.5714V74H137.615V70.5714C137.615 69.8857 137.615 69.5429 139.336 68.5143C141.402 67.1429 144.5 65.0857 144.5 60.2857C144.5 54.4571 140.025 50 134.172 50C130.385 50 126.943 52.0571 125.221 55.1429L123.5 58.2286L129.352 61.6571L131.074 58.5714Z"
          fill="#08044F"
        />
        <rect x="142.5" y="137" width="48" height="6" rx="3" fill="#0189FF" />
        <rect x="132.5" y="137" width="6" height="6" rx="3" fill="#0189FF" />
        <rect x="142.5" y="150" width="25" height="6" rx="3" fill="#0189FF" />
        <rect x="0.5" y="53" width="59" height="6" rx="3" fill="#1EBDAF" />
        <rect x="18.5" y="65" width="31" height="6" rx="3" fill="#1EBDAF" />
        <rect x="53.5" y="65" width="6" height="6" rx="3" fill="#1EBDAF" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="197" height="197" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
