import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import { BtnPrimary, BtnSecondary } from '@eclass/ui-kit'

import { Modal } from 'app/Components/UI'
import { Context } from './Context/Context'
import { QuestionsResource } from './QuestionsResource/QuestionsResource'
import { FormQuestion } from './FormQuestion/FormQuestion'
import { useModalQuestion, useGetQuestionInfo } from './hooks'
import * as Style from './style'
import * as Types from './types'

const defaultValuesForm = {
  title: '',
  value: EditorState.createEmpty(),
  action: 0,
  visibility: 'private',
}

/**
 * Modal encargada de crear preguntas y de editarla
 */
export const ModalQuestion = ({
  addQuestionInfo,
  edit,
  isOpen,
  resourceName,
  toggleModal,
  type,
}: Types.ModalQuestion) => {
  const { t } = useTranslation()

  const { saveQuestion, questionCreateLoading } = useModalQuestion()
  const { refetch } = useGetQuestionInfo()

  const [disabledConfirm, setDisabledConfirm] = React.useState(true)

  const [title, setTitle] = React.useState('')
  const [value, setValue] = React.useState(EditorState.createEmpty())
  const [visibility, setVisibility] = React.useState('private')

  const rawResponse = draftToHtml(convertToRaw(value.getCurrentContent()))

  const hasQuestions =
    addQuestionInfo &&
    addQuestionInfo.context &&
    (addQuestionInfo.context.own.total > 0 || addQuestionInfo.context.public.total > 0)

  const modalTypeToTitle = (typeModal: Types.ModalType) => {
    const titleOptions = {
      resource: t('QuestionAboutYourMaterial'),
      test: t('QuestionAboutYourEvaluation'),
      helper: t('QuestionAboutYourCourse'),
      work: t('QuestionAboutYourEvaluation'),
    }

    return titleOptions[typeModal]
  }

  React.useEffect(() => {
    setDisabledConfirm(title === '' || rawResponse.length <= 8)
  }, [title, rawResponse])

  React.useEffect(() => {
    if (edit) {
      setTitle(edit.title || '')
      const { contentBlocks, entityMap } = htmlToDraft(edit.text || '')
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
      setValue(EditorState.createWithContent(contentState))
      setVisibility(edit.public === 1 ? 'public' : 'private')
    }
  }, [edit])

  const handleSubmit = async () => {
    const isPublicQuestion = visibility === 'public'

    await saveQuestion({
      type,
      text: rawResponse,
      title,
      publicQuestion: isPublicQuestion,
    })

    toggleModal()
    if (type !== 'helper' && !edit) {
      refetch()

      // Sirve para limpiar los inputs del modal
      setTitle(defaultValuesForm.title)
      setValue(defaultValuesForm.value)
      setVisibility(defaultValuesForm.visibility)
    }
  }

  return (
    <Modal
      type="full"
      isOpen={isOpen}
      toggle={toggleModal}
      closeModal={toggleModal}
      header={modalTypeToTitle(type)}
    >
      <Style.ModalWrapper>
        {hasQuestions && (
          <Style.QuestionWrapper>
            <QuestionsResource context={addQuestionInfo?.context} />
          </Style.QuestionWrapper>
        )}
        <Context type={type} name={resourceName}></Context>
        <hr />
        <FormQuestion
          title={title}
          setTitle={setTitle}
          value={value}
          setValue={setValue}
          visibility={visibility}
          setVisibility={setVisibility}
          type={type}
          canPublic={addQuestionInfo?.canPublic}
        />
        <Style.ModalFooter>
          <BtnPrimary
            isLoading={questionCreateLoading}
            disabled={disabledConfirm}
            onClick={() => handleSubmit()}
            m="0 12px 0 0"
          >
            {edit ? t('QuestionSave') : t('QuestionSubmit')}
          </BtnPrimary>

          <BtnSecondary disabled={questionCreateLoading} onClick={() => toggleModal()}>
            {t('QuestionCancel')}
          </BtnSecondary>
        </Style.ModalFooter>
      </Style.ModalWrapper>
    </Modal>
  )
}
