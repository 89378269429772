import styled from 'styled-components/macro'
import { UIVars } from 'app/Components/UI'
import { vars } from '@eclass/ui-kit'

export const Wrapper = styled.div`
  &.Question__Info {
    margin-right: 30px;
    width: 56px;
    .Question__Number {
      &.Answered {
        color: ${UIVars.setColors.whiteThree};
        background-color: ${UIVars.setColors.completed};
      }
      &.Correct {
        color: ${UIVars.setColors.black};
        background-color: ${UIVars.setColors.lightMint};
      }
      &.Incorrect {
        color: ${UIVars.setColors.black};
        background-color: ${UIVars.setColors.paleSalmon};
      }
      &.Omitted.Feedback {
        color: ${UIVars.setColors.black};
        background-color: ${UIVars.setColors.icons};
      }
      &.PartiallyCorrect {
        color: ${UIVars.setColors.black};
        background-color: ${vars('colors-alert-pale')};
      }
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${UIVars.setColors.whiteTwo};
      border-radius: 5px;
      width: 56px;
      height: 56px;
      .Number {
        line-height: 32px;
        font-size: 32px;
        font-weight: bold;
      }
    }
    .Question__Value {
      color: ${UIVars.setColors.brownGrey};
      font-size: 14px;
      line-height: 15px;
      margin-top: 8px;
      text-align: center;
    }

    @media screen and (max-width: 990px) {
      width: auto;
      margin: 0 0 23px 0;
      display: flex;
      align-items: center;
      .Question__Value {
        margin-left: 10px;
      }
    }
  }
`
