import styled from 'styled-components/macro'

import { UIVars } from 'app/Components/UI'

export const Wrapper = styled.div`
  border-bottom: 1px solid ${UIVars.setColors.veryLightPink};

  &.Mobile {
    .Question__Data {
      flex-direction: column;
      margin-top: 30px;
      .Question__Info {
        margin: 0;
        display: flex;
        margin-bottom: 23px;
        align-items: center;
        .Question__Value {
          margin-left: 10px;
        }
      }
      .Question__Content {
        width: 100%;
        font-size: 16px;
        line-height: 26px;
        p {
          font-size: 16px;
        }
      }
    }
  }

  .Question__Data {
    display: flex;
    margin-top: 30px;
    p {
      font-size: 18px;
    }

    .Question__Content {
      width: 100%;
      line-height: 31px;
      max-width: 700px;
      font-size: 18px;
      color: ${UIVars.setColors.black};
      .Article__image_zoom {
        max-width: 100%;
        margin: 0;
      }
      .Pre_Options {
        margin: 16px 0;
      }
      .Article__quote,
      .Article__outstanding {
        margin: 30px 0;
      }
    }
  }
`
