import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 16px;
  &:not(:last-child) {
    border-bottom: 1px solid #e6e6e6;
  }
  .Topic__Title__Box {
    display: flex;
    font-weight: bold;
    .Topic__Initials {
      background-color: #${(props) => props.color};
      border-radius: 4px;
      padding: 0 4px;
      color: #fff;
      margin-right: 8px;
    }
    .Topic__Title {
      font-size: 20px;
    }
  }
  .Topic__Description {
    font-weight: bold;
    padding: 8px 0;
  }
  .LearningGoals {
    padding: 0 14px;
  }
`
