import { BaseIcons } from './BaseIcons'
import { IAudio } from '../Podcast'
import { vars } from '@eclass/ui-kit'

export const Download = ({ audio }: IAudio) => {
  const onClick = () => {
    if (audio) {
      const linkDownload = document.createElement('a')
      linkDownload.href = audio.src
      linkDownload.click()
      linkDownload.remove()
    }
  }

  return (
    <BaseIcons onClick={onClick} dark>
      <svg width={16} height={16} fill="none">
        <path
          fill={vars('colors-neutral-white')}
          d="M8 12c.3 0 .5-.1.7-.3L14.4 6 13 4.6l-4 4V0H7v8.6l-4-4L1.6 6l5.7 5.7c.2.2.4.3.7.3ZM15 14H1v2h14v-2Z"
        />
      </svg>
    </BaseIcons>
  )
}
