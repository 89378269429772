export const AnchorComment = (props) => {
  return (
    <g {...props}>
      <path
        d="M9 4.98765L7.58 6.40414L11.17 9.9753H2V0H0V11.9704H11.17L7.58 15.5415L9 16.958L15 10.9728L9 4.98765Z"
        fill="#C6C6C6"
      />
    </g>
  )
}
