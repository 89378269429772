import { ChangeEvent, useState } from 'react'

import { BaseIcons } from '../BaseIcons'
import { On } from './On'
import { Off } from './Off'
import { vars } from '@eclass/ui-kit'
import { Box, useMediaQuery } from '@chakra-ui/react'
import { IAudio } from '../..'

export const Volumen = ({ audio }: IAudio) => {
  const [sound, setSound] = useState(true)

  const [value, setValue] = useState(100)
  const [vol, setVol] = useState(1)
  const [isNotSmartphone] = useMediaQuery('(min-width: 640px)')

  const onClick = () => {
    if (audio) {
      if (sound) {
        setValue(0)
        audio.volume = 0
        audio.muted = true
      } else {
        setValue(vol * 100)
        audio.muted = false
        audio.volume = vol
      }
      setSound(!sound)
    }
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (audio) {
      const newValue = Number(e.target.value)
      setValue(newValue)
      if (newValue === 0) {
        setSound(false)
      } else {
        !sound && setSound(true)
      }

      setVol(newValue / 100)
      audio.volume = newValue / 100
    }
  }

  return (
    <Box w="1.75rem">
      <Box
        display="flex"
        gap="8px"
        className={isNotSmartphone ? 'isNotSmartphone' : ''}
        alignItems="center"
        w="1.75rem"
        overflow="hidden"
        _hover={{
          '&.isNotSmartphone': {
            w: '90px',
            '>input': {
              opacity: 1,
            },
          },
        }}
      >
        <BaseIcons onClick={onClick}>{sound ? <On /> : <Off />}</BaseIcons>
        <Box
          alignItems="center"
          appearance="none"
          as="input"
          background={`linear-gradient(to right, ${vars(
            'colors-main-deepSkyBlue'
          )} ${value}%, ${vars('colors-neutral-platinum')} 0)`}
          borderRadius={vars('radii-big')}
          cursor="pointer"
          display="flex"
          h="4px"
          max={100}
          min={0}
          type="range"
          value={value}
          onChange={onChange}
          opacity={0}
          w="50px"
          transition="all .2s ease-in-out"
          _hover={{
            '&::-webkit-slider-thumb': {
              bg: vars('colors-main-azureRadiance'),
              transform: 'scale(1.15)',
              transition: 'all .3s',
            },
          }}
          sx={{
            '&::-webkit-slider-thumb': {
              appearance: 'none',
              w: '14px',
              borderWidth: 0,
              borderRadius: vars('radii-rounded'),
              h: '14px',
              bg: vars('colors-main-deepSkyBlue'),
            },
          }}
        />
      </Box>
    </Box>
  )
}
