import { useTranslation } from 'react-i18next'
import { TinyAlert } from '@eclass/ui-kit'
import { useLocation } from 'react-router-dom'

import * as Style from './Style'
import { UIVars } from 'app/Components/UI'
import { Icon } from 'app/Components/UI/Icons'
import { QuestionAlert } from '../../QuestionAlert/QuestionAlert'
import { ContentParser } from 'app/Components'
import useExercise from '../../../../Exercise/Practice/useExercise'
import { LabelOmitted } from '../../LabelOmitted'
import { cleanLineBreaks } from './cleanLineBreaks'

const Enunciated = ({
  answer,
  position,
  sideInfo,
  id,
  hasLevelsStages,
  missingAnswers,
  evaluationState,
}: any) => {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const {
    state: { answers, seeCorrect, deleteIncorrect, embeds },
  } = useExercise()
  const isResource = pathname.includes('resource/view')

  let status = ''
  let show = false
  let correct = false

  if (answers && answers.length > 0) {
    const answersActive = answers.filter((item: any) => item.answerId === id)
    if (answersActive[0]) {
      const { correct: correctActive, answered } = answersActive[0]
      correct = correctActive
      show = answered && !seeCorrect && !deleteIncorrect
      status = show ? (correct ? 'correct' : 'error') : ''
    }
  }

  return (
    <Style.Enunciated className={`enunciated ${status}`}>
      {show && (
        <Icon
          name={correct ? 'circularCheck' : 'circularError'}
          fill={correct ? UIVars.setColors.checkGreen : UIVars.setColors.checkError}
          height={20}
          width={20}
        />
      )}
      {!answer.config?.mandatory && !isResource && sideInfo && (
        <TinyAlert status="warning" text={t('ResourceOptimalQuestion')} margin="0 0 4px" />
      )}
      <LabelOmitted answer={answer.state!} evaluation={evaluationState} isExercise={!sideInfo} />
      <Style.Question>
        {!sideInfo && <span>{position}.</span>}
        <div className="question exerciseQuestion">
          <QuestionAlert show={(hasLevelsStages && missingAnswers)!} answer={answer} />
          {ContentParser({
            content: cleanLineBreaks(answer.instructions),
            embeds: embeds || answer.embeds,
          })}
        </div>
      </Style.Question>
    </Style.Enunciated>
  )
}

export default Enunciated
