import styled, { createGlobalStyle } from 'styled-components'
import { UIVars } from 'app/Components/UI'

export const StyleDownload = {
  Message: styled.div`
    color: ${UIVars.setColors.black};
    padding: 25px 15px 0;
    text-align: center;
    h4 {
      font-size: 20px;
      font-weight: bold;
      line-height: 23px;
      margin-top: 16px;
    }
    p {
      font-size: 14px;
      padding: 0px 15px;
    }
  `,
  Wrapper: createGlobalStyle`
    .modal-delete{
      width: 387px !important;
      height: 174px !important;
      padding: 0 !important;
      .modal-content{
        height: 100%;
        border-radius: 10px !important;
        box-shadow: 0 5px 10px 0 rgba(199, 199, 199, 0.5);
      }
      .close-modal{
        display: none;
      }
      .modal-body{
        padding: 0 15px;
        div{
        }
      }

      @media screen and (max-width: 768px) {
        margin: 20px !important;
        height: 174px !important;
        width: auto !important;

      }
    }
  `,
}
