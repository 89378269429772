import React, { useCallback } from 'react'

import { ContentParser } from 'app/Components'
import * as UI from 'app/Components/UI'
import * as Style from './Style'
import useExercise from 'app/Views/Exercise/Practice/useExercise'
import { COLOR } from '../../IconStatus'
import { Icon } from 'app/Components/UI/Icons'
import { useTypeProfile } from '../useTypeProfile'

export const SelectInTheBlank = (props: any) => {
  const { optionDisabled } = props
  const { isEditor } = useTypeProfile()
  const {
    state: { answers, answered, seeCorrect, hint, send },
    dispatch,
  } = useExercise()

  const question = JSON.parse(props.excercises.Answer.content)

  const handleRender = useCallback(
    (node: any, index: number) => {
      const regex = '___REPLACE_SITB'
      if (node.includes(regex)) {
        const handleInput = (e: any) => {
          dispatch({
            answerId: index,
            value: Number(e.target.value),
          })
        }
        const answersSelect = answers[index]
        const name = `${regex}___${index}`
        let correct = answersSelect?.correct
        let color = correct ? COLOR.success : COLOR.error
        if (seeCorrect) {
          color = COLOR.success
          correct = true
        }
        if (answered || seeCorrect) {
          return (
            <Style.Validate color={color} key={name}>
              <Icon
                name={correct ? 'circularCheck' : 'circularError'}
                fill={color}
                height={20}
                width={20}
              />
              {seeCorrect ? (
                <ul>
                  {hint?.[index]?.values?.length &&
                    hint[index].values.map((option: any) => {
                      return option.correct ? <li key={option.text}>{option.text}</li> : null
                    })}
                </ul>
              ) : (
                <div>{hint?.[index]?.values?.[answersSelect?.value]?.text}</div>
              )}
            </Style.Validate>
          )
        }
        return (
          <UI.Select
            size="xs"
            className={'sitb'}
            inputSize={12}
            key={name}
            disabled={(send.status || optionDisabled) && !isEditor}
            name={name}
            options={question.inputs[index].options}
            onChange={handleInput}
            select={answersSelect.value === null ? undefined : answersSelect.value}
            placeholderEmpty
          />
        )
      }
      return null
    },
    [question.inputs, answers, dispatch, seeCorrect, answered, hint, send, optionDisabled, isEditor]
  )

  return (
    <Style.Wrapper>
      {ContentParser({
        content:
          question.text
            .replace(/<div><p><br><\/p><\/div>/g, '')
            .replace(/<div><br><\/div>/g, '')
            .replace(/<div><\/div>/g, '')
            .replace(/<p><br><\/p>/g, '') || '',
        embeds: props.excercises.Answer.embeds,
        other: {
          type: 'SITB',
          handleRender,
        },
      })}
    </Style.Wrapper>
  )
}
