import { gql, useQuery, QueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

export const GET_RESOURCE_REQUIREMENTS = gql`
  ${STATUS_FIELDS}
  query getResourceRequirements($ResourceRequirementsInput: ResourceRequirementsInput!) {
    getResourceRequirements(input: $ResourceRequirementsInput) {
      status {
        ...StatusFields
      }
      Info {
        title
        canAccess
        href
        Requirements {
          name
          href
          status {
            id
            name
            enabled
          }
        }
      }
      Menu {
        Navigation {
          next {
            href
            name
            status {
              enabled
              style
            }
          }
          prev {
            href
            name
            status {
              enabled
              style
            }
          }
        }
        Links {
          id
          name
          href
          indicators {
            id
            name
            image
            obtained
            max
          }
          status {
            id
            name
            detail
            style
            enabled
          }
          Childrens {
            id
            name
            href
            status {
              id
              name
              detail
              style
              enabled
            }
            indicators {
              id
              name
              image
              obtained
              max
            }
          }
        }
        Unit {
          id
          name
          title
        }
      }
    }
  }
`

export type ResourceRequirementsPayload = Api.ResourceRequirementsPayload

export type ResourceRequirementsInput = Api.ResourceRequirementsInput

interface ResourceRequirementsPayloadApollo {
  getResourceRequirements: ResourceRequirementsPayload
}

interface ResourceRequirementsPayloadInputApollo {
  ResourceRequirementsInput: ResourceRequirementsInput
}

export const useQueryGetResourceRequirements = (
  options?: QueryHookOptions<
    ResourceRequirementsPayloadApollo,
    ResourceRequirementsPayloadInputApollo
  >
) => {
  return useQuery<ResourceRequirementsPayloadApollo, ResourceRequirementsPayloadInputApollo>(
    GET_RESOURCE_REQUIREMENTS,
    options
  )
}
