import { UIVars } from 'app/Components/UI'
import * as type from '../types'

export const Cross = ({
  className = '',
  fill = UIVars.setColors.iconFill,
  height = 19,
  width = 19,
}: type.basic) => {
  return (
    <svg
      data-testid="icon-cross"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={`0 0 ${width} ${height}`}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M18.385 2.121L16.263 0l-7.07 7.071L2.12 0 0 2.121l7.071 7.071L0 16.264l2.121 2.12 7.071-7.07 7.072 7.07 2.12-2.12-7.07-7.072 7.07-7.07z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}
