import React from 'react'
import styled from 'styled-components/macro'

type Props = {
  size?: number
  insideGrid?: number
  children: any
  inside?: boolean
  hasLevelsStages?: boolean
}

type PropsStyle = {
  inside: boolean
}

const Grid = ({ size = 1, insideGrid = 1, children, inside = false, hasLevelsStages }: Props) => {
  const full = size === 3 || insideGrid === 3 ? 'grid-full' : ''
  return (
    <Style
      className={`grid grid-${size} ${full} ${hasLevelsStages && 'hasLevelsStages'}`}
      inside={inside}
    >
      {children}
    </Style>
  )
}

const GAP = 70
export const PADDING3 = (GAP * 2) / 3 // padding 3 columnas
export const PADDING2 = GAP / 2 // padding 2 columnas
export const WIDTH_COLUMN_3 = 1000

const Style = styled.div<PropsStyle>`
  left: 50%;
  position: relative;
  transform: translateX(-50%);

  &.hasLevelsStages {
    > .alternatives {
      border-bottom: 1px solid #c6c6c6;
    }
  }

  &.grid-full {
    min-width: ${WIDTH_COLUMN_3}px;
  }

  &.grid {
    > li,
    > div {
      margin-bottom: 16px;
      /* padding-bottom: 16px; */
      :last-child {
        margin-bottom: 48px;
        padding-bottom: 0;
      }
      &:not(:last-child) {
        &.Reorder {
          border-bottom: 1px solid #e6e6e6;
          padding-bottom: 22px;
        }
      }
      &:first-child {
        &.Reorder {
          padding-top: 16px;
        }
      }
    }
  }

  &.grid-2,
  &.grid-3 {
    display: flex;
    flex-wrap: wrap;
    > li,
    > div {
      border-bottom: none;
    }
  }
  &.grid-2 {
    > li,
    > div {
      width: 50%;
      &:nth-child(odd) {
        padding-right: ${PADDING2}px;
      }
      &:nth-child(even) {
        padding-left: ${PADDING2}px;
      }
    }
  }
  &.grid-3 {
    padding: 0 15px;
    > li,
    > div {
      width: 33.3333%;
      &:nth-child(1),
      &:nth-child(3n - 2) {
        padding-left: 0;
        padding-right: ${PADDING3}px;
      }
      &:nth-child(2),
      &:nth-child(3n - 1) {
        padding-left: ${PADDING3 / 2}px;
        padding-right: ${PADDING3 / 2}px;
      }
      &:nth-child(3),
      &:nth-child(3n) {
        padding-left: ${PADDING3}px;
        padding-right: 0;
      }
    }
  }

  @media screen and (max-width: ${WIDTH_COLUMN_3}px) {
    &.grid-full {
      min-width: 100%;
      padding: 0;
    }
    &.grid-3 {
      > li,
      > div {
        width: 50%;
        &:nth-child(odd) {
          padding-left: 0;
          padding-right: ${PADDING2}px;
        }
        &:nth-child(even) {
          padding-left: ${PADDING2}px;
          padding-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding: 0 15px;
    .grid {
      padding: 0;
    }
    &.grid-full {
      padding: 0 15px;
    }
  }

  @media screen and (max-width: 640px) {
    padding-left: 0;
    padding-right: 0;
    &.grid-full {
      padding-left: 0;
      padding-right: 0;
    }
    &.grid {
      > li,
      > div {
        padding-left: 0;
        padding-right: 0;
        &:nth-child(1n + 1) {
          padding-left: 15px;
          padding-right: 15px;
        }
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }

    &.grid-2,
    &.grid-3 {
      > li,
      > div {
        width: 100%;
        &:last-child {
          border-bottom: none;
        }
      }
    }
    .alternatives {
      .form-group {
        display: grid;
      }
    }
    .grid-lastchild {
      .form-group {
        margin-bottom: 0;
      }
    }
  }
`

export default Grid
