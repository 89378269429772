import { Types } from './types'

export const initialValue: Types.EvaluationCreatePayload = {
  status: { success: false, name: 'Initial State', detail: '', code: 0 },
  Alerts: [],
  Evaluation: {
    qualification: {
      value: 0,
      valueFormatted: '',
      status: { id: 0, name: '', detail: '', style: '' },
    },
    config: {
      id: 0,
      testId: 0,
      testTypeId: 2, // control
      hasTime: 100,
      showQualification: false,
      indicator: {
        name: '',
        image: '',
      },
      timeRemaining: 100,
      ludic: false,
      state: 0,
      showAnswers: false,
      score: { total: 0, obtained: 0 },
      repeat: {
        hideButton: false,
        total: 0,
        attempt: 0,
        criterial: '',
      },
      startDate: new Date('0000-00-00T00:00:00.000Z'),
      releaseDate: new Date('0000-00-00T00:00:00.000Z'),
      endDate: new Date('0000-00-00T00:00:00.000Z'),
      completedDate: new Date('0000-00-00T00:00:00.000Z'),
      daysRemaining: 0,
      folio: '',
      completed: false,
      hasRevision: false,
      showFeedback: false,
      canAnswer: true,
    },
    header: {
      name: '',
    },
    Levels: [
      {
        id: 0,
        name: '',
        Stages: [
          {
            id: 0,
            name: '',
            Answers: [
              {
                instructions: '',
                content: '',
                answer: '',
                embeds: [],
                loading: false,
                config: {
                  id: 0,
                  evaluationId: 0,
                  levelId: 0,
                  stageId: 0,
                  mandatory: false,
                  sortOrder: 0,
                  ItemType: { id: 0, name: '' },
                  // @ts-ignore
                  state: 0,
                },
                feedback: {
                  correct: false,
                  score: { total: 0, obtained: 0 },
                  skipped: false,
                  text: '',
                  status: {
                    id: 0,
                    enabled: false,
                    name: '',
                    detail: '',
                    style: '',
                    date: new Date(),
                  },
                },
              },
            ],
          },
        ],
      },
    ],
    Answers: [],
    navigation: {
      prev: {
        name: '',
        href: '',
        type: { id: 0, title: '' },
        status: { id: 0, enabled: false, detail: '', style: '' },
      },
      next: {
        name: '',
        href: '',
        type: { id: 0, title: '' },
        status: { id: 0, enabled: false, detail: '', style: '' },
      },
    },
  },
  // @ts-ignore
  Answers: [],
}
