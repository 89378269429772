import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import * as Apollo from 'app/Apollo'
import { checkFor } from 'app/Services'
import * as Types from './types'

export function useGetQuestionInfo() {
  const {
    courses: {
      getCourseInfo: { Course, Student },
    },
  } = useSelector((state: Types.Store) => state)
  const { courseId, programId, resourceId, studentId, unitId } = useParams<Types.RouteParams>()

  const { data, loading, refetch } = Apollo.useQueryGetQuestionInfo({
    variables: {
      QuestionInfoInput: {
        courseId: Number(courseId),
        programId: Number(programId),
        resourceId: Number(resourceId),
        studentId: Number(studentId),
        unitId: Number(unitId),
      },
    },
    skip:
      !Student?.dates?.canGenerateActivity ||
      !Course?.haveQuestions ||
      checkFor(
        { conditions: [null, undefined] },
        courseId,
        programId,
        resourceId,
        studentId,
        unitId
      ),
  })

  return {
    data,
    loading,
    refetch,
  }
}
