import { gql, useMutation, MutationHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const RANKING_ACHIEVEMENT = gql`
  ${STATUS_FIELDS}
  mutation RankingAchievement($RankingAchievementInput: RankingAchievementInput!) {
    RankingAchievement(input: $RankingAchievementInput) {
      status {
        ...StatusFields
      }
    }
  }
`

export type RankingAchievementPayload = Api.RankingAchievementPayload

export type RankingAchievementInput = Api.RankingAchievementInput

interface RankingAchievementPayloadApollo {
  RankingAchievement: RankingAchievementPayload
}

interface RankingAchievementInputApollo {
  RankingAchievementInput: RankingAchievementInput
}

export const useMutationRankingAchievement = (
  options?: MutationHookOptions<RankingAchievementPayloadApollo, RankingAchievementInputApollo>
) => {
  return useMutation<RankingAchievementPayloadApollo, RankingAchievementInputApollo>(
    RANKING_ACHIEVEMENT,
    options
  )
}
