import styled from 'styled-components/macro'
import UIVars from '../variables'

export const Wrapper = styled.div`
  align-self: center;
  color: ${UIVars.setColors.brownGreyTwo};
  flex-basis: 200px;
  flex-shrink: 0;
  font-size: 14px;
  a {
    align-items: center;
    color: currentColor;
    display: flex;
    :hover,
    :active {
      color: ${UIVars.setColors.brownGreyTwo};
    }
  }
  .Content {
    span {
      line-height: 14px;
      font-weight: 500;
      :hover :active {
        color: ${UIVars.setColors.brownGreyTwo};
      }
    }
  }
`
