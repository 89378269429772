import classNames from 'classnames'
import ReactHtmlParser from 'react-html-parser'

import { Props } from '../../types'
import './style.scss'

/**
 * @example
 *    <ImageBox config={options} id={61} />
 */
export const ImageBox = ({ config, id }: Props.ImageBox) => {
  const hasParagraph =
    config.text.replace(/<p><br><\/p>/g, '').replace(/<p>(&nbsp;)+<\/p>/gi, '').length > 0

  return (
    <section
      className={`breakerContainer ${classNames('Article__image_box', {
        round: config.style === 'circle',
        framed: config.style === 'framed',
      })} ${hasParagraph ? 'hasParagraph' : ''}`}
      data-testid="imageBox"
      key={id}
    >
      <figure>
        <img
          src={`https://cdn.eclass.com/${config.image}`}
          alt={`https://cdn.eclass.com/${config.image}`}
        />
      </figure>
      {hasParagraph && ReactHtmlParser(config.text)}
    </section>
  )
}
