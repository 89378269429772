import { ContentParser } from 'app/Components'
import { renderEmbeds } from 'app/Views/Resources/renderEmbeds'
import { Types } from './types'

/**
 * Convierto contenido del recurso en nodos HTML
 * @param datos {Types.pdfData}
 */
export const convertData = (datos: Types.pdfData[]) => {
  const finalResources = datos.map((item: Types.pdfData) => {
    return {
      content: ContentParser({
        content: item.Resource!.content.text,
        embeds: item.Resource!.content.embeds,
        other: {
          type: 'TEST',
          handleRender: renderEmbeds,
          evaluations: item.Evaluations,
        },
        data: item.Resource!.content,
      }),
      resource: item.Resource,
      footnotes: item.Resource.content.footnotes,
      definitions: item.Resource.content.definitions,
      config: item.Resource.config,
    }
  })
  return finalResources
}
