import React from 'react'
import { emojis } from './emojis'

/** Ambos ids corresponden a preguntas nps */
const NPS_ID_15 = 15
const NPS_ID_16 = 16

export const EmojiScale = ({ value, id }: { value: string; id: number }) => {
  const showEmojisNpsQuestion = [NPS_ID_15, NPS_ID_16].includes(id)

  const valueToNum = Number(value)
  return showEmojisNpsQuestion ? <span className="emoji">{emojis[valueToNum]?.emoji}</span> : <></>
}
