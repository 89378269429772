// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { axiosError, getToken } from '../../../Services'

/**
 * @typedef {Object} InputType
 * @property {number} studentId - Alumno
 * @property {number} courseId - Curso
 * @property {number} unitId - Unidad
 * @property {number} resourceId - Recurso
 * @property {number} programId - Programa
 */

/**
 * Obtiene los datos de una pregunta aplicada.
 *
 * @param {InputType} input - Parámetros para buscar los datos de la pregunta aplicada.
 * @example
 * ```js
 *    const input = {
 *      studentId: 111,
 *      courseId: 222,
 *      unitId: 333,
 *      resourceId: 444,
 *      programId: 555
 *    }
 *    const response = await getResource(input)
 * ```
 * @returns Retorna el Recurso con sus partes (status, Exercise, ExerciseAnswers).
 */

const getExerciseQuestion = (input) => {
  const requestId = 'getExerciseQuestion'
  return async (dispatch) => {
    getToken()
    const query = `
      query ExerciseGetAnswer($ExerciseGetAnswerInput: ExerciseGetAnswerInput!) {
        ExerciseGetAnswer(input: $ExerciseGetAnswerInput) {
          token
          status {
            success
            name
            detail
            code
          }
          CallToAction {
            href
          }
          Evaluation {
            Topics {
              id
              colour
              initials
              title
              description
              LearningGoals {
                id
                title
                description
              }
            }
            header {
              name
              description
              Type {
                id
                name
              }
            }
            config {
              id
              testId
              hasTime
              timeRemaining
              state
              showAnswers
              score {
                total
                obtained
              }
              completed
              hasRevision
              showFeedback
              canAnswer
              ludic
              indicator {
                id
                name
                image
                obtained
                max
              }
            }
            Levels {
              id
              name
              Stages {
                id
                name
                Answers {
                  state
                  config {
                    id
                    sortOrder
                  }
                  url {
                    name
                    href
                  }
                }
              }
            }
          }
          Answer {
            config {
              id
              name
              featured
              evaluationId
              ItemType {
                id
                name
              }
              mandatory
              indicator {
                id
                name
                image
                obtained
                max
              }
              WordBank
              columns
              itemsInColumns
              answersInHorizontal
            }
            feedback {
              correct
              hint
              skipped
              score {
                total
                obtained
              }
              corrects {
                total
                obtained
              }
              correct
              text
              hint
              status {
                id
                enabled
                name
                detail
                style
              }
            }
            embeds {
              id
              name
              configurations
              typeId
            }
            AdditionalInformation {
              pre {
                title
                content
              }
              post {
                title
                content
              }
            }
            answered
            instructions
            content
            answer
            Translations {
              locale
              field
              content
              default
            }
          }
        }
      }
    `
    const variables = {
      ExerciseGetAnswerInput: {
        studentId: parseInt(input.studentId, 10),
        courseId: parseInt(input.courseId, 10),
        unitId: parseInt(input.unitId, 10),
        resourceId: parseInt(input.resourceId, 10),
        programId: parseInt(input.programId, 10),
        calcMenu: false,
      },
    }
    if (input.answerId) {
      variables.ExerciseGetAnswerInput.answerId = parseInt(input.answerId, 10)
    }
    if (input.repeat) {
      variables.ExerciseGetAnswerInput.repeat = input.repeat
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then((response) => response.data.data.ExerciseGetAnswer)
      .catch((err) => axiosError(err, requestId, dispatch))
  }
}

export default getExerciseQuestion
