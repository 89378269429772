import { ValidationTypes } from './types'

const validateInput = (
  typeValidation: ValidationTypes,
  newValue: string,
  validation: (regex: any, value: any, message: string) => void,
  t: (key: string) => string
) => {
  switch (typeValidation) {
    case 'phone':
      // en esta expresión regular el + es opcional (?), acepta del 0-9 entre 9 y 10 caracteres
      return validation('^([+])?([0-9]{9,13})$', newValue, t('ProfileOnlyNumbers11'))
    case 'numbers':
      // Sólo acepta números.
      return validation('^[0-9]*$', newValue, t('ProfileOnlyNumbers'))
    case 'email':
      // en esta expresión regular la "@" es obligatoria y caracteres afabeticos seguidos de un punto
      return validation('^.+@[^.].*.[a-z]{2}$', newValue, t('ProfileValidMail'))
    case 'alphabetic':
      // en esta acepta de la a-z mayuscula minuscula tildes y espacios sin limite
      return validation('^[a-zA-ZñÑáéíóúÁÉÍÓÚ\\s]+$', newValue, t('CrossOnlyLetters'))
    case 'alphanumeric':
      // esta expresión acepta mayus min a-z números 0-9, simbolos espacios y tildes sin limite
      return validation(
        '^[a-zA-Z0-9!@#$&()\\-`.+,/"ñÑáéíóúÁÉÍÓÚ\\s]+$',
        newValue,
        t('ResourceErrorTryAgain')
      )
    case 'pass':
      // esta expresión acepta mayus min a-z  simbolos específicos espacios sin límite
      return validation('^[a-zA-Z0-9!@#$%&·()“”\\-`.+,/"]+$', newValue, t('ResourceErrorTryAgain'))
    case 'novalidate':
      return newValue
    default:
      return null
  }
}

export default validateInput
