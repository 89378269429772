import { memo, useContext } from 'react'
import { Box } from '@chakra-ui/react'
import {
  startOfWeek,
  addDays,
  format,
  setHours,
  setMinutes,
  setSeconds,
  setMilliseconds,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useTranslation } from 'react-i18next'
import { vars } from '@eclass/ui-kit'

import { useDateFormat, dateFormat as dateFormatService } from 'app/Services/dateFormat'
import { ContextTimeZone } from 'app/Components/AppFrame/ProviderTimeZone'

/**
 * Horario de atención con horas en UTC del usuario
 */
export const AttentionTimeSchedule = memo(() => {
  const { dateFormat } = useDateFormat()
  const { t } = useTranslation()
  const TZ = useContext(ContextTimeZone)

  /**
   * Devuelve un string con la fecha del lunes/viernes de esta semana con la hora de inicio/fin correspondiente
   */
  const getDate = (day: 'MONDAY' | 'FRIDAY') => {
    const monday = startOfWeek(new Date().getTime(), { weekStartsOn: 1 })
    const days = {
      MONDAY: {
        date: monday,
        hour: 8, // hora chile
      },
      FRIDAY: {
        date: addDays(monday, 4),
        hour: 19, // hora chile
      },
    }

    // cambio a hora definida
    const hours = setHours(days[day].date, days[day].hour)
    const minutes = setMinutes(hours, 0)
    const seconds = setSeconds(minutes, 0)
    const hoursCorrect = setMilliseconds(seconds, 0)

    // Armo string con la fecha y hora obtenida pero le sobrescribo que es hora chile
    const dateChile = `${format(hoursCorrect, 'yyyy-MM-dd')}T${format(
      hoursCorrect,
      'HH:mm'
    )}:00${dateFormatService({ date: new Date(), format: { free: 'XXX' } })}`

    // formateo hora a zona horaria 0
    const horaSinUTC = utcToZonedTime(dateChile, 'Africa/Dakar')

    // retorno formato esperado para pasarlo al hooks de la zona horaria
    return `${format(horaSinUTC, 'yyyy-MM-dd')}T${format(horaSinUTC, 'HH:mm')}:00.000Z`
  }

  /** Textos a mostrar */
  const getData = () => {
    const mondayString = getDate('MONDAY')
    const fridayString = getDate('FRIDAY')

    const end = dateFormat({ date: fridayString, format: { free: 'i' } })
    const ID_VIERNES = 5

    // si se pasa del viernes
    if (Number(end) > ID_VIERNES) {
      return t('CrossAttentionTimeScheduleRestricted', {
        initialDay: dateFormat({ date: mondayString, format: { free: 'EEEE' } }),
        initalHour: dateFormat({ date: mondayString, format: { free: 'HH:mm' } }),
        endHour: dateFormat({ date: fridayString, format: { free: `HH:mm 'hrs.'` } }),
      })
    }
    return t('CrossAttentionTimeSchedule', {
      initalHour: dateFormat({ date: mondayString, format: { free: 'HH:mm' } }),
      endHour: dateFormat({ date: fridayString, format: { free: `HH:mm 'hrs.'` } }),
    })
  }

  const text = getData()

  return (
    <Box>
      <Box
        as="p"
        fontSize="14px"
        m="11px 0 0"
        sx={{
          '&:first-letter': {
            textTransform: 'uppercase',
          },
        }}
      >
        {text}
      </Box>
      <Box as="p" fontSize="14px" m="8px 0 0" color={vars('colors-neutral-gray')}>
        {t('CrossAttentionTimeScheduleEdit')}{' '}
        <Box
          as="span"
          color={vars('colors-main-deepSkyBlue')}
          cursor="pointer"
          fontWeight={500}
          onClick={TZ.onOpen}
          textDecoration="underline"
        >
          {t('CrossAttentionTimeScheduleEditLink')}
        </Box>
      </Box>
    </Box>
  )
})
