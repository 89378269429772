import React from 'react'

import { ContentParser } from 'app/Components'
import { Feedback } from './components'
import { getArraySelected, getFields, RenderAnswer } from './services'
import './index.css'
import { TAnswer, IAnswered } from './types'

export const Cloze = (props) => {
  const content = props.answer?.instructions
  const answers = JSON.parse(props.answer?.content) as TAnswer[]
  const selected = getArraySelected(props.answer?.answer) as IAnswered[]

  const isComplete = props.evaluationState === 5
  const viewRevisor = props.evaluationState === 2

  const save = (configId: number, response: string | number) => {
    // Valido que todos esten respondidos y lo mando dentro de la opción,
    // para al momento de guardar siga el flujo de una pregunta normal solo si esta todo respondido
    let allAnswered = true
    const fields = getFields(props.id)
    if (fields) {
      // Verifica si todos los campos están completos
      allAnswered = !Array.from(fields).find((item) => !item.value)
    }

    props.action({
      id: props.id,
      option: JSON.stringify({ configId, response, allAnswered }),
      type: 'ANSWER',
    })
  }

  const handleRender = (node, index) => {
    const regex = '___REPLACE_CLOZE'
    if (node.includes(regex)) {
      return (
        <React.Fragment key={index}>
          <RenderAnswer
            index={index}
            answers={answers}
            save={save}
            selected={selected}
            viewRevisor={viewRevisor}
            isComplete={isComplete}
            propsLoading={props.answer.loading}
            config={props.answer}
            id={props.id}
          />
        </React.Fragment>
      )
    }
    return <></>
  }

  return (
    <section>
      <main className={`Question-Cloze cloze-${props.id}`}>
        {ContentParser({
          content,
          embeds: props.answer?.embeds || [],
          other: {
            type: 'CLOZE',
            handleRender,
          },
        })}
      </main>
      {isComplete && props.answer?.feedback && <Feedback content={props.answer.feedback.text} />}
    </section>
  )
}
