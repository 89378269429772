import styled, { css } from 'styled-components/macro'

import { layout } from '../Common'

export const Wrapper = styled.div`
  ${layout(css`
    .profile-addressTypeId {
      grid-area: addressTypeId;
    }

    .profile-countryId {
      grid-area: countryId;
    }

    .profile-regionId {
      grid-area: regionId;
    }

    .profile-locationId {
      grid-area: locationId;
    }

    .profile-address {
      grid-area: address;
    }

    .profile-number {
      grid-area: number;
    }

    .profile-block {
      grid-area: block;
    }

    .profile-apartment {
      grid-area: apartment;
    }

    .profile-private {
      grid-area: private;
    }

    grid-template-areas:
      'countryId regionId'
      'locationId .';

    &.profile {
      grid-template-areas:
        'addressTypeId .'
        'countryId regionId'
        'locationId locationId'
        'address number'
        'block apartment'
        'private private';
    }
  `)}
`
