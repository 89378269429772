import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Modal } from 'app/Components/UI'

export const IFrame = ({ src, height, placeholder, freeWidth }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { isMobile } = useSelector((state) => state.ui)

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }
  const isFreeWidth = freeWidth === '1'

  const styleGeneral = {
    marginLeft: 'calc(50% - 50vw)',
    marginRight: 'calc(50% - 50vw)',
    transform: 'translateX(calc(50vw - 50%))',
    width: isFreeWidth ? '940px' : '100%',
  }

  return (
    <>
      {isMobile ? (
        <div style={{ display: 'block', cursor: 'pointer' }} onClick={() => toggleModal()}>
          <img
            alt="placeholder_iframe"
            src={placeholder}
            style={{
              display: 'block',
              maxWidth: '100%',
              width: '100%',
              marginBottom: '32px',
            }}
          />
        </div>
      ) : (
        <div style={styleGeneral}>
          <iframe
            src={src}
            frameBorder="0"
            title="iframe_content"
            style={{
              marginBottom: '32px',
              height: `${height}px`,
              width: isFreeWidth ? '940px' : '100%',
            }}
          ></iframe>
        </div>
      )}
      <Modal type="iframe" isOpen={isOpen} toggle={toggleModal} closeModal={toggleModal}>
        <iframe
          src={src}
          frameBorder="0"
          title="iframe_content"
          style={{ height: '100%', width: '100%' }}
        ></iframe>
      </Modal>
    </>
  )
}
