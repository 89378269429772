export const HelpDropdown = (props) => {
  return (
    <g {...props} width="16" height="16" viewBox="0 0 16 16">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8 13C7.448 13 7 12.552 7 12C7 11.448 7.448 11 8 11C8.552 11 9 11.448 9 12C9 12.552 8.552 13 8 13ZM9.623 8.092C9.126 8.519 9 8.663 9 9C9 9.553 8.552 10 8 10C7.448 10 7 9.553 7 9C7 7.706 7.795 7.024 8.322 6.573C8.819 6.148 8.945 6.003 8.945 5.667C8.945 5.484 8.945 5 8.001 5C7.565 5.024 7.1 5.224 6.743 5.561C6.342 5.939 5.708 5.92 5.329 5.52C4.95 5.118 4.968 4.485 5.37 4.106C6.072 3.445 6.987 3.052 7.949 3.002H7.952C9.764 3.002 10.945 4.073 10.945 5.668C10.945 6.961 10.15 7.643 9.624 8.093L9.623 8.092Z"
          fill="white"
        />
      </svg>
    </g>
  )
}
