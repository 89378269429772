import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { StudentInstance } from '@eclass/sequelize-models'
import { Alert } from '@eclass/ui-kit'

import { Modal as UIModal } from 'app/Components/UI'
import { ClassTeacher } from 'app/Components/LiveClassDetails/TeacherAndDetails/TeacherBox/TeacherBox'
import { Required } from './TypeQuestion/Required'
import { Form } from './Form'
import { IQuestion } from '../types'
import { CEVReducer } from 'app/Redux/Reducers/CEV/types'

interface props {
  feedback: CEVReducer['feedback']
  closeModal: () => void
  show: boolean
  teacher: StudentInstance
  questions: IQuestion[]
  success: boolean
  setSuccess: (value: boolean) => void
  setSend: (value: boolean) => void
}

export const Modal = ({
  feedback,
  closeModal,
  show,
  teacher,
  questions,
  success,
  setSuccess,
  setSend,
}: props) => {
  const { t } = useTranslation()

  return (
    <UIModal
      className="Cev--Feedback"
      closeModal={() => !feedback.sending && closeModal()}
      fade={true}
      header={`Evaluar profesor`}
      isOpen={show}
      toggle={() => !feedback.sending && closeModal()}
      type={'full'}
    >
      <Box
        sx={{
          '.teacherInfo': { p: 0 },
          '.alert-info': { mb: '1.25rem' },
          '.e_alert': {
            width: '100%',
          },
        }}
      >
        <Alert state="info" m="0 0 16px 0">
          {t('CEVFeedbackAnonymous')}
          <br />
          <Required />
          {t('CEVFeedbackRequired')}
        </Alert>
        <ClassTeacher teachers={[teacher]} />
        <Form
          questions={questions}
          closed={closeModal}
          success={success}
          setSuccess={setSuccess}
          setSend={setSend}
        />
      </Box>
    </UIModal>
  )
}
