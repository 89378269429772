import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactHtmlParser from 'react-html-parser'

import { useDateFormat } from 'app/Services'
import { cvCalendar, cvReloj, cvTime, where, room, password } from 'assets/svg'

const PROFILE_ID_TEACHER = 2
const OFFLINE_LESSON = 0

interface IItem {
  icon: string
  classes: string
  description: string
  label?: string
  value: string | null
}

export const useData = (data) => {
  const { t } = useTranslation()
  const { dateFormat } = useDateFormat()

  const [teachers, setTeachers] = React.useState([])
  const [classDetails, setClassDetails] = React.useState([])

  const hasLatLon = (latitude, longitude) => latitude !== 0 || longitude !== 0
  const mapAddress = (latitude, longitude, address) => (
    <a
      href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {address}
    </a>
  )

  const formatAddress: IItem = {
    icon: where,
    classes: '',
    description: 'Dirección',
    label: t('F2FAddress'),
    value: null,
  }

  const formatRoom: IItem = {
    icon: room,
    classes: '',
    description: 'Sala',
    label: t('F2FRoom'),
    value: null,
  }

  const formatDate: IItem = {
    icon: cvCalendar,
    classes: 'detaildate',
    description: 'Fecha',
    value: null,
  }

  const formatDuration: IItem = {
    icon: cvReloj,
    classes: '',
    description: 'Duración',
    value: null,
  }

  const formatTime: IItem = {
    icon: cvTime,
    classes: '',
    description: 'Horario',
    label: t('F2FTime'),
    value: null,
  }

  const formatPassword: IItem = {
    icon: password,
    classes: '',
    description: 'Contraseña',
    label: t('F2FPassword'),
    value: null,
  }

  const transformData = React.useCallback(() => {
    let formatTeachers: any = []
    const formatClassDetails: any = []

    // Verifica si el request es de clase presencial
    if (data?.Lesson) {
      formatTeachers = data?.Teachers?.map((teacher) => ({
        id: teacher.id,
        fullName: teacher.Person?.fullName,
        picture: teacher.Person?.picture,
        profileId: teacher.Profile?.id,
      }))

      if (data.Lesson.remote === OFFLINE_LESSON) {
        if (data.Lesson?.Headquarter?.address) {
          const { lattitude, longitude, address } = data.Lesson.Headquarter

          if (hasLatLon(lattitude, longitude)) {
            formatAddress.value = mapAddress(lattitude, longitude, address) as any
          } else {
            formatAddress.value = address
          }

          formatClassDetails.push(formatAddress)
        }

        if (data.Lesson?.Headquarter.room) {
          formatRoom.value = data.Lesson.Headquarter.room
          formatClassDetails.push(formatRoom)
        }
      }

      if (data.Lesson?.date) {
        formatDate.label = t('CEVDate')
        formatDate.value = dateFormat({ date: data.Lesson?.startLessonTime, format: 'dmy' }) as any
        formatClassDetails.push(formatDate)
      }

      if (data.Lesson?.startLessonTime) {
        formatTime.value = dateFormat({ date: data.Lesson.startLessonTime, format: 'h' }) as any
        formatClassDetails.push(formatTime)
      }

      if (data.Lesson?.password) {
        formatPassword.value = data.Lesson.password
        formatClassDetails.push(formatPassword)
      }
    } else {
      const formatTeacher = {
        id: data?.Teacher?.id,
        fullName: `${data?.Teacher?.name} ${data?.Teacher?.firstSurname} ${data?.Teacher?.lastSurname}`,
        picture: data.Teacher?.picture || '',
        profileId: PROFILE_ID_TEACHER,
      }

      formatTeachers = [formatTeacher]

      if (data?.Unit?.messages?.date) {
        // detalle CEV
        // puede que en otro caso  validar
        formatDate.value = ReactHtmlParser(data.Unit.messages.date) as any
        if (data.Unit.messages.dateFull) {
          formatDate.label = t('CEVDate')
          formatDate.value = dateFormat({ date: data.Unit.messages.dateFull, format: 'dmy' })
        }
        formatClassDetails.push(formatDate)
      }

      if (data?.Unit?.messages?.duration) {
        formatDuration.label = t('CEVDurationLabel')
        formatDuration.value = ReactHtmlParser(data.Unit.messages.duration) as any
        formatClassDetails.push(formatDuration)
      }

      if (data?.Unit?.messages?.gmt) {
        // detalle CEV
        // puede que en otro caso  validar
        formatTime.value = ReactHtmlParser(data.Unit.messages.gmt) as any
        formatTime.label = ''
        if (data?.Unit?.messages?.dateFull) {
          formatTime.label = t('CEVHour')
          formatTime.value = dateFormat({ date: data.Unit.messages.dateFull, format: 'h' })
        }
        formatClassDetails.push(formatTime)
      }
    }

    setTeachers(formatTeachers)
    setClassDetails(formatClassDetails)
  }, [])

  React.useEffect(() => {
    transformData()
  }, [transformData])

  return {
    teachers,
    classDetails,
  }
}
