import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { RequestError } from 'app/Components'
import * as Types from '../types'
import * as Apollo from 'app/Apollo'
import { RouteParams } from 'Config/routes/types'
import { Store } from '../../../../Courses/types'

export const useSubmit = ({ questions, success, setSuccess, closed, setSend }: Types.IForm) => {
  const dispatch = useDispatch()
  const {
    cev: { feedback },
  } = useSelector((store: Store) => store)

  const methods = useForm({
    defaultValues: {},
    mode: 'onBlur',
  })

  const [isBloqued, setIsBloqued] = useState(true)
  const params = useParams<RouteParams>()

  const [mutationResourceMeetAddFeedback] = Apollo.useMutationResourceMeetAddFeedback({
    onCompleted: (dataCompleted) => {
      dispatch({ type: 'CEV_FEEDBACK_SENDING', payload: false })
      if (!dataCompleted || !dataCompleted.ResourceMeetAddFeedback.status.success) {
        RequestError.handle(dispatch, dataCompleted.ResourceMeetAddFeedback)
      } else {
        closed()

        // logica para mostrar alert, el setTimeout es para evitar que se muestre varias veces
        if (!success) {
          setSuccess(true)
          setSend(true)
          setTimeout(() => {
            setSuccess(false)
          }, 100)
        }
      }
    },
  })

  const onSubmit = (newParameters) => {
    if (!isBloqued) {
      const newQuestions: Types.QuestionAdd[] = Object.entries(newParameters).map((item) => ({
        questionId: Number(item[0].replace('question', '')),
        answer: `${item[1]}`,
      }))
      dispatch({ type: 'CEV_FEEDBACK_SENDING', payload: true })

      mutationResourceMeetAddFeedback({
        variables: {
          ResourceMeetAddFeedbackInput: {
            studentId: Number(params.studentId),
            courseId: Number(params.courseId),
            unitId: Number(params.unitId),
            resourceId: Number(params.resourceId),
            questions: newQuestions,
          },
        },
      })
    }
  }

  // validar que toda las preguntas requeridas esten contestadas
  const handlerAnswerEmpty = () => {
    // valido por tipo si el valor corresponde a una respuesta vacia
    const isEmpty = (value, type: Types.IQuestion['type']) => {
      if (type === 'text' && value === '') {
        return true
      } else if (type === 'star' && Number(value) === 0) {
        return true
      }
      return false
    }

    // recorro preguntas y solo guardo las requeridas que esten vacias
    const hasEmpty = questions.filter((question) => {
      if (question.isMandatory && isEmpty(methods.watch()[question.name!], question.type)) {
        return question
      }
      return undefined
    })

    // si no tengo vacias paso a desbloquear boton de submit
    if (hasEmpty.length === 0 && isBloqued) {
      setIsBloqued(false)
    }
  }

  useEffect(() => {
    handlerAnswerEmpty()
  }, [methods.watch])

  return {
    methods,
    isBloqued,
    onSubmit,
    sending: feedback.sending || false,
  }
}
