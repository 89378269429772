export const Comment2 = (props) => {
  return (
    <g {...props}>
      <path
        fill="#0189FF"
        d="M6.5 0C2.91 0 0 2.462 0 5.5c0 1.075.37 2.074 1 2.922V12l2.699-1.542c.89.36 1.841.543 2.801.542 3.59 0 6.5-2.462 6.5-5.5S10.09 0 6.5 0Z"
      />
      <path
        fill="#0189FF"
        d="M16 9.5c0-.987-.429-1.897-1.147-2.639-.729 3.487-4.193 6.14-8.353 6.14-.103 0-.202-.019-.305-.022C7.231 13.617 8.556 14 10 14c.45 0 .886-.04 1.307-.11L15 16v-4h-.012c.64-.715 1.012-1.575 1.012-2.5Z"
      />
    </g>
  )
}
