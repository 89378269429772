import { FormControl, FormLabel } from '@chakra-ui/react'
import { useFormContext, Controller } from 'react-hook-form'

import * as Types from '../types'
import { TypesQuestion } from './TypeQuestion'
import { Required } from './TypeQuestion/Required'

interface props {
  data: Types.IQuestion
}

export const Question = ({ data }: props) => {
  const { control } = useFormContext()

  const name = data.name!

  const defaulValue = () => {
    switch (data.type) {
      case 'star':
        return 0
      case 'text':
        return ''
      default:
        return ''
    }
  }

  return (
    <FormControl data-testid="CEVQuestion">
      <FormLabel htmlFor={name}>
        {data.isMandatory && <Required />}
        {data.title}
      </FormLabel>
      <Controller
        control={control}
        defaultValue={defaulValue}
        name={name}
        render={({ value, onChange }) => (
          <TypesQuestion
            value={value}
            onChange={onChange}
            name={name}
            type={data.type}
            limit={data.range}
          />
        )}
        rules={{ required: data.isMandatory }}
      />
    </FormControl>
  )
}
