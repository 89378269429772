export const alternatives = 2
export const text = 3
export const select = 5
export const scale = 6
export const file = 8
export const freeWrite = 11
export const textAndMultimedia = 12
export const multipleChoice = 14
export const multipleAnswer = 15
export const typeIn = 17
export const selectInTheBlank = 18
export const reorder = 21
export const multipleChoiceTest = 23
export const close = 24
