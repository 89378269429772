import styled from 'styled-components/macro'

import { UIVars } from 'app/Components/UI'
import { Types } from './types'

export const Header = styled.header`
  background-color: ${UIVars.setColors.whiteTwo};
  width: 100%;

  /* &.onComments {
    height: 90px;
  }

  @media screen and (max-width: 768px) {
    &.onComments {
      height: 120px;
    }
  } */
`

export const Wrapper = styled.div<Types.StyleWrapper>`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 15px;
  position: relative;
  width: 100%;

  button {
    position: absolute;
    left: 0;
    padding: 0;
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;
    padding: ${({ back }: Types.StyleWrapper) => (back ? '20px' : '15')} 0;
  }
`

export const Title = styled.div`
  align-items: center;
  display: flex;

  @media screen and (max-width: 640px) {
    margin: 0 auto;
  }
`
export const Icon = styled.div`
  margin-right: 1rem;
  svg {
    height: 45px;
  }
`

export const H1 = styled.h1`
  color: ${UIVars.setColors.blackThree};
  font-size: 24px;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 0;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`

export const Align = styled.div`
  display: flex;

  > button {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 640px) {
    display: contents;

    > button {
      align-self: flex-start;
      position: inherit;
    }
  }
`
