import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Alert } from '@eclass/ui-kit'
import { Box } from '@chakra-ui/react'

import { ContentParser } from 'app/Components'
import { useIsProvisional } from '../hooks/useIsProvisional'

interface props {
  margin?: string
  maxWidth?: string
}
/**
 * Si el Alumno es provisorio se muestra un alert informativo con su estado.
 *
 * Para conocer si es provisorio se valida desde la store de redux.
 *
 * courses.getCourseInfo.Student?.provisional
 */
export const Information = ({ margin = '0', maxWidth = '57.875rem' }: props) => {
  const { isProvisional } = useIsProvisional()
  const { t } = useTranslation()
  const { pathname } = useLocation()

  // solo aparece en estas urls
  const enabledViews = ['units', 'helper', 'calendar']
  const showView = enabledViews.filter((view) => pathname.includes(view))
  const isCEVSchedule = pathname.includes('resource/calendar')

  if (!isProvisional || (isProvisional && showView.length === 0)) return null

  return (
    <Box
      data-testid="provisionalInformation"
      sx={{
        '.e_alert': {
          p: {
            marginBottom: '0',
          },
        },
      }}
    >
      <Alert state="warning" m={isCEVSchedule ? '2.5rem auto' : '2.5rem auto 0px'}>
        {ContentParser({
          content: isCEVSchedule ? t('CEVProvisionalAlert') : t('ContentProvisionalAlert'),
        })}
      </Alert>
    </Box>
  )
}
