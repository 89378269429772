import { EvaluationCreatePayload, RequestStatus } from '@eclass/api'

import { testsStates } from '../../testsStates'
import { updateAnswer } from './updateAnswer'
import { stateQuestion } from '../../helpers/stateQuestion'
import { Props } from './'
import { fieldDisabled } from '../../Questions/QuestionTypes/Cloze/services/fieldDisabled'

/**
 * Luego de que se responda una pregunta disparamos este evento para actualizar el estado de la pregunta.
 * Pasamos state = 1, answered.
 *
 * Buscamos todas las preguntas y solo actualizamos la que fue respondida en el nivel/etapa.
 */
const answered = (
  test: EvaluationCreatePayload,
  levelId: number,
  stageId: number,
  id: number,
  status: RequestStatus,
  skipped: boolean,
  params: Props['params'],
  extra: Props['extra']
) => {
  const { code, success } = status

  // Actualizo datos de pregunta seleccionada en Levels y Answer
  test.Evaluation! = updateAnswer({
    id,
    levelId,
    Evaluation: test.Evaluation!,
    setAnswer,
  })

  /**
   * Datos a actualizar en la pregunta
   * @param currentAnswer {any[]}
   */
  function setAnswer(answer: any) {
    if (answer.option === 'removeFile') {
      return {
        ...answer,
        state: stateQuestion.INITIAL,
        loading: false,
      }
    }

    let newState = answer.state
    if (answer.config.ItemType?.id === 24) {
      // si es cloze, valido que todo este respondido si es así permito el revisar/finalizar
      newState = extra?.pendingAnswers?.pending === 0 ? 1 : 0
    }

    fieldDisabled({
      configClose: answer.config,
      answerRequest: params?.response,
      disabled: false,
    })

    return {
      ...answer,
      state: newState,
      errorSave: false,
      loading: false,
    }
  }

  /**
   * Si está OK la respuesta, pero el tiempo expiró entonces:
   * - Marcamos como completada la Prueba y estado 3 (Finalizada) para ver el mini resumen.
   */
  if (success && code === 201) {
    test.Evaluation!.config.state = testsStates.FINISHED

    test.Evaluation!.config.completed = true
  }

  return { ...test }
}

export default answered
