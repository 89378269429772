import { useEffect } from 'react'
import { ZendeskAPI } from 'react-zendesk'

import config from '../../../../Config/domains'
import { restrictedView } from './restrictedView'

/**
 * Valido que el chatbot de Zendesk se debe mostrar en la ruta actual,
 * si no es así lo oculto de caso contrario lo dejo visible
 */
export const useZendesk = () => {
  const active = config.keyZendesk && config.keyZendesk.length > 0

  useEffect(() => {
    if (active) {
      if (restrictedView()) {
        ZendeskAPI('messenger', 'hide')
      } else {
        ZendeskAPI('messenger', 'show')
      }
    }
  }, [active, restrictedView])
}
