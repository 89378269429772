import { Box, useMediaQuery } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { HelpCenter } from '../../HelpCenter/HelpCenter'
import { HiddenBackground } from './HiddenBackground'

export const MenuItemHelpCenter = () => {
  const [isMobile] = useMediaQuery('(max-width: 480px)')
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const openDrop = document.getElementsByClassName('openDrop')
    setIsOpen(openDrop.length === 1 && isMobile)
  }, [])
  return (
    <Box
      sx={{
        '.coordinatorBox': {
          borderRight: '1px solid #e6e6e6',
          height: '100%',
          padding: '32px 0 0 0',
          width: '388px',

          '> article': {
            padding: '0',
          },
        },
        '.frequentQuestionsBox': {
          padding: '32px 0 0 0',
        },

        '.HelpCenter': {
          '@media screen and (max-width: 768px)': {
            display: 'flex',
            flexFlow: 'column',
            '.coordinatorBox, .frequentQuestionsBox': {
              width: '375px',
            },
            '.frequentQuestionsBox': {
              borderTop: '1px solid #e6e6e6',
            },
          },
          '@media screen and (max-width: 480px)': {
            '.coordinatorBox, .frequentQuestionsBox': {
              width: '100%',
            },

            article: {
              maxWidth: '100%',
            },
            '.CoordinatorLink': {
              margin: 'auto -28px',
            },
          },
        },
      }}
    >
      {isOpen && <HiddenBackground />}

      <HelpCenter type="dropdown" />
    </Box>
  )
}
