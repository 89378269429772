import React, { useState, useEffect } from 'react'
import { FormGroup } from 'reactstrap'
import { useSelector } from 'react-redux'

import { iOptions } from '../../types'
import './style.scss'
import { DesktopOptions } from './DesktopOptions'
import { MobileOptions } from './MobileOptions'
import { valueEmpty } from 'app/Views/Tests/helpers/valueEmpty'
import { useViewDownload } from 'app/Views/Resources/PDF'

export const ScaleOptions = ({ answer, action, optionDisabled, evaluationState }: iOptions) => {
  const isMobile = useSelector((state: any) => state.ui.isMobile)
  const isViewDownload = useViewDownload()

  const options = JSON.parse(answer.content!)
  const [selectedOption, setSelectedOption] = useState('')

  const changeOption = (id: number, state: number, option: string) => {
    if (state !== 5) {
      setSelectedOption(option)
      action({
        id,
        option,
        type: 'ANSWER',
      })
    }
  }

  useEffect(() => {
    if (valueEmpty(evaluationState, answer.state!)) {
      if (answer.option) {
        setSelectedOption(answer.option)
      } else if (answer.answer!.length > 0) {
        setSelectedOption(answer.answer!)
      }
    }
  }, [selectedOption, answer, evaluationState])

  // en la versión PDF el render debe ser el de desktop

  return (
    <div className="QuestionScale Options">
      <FormGroup tag="fieldset">
        <FormGroup check>
          {isMobile && !isViewDownload ? (
            <MobileOptions
              answer={answer}
              changeOption={changeOption}
              selectedOption={selectedOption}
              options={options}
              optionDisabled={optionDisabled}
            />
          ) : (
            <DesktopOptions
              answer={answer}
              changeOption={changeOption}
              selectedOption={selectedOption}
              options={options}
              optionDisabled={optionDisabled}
            />
          )}
        </FormGroup>
      </FormGroup>
    </div>
  )
}
