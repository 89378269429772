import styled, { css, SimpleInterpolation } from 'styled-components/macro'
import { UIVars } from 'app/Components/UI'

/**
 * ### Wrapper con los datos base para los layout de los formularios
 * @param templeateAreas {SimpleInterpolation} Estilo que corresponden a la version desktop. Debe tener el grid-template-areas correspondiente, con los nombres de las secciones
 * @param mobile {SimpleInterpolation} Estilo que corresponden a la version mobile
 */
export function layout(templeateAreas: SimpleInterpolation, mobile?: SimpleInterpolation) {
  return css`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 30px;

    ${mobile}

    &.noJob {
      max-height: 17px;
      overflow-y: hidden;
    }

    @media screen and (min-width: 640px) {
      column-gap: 20px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      row-gap: 30px;

      ${templeateAreas}
    }
  `
}

export const Error = styled.span`
  color: ${UIVars.setColors.red};
  display: flex;
  font: 12px / 16px ${UIVars.fontRoboto};
  margin-top: 5px;
`
