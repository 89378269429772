import styled from 'styled-components/macro'
import { UIVars } from 'app/Components/UI'

export const Wrapper = styled.div`
  background-color: ${UIVars.setColors.whiteTwo};
  border-radius: 5px;
  display: flex;
  padding: 15px 20px;
  margin-bottom: 30px;
  @media screen and (max-width: 440px) {
    flex-direction: column;
  }
`

export const Refresh = styled.span`
  align-self: center;
  color: ${UIVars.setColors.primary};
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
  font-weight: 500;
  margin-left: 20px;
  @media screen and (max-width: 440px) {
    align-self: start;
    margin: 0;
  }
`
