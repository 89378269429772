export const BigStar = (props) => {
  return (
    <g {...props} fillRule="nonzero">
      <path
        d="M20 4.14917L25.15 14.585L36.6666 16.2583L28.3333 24.3808L30.3008 35.8508L20 30.4358L9.69915 35.8508L11.6666 24.3808L3.33331 16.2583L14.85 14.585L20 4.14917Z"
        stroke="#90A1B0"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </g>
  )
}
