import { UIVars } from 'app/Components/UI'
import * as type from '../types'

export const Stop = ({
  className = '',
  fill = UIVars.setColors.iconFill,
  height = 22,
  width = 22,
}: type.basic) => {
  return (
    <svg
      data-testid="icon-stop"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={`0 0 ${width} ${height}`}
    >
      <path
        fill={fill}
        d="M21 0H1a1 1 0 00-1 1v20a1 1 0 001 1h20a1 1 0 001-1V1a1 1 0 00-1-1z"
      ></path>
    </svg>
  )
}
