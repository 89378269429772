import { useFormContext } from 'react-hook-form'

import { useStudyLevels } from './useStudyLevels'
import { useInstitutionsTypes } from './useInstitutionsTypes'
import { useAllInstitutions } from './useAllInstitutions'
import { useSetCountry } from '../Common'

export const useLogic = (profile: boolean) => {
  const FormContext = useFormContext()
  const { institutionsTypes } = useInstitutionsTypes(profile)
  const { countriesList } = useSetCountry('countryId', profile)
  const { institutions, status, load: getInstitutions } = useAllInstitutions(profile)
  const { studyLevels } = useStudyLevels()

  /**
   * Limpio campos de país y intitución si es que tengo algo seleccionado
   */
  const clean = (input: string) => {
    const { countryId, institutionId, otherInstitution } = FormContext.getValues()

    if (input === 'institutionTypeId' && (countryId || institutionId || otherInstitution)) {
      const cleanOptions = [
        { name: 'institutionId', value: null },
        { name: 'otherInstitution', value: '' },
        { name: 'countryId', value: null },
      ]
      cleanOptions.forEach(({ name, value }) => FormContext.setValue(name, value))
    }
  }

  const blockedCountries = !FormContext.watch('institutionTypeId')
  const isSelectInstitution = institutions.length > 0
  const blockedInstitution = !FormContext.watch('countryId') || status === 'loading'

  return {
    institutionsTypes,
    countriesList,
    institutions,
    studyLevels,
    clean,
    getInstitutions,
    isSelectInstitution,
    blockedCountries,
    blockedInstitution,
  }
}
