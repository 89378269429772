import { Props } from '../types'
import './style.scss'
import { useBackground } from './utils'

export const ParallaxPdfView = ({ config }: Props.Parallax) => {
  const background = useBackground(config)
  const sectionStyle = {
    backgroundImage: `url(${background})`,
  }
  return (
    <section className="Parallax" style={sectionStyle}>
      <div className={`Content ${config.color} ${config.background === 0 ? 'none' : ''}`}>
        {config.title && <h3>{config.title}</h3>}
        {config.subtitle && <p>{config.subtitle}</p>}
      </div>
    </section>
  )
}
