type config = {
  action: 'answer' | 'review' | 'resume'
  page: number
}

/** Cada vez  que navego por los niveles, paso el loading a false de los ejercicios del nivel que voy */
export const navigationLevel = (testState, { action, page }: config) => {
  if (['review', 'answer'].includes(action)) {
    if (
      testState.Evaluation?.Levels[page].Stages[0]?.Answers &&
      testState.Evaluation?.Levels[page].Stages[0]?.Answers.length
    ) {
      const answers = testState.Evaluation?.Levels[page].Stages[0].Answers.map((answer) => ({
        ...answer,
        loading: false,
      }))

      testState.Evaluation.Levels[page].Stages[0].Answers = answers
    }
  }
  return { ...testState }
}
