import React, { memo } from 'react'
import toast from 'toasted-notes'
import { useTranslation } from 'react-i18next'

import { SideFlash } from 'app/Components/UI/Alert'
import { Props } from './types'

const VISIBLE = 3000

export const PopAlert = memo(({ uploadingFile }: Props.PopAlert) => {
  const { t } = useTranslation()

  return (
    <>
      {uploadingFile && uploadingFile.state! === 'success' && (
        <>
          {toast.notify(
            () => (
              <SideFlash type={uploadingFile.state!}>
                <>
                  {uploadingFile && uploadingFile.state! === 'success'
                    ? t('CommentSystemFileSuccessfullyAttached')
                    : t('ProfileErrorSaving')}
                </>
              </SideFlash>
            ),
            {
              position: 'top',
              duration: VISIBLE,
            }
          )}
        </>
      )}
    </>
  )
})
