import { gql } from '@apollo/client'

import * as Type from '../types'

export const ALL_HEADING = gql`
  query allHeadings {
    allHeadings(orderBy: name_ASC) {
      name
      id
    }
  }
`

export interface ALL_HEADING_TYPE {
  allHeadings: Type.list[]
}
