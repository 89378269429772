// @External Dependencies
import ReactPaginate from 'react-paginate'
import styled from 'styled-components/macro'

// @Dependencies
import { path } from '../../../assets/svg'
import variables from './variables'

type PaginatorProps = {
  pageCount: number
  initialPage?: number
  disableInitialCallback?: boolean
  onPageChange: (e) => void
}

// @Component Paginator
export const Paginator = ({
  pageCount,
  onPageChange,
  initialPage = 0,
  disableInitialCallback,
}: PaginatorProps) => {
  return (
    <Wrapper>
      <ReactPaginate
        initialPage={initialPage}
        disableInitialCallback={disableInitialCallback}
        pageCount={pageCount}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        previousLabel={<img src={path} alt="arrow-previuos" />}
        nextLabel={<img src={path} alt="arrow-next" />}
        onPageChange={onPageChange}
        containerClassName="Paginate"
        previousClassName="arrow Paginate_Previous"
        nextClassName="arrow Paginate_Next"
        activeClassName="Page_Active"
        pageClassName="Pages"
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .Paginate {
    align-items: baseline;
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    .arrow {
      a {
        align-items: center;
        background-color: ${variables.setColors.deepSkyBlue};
        border-radius: 50%;
        display: flex;
        height: 40px;
        justify-content: center;
        width: 40px;
        &:focus {
          outline: none;
        }
      }
    }
    .break {
      margin: 0 6px;
    }
    .Pages {
      color: ${variables.setColors.primary};
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      margin-bottom: 4px;
      margin: 0 1px;
      text-align: center;
      width: 20px;
    }
    .Page_Active {
      a {
        color: ${variables.setColors.brownGreyTwo};
        font-weight: 500;
      }
    }
    .Paginate_Previous {
      cursor: pointer;
      margin-right: 10px;
      img {
        transform: rotate(180deg);
      }
    }
    .Paginate_Next {
      cursor: pointer;
      margin-left: 10px;
    }
  }
`
