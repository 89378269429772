/**
 * Estados que puede tener la pregunta
 *
 * 0: Sin responder
 * 1: Respodida
 * 2: Respondida Buena
 * 3: Respondida Mala
 * 4: Omitida
 * 5: No puede ser respondida.
 * 6: ????
 */
export const stateQuestion = {
  /**
   * 0: Estado inicial
   */
  INITIAL: 0,

  /**
   * 1: Respodida
   */
  ANSWERED: 1,

  /**
   * 2: Respondida Buena
   */
  ANSWERED_GOOD: 2,

  /**
   * 3: Respondida Mala
   */
  ANSWERED_BAD: 3,

  /**
   * 4: Omitida
   */
  OMITTED: 4,

  /**
   * 5: No puede ser respondida
   */
  CANT_ASNWER: 5,

  /**
   * 6: ????
   */
  NOT_KNOWN: 6,
}
