// External dependencies
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

// Local dependencies
import VisibilitySensor from 'app/Components/VisibilitySensor'
import { ContentParser } from 'app/Components'
import { QuestionAlert } from '../../QuestionAlert/QuestionAlert'
import { SideInfo } from '../../SideInfo'
import { Feedback } from '../../Feedback'
import { Select as SelectInput } from 'app/Components/UI'
import { Wrapper } from './Style'
import { Question } from '../types'
import { Skip, classOmitted } from '../../Skip'
import { LabelOmitted } from '../../LabelOmitted'
import { valueEmpty } from 'app/Views/Tests/helpers/valueEmpty'
import { Profiles, useTypeProfile } from '../useTypeProfile'
import { TinyAlert } from '@eclass/ui-kit'
import { Instruction } from '../Instruction'

type OptionProp = {
  tag: string
  value: string
}

export const Select = ({
  answer,
  position,
  action,
  evaluationState,
  optionDisabled,
  isMobile,
  showFeedback,
  setActiveQuestion,
  hasLevelsStages,
  missingAnswers,
  feedback,
  score,
}: Question) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { profile } = useTypeProfile()

  const [selected, setSelected] = useState('')
  const isResource = pathname.includes('resource/view')
  /* esto es para cuando es un test de nivel anonimo me deje guardar */
  const isLevelTestAnon = pathname.includes('test-level')

  const canRespond = [Profiles.REVISOR, Profiles.ESTUDIANTE].includes(profile)

  const changeOption = (id: number, state: number, option: string) => {
    if ((state !== 5 && canRespond) || isLevelTestAnon) {
      setSelected(option)
      action({
        id,
        option,
        type: 'ANSWER',
      })
    }
  }

  useEffect(() => {
    if (valueEmpty(evaluationState, answer.state!)) {
      if (answer.option) {
        setSelected(answer.option)
      } else if (answer.answer!.length > 0) {
        setSelected(answer.answer!)
      }
    }
  }, [setSelected, answer, evaluationState])

  const selectOptions = () => {
    const options = JSON.parse(answer.content!)
    const formatOptions = options.map(({ tag, value }: OptionProp, key: number) => {
      const name = tag.length > 0 ? `${tag} - ${value}` : value
      return {
        id: tag || key,
        name,
        value,
      }
    })
    return formatOptions
  }

  return (
    <VisibilitySensor setter={setActiveQuestion} id={`question-${answer.config?.id!}`}>
      <Wrapper
        className={`Question select ${isMobile ? 'Mobile' : ''}`}
        id={`question-${answer.config?.id!}`}
      >
        <div className={`Question__Data ${classOmitted(answer.skipped!, evaluationState)}`}>
          <SideInfo
            status={answer.state}
            position={position}
            evaluationState={evaluationState}
            score={score}
          />
          <div className="Question__Content">
            {answer.config && !answer.config.mandatory && !isResource && (
              <TinyAlert status="warning" text={t('ResourceOptimalQuestion')} margin="0 0 4px" />
            )}
            <LabelOmitted answer={answer.state!} evaluation={evaluationState} />
            <QuestionAlert show={(hasLevelsStages && missingAnswers)!} answer={answer} />
            <Instruction>
              {ContentParser({
                content: answer.instructions!,
                embeds: answer.embeds,
              })}
            </Instruction>
            <SelectInput
              name="Select_Question"
              id="Select_Question"
              className="Select_Question"
              disabled={optionDisabled && !canRespond}
              select={selected}
              inputSize={9}
              onChange={(e: any) => changeOption(answer.config?.id!, answer.state!, e.target.value)}
              options={selectOptions()}
            />
            <Skip answer={answer} action={action} evaluationState={evaluationState} />
          </div>
        </div>
        <Feedback
          answer={answer}
          evaluationState={evaluationState}
          showFeedback={showFeedback}
          type={feedback}
        />
      </Wrapper>
    </VisibilitySensor>
  )
}
