import { Box, useMediaQuery } from '@chakra-ui/react'
import { vars } from '@eclass/ui-kit'

/**
 * Wrapper para modificar estilos globales de la libreria react-slick
 */
export const Wrapper = ({ children }) => {
  const [breakerContainer] = useMediaQuery('(min-width: 840px)')
  return (
    <Box
      className={breakerContainer ? 'breakerContainer' : ''}
      bg={vars('colors-neutral-cultured2')}
      position="relative"
      marginLeft="-15px"
      marginRight="-15px"
      p="40px 20px 48px"
      sx={{
        '.navigation': {
          mb: '32px',
        },

        '.slick-slider': {
          userSelect: 'auto',
        },

        '.slick-arrow': {
          bottom: 'inherit',
          top: '-47px',
          ':before': {
            content: '""',
          },
        },
        '.slick-prev': {
          left: '0',
        },
        '.slick-next': {
          right: '20px',
        },
        '.slick-slide img': {
          width: '100%',
        },

        '.slick-active': {
          zIndex: 10,
        },

        '@media screen and (min-width: 768px)': {
          p: '40px 52px 48px',
        },

        '@media screen and (min-width: 1030px)': {
          p: '40px 102px 48px',
        },
      }}
    >
      {children}
    </Box>
  )
}
