import { Box } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { IBase } from '.'

export const Textarea = ({ name, value, onChange, sending }: IBase) => {
  const { t } = useTranslation()
  const { formState } = useFormContext()

  return (
    <Box
      sx={{
        '>textarea': {
          height: '8.75rem',
        },
      }}
    >
      <Input
        name={name}
        value={value}
        type="textarea"
        disabled={formState.isSubmitting || sending}
        onChange={(e) => !sending && onChange(e.target.value)}
        placeholder={t('CEVFeedbackTextareaPlaceholder')}
      />
    </Box>
  )
}
