// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Ripples from 'react-touch-ripple'

// @Component
const Pressed = ({ children, disabled, color, dark, under, timeout, opacity, className }) => {
  const filterOpacity = () => {
    return dark ? 0.2 : opacity
  }

  const filterColor = () => {
    if (color) {
      return color
    } else {
      return dark ? 'rgb(204, 204, 204)' : 'rgb(255, 255, 255)'
    }
  }

  return disabled ? (
    <div className="Pressed__content">{children}</div>
  ) : (
    <Wrap
      className={className}
      color={filterColor()}
      timeout={timeout}
      under={under}
      opacity={filterOpacity()}
    >
      {children}
    </Wrap>
  )
}

// @Proptypes
Pressed.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  opacity: PropTypes.number,
  timeout: PropTypes.number,
  state: PropTypes.string,
  under: PropTypes.bool,
  className: PropTypes.string,
}

Pressed.defaultProps = {
  opacity: 0.2,
  timeout: 1000,
  under: true,
  dark: false,
}

// @Export Component
export default Pressed

const Wrap = ({ color, timeout, under, children, opacity, className }) => (
  <StyledWrap opacity={opacity}>
    <Ripples
      color={color}
      timeout={{
        enter: timeout,
        exit: timeout,
      }}
    >
      <div className={`Pressed__content ${under ? 'under' : ''} ${className || ''}`}>
        {children}
      </div>
    </Ripples>
  </StyledWrap>
)

// @Export Component
const StyledWrap = styled.div`
  height: 100%;
  & > div {
    display: block !important;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .Pressed__content {
    height: 100%;
    position: relative;

    & + span {
      margin: 0;
    }
  }
  .under {
    z-index: 2;
  }
  .rtr-ripple-entering {
    opacity: ${(props) => props.opacity};
  }
`
