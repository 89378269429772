import styled from 'styled-components/macro'
import { vars } from '@eclass/ui-kit'

const SIDE_PADDING = '24px'
const PADDING = `0 ${SIDE_PADDING}`

export const Box = styled.article`
  background: ${vars('colors-neutral-white')};
  flex-basis: 100%;
  height: 100%;
  max-width: 388px;
  padding-bottom: 0;
  text-align: center;
  transition: box-shadow 0.25s;

  & > header {
    padding: ${PADDING};
  }
`

export const Header = styled.header`
  &.coordinatorHeader {
    margin-bottom: 32px;
  }

  &.faqHeader {
    margin-bottom: 16px;
  }
`

export const Title = styled.p`
  font-size: 16px;
  line-height: normal;
  margin-bottom: 16px;
`

export const AttentionTime = styled.p`
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 0;
`

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;

  li {
    cursor: pointer;
    transition: background 0.25s;

    &:first-child {
      border-top: 1px solid ${vars('colors-neutral-platinum')};
    }

    &:not(last-child) {
      border-bottom: 1px solid ${vars('colors-neutral-platinum')};
    }
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background: ${vars('colors-neutral-cultured2')};
      color: ${vars('colors-neutral-darkCharcoal')};
    }
  }
`

export const Anchor = styled.a`
  color: ${vars('colors-neutral-darkCharcoal')};
  line-height: 18px;
  max-height: 48px;
  padding: 14px ${SIDE_PADDING} 16px;

  &:hover {
    color: ${vars('colors-neutral-darkCharcoal')};
  }

  @media screen and (max-width: 350px) {
    > div {
      max-width: 70%;
    }
  }
`
export const CoordinatorInfo = styled.section`
  display: flex;
  border-top: 1px solid ${vars('colors-neutral-platinum')};
  padding: 16px 24px;

  .info {
    margin-left: 16px;
    text-align: left;
  }

  p {
    margin-bottom: 0;
    font-size: 14px;

    &.profile {
      margin-top: 4px;
    }
  }

  svg path {
    fill: ${vars('colors-main-ziggurat')};
  }
`

export const FrequentQuestions = styled.section`
  margin: 0 0 32px;

  > p {
    color: ${vars('colors-neutral-darkCharcoal')};
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0;
    padding: ${PADDING};
  }

  button {
    margin: 24px 0 0;
  }
`
