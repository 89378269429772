import React from 'react'

import './style.scss'
import { IDModules } from '../'
import { Footer } from './Footer'
import { Content } from './Content'
import { DownloadFile } from 'app/Components/UI'
import { Image } from '../Image'
import { AlternativeContentProps } from '../types'

export const AlternativeContent = ({ config, typeModule }: AlternativeContentProps.Base) => {
  // Render para el tipo archivo
  if (
    typeModule === IDModules.DESCARGAR_ARCHIVO &&
    (!config.has_alternative || config.has_alternative === 0)
  ) {
    return (
      <div className="AlternativeContentPDF">
        <DownloadFile doc={config} id={config.id!} />
        <Footer config={config} typeModule={typeModule} />
      </div>
    )
  }

  // has_alternative === 1 tiene contenido alternativo
  if (
    IDModules.IMAGENES === typeModule &&
    (!config.has_alternative || config.has_alternative === 0)
  ) {
    return <Image config={config} id={config.id!} />
  }

  // Render por defecto
  return (
    <div className="AlternativeContentPDF" data-testid="AlternativeContent">
      {/* Texto */}
      <Content config={config} />

      {/* Footer con enlaces */}
      <Footer config={config} typeModule={typeModule} />
    </div>
  )
}
