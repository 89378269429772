import { useEffect, useState } from 'react'
import { DropdownItem } from 'reactstrap'
import goTo from '@eclass/scrollmove'

import { Icon } from '../Icons'
import { Box } from '@chakra-ui/react'
import { TinyAlert } from '@eclass/ui-kit'
import { UIVars } from 'app/Components/UI'

import { getQuestionStatus } from 'app/Views/Tests/helpers/questionStatus'
import { useTranslation } from 'react-i18next'
import { ItemTestProps } from './Types'
import * as ItemStyle from './ItemStyle'

const MenuItemTest = ({
  number,
  answer: {
    state = 0,
    config: { id },
  },
  activeQuestion,
}: ItemTestProps) => {
  const { t } = useTranslation()
  const questionStatus: any = getQuestionStatus(t, state)
  const [active, setActive] = useState(false)

  useEffect(() => {
    if (activeQuestion) {
      activeQuestion === `question-${id}` ? setActive(true) : setActive(false)
    }
  }, [setActive, activeQuestion, id])

  const labelStatus = {
    Pending: 'pending',
    Answered: 'answered',
    Omitted: 'omitted',
    Correct: 'success',
    Incorrect: 'error',
  }

  return (
    <ItemStyle.Test key={number}>
      <DropdownItem className={questionStatus.className} onClick={() => goTo(`#question-${id}`)}>
        {
          <span>
            <Icon
              name="in-progress"
              fill={active ? UIVars.setColors.primary : 'transparent'}
              className="flag"
            />
          </span>
        }
        <span>{`${t('ResourceQuestion')} ${number + 1}`}</span>

        <Box float="right">
          <TinyAlert status={labelStatus[questionStatus.className]} text={questionStatus.status} />
        </Box>
      </DropdownItem>
    </ItemStyle.Test>
  )
}

export default MenuItemTest
