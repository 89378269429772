import { FormProvider } from 'react-hook-form'
import { VStack, HStack } from '@chakra-ui/react'
import { BtnPrimary, BtnSecondary, vars } from '@eclass/ui-kit'
import { useTranslation } from 'react-i18next'

import { Question } from './Question'
import { useSubmit } from './useSubmit'
import * as Types from '../types'

export const Form = (props: Types.IForm) => {
  const { t } = useTranslation()
  const { questions, closed } = props
  const { methods, isBloqued, onSubmit, sending } = useSubmit(props)

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <VStack
          spacing={4}
          align="stretch"
          pt="1rem"
          mt="1rem"
          borderTop={`1px solid ${vars('colors-neutral-platinum')}`}
        >
          {questions.map((question) => (
            <Question data={question} key={question.title} />
          ))}
        </VStack>
        <HStack
          spacing="1.5rem"
          mt="2rem"
          sx={{
            '>button': {
              m: 0,
            },
            '.button__container': {
              padding: '.75em 1.25em',
              width: 'initial',
            },
          }}
        >
          <BtnPrimary
            type="submit"
            disabled={isBloqued || sending}
            isLoading={methods.formState.isSubmitting || sending}
          >
            {t('CEVFeedbackSend')}
          </BtnPrimary>

          <BtnSecondary
            disabled={methods.formState.isSubmitting || sending}
            onClick={closed}
            type="button"
          >
            {t('CEVFeedbackCancel')}
          </BtnSecondary>
        </HStack>
      </form>
    </FormProvider>
  )
}
