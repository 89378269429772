import { Box } from '@chakra-ui/react'
import { vars } from '@eclass/ui-kit'

import { ContentParser } from 'app/Components'

export const Feedback = ({ content }: { content?: string }) => {
  if (!content || content.length === 0) return <></>

  return (
    <Box
      bg={vars('colors-neutral-cultured2')}
      borderRadius={vars('radii-big')}
      color={vars('colors-neutral-darkCharcoal')}
      fontSize="14px"
      lineHeight="19px"
      mt="24px"
      p="24px"
    >
      {ContentParser({ content })}
    </Box>
  )
}
