import { Box } from '@chakra-ui/react'

import * as Type from '../types'
import './stylePDF.scss'
import ContentParser from '../../..'

export const PDF = ({ data }: Type.propsComponents) => {
  const total = data.Milestones.length

  return (
    <div>
      {data.Milestones.map((item, index) => (
        <Box key={item.label} className="PDFTimeline">
          <Box className="label">
            <h6>{item.label}</h6>
            <p>
              {index + 1} de {total}
            </p>
          </Box>
          <Box className="content ContentArticleChild">
            {ContentParser({ content: item.text, embeds: data.embeds, changeTypeNode: false })}
          </Box>
        </Box>
      ))}
    </div>
  )
}
