import React from 'react'

import useExercise from 'app/Views/Exercise/Practice/useExercise'
import BaseMultiple from './BaseMultiple'

const CheckboxExercise = ({ option, answer, optionValue, optionDisabled, id }: any) => {
  const {
    state: { answers, answered, seeCorrect, send, hint, embeds },
    dispatch,
  } = useExercise()

  const answerActive = answers.filter((item: any) => item.answerId === id)

  const handleChecked = () => {
    const status = answerActive[0]?.options.filter(
      (item: any, index: number) => index === optionValue
    )

    let checked = 0
    if (status?.length > 0) {
      checked = status[0]?.checked || status[0]?.selected
    }

    if (seeCorrect) {
      return 0
    } else {
      if (checked) {
        return 1
      } else {
        return 0
      }
    }
  }

  const handleChange = (e: any) => {
    if (answerActive && answerActive.length > 0 && answerActive[0].options) {
      const newOption = answerActive[0].options.map((item: any, index: number) => {
        if (index === optionValue) {
          item.checked = e.target.checked ? 1 : 0
        }
        return item
      })
      dispatch({
        answerId: id,
        options: newOption,
      })
    }
  }

  let correctOption = false
  if (hint) {
    const hindActive = hint.filter((item: any) => item.answerId === id)
    if (hindActive?.length > 0) {
      hindActive[0].options.forEach((item: any, index: number) => {
        if (item.selected && optionValue === index) {
          correctOption = true
        }
      })
    }
  }

  return (
    <BaseMultiple
      option={option}
      seeCorrect={seeCorrect}
      correctOption={correctOption}
      handleChecked={handleChecked}
      optionDisabled={optionDisabled || send.status}
      answered={answered}
      optionValue={optionValue}
      id={id}
      handleChange={handleChange}
      answer={answer}
      type="checkbox"
      embeds={embeds}
    />
  )
}

export default CheckboxExercise
