import React from 'react'
import { EventStreamMarshaller } from '@aws-sdk/eventstream-marshaller'
import { toUtf8, fromUtf8 } from '@aws-sdk/util-utf8-node'
import Mic from 'microphone-stream'

import { convertAudioToBinaryMessage, validate, createPresignedUrl } from './Services'
import { useSafeState } from 'app/Services'
import * as Type from './types.d'
import { Props } from '../types'
import { useCreateContentModuleVR } from './useCreateContentModuleVR'

const sampleRate = 44100
const eventStreamMarshaller = new EventStreamMarshaller(toUtf8, fromUtf8)

export const useRecorder = ({ config, id }: Props.Voice) => {
  const { options, amazon } = config
  const [turnOff, setTurnOff] = useSafeState(false)
  const [transcript, setTranscript] = React.useState<string>()
  const [inputSampleRate, setInputSampleRate] = React.useState<number>()
  const initError = {
    show: false,
    isSupported: true,
  }
  const [state, setState] = React.useState<Type.state>('INIT')
  const [mediaStream, setMediaStream] = useSafeState<MediaStream | undefined>(undefined)
  const [alertError, setAlertError] = React.useState<Type.error>(initError)

  const browserSupport = !!navigator.mediaDevices.getUserMedia

  const connection = React.useRef<any>()
  const micro = React.useRef<any>()
  const stream = React.useRef<MediaStream>()
  const servicesStarted = micro.current && connection.current && stream.current

  const { createContentModuleVR } = useCreateContentModuleVR()

  /**
   * Consulto permiso y comienzo a grabar.
   * Valido transcripción con lista configurada
   */
  const start = async () => {
    try {
      const apiMicro = await navigator.mediaDevices.getUserMedia({ audio: true })
      const newMediaStream = new MediaStream(apiMicro)
      stream.current = newMediaStream
      setMediaStream(newMediaStream)

      micro.current = new Mic()
      micro.current.on('format', (data) => {
        setInputSampleRate(data.sampleRate)
      })

      const url = createPresignedUrl(amazon, sampleRate)
      connection.current = new WebSocket(url)
      connection.current.binaryType = 'arraybuffer'

      if (micro.current && stream.current && connection.current) {
        micro.current.setStream(stream.current)

        connection.current.onopen = () => {
          micro.current.on('data', (audioChunk) => {
            // the audio stream is raw audio bytes. Transcribe expects PCM with additional metadata, encoded as binary
            const binary = convertAudioToBinaryMessage({
              audioChunk,
              inputSampleRate,
              sampleRate,
              eventStreamMarshaller,
            })
            if (connection.current.readyState === connection.current.OPEN) {
              connection.current.send(binary)
            }
          })
        }

        connection.current.onmessage = (message: MessageEvent) => {
          const messageWrapper = eventStreamMarshaller.unmarshall(Buffer.from(message.data))
          const messageBody = JSON.parse(
            String.fromCharCode.apply(String, messageWrapper.body as any)
          )
          if (messageWrapper.headers[':message-type'].value === 'event') {
            handleEventStreamMessage(messageBody)
          }
        }

        connection.current.onerror = () => {
          connection.current.close()
        }

        connection.current.onclose = (closeEvent) => {
          stop()
        }

        setState('RECORDING')
      } else {
        servicesError()
      }
    } catch (error) {
      setState('DENIED')
    }
  }

  // detengo proceso y levanto error si no funcionan bien los servicios
  const servicesError = () => {
    stop()
    setAlertError({
      show: true,
      text: 'Error: Se han detectado problemas al transcribir tu audio. Vuelve a intentarlo',
      isSupported: true,
    })
    setState('INIT')
  }

  // Manejo el tiempo máximo de grabación
  React.useEffect(() => {
    if (state === 'RECORDING') {
      setTimeout(() => {
        // valido que existan los servicios y que sean el mismo que comenzo
        if (
          connection.current &&
          micro.current &&
          stream.current &&
          mediaStream?.id === stream.current.id
        ) {
          setTurnOff(true)
          stop()
          setState('NOT_UNDERSTAND')
        }
      }, 10000)
    }
  }, [state, mediaStream])

  /**
   * Obtengo transcripción de amazon, termino el proceso de grabar y valido
   */
  const handleEventStreamMessage = (messageJson) => {
    const results = messageJson.Transcript.Results
    if (results.length > 0) {
      if (results[0].Alternatives.length > 0) {
        const amazonTranscript = decodeURIComponent(escape(results[0].Alternatives[0].Transcript))
        // if this transcript segment is final, add it to the overall transcription
        if (!results[0].IsPartial && connection.current) {
          setTranscript(amazonTranscript)
          connection.current?.close()

          const isCorrect = validate(options, amazonTranscript)

          if (isCorrect) {
            setState('SUCCESS')
          } else {
            setState('ERROR')
          }

          createContentModuleVR({
            contentModuleId: id,
            provider: 'amazon',
            textOptions: options,
            textFeedback: amazonTranscript,
            isCorrect,
          })
        }
      }
    }
  }

  /**
   * Detego el Socket y MIC
   */
  const stop = () => {
    if (micro.current || connection.current || stream.current) {
      micro.current?.stop()
      micro.current = undefined
      connection.current = undefined
      stream.current = undefined
    }
  }

  /**
   * Efecto cuando le doy a detener la grabación,
   * pregunta si se detuvo la grabación y si se transcribio algo,
   * si no es así se marca como error.
   * También cierro socket y microfono al salir
   */
  React.useEffect(() => {
    if (turnOff && !transcript) {
      setState('NOT_UNDERSTAND')
    }
    return () => {
      stop()
    }
  }, [turnOff, transcript])

  /**
   * Evento al dar el click.
   * primero valido soporte de la API para grabar,
   * y luego el evento click que actua dependiendo del tipo de state que tenga
   */
  const onClick = () => {
    if (browserSupport) {
      setTranscript(undefined)
      setTurnOff(false)
      if (['INIT', 'DENIED'].includes(state)) {
        !servicesStarted && start()
      } else if (state === 'RECORDING') {
        setAlertError(initError)
        setState('LOADING')
        // Para transmición socket, le doy delay para que alcance a procesar todo lo enviado
        setTimeout(() => {
          setTurnOff(true)
          stop()
        }, 2000)
      }
    } else {
      setState('NOT_SUPPORTED')
      setAlertError({
        show: true,
        isSupported: false,
      })
    }
  }

  const redo = () => {
    setState('INIT')
    setAlertError(initError)
  }

  const closedError = () => {
    setAlertError(initError)
  }

  return {
    browserSupport,
    state,
    onClick,
    redo,
    alertError,
    closedError,
    transcript,
  }
}
