import { useState, useRef, useEffect } from 'react'
import Slider from 'react-slick'

import * as Type from '../types'
import { Arrow, TArrow, Progress, Nav, Wrapper, Content } from './components'

export const PERFECT_LENGTH = 4

export const Horizontal = ({ data }: Type.propsComponents) => {
  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()
  const slider1 = useRef(null)
  const slider2 = useRef(null)

  const [active, setActive] = useState(0)

  useEffect(() => {
    slider1.current !== null && setNav1(slider1.current)
    slider2.current !== null && setNav2(slider2.current)
  }, [])

  const total = data.Milestones.length
  const visibleElements = total > PERFECT_LENGTH ? PERFECT_LENGTH : total

  const validateShowArrow = (type: TArrow) => {
    if (type === 'next' && active !== total - 1) {
      return <Arrow type="next" onClick={onclick} className={undefined} />
    } else if (type === 'prev' && active !== 0) {
      return <Arrow onClick={onclick} className={undefined} />
    }
    return <></>
  }

  return (
    <Wrapper>
      <Progress
        active={active}
        reference={slider1}
        total={total}
        visibleElements={visibleElements}
      />
      <Slider
        asNavFor={nav2}
        className="navigation"
        focusOnSelect={true}
        infinite={false}
        ref={slider1}
        responsive={[
          {
            breakpoint: 720,
            settings: {
              slidesToShow: 1,
            },
          },
        ]}
        slidesToShow={visibleElements}
        swipeToSlide={false}
        swipe={false}
      >
        {data.Milestones.map((item, index) => (
          <Nav active={active} label={item.label} index={index} key={item.label} />
        ))}
      </Slider>
      <Slider
        adaptiveHeight={true}
        asNavFor={nav1}
        beforeChange={(current: number, next: number) => setActive(next)}
        fade={true}
        infinite={false}
        nextArrow={validateShowArrow('next')}
        prevArrow={validateShowArrow('prev')}
        ref={slider2}
        swipeToSlide={false}
        swipe={false}
      >
        {data.Milestones.map((item, index) => (
          <Content item={item} index={index} total={total} key={index} data={data} />
        ))}
      </Slider>
    </Wrapper>
  )
}
