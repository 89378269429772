import { gql, useMutation, MutationHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

/**
 * Obtiene la url para la cev
 */
export const USER_PROCTORING = gql`
  ${STATUS_FIELDS}
  mutation UserProctoring($UserProctoringInput: UserProctoringInput!) {
    UserProctoring(input: $UserProctoringInput) {
      status {
        ...StatusFields
      }
      type
      button {
        text
        link
      }
      text
      helpCenterUrl {
        id
        link
        text
      }
    }
  }
`
interface UserProctoringPayloadApollo {
  UserProctoring: Api.UserProctoringPayload
}

interface UserProctoringInputApollo {
  UserProctoringInput: Api.UserProctoringInput
}

export const useMutationtUserProctoring = (
  options?: MutationHookOptions<UserProctoringPayloadApollo, UserProctoringInputApollo>
) => {
  return useMutation<UserProctoringPayloadApollo, UserProctoringInputApollo>(
    USER_PROCTORING,
    options
  )
}
