import React from 'react'

export const Poll = () => (
  <svg
    width="46"
    height="45"
    role="iconPoll"
    viewBox="0 0 46 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22.8323" cy="22.5" r="22.5" fill="#E0EEFA" />
    <path
      d="M40.3323 14H22.3323C21.78 14 21.3323 14.4477 21.3323 15V17C21.3323 17.5523 21.78 18 22.3323 18H40.3323C40.8846 18 41.3323 17.5523 41.3323 17V15C41.3323 14.4477 40.8846 14 40.3323 14Z"
      fill="#1EBDAF"
    />
    <path
      d="M28.3323 22H22.3323C21.78 22 21.3323 22.4477 21.3323 23V25C21.3323 25.5523 21.78 26 22.3323 26H28.3323C28.8846 26 29.3323 25.5523 29.3323 25V23C29.3323 22.4477 28.8846 22 28.3323 22Z"
      fill="#FFBC27"
    />
    <path
      d="M38.3323 30H22.3323C21.78 30 21.3323 30.4477 21.3323 31V33C21.3323 33.5523 21.78 34 22.3323 34H38.3323C38.8846 34 39.3323 33.5523 39.3323 33V31C39.3323 30.4477 38.8846 30 38.3323 30Z"
      fill="#0189FF"
    />
    <path
      d="M14.3323 6H12.3323C11.2277 6 10.3323 6.89543 10.3323 8V21L10.3323 36.5375C10.3323 37.8975 11.3959 39 12.708 39H33.3323C34.4368 39 35.3323 38.1046 35.3323 37V22.5V8.46252C35.3323 7.10251 34.2686 6 32.9565 6H31.3323"
      stroke="#08044F"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3323 26C17.4368 26 18.3323 25.1046 18.3323 24C18.3323 22.8954 17.4368 22 16.3323 22C15.2277 22 14.3323 22.8954 14.3323 24C14.3323 25.1046 15.2277 26 16.3323 26Z"
      stroke="#08044F"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3323 18C17.4368 18 18.3323 17.1046 18.3323 16C18.3323 14.8954 17.4368 14 16.3323 14C15.2277 14 14.3323 14.8954 14.3323 16C14.3323 17.1046 15.2277 18 16.3323 18Z"
      stroke="#08044F"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3323 34C17.4368 34 18.3323 33.1046 18.3323 32C18.3323 30.8954 17.4368 30 16.3323 30C15.2277 30 14.3323 30.8954 14.3323 32C14.3323 33.1046 15.2277 34 16.3323 34Z"
      stroke="#08044F"
      strokeWidth="2"
    />
    <rect x="16.3323" y="1" width="13" height="5" rx="2" fill="#FF554D" />
    <rect x="17.3323" y="2" width="11" height="7" rx="1" stroke="#08044F" strokeWidth="2" />
  </svg>
)
