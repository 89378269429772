import styled from '@emotion/styled'

type nameSize = 'md' | 'lg' | 'xl'

interface Props {
  className?: string
  children: React.ReactNode
  maxW?: nameSize
  mt?: number
}

const SIZES = {
  /** 796px -> 49.75 */
  md: 49.75,
  /** 1000px -> 62.5 */
  lg: 62.5,
  /** 1440px -> 90 */
  xl: 90,
  /** 1920px -> 120 */
  xxl: 120,
}

/**
 * Componente ContainerArticle
 * Limita el contenido a un ancho predeterminado el cual puede variar en tamaños definidos:
 * - md = 796px (8 columnas en diseño)
 * - lg = 1000px (10 columnas en diseño)
 * - xl = 1440px (12 columnas en diseño)
 *
 * Si algún elemento al interior del container es más ancho que este, se le debe agregar las siguientes clases:
 * - "breakerContainer", La cual le da un max-width: 1000px,
 * - "breakerContainer-xl", da un ancho completo con el límite de 1920px, este no muestra padding en las versiones móviles
 * @example
 * <Container />
 */
export const ContainerArticle = ({
  children,
  className = '',
  maxW = 'md',
  mt = 0,
}: Props): JSX.Element => {
  return (
    <Styles mt={mt} maxW={maxW} className={className} data-testid="containerArticle">
      {children}
    </Styles>
  )
}

interface PropsStyle {
  maxW: nameSize
  mt: number
}

const Styles = styled.section<PropsStyle>`
  margin: ${({ mt }) => `${mt}rem auto 0`};
  max-width: ${({ maxW }) => `${SIZES[maxW] + 2}rem`};
  padding: 0;
  width: 100%;

  .breakerContainer,
  .breakerContainer-full {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    transform: translateX(calc(50vw - 50%));
    width: 100vw;
  }

  .breakerContainer {
     max-width: calc(100vw - 2rem);
    width: 100%;
    @media screen and (min-width: ${`${SIZES.md + 2}rem`}) {
      width: 100vw;
    }
    @media screen and (min-width: ${`${SIZES.lg + 2}rem`}) {
      max-width: ${`${SIZES.lg}rem`};
    }

  .breakerContainer-full {
    max-width: ${`${SIZES.xxl}rem`};
  }
`
