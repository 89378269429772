import { useState, useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Store } from 'app/Redux/types'
import { UIVars } from 'app/Components/UI'
import { Icon, PowerByEclass } from 'app/Components/UI/Icons'
import Wrapper from './Style'
import usePosition from './usePosition'

type Props = {
  inside?: boolean
}

/**
 * Componente footer
 * @example
 *  <Footer inside />
 */
const Footer = ({ inside }: Props) => {
  const match = useRouteMatch()
  const {
    ui: { isMobile, pdfPrivacy, HELP_CENTER },
    courses,
    loggedUser,
  } = useSelector((state: Store) => state)
  const { bottom, loading } = usePosition({ match })
  const [path, setPath] = useState(match.path)

  const isLogged = loggedUser && Object.keys(loggedUser).length > 0
  const { t } = useTranslation()

  useEffect(() => {
    setPath(match.path)
  }, [match, path])

  const validateHelpCenter = () => {
    const link = 'https://ayuda.eclass.com'
    if (HELP_CENTER?.FooterBoxes || courses.getCourseInfo.footerBoxes) {
      const links = courses.getCourseInfo.footerBoxes ?? HELP_CENTER?.FooterBoxes
      if (links) {
        const faq = links.find((box) => box.type === 'FREQUENT_QUESTIONS')
        if (faq?.links && faq.links?.length === 1) {
          return faq.links[0].href
        }
      }
    }
    return link
  }

  return (
    <Wrapper
      inside={inside}
      bottom={bottom}
      loading={loading ? 1 : undefined}
      className="Footer"
      id="Footer"
    >
      <Container fluid={isMobile}>
        <Row>
          <Col md="12" className="flex">
            <Link to="/courses">
              <PowerByEclass />
            </Link>
            <nav className={`Footer__Nav ${isMobile ? 'Mobile' : ''}`}>
              <ul className="noStyledList flex">
                <li className={`${path === '/courses' ? 'Active_View' : ''}`}>
                  {isLogged ? (
                    <Link to="/courses">{t('DashboardCourses')}</Link>
                  ) : (
                    <Link to="/login">{t('ContentEnter')}</Link>
                  )}
                </li>
                <li>
                  <a
                    href={validateHelpCenter()}
                    target="_blank"
                    rel="noreferrer"
                    id="CrossFrecuentQuestions"
                  >
                    {t('DashboardFAQ')}
                    <Icon
                      className="arrow-top-right"
                      name="arrow-top-right"
                      fill={UIVars.setColors.icons}
                    />
                  </a>
                </li>
                <li className={`${path === '/requirements' ? 'Active_View' : ''}`}>
                  <Link to="/requirements">{t('DashboardTechnical')}</Link>
                </li>
                {isLogged && (
                  <li className={`${path === '/terms' ? 'Active_View' : ''}`}>
                    <Link to="/terms">{t('ContentTermsConditions')}</Link>
                  </li>
                )}
                <li>
                  <a href={pdfPrivacy || ''} target="_blank" rel="noreferrer">
                    {t('DashboardPrivacy')}
                    <Icon
                      className="arrow-top-right"
                      name="arrow-top-right"
                      fill={UIVars.setColors.icons}
                    />
                  </a>
                </li>
              </ul>
            </nav>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export default Footer
