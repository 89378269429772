import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import classNames from 'classnames'

import { Props } from '../types'
import './style.scss'

/**
 * ## Función General
 * Este recurso permite que que el/la estudiante pueda visualizar una lista de definición de conceptos.
 * ## Función Metodológica
 * Esta visualización, en primer lugar, permite revisar el contenido en "general", es decir, los/ las alumnas pueden ver cuales son los puntos a tratar, y por otro lado, tiene un estilo de continuidad, y su revisión está determinada por un dinamismo, por la acción de desplegar el contenido. Dependiendo del objetivo del contenido, el acordeón puede considerar además una continuidad o una enumeración.
 * @example
 *  <Accordion config={options} id={2}>
 */
export const Accordion = ({ config, id }: Props.Accordion) => {
  if (!config || !config.items || config.items.length === 0) {
    return null
  }
  return (
    <section className="Article__accordeon" key={id} data-testid="accordeon">
      <div className="panel-group" id={`accordion_content_${id}`}>
        {Array.from(config.items)?.map((item, key) => {
          return (
            <React.Fragment key={`panel_${key}`}>
              {!!item.new_page && <hr className="pdfpagebreak" />}
              <div className="panel panel-default">
                <div
                  className={classNames('card-header', {
                    collapsed: key !== 0,
                  })}
                  data-target={`#collapse_${id}_${key}`}
                  data-toggle="collapse"
                  id={`heading_${key}`}
                >
                  <div className="Accordion__Title mb-0">{item.title}</div>
                </div>
                <div
                  className={classNames('collapse', {
                    show: key === 0,
                  })}
                  data-parent={`#accordion_content_${id}`}
                  id={`collapse_${id}_${key}`}
                >
                  <div className="card-body">{ReactHtmlParser(item.text)}</div>
                </div>
              </div>
            </React.Fragment>
          )
        })}
      </div>
    </section>
  )
}
