import React from 'react'
import { useFormContext } from 'react-hook-form'
import ReactHtmlParser from 'react-html-parser'

import { Wrapper } from './style'

type props = {
  name: string
  text: string
  onChange?: (checked: boolean) => void
  disabled?: boolean
}

/**
 * ## Componente Checkbox que va al pie de los modales
 * @example
 * <CheckBox
 *  text={'Esta información es privada'}
 *  name="acepta" />
 */
export const CheckBox = ({ name, text, onChange, disabled = false }: props) => {
  const { register, formState } = useFormContext()

  return (
    <Wrapper className={`form-checkbox profile-${name}`}>
      <label>
        <input
          type="checkbox"
          ref={register}
          disabled={disabled || formState.isSubmitting}
          name={name}
          onChange={(e) => {
            onChange && onChange(e.target.checked)
          }}
        />
        <span className="checkmark"></span>
        <p>{ReactHtmlParser(text)}</p>
      </label>
    </Wrapper>
  )
}
