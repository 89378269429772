// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { axiosError, getToken } from '../../../Services'

/**
 * Obtiene los datos personales de una persona para la confirmación de Perfil
 *
 * Retorna ordenados por Pasos los set de datos
 * - dataPersonalInfo: Datos Personales
 * - dataContactInfo: Datos de Contacto
 * - dataAcademyInfo: Datos Académicos
 * @example
 *  ```js
 *    const response = await getPersonInfo()
 *  ```
 * @returns Objeto con los datos ordenados para los 3 pasos de la confirmación de Perfil
 */
const getPersonInfo = () => {
  const requestId = 'getPersonInfo'
  return async (dispatch) => {
    getToken()

    const query = `
      query me {
        me {
          User {
            Person {
              id
              formatDocumentNumber
              documentNumber
              documentNumberId
              nationId
              name
              firstSurname
              lastSurname
              birthday
              gender
              email
              private
              studyLevelId
              company
              position
              professionalResume
              fullName
              picture
              showOnline
              timeZone
              Jobs {
                jobTypeId
                industryId
                executivePositionId
                position
                name
                id
                startDate
                endDate
                current
                private
                endDate
                startDate
                headingId
              }
              Phone2s {
                phoneTypeId
                countryCode
                areaCode
                number
                id
                private
                isWhatsapp
                canContactWhatsapp
              }
              Address2s {
                addressTypeId
                countryId
                regionId
                locationId
                address
                number
                block
                apartment
                id
                private
              }
              AcademicTrainings {
                id
                career
                countryId
                institutionTypeId
                promotion
                isPrivate
                studyType
                otherInstitution
                Institution {
                  name
                  id
                }
              }
            }
          }
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then((response) => {
        if (response.status === 200 && response.data.data.me) {
          const { User } = response.data.data.me
          // Paso 1: Información Personal
          const dataPersonalInfo = {
            documentNumber: User.Person.formatDocumentNumber,
            documentNumberId: User.Person.documentNumberId,
            nationId: User.Person.nationId,
            name: User.Person.name,
            firstSurname: User.Person.firstSurname,
            lastSurname: User.Person.lastSurname,
            birthday: User.Person.birthday,
            gender: User.Person.gender,
            id: User.Person.id,
            fullName: User.Person.fullName,
            picture: User.Person.picture,
            showOnline: User.Person.showOnline,
            timeZone: User.Person.timeZone,
          }

          // Paso 2: Datos de Contacto
          let addressInfo = {}
          let phoneInfo = {}
          if (User.Person.Address2s.length) {
            addressInfo = User.Person.Address2s.map((value) => {
              return {
                address: value.address,
                addressTypeId: value.addressTypeId,
                apartment: value.apartment,
                block: value.block,
                countryId: value.countryId,
                locationId: value.locationId,
                number: value.number,
                regionId: value.regionId,
                private: value.private,
                id: value.id,
              }
            })
          }

          if (User.Person.Phone2s.length) {
            phoneInfo = User.Person.Phone2s.map((value) => {
              return {
                areaCode: value.areaCode,
                countryCode: value.countryCode,
                number: value.number,
                phoneTypeId: value.phoneTypeId,
                id: value.id,
                private: value.private,
                isWhatsapp: value.isWhatsapp,
                canContactWhatsapp: value.canContactWhatsapp,
              }
            })
          }

          let academicTrainingInfo = {}
          if (User.Person.AcademicTrainings.length) {
            academicTrainingInfo = User.Person.AcademicTrainings.map((value) => {
              return {
                career: value.career,
                promotion: value.promotion,
                private: value.isPrivate,
                institution: value.Institution ? value.Institution.name : '',
                otherInstitution: value.Institution ? '' : value.otherInstitution,
                institutionId: value.Institution ? value.Institution.id : 0,
                id: value.id,
                countryId: value.countryId,
                institutionTypeId: value.institutionTypeId,
                studyType: value.studyType,
              }
            })
          }

          const dataContactInfo = {
            email: User.Person.email,
            private: User.Person.private,
            addressInfo,
            phoneInfo,
          }

          // Paso 3: Información académica y laboral
          let jobInfo = {
            unemployed: false,
          }
          if (User.Person.Jobs.length) {
            jobInfo = User.Person.Jobs.map((value) => {
              return {
                executivePositionId: value.executivePositionId,
                industryId: value.industryId,
                jobTypeId: value.jobTypeId,
                position: value.position,
                name: value.name,
                startDate: value.startDate,
                endDate: value.endDate,
                id: value.id,
                current: value.current,
                private: value.private,
                headingId: value.headingId,
              }
            })
          } else {
            jobInfo = {
              unemployed: true,
            }
          }

          const dataAcademyInfo = {
            studyLevelId: User.Person.studyLevelId,
            professionalResume: User.Person.professionalResume,
            jobInfo,
            academicTrainingInfo,
          }

          return {
            dataPersonalInfo,
            dataContactInfo,
            dataAcademyInfo,
            status: {
              success: true,
              detail: 'Petición exitosa',
            },
          }
        } else {
          return {
            status: {
              success: false,
              detail: 'Error al buscar las Industrias.',
            },
          }
        }
      })
      .catch((err) => axiosError(err, requestId, dispatch))
  }
}

export default getPersonInfo
