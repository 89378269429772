export const PersonalDataIcon = (props) => {
  return (
    <g {...props} fillRule="nonzero">
      <path
        d="M8 8C10.2091 8 12 6.20914 12 4C12 1.79086 10.2091 0 8 0C5.79086 0 4 1.79086 4 4C4 6.20914 5.79086 8 8 8Z"
        fill="#B0CFE0"
      />
      <path
        d="M16 13.2C16.0017 12.8172 15.8928 12.442 15.6865 12.1195C15.4802 11.7971 15.1853 11.541 14.837 11.382C12.6811 10.4439 10.351 9.97288 8.00002 9.99999C5.64902 9.97288 3.31892 10.4439 1.16302 11.382C0.814771 11.541 0.519799 11.7971 0.313505 12.1195C0.107211 12.442 -0.00163935 12.8172 1.86626e-05 13.2V16H16V13.2Z"
        fill="#B0CFE0"
      />
    </g>
  )
}
