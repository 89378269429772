import React, { useState, useEffect } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import * as Style from './Style'
import { Icon } from '../../../Components/UI/Icons'
import { Avatar, Pressed } from '../../../Components/UI'
import HeaderDrop from './HeaderDrop'
import { Items } from './Items'
import { NewDropProps, Indicator } from './Types'
import { RouteParams } from 'Config/routes/types'
import { getIdButtonDropdown } from './getIdButtonDropdown'

const LUDICO = 3

/**
 * Dropdown con contenido personalizable
 * Muestra Dropdown base e items creados según necesidad
 * @example<NewDropdown
 *           btnIcon={'bullet-list'}
 *           btnTitle={}
 *           btnTitleMobile={}
 *           dropdownType={'test'}
 *           headerTitle={title}
 *           items={answers}/>
 */
const NewDropdown = ({
  btnIcon,
  btnTitle,
  btnTitleMobile,
  dropdownType,
  headerTitle,
  items = [],
  activeQuestion,
  userInfo,
  indicator,
  level,
  displayDropdown,
  levels,
}: NewDropProps) => {
  const { t } = useTranslation()
  const { resourceId } = useParams<RouteParams>()
  const [stars, setStars] = useState<Indicator>()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [activeNavigation, setActive] = useState(Number(resourceId) !== 0)
  const [activeNavId, setNavId] = useState(Number(resourceId))
  const toggle = () => {
    setDropdownOpen((prevState) => !prevState)
    displayDropdown && displayDropdown(!dropdownOpen)
  }

  /**
   * Calcula las estrellas.
   * Mantiene las de la clase o calcula las de los Childrens
   */
  useEffect(() => {
    const activeItem = items.find((item) => item.id === activeNavId)!
    if (!activeNavId) {
      setStars(indicator!)
    } else if (activeItem) {
      const total = activeItem.Childrens?.reduce(
        (acc, child) => {
          const starIndicator = child.indicators?.find(
            ({ id, image }) => id === LUDICO || image === 'estrellas'
          )
          if (starIndicator) {
            acc.max += starIndicator.max!
            acc.obtained += starIndicator.obtained!
          }
          return acc
        },
        { max: 0, obtained: 0 }
      )
      setStars({
        ...indicator!,
        ...total,
      })
    }
  }, [indicator, activeNavId, items])

  return (
    <Style.Wrapper type={dropdownType} titleMobile={btnIcon} data-testid="menu-user">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        {dropdownType === 'avatar' ? (
          <Style.AvatarDrop>
            <DropdownToggle caret>
              <Avatar
                fullName={userInfo?.fullName ? userInfo.fullName : ''}
                userId={userInfo?.id ? userInfo.id : 0}
                picture={userInfo?.picture ? userInfo.picture : ''}
                size={30}
                fontSize={14}
                id="CrossAvatar"
              />
            </DropdownToggle>
          </Style.AvatarDrop>
        ) : showDropdown(items) ? (
          <Pressed disabled={dropdownType === 'helpCenter'}>
            <Style.ButtonDrop type={dropdownType}>
              <DropdownToggle caret id={getIdButtonDropdown(dropdownType)}>
                {btnIcon && (
                  <Icon name={btnIcon || ''} id={getIdButtonDropdown(dropdownType)} fill="#fff" />
                )}
                <span id={getIdButtonDropdown(dropdownType)} className="Desktop_title">
                  {t(`${btnTitle}`)}
                </span>
                {btnTitleMobile && (
                  <span id={getIdButtonDropdown(dropdownType)} className="Mobile_title">
                    {t(`${btnTitleMobile}`)}
                  </span>
                )}
              </DropdownToggle>
            </Style.ButtonDrop>
          </Pressed>
        ) : (
          <></>
        )}
        <Style.DropdownBox type={dropdownType} icon={btnIcon!}>
          <DropdownMenu aria-hidden>
            <HeaderDrop
              aria-hidden
              dropdownType={dropdownType}
              userInfo={userInfo}
              headerTitle={headerTitle}
              activeNavigation={activeNavigation}
              setActive={setActive}
              items={items}
              activeNavId={activeNavId}
              setNavId={setNavId}
              stars={stars}
            />
            <Style.Itemdrop
              items={items || null}
              type={dropdownType}
              className={dropdownOpen ? 'openDrop' : 'closedDrop'}
              role="list"
            >
              <Items
                dropdownType={dropdownType}
                items={items}
                activeQuestion={activeQuestion}
                activeNavigation={activeNavigation}
                setActive={setActive}
                setNavId={setNavId}
                activeNavId={activeNavId}
                level={level}
                levels={levels}
              />
            </Style.Itemdrop>
          </DropdownMenu>
        </Style.DropdownBox>
      </Dropdown>
    </Style.Wrapper>
  )
}

export default NewDropdown

const showDropdown = (items) => {
  // TODO: a futuro si el control/test trae pregunta cloze, la API no me debiese de mandar link de navegación
  const isCloze = items.find((item) => item.config?.ItemType?.id === 24) // 24 es el valor de la pregunta cloze

  if (isCloze) return false

  return true
}
