import React from 'react'
// import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import Select, { components } from 'react-select'
import { vars } from '@eclass/ui-kit'

import { accordionClosed, calendarDate } from 'assets/svg/'
import { Types } from './types'
import { Wrapper } from './Styles'

const CaretDownIcon = ({ active, type }: Types.Status) => <Arrow active={active} type={type} />

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <CaretDownIcon
      active={props.selectProps.menuIsOpen}
      type={props.selectProps.name === 'yearSearch' ? 'date' : ''}
    />
  </components.DropdownIndicator>
)

export const setValueEmpty = (item: Types.option) => {
  return item && item.value && item.value > 0 ? item : ''
}

export const MySelect = ({
  options,
  placeholder = 'Selecciona',
  value,
  onChange,
  onClick,
  disabled = false,
  name,
}: Types.Props) => {
  const handleChange = React.useCallback(
    (e: any) => {
      if (e.value) {
        onChange(e)
      }
    },
    [onChange]
  )

  return (
    <Wrapper>
      <Select
        className="wrapper-react-select"
        id={name}
        name={name}
        components={{ DropdownIndicator }}
        options={options}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        isDisabled={disabled}
        styles={stylesMySelect}
      />
    </Wrapper>
  )
}

const stylesMySelect = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  control: (provided, state) => ({
    ...provided,
    height: '48px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: vars('colors-main-deepSkyBlue'),
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: '2px',
    zIndex: 9999,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    paddingRight: '13px',
    '& svg': {
      width: state.selectProps.menuIsOpen === true ? '35px' : '25px',
      height: '25px',
    },
    transform:
      state.selectProps.menuIsOpen && state.selectProps.name !== 'yearSearch'
        ? 'rotate(180deg)'
        : '',
    color: state.selectProps.menuIsOpen === true ? '#0189ff' : '',
  }),
  container: (provided, state) => ({
    ...provided,
    border: state.isFocused ? 0 : 0,
  }),
}

export const Arrow = styled.div<Types.Status>`
  :after {
    content: url(${({ type }) => (type === 'date' ? calendarDate : accordionClosed)});
    position: absolute;
    right: ${({ active, type }) => (active && type !== 'date' ? '-15px' : '20px')};
    top: 50%;
    transform: scale(0.94) translateY(-50%);
    width: 16px;
  }
`
