import { vars } from '@eclass/ui-kit'

export const Off = () => (
  <svg width={16} height={16} fill="none">
    <path
      fill={vars('colors-main-blueGrey')}
      fillRule="evenodd"
      d="M12.285 10.577A4.996 4.996 0 0 0 13 8a4.993 4.993 0 0 0-1.249-3.307 1.001 1.001 0 0 0-1.5 1.324 2.995 2.995 0 0 1 .545 3.07l1.49 1.49Zm-2.023-.607-.012.014a1 1 0 0 0 1.425 1.399L10.262 9.97Z"
      clipRule="evenodd"
    />
    <path
      fill={vars('colors-main-blueGrey')}
      fillRule="evenodd"
      d="M14.677 12.969A10.008 10.008 0 0 0 16 7.999c0-2.84-1.215-5.556-3.332-7.451a1 1 0 0 0-1.334 1.49A8.014 8.014 0 0 1 14 8a8 8 0 0 1-.802 3.49l1.479 1.479Zm-.554.862-1.438-1.439a7.998 7.998 0 0 1-1.35 1.57 1 1 0 0 0 1.333 1.49 9.993 9.993 0 0 0 1.455-1.621Z"
      clipRule="evenodd"
    />
    <path
      fill={vars('colors-main-blueGrey')}
      d="M14.5 15.4a.501.501 0 0 1-.353-.145l-14-14A.5.5 0 0 1 .855.547l14 14a.5.5 0 0 1-.354.854Z"
    />
    <path
      fill={vars('colors-main-blueGrey')}
      fillRule="evenodd"
      d="M9.333 7.625V1.333A.667.667 0 0 0 8.267.8l-3.29 2.468 4.356 4.357Zm-5.166-3.75-1.056.792H.833c-.368 0-.833.298-.833.666v5.334c0 .368.465.666.833.666h2.278L8.267 15.2a.664.664 0 0 0 .698.063.667.667 0 0 0 .368-.596V9.04L4.167 3.875Z"
      clipRule="evenodd"
    />
  </svg>
)
