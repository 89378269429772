import { useEffect } from 'react'
import { Box } from '@chakra-ui/react'
import { vars } from '@eclass/ui-kit'

import { Pause } from './Pause'
import { Play } from './Play'
import { IAudio } from '../..'
import { analytic } from 'app/Services'

interface IPlayPause extends IAudio {
  time: number
  isPlaying: {
    value: boolean
    set: (value: boolean) => void
  }
  duration: number
}
export const PlayPause = ({ audio, time, isPlaying, duration }: IPlayPause) => {
  useEffect(() => {
    if (time === 0) {
      isPlaying.set(false)
    }
  }, [time])

  const onClick = () => {
    if (duration > 0 && audio) {
      let value = false
      if (!isPlaying.value) {
        if (time === 0) {
          audio.currentTime = 0
        }
        audio.play()
        value = true
      } else {
        audio.pause()
      }
      isPlaying.set(value)

      analytic.customEvent({
        category: 'Podcast',
        action: 'Play/pause',
        value: value ? 'Play' : 'Pause',
      })
    }
  }
  return (
    <Box
      as="button"
      alignItems="center"
      bg={vars('colors-main-deepSkyBlue')}
      border="none"
      borderRadius={vars('radii-rounded')}
      cursor="pointer"
      display="flex"
      justifyContent="center"
      h="2.5rem"
      onClick={onClick}
      overflow="hidden"
      p={0}
      position="relative"
      transition="background .3s ease-in-out"
      w="2.5rem"
      id="podcastPlayPause"
      _hover={{
        bg: vars('colors-main-azureRadiance'),
      }}
    >
      {!isPlaying.value ? <Play /> : <Pause />}
    </Box>
  )
}
