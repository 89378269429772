import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useQuery } from '@apollo/client'

import * as Apollo from 'app/Apollo'
import { Types } from '../types'
import { Types as TypesPhone } from '../Common/Phone/types'

/**
 * Obtengo listado de tipos de telefonos, como a su vez
 * @returns
 */
export const useTelephoneData = () => {
  const { getValues, setValue } = useFormContext()

  const [options, setOption] = React.useState<Types.select[]>([])
  const [isChekedWhatsapp, setIsChekedWhatsapp] = React.useState(false)
  const [isChekedCanContact, setIsChekedCanContact] = React.useState(false)

  const { loading, data, error } = useQuery<Apollo.ALL_PHONE_TYPE_2S_TYPE>(Apollo.ALL_PHONE_TYPE_2S)

  const getPhoneList = React.useCallback(async () => {
    const list = data?.allPhoneType2s.map((x) => {
      return {
        label: x.name,
        value: x.id,
      }
    })

    /** Se remueven de la lista solo id Móvil y Fijo */
    const newlist = list?.filter((opt) => opt.value === 1 || opt.value === 2)

    /** Renombro los elementos con los nuevos labels */
    const renameTelephoneName = newlist?.map((elem) => {
      let obj = { ...elem }
      if (elem.value === 1) {
        obj = { ...elem, label: 'Fijo' }
      }
      if (elem.value === 2) {
        obj = { ...elem, label: 'Móvil' }
      }
      return obj
    })

    if (renameTelephoneName) {
      setOption(renameTelephoneName)
      if (getValues().phoneTypeId) {
        setValue(
          'phoneTypeId',
          renameTelephoneName.find((option) => option.value === getValues().phoneTypeId)
        )
      }
    }
  }, [setOption, getValues, setValue, data])

  React.useEffect(() => {
    if (options?.length === 0 && data && data?.allPhoneType2s?.length > 0) {
      getPhoneList()
    }
  }, [data, options])

  const onChange = (newValue: string, newData: TypesPhone.dataFull) => {
    setValue('countryCode', newData.dialCode)
  }

  React.useEffect(() => {
    const valueContext = getValues().isWhatsapp
    const canContactContext = getValues().canContactWhatsapp
    if (typeof valueContext === 'boolean' && isChekedWhatsapp !== valueContext) {
      setIsChekedWhatsapp(getValues().isWhatsapp)
    }
    if (typeof canContactContext === 'boolean' && isChekedCanContact !== canContactContext) {
      setIsChekedCanContact(getValues().canContactWhatsapp)
    }
  }, [getValues().isWhatsapp, isChekedWhatsapp, getValues().canContactWhatsapp, isChekedCanContact])

  const handleWhatsapp = (newValue: boolean) => {
    setIsChekedWhatsapp(newValue)
  }

  const handleCanContact = (newValue: boolean) => {
    setIsChekedCanContact(newValue)
  }

  return {
    loading,
    error,
    options,
    onChange,
    isChekedWhatsapp,
    handleWhatsapp,
    handleCanContact,
  }
}
