import { format, parseISO, isValid } from 'date-fns'
import es from 'date-fns/locale/es'

/**
 * Retorno fecha con formato solicitado
 * @todo i18n - hacer obligatorio el tercer parámetor
 * @example
 * formatDate(item.date, 'd')
 */
export const formatDate = (date: Date, dateformat: string, locale = es) => {
  const isValidDate = typeof date === 'string' ? isValid(parseISO(date)) : isValid(date)

  if (isValidDate) {
    return format(new Date(date), dateformat, {
      locale,
    })
  }
  return ''
}
