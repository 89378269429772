export const getTypeAnswer = (type: string) => {
  const abbr = {
    NUMERICAL: ['NUMERICAL', 'NM'],
    MULTICHOICE_S: ['MULTICHOICE_S', 'MCS'],
    SHORTANSWER: ['SHORTANSWER', 'SA', 'MW'],
    MULTICHOICE: ['MULTICHOICE', 'MC'],
  }

  // Buscar el tipo correspondiente en el objeto abrei
  const foundType = Object.keys(abbr).find((key) => abbr[key].includes(type))

  // Si se encuentra el tipo correspondiente, devolver el nombre del tipo
  if (foundType) {
    return foundType
  } else {
    // Si el tipo no se encuentra, devolver un mensaje de error o manejarlo según sea necesario
    return false
  }
}
