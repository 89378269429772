import { useTranslation } from 'react-i18next'

import './style.scss'
import { Icon } from 'app/Components/UI/Icons/Icon'
import { ContentParser } from 'app/Components'
import { testsStates } from '../../testsStates'
import { stateQuestion } from '../../helpers/stateQuestion'
import { EvaluationConfigFeedback } from '@eclass/api'

type Props = {
  evaluationState: number
  showFeedback: boolean
  answer: any
  type?: EvaluationConfigFeedback
}

const INCORRECTAS_SIN_FEEDBACK = 4

export const Feedback = ({ evaluationState, showFeedback, answer, type }: Props) => {
  const { t } = useTranslation()
  if (
    !showFeedback ||
    !answer.feedback ||
    (showFeedback && type?.id === INCORRECTAS_SIN_FEEDBACK)
  ) {
    return null
  }

  if (evaluationState === testsStates.EVALUATED && answer.state !== stateQuestion.NOT_KNOWN) {
    let icon = ''
    let text = ''
    if (answer.state === stateQuestion.OMITTED) {
      icon = 'circularNeutral'
      text = t('ResourceQuestionOmitted')
    } else {
      if (answer.feedback.correct) {
        icon = 'circularCheck'
        text = t('ResourceCorrect')
      } else {
        const { total, obtained } = answer.feedback.score
        const multiplePointsQuestion = total > 1
        const belowMaxPoints = obtained > 0 && obtained < total
        if (multiplePointsQuestion && belowMaxPoints) {
          icon = 'partialCheck'
          text = t('ResourcePartiallyCorrect')
        } else {
          icon = 'circularError'
          text = t('ResourceIncorrect')
        }
      }
    }

    return (
      <div data-testid="Feedback" className="Feedback FeedbackQuestion">
        <div className="State">
          <Icon viewBox={17} width={17} name={icon} />
          <b>{text}</b>
        </div>
        {answer.feedback?.text && (
          <div className="Text stylesInside">
            {ContentParser({
              content: answer.feedback.text,
              embeds: answer.embeds,
            })}
          </div>
        )}
        {answer.feedback.comment && answer.feedback.comment.length > 0 && (
          <>
            {answer.feedback.text ? <hr /> : ''}
            <div>
              <Icon viewBox={17} width={17} name="profile-icon" fill={'#b0cfe0'} />
              <b>{t('ResourceFeedback')}</b>
            </div>
            <div className="Text">{answer.feedback.comment}</div>
          </>
        )}
      </div>
    )
  } else {
    return null
  }
}
