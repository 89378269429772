import { ISelect, IInputsCommon } from '../types'

export const Select = ({ answer, disabled, save, configId, value, id }: IInputsCommon<ISelect>) => {
  if (!answer?.options || answer.options.length === 0) {
    return <></>
  }

  const onChange = (e) => {
    if (e.target.value) {
      save(configId, Number(e.target.value))
    }
  }

  return (
    <select
      id={id}
      disabled={disabled}
      onChange={onChange}
      defaultValue={value !== undefined ? value : ''}
      data-testid={`cloze-${answer.type}`}
    >
      <option value="" disabled></option>
      {answer.options.map((option, key) => {
        return (
          <option key={key} value={option.value}>
            {option.option}
          </option>
        )
      })}
    </select>
  )
}
