import * as type from '../types'

export const Redo = ({ className = '', fill = 'white', height = 15, width = 15 }: type.basic) => {
  return (
    <svg
      data-testid="icon-stop"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={`0 0 ${width} ${height}`}
    >
      <path
        fill={fill}
        d="M8.262 0l.749 6.486 2.246-2.128a4.89 4.89 0 010 7.095c-.963 1.013-2.353 1.52-3.744 1.52-1.39 0-2.78-.507-3.743-1.52a4.89 4.89 0 010-7.095c.642-.608 1.497-1.115 2.46-1.317l-.642-1.926a7.421 7.421 0 00-3.422 1.824c-2.888 2.737-2.888 7.196 0 10.034C3.556 14.29 5.48 15 7.406 15c2.032 0 3.85-.71 5.241-2.027 2.888-2.737 2.888-7.196 0-10.034L15 .71 8.262 0z"
      ></path>
    </svg>
  )
}
