import React from 'react'
import VizSensor from 'react-visibility-sensor'

type Props = {
  children: JSX.Element
  setter: Function
  id: number | string
}

export const VisibilitySensor = ({ children, setter, id }: Props) => {
  return (
    <VizSensor
      onChange={(isVisible) => {
        isVisible ? setter(id) : setter('')
      }}
    >
      {children}
    </VizSensor>
  )
}
