import { vars } from '@eclass/ui-kit'
import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  .Classes__Indicators {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0px;
    font-size: 12px;
    margin-bottom: 0px;
    color: ${vars('colors-neutral-gray')};
    grid-row-gap: 8px;

    .Indicator__Name.Material {
      display: none;
    }

    .Indicator-li {
      display: flex;
      align-items: center;
      border-bottom: none !important;
      font-size: 14px;
      svg {
        margin-right: 8px;
      }
    }

    &.Multiple {
      .Indicator {
        &:not(:last-child) {
          border-right: 1px solid ${vars('colors-neutral-platinum')};
          padding-right: 10px;
          margin-right: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 660px) {
    .Classes__Indicators {
      flex-wrap: wrap;
      &.calendar {
        flex-direction: column;
        gap: 8px;
        .Indicator-li {
          &.calendar {
            border-right: none !important;
            padding-right: 0px !important;
          }
          &.time,
          &.map-indicator {
            padding-left: 0px !important;
            justify-content: flex-start;
          }
          &.remote {
            padding-left: 0px !important;
            justify-content: flex-start;
          }
        }
      }
    }
  }
`
