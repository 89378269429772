import { FooterBoxes } from '@eclass/api'
import { Coordinator } from './CoordinatorBox/Coordinator'
import { FrequentQuestions } from './FrequentQuestionsBox/FrequentQuestions'

/**
 * Devuelvo componente correspondiente al tipo de caja que se requiera
 */
interface IHandleTypeBox {
  box: FooterBoxes
  isSence: boolean
}

export const HandleTypeBox = ({ box, isSence = false }: IHandleTypeBox) => {
  if (!box || !box.type) {
    return null
  }

  switch (box.type) {
    case 'CONTACT_TO_YOU_ACADEMIC_COORDINATOR':
      return <Coordinator data={box} />
    case 'FREQUENT_QUESTIONS':
      return <FrequentQuestions data={box} isSence={isSence} />
    default:
      return null
  }
}
