import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Store } from 'app/Redux/types'

export const useLogic = () => {
  const {
    loggedUser,
    ui: { isMobile },
  } = useSelector((state: Store) => state)
  const { pathname } = useLocation()

  const [loading, setLoading] = React.useState(true)
  const [logged, setLogged] = React.useState(false)
  const [user, setUser] = React.useState({})

  React.useEffect(() => {
    const currentLogged = Object.keys(loggedUser).length > 0
    if (currentLogged) {
      setUser(loggedUser.user!)
    }
    setLogged(currentLogged)
    setLoading(false)
  }, [loggedUser])

  const hasPermissions = ['confirmation'].every((url) => !pathname.includes(url))

  return {
    isMobile,
    loading,
    logged,
    user,
    hasPermissions,
  }
}
