import React from 'react'

export const TestSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    data-testid="TestResourceIcon"
    viewBox="0 0 30 30"
  >
    <g fill="#E0EEFA" fillRule="nonzero" data-testid="TestSvg">
      <path d="M4 0C1.8 0 0 1.8 0 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zM4 11c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zM4 22c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4zM29 2H12c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1h17c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1zM29 13H12c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1h17c.6 0 1-.4 1-1v-2c0-.6-.4-1-1-1zM29 24H12c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1h17c.6 0 1-.4 1-1v-2c0-.6-.4-1-1-1z" />
    </g>
  </svg>
)
