import { gql } from '@apollo/client'

import * as Type from '../types'

export const ALL_IDENTIFICATION_DOCUMENTS = gql`
  query allIdentificationDocuments {
    allIdentificationDocuments(orderBy: sortOrder_ASC, filter: { loginTypeId_not: 0 }) {
      name
      id
    }
  }
`

export interface ALL_IDENTIFICATION_DOCUMENTS_TYPE {
  allIdentificationDocuments: Type.list[]
}
