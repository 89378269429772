// @External Dependencies
import axios from 'axios'
// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { axiosError } from '../../../Services'
import { encryptData } from './ecrypto'

/**
 * @typedef {Object} FilterType
 * @property {string} code - Código del Usuario.
 * @property {string} passwd - Nueva contraseña.
 * @property {string} campusEmail - Email del campus.
 * @property {string} captchaToken - Token recaptcha.
 */

/**
 * Busca un usuario en la base de datos según el Tipo. Si es email busca en la tabla de Personas por el email, sino lo encuentra busca entre los Usuarios de tipo Mail
 * @param {FilterType} input - Recibe el input con el Tipo Usuario y el string de búsqueda
 * @example
 * ```js
 *  const { status } = await UserChangePasswd(input)
 * ```
 * @returns Retorna el estado si envió el mail o no.
 */
const UserChangePasswd = (input) => {
  const requestId = 'UserChangePasswd'
  return async (dispatch) => {
    const query = `
      mutation UserChangePasswd($UserChangePasswdInput: UserChangePasswdInput!) {
        UserChangePasswd(input: $UserChangePasswdInput) {
          status {
            code
            detail
            name
            success
          }
        }
      }
    `

    const variables = {
      UserChangePasswdInput: {
        ...input,
        passwd: encryptData(input.passwd),
        forceSecure: true,
      },
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then((response) => response.data.data.UserChangePasswd)
      .catch((err) => {
        axiosError(err, requestId, dispatch)
      })
  }
}

export default UserChangePasswd
