import React from 'react'
import { useTranslation } from 'react-i18next'

import { useTelephoneData } from './useTelephoneData'
import { Select, CheckBox, Phone } from '../Common'
import * as Style from './style'

/**
 * Campos para agregar número téfonico
 */
export const Telephone = ({ profile = false, isSubmit = false }) => {
  const { t } = useTranslation()
  const { options, onChange, isChekedWhatsapp, handleWhatsapp, handleCanContact } =
    useTelephoneData()

  const classIsProfile = profile ? 'profile' : ''
  const ClassIsChekedWhatsapp = isChekedWhatsapp ? 'isChekedWhatsapp' : ''

  return (
    <Style.Wrapper className={`ProfileForms-Telephone ${classIsProfile} ${ClassIsChekedWhatsapp}`}>
      <p className="profile-paragraphPhone">{t('ProfilePrivacityPhone')}</p>
      {/* TIPO */}
      <Select
        name="phoneTypeId"
        label={t('ProfilePhoneType')}
        placeholder={t('ResourceSelect')}
        options={options}
        disabled={isSubmit}
      />
      {/* NÚMERO */}
      <Phone
        name="phoneNumber"
        label={t('ProfilePhoneNumber')}
        onChange={onChange}
        disabled={isSubmit}
      />
      <CheckBox
        name="canContactWhatsapp"
        text={t('ProfilePermissionWay')}
        disabled={isSubmit}
        onChange={handleCanContact}
      />
      <CheckBox
        name="isWhatsapp"
        text={t('ProfileMyWhatsapp')}
        onChange={handleWhatsapp}
        disabled={isSubmit}
      />
    </Style.Wrapper>
  )
}
