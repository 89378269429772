import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { getToken, axiosError } from '../../../Services'

/**
 * @typedef {Object} FilterType
 * @property {number} programId - Programa
 * @property {number} studentId - Alumno Curso
 * @property {number} courseId - Curso
 * @property {number} unitId - Unidad
 * @property {number} resourceId - Recurso
 * @property {number} evaluationId - Evaluación
 * @property {boolean} [finalize] - Finalizar
 */

/**
 * Calcula la nota de una prueba.
 * @param {FilterType} input - Curabitur maximus tellus nec
 * @example
 * ```js
 *  const params = { programId, studentId, courseId, unitId, resourceId }
 *  const response = await EvaluationQualify(params)
 * ```
 * @returns Retorna el objeto de una evaluación nueva o alguna que ya exista.
 */
const EvaluationQualify = (input) => {
  const requestId = 'EvaluationQualify'
  return async (dispatch) => {
    getToken()

    const query = `
      mutation EvaluationQualify($EvaluationQualifyInput: EvaluationQualifyInput!) {
      EvaluationQualify(input: $EvaluationQualifyInput) {
        status {
          success
          name
          detail
          code
        }
        score {
          total
          obtained
        }
        qualification {
          value
          valueFormatted
          status {
            enabled
            name
            detail
            style
          }
        }
        completedDate
        folio
        Evaluation {
          qualification {
            valueFormatted
            status {
              id
              name
              detail
              style
            }
          }
          config {
            id
            hasTime
            timeRemaining
            state
            showAnswers
            score {
              total
              obtained
            }
            repeat {
              hideButton
              criterial
              total
              attempt
            }
            startDate
            endDate
            completedDate
            daysRemaining
            folio
            completed
            hasRevision
            showFeedback
            canAnswer
            showQualification
            hasProctoring
            hasRevisionQuestions
          }
          header {
            name
            Type {
              id
              name
            }
          }
          Levels {
            id
            name
            Stages {
              id
              name
              Answers {
                instructions
                content
                answer
                embeds {
                  id
                  name
                  configurations
                  typeId
                }
                config {
                  id
                  evaluationId
                  levelId
                  stageId
                  mandatory
                  sortOrder
                  ItemType {
                    id
                    name
                  }
                }
                feedback {
                  correct
                  score {
                    total
                    obtained
                  }
                  skipped
                  text
                  status {
                    id
                    enabled
                    name
                    detail
                    style
                  }
                }
              }
            }
          }
          navigation {
            prev {
              name
              href
              type {
                id
                name
              }
              status {
                id
                enabled
                id
                detail
                style
              }
            }
            next {
              name
              href
              type {
                id
                name
              }
              status {
                id
                enabled
                id
                detail
                style
              }
            }
          }
        }
      }
    }
    `
    const variables = {
      EvaluationQualifyInput: input,
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then((response) => response.data.data.EvaluationQualify)
      .catch((err) => axiosError(err, requestId, dispatch))
  }
}

export default EvaluationQualify
