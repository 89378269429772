import { useParams, useHistory } from 'react-router-dom'

import { Props, Types } from './types'
import { useRedux } from './useRedux'
import { isIOS } from 'app/Services'

export const useLogic = ({ unit }: Props.useLogic) => {
  const params = useParams<Types.params>()
  const { setModal, storePDF } = useRedux()
  const history = useHistory()

  /**
   * abro pestaña para la descarga, si es toda la unidad necesito el id de ella
   * @param unitId {number}
   */
  const openViewPDF = (unitId?: number) => {
    const paramsCommon = [
      params.programId,
      params.studentId,
      params.courseId,
      unitId || params.unitId,
    ]

    if (!unitId) {
      paramsCommon.push(params.resourceId)
    }

    if (isIOS()) {
      const url = [document.location.origin, 'resource/pdf', ...paramsCommon]
      window.open(url.join('/'))
    } else {
      const url = ['/resource/pdf', ...paramsCommon]
      history.push(url.join('/'))
    }

    setModal('NULL')
  }

  // Evento al presionar el boton de descarga
  const clickButton = (id: number) => {
    if (unit) {
      setModal('UNIT', id)
      // levanto modal unidad
    } else {
      openViewPDF()
    }
  }

  /**
   * Obtengo id de la unidad y redirecciono
   * @param id {number}
   */
  const getResources = (id: number) => {
    openViewPDF(id)
  }

  return {
    clickButton,
    getResources,
    storePDF,
  }
}
