export const HorizontalFlag = (props) => {
  return (
    <svg
      {...props}
      width="12"
      height="17"
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 0.5C0.9 0.5 0 1.4 0 2.5V16.5L6 13.5L12 16.5V2.5C12 1.4 11.1 0.5 10 0.5H2Z"
        fill="#B0CFE0"
      />
    </svg>
  )
}
