import { useTranslation } from 'react-i18next'
import { Alert } from '@eclass/ui-kit'

type AlertTypes = {
  show: boolean
  answer: any
}

/**
 * Se renderiza si la pregunta no fue contestada y es obligatoria
 * @param show {boolean}
 * @param answer {any}
 */
export const QuestionAlert = ({ show, answer }: AlertTypes) => {
  const { t } = useTranslation()

  if (show && answer.state === 0 && answer.config?.mandatory) {
    return (
      <Alert state="error" m="0 0 16px">
        {t('ResourceMandatory')}
      </Alert>
    )
  }

  return null
}
