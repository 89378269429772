import React from 'react'
import ReactHtmlParser from 'react-html-parser'

import { Props } from './types'
import { Icon } from 'app/Components/UI/Icons'
import { Pressed, DelayLink, UIVars } from 'app/Components/UI'
import * as Style from './style'
import { Truncate } from 'app/Components/UI/Truncate'
import { HandleIcon } from './'

export const Button = ({
  next = false,
  prev = false,
  statusStyle,
  type,
  href,
  label,
  title,
  disabled = false,
  id,
}: Props.Button) => {
  const ResourceFooterBlueBox = () => (
    <Style.Arrow className={`Arrow ${typeButton} ${type}`} id={id}>
      <Icon name="arrow-big" className="arrow-big" fill={UIVars.setColors.whiteThree} />
    </Style.Arrow>
  )

  /**
   * Parseo string solo si trae elemento html dentro
   */
  const handleParser = () => {
    let setTitle = title
    const parserTitle = ReactHtmlParser(title!)
    if (typeof parserTitle[0] === 'object') {
      // suponiendo que solo viene un elemento html
      setTitle = parserTitle[0].props.children[0]
    } else if (typeof parserTitle[0] === 'string') {
      setTitle = parserTitle.join()
    }
    return setTitle
  }
  const typeButton = next ? 'next' : 'prev'
  const classDisabled = disabled ? 'disabled' : ''

  return (
    <Style.Button role="NavButton" className={`${typeButton} ${classDisabled}`}>
      <Pressed under={false} color={UIVars.setColors.blackThree} opacity={0.1} disabled={disabled}>
        <DelayLink
          role="NavButton-link"
          className={`${typeButton} ${statusStyle || ''} ${type}`}
          disabled={disabled}
          to={href!}
          id={id}
        >
          {prev && ResourceFooterBlueBox()}
          <Style.Content className={typeButton} id={id}>
            {next && <HandleIcon type={type} />}
            <div className="content__text">
              <span>{label}</span>
              <Truncate text={handleParser()!} />
            </div>
            {prev && <HandleIcon type={type} />}
          </Style.Content>
          {next && ResourceFooterBlueBox()}
        </DelayLink>
      </Pressed>
    </Style.Button>
  )
}
