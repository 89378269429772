/** Calcula el nombre compuesto por unidad y recurso donde fue cargada la pregunta
 * y sus posibles variantes si falta el nombre de la unidad
 * @example 'Unidad 1 "Soporte en la era digital", Recurso 1'
 * @param {string} unitName - Nombre de la unidad
 * @param {string} resourceName - Nombre del recurso
 * @returns {string} - Nombre compuesto por unidad y recurso
 */
export const compoundUnitResourceName = ({
  unitName,
  resourceName,
}: {
  unitName?: string
  resourceName?: string
}): string | undefined => {
  let name

  if (unitName) {
    name = unitName
  }

  if (resourceName) {
    if (name) {
      name += `, ${resourceName}`
    } else {
      name = resourceName
    }
  }

  return name
}
