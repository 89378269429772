import { exerciseWithAnswerId, exerciseWithAQuestion } from './index'
import {
  Alternatives,
  Scale,
  File,
  Text,
  Select,
  TypeIn,
  SelectInTheBlank,
  Reorder,
  TextAndMultimedia,
  Cloze,
} from '../QuestionTypes'

import {
  alternatives,
  scale,
  multipleChoiceTest,
  multipleAnswer,
  multipleChoice,
  typeIn,
  selectInTheBlank,
  select,
  text,
  file,
  reorder,
  textAndMultimedia,
  close,
} from './idsQuetionTypes'
import { numberOfQuestion } from 'app/Views/Tests/helpers/numberOfQuestion'

export const getQuestion = (
  answers,
  excercises,
  setActiveQuestion,
  evaluationState,
  showFeedback,
  action,
  disabled,
  isMobile,
  gridOptions,
  t,
  hasLevelsStages,
  missingAnswers,
  stages,
  stageIndex,
  canGenerateActivity,
  Feedback,
  levels
) => {
  const arrayOfQuestions = []

  let newAnswers = answers
  if (excercises !== undefined) {
    if (exerciseWithAQuestion.includes(excercises.Answer.config.ItemType.id)) {
      newAnswers = [
        {
          ...excercises.Answer,
          answerId: excercises.Answer?.config.id,
        },
      ]
    } else {
      const setAnswers = JSON.parse(excercises.Answer.content)
      newAnswers = setAnswers.map((item) => {
        return {
          ...item,
          content: JSON.stringify(item.options),
          loading: false,
          option: 0,
        }
      })
    }
  }

  /**
   * Esto valida si estoy en un test de nivel con alumno anónimo
   */
  const isLevelTest = window.location.href.includes('test-level')

  newAnswers.forEach((answer, index) => {
    const id = excercises
      ? exerciseWithAnswerId.includes(excercises.Answer.config.ItemType.id)
        ? excercises.Answer?.config?.id
        : answer.answerId || answer?.config?.id
      : answer?.config?.id
    const typeId = excercises ? excercises.Answer?.config.ItemType.id : answer?.config?.ItemType.id
    const props = {
      id,
      setActiveQuestion,
      evaluationState,
      showFeedback,
      key: id,
      position: numberOfQuestion({
        stages,
        id,
        hasLevelsStages,
        index,
        stageIndex,
        isLevelTest,
        levels,
      }),
      answer,
      score: isLevelTest ? { total: 0, obtained: 0 } : answer.feedback?.score,
      action,
      optionDisabled: isLevelTest
        ? false
        : disabled || answer.loading || answer.skipped || !canGenerateActivity,
      selected: answer.option,
      isMobile,
      missingAnswers,
      hasLevelsStages,
      feedback: Feedback,
    }
    switch (typeId) {
      case scale:
        arrayOfQuestions.push(<Scale {...props} />)
        break
      case alternatives:
      case multipleChoiceTest:
      case multipleChoice:
      case multipleAnswer:
        arrayOfQuestions.push(
          <Alternatives
            {...props}
            type={typeId}
            gridOptions={gridOptions}
            excercises={excercises}
            id={id}
            lastChild={index === newAnswers.length - 1}
          />
        )
        break
      case file:
        arrayOfQuestions.push(<File {...props} />)
        break
      case text:
        props.optionDisabled = disabled
        arrayOfQuestions.push(<Text {...props} />)
        break
      case select:
        arrayOfQuestions.push(<Select {...props} />)
        break
      case typeIn:
        arrayOfQuestions.push(<TypeIn {...props} excercises={excercises} />)
        break
      case selectInTheBlank:
        arrayOfQuestions.push(<SelectInTheBlank {...props} excercises={excercises} />)
        break
      case reorder:
        arrayOfQuestions.push(<Reorder {...props} excercises={excercises} key={index} />)
        break
      case textAndMultimedia:
        arrayOfQuestions.push(<TextAndMultimedia {...props} excercises={excercises} key={index} />)
        break
      case close:
        arrayOfQuestions.push(<Cloze {...props} excercises={excercises} key={index} />)
        break
      default:
        arrayOfQuestions.push(<div key={index}>{t('ResourceInvalidQuestionType')}</div>)
        break
    }
  })
  return arrayOfQuestions
}
