import { Box } from '@chakra-ui/react'
import { BtnTertiary, vars } from '@eclass/ui-kit'
import { DownloadCertificateInput } from '@eclass/api'

import { Icon } from 'app/Components/UI/Icons'
import { Modal } from './components'
import { useDownload } from './hooks/useDownload'

export interface ICertificateDownload {
  label: string
  config: DownloadCertificateInput
}
export const CertificateDownload = ({ label, config }: ICertificateDownload) => {
  const { status, download } = useDownload(config)

  return (
    <>
      <Box
        sx={{
          button: {
            p: 0,
            gap: '.5rem',
            '>span': { display: 'none' },
            '>svg': { m: 0 },
          },
        }}
      >
        <BtnTertiary iconStatus="noIcon" onClick={download}>
          <Icon name="download" fill={vars('colors-main-blueGrey')} />
          {label}
        </BtnTertiary>
      </Box>
      <Modal isOpen={status === 'DOWNLOAD'} />
    </>
  )
}
