import { gql, useMutation, MutationHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const STUDENT_COURSE_ENDS = gql`
  ${STATUS_FIELDS}
  mutation StudentCourseEnd($StudentCourseEndInput: StudentCourseEndInput!) {
    StudentCourseEnd(input: $StudentCourseEndInput) {
      status {
        ...StatusFields
      }
    }
  }
`
export type StudentCourseEndsPayload = Api.StudentCourseEndPayload

export type StudentCourseEndsInput = Api.StudentCourseEndInput

interface StudentCourseEndsPayloadApollo {
  StudentCourseEnd: StudentCourseEndsPayload
}

interface StudentCourseEndsInputApollo {
  StudentCourseEndInput: StudentCourseEndsInput
}

export const useMutationStudentCourseEnds = (
  options?: MutationHookOptions<StudentCourseEndsPayloadApollo, StudentCourseEndsInputApollo>
) => {
  return useMutation<StudentCourseEndsPayloadApollo, StudentCourseEndsInputApollo>(
    STUDENT_COURSE_ENDS,
    options
  )
}
