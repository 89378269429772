export const Night = (props) => {
  return (
    <g {...props}>
      <path
        d="M13.9 10.6001C11.9 11.1001 9.8 10.4001 8.3 9.00006C6.3 7.00006 5.9 3.90006 7.4 1.50006C7.9 0.800065 7.2 -0.199935 6.3 6.50231e-05C4.8 0.300065 3.4 1.10007 2.3 2.20007C0.8 3.70007 0 5.80007 0 7.90006C0 10.0001 0.8 12.1001 2.4 13.6001C3.9 15.2001 5.9 16.0001 8.1 16.0001C10.3 16.0001 12.3 15.2001 13.8 13.6001C14.2 13.2001 14.6 12.7001 15 12.1001C15.4 11.4001 14.8 10.4001 13.9 10.6001Z"
        fill="#B0CFE0"
      />
      <path
        d="M15.7 3.7C16.1 3.3 16.1 2.7 15.7 2.3C15.3 1.9 14.7 1.9 14.3 2.3C14 2.6 13 2.6 12.7 2.3C12.3 1.9 11.7 1.9 11.3 2.3C10.9 2.7 10.9 3.3 11.3 3.7C11.6 4 11.6 5 11.3 5.3C10.9 5.7 10.9 6.3 11.3 6.7C11.7 7.1 12.3 7.1 12.7 6.7C13 6.4 14 6.4 14.3 6.7C14.7 7.1 15.3 7.1 15.7 6.7C16.1 6.3 16.1 5.7 15.7 5.3C15.4 5 15.4 4 15.7 3.7Z"
        fill="#B0CFE0"
      />
    </g>
  )
}
