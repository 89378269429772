// @External Dependencies
import * as React from 'react'
import styled from 'styled-components/macro'
import ReCAPTCHA from 'react-google-recaptcha'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const Recaptcha = ({ className, verify, setCaptchaToken }: any) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      <p>{t('LogInNotRobot')}</p>
      <ReCAPTCHA
        sitekey="6LffuakUAAAAAD6DpHSbw5bxOlfHf714Daz-8X2m"
        onChange={(token: string) => {
          setCaptchaToken(token)
          verify(true)
        }}
      />
    </div>
  )
}

// @Proptypes
Recaptcha.propTypes = {
  verify: PropTypes.func.isRequired,
}

export default styled(Recaptcha)`
  margin-bottom: 20px;
  p {
    font-size: 12px;
    margin-bottom: 10px;
  }
`
