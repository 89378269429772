import { useEffect, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import axios from 'axios'
import { axiosError, axiosAbort, getToken } from '..'

import { apiURL } from '../../../Config/getDomainConfig'
import { Types } from 'app/Views/Courses/types'

/**
 * Custom hook para chequear si usuario viene de campus virtual.
 *
 * Si viene de CV se redirecciona a url del portal.
 * @param {string} nextUrl - Url a redirigir.
 * @example useCheckPortal('newPath')
 */
export const useCheckPortal = (nextUrl = '') => {
  const { portalId } = useSelector((state: Types.Store) => state.loggedUser)
  const [loading, setloading] = useState(true)

  const checkPortal = useCallback(async () => {
    getToken()
    const query = `
      query Portal {
        Portal(id: ${portalId}) {
          url
        }
      }
    `
    await axios
      .post(`${apiURL}/graphql`, { query })
      .then((response) => {
        const portal = response?.data?.data?.Portal!
        if (portal?.url) {
          window.location.replace(`${portal.url}/${nextUrl}`)
        } else {
          setloading(false)
        }
      })
      .catch((err) => axiosError(err, 'checkPortal', null))
  }, [portalId, nextUrl])

  useEffect(() => {
    if (portalId) {
      checkPortal()
    } else {
      loading && setloading(false)
    }
    return () => {
      axiosAbort()
    }
  }, [portalId, checkPortal, loading])

  return {
    loading,
  }
}
