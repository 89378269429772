import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { PublicImage } from 'app/Redux/Actions'
import * as Icons from '../icons'
import { AttachFile } from './AttachFile'
import { Props } from '../types'
import { RouteParams } from 'Config/routes/types'

const className = 'toolbar-option'

export const useToolbar = (type = 'basic', setAttached: Props.setAttached, attachTotal: number) => {
  const dispatch = useDispatch()
  const { courseId } = useParams<RouteParams>()

  const uploadCallback = async (file: File) => {
    // @ts-ignore
    const request = await dispatch<any>(PublicImage(file, Number(courseId)))
    if (request.data.status.success) {
      return request
    }
  }

  /**
   * Default toolbar.
   */
  const baseToolbar = {
    inline: {
      options: ['bold', 'italic', 'underline'],
      bold: { icon: Icons.bold, className },
      italic: { icon: Icons.italic, className },
      underline: {
        icon: Icons.underline,
        className,
      },
    },
  }

  const baseComment = {
    options: ['inline', 'list', 'emoji', 'link', 'image'],
    ...baseToolbar,
    list: {
      options: ['ordered'],
      ordered: { icon: Icons.list, className },
    },
    emoji: {
      icon: Icons.emoji,
      className,
      popupClassName: 'CustomPopUp-Toolbar',
      // emojis: ['😀','😁', '😂']
    },
    // attach
    link: {
      icon: Icons.link,
      popupClassName: 'CustomPopUp-Toolbar',
      defaultTargetOption: '_blank',
      options: ['link'],
      link: { icon: Icons.link, className },
    },
    image: {
      className,
      icon: Icons.image,
      previewImage: true,
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png',
      urlEnabled: false,
      alignmentEnabled: false,
      uploadCallback,
      defaultSize: {
        height: '100%',
        width: '100%',
      },
    },
  }

  const baseAppliedQuestion = {
    options: ['inline', 'image'],
    ...baseToolbar,
    image: {
      className,
      icon: Icons.image,
      previewImage: true,
      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png',
      urlEnabled: false,
      alignmentEnabled: false,
      uploadCallback,
      defaultSize: {
        height: '100%',
        width: '100%',
      },
    },
  }

  if (type === 'comments') {
    return {
      default: baseComment,
      custom: [<AttachFile setAttached={setAttached!} total={attachTotal} />],
    }
  }
  if (type === 'helper') {
    return {
      default: baseComment,
    }
  }
  if (type === 'appliedQuestion') {
    return {
      default: baseAppliedQuestion,
    }
  } else {
    return {
      default: {
        options: ['inline'],
        ...baseToolbar,
      },
      custom: [],
    }
  }
}
