import { gql, useQuery, QueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

export const GET_TABS_INFO = gql`
  ${STATUS_FIELDS}
  query getCourseInfo($CourseInfoInput: CourseInfoInput!) {
    getCourseInfo(input: $CourseInfoInput) {
      status {
        ...StatusFields
      }
      Course {
        progress
        haveQuestions
      }
      Tabs {
        id
        name
        url
        label {
          type
          name
          state
        }
      }
      CourseRankings {
        id
        name
      }
    }
  }
`
export type CourseInfoPayload = Api.CourseInfoPayload

export type CourseInfoInput = Api.CourseInfoInput

interface CourseInfoPayloadApollo {
  getCourseInfo: CourseInfoPayload
}

interface CourseInfoPayloadInputApollo {
  CourseInfoInput: CourseInfoInput
}

export const useQueryGetTabsInfo = (
  options?: QueryHookOptions<CourseInfoPayloadApollo, CourseInfoPayloadInputApollo>
) => {
  return useQuery<CourseInfoPayloadApollo, CourseInfoPayloadInputApollo>(GET_TABS_INFO, options)
}
