import { gql } from '@apollo/client'

import * as Type from '../types'

export const ALL_STUDY_LEVELS = gql`
  query allStudyLevels {
    allStudyLevels(orderBy: name_ASC) {
      name
      id
    }
  }
`

export interface ALL_STUDY_LEVELS_TYPE {
  allStudyLevels: Type.list[]
}
