import React from 'react'

import { Link } from '@eclass/api'
// @Dependencies
import { Button } from 'app/Components/UI'
import { Icon } from 'app/Components/UI/Icons'
import './style.scss'

type Props = {
  docs: Link[]
}

export const DownloadFiles = ({ docs }: Props) => {
  return (
    <div className="ComponentDownloadFiles DownloadFiles">
      {docs.map(({ href, name }, key: number) => {
        const documentExtension = href?.split('.')[href.split('.').length - 1]

        return (
          <li key={key} className="File flex">
            <div>
              <strong>{`${name}`}</strong>
              <span>{`(${documentExtension})`}</span>
            </div>
            <Button link={href} blank>
              <Icon name="download" width={14} height={16} fill="white" />{' '}
            </Button>
          </li>
        )
      })}
    </div>
  )
}
