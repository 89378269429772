import { FC } from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import { vars } from '@eclass/ui-kit'

interface IBaseIcons extends BoxProps {
  onClick: () => void
  dark?: boolean
  sx?: Object
}
export const BaseIcons: FC<IBaseIcons> = ({ onClick, children, dark = false, sx, ...custom }) => {
  const size = '28px' // '1.75rem'
  return (
    <Box
      alignItems="center"
      as="button"
      bg="transparent"
      border="none"
      borderRadius={vars('radii-rounded')}
      className={`${dark ? 'isDark' : ''}`}
      cursor="pointer"
      data-testid="podcastIcon"
      display="flex"
      h={size}
      justifyContent="center"
      onClick={onClick}
      overflow="hidden"
      p={0}
      position="relative"
      w={size}
      {...custom}
      sx={{
        svg: {
          zIndex: 2,
        },
        '>div': {
          transition: 'all .3s ease-in-out',
          bg: vars('colors-neutral-cultured'),
        },
        '&.isDark > div': {
          bg: vars('colors-neutral-white'),
          opacity: '.1',
        },
        ':hover': {
          '>div': {
            bg: vars('colors-neutral-cultured2'),
          },
          '&.isDark > div': {
            bg: vars('colors-neutral-white'),
            opacity: '.15',
          },
        },
        ...sx,
      }}
    >
      <Box h="100%" left={0} position="absolute" top={0} w="100%" />
      {children}
    </Box>
  )
}
