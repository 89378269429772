import jwtDecode from 'jwt-decode'
import * as Sentry from '@sentry/react'
import { useSelector } from 'react-redux'

import { Store } from 'app/Redux/types'
import { useSentryErrors } from 'app/Services'

export const useSentryError = (fileURL: string) => {
  const {
    loggedUser: { user },
  } = useSelector((state: Store) => state)
  const { sentryErrors } = useSentryErrors()

  const send = async (err: HTMLAudioElement['error']) => {
    const tokenLvlTest = window.localStorage.getItem('token_test')

    const error = await setError(err!)

    const baseError = {
      context: `Audio - ${error.type}`,
      extra: {
        level: 'error',
        extra: {
          code: error.detail?.code ?? 'Unknown error',
          message: error.detail?.message ?? 'Unknown error',
          audioSrc: fileURL,
        },
      },
    }

    if (user === undefined || (user && tokenLvlTest)) {
      let configUser: Sentry.User = {}
      if (tokenLvlTest) {
        const { contactId, testId }: any = jwtDecode(tokenLvlTest)
        configUser = {
          contactId,
          testId,
          fileURL,
        }

        const lvlTest = window.localStorage.getItem('level_test_user')
        if (lvlTest) {
          const { name, lastSurname, firstSurname, documentId, documentNumber, email } =
            JSON.parse(lvlTest)
          configUser = {
            ...configUser,
            username: `${name} ${firstSurname} ${lastSurname}`,
            email,
            document: documentId.label,
            documentNumber,
            extra: baseError.extra,
          }
        }
        sentryErrors({
          user: configUser,
          ...baseError,
        })
      } else {
        sentryErrors(baseError)
      }
    } else {
      sentryErrors(baseError)
    }
  }

  const setError = async (err: HTMLAudioElement['error']) => {
    if (!err) {
      // Si no trae error, se valida que la url sea valida
      try {
        const response = await fetch(fileURL)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }
      } catch (error2) {
        return {
          type: 'NetworkError',
          detail: {
            code: error2?.message || '',
            message: 'Failed to load audio file',
          },
        }
      }
      return {
        type: 'Unknown error, input error undefined',
      }
    }

    let errorType
    switch (err.code) {
      case MediaError.MEDIA_ERR_ABORTED:
        errorType = 'MediaError.MEDIA_ERR_ABORTED'
        break
      case MediaError.MEDIA_ERR_NETWORK:
        errorType = 'MediaError.MEDIA_ERR_NETWORK'
        break
      case MediaError.MEDIA_ERR_DECODE:
        errorType = 'MediaError.MEDIA_ERR_DECODE'
        break
      case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
        errorType = 'MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED'
        break
      default:
        errorType = 'Unknown error, invalid code'
        break
    }
    return { type: errorType, detail: err }
  }

  return { send }
}
