import React from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from 'app/Components/UI/Icons/Icon'

import { HeaderAvatar as Style } from './style'
import { MyAvatar } from './MyAvatar'

import { Props } from './types'

/**
 * Componente que muestra el avatar y nombre del usuario,
 * también muestra si lo quieres si eres ayudante
 * @example <Header showHelper={false} user={user} />
 */
export const HeaderAvatar = ({ user, selfComment, profileId }: Props.HeaderAvatar) => {
  const { t } = useTranslation()

  const name =
    user?.fullName && user.fullName.trim() !== '' ? user.fullName : t('CommentSystemAnonymous')

  const getIcon = (id: number) => {
    switch (id) {
      case 6:
        return (
          <Style.Helper>
            <Icon name="coordinator" />
            {t('QuestionCoordinator')}
          </Style.Helper>
        )
      case 3:
        return (
          <Style.Helper>
            <Icon name="assitant" />
            {t('QuestionHelper')}
          </Style.Helper>
        )
      case 2:
        return (
          <Style.Helper>
            <Icon name="board" />
            {t('QuestionTeacher')}
          </Style.Helper>
        )
      default:
    }
  }

  const isPrivate = selfComment?.private

  return (
    <Style.Wrapper role="HeaderAvatar" className="headerAvatar">
      <MyAvatar user={user} />
      <Style.Info>
        {!selfComment ? (
          <div className="name">{name}</div>
        ) : (
          <div className="selfComment">
            <p className="by">
              {t('QuestionBy')} <i>{name}</i> {selfComment.date}
            </p>
            <div>
              <ul>
                <li>
                  <Icon name={isPrivate ? 'lock' : 'unlocked'} fill="#b0cfe0" />
                  <span>{isPrivate ? t('QuestionPrivate') : t('QuestionPublic')} </span>
                </li>
              </ul>
            </div>
          </div>
        )}
        {getIcon(profileId!)}
      </Style.Info>
    </Style.Wrapper>
  )
}
