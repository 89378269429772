import { useState, useEffect, useCallback } from 'react'
import { Box } from '@chakra-ui/react'
import { StudentInstance } from '@eclass/sequelize-models'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { AlertNotification } from 'app/Components/UI/Alert'
import * as Redux from 'app/Redux/types'
import { IQuestion } from './types'
import { HasFeedback } from './HasFeedback'
import { Modal } from './Modal/Modal'
import { BtnSecondary } from '@eclass/ui-kit'

interface props {
  teacher: StudentInstance
}

/**
 * Muestra Botón con el cual se puede hacer el feedback al profesor
 */
export const Feedback = ({ teacher }: props): JSX.Element | null => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    cev: { feedback },
  } = useSelector((store: Redux.Store) => store)

  // manejo del modal
  const [show, setShow] = useState(false)
  // manejo del alert
  const [success, setSuccess] = useState(false)
  // manejo del mensaje de guardado
  const [send, setSend] = useState(false)

  // agrego `name` a las preguntas para manejarlo en el form
  const questions: IQuestion[] = Array.from(feedback.questions || []).map((item) => ({
    ...item,
    name: `question${item.questionId}`,
  }))

  const closeModal = useCallback(() => {
    feedback?.forceModal && dispatch({ type: 'CEV_FEEDBACK_FORCE_MODAL', payload: false })
    setShow(false)
  }, [feedback])

  // Si necesito levantar el modal sin presionar btn
  useEffect(() => {
    if (feedback?.forceModal && !show) {
      setShow(true)
    }
  }, [feedback?.forceModal, show])

  // si ya hizo el feedback no monto los demas componentes
  if (feedback?.config?.hasFeedback) {
    return (
      <Box mt="1rem" textAlign="center">
        <HasFeedback />
      </Box>
    )
  }

  if (questions.length === 0) {
    return null
  }

  return (
    <Box
      mt="8px"
      ml="43px"
      width="fit-content"
      sx={{
        '>button': {
          marginRight: 0,
          '.button__container': {
            padding: '.5em 1em',
            height: 'auto',
            width: 'auto',
          },
        },
      }}
    >
      {!send ? (
        <BtnSecondary
          onClick={() => setShow(true)}
          m="0 0 16px 0"
        >{`Evaluar Profesor`}</BtnSecondary>
      ) : (
        <HasFeedback />
      )}
      <AlertNotification
        message={t('CEVFeedbackSuccess')}
        type="success"
        show={success}
        closed={() => setSuccess(false)}
      />
      <Modal
        feedback={feedback}
        closeModal={closeModal}
        show={show}
        teacher={teacher}
        questions={questions}
        success={success}
        setSuccess={setSuccess}
        setSend={setSend}
      />
    </Box>
  )
}
