import { ProductLogo } from '../Branding'
import { FooterHelp } from '../Footer/Help'
import { Container } from 'app/Layouts'
import { LegalText } from '../LegalText/LegalText'

// @Component PreFooter
export default function PreFooter({ courseInfo, config }) {
  return (
    <div>
      {courseInfo && courseInfo.Course.Product && courseInfo.Course.Product.brandingLogo && (
        <Container>
          <ProductLogo product={courseInfo.Course.Product} />
        </Container>
      )}
      <Container>
        <LegalText />
      </Container>
      {!config.appMobile && <FooterHelp />}
    </div>
  )
}
