import { ProvisionalState } from '.'

type ProvisionalActionType =
  | { type: 'Provisional - Show modal'; payload: boolean }
  | { type: 'Provisional - Status redux'; payload: boolean }

export const ProvisionalReducer = (
  state: ProvisionalState,
  action: ProvisionalActionType
): ProvisionalState => {
  switch (action.type) {
    case 'Provisional - Show modal':
      return { ...state, status: action.payload }
    case 'Provisional - Status redux':
      return { ...state, isProvisional: action.payload }
    default:
      return state
  }
}
