import { useSelector } from 'react-redux'

import { Store } from 'app/Redux/types'
import { dateFormat, IDateFormat } from './dateFormat'

interface props {
  date: Date | string
  format?: IDateFormat['format']
  withoutTimezone?: boolean
}

/**
 * Contine método setDate que da formatea fecha o formato definid.
 *
 * Toma en consideración la zona horaria del user y el idioma definido para la app
 * @example const { dateFormat } = useDateFormat()
 * dateFormat({date : new Date()})
 */
export const useDateFormat = () => {
  const { loggedUser, ui } = useSelector((state: Store) => state)

  const timeZone = loggedUser?.user?.timeZone

  /**
   * seteo con los valores correctos el idioma definido, los datos se toman de
   * @see src/app/Services/useLocal.ts
   */
  const language = (): IDateFormat['language'] => {
    switch (ui.i18n) {
      case 'spa':
      case 'es_eng':
        return 'es'
      case 'eng':
        return 'enUS'
      case 'por':
        return 'pt'
      default:
        return 'es'
    }
  }

  const setDate = ({ date, format, withoutTimezone = false }: props) => {
    const zone0 = 'Africa/Dakar'
    const finalDate = date

    return dateFormat({
      date: finalDate,
      format,
      language: language(),
      timeZone: withoutTimezone ? zone0 : timeZone,
    })
  }

  return {
    dateFormat: setDate,
  }
}
