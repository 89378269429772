interface IFieldDisabled {
  configClose: any
  answerRequest: any
  disabled: any
}
/**
 * Activa o desabilida campo si este es close y si esta en el dom
 */
export const fieldDisabled = ({ configClose, answerRequest, disabled }: IFieldDisabled) => {
  if (
    configClose.ItemType?.id === 24 &&
    answerRequest &&
    /"configId":.*,"response":/.test(answerRequest)
  ) {
    const respond = JSON.parse(answerRequest)
    if (respond.configId >= 0) {
      const field = document.getElementById(`${configClose.id}_${respond.configId}`) as
        | HTMLInputElement
        | HTMLSelectElement
      if (field) {
        field.disabled = disabled
      }
    }
  }
}
