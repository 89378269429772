import { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getPhoneTypes } from 'app/Redux/Actions'
import { Types } from '../types'
import { useData } from './useData'

/**
 * hooks que obtine los tipos de teléfonos,
 * siempre y cuando no tenga datos en la store
 * @example
 * const { phoneTypes, status } = usePhoneTypes()
 */
export const usePhoneTypes = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { profile, setProfile } = useData()
  const [state, setState] = useState(profile.phoneTypes)
  const [status, setStatus] = useState('init')
  const phoneTypesMap = new Map([
    [1, t('ProfilePersonal')],
    [2, t('ProfileCellPhone')],
    [3, t('ProfileWork')],
    [4, t('ProfileWorkCellPhone')],
  ])

  const loadPhoneTypes = useCallback(async () => {
    setStatus('loading')
    const response = await dispatch<any>(getPhoneTypes())
    if (response && response.status.success) {
      const phoneTypes = response.data.map((type: Types.PhoneTypeAttr) => {
        return {
          label: phoneTypesMap.get(type.id!),
          value: type.id,
        }
      })
      setProfile({ phoneTypes })
      setState(phoneTypes)
      setStatus('success')
    } else {
      setStatus('error')
    }
  }, [dispatch, setProfile])

  useEffect(() => {
    if (!state) {
      loadPhoneTypes()
    }
  }, [state, loadPhoneTypes])

  return { phoneTypes: state, status }
}
