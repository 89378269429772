import styled from 'styled-components/macro'
import { UIVars } from 'app/Components/UI'
import { bgFooterHelp, bgFooterHelpMobile } from '../../../../assets/img/'

export default styled.footer`
  background: url(${bgFooterHelp}) center 31% / cover;
  position: relative;
  text-align: center;
  padding: 0;

  .FooterHelp__section {
    align-items: normal;
    gap: 20px;

    .HelpCenter {
      gap: 20px;

      .coordinatorBox,
      .frequentQuestionsBox {
        width: 388px;

        article {
          padding-bottom: 0;
        }
      }
    }

    article {
      border-radius: 10px;
      padding-top: 32px;
    }

    @media screen and (max-width: 670px) {
      .HelpCenter {
        align-items: center;
        flex-direction: column;
        gap: 44px;
        margin: 0 20px;
        width: -webkit-fill-available;

        .coordinatorBox,
        .frequentQuestionsBox {
          width: -webkit-fill-available;
        }
        .coordinatorBox {
          width: 100%;
        }
        article {
          max-width: none;
        }
      }
    }
    @media screen and (min-width: 671px) and (max-width: 1023px) {
      justify-content: center;

      .HelpCenter {
        gap: 16px;
        margin: 0 28px;
        .coordinatorBox {
          width: 50%;
        }
        article {
          width: max-content;
          max-width: 348px;
        }
      }
    }
  }

  .container,
  .container-fluid {
    padding: 50px 15px;
  }

  h2 {
    font-weight: 300;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 40px;
    position: relative;
    text-transform: uppercase;
  }

  h2,
  strong {
    color: #333;
    line-height: normal;
  }

  img {
    color: ${UIVars.setColors.primary};
    font-size: 1rem;
    margin-right: 10px;
  }

  figure {
    img {
      margin-right: 0;
    }
  }

  .FooterHelp__item {
    color: #333;
    display: flex;
    font-size: 14px;
    width: 100%;

    &:last-child {
      margin-right: 0;
    }

    &:only-child {
      max-width: 388px;
    }
  }

  @media screen and (max-width: 992px) {
    .FooterHelp__item {
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 770px) {
    h2 {
      font-size: 24px;
    }

    // .FooterHelp__item {
    //   flex: 0 0 calc(50% - 10px);
    //   margin: 0 0 20px;
    // }
  }

  @media screen and (max-width: 670px) {
    .FooterHelp__section {
      flex-direction: column;
    }

    .FooterHelp__item {
      flex: 0 0 calc(50% - 10px);
      max-width: none;
      margin: 0 0 20px;
      width: 100%;

      &:only-child {
        max-width: none;
      }
    }
  }

  @media screen and (max-width: 500px) {
    background: url(${bgFooterHelpMobile}) no-repeat center 31% / cover;

    .col-md-4 {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`
