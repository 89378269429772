import { Types } from './types'
import { initialState } from './initialState'

/**
 * Guarda los datos del usuario una vez logueado.
 *
 * @retuns Objeto con los datos del usuario
 */
export const profile = (state: Types.Store = initialState, action: Types.ReducerAction): any => {
  switch (action.type) {
    case 'PROFILE_COUNTRIES':
      return {
        ...state,
        countries: action.payload,
      }
    case 'PROFILE_ADD':
      return {
        ...state,
        ...dispatchAddData(action.payload),
      }
    case 'PROFILE_ALERT':
      return {
        ...state,
        ...dispatchAlert(action.payload),
      }
    default:
      return state
  }
}

/** Seteo datos manteniendo los demas de la store */
const dispatchAddData = (payload: Types.Store) => payload

/** Seteo alert */
const dispatchAlert = (payload: Types.Alert & string) => ({
  alert: {
    show: payload !== 'closed',
    type: payload,
  },
})
