declare global {
  interface Window {
    dataLayer: any
  }
}

interface IPushToDataLayer {
  event: string
  eventProps?: ICustomEvent
}

const pushToDataLayer = (obj: IPushToDataLayer) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(obj)
}

interface ICustomEvent {
  category: string
  action: string
  label?: string
  value?: string
  nonInteraction?: boolean
}
export const customEvent = (eventProps: ICustomEvent) => {
  pushToDataLayer({
    event: 'CustomEvent',
    eventProps,
  })
}
