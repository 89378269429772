import styled from 'styled-components/macro'
import { UIVars } from 'app/Components/UI'

export const ModalWrapper = styled.div`
  hr {
    margin: auto;
    margin-top: 10px;
    margin-bottom: 15px;
    border-top: 1px solid ${UIVars.setColors.backgroundGrey};
  }
`

export const ModalFooter = styled.div`
  display: flex;
  .button__container {
    width: auto;
    padding: 0px 30px;
  }

  @media screen and (max-width: 425px) {
    .button__container {
      padding: 0px 20px;
      line-height: 18px;
    }
  }
`

export const QuestionWrapper = styled.div`
  margin-bottom: 20px;
`
