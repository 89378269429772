import * as Sentry from '@sentry/react'
import { useSelector } from 'react-redux'

import { Store } from 'app/Redux/types'
import { store } from 'app/Redux'

interface IProps {
  user?: Sentry.User
  context: string
  extra?: {}
  err?: string
}
/**
 * Dispara un error a sentry con la cadena de texto que se le pase
 *
 * Solo en producción
 */
export const useSentryErrors = () => {
  const {
    loggedUser: { user: userStore },
  } = useSelector((state: Store) => state)

  const sentryErrors = ({ err, user, context, extra = {} }: IProps) => {
    if (process.env.NODE_ENV === 'production') {
      let configUser: Sentry.User = {
        username: userStore?.fullName || '',
        email: userStore?.email || '',
        id: `${userStore?.id}`,
      }
      if (user) {
        configUser = {
          ...configUser,
          ...user,
        }
      }

      Sentry.setUser(configUser)
      Sentry.captureMessage(context || 'Something went wrong', extra)
      err && Sentry.captureException(err)
    }
  }

  return { sentryErrors }
}

interface IServiceSentryErrors {
  user: Sentry.User
  context: string
  extra?: {}
  err?: string
}
/** Servicion que envia errores a sentry, se utiliza cuando no se puedo utilizar el hooks */
export const serviceSentryErrors = ({ user, context, extra = {}, err }: IServiceSentryErrors) => {
  if (process.env.NODE_ENV === 'production') {
    let dataUser = user

    if (!dataUser.id) {
      const userStore = store.getState().loggedUser.user
      if (userStore) {
        dataUser = {
          ...dataUser,
          username: userStore?.fullName || '',
          email: userStore?.email || '',
          id: `${userStore?.id}`,
        }
      }
    }

    Sentry.setUser(dataUser as Sentry.User)
    Sentry.captureMessage(context || 'Something went wrong', extra)
    err && Sentry.captureException(err)
  }
}
