import styled from 'styled-components/macro'

import { UIVars } from 'app/Components/UI'

export const Download = styled.div`
  &.isOfUnit {
    > button {
      margin-top: 0;
    }
  }

  &.isCenter {
    display: flex;
    justify-content: center;
  }
`

export const WrapperPDF = styled.div`
  max-width: 700px;
  margin: 0 auto;
`

export const Button = styled.div`
  align-items: center;
  display: flex;
  padding: 0;
  > button {
    padding-left: 0;
    padding-right: 0;
  }
`

export const ModalUnit = styled.div`
  .confirmation .button__container {
    height: 44px;
    padding: 0 10px;
    width: 180px;
  }

  > ul {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px;
    padding-inline-start: 17px;
  }

  strong {
    font-size: 18px;
  }

  > .textInfo {
    color: ${UIVars.setColors.brownGreyTwo};
    font-size: 14px;
    margin-bottom: 20px;
  }

  > footer {
    display: flex;
    padding: 0;
    button {
      margin-right: 20px;
    }
  }
  @media screen and (max-width: 475px) {
    button {
      width: 50%;
      div {
        div {
          .Pressed__content {
            .button__container {
              width: 100%;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 365px) {
    footer {
      button:first-child {
        width: 60%;
      }
      button:last-child {
        width: 40%;
      }
    }
  }
`

export const AlertClose = styled.div`
  align-items: center;
  background: ${UIVars.setColors.whiteThree};
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  padding: 16px;
  position: absolute;
  top: 0;
  width: 100vw;

  .e_alert {
    max-width: fit-content;
  }
`
