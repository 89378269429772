import { gql, useMutation, MutationHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../../fragments'

const ADD_RESOURCE_RATING = gql`
  ${STATUS_FIELDS}
  mutation addResourceRating($ResourceStudentRatingInput: ResourceStudentRatingInput!) {
    addResourceRating(input: $ResourceStudentRatingInput) {
      status {
        ...StatusFields
      }
    }
  }
`

export type ResourceStudentRatingPayload = Api.ResourceRatingPayload

export type ResourceStudentRatingInput = Api.ResourceRatingInput

interface AddResourceRatingPayloadApollo {
  addResourceRating: ResourceStudentRatingPayload
}

interface AddResourceRatingInputApollo {
  ResourceStudentRatingInput: ResourceStudentRatingInput
}

export const useMutationAddResourceRating = (
  options?: MutationHookOptions<AddResourceRatingPayloadApollo, AddResourceRatingInputApollo>
) => {
  return useMutation<AddResourceRatingPayloadApollo, AddResourceRatingInputApollo>(
    ADD_RESOURCE_RATING,
    options
  )
}
