import * as Icon from './svgs'

/**
 * Devuelve svg del icono que se le pide, los nombres de estos deben estar registrados en el type TNames
 */
const getPath = (name: TNames, props: any) => {
  const paths = {
    absent: <Icon.Absent />,
    academic_data: <Icon.AcademicData {...props} />,
    academic_historial: <Icon.AcademicHistorial {...props} />,
    add: <Icon.Add {...props} />,
    afternoon: <Icon.Afternoon {...props} />,
    alert: <Icon.Alert {...props} />,
    alertError: <Icon.AlertError {...props} />,
    alertInfo: <Icon.AlertInfo {...props} />,
    alertSuccess: <Icon.AlertSuccess {...props} />,
    alertWarning: <Icon.AlertWarning {...props} />,
    alarmDisable: <Icon.AlarmDisable />,
    anchorComment: <Icon.AnchorComment {...props} />,
    answers: <Icon.Answers {...props} />,
    arrow: <Icon.Arrow {...props} />,
    'arrow-back': <Icon.ArrowBack {...props} />,
    'arrow-big': <Icon.ArrowBig {...props} />,
    'arrow-down': <Icon.ArrowDown {...props} />,
    'arrow-in': <Icon.ArrowIn {...props} />,
    'arrow-top-right': <Icon.ArrowTopRight {...props} />,
    'arrow-nav-test': <Icon.ArrowNavTest {...props} />,
    assitant: <Icon.Assitant {...props} />,
    attend: <Icon.Attend />,
    audio: <Icon.Audio {...props} />,
    back: <Icon.Back {...props} />,
    bag: <Icon.Bag {...props} />,
    bell: <Icon.Bell {...props} />,
    belongs: <Icon.Belongs {...props} />,
    bigStar: <Icon.BigStar {...props} />,
    'blocked isTest': <Icon.BlockedIsTest {...props} />,
    'blocked pending isTest': <Icon.Blocked {...props} />,
    'blocked pending': <Icon.Blocked {...props} />,
    blocked: <Icon.Blocked {...props} />,
    blueSimpleCheck: <Icon.BlueSimpleCheck />,
    board: <Icon.Board {...props} />,
    'bullet-list': <Icon.BulletList {...props} />,
    evaluation: <Icon.BulletList {...props} />,
    cake: <Icon.Cake {...props} />,
    calendar: <Icon.Calendar {...props} />,
    'calendar-time': <Icon.CalendarTime {...props} />,
    certificate: <Icon.Certificate {...props} />,
    'circle-check': <Icon.CircleCheck {...props} />,
    circularCheck: <Icon.CircularCheck {...props} />,
    circularError: <Icon.CircularError {...props} />,
    circularNeutral: <Icon.CircularNeutral {...props} />,
    clock: <Icon.Clock {...props} />,
    close: <Icon.Close {...props} />,
    'close-modal': <Icon.CloseModal {...props} />,
    comment: <Icon.Comment {...props} />,
    comment2: <Icon.Comment2 {...props} />,
    completed: <Icon.Completed {...props} />,
    contactMe: <Icon.ContactMe {...props} />,
    coordinator: <Icon.Coordinator />,
    courseIcon: <Icon.CourseIcon {...props} />,
    'done isTest': <Icon.Completed {...props} />,
    'done revised isTest': <Icon.Completed {...props} />,
    'done revised': <Icon.Completed {...props} />,
    'done woRevised isTest': <Icon.BlockedIsTest {...props} />,
    done: <Icon.Completed {...props} />,
    download: <Icon.Download {...props} />,
    earlyMorning: <Icon.EarlyMorning {...props} />,
    estrellas: <Icon.Stars {...props} />,
    filterNewOld: <Icon.FilterNewOld {...props} />,
    flag: <Icon.Flag {...props} />,
    folio: <Icon.Folio {...props} />,
    game: <Icon.Game {...props} />,
    'goback-courses': <Icon.GobackCourses {...props} />,
    helpDropdown: <Icon.HelpDropdown {...props} />,
    'horizontal-flag': <Icon.HorizontalFlag {...props} />,
    'in-progress draft isTest': <Icon.InProgress {...props} />,
    'in-progress isTest': <Icon.InProgress {...props} />,
    'in-progress wAnswers isTest': <Icon.InProgress {...props} />,
    'in-progress draft': <Icon.InProgress {...props} />,
    'in-progress': <Icon.InProgress {...props} />,
    info: <Icon.Info {...props} />,
    'information-gmt': <Icon.InformationGmt {...props} />,
    'information-welcome': <Icon.InformationWelcome {...props} />,
    pendingInfo: <Icon.PendingInfo />,
    justCheck: <Icon.JustCheck {...props} />,
    letter: <Icon.Letter {...props} />,
    linkedin: <Icon.Linkedin {...props} />,
    lock: <Icon.Lock {...props} />,
    lockWithHole: <Icon.LockWithHole {...props} />,
    'map-indicator': <Icon.MapIndicator {...props} />,
    materials: <Icon.Materials {...props} />,
    mediumStar: <Icon.MediumStar {...props} />,
    menuListItems: <Icon.MenuListItems {...props} />,
    microphone: <Icon.Microphone {...props} />,
    mobileChat: <Icon.MobileChat {...props} />,
    morning: <Icon.Morning {...props} />,
    multimedia: <Icon.Multimedia {...props} />,
    night: <Icon.Night {...props} />,
    objectives: <Icon.Objectives {...props} />,
    goals: <Icon.Objectives {...props} />,
    group: <Icon.Group {...props} />,
    'opened isTest': <Icon.Opened {...props} />,
    'opened woDraft isTest': <Icon.Opened {...props} />,
    'opened woDraft': <Icon.Opened {...props} />,
    opened: <Icon.Opened {...props} />,
    partialCheck: <Icon.PartialCheck {...props} />,
    pdf: <Icon.Pdf />,
    phone: <Icon.Phone {...props} />,
    pin: <Icon.Pin {...props} />,
    'play-video': <Icon.PlayVideo {...props} />,
    portafolio: <Icon.Portafolio {...props} />,
    'profile-icon': <Icon.ProfileIcon {...props} />,
    personalDataIcon: <Icon.PersonalDataIcon {...props} />,
    presentation: <Icon.Presentation {...props} />,
    questionnaire: <Icon.Questionnaire {...props} />,
    questions: <Icon.Questions {...props} />,
    rankingStars: <Icon.RankingStars {...props} />,
    reading: <Icon.Reading {...props} />,
    refresh: <Icon.Refresh {...props} />,
    remote: <Icon.Remote />,
    repetition: <Icon.Refresh {...props} />,
    resources: <Icon.Materials {...props} />,
    search: <Icon.Search {...props} />,
    settings: <Icon.Settings {...props} />,
    'show-welcome': <Icon.ShowWelcome {...props} />,
    stars: <Icon.Stars {...props} />,
    stopWatch: <Icon.StopWatch {...props} />,
    thumbsUp: <Icon.ThumbsUp {...props} />,
    'tilted-pen': <Icon.TiltedPen {...props} />,
    time: <Icon.Clock {...props} />,
    timelineArrow: <Icon.TimelineArrow {...props} />,
    trash: <Icon.Trash {...props} />,
    trashNoX: <Icon.TrashNoX {...props} />,
    unlocked: <Icon.Unlocked {...props} />,
    video: <Icon.Video {...props} />,
    watch: <Icon.Watch {...props} />,
    warning: <Icon.Warning {...props} />,
    webcam: <Icon.Webcam {...props} />,
    'webcam-effect': <Icon.WebcamEffect {...props} />,
    whatsapp: <Icon.Whatsapp {...props} />,
    'window-paragraph': <Icon.WindowParagraph {...props} />,
    x: <Icon.X {...props} />,
  }

  if (!name || !paths[name]) {
    return <></>
  }

  return paths[name]
}

export type TNames =
  | 'absent'
  | 'academic_data'
  | 'academic_historial'
  | 'add'
  | 'afternoon'
  | 'alert'
  | 'alertError'
  | 'alertInfo'
  | 'alertSuccess'
  | 'alertWarning'
  | 'alarmDisable'
  | 'anchorComment'
  | 'answers'
  | 'arrow'
  | 'arrow-back'
  | 'arrow-big'
  | 'arrow-down'
  | 'arrow-in'
  | 'arrow-top-right'
  | 'arrow-nav-test'
  | 'assitant'
  | 'attend'
  | 'audio'
  | 'back'
  | 'bag'
  | 'bell'
  | 'belongs'
  | 'bigStar'
  | 'blocked isTest'
  | 'blocked pending isTest'
  | 'blocked pending'
  | 'blocked'
  | 'blueSimpleCheck'
  | 'board'
  | 'bullet-list'
  | 'evaluation'
  | 'cake'
  | 'calendar'
  | 'calendar-time'
  | 'certificate'
  | 'circle-check'
  | 'circularCheck'
  | 'circularError'
  | 'circularNeutral'
  | 'clock'
  | 'close'
  | 'close-modal'
  | 'comment'
  | 'comment2'
  | 'completed'
  | 'contactMe'
  | 'coordinator'
  | 'courseIcon'
  | 'done isTest'
  | 'done revised isTest'
  | 'done revised'
  | 'done woRevised isTest'
  | 'done'
  | 'download'
  | 'earlyMorning'
  | 'estrellas'
  | 'filterNewOld'
  | 'flag'
  | 'folio'
  | 'game'
  | 'goback-courses'
  | 'helpDropdown'
  | 'horizontal-flag'
  | 'in-progress draft isTest'
  | 'in-progress isTest'
  | 'in-progress wAnswers isTest'
  | 'in-progress draft'
  | 'in-progress'
  | 'info'
  | 'information-gmt'
  | 'information-welcome'
  | 'pendingInfo'
  | 'justCheck'
  | 'letter'
  | 'linkedin'
  | 'lock'
  | 'lockWithHole'
  | 'map-indicator'
  | 'materials'
  | 'mediumStar'
  | 'menuListItems'
  | 'microphone'
  | 'mobileChat'
  | 'morning'
  | 'multimedia'
  | 'night'
  | 'objectives'
  | 'goals'
  | 'group'
  | 'opened isTest'
  | 'opened woDraft isTest'
  | 'opened woDraft'
  | 'opened'
  | 'partialCheck'
  | 'pdf'
  | 'phone'
  | 'pin'
  | 'play-video'
  | 'portafolio'
  | 'profile-icon'
  | 'personalDataIcon'
  | 'presentation'
  | 'questionnaire'
  | 'questions'
  | 'rankingStars'
  | 'reading'
  | 'refresh'
  | 'remote'
  | 'repetition'
  | 'resources'
  | 'search'
  | 'settings'
  | 'show-welcome'
  | 'stars'
  | 'stopWatch'
  | 'thumbsUp'
  | 'tilted-pen'
  | 'time'
  | 'timelineArrow'
  | 'trash'
  | 'trashNoX'
  | 'unlocked'
  | 'video'
  | 'watch'
  | 'warning'
  | 'webcam'
  | 'webcam-effect'
  | 'whatsapp'
  | 'window-paragraph'
  | 'x'

export default getPath
