import { gql } from '@apollo/client'

import * as Type from '../types'

export const ALL_PHONE_TYPE_2S = gql`
  query allPhoneType2s {
    allPhoneType2s(orderBy: name_ASC) {
      name
      id
    }
  }
`
export interface ALL_PHONE_TYPE_2S_TYPE {
  allPhoneType2s: Type.list[]
}
