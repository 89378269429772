export const QuestionCheck = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" data-testid="QuestionCheck">
      <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
      <rect x="13" y="23" width="12" height="9" rx="2" fill="#FFBC27" />
      <line
        x1="18"
        y1="27"
        x2="20"
        y2="27"
        stroke="#08044F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <rect x="14" y="23" width="10" height="12" rx="1" stroke="#08044F" strokeWidth="2" />
      <path
        d="M24 15H14C13.4477 15 13 15.4477 13 16V18C13 18.5523 13.4477 19 14 19H24C24.5523 19 25 18.5523 25 18V16C25 15.4477 24.5523 15 24 15Z"
        fill="#FF554D"
      />
      <path
        d="M31 9H28C27.4477 9 27 9.44772 27 10V12C27 12.5523 27.4477 13 28 13H31C31.5523 13 32 12.5523 32 12V10C32 9.44772 31.5523 9 31 9Z"
        fill="#1EBDAF"
      />
      <line
        x1="14"
        y1="11"
        x2="24"
        y2="11"
        stroke="#08044F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M35 29V37C35 38.1046 34.1046 39 33 39H12.3757C11.0636 39 10 37.8975 10 36.5375L10 21V8C10 6.89543 10.8954 6 12 6H19H31H32.6243C33.9363 6 35 7.10251 35 8.46252V15"
        stroke="#08044F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M42 18H28C27.4477 18 27 18.4477 27 19V25C27 25.5523 27.4477 26 28 26H29.4648C29.7992 26 30.1114 26.1671 30.2969 26.4453L31.1679 27.7519C31.5638 28.3457 32.4362 28.3457 32.832 27.7519L33.7031 26.4453C33.8886 26.1671 34.2008 26 34.5352 26H42C42.5523 26 43 25.5523 43 25V19C43 18.4477 42.5523 18 42 18Z"
        fill="#0189FF"
      />
      <line
        x1="38"
        y1="22"
        x2="44"
        y2="22"
        stroke="#08044F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="32"
        y1="22"
        x2="34"
        y2="22"
        stroke="#08044F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="35" cy="35" r="8" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 35C27 30.6 30.6 27 35 27C39.4 27 43 30.6 43 35C43 39.4 39.4 43 35 43C30.6 43 27 39.4 27 35ZM30.6 35L34 38.4L39.4 33L38 31.6L34 35.6L32 33.6L30.6 35Z"
        fill="#28A745"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45 35C45 40.5228 40.5228 45 35 45C29.4772 45 25 40.5228 25 35C25 29.4772 29.4772 25 35 25C40.5228 25 45 29.4772 45 35ZM35 43C39.4183 43 43 39.4183 43 35C43 30.5817 39.4183 27 35 27C30.5817 27 27 30.5817 27 35C27 39.4183 30.5817 43 35 43Z"
        fill="white"
      />
    </svg>
  )
}
