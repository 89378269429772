import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, Controller } from 'react-hook-form'

import { Types } from './types'
import { Label, handleRequired, TypeError } from '../'
import { WrapperSelectForm } from './Styles'
import { MySelect } from './index'

export const SelectForm = ({
  name,
  options,
  label,
  placeholder,
  onChange: handleChange,
  disabled,
  required,
}: Types.SelectForm) => {
  const { control, formState, errors } = useFormContext()
  const { t } = useTranslation()

  const error = errors[name]?.message

  const statusRequired = handleRequired(required)

  const showError = statusRequired !== false
  const classStatus = showError && error ? 'status-error' : ''

  /**
   * Esto valida si estoy en un test de nivel con alumno anónimo
   */
  const isLevelTest = window.location.href.includes('test-level')

  return (
    <WrapperSelectForm className={`profile-${name} ${classStatus}`}>
      <Label htmlFor={name}>{label}</Label>
      {!required && isLevelTest && <span className="optional">(opcional)</span>}
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        rules={{ required: statusRequired }}
        render={({ value, onChange }) => (
          <MySelect
            name={name}
            options={options}
            placeholder={placeholder || t('ResourceSelect')}
            value={value}
            disabled={disabled || options.length === 0 || formState.isSubmitting}
            onChange={(e) => {
              onChange(e)
              handleChange && handleChange(name, e)
            }}
          />
        )}
      />
      {statusRequired !== false && <TypeError error={error} />}
    </WrapperSelectForm>
  )
}
