import { gql } from '@apollo/client'

import * as Type from '../types'

export const ALL_EXECUTIVE_POSITIONS = gql`
  query allExecutivePositions($headingId: Int) {
    allExecutivePositions(orderBy: sortOrder_ASC, filter: { headingId: $headingId }) {
      name
      id
    }
  }
`

export interface ALL_EXECUTIVE_POSITIONS_TYPE {
  allExecutivePositions: Type.list[]
}
