import { gql, useQuery, QueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

export const GET_QUESTION_VIEW = gql`
  ${STATUS_FIELDS}
  query getQuestionView($QuestionViewInput: QuestionViewInput!) {
    getQuestionView(input: $QuestionViewInput) {
      status {
        ...StatusFields
      }
      Question {
        id
        text
        title
        public
        created
        isAnswered
        Student {
          id
          Person {
            id
            fullName
            picture
          }
        }
      }
      Config {
        referer {
          href
          name
          indicators {
            name
            image
          }
        }
        type
      }
      QuestionAnswers {
        id
        text
        parentId
        created
        viewedAt
        Childrens {
          id
          text
          created
          viewedAt
          Student {
            id
            profileId
            Person {
              fullName
              picture
              id
            }
          }
        }
        Student {
          id
          profileId
          Person {
            fullName
            picture
            id
          }
        }
      }
      total
      pages
    }
  }
`

export type QuestionViewPayload = Api.QuestionViewPayload

export type QuestionViewInput = Api.QuestionViewInput

export interface QuestionViewPayloadApollo {
  getQuestionView: QuestionViewPayload
}

export interface QuestionViewInputApollo {
  QuestionViewInput: QuestionViewInput
}

export const useQueryGetQuestionView = (
  options?: QueryHookOptions<QuestionViewPayloadApollo, QuestionViewInputApollo>
) => {
  return useQuery<QuestionViewPayloadApollo, QuestionViewInputApollo>(GET_QUESTION_VIEW, options)
}
