import { vars } from '@eclass/ui-kit'
import { Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export const HasFeedback = () => {
  const { t } = useTranslation()
  return (
    <Text fontSize={'.875rem'} color={vars('colors-neutral-gray')}>
      {t('CEVFeedbackDone')}
    </Text>
  )
}
