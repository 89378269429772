import React from 'react'
import toast from 'toasted-notes'
import ReactHtmlParser from 'react-html-parser'

import { SideFlash } from './'
import { AlertNotification as Props } from './helpers/types'

const VISIBLE = 6000
const EDGE_DISTANCE = 30
const DEFAULT_POSITION = 'top'
/**
 * Componente que muestra una alerta tipo notificación,
 * posee diferentes estados
 *
 * @example
 * <AlertNotification
 *  message="Error al ingresar"
 *  show={true}
 * />
 */
export const AlertNotification = React.memo(
  ({
    closed,
    duration = VISIBLE,
    margin = EDGE_DISTANCE,
    message,
    position = DEFAULT_POSITION,
    show,
    type,
    toasted,
    maxWidth,
    queueIt = false,
    cleanUp = false,
  }: Props) => {
    const clean = () => {
      const alerts = document.getElementsByClassName('Toaster__manager-top')
      if (alerts?.[0].textContent !== '') {
        alerts[0].innerHTML = ''
      }
    }

    React.useEffect(() => {
      return () => {
        cleanUp && clean()
      }
    }, [])

    if (!show || (document.getElementById('alert') && !queueIt)) {
      return null
    }

    return (
      <>
        {toast.notify(
          ({ onClose }) => (
            <SideFlash
              maxWidth={maxWidth}
              type={type}
              margin={margin}
              onClose={onClose}
              toasted={toasted}
            >
              <span>{ReactHtmlParser(message)}</span>
            </SideFlash>
          ),
          {
            position,
            duration,
          }
        )}
      </>
    )
  }
)
