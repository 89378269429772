export const fileInitialState = {
  loaded: false,
  name: '',
  extension: '',
  rejectModal: false,
  rejectMessage: {
    title: '',
    message: '',
  },
}

export const fileReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'loaded':
      return {
        ...state,
        loaded: true,
        name: action.payload.name,
        extension: action.payload.extension,
        remove: false,
      }
    case 'remove':
      return { ...state, name: '', loaded: false, remove: true }
    case 'rejected':
      return {
        ...state,
        rejectModal: true,
        rejectMessage: {
          title: action.payload.title,
          message: action.payload.message,
        },
      }
    case 'get_it':
      return {
        ...state,
        rejectModal: false,
        rejectMessage: {
          title: '',
          message: '',
        },
      }

    default:
      return state
  }
}
