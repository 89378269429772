import styled from 'styled-components/macro'
import { PADDING2, PADDING3, WIDTH_COLUMN_3 } from 'app/Components/UI/Grid'
import { setMultimediaV8 } from 'app/Views/Tests/Questions/QuestionTypes/Alternatives/Style'

export const Wrapper = styled.div`
  font-size: 18px;
  line-height: 44px;
  div > p {
    font-size: 18px;
    line-height: 44px;
    margin-bottom: 0;
  }
  .InputUI,
  .Select {
    display: inline-block;
    vertical-align: middle;
    .form-group {
      margin: 0;
    }
    .container-input {
      padding-left: 0;
      padding-right: 0;
    }
    select {
      min-width: 116px;
    }
  }
  .Select {
    .input-box:after {
      right: 8px;
    }
  }
  > div.question {
    display: block;
  }
  .grid-TI-SITB-2,
  .grid-TI-SITB-3 {
    display: flex;
    flex-wrap: wrap;
    & > div,
    & > section {
      margin-bottom: 15px;
    }
    img,
    audio {
      width: 100%;
    }
  }
  .grid-TI-SITB-2 {
    & > div,
    & > section {
      width: 50%;
      &:nth-child(odd) {
        padding-right: ${PADDING2}px;
      }
      &:nth-child(even) {
        padding-left: ${PADDING2}px;
      }
      &:nth-last-child(1),
      &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }
  }
  .grid-TI-SITB-3 {
    left: 50%;
    min-width: ${WIDTH_COLUMN_3}px;
    transform: translateX(-50%);
    position: relative;
    & > div,
    & > section {
      width: 33.3333%;
      &:nth-child(1),
      &:nth-child(3n - 2) {
        padding-left: 0;
        padding-right: ${PADDING3}px;
      }
      &:nth-child(2),
      &:nth-child(3n - 1) {
        padding-left: ${PADDING3 / 2}px;
        padding-right: ${PADDING3 / 2}px;
      }
      &:nth-child(3),
      &:nth-child(3n) {
        padding-left: ${PADDING3}px;
        padding-right: 0;
      }
      &:nth-last-child(1),
      &:nth-last-child(2),
      &:nth-last-child(3) {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: ${WIDTH_COLUMN_3}px) {
    .grid-TI-SITB-3 {
      left: 0;
      min-width: 100%;
      transform: translateX(0);
      & > div,
      & > section {
        width: 50%;
        &:nth-child(odd) {
          padding-left: 0;
          padding-right: ${PADDING2}px;
        }
        &:nth-child(even) {
          padding-left: ${PADDING2}px;
          padding-right: 0;
        }
        &:nth-last-child(3) {
          margin-bottom: 48px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    p,
    div > p {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 640px) {
    margin-bottom: 30px !important;
    .grid-TI-SITB-2,
    .grid-TI-SITB-3 {
      margin: 0 -15px;
      width: 100vw;
      & > div,
      & > section {
        width: 100%;
        &:not(:last-child) {
          margin-bottom: 0px;
          padding-bottom: 21px;
          margin-bottom: 21px;
        }
        &:nth-child(1n + 1) {
          border-bottom: 1px solid #c6c6c6;
          padding-left: 15px;
          padding-right: 15px;
        }
        &:last-child {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }
    .grid-TI-SITB-2.paired {
      & > div,
      & > section {
        &:not(:last-child) {
          margin-bottom: 30px;
          padding-bottom: 30px;
        }
        &:nth-child(odd) {
          border-bottom: none;
          margin-bottom: 0px;
          padding-bottom: 0px;
        }
        &:nth-child(even) {
          border-bottom: 1px solid #c6c6c6;
          margin-bottom: 21px;
          padding-bottom: 21px;
        }
        &:last-child {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }
  }
  ${setMultimediaV8()}
  .Article__image_box,
  .Article__image_break,
  .Article__image_zoom,
  .Article__video,
  .Article__audio,
  .Article__video {
    padding-bottom: 9px;
    padding-top: 9px;
    line-height: 0;
  }
`

type PropsCharts = {
  size: number
}

const MIN_LETER = 3
const WIDTH_MINIMO_INPUT = 57
const WIDTH_PER_LETTER = 8
export const Charts = styled.div<PropsCharts>`
  display: inline-block;
  max-width: ${({ size }: PropsCharts) =>
    size > MIN_LETER
      ? WIDTH_MINIMO_INPUT + WIDTH_PER_LETTER * (size - MIN_LETER)
      : WIDTH_MINIMO_INPUT}px;
  width: 100%;
  > .InputUI {
    width: 100%;
  }
`

type ValidateProps = {
  color: string
}

export const Validate = styled.div<ValidateProps>`
  display: inline-block;
  line-height: 24px;
  max-width: calc(100% - 9px);
  & * {
    display: inline-block;
  }
  &,
  p {
    color: ${({ color }: ValidateProps) => color};
  }
  svg {
    margin-right: 5px;
    margin-top: 3px;
    vertical-align: top;
  }
  ul {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      &:not(:last-child) {
        &:after {
          content: '/';
          margin: 0 2px;
        }
      }
    }
  }
`
