import * as Type from './types'
import { Vertical } from './Vertical'
import { Horizontal } from './Horizontal'
import { PDF } from './PDF'
import { useViewDownload } from 'app/Views/Resources/PDF'

/** Numero minimo de hitos por timeline */
const MINIMUM_REQUIRED = 2

export const Timeline = ({ config, embeds }: Type.propsTimeline) => {
  const isViewDownload = useViewDownload()

  if (config.Milestones.length < MINIMUM_REQUIRED) {
    return null
  }

  /** Ordeno lista de hitos */
  const Milestones = config.Milestones.sort((a, b) => {
    return a.sortOrder - b.sortOrder
  })

  const data: Type.dataTimeline = {
    ...config,
    Milestones,
    embeds,
  }

  if (isViewDownload) {
    return <PDF data={data} />
  }

  if (config.isPortrait) {
    return <Vertical data={data} />
  }

  return <Horizontal data={data} />
}
