export const Board = (props) => {
  return (
    <g {...props}>
      <path
        d="M2 8H14C14.6 8 15 7.6 15 7V1C15 0.4 14.6 0 14 0H2C1.4 0 1 0.4 1 1V7C1 7.6 1.4 8 2 8Z"
        fill="#0189FF"
      />
      <path
        d="M16 10H0V12H4L2.2 14.4C1.9 14.8 2 15.5 2.4 15.8C2.6 15.9 2.8 16 3 16C3.3 16 3.6 15.9 3.8 15.6L6.5 12H7V14H9V12H9.5L12.2 15.6C12.4 15.9 12.7 16 13 16C13.2 16 13.4 15.9 13.6 15.8C14 15.5 14.1 14.8 13.8 14.4L12 12H16V10Z"
        fill="#0189FF"
      />
    </g>
  )
}
