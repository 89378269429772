import React from 'react'

export const SurveyIcon = () => (
  <svg
    width="45"
    data-testid="SurveyIcon"
    className="survey"
    id="survey"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
    <path
      d="M40 14H22C21.4477 14 21 14.4477 21 15V17C21 17.5523 21.4477 18 22 18H40C40.5523 18 41 17.5523 41 17V15C41 14.4477 40.5523 14 40 14Z"
      fill="#1EBDAF"
    />
    <path
      d="M28 22H22C21.4477 22 21 22.4477 21 23V25C21 25.5523 21.4477 26 22 26H28C28.5523 26 29 25.5523 29 25V23C29 22.4477 28.5523 22 28 22Z"
      fill="#FFBC27"
    />
    <path
      d="M38 30H22C21.4477 30 21 30.4477 21 31V33C21 33.5523 21.4477 34 22 34H38C38.5523 34 39 33.5523 39 33V31C39 30.4477 38.5523 30 38 30Z"
      fill="#0189FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 7C11.4477 7 11 7.44772 11 8V36.5375C11 37.3793 11.6494 38 12.3757 38H33C33.5523 38 34 37.5523 34 37V8.46252C34 7.62067 33.3505 7 32.6243 7H31C30.4477 7 30 6.55228 30 6C30 5.44772 30.4477 5 31 5H32.6243C34.5221 5 36 6.58434 36 8.46252V37C36 38.6569 34.6569 40 33 40H12.3757C10.4779 40 9 38.4157 9 36.5375V8C9 6.34315 10.3431 5 12 5H14C14.5523 5 15 5.44772 15 6C15 6.55228 14.5523 7 14 7H12Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 24C13 22.3431 14.3431 21 16 21C17.6569 21 19 22.3431 19 24C19 25.6569 17.6569 27 16 27C14.3431 27 13 25.6569 13 24ZM16 23C15.4477 23 15 23.4477 15 24C15 24.5523 15.4477 25 16 25C16.5523 25 17 24.5523 17 24C17 23.4477 16.5523 23 16 23Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 16C13 14.3431 14.3431 13 16 13C17.6569 13 19 14.3431 19 16C19 17.6569 17.6569 19 16 19C14.3431 19 13 17.6569 13 16ZM16 15C15.4477 15 15 15.4477 15 16C15 16.5523 15.4477 17 16 17C16.5523 17 17 16.5523 17 16C17 15.4477 16.5523 15 16 15Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 32C13 30.3431 14.3431 29 16 29C17.6569 29 19 30.3431 19 32C19 33.6569 17.6569 35 16 35C14.3431 35 13 33.6569 13 32ZM16 31C15.4477 31 15 31.4477 15 32C15 32.5523 15.4477 33 16 33C16.5523 33 17 32.5523 17 32C17 31.4477 16.5523 31 16 31Z"
      fill="#08044F"
    />
    <rect x="16" y="1" width="13" height="5" rx="2" fill="#FF554D" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27 3H18L18 8H27V3ZM18 1C16.8954 1 16 1.89543 16 3V8C16 9.10457 16.8954 10 18 10H27C28.1046 10 29 9.10457 29 8V3C29 1.89543 28.1046 1 27 1H18Z"
      fill="#08044F"
    />
  </svg>
)
