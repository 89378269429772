import styled, { css } from 'styled-components/macro'

import { layout } from '../Common'

export const Wrapper = styled.div`
  margin-bottom: 30px;
  margin-top: -20px;

  ${layout(
    css`
      .profile-paragraphPhone {
        margin-bottom: 0;
        font-size: 0.875rem;
        grid-area: paragraphPhone;
      }

      .profile-phoneTypeId {
        grid-area: phoneTypeId;
      }

      .profile-phoneNumber {
        grid-area: phoneNumber;
      }

      .profile-isWhatsapp {
        grid-area: isWhatsapp;
      }

      .profile-canContactWhatsapp {
        grid-area: canContactWhatsapp;
      }

      grid-template-areas:
        'paragraphPhone paragraphPhone'
        'phoneTypeId phoneNumber'
        'canContactWhatsapp canContactWhatsapp'
        'isWhatsapp isWhatsapp';

      &.profile {
        margin-bottom: 0;
        grid-template-areas:
          'paragraphPhone paragraphPhone'
          'phoneTypeId phoneNumber'
          'canContactWhatsapp canContactWhatsapp'
          'isWhatsapp isWhatsapp';

        &.isChekedWhatsapp {
          grid-template-areas:
            'paragraphPhone paragraphPhone'
            'phoneTypeId phoneNumber'
            'canContactWhatsapp canContactWhatsapp'
            'isWhatsapp isWhatsapp';
        }
      }
    `
  )}

  @media screen and (max-width: 425px) {
    margin-top: 0;
  }
`
