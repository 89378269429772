import { gql, useQuery, QueryHookOptions, useApolloClient } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'
import { useState } from 'react'

export const GET_COURSE_MENU = gql`
  ${STATUS_FIELDS}
  query getCourseMenu($CourseMenuInput: CourseMenuInput!) {
    getCourseMenu(input: $CourseMenuInput) {
      status {
        ...StatusFields
      }
      Menu {
        Unit {
          id
          name
        }
        Links {
          id
          name
          href
          indicators {
            name
            image
            obtained
            max
            startDate
          }
          status {
            id
            detail
            name
            style
            enabled
          }
          Childrens {
            id
            name
            href
            status {
              name
              detail
              enabled
              style
            }
            indicators {
              name
              image
              obtained
              max
            }
          }
        }

        Navigation {
          prev {
            name
            href
            type {
              id
              name
            }
            status {
              id
              enabled
              id
              detail
              style
            }
          }
          next {
            name
            href
            type {
              id
              name
            }
            status {
              id
              enabled
              id
              detail
              style
            }
          }
        }
      }
    }
  }
`

const GET_COURSE_MENU_NAV = gql`
  ${STATUS_FIELDS}
  query getCourseMenu($CourseMenuInput: CourseMenuInput!) {
    getCourseMenu(input: $CourseMenuInput) {
      status {
        ...StatusFields
      }
      Menu {
        Navigation {
          prev {
            name
            href
            type {
              id
              name
            }
            status {
              id
              enabled
              detail
              style
            }
          }
          next {
            name
            href
            type {
              id
              name
            }
            status {
              id
              enabled
              detail
              style
            }
          }
        }
      }
    }
  }
`

export type GetCourseMenuPayload = Api.CourseMenuPayload

export type GetCourseMenuInput = Api.CourseMenuInput

export interface GetCourseMenuPayloadApollo {
  getCourseMenu: GetCourseMenuPayload
}

export interface GetCourseMenuInputApollo {
  CourseMenuInput: GetCourseMenuInput
}

export interface GetCourseMenuInputApolloInternal {
  CourseMenuInput: GetCourseMenuInput
  onlyNav?: boolean
}

export const useQueryGetCourseMenu = (
  options?: QueryHookOptions<GetCourseMenuPayloadApollo, GetCourseMenuInputApolloInternal>
) => {
  const query = options?.variables?.onlyNav ? GET_COURSE_MENU_NAV : GET_COURSE_MENU
  return useQuery<GetCourseMenuPayloadApollo, GetCourseMenuInputApolloInternal>(query, options)
}

/**
 * Obtiene la información cacheada de la query getCourseMenu,
 * para utilizar el updateCache se debe utilizar antes el getCache.
 * si no es así se deben pasar los parametros de la query
 */
export const useSetCacheQueryGetCourseMenu = () => {
  const client = useApolloClient()
  const [inputGlobal, setInputGlobal] = useState<GetCourseMenuInput>()

  const getCache = ({ input }: { input: GetCourseMenuInput }) => {
    setInputGlobal(input)
    return client.readQuery<GetCourseMenuPayloadApollo, GetCourseMenuInputApolloInternal>({
      query: GET_COURSE_MENU,
      variables: {
        CourseMenuInput: input,
      },
    })
  }

  const updateCache = ({
    newData,
    input,
  }: {
    newData: GetCourseMenuPayloadApollo
    input?: GetCourseMenuInput
  }) => {
    client.writeQuery({
      query: GET_COURSE_MENU,
      data: {
        ...newData,
      },
      variables: {
        CourseMenuInput: input ?? inputGlobal,
      },
    })
  }

  return { getCache, updateCache }
}
