/**
 * Agrego un Timeout para peticiones
 */
export const fetchWithTimeout = async (resource: string, options) => {
  const { timeout = 50000 } = options

  const controller = new AbortController()
  const id = setTimeout(() => controller.abort(), timeout)

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  })
  clearTimeout(id)

  return response
}
