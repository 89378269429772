import React from 'react'

export const HelperIcon = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22.5" cy="22.5" r="22.5" fill="#ffffff" />
      <path
        d="M34 1H10C9.44772 1 9 1.44771 9 2V11C9 11.5523 9.44772 12 10 12H10.9709C11.5096 12 11.9194 12.4837 11.8308 13.0151C11.7053 13.7679 12.5393 14.3071 13.1743 13.8838L15.7481 12.1679C15.9123 12.0584 16.1054 12 16.3028 12H19.4648C19.7992 12 20.1114 12.1671 20.2969 12.4453L21.1679 13.7519C21.5638 14.3457 22.4362 14.3457 22.8321 13.7519L23.7031 12.4453C23.8886 12.1671 24.2008 12 24.5352 12H27.6667C27.883 12 28.0936 12.0702 28.2667 12.2L30.4 13.8C31.0592 14.2944 32 13.824 32 13C32 12.4477 32.4477 12 33 12H34C34.5523 12 35 11.5523 35 11V2C35 1.44772 34.5523 1 34 1Z"
        fill="#FF554D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 8C19 7.44772 19.4477 7 20 7L27 7C27.5523 7 28 7.44771 28 8C28 8.55228 27.5523 9 27 9L20 9C19.4477 9 19 8.55228 19 8Z"
        fill="#08044F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 8C13 7.44772 13.4477 7 14 7L16 7C16.5523 7 17 7.44771 17 8C17 8.55228 16.5523 9 16 9L14 9C13.4477 9 13 8.55228 13 8Z"
        fill="#08044F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 4C13 3.44772 13.4477 3 14 3L23 3C23.5523 3 24 3.44771 24 4C24 4.55228 23.5523 5 23 5L14 5C13.4477 5 13 4.55228 13 4Z"
        fill="#08044F"
      />
      <path
        d="M18 26C18 24.8954 18.8954 24 20 24H25C26.1046 24 27 24.8954 27 26V31H18V26Z"
        fill="#0189FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 28.5C16 24.9101 18.9101 22 22.5 22C26.0899 22 29 24.9101 29 28.5C29 32.0899 26.0899 35 22.5 35C18.9101 35 16 32.0899 16 28.5ZM22.5 24C20.0147 24 18 26.0147 18 28.5C18 30.9853 20.0147 33 22.5 33C24.9853 33 27 30.9853 27 28.5C27 26.0147 24.9853 24 22.5 24Z"
        fill="#08044F"
      />
      <path
        d="M33 20C33 18.8954 33.8954 18 35 18H40C41.1046 18 42 18.8954 42 20V25H33V20Z"
        fill="#1EBDAF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 22.5C31 18.9101 33.9101 16 37.5 16C41.0899 16 44 18.9101 44 22.5C44 26.0899 41.0899 29 37.5 29C33.9101 29 31 26.0899 31 22.5ZM37.5 18C35.0147 18 33 20.0147 33 22.5C33 24.9853 35.0147 27 37.5 27C39.9853 27 42 24.9853 42 22.5C42 20.0147 39.9853 18 37.5 18Z"
        fill="#08044F"
      />
      <path
        d="M3 19C3 17.8954 3.89543 17 5 17H10C11.1046 17 12 17.8954 12 19V24H3V19Z"
        fill="#FFBC27"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 21.5C1 17.9101 3.91015 15 7.5 15C11.0899 15 14 17.9101 14 21.5C14 25.0899 11.0899 28 7.5 28C3.91015 28 1 25.0899 1 21.5ZM7.5 17C5.01472 17 3 19.0147 3 21.5C3 23.9853 5.01472 26 7.5 26C9.98528 26 12 23.9853 12 21.5C12 19.0147 9.98528 17 7.5 17Z"
        fill="#08044F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.3694 38.8275C27.5995 37.0793 25.0943 36 22.3333 36C19.5723 36 17.0671 37.0793 15.2972 38.8275C14.9043 39.2156 14.9004 39.8488 15.2885 40.2417C15.6766 40.6346 16.3098 40.6385 16.7027 40.2504C18.0963 38.8739 20.0956 38 22.3333 38C24.571 38 26.5703 38.8739 27.9639 40.2504C28.3568 40.6385 28.99 40.6346 29.3781 40.2417C29.7662 39.8488 29.7623 39.2156 29.3694 38.8275Z"
        fill="#08044F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.3694 32.8275C42.5995 31.0793 40.0943 30 37.3333 30C34.5723 30 32.0671 31.0793 30.2972 32.8275C29.9043 33.2156 29.9004 33.8488 30.2885 34.2417C30.6766 34.6346 31.3098 34.6385 31.7027 34.2504C33.0963 32.8739 35.0956 32 37.3333 32C39.571 32 41.5703 32.8739 42.9639 34.2504C43.3568 34.6385 43.99 34.6346 44.3781 34.2417C44.7662 33.8488 44.7623 33.2156 44.3694 32.8275Z"
        fill="#08044F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3694 31.8275C12.5995 30.0793 10.0943 29 7.33329 29C4.57226 29 2.06711 30.0793 0.297221 31.8275C-0.0957012 32.2156 -0.0996017 32.8488 0.28851 33.2417C0.676621 33.6346 1.30977 33.6385 1.7027 33.2504C3.09626 31.8739 5.09561 31 7.33329 31C9.57097 31 11.5703 31.8739 12.9639 33.2504C13.3568 33.6385 13.99 33.6346 14.3781 33.2417C14.7662 32.8488 14.7623 32.2156 14.3694 31.8275Z"
        fill="#08044F"
      />
    </svg>
  )
}
