/**
 * Configuración de react-dropzone, tamaño y formatos permitidos
 */
export const configDropzone = {
  maxSize: 150000000,
  accept: [
    'text/plain',

    // Word documents
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.ms-word.document.macroEnabled.12',
    'application/vnd.ms-word.template.macroEnabled.12',

    // Excel documents
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.ms-excel.template.macroEnabled.12',
    'application/vnd.ms-excel.addin.macroEnabled.12',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12',

    // PowerPoint documents
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.template',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.ms-powerpoint.addin.macroEnabled.12',
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
    'application/vnd.ms-powerpoint.template.macroEnabled.12',
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',

    // Access database
    'application/vnd.ms-access',

    // Compressed files
    'application/zip',
    'application/octet-stream',
    'application/x-rar-compressed',

    // PDF
    'application/pdf',

    // Audio files
    'audio/mpeg',
    'audio/mp3',
    'audio/x-wav',
    'audio/x-ms-wma',

    // Video files
    'video/x-msvideo',
    'video/mp4',
    'video/mpeg',
    'video/x-ms-wmv',

    // Image files
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/tiff',
  ],
}
