// External dependencies
import React from 'react'

// Local dependencies
import { SideInfo } from '../../SideInfo'
import { Feedback } from '../../Feedback'
import { Options } from './Options'
import * as Style from './Style'
import { Question } from '../types'
import Enunciated from './Enunciated'
import VisibilitySensor from 'app/Components/VisibilitySensor'
import { Skip, classOmitted } from '../../Skip'

import { multipleChoiceTest, multipleChoice } from '../../QuestionWrapper/idsQuetionTypes'

export const Alternatives = ({
  answer,
  position,
  action,
  evaluationState,
  optionDisabled,
  showFeedback,
  setActiveQuestion,
  gridOptions,
  type,
  id,
  lastChild,
  excercises,
  hasLevelsStages,
  missingAnswers,
  feedback,
  score,
}: Question) => {
  let typeOption = type
  // cuando es control tipo choise y trae id 14. lo paso a 23 el que le corresponde
  if (!excercises && type === multipleChoice) {
    typeOption = multipleChoiceTest
  }

  const sideInfo = !excercises

  return (
    <VisibilitySensor setter={setActiveQuestion} id={`question-${id}`}>
      <div className={`alternatives ${lastChild ? 'grid-lastchild' : ''}`} id={`question-${id}`}>
        <Style.Data
          className={`QuestionContainer ${classOmitted(answer.skipped!, evaluationState)}`}
        >
          {sideInfo && (
            <SideInfo
              status={answer.state}
              position={position}
              evaluationState={evaluationState}
              score={score}
            />
          )}
          <Style.Content className="alternatives-conte">
            <Enunciated
              answer={answer}
              position={position}
              sideInfo={sideInfo}
              id={id}
              hasLevelsStages={hasLevelsStages}
              missingAnswers={missingAnswers}
              evaluationState={evaluationState}
            />
            <Options
              grid={gridOptions}
              answer={answer}
              action={action}
              optionDisabled={optionDisabled}
              id={id}
              type={typeOption}
              evaluationState={evaluationState}
            />
            <Skip answer={answer} action={action} evaluationState={evaluationState} />
          </Style.Content>
        </Style.Data>
        <Feedback
          answer={answer}
          evaluationState={evaluationState}
          showFeedback={showFeedback}
          type={feedback}
        />
      </div>
    </VisibilitySensor>
  )
}
