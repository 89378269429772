import { Button } from '@chakra-ui/react'

import { ChevronNext, ChevronPrev } from 'app/Components/UI/Icons'

interface props {
  next?: boolean
  onClick: () => void
}

/**
 * @param {Boolean} employees - next para mostrar el icono >
 * @param {Function} onClick - Metodo al hacerle click
 * @example <Arrow onClick={() => refSlider.current?.slickPrev()} />
 */
export const Arrow = ({ next, onClick }: props) => {
  const bg = 'var(--chakra-colors-main-deepSkyBlue)'
  const color = 'var(--chakra-colors-neutral-white)'
  return (
    <Button
      data-testid={`arrow-${next ? 'next' : 'prev'}`}
      border="none"
      outline="none"
      borderRadius="50%"
      bg={bg}
      h="2.5rem"
      w="2.5rem"
      _hover={{ bg }}
      _active={{ bg }}
      _focus={{ boxShadow: 'none' }}
      onClick={(e) => {
        e.preventDefault()
        onClick && onClick()
      }}
    >
      {next ? <ChevronNext color={color} /> : <ChevronPrev color={color} />}
    </Button>
  )
}
