import { useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { UIVars } from 'app/Components/UI'
import { BtnSecondary } from '@eclass/ui-kit'

type Props = {
  text: string
  className?: string
}

const RESET_TIME = 3000

/**
 * Botón que copia en el portapapeles el texto que se le pase
 * @param text {string}
 * @param className {string}
 * @example <ButtonCopy text="hola" />
 */
export const ButtonCopy = ({ text, className }: Props) => {
  const { t } = useTranslation()
  const init = t('DashboardCopy')
  const [state, setState] = useState(init)

  /**
   * Paso a state a copiado y copio texto en el portapapeles
   * se resetea al tiempo configurado en RESET_TIME
   */
  const onClick = () => {
    if (state === init) {
      const inputFake: HTMLInputElement = document.createElement('input')
      if (inputFake && document) {
        // Asigna el contenido del elemento especificado al valor del campo
        inputFake.setAttribute('value', text)

        // Añade el campo a la página
        document.body.appendChild(inputFake)

        // Selecciona el contenido del campo
        inputFake.select()

        // Copia el texto seleccionado
        document.execCommand('copy')

        // Elimina el campo de la página
        document.body.removeChild(inputFake)
        setState(t('DashboardCopied'))
        setTimeout(() => {
          setState(init)
        }, RESET_TIME)
      }
    }
  }

  // clase css para el estilo del copiado
  let status = ''
  if (state !== init) {
    status = 'copied'
  }

  return (
    <StyleButton data-testid="ButtonCopy" className={`${status} ${className}`}>
      <BtnSecondary onClick={onClick} size="small">
        {state}
      </BtnSecondary>
    </StyleButton>
  )
}

const StyleButton = styled.div`
  margin-left: 8px;
  transition: all 0.3s;

  &.copied {
    button {
      background: ${UIVars.setColors.success};
      color: ${UIVars.setColors.black};
    }
  }

  :focus {
    outline-width: 0;
  }
`
