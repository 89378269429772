import React from 'react'

export const CoursesIcon = () => (
  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 38C9.20914 38 11 36.2091 11 34C11 31.7909 9.20914 30 7 30C4.79086 30 3 31.7909 3 34C3 36.2091 4.79086 38 7 38Z"
      fill="#0189FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 37C24.6569 37 26 35.6569 26 34C26 32.3431 24.6569 31 23 31C21.3431 31 20 32.3431 20 34C20 35.6569 21.3431 37 23 37Z"
      fill="#1EBDAF"
      stroke="#1EBDAF"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 34C7 33.4477 7.44772 33 8 33L36 33C36.5523 33 37 33.4477 37 34C37 34.5523 36.5523 35 36 35L8 35C7.44772 35 7 34.5523 7 34Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35 34C35 31.7909 36.7909 30 39 30C41.2091 30 43 31.7909 43 34C43 36.2091 41.2091 38 39 38C36.7909 38 35 36.2091 35 34ZM39 32C37.8954 32 37 32.8954 37 34C37 35.1046 37.8954 36 39 36C40.1046 36 41 35.1046 41 34C41 32.8954 40.1046 32 39 32Z"
      fill="#08044F"
    />
    <path
      d="M2 12C2 10.8954 2.89543 10 4 10H19C20.1046 10 21 10.8954 21 12V22H2V12Z"
      fill="#FF554D"
    />
    <path
      d="M25 12C25 10.8954 25.8954 10 27 10H42C43.1046 10 44 10.8954 44 12V22H25V12Z"
      fill="#FFBC27"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 20H4L4 24H19V20ZM2 18V24C2 25.1046 2.89543 26 4 26H19C20.1046 26 21 25.1046 21 24V18H2Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42 20H27L27 24H42V20ZM25 18V24C25 25.1046 25.8954 26 27 26H42C43.1046 26 44 25.1046 44 24V18H25Z"
      fill="#08044F"
    />
  </svg>
)
