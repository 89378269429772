import styled from 'styled-components/macro'
import UIVars from '../../variables'

export const LinkGoBack = styled.div`
  position: absolute;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: ${UIVars.setColors.brownGreyTwo};

  @media screen and (max-width: 640px) {
    position: inherit;
  }
`
