export const ArrowNext = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
        fill="#0189FF"
      />
      <path d="M18.0741 26L24 20L18.0741 14L16 16.1L19.8519 20L16 23.9L18.0741 26Z" fill="white" />
    </svg>
  )
}
