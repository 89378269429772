import { vars } from '@eclass/ui-kit'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  background: white;

  .ResourceNav {
    &__back {
      position: relative;
      padding-bottom: 12px;
      padding-top: 12px;

      > Button {
        padding: 0;
      }

      align-items: center;
      display: flex;
      line-height: 1.3;
      justify-content: end;
    }
  }

  .ResourceNav__menu {
    .Objectives__Button {
      button {
        font-size: 14px;
        height: 32px;

        .Svg__Icon {
          margin-right: 0px;
        }
      }
    }
  }

  &.isFixed {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 999;
  }

  button {
    flex-shrink: 0;
  }

  a {
    color: ${vars('colors-neutral-spanishGrey')};
    font-size: 14px;
    text-decoration: none;

    img {
      font-size: 14px !important;
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 0;

    .ResourceNav {
      &__back {
        height: 50px;
      }
    }

    .container {
      padding: 0;
    }

    &.fixed {
      top: 0px;
    }
  }
`
export default Wrapper
