export const Afternoon = (props) => {
  return (
    <g {...props}>
      <path d="M9 0H7V2H9V0Z" fill="#B0CFE0" />
      <path
        d="M14.2941 3.05152L12.8803 1.63672L11.4655 3.05052L12.8793 4.46532L14.2941 3.05152Z"
        fill="#B0CFE0"
      />
      <path d="M16 7H14V9H16V7Z" fill="#B0CFE0" />
      <path
        d="M11.5352 13.0193L12.95 14.4331L14.3638 13.0183L12.949 11.6045L11.5352 13.0193Z"
        fill="#B0CFE0"
      />
      <path d="M9 14H7V16H9V14Z" fill="#B0CFE0" />
      <path
        d="M1.56618 12.9485L2.97998 14.3633L4.39478 12.9495L2.98098 11.5347L1.56618 12.9485Z"
        fill="#B0CFE0"
      />
      <path d="M2 7H0V9H2V7Z" fill="#B0CFE0" />
      <path
        d="M4.46464 3.12034L3.04984 1.70654L1.63604 3.12134L3.05084 4.53514L4.46464 3.12034Z"
        fill="#B0CFE0"
      />
      <path
        d="M8 4C5.8 4 4 5.8 4 8C4 10.2 5.8 12 8 12C10.2 12 12 10.2 12 8C12 5.8 10.2 4 8 4Z"
        fill="#B0CFE0"
      />
    </g>
  )
}
