import { IAnswered, TAnswer, ISelect } from '../types'

export const parseFeedback = ({
  type,
  feedback,
  isComplete,
  answer,
}: {
  type: string
  feedback: IAnswered['feedback']
  isComplete: boolean
  answer: TAnswer
}) => {
  if (isComplete && ['MULTICHOICE_S', 'MULTICHOICE'].includes(type) && feedback) {
    const configSelect = answer as ISelect
    // Obtengo respuesta correcta del array de opciones en el caso de que sea un select
    const correct = configSelect.options.find((option) => option.value === feedback.correctAnswer)
    if (correct) {
      return { ...feedback, correctAnswer: correct.option }
    }
  }

  return feedback
}
