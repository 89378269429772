// @External Dependencies
import axios from 'axios'

// @Dependencies
// import { PersonContactabilityInput } from '@eclass/api'
import { apiURL } from '../../../../Config/getDomainConfig'
import { axiosError } from 'app/Services'

const PersonContactability = (input) => {
  const requestId = 'PersonContactability'
  // return async (dispatch: PersonContactabilityInput) => {
  return async (dispatch) => {
    const query = `
      mutation PersonContactability($PersonContactabilityInput: PersonContactabilityInput!) {
        PersonContactability(input: $PersonContactabilityInput) {
          status {
            success
            name
            detail
            code
          }
        }
      }
    `

    const variables = {
      PersonContactabilityInput: input,
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response) => response.data.data.PersonContactability)
      .catch((err) => axiosError(err, requestId, dispatch))
  }
}

export default PersonContactability
