import { Box, CSSObject } from '@chakra-ui/react'

interface IContainer {
  children?: React.ReactNode
  /**
   * [s] 900px
   *
   * [m] 1000px Valor por defecto
   *
   * [l] 1200px
   */
  size?: /** aqweqwe */
  'xs' | 's' | 'm' | 'l' | 'xl'
  /** Le da el fondo por completo al div */
  backgroundFull?: string
  /** Le da el fondo al div, respetando los padding */
  background?: string
  /** en pixeles */
  padding?: number
  /** Permite ingresarle estilos unicos */
  sx?: CSSObject
}

/**
 * Contenedor que centra el contenido de la V8
 *
 * Por defecto tiene un ancho máximo de 1000px, pero tiene configurado dos valores mas
 *
 * @example
 * <Container>
 *   contenido
 * </Container>
 */
export const Container: React.FC<IContainer> = ({
  children,
  size = 'm',
  backgroundFull = 'transparent',
  background = 'transparent',
  padding = 16,
  sx,
}) => {
  const p = padding / 16

  const sizes = {
    xs: '50rem',
    s: '56.25rem',
    m: '62.5rem',
    l: '75rem',
    xl: '87rem',
  }

  return (
    <Box width="100%" bg={backgroundFull} p={`0 ${p}em`} sx={sx} id="LayoutsContainer">
      <Box width="100%" bg={background} maxW={sizes[size] ?? sizes.m} margin="0 auto">
        {children}
      </Box>
    </Box>
  )
}
