import React, { useCallback } from 'react'
import { DropEvent, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { AnswerType } from '../../types'
import { useFile, getFileExt } from './useFile'
import { Wrapper } from './Style'
import { Loaded } from './Loaded'
import { InfoModal } from './InfoModal'
import { configDropzone } from 'app/Services'

type Props = {
  id: number
  isMobile: boolean
  action: any
  answer: AnswerType
  optionDisabled: boolean
}

export interface FileError {
  message: string
  code: string
}

export interface FileRejection {
  file: File
  errors: FileError[]
}

export const FileZone = ({ id, isMobile, action, answer, optionDisabled }: Props) => {
  const { t } = useTranslation()
  const { file, fileDispatch } = useFile(action, answer)

  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach(async (acceptedFile) => {
        fileDispatch({
          type: 'loaded',
          payload: {
            name: acceptedFile.name,
            extension: getFileExt(acceptedFile.name),
          },
        })
        action({
          id,
          option: {
            file: acceptedFile,
            name: acceptedFile.name,
          },
          type: 'ANSWER',
        })
      })
    },
    [action, id, fileDispatch]
  )

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[], event: DropEvent) => {
      fileRejections.forEach(async (fileReject) => {
        if (fileReject.file.size > configDropzone.maxSize) {
          fileDispatch({
            type: 'rejected',
            payload: {
              title: t('CommentSystemFileExceeds', { size: 150 }),
              message: t('CommentSystemTryWithFile'),
            },
          })
        } else {
          fileDispatch({
            type: 'rejected',
            payload: {
              title: t('CrossFileCantUploaded'),
              message: t('CrossFileTypeInvalid'),
            },
          })
        }
      })
    },
    [fileDispatch, t]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...configDropzone,
    multiple: false,
    onDropAccepted,
    onDropRejected,
  })
  // Si ya tiene archivo cae en este render
  const commonLoaded = { isMobile, fileDispatch, optionDisabled }
  // cuando el archivo ya viene cargado desde el back
  if (file?.loaded && !file.remove && (isNaN(answer?.option) || answer?.option !== 'removeFile')) {
    return <Loaded {...commonLoaded} file={file} />
  } else if (
    !file.loaded &&
    typeof answer?.option === 'object' &&
    answer?.option?.name !== '' &&
    !file.remove
  ) {
    // cuando el archivo se acaba de cargar (de manera local)
    return (
      <Loaded
        {...commonLoaded}
        file={{
          name: answer?.option?.name,
          extension: getFileExt(answer?.option?.name),
        }}
      />
    )
  }

  let classSpecial = ''
  if (!isMobile) {
    classSpecial = isDragActive ? 'on-drop' : ''
  } else {
    classSpecial = 'toggleModal'
  }

  const toggleModal = () => {
    fileDispatch({ type: 'get_it' })
  }

  return (
    <>
      <InfoModal file={file} toggleModal={toggleModal} />
      <Wrapper className={`FileZone ${classSpecial}`} {...getRootProps()}>
        <input {...getInputProps()} />
        {!isMobile ? (
          <>
            {isDragActive ? (
              <span>{t('ResourceDragFile')}</span>
            ) : (
              <div className="Placeholder">
                {t('QuestionDragFile')}
                <span className="FileZone__Button">{t('QuestionDragClick')}</span>
              </div>
            )}
          </>
        ) : (
          <div className="Placeholder">
            <span className="FileZone__Button">{t('ResourceUpload')}</span>
          </div>
        )}
      </Wrapper>
    </>
  )
}
