import styled from 'styled-components/macro'

import { UIVars } from 'app/Components/UI'

export const Style = styled.div`
  margin-bottom: 30px;

  button {
    min-width: 100px;
  }

  &.btn-omitted {
    button[disabled] .button__container {
      background: ${UIVars.setColors.info};
      color: ${UIVars.setColors.primary};
    }
  }
`
