import { Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import { UIVars } from 'app/Components/UI'
import { Icon } from 'app/Components/UI/Icons'
import { IBase } from '.'

export const Stars = ({ value, onChange, limit = 3, sending }: IBase) => {
  const numberStars = [...Array(limit)].map((x, key) => key + 1)
  const { formState } = useFormContext()

  const isSelectArrastre = (selected: string | undefined, index: number) => {
    if (selected && index < Number(selected)) {
      return 'selected'
    }
    return ''
  }

  const colorHover = UIVars.setColors.darkGrayishBlue
  const colorSelected = UIVars.setColors.completed
  const isDisabled = formState.isSubmitting
  return (
    <RadioGroup onChange={(e) => !sending && onChange(e)} value={value}>
      <Stack
        direction="row"
        sx={{
          width: 'max-content',
          // todos cambian de color al hover
          ':hover > label span': {
            g: {
              fill: colorHover,
              stroke: colorHover,
            },
            path: {
              stroke: colorHover,
            },
          },
          label: {
            cursor: 'pointer',
            m: 0,
            marginInlineStart: '0!important',
            ':hover': {
              span: {
                g: {
                  fill: colorHover,
                  stroke: colorHover,
                },
                path: {
                  stroke: colorHover,
                },
              },
              // si hago hover sobre uno, los que vienen se quedan sin efecto
              ' ~ label span g': {
                fill: 'none',
              },
            },
          },
          '.inputRadio': {
            display: 'none',
          },
          'span[data-checked], .selected ~ span': {
            g: {
              fill: colorSelected,
              stroke: colorSelected,
            },
            path: {
              stroke: colorSelected,
            },
          },
        }}
      >
        {numberStars.map((option) => (
          <Radio
            className={`inputRadio ${isSelectArrastre(value, option)}`}
            key={option}
            isDisabled={isDisabled || sending}
            value={`${option}`}
          >
            <Icon viewBox={40} width={40} name={'bigStar'} />
          </Radio>
        ))}
      </Stack>
    </RadioGroup>
  )
}
