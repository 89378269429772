import { useSelector } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'
import { Box } from '@chakra-ui/react'

import * as Redux from 'app/Redux/types'
import './style.scss'

export const LegalText = () => {
  type Store = Redux.Store

  const {
    courses: { getCourseInfo: courseInfo },
  } = useSelector((state: Store) => state)

  const text = courseInfo.Course!.legalText

  if (!text || text === '') {
    return <></>
  }
  return <Box className="legalText">{ReactHtmlParser(text)}</Box>
}
