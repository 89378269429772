// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { UIVars } from 'app/Components/UI'
import { IconProps } from './types'
import getPath from './getPath'

const getViewBox = (viewBox: number, width: number, height: number) => {
  return viewBox ? `0 0 ${viewBox} ${viewBox}` : `0 0 ${width} ${height || width}`
}

/**
 * Este componente se encarga de dibujar todos los íconos svg pequeños de la app.
 * Los íconos más elaborados, tienen un archivo exclusivo para cada uno, por ejemplo
 * los de Ranking.
 */
const Icon = ({
  name,
  style = {},
  fill = 'none',
  stroke = '',
  className = '',
  width = 16,
  height,
  onClick,
  viewBox,
  id,
}: IconProps) => {
  if (!name) return <></>
  // @ts-ignore
  if (UIVars.setColors[fill]) {
    // @ts-ignore
    fill = UIVars.setColors[fill]
  }

  return (
    <StyledSVG
      aria-hidden
      id={id}
      role="Icon"
      data-testid={`Icon-${name}`}
      onClick={onClick}
      width={width}
      style={style}
      height={height}
      viewBox={getViewBox(viewBox, width, height!)}
      className={`Svg__Icon ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {getPath(name, { fill, stroke, width, height })}
    </StyledSVG>
  )
}

// @Export Styled Component
const StyledSVG = styled.svg`
  flex-shrink: 0;
  margin-right: ${({ className }) =>
    className && className.split(' ').includes('arrow-icon') ? 0 : '10px'};
  height: ${({ height, width }) => (height ? `${height}px` : `${width}px`)};
  width: ${({ width }) => (width ? `${width}px` : '16px')};
`

// @PropTypes
Icon.propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  viewBox: PropTypes.number,
  onClick: PropTypes.func,
}

export { Icon }
