export const SurveyCheck = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" data-testid="SurveyCheck">
      <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
      <path
        d="M40 14H22C21.4477 14 21 14.4477 21 15V17C21 17.5523 21.4477 18 22 18H40C40.5523 18 41 17.5523 41 17V15C41 14.4477 40.5523 14 40 14Z"
        fill="#1EBDAF"
      />
      <path
        d="M28 22H22C21.4477 22 21 22.4477 21 23V25C21 25.5523 21.4477 26 22 26H28C28.5523 26 29 25.5523 29 25V23C29 22.4477 28.5523 22 28 22Z"
        fill="#FFBC27"
      />
      <path
        d="M38 30H22C21.4477 30 21 30.4477 21 31V33C21 33.5523 21.4477 34 22 34H38C38.5523 34 39 33.5523 39 33V31C39 30.4477 38.5523 30 38 30Z"
        fill="#0189FF"
      />
      <path
        d="M14 6H12C10.8954 6 10 6.89543 10 8V21L10 36.5375C10 37.8975 11.0636 39 12.3757 39H33C34.1046 39 35 38.1046 35 37V22.5V8.46252C35 7.10251 33.9363 6 32.6243 6H31"
        stroke="#08044F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 26C17.1046 26 18 25.1046 18 24C18 22.8954 17.1046 22 16 22C14.8954 22 14 22.8954 14 24C14 25.1046 14.8954 26 16 26Z"
        stroke="#08044F"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 18C17.1046 18 18 17.1046 18 16C18 14.8954 17.1046 14 16 14C14.8954 14 14 14.8954 14 16C14 17.1046 14.8954 18 16 18Z"
        stroke="#08044F"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 34C17.1046 34 18 33.1046 18 32C18 30.8954 17.1046 30 16 30C14.8954 30 14 30.8954 14 32C14 33.1046 14.8954 34 16 34Z"
        stroke="#08044F"
        strokeWidth="2"
      />
      <rect x="16" y="1" width="13" height="5" rx="2" fill="#FF554D" />
      <rect x="17" y="2" width="11" height="7" rx="1" stroke="#08044F" strokeWidth="2" />
      <circle cx="35" cy="35" r="8" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 35C27 30.6 30.6 27 35 27C39.4 27 43 30.6 43 35C43 39.4 39.4 43 35 43C30.6 43 27 39.4 27 35ZM30.6 35L34 38.4L39.4 33L38 31.6L34 35.6L32 33.6L30.6 35Z"
        fill="#28A745"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45 35C45 40.5228 40.5228 45 35 45C29.4772 45 25 40.5228 25 35C25 29.4772 29.4772 25 35 25C40.5228 25 45 29.4772 45 35ZM35 43C39.4183 43 43 39.4183 43 35C43 30.5817 39.4183 27 35 27C30.5817 27 27 30.5817 27 35C27 39.4183 30.5817 43 35 43Z"
        fill="white"
      />
    </svg>
  )
}
