import { useSelector } from 'react-redux'

import * as Types from '../../types'
import { Stars } from './Stars'
import { Textarea } from './Textarea'
import { Store } from '../../../../../Courses/types'

export interface IBase {
  name: string
  onChange: (e: string) => void
  value?: string
  limit?: number
  sending?: boolean
}

export interface IProps extends IBase {
  type: Types.Question
}
export const TypesQuestion = (props: IProps) => {
  const {
    cev: { feedback },
  } = useSelector((store: Store) => store)

  const { type, ...base } = props
  base.sending = feedback.sending || false
  switch (type) {
    case 'star':
      return <Stars {...base} />
    case 'text':
      return <Textarea {...base} />

    default:
      return <>Tipo invalido</>
  }
}
