export const Absent = () => {
  return (
    <g>
      <circle cx="6" cy="6" r="6" fill="#CC0000" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.87928 2.46447L2.46507 3.87868L4.58639 6L2.46507 8.12132L3.87928 9.53553L6.0006 7.41421L8.12192 9.53553L9.53614 8.12132L7.41482 6L9.53614 3.87868L8.12192 2.46447L6.0006 4.58579L3.87928 2.46447Z"
        fill="white"
      />
    </g>
  )
}
