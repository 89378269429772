/* Importamos las imagenes por defecto */
import bgDefaultCoursesDay from './bgDefaultCoursesDay.webp'
import bgDefaultCoursesTabletDay from './bgDefaultCoursesTabletDay.webp'
import bgDefaultCoursesMobileDay from './bgDefaultCoursesMobileDay.webp'
import bgDefaultCoursesNight from './bgDefaultCoursesNight.webp'
import bgDefaultCoursesTabletNight from './bgDefaultCoursesTabletNight.webp'
import bgDefaultCoursesMobileNight from './bgDefaultCoursesMobileNight.webp'
import bgDefaultLogin from './bgDefaultLogin.webp'

import { BrandingImages, Images } from './types'

/** Imagenes de día */
const day: Images = {
  desktop: bgDefaultCoursesDay,
  tablet: bgDefaultCoursesTabletDay,
  mobile: bgDefaultCoursesMobileDay,
}

/** Imagenes de noche */
const night: Images = {
  desktop: bgDefaultCoursesNight,
  tablet: bgDefaultCoursesTabletNight,
  mobile: bgDefaultCoursesMobileNight,
}

/**
 * Estructura del branding por defecto.
 */
const branding: BrandingImages = {
  day,
  night,
  login: bgDefaultLogin,
}

export default branding
