import { Box, Text } from '@chakra-ui/react'
import ReactHtmlParser from 'react-html-parser'
import { vars } from '@eclass/ui-kit'

import { SlideImage } from '../../types'

interface props {
  slide: SlideImage
}

/**
 * Muestra información relacionada con el slide activo,
 * si no tiene textos por mostrar no se retorna nada
 * @param {SlideImage} slide - configuración
 * @example <Information slide={slides[active]} />
 */
export const Information = ({ slide }: props) => {
  const color = 'var(--chakra-colors-neutral-white)'

  if (slide.title === '' && slide.text === '') {
    return null
  }

  return (
    <Box
      data-testid="slideshowInformation"
      padding="1.5rem 1.875rem"
      bg={vars('colors-neutral-darkCharcoal')}
      width="100%"
      color={color}
      sx={{
        p: {
          color,
          fontsize: '1rem',
          lineHeight: '1.5625rem',
        },
        'p:last-child': {
          margin: '0',
        },
        '@media screen and (min-width: 768px)': {
          mt: '0',
          position: 'absolute',
          width: '23.6875rem', // 379px
          bottom: '0',
          right: '0',
        },
      }}
    >
      {slide.title === '' ? (
        ''
      ) : (
        <Text
          as="h5"
          fontSize="1.25rem"
          fontWeight="700"
          lineHeight="1.4375rem"
          mb=".6875rem"
          data-testid="slideshowInformationTitle"
        >
          {slide.title}
        </Text>
      )}
      {ReactHtmlParser(slide.text)}
    </Box>
  )
}
