import { useTranslation } from 'react-i18next'
import { Icon, SpeechRecognition } from 'app/Components/UI/Icons'
import * as Style from './style'
import * as Type from './types.d'

export const Feedback = ({ transcript, state, redo }: Type.Feedback) => {
  const showRedo = ['SUCCESS', 'ERROR', 'NOT_UNDERSTAND'].includes(state)
  const success = state === 'SUCCESS'
  const notUnderstand = state === 'NOT_UNDERSTAND'
  const iconName = success ? 'circularCheck' : notUnderstand ? 'info' : 'circularError'
  const { t } = useTranslation()

  return (
    <Style.FeedbackBox className={state}>
      <Style.TranscriptBox>
        <span>
          <Icon fill="#e99e21" name={iconName} />
        </span>
        <Style.Transcript>
          {notUnderstand && t('ResourceVoiceNotUnderstand')}
          {transcript}
        </Style.Transcript>
      </Style.TranscriptBox>
      {showRedo && (
        <Style.Redo onClick={redo}>
          <SpeechRecognition.Redo />
        </Style.Redo>
      )}
    </Style.FeedbackBox>
  )
}
