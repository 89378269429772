import { UIVars } from 'app/Components/UI'

/**
 * En base a un Objeto prepara los font-size base
 */
const fontSize = (obj: object, fontSizeBase: number, className = false) =>
  Object.keys(obj)
    .reduce((acc, val) => {
      const hadClassName = className ? '&.' : ''
      const attribute = hadClassName + val
      // @ts-ignore
      acc.push(`${attribute} { font-size: ${obj[val] / fontSizeBase}rem;}`)
      return acc
    }, [])
    .join(' \n')

const Text = `
  body, html {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
  }

  h1, h2, h3, h4, h5, h6, p {
    font-weight: 400;
    &.active{
      color: ${UIVars.setColors.deepSkyBlue}
    }
    &.disabled{
      color: ${UIVars.setColors.brownGreyTwo}
    }
  }

  ${fontSize(UIVars.hSize.normal, UIVars.fontSize)}
  ${fontSize(UIVars.hSize.normal, UIVars.fontSize, true)}

  .article {
    ${fontSize(UIVars.articleH.normal, UIVars.fontSize)}
  }

  .tab{
    &.active{
      color: ${UIVars.setColors.deepSkyBlue}
    }
    &.disabled{
      color: ${UIVars.setColors.brownGreyTwo}
    }
  }

  hr {
    border-top-color: ${UIVars.setColors.veryLightPink};
    margin: 40px 0;
  }

  p {
    font-size: ${UIVars.pSize.medium / UIVars.fontSize}rem;
    ${fontSize(UIVars.pSize, UIVars.fontSize, true)}
  }

  a {
    color: ${UIVars.setColors.blackTwo};
    ${fontSize(UIVars.pSize, UIVars.fontSize, true)}

    &:hover {
      color: ${UIVars.setColors.primary};
      text-decoration: none;
    }
  }

  .alert{
    ${fontSize(UIVars.alertFontSize, UIVars.fontSize, true)}
    &.warning{
      background: #856404;
    }
    &.info{
      background: ${UIVars.setColors.deepSeaBlue};
    }
  }


  @media screen and (max-width: 480px) {
    ${fontSize(UIVars.hSize.mobile, UIVars.fontSizeMobile)}
    .article {
      ${fontSize(UIVars.articleH.mobile, UIVars.fontSizeMobile)}
    }
  }
`

export default Text
