import * as React from 'react'
import { useListCountries, useSetSelect } from '../Common'
import { Types } from '../types'

export const useSetCountry = (name: string, toCall: boolean = true) => {
  const { countries } = useListCountries(toCall)

  const [countriesList, setCountriesList] = React.useState<Types.select[]>([])
  const { setOption } = useSetSelect(countriesList, name)

  React.useEffect(() => {
    if (countries.length > 0 && countriesList.length === 0) {
      setCountriesList(countries)
      setOption(countries)
    }
  }, [countries, countriesList, setOption])

  return { countriesList }
}
