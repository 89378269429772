import styled, { css } from 'styled-components/macro'

import { layout } from '../Common'

export const Wrapper = styled.div`
  margin-top: -20px;

  ${layout(
    css`
      .profile-paragraphTimeZone {
        margin-bottom: 0;
        font-size: 0.875rem;
        grid-area: paragraphTimeZone;
      }

      .profile-timeZone {
        grid-area: timeZone;
      }

      grid-template-areas:
        'paragraphTimeZone paragraphTimeZone'
        'timeZone timeZone';

      &.profile {
        margin-top: 0;
        grid-template-areas:
          'paragraphTimeZone paragraphTimeZone'
          'timeZone timeZone';

        .profile-paragraphTimeZone p {
          font-size: 1rem;
        }
      }
    `
  )}

  @media screen and (max-width: 425px) {
    margin-top: 0;
  }
`
