import React, { useState, useEffect, useCallback } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { Progress } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { BtnTertiary, NewTooltip, vars } from '@eclass/ui-kit'
import { Box, Center, Divider, Flex, useDisclosure, useMediaQuery } from '@chakra-ui/react'

import useInterval from './useInterval'
import { Types } from '../types'
import { useTabActive } from './useTabActive'
import { usePrevious } from './usePrevious'
import { useNewDate } from 'app/Services/useNewDate'
import { TimeInfoModal } from './TimeInfoModal'
import { useEvaluationCheckLevel } from 'app/Views/LevelTest/Test/useEvaluationCheckLevel'
import { Icon } from '../../Icons'
import { useFormatDates } from './useFormatDates'

const TO_SECOND = 60
const INTERVAL_SECOND = 1000
const COMPLETE_PERCENTAGE = 100
const AVERAGE_PERCENTAGE = 50
const LAST_PERCENTAGE = 8

/**
 * Maneja el tiempo restante de una evaluación, cuando se recarga trae el tiempo restante de la api.
 *
 * @example <TimeProgress config={config} testActions={testActions} />
 */
const TimeProgress = ({ config, testActions, currentLevel }: Types.TimeProgressProps) => {
  const { t } = useTranslation()

  const [isMobile] = useMediaQuery('(max-width: 611px)')
  const { isOpen: isOpenTooltip, onToggle, onClose } = useDisclosure()

  const { dateTooltip, dateStart, dateEnd } = useFormatDates({
    start: config.startDate,
    end: config.endDate,
  })
  /**
   * Esto valida si estoy en un test de nivel con alumno anónimo
   */
  const isLevelTest = window.location.href.includes('test-level')
  const { loading, EvaluationCheckLevel } = useEvaluationCheckLevel(config.id, currentLevel)
  const [percentage, setPercentage] = useState(0)
  const [time, setTime] = useState(0)
  const history = useHistory()

  const isTabActive = useTabActive()
  const previousTime = usePrevious(isTabActive)
  const [isOpen, setIsOpen] = React.useState(false)

  const { getDate } = useNewDate()

  /**
   * Asigno valores iniciales solo si no esta completo el tiempo
   */
  useEffect(() => {
    if (!config.completed) {
      // Redondeo valores y paso de minutos a segundos
      const initialPercentage = Math.round(config.timeRemaining)
      const initialTime = Math.round(config.hasTime * TO_SECOND)
      setPercentage(initialPercentage)
      // obtengo el valor en tiempo de acuerdo a su porcentaje
      setTime(Math.round((initialTime * initialPercentage) / COMPLETE_PERCENTAGE))
    }
  }, [config])

  const checkLevel = useCallback(async () => {
    const { data } = await EvaluationCheckLevel()
    if (data?.EvaluationCheckLevel.status.success) {
      if (data.EvaluationCheckLevel.isCompleted && !data.EvaluationCheckLevel.nextLevelId) {
        // @ts-ignore
        history.push(data.EvaluationCheckLevel.feedbackUrl)
      }
    }
  }, [loading, EvaluationCheckLevel])

  useEffect(() => {
    if (time < 0) {
      testActions({ type: 'FINALIZE' })
    }
  }, [time, testActions])

  useEffect(() => {
    if (isLevelTest && time === 300) {
      setIsOpen(true)
    }
    if (isLevelTest && time < 0) {
      checkLevel()
    }
  }, [time])

  function getMinutesBetweenDates(startDate, endDate) {
    const diff = endDate.getTime() - startDate.getTime()

    return diff / 60000
  }

  useEffect(() => {
    if (isTabActive && !previousTime) {
      getDate((newDate) => {
        /** Obtengo los minutos restantes comparando la hora actual con la hora fin */
        const refreshMinutes = getMinutesBetweenDates(new Date(newDate), new Date(config.endDate))
        /** Paso los minutos a segundos */
        const initialTime = Math.round(refreshMinutes * TO_SECOND)
        /** Obtengo el porcentaje en base al nuevo tiempo */
        const percentageRemaining =
          (initialTime * COMPLETE_PERCENTAGE) / (Math.round(config.hasTime) * TO_SECOND)
        setPercentage(percentageRemaining)
        setTime(initialTime)
      })
    }
  }, [isTabActive])

  /**
   * Modifico estados cada un segundo
   */
  useInterval(() => {
    const newTime = time - 1
    const percentageRemaining =
      (newTime * COMPLETE_PERCENTAGE) / (Math.round(config.hasTime) * TO_SECOND)
    setTime(newTime)
    // obtengo el porcentaje en relación a los minutos que lleva
    setPercentage(percentageRemaining)
  }, INTERVAL_SECOND)

  if (!config.hasTime || config.completed) {
    return null
  }

  /**
   * Textos de la cuenta regresiva
   */
  let timeRemaining = time
  let typeOfTime = t('Sec')
  if (time >= TO_SECOND) {
    timeRemaining = Math.round(time / TO_SECOND)
    typeOfTime = 'min'
  }

  /** Parche al problema del que el tooltip queda oculto cuando el nav esta stiky */
  const onMouseEnter = () => {
    // le doy un tiempo para que se agregue en el dom antes de modificarlo
    setTimeout(() => {
      const timeTooltip = document.querySelector('.timeTooltip') as HTMLDivElement
      if (timeTooltip) {
        const parentTimeTooltip = timeTooltip.parentElement as HTMLDivElement
        parentTimeTooltip.style.zIndex = '999'
      }
    }, 1)
  }
  /** Para que la corrección al tooltip funcione, debe ser llamada cada vez que se presione el botón, por lo que  se agrupan ambas funciones en una sola */
  const onClickButton = () => {
    onMouseEnter() // Llama a la función onMouseEnter
    onToggle() // Llama a la función onToggle
  }

  return (
    <>
      {config.hasTime > 0 ? (
        <Flex
          height="38px"
          sx={{
            '@media screen and (min-width: 612px)': {
              left: '50%',
              position: 'absolute',
              transform: 'translateX(-50%)',
            },
          }}
        >
          <Box
            display="flex"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              '.progress': {
                width: isMobile ? '56px' : '100px',
                height: '6px',
                transform: 'rotate(180deg)',
              },
              '.time': {
                fontSize: '16px',
                fontWeight: '700',
                mr: '4px',
              },
              '.typeoftime': { fontSize: '12px', fontWeight: '700' },
              '.progress .progress-bar': {
                bg: `${
                  percentage <= LAST_PERCENTAGE
                    ? vars('colors-alert-red')
                    : percentage <= AVERAGE_PERCENTAGE
                    ? vars('colors-alert-orangeyYelow')
                    : ''
                }`,
              },
            }}
          >
            <Center mb="4px">
              <span className="time">{timeRemaining}</span>
              <span className="typeoftime">{typeOfTime}</span>
            </Center>
            <Progress value={percentage} />
          </Box>
          <Divider
            orientation="vertical"
            height="28px"
            m="0 12px"
            display="flex"
            alignSelf="center"
          />
          {isMobile ? (
            <Box
              display="flex"
              alignItems="center"
              sx={{
                button: { pl: '0', pr: '0' },
                '.chakra-button__icon': { marginInlineEnd: '0' },
                '.chakra-button__icon svg': { mr: '8px' },
              }}
            >
              <NewTooltip
                label={ReactHtmlParser(dateTooltip)}
                placement="bottom"
                isOpen={isOpenTooltip}
                className="timeTooltip"
              >
                <BtnTertiary
                  activeWhenPress
                  onMouseLeave={onClose}
                  onClick={onClickButton}
                  withoutColor
                  iconCustom={<Icon name="stopWatch" fill={vars('colors-main-blueGrey')} />}
                >
                  {t('ResourceDeadline')}
                </BtnTertiary>
              </NewTooltip>
            </Box>
          ) : (
            <Box fontSize="14px" lineHeight="19px" fontWeight="700">
              <Flex color={vars('colors-neutral-spanishGrey')} gap="4px">
                {t('ResourceStart')}:<Box fontWeight="400">{dateStart}</Box>
              </Flex>
              <Flex gap="4px">
                {t('ResourceEnd')}:<Box fontWeight="400">{dateEnd}</Box>
              </Flex>
            </Box>
          )}
        </Flex>
      ) : null}
      <TimeInfoModal setIsOpen={setIsOpen} isOpen={isOpen} />
    </>
  )
}

export default TimeProgress
