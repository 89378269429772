// @External Dependencies
import React, { useState, useEffect } from 'react'

import { ProgressProps } from './types'
import { StyleProgress } from './Style'

const rotationMax = 180
const percentageMax = 100
const percentageMin = 0
const percentageMaxSide: number = percentageMax / 2

// @Component
export const Progress = (props: ProgressProps) => {
  const { progress, size, strokeWidth, color } = props
  const [rotation, setRotation] = useState({
    left: 0,
    right: 0,
  })

  // convierto el progreso(%) a rotación(deg)
  useEffect(() => {
    // si pongo un porcentaje mayor al maximo. lleno los dos lados
    if (progress > percentageMax) {
      setRotation({
        left: rotationMax,
        right: rotationMax,
      })
    }
    // si pongo un porcentaje mayor al maximo de un lado (50%). lleno un lado y el calculo lo que pinto del otro
    // ejemplo progress = 75 || 25 -- 50
    else if (progress >= percentageMaxSide) {
      setRotation({
        left: ((progress - percentageMaxSide) * rotationMax) / percentageMaxSide,
        right: rotationMax,
      })
    }
    // si es mayor al porcentaje minimo y menor al maximo del porcentaje de un lado,
    // ejemplo progress = 35 || 0 -- 35
    else if (percentageMin < progress && progress < percentageMaxSide) {
      setRotation({
        left: 0,
        right: (progress * rotationMax) / percentageMaxSide,
      })
    }
  }, [progress])

  if (rotation.left === 0 && rotation.right === 0) {
    return null
  }

  return (
    <>
      {Object.entries(rotation).map(([key, value]) => {
        if (value > 0) {
          return (
            <StyleProgress
              key={key}
              color={color}
              size={size}
              strokeWidth={strokeWidth}
              {...rotation}
              className={`Cpb__${key}`}
            >
              <div className="Cpb__wrap">
                <div className="Cpb__circle" />
              </div>
            </StyleProgress>
          )
        }
        return null
      })}
    </>
  )
}

export default Progress
