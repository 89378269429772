import React from 'react'
import { useTranslation } from 'react-i18next'

import { Select, Input } from '../Common'
import { useDocuments } from './useDocuments'

/**
 * Campos con el tipo de documento
 */
export const DocumentTypes = () => {
  const { t } = useTranslation()
  const { documents, isBloqued, typeDocument, handleTypeDocument } = useDocuments()

  return (
    <>
      {/* TIPO DE DOCUMENTO */}
      <Select
        disabled={isBloqued}
        name="documentNumberId"
        label={t('ProfileDocuType')}
        options={documents}
        onChange={handleTypeDocument}
      />

      {/* NÚMERO DE DOCUMENTO */}
      <Input
        disabled={isBloqued}
        name="documentNumber"
        placeholder={typeDocument.placeholder}
        type={typeDocument.type}
        label={typeDocument.label}
      />
    </>
  )
}
