import { EvaluationCreatePayload } from '@eclass/api'
import { testsStates } from '../../testsStates'

/**
 * Cambia el estado de la evaluación para el estado 7 = utilizado para forzar la repeticion.
 */
const repeat = (test: EvaluationCreatePayload) => {
  test.Evaluation!.config.state = testsStates.REPEAT_NOW

  return { ...test }
}

export default repeat
