import React from 'react'

import { UIVars } from 'app/Components/UI'
import { Icon } from 'app/Components/UI/Icons'

type props = {
  type?: string
  wrong?: boolean
}

type style = {
  backgroundColor: string
  borderRadius: string
  padding: string
}

type icon = {
  name: string
  fill: string
  style: style | null
  size: number
}

export const COLOR = {
  success: UIVars.setColors.checkGreen,
  error: UIVars.setColors.checkError,
}

export const IconStatus = ({ type = 'radio', wrong = false }: props) => {
  const color = wrong ? COLOR.error : COLOR.success

  let configIcon: icon = {
    name: 'circularCheck',
    fill: color,
    style: null,
    size: 18,
  }

  if (type === 'checkbox') {
    configIcon = {
      name: 'done',
      fill: '#fff',
      style: {
        backgroundColor: color,
        borderRadius: '3px',
        padding: '2px',
      },
      size: 16,
    }
  }

  return (
    <Icon
      name={configIcon.name}
      fill={configIcon.fill}
      style={configIcon.style}
      height={configIcon.size}
      width={configIcon.size}
    />
  )
}
