import styled, { createGlobalStyle } from 'styled-components/macro'
import { UIVars } from 'app/Components/UI'

export const Style = {
  ModalWrapper: createGlobalStyle`
  .modal{
    .modal-dialog{
  .modal-content{
    width: 590px;
  }
  .modal-footer{
        background-color: ${UIVars.setColors.whiteThree};
        border-top: ${UIVars.borderSolid};
        padding: 0;
  }
    }
    @media screen and (max-width: 768px) {
    padding: 20px 20px;
    height: auto;
      .modal-dialog{
        .modal-content{
          border-radius: 10px !important;
        }
      }
      }
  }

  `,
  AlertContent: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      height: 48px;
      width: 48px;
      margin-bottom: 20px;
    }
  `,
  AlertTitle: styled.h3`
    display: flex;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
    width: fit-content;
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  `,
  AlertMainText: styled.p`
    font-size: 16px;
    line-height: 28px;
    width: 529px;
    padding: 0 30px;
    @media screen and (max-width: 768px) {
      padding: 0;
      width: auto;
    }
  `,
  AlertFooter: styled.div`
    color: ${UIVars.setColors.primary};
    margin: 20px auto;
    font-weight: 500;
    font: 16px/18.75px ${UIVars.fontRoboto};
    cursor: pointer;
  `,
}
