// @External Dependencies
import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

// @Local Dependencies
import Header from '../Header'
import Footer from '../Footer'
import { AppFrameProps } from './types'
import { ModalTerms } from '../TermsAndCondModal'
import { NotificationLeaderboard } from '../NotificationLeaderboard'
import { ProviderTimeZone } from './ProviderTimeZone'
import { useZendesk } from '../Zendesk'
import { BarImpersonate } from '../Impersonate'

/**
 * Componente base para las rutas privadas.
 *
 * @example
 * <AppFrame config={domainConfig}>
 *    <Component {...props} />
 * </AppFrame>
 */
const AppFrame: FC<AppFrameProps> = ({ config, children }) => {
  const { pathname } = useLocation()
  const [showFooter, setShowFooter] = useState(true)

  useZendesk()

  useEffect(() => {
    /**
     * Validamos que se pueda mostrar o no el <Footer />
     *
     * - paths: resource, confirmation, test, exercise
     * - config.appMobile: true
     * - toggleWelcome: true
     */
    const pathWithoutFooter = [
      'resource',
      'test',
      'exercise',
      'confirmation',
      'prerequisites',
      'login_sence',
      'work',
    ]
    const hidePath = pathWithoutFooter.some((path) => pathname.includes(path))
    if (!config.appMobile && !hidePath) {
      setShowFooter(true)
    } else {
      setShowFooter(false)
    }
  }, [pathname, config.appMobile])

  return (
    <section id="MainContainer">
      <BarImpersonate />
      <ModalTerms />
      <ProviderTimeZone>
        {!config.appMobile && <Header config={config} />}
        <div id="ViewContainer">{React.cloneElement(children, { config })}</div>
      </ProviderTimeZone>
      <NotificationLeaderboard />
      {showFooter && <Footer />}
    </section>
  )
}

export default AppFrame
