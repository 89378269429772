import { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Types } from './types'

const MICROLEARNING_NAVIGATION_ID = 5

export const useFixed = () => {
  const {
    courses: { getCourseInfo: courseInfo },
  } = useSelector((state: Types.Store) => state)

  const isMicroLearning = courseInfo.Course?.navigationId === MICROLEARNING_NAVIGATION_ID

  const [isFixed, setIsFixed] = useState<Types.isFixed>('')
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const [isBreadCrumbFixed, setIsBreadCrumbFixed] = useState(false)

  const handleScroll = useCallback(() => {
    const windowScrollTop =
      window.pageYOffset || document.documentElement?.scrollTop || document.body?.scrollTop || 0

    const MainHeader = document.getElementById('MainHeader')
    const ResourceNav = document.getElementsByClassName('ResourceNav')
    const ResourceNavNext = ResourceNav[0]?.nextElementSibling as HTMLElement
    const BreadCrumb = document.getElementById('BreadCrumb')
    const ViewContainer = document.getElementById('ViewContainer')
    const HeaderBranding = document.getElementsByClassName('HeaderBranding')
    const ResumeContainer = document.getElementsByClassName('EndOfCourse')
    const WelcomeContainer = document.getElementsByClassName('activeWelcome')

    const isMicroLearningContainers = isMicroLearning
      ? ResumeContainer.length
        ? (ResumeContainer[0] as HTMLElement).clientHeight +
          (HeaderBranding[0] as HTMLElement).clientHeight
        : HeaderBranding.length
        ? (HeaderBranding[0] as HTMLElement).clientHeight
        : 0
      : 0

    if (
      MainHeader &&
      ViewContainer &&
      ResourceNav &&
      BreadCrumb &&
      ResourceNav.length > 0 &&
      ResourceNavNext
    ) {
      const resourceNavHeight = (ResourceNav[0] as HTMLElement).clientHeight

      let breadcrumbHeight = BreadCrumb.clientHeight
      if (document.getElementsByClassName('BreadCrumbFixed')?.[0]) {
        breadcrumbHeight = 0
      }

      if (
        windowScrollTop >=
        MainHeader.clientHeight + breadcrumbHeight + isMicroLearningContainers
      ) {
        if (isFixed !== 'isFixed') {
          ResourceNavNext.style.marginTop = `${resourceNavHeight}px`
          setIsFixed('isFixed')
        }
        if (isMicroLearning && WelcomeContainer && WelcomeContainer.length) {
          setIsFixed('')
        }
      } else {
        if (isMicroLearning && WelcomeContainer && WelcomeContainer.length) {
          setIsFixed('')
        }
        if (ResourceNavNext.style.marginTop !== '0px') {
          ResourceNavNext.style.marginTop = '0px'
          setIsFixed('')
        }
      }

      // Lógica para fijar el BreadCrumb y evitar el solapamiento
      if (windowScrollTop < lastScrollTop && windowScrollTop > MainHeader.clientHeight) {
        setIsBreadCrumbFixed(true)
      } else if (windowScrollTop <= MainHeader.clientHeight || windowScrollTop > lastScrollTop) {
        setIsBreadCrumbFixed(false)
      }

      setLastScrollTop(windowScrollTop)
    }
  }, [isFixed, lastScrollTop, isMicroLearning])

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll, isFixed])

  useEffect(() => {
    const breadCrumb = document.getElementById('BreadCrumb')
    if (breadCrumb) {
      const ResourceNav = document.getElementsByClassName('ResourceNav')
      if (ResourceNav.length > 0) {
        const resourceNavElement = ResourceNav[0] as HTMLElement
        if (isBreadCrumbFixed) {
          breadCrumb.classList.add('BreadCrumbFixed')
          resourceNavElement.style.top = `${breadCrumb.clientHeight - 1}px` // Ajuste para evitar solapamiento
        } else {
          breadCrumb.classList.remove('BreadCrumbFixed')
          resourceNavElement.style.top = '0' // Asegura que no haya margen cuando no esté fijo
        }
      }
    }
  }, [isBreadCrumbFixed])

  return { isFixed }
}
