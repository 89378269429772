// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { axiosError, getToken } from 'app/Services'

/**
 * Confirma la asistencia a la clase presencial online y obtiene la url de la clase
 *
 * @example
 * ```
 *   await resourceAttendPersonalMeeting(this.props.config)
 * ```
 */
const resourceAttendPersonalMeeting = (input: any) => {
  const requestId = 'resourceAttendPersonalMeeting'
  return async (dispatch: any) => {
    getToken()
    const query = `
    mutation ResourceAttend($ResourceAttendInput: ResourceAttendInput!) {
      ResourceAttend(input: $ResourceAttendInput) {
        url
        status {
          success
          name
          detail
          code
        }
      }
    }
    `
    const variables = {
      ResourceAttendInput: input,
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response: any) => {
        return response.data.data.ResourceAttend
      })
      .catch((err: any) => {
        axiosError(err, requestId, dispatch)
      })
  }
}
export default resourceAttendPersonalMeeting
