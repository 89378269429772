import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import * as Apollo from 'app/Apollo'

export const Branding = ({ config }) => {
  const dispatch = useDispatch()

  const { data, loading } = Apollo.useQueryCampusBranding({
    variables: {
      CampusBrandingInput: {
        campusId: config.campusId,
        programId: config.programId,
      },
    },
  })

  const setBranding = async () => {
    if (!data || !data?.CampusBranding.status.success) {
      dispatch({
        type: 'SET_BRANDING',
        payload: { result: {}, config },
      })
    } else {
      dispatch({
        type: 'SET_BRANDING',
        payload: {
          result: data.CampusBranding,
          config,
        },
      })
    }
  }

  useEffect(() => {
    if (data && !loading) {
      setBranding()
    }
  }, [data, loading])

  return <></>
}
