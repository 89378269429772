import styled from 'styled-components/macro'
import { UIVars, Dot } from 'app/Components/UI'

export const QuestionsResourceWrapper = styled.div`
  background: ${UIVars.setColors.whiteTwo};
  border-radius: 10px;
  color: ${UIVars.setColors.brownThree};
  font-size: 0.75rem;
  padding: 10px 16px;

  a {
    color: ${UIVars.setColors.primary};
    font-size: 0.75rem;
    font-weight: 500;
    text-decoration-line: underline;
  }
`

export const MainSection = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    color: ${UIVars.setColors.black};
    font-size: 1rem;
    font-weight: 700;
  }

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
`

export const MyQuestions = styled.div`
  display: flex;
`

export const WrapperDot = styled(Dot)`
  margin-left: 4px;
  margin-top: 5px;
`

export const OthersQuestions = styled.div`
  line-height: normal;

  hr {
    border-top: 1px solid ${UIVars.setColors.backgroundGrey};
    margin: 10px 0;
  }
`
