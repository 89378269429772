import { useEffect, useState } from 'react'
import { BtnLink, TinyAlert } from '@eclass/ui-kit'

import { Props } from '../types'
import { setCdnUrl } from '../../utils'
import './style.scss'
import { useSentryError } from './useSentryError'

/**
 * ## Función General
 * Permite incrustar un audio con el Tag <audio></audio> interpretando la configuración de la base de datos.
 * @example
 *    <Audio config={options} id={3} />
 */
export const Audio = ({ config, id }: Props.Audio) => {
  const denegateAccess = config.deny_download === 1
  const [error, setError] = useState(false)
  const [retryCount, setRetryCount] = useState(0)
  const url = setCdnUrl(config.audio)

  const sentry = useSentryError(url)

  useEffect(() => {
    const elementHtml = document.getElementById(`audioModule${id}`)

    if (elementHtml && denegateAccess) {
      elementHtml.setAttribute('controlsList', 'nodownload')
    }
  }, [denegateAccess, id])

  const handleAudioError = (event) => {
    setRetryCount(retryCount + 1)
    setError(true)

    if (retryCount === 3) {
      const target = event.target as HTMLAudioElement
      sentry.send(target?.error)
    }
  }

  const handleRetry = () => {
    setError(false)
    const audioElement = document.getElementById(`audioModule${id}`) as HTMLAudioElement
    if (audioElement) {
      audioElement.pause()
      audioElement.load()
      audioElement.play()
    }
  }

  return (
    <section className="Article__audio" key={id}>
      <audio
        controls
        id={`audioModule${id}`}
        data-testid={`audioModule${id}`}
        onError={handleAudioError}
        onContextMenu={denegateAccess ? (e) => e.preventDefault() : undefined}
      >
        <source src={url} />
        Tu navegador no soporta el elemento de audio.
      </audio>
      {error && (
        <div className="Article__audio-error">
          <TinyAlert status="error" text="Hubo problemas en cargar el audio." />
          <BtnLink as="button" role="button" onClick={handleRetry}>
            Intentalo nuevamente
          </BtnLink>
        </div>
      )}
    </section>
  )
}
