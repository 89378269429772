const initialState = {
  getUnitList: {},
}

/**
 * Export reducer de units.
 *
 * @param {Object} state - Estado inicial
 * @param {string} action - Acción a procesar
 */
export const units = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case 'SET_UNIT_LIST':
      return {
        ...state,
        getUnitList: payload,
      }
    case 'SET_CONNECT':
      return {
        ...state,
        pusherConnection: payload,
      }
    default:
      return state
  }
}
