import { useState, useRef } from 'react'
import { Box, Image, useMediaQuery } from '@chakra-ui/react'
import Slider from 'react-slick'
import { vars } from '@eclass/ui-kit'

import { Nav, Information } from './components'
import { Props } from '../types'
import { ViewPDF } from './ViewPDF'
import { useViewDownload } from 'app/Views/Resources/PDF'

/**
 * Componente que genera un galeria de imagenes con información adicional
 * @example
 *    <Slideshow config={options} id={7} />
 */
export const Slideshow = ({ config, id }: Props.Slideshow) => {
  const isViewDownload = useViewDownload()
  const refSlider = useRef<Slider | null>(null)
  const [isDesktop] = useMediaQuery('(min-width: 768px)')

  let slides: Props.Slideshow['config']['images'] = []
  if (config.images.length) {
    // ordeno lista de slide de acuerdo al orden establecido
    slides = config.images.sort(function (a, b) {
      if (a.orden > b.orden) {
        return 1
      }
      if (a.orden < b.orden) {
        return -1
      }
      return 0
    })
  }

  const [active, setActive] = useState(0)

  // marco index del slider activo
  const beforeChange = (current: number, next: number) => setActive(next)

  if (slides.length === 0) {
    return null
  }

  if (isViewDownload) {
    return <ViewPDF slides={slides} type={config.type} />
  }

  const vertical = config.type === 'vertical'
  const heightImg = vertical ? '34.0625rem' : '27.25rem'

  return (
    <Box
      as="section"
      data-testid="Slideshow"
      id={`Slideshow-${id}`}
      className={vertical ? '' : 'breakerContainer'}
    >
      <Box
        as="section"
        position="relative"
        overflow="hidden"
        maxW={vertical ? '45.5rem' : ''} // 728ox
        ml={vertical ? 'auto' : 0}
        mr={vertical ? 'auto' : 0}
        sx={{
          '.slick-arrow': {
            display: 'none !important',
          },
          '.slick-track': {
            bg: vars('colors-neutral-darkCharcoal'),
          },
          '.slick-slide': {
            bg: vars('colors-neutral-white'),
          },
          '.slick-slide img': {
            height: vertical ? '20.9375rem' : 'initial', // 335px
            m: '0 auto',
            maxWidth: vertical ? 'initial' : '62.5rem', // 1000px
            width: vertical ? 'initial' : '100%',
          },
          '@media screen and (min-width: 768px)': {
            height: heightImg,
            '.slick-slide img': {
              height: heightImg,
              width: 'auto',
              m: '0',
              maxWidth: '62.5rem',
            },
          },
        }}
      >
        <Slider
          adaptiveHeight={false}
          infinite
          swipeToSlide
          beforeChange={beforeChange}
          ref={(slider) => (refSlider.current = slider)}
        >
          {slides.map((slide, key) => (
            <Box key={key}>
              <Image src={`//cdn.eclass.com/${slide.image}`} alt={slide.title} />
              {!isDesktop && <Information slide={slide} />}
            </Box>
          ))}
        </Slider>
        {isDesktop && <Information slide={slides[active]} />}
      </Box>
      <Nav
        active={active}
        total={slides.length}
        next={() => refSlider.current?.slickNext()}
        prev={() => refSlider.current?.slickPrev()}
      />
    </Box>
  )
}
