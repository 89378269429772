import styled from 'styled-components/macro'
import { UIVars } from 'app/Components/UI'

type Props = {
  isVisible: boolean
}

export const FormWrapper = styled.div`
  margin-bottom: 30px;

  label {
    font-size: 16px;
    font-weight: bold;
  }

  button {
    margin-top: 30px;
  }
`

export const Editor = styled.div`
  margin-bottom: 20px;

  .input-box {
    .toggle-box {
      margin-top: 20px;
      svg {
        margin-left: 10px;
        margin-right: 0px;
      }
    }
  }

  .editor-class {
    font-size: 16px;
    &.tall {
      height: auto !important;
    }
  }
`

export const InputText = styled.div`
  margin-bottom: 20px;

  > div {
    height: auto;
  }
`

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;

  .brownText {
    margin-top: 20px;
    color: ${UIVars.setColors.brownGrey};
  }
`

export const RadioGroup = styled.div`
  display: flex;
  gap: 30px;
`

export const Radio = styled.div<Props>`
  display: flex;

  label {
    display: flex;
    align-items: baseline;
    margin-bottom: 0;
    font-weight: 400;

    span {
      color: ${(props) => (props.isVisible ? UIVars.setColors.black : UIVars.setColors.brownGrey)};
    }
  }

  input[type='radio'] {
    width: 20px;
    height: 20px;
    margin-right: 11px;
  }

  &.hideInput {
    label {
      align-items: center;
    }
    input {
      display: none;
    }
  }
`
