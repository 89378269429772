// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL, CDN_URL } from '../../../Config/constants'
import { axiosError, getToken } from '../../Services'

/**
 * @returns {Promise<import('@eclass/api').PublicImagePayload>} .
 */
export const PublicImage = (file: File, courseId: number) => {
  const requestId = 'PublicImage'
  return async (dispatch: any) => {
    getToken()
    const query = `
      mutation PublicImage($PublicImageInput: PublicImageInput!) {
        PublicImage(input: $PublicImageInput) {
          status {
            success
            name
            detail
            code
          }
          url
        }
      }
    `

    const form = new FormData()
    const variables = {
      PublicImageInput: {
        courseId,
        file: {
          id: 0,
          type: 'ANSWER',
          name: 'new file',
          file,
        },
      },
    }
    const operations = { query, variables }

    form.append('operations', JSON.stringify(operations))
    const map = {
      0: ['variables.PublicImageInput.file.file'],
    }
    form.append('map', JSON.stringify(map))
    form.append('0', file)

    const options: any = {
      url: `${apiURL}/graphql`,
      method: 'POST',
      data: form,
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    return axios(options)
      .then((response: any) => {
        const publicImage = response?.data?.data?.PublicImage
        if (publicImage) {
          publicImage.url = `${CDN_URL}${publicImage.url}`
        }
        return {
          data: publicImage,
        }
      })
      .catch((err: any) => axiosError(err, requestId, dispatch))
  }
}

/**
 * Usando new XMLHttpRequest()
 */
export const UploadPublicImage = (file: File) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', `${apiURL}/graphql`)
    xhr.setRequestHeader('Authorization', `bearer ${getToken()}`)

    const query = `
      mutation PublicImage($PublicImageInput: PublicImageInput!) {
        PublicImage(input: $PublicImageInput) {
          status {
            success
            name
            detail
            code
          }
          url
        }
      }
    `

    const form = new FormData()
    const variables = {
      PublicImageInput: {
        file: {
          id: 0,
          type: 'ANSWER',
          name: file.name,
          file,
        },
      },
    }
    const operations = { query, variables }
    const map = {
      0: ['variables.PublicImageInput.file.file'],
    }

    form.append('operations', JSON.stringify(operations))
    form.append('map', JSON.stringify(map))
    form.append('0', file)
    xhr.send(form)

    xhr.addEventListener('load', () => {
      const { data } = JSON.parse(xhr.responseText)
      if (data.PublicImage.status.success) {
        data.PublicImage.url = `${CDN_URL}${data.PublicImage.url}`
        resolve({
          data: data.PublicImage,
        })
      } else {
        reject(data.PublicImage.status.detail)
      }
    })
    xhr.addEventListener('error', () => {
      const error = JSON.parse(xhr.responseText)
      reject(error)
    })
  })
}
