import * as React from 'react'

export const CommentsIcon = () => (
  <svg
    width="45"
    height="45"
    role="CommentsIcon"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
    <path
      d="M27 18C27 16.8954 27.8954 16 29 16H33C34.1046 16 35 16.8954 35 18V23H27V18Z"
      fill="#0189FF"
    />
    <path
      d="M41 1H27C26.4477 1 26 1.44772 26 2V9C26 9.55229 26.4477 10 27 10H28.4648C28.7992 10 29.1114 10.1671 29.2969 10.4453L30.1679 11.7519C30.5638 12.3457 31.4362 12.3457 31.832 11.7519L32.7031 10.4453C32.8886 10.1671 33.2008 10 33.5352 10H41C41.5523 10 42 9.55229 42 9V2C42 1.44772 41.5523 1 41 1Z"
      fill="#1EBDAF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36 6C36 5.44772 36.4477 5 37 5L44 5C44.5523 5 45 5.44771 45 6C45 6.55228 44.5523 7 44 7L37 7C36.4477 7 36 6.55228 36 6Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 6C30 5.44772 30.4477 5 31 5L33 5C33.5523 5 34 5.44771 34 6C34 6.55228 33.5523 7 33 7L31 7C30.4477 7 30 6.55228 30 6Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 21C25 17.6863 27.6863 15 31 15C34.3137 15 37 17.6863 37 21C37 24.3137 34.3137 27 31 27C27.6863 27 25 24.3137 25 21ZM31 17C28.7909 17 27 18.7909 27 21C27 23.2091 28.7909 25 31 25C33.2091 25 35 23.2091 35 21C35 18.7909 33.2091 17 31 17Z"
      fill="#08044F"
    />
    <path
      d="M9.85715 11.8571C9.85715 10.7525 10.7526 9.85712 11.8571 9.85712H18.1429C19.2474 9.85712 20.1429 10.7525 20.1429 11.8571V17.5714H9.85715V11.8571Z"
      fill="#FFBC27"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15C8 11.134 11.134 8 15 8C18.866 8 22 11.134 22 15C22 18.866 18.866 22 15 22C11.134 22 8 18.866 8 15ZM15 10C12.2386 10 10 12.2386 10 15C10 17.7614 12.2386 20 15 20C17.7614 20 20 17.7614 20 15C20 12.2386 17.7614 10 15 10Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.26869 27.809C7.4532 25.4667 10.5673 24 14.0222 24C20.6393 24 26 29.3745 26 36C26 36.5523 25.5523 37 25 37C24.4477 37 24 36.5523 24 36C24 30.4752 19.5309 26 14.0222 26C11.1454 26 8.55385 27.2189 6.73131 29.1731C6.35463 29.577 5.72185 29.5991 5.31796 29.2224C4.91407 28.8457 4.89201 28.2129 5.26869 27.809Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.296 31.1986C26.1457 29.818 28.4457 29 30.9345 29C34.4756 29 37.6302 30.6548 39.6548 33.2278C39.9963 33.6618 39.9214 34.2905 39.4873 34.632C39.0533 34.9736 38.4246 34.8986 38.0831 34.4645C36.4232 32.3551 33.8393 31 30.9345 31C28.8904 31 27.0075 31.6704 25.4923 32.8014C25.0497 33.1317 24.4232 33.0408 24.0928 32.5982C23.7624 32.1556 23.8534 31.529 24.296 31.1986Z"
      fill="#08044F"
    />
    <path
      d="M4 42L16 42C16.5523 42 17 41.5523 17 41L17 34C17 33.4477 16.5523 33 16 33L14.5352 33C14.2008 33 13.8886 32.8329 13.7031 32.5547L12.832 31.2481C12.4362 30.6543 11.5638 30.6543 11.1679 31.2481L10.2969 32.5547C10.1114 32.8329 9.79917 33 9.46482 33L4 33C3.44771 33 3 33.4477 3 34L3 41C3 41.5523 3.44772 42 4 42Z"
      fill="#FF554D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.74228e-08 37C3.91405e-08 36.4477 0.447715 36 1 36L8 36C8.55229 36 9 36.4477 9 37C9 37.5523 8.55229 38 8 38L1 38C0.447715 38 1.35705e-07 37.5523 8.74228e-08 37Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 37C11 36.4477 11.4477 36 12 36L14 36C14.5523 36 15 36.4477 15 37C15 37.5523 14.5523 38 14 38L12 38C11.4477 38 11 37.5523 11 37Z"
      fill="#08044F"
    />
  </svg>
)
