import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormGroup } from 'reactstrap'

import { iOptions, Option } from '../../types'
import * as Style from './Style'
import { Grid } from 'app/Components/UI'

import RadioTest from './RadioTest'
import RadioExercise from './RadioExercise'
import CheckboxExercise from './CheckboxExercise'

import {
  multipleChoiceTest,
  multipleChoice,
  multipleAnswer,
} from '../../../QuestionWrapper/idsQuetionTypes'

export const Options = ({
  answer,
  action,
  optionDisabled,
  grid,
  id,
  type,
  evaluationState,
}: iOptions) => {
  const anserParse = JSON.parse(answer.content!)
  const { t } = useTranslation()
  let options = anserParse

  if (type === multipleChoiceTest && anserParse[0].options) {
    options = anserParse[0].options
  }

  if (!options || (options && options.length === 0)) return null

  return (
    <Style.Wrapper className="Options">
      <FormGroup tag="fieldset">
        <FormGroup check>
          <Grid size={grid}>
            {options.length > 0 ? (
              options.map((option: Option, optionValue: number) => {
                const common = {
                  evaluationState,
                  key: optionValue,
                  option,
                  answer,
                  optionValue,
                  optionDisabled,
                  id,
                }
                switch (type) {
                  case multipleChoice:
                    return <RadioExercise {...common} />
                  case multipleAnswer:
                    return <CheckboxExercise {...common} />
                  default:
                    return <RadioTest {...common} action={action} />
                }
              })
            ) : (
              <p>
                <strong>{t('CrossSeeOptions')}</strong>
              </p>
            )}
          </Grid>
        </FormGroup>
      </FormGroup>
    </Style.Wrapper>
  )
}
