import { memo } from 'react'

import { Select, Input, FeedbackField } from '../components'
import { getTypeAnswer, parseFeedback } from '../services'
import { IInput, IRenderAnswer, ISelect, IInputsCommon } from '../types'

const Answer = ({
  index,
  answers,
  save,
  selected,
  viewRevisor,
  isComplete,
  config,
  id,
}: IRenderAnswer) => {
  const answer = answers[index]
  if (answer === null || !answer.type) {
    return <></>
  }

  // Busca la respuesta seleccionada
  const findSelected = selected.find((item) => item.configId === index)

  // Valido que el tipo de pregunta sea el correcto
  const typeParse = getTypeAnswer(answer.type)
  if (!typeParse) {
    return <></>
  }

  const configBase = {
    id: `${id}_${index}`,
    configId: index,
    answer: answer,
    value: findSelected?.response ?? undefined,
    disabled: isComplete || viewRevisor,
    save,
    config,
  }

  const types = {
    NUMERICAL: <Input {...(configBase as IInputsCommon<IInput>)} />,
    MULTICHOICE_S: <Select {...(configBase as IInputsCommon<ISelect>)} />,
    SHORTANSWER: <Input {...(configBase as IInputsCommon<IInput>)} />,
    MULTICHOICE: <Select {...(configBase as IInputsCommon<ISelect>)} />,
  }

  return (
    <>
      {types[typeParse] ?? <></>}
      {isComplete && (
        <FeedbackField
          answer={parseFeedback({
            type: typeParse,
            feedback: findSelected?.feedback,
            isComplete,
            answer,
          })}
        />
      )}
    </>
  )
}

export const RenderAnswer = memo(Answer)
