/**
 * Limpia espacios vacios generados en el froala
 */
export const cleanLineBreaks = (text: string, withoutModule = true) => {
  let content = text

  // Espacios vacios
  content = content.replace(/<div><p><br><\/p><\/div>/g, '')
  content = content.replace(/<div><br><\/div>/g, '')
  content = content.replace(/<div><\/div>/g, '')
  content = content.replace(/<p><br><\/p>/g, '')
  content = content.replace(/<div class="question"><\/div>/g, '')
  content = content.replace(/<p><br>/g, '<p>')
  content = content.replace(/<p><br><br>/g, '<p>')
  content = content.replace(/<br><\/p>/g, '</p>')

  if (withoutModule) {
    // Saltos agregados antes o despues de un CONTENT_MODULE
    content = content.replace(
      /___REPLACE_CONTENT_MODULE___<p>(.*?)<br>/g,
      '___REPLACE_CONTENT_MODULE___<p>$1'
    )
    content = content.replace(
      /<br><\/p>___REPLACE_CONTENT_MODULE___/g,
      '</p>___REPLACE_CONTENT_MODULE___'
    )
  }

  return content
}
