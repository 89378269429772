import { Box } from '@chakra-ui/react'

import * as Type from '../types'
import { ScrollBar, Wrapper, Content } from './components'

export const Vertical = ({ data }: Type.propsComponents) => {
  const total = data.Milestones.length

  return (
    <Wrapper>
      <ScrollBar />
      <Box>
        {data.Milestones.map((item, index) => (
          <Content item={item} index={index} total={total} key={index} data={data} />
        ))}
      </Box>
    </Wrapper>
  )
}
