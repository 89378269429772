import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

// import * as Apollo from 'app/Apollo'
import { RouteParams } from 'Config/routes/types'
import { createContentModuleVR as axiosCreateContentModuleVR } from 'app/Redux/Actions'

interface IcreateContentModuleVR {
  contentModuleId: number
  provider: string
  textOptions: string[]
  textFeedback: string
  isCorrect: boolean
}
export const useCreateContentModuleVR = () => {
  const dispatch = useDispatch()
  const { studentId, courseId, unitId, resourceId } = useParams<RouteParams>()

  // const [save] = Apollo.useMutationcreateContentModuleVR()

  const createContentModuleVR = async (config: IcreateContentModuleVR) => {
    await dispatch<any>(
      axiosCreateContentModuleVR({
        routeParams: {
          studentId,
          courseId,
          unitId,
          resourceId,
        },
        contentModule: {
          id: config.contentModuleId,
          provider: config.provider,
          options: config.textOptions,
        },
        textFeedback: config.textFeedback,
        isCorrect: config.isCorrect ? 1 : 0,
      })
    )
    /*
    save({
      variables: {
        createContentModuleVRInput: {
          modifiedUserId: 0,
          studentId: Number(studentId),
          courseId: Number(courseId),
          unitId: Number(unitId),
          resourceId: Number(resourceId),
          contentModuleId: config.contentModuleId,
          provider: config.provider,
          textOptions: config.textOptions.join('|'),
          textFeedback: config.textFeedback,
          isCorrect: config.isCorrect,
        },
      },
    })
    */
  }

  return { createContentModuleVR }
}
