import { gql, useMutation, MutationHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const ADD_RESOURCE_PROGRESS = gql`
  ${STATUS_FIELDS}
  mutation addResourceProgress($ResourceProgressInput: ResourceProgressInput!) {
    addResourceProgress(input: $ResourceProgressInput) {
      status {
        ...StatusFields
      }
    }
  }
`

export type AddResourceProgressPayload = Api.ResourceProgressPayload

export type AddResourceProgressInput = Api.ResourceProgressInput

interface AddResourceProgressPayloadApollo {
  addResourceProgress: AddResourceProgressPayload
}

interface AddResourceProgressInputApollo {
  ResourceProgressInput: AddResourceProgressInput
}

export const useMutationAddResourceProgress = (
  options?: MutationHookOptions<AddResourceProgressPayloadApollo, AddResourceProgressInputApollo>
) => {
  return useMutation<AddResourceProgressPayloadApollo, AddResourceProgressInputApollo>(
    ADD_RESOURCE_PROGRESS,
    options
  )
}
