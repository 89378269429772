import { createGlobalStyle } from 'styled-components'

export const ModalStyleReset = createGlobalStyle`
  .modal .modal-dialog .modal-content {
    max-width: 360px !important;
    margin-top: 143px;
  }

  .modal .modal-dialog .modal-body .slick-slider {
    .slick-arrow {
      display: none !important;
     }

  }

  @media screen and (max-width: 382px) {
    .modal .modal-dialog .modal-content {
      width: auto !important;
      margin-top: 143px;
    }
  }
`
