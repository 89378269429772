import React from 'react'

export const Evaluation = () => (
  <svg
    width="45"
    height="45"
    data-testid="EvaluationIcon"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
    <path
      d="M21 9H30C30.5523 9 31 9.44772 31 10V14C31 14.5523 30.5523 15 30 15H21V9Z"
      fill="#1EBDAF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 11H18V13H30V11ZM18 9C16.8954 9 16 9.89543 16 11V13C16 14.1046 16.8954 15 18 15H30C31.1046 15 32 14.1046 32 13V11C32 9.89543 31.1046 9 30 9H18Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 13.358C7.55228 13.358 8 13.8057 8 14.358V37.9106C8 38.5123 8.48774 39 9.08941 39H26.7599C27.3122 39 27.7599 39.4477 27.7599 40C27.7599 40.5523 27.3122 41 26.7599 41H9.08941C7.38317 41 6 39.6168 6 37.9106V14.358C6 13.8057 6.44772 13.358 7 13.358Z"
      fill="#08044F"
    />
    <path
      d="M31 17H24C23.4477 17 23 17.4477 23 18V22C23 22.5523 23.4477 23 24 23H31C31.5523 23 32 22.5523 32 22V18C32 17.4477 31.5523 17 31 17Z"
      fill="#FFBC27"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 20C16 18.3431 17.3431 17 19 17C20.6569 17 22 18.3431 22 20C22 21.6569 20.6569 23 19 23C17.3431 23 16 21.6569 16 20ZM19 19C18.4477 19 18 19.4477 18 20C18 20.5523 18.4477 21 19 21C19.5523 21 20 20.5523 20 20C20 19.4477 19.5523 19 19 19Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 28C16 26.3431 17.3431 25 19 25C20.6569 25 22 26.3431 22 28C22 29.6569 20.6569 31 19 31C17.3431 31 16 29.6569 16 28ZM19 27C18.4477 27 18 27.4477 18 28C18 28.5523 18.4477 29 19 29C19.5523 29 20 28.5523 20 28C20 27.4477 19.5523 27 19 27Z"
      fill="#08044F"
    />
    <path
      d="M29 25H24C23.4477 25 23 25.4477 23 26V30C23 30.5523 23.4477 31 24 31H29C29.5523 31 30 30.5523 30 30V26C30 25.4477 29.5523 25 29 25Z"
      fill="#FF554D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0861 7C14.4845 7 13.9967 7.48774 13.9967 8.08941V31.9106C13.9967 32.5123 14.4845 33 15.0861 33H26.9102C27.4625 33 27.9102 33.4477 27.9102 34C27.9102 34.5523 27.4625 35 26.9102 35H15.0861C13.3799 35 11.9967 33.6168 11.9967 31.9106V8.08941C11.9967 6.38317 13.3799 5 15.0861 5H32.8943C34.6006 5 35.9837 6.38317 35.9837 8.08941V20C35.9837 20.5523 35.536 21 34.9837 21C34.4315 21 33.9837 20.5523 33.9837 20V8.08941C33.9837 7.48775 33.496 7 32.8943 7H15.0861Z"
      fill="#08044F"
    />
    <path
      d="M32 25C32 23.8954 32.8954 23 34 23H36C37.1046 23 38 23.8954 38 25V27H32V25Z"
      fill="#0189FF"
    />
    <path
      d="M32 29H38V39.3043C38 39.6576 37.9064 40.0046 37.7287 40.3101L35.8643 43.5144C35.4787 44.1772 34.5213 44.1772 34.1357 43.5144L32.2713 40.3101C32.0936 40.0046 32 39.6576 32 39.3043V29Z"
      fill="#0189FF"
    />
  </svg>
)
