import { Box } from '@chakra-ui/react'

export const WorkIcon = () => {
  return (
    <Box
      sx={{
        '@media screen and (min-width: 991px)': {
          svg: {
            transform: 'scale(.55)',
          },
        },
      }}
    >
      <svg width={65} height={56} fill="none" className="symbolSizeError">
        <path
          fill="#E0EEFA"
          d="M44.5 52h-6v-4h-12v4h-6a2 2 0 0 0 0 4h24a2 2 0 0 0 0-4ZM62.726 0a2.13 2.13 0 0 0-.226 0h-60a1.89 1.89 0 0 0-2 2v40a1.89 1.89 0 0 0 2 2h60a1.89 1.89 0 0 0 2-2V2a1.89 1.89 0 0 0-1.774-2ZM44.31 22.847A2 2 0 0 1 42.5 24h-8v10a2 2 0 0 1-4 0V24h-8a2 2 0 0 1-1.538-3.28l10-12a2.072 2.072 0 0 1 3.076 0l10 12a2 2 0 0 1 .272 2.128Z"
        />
      </svg>
    </Box>
  )
}
