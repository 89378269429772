export const Attend = () => {
  return (
    <g>
      <circle cx="6" cy="6" r="6" fill="#28A745" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.41421 5.53555L2 6.94977L3.41421 8.36398L4.82843 9.7782L9.77817 4.82845L8.36396 3.41423L4.82843 6.94977L3.41421 5.53555Z"
        fill="white"
      />
    </g>
  )
}
