import React from 'react'

export const EmptyCourseAcademicIcon = () => {
  return (
    <svg
      width="197"
      height="197"
      viewBox="0 0 197 197"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M98.5 197C152.9 197 197 152.9 197 98.5C197 44.1 152.9 0 98.5 0C44.1 0 0 44.1 0 98.5C0 152.9 44.1 197 98.5 197Z"
        fill="#F4F4F4"
      />
      <rect
        x="102"
        y="14"
        width="25"
        height="6"
        rx="3"
        transform="rotate(90 102 14)"
        fill="#0189FF"
      />
      <rect
        x="122"
        y="44.6777"
        width="25"
        height="6"
        rx="3"
        transform="rotate(-45 122 44.6777)"
        fill="#0189FF"
      />
      <rect
        x="58.2427"
        y="27"
        width="25"
        height="6"
        rx="3"
        transform="rotate(45 58.2427 27)"
        fill="#0189FF"
      />
      <rect y="65" width="197" height="29" rx="5" fill="#FFBC27" />
      <rect x="10" y="77" width="52" height="6" rx="3" fill="#08044F" />
      <rect x="66" y="77" width="6" height="6" rx="3" fill="#08044F" />
      <rect x="10" y="100" width="176" height="29" rx="5" fill="#FF554D" />
      <rect x="99" y="112" width="6" height="6" rx="3" fill="#08044F" />
      <rect x="20" y="112" width="74" height="6" rx="3" fill="#08044F" />
      <rect x="23" y="134" width="151" height="29" rx="5" fill="#1EBDAF" />
      <rect x="33" y="146" width="47" height="6" rx="3" fill="#08044F" />
    </svg>
  )
}
