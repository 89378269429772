import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Label } from 'reactstrap'
import { useSelector } from 'react-redux'
import { Button, Modal } from 'app/Components/UI'
import { flashWarning } from 'assets/svg'

import { useAlerts } from './useAlerts'
import { Alert as Style } from './style'
import { Types } from './types'

/**
 * @example <Alerts />
 */
export const Alerts = () => {
  const { t } = useTranslation()
  const {
    comments: { type, show },
  } = useSelector((store: Types.Store) => store)

  const sizeFile = 150

  const { closed, handleAgreed } = useAlerts()
  const [check, setCheck] = useState(false)
  const [state, setState] = useState({
    title: t('CommentSystemFileExceeds', { size: sizeFile }),
    info: t('CommentSystemTryWithFile'),
  })

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]

    if (show) {
      body.style.overflowY = 'hidden'

      const observer = new MutationObserver(() => {
        const content = document.querySelector('.modal-comment')
        if (content) {
          const parent = content.parentElement?.parentElement?.parentElement
          if (parent) {
            parent.style.zIndex = '9999'
            observer.disconnect() // Desconectamos el observer una vez realizado el cambio
          }
        }
      })

      // Observamos los cambios en el DOM del body para encontrar el contenido de la modal
      observer.observe(body, { childList: true, subtree: true })
    } else if (!show && body.style.overflowY === 'hidden') {
      body.style.overflowY = 'scroll'
    }

    return () => {
      body.style.overflowY = 'scroll'
    }
  }, [show])

  useEffect(() => {
    if (type === 'redirect') {
      setState({
        title: t('CommentSystemLeavingEclass'),
        info: t('CommentSystemTrustedSite'),
      })
    } else if (type === 'download') {
      setState({
        title: t('CommentSystemDownloadFiles'),
        info: t('CommentSystemTrustedSource'),
      })
    } else if (type === 'delete') {
      setState({
        title: t('CommentSystemDeleteFiles'),
        info: t('CommentSystemIrrevesibleAction'),
      })
    } else if (type === 'size') {
      setState({
        title: t('CommentSystemFileExceeds', { size: sizeFile }),
        info: t('CommentSystemTryWithFile'),
      })
    }
  }, [type, t])

  return (
    <>
      <Style.Wrapper type={type} />
      <Modal
        className="modal-comment"
        isOpen={show}
        fade={true}
        toggle={() => closed()}
        closeModal={() => closed()}
        footer={
          <>
            {type !== 'size' && (
              <Button
                text={type === 'download' ? t('ResourceStopDownload') : t('ResourceBack')}
                border
                onClick={() => {
                  closed()
                }}
              />
            )}
            <Button
              text={type === 'download' ? t('ResourceAllowDownload') : t('DashboardUnderstand')}
              border
              onClick={() => {
                handleAgreed(check)
              }}
            />
          </>
        }
      >
        <Style.Message>
          <img className="image" src={flashWarning} alt=" " />
          <h4>{state.title}</h4>
          <p>{state.info}</p>
          {type !== 'size' ||
            ('delete' && (
              <Style.Check>
                <Label check>
                  <Input
                    data-testid="inputSize"
                    checked={check}
                    name="check"
                    type="checkbox"
                    onChange={(e) => setCheck(e.target.checked)}
                  />
                  {t('CommentSystemNotShowMsg')}
                </Label>
              </Style.Check>
            ))}
        </Style.Message>
      </Modal>
    </>
  )
}
