import { useState, ChangeEvent, useEffect } from 'react'
import { Box } from '@chakra-ui/react'
import { vars } from '@eclass/ui-kit'

import { IAudio } from '..'

interface IRange extends IAudio {
  duration: number
  time: number
  isPlaying: boolean
}
export const Range = ({ duration, time, audio, isPlaying }: IRange) => {
  const progress = 100 / duration

  const [value, setValue] = useState(0)

  useEffect(() => {
    if (time) {
      setValue(time * progress)
    }
  }, [time, duration])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (audio) {
      const newValue = Number(e.target.value)
      setValue(newValue)
      const PercentageToSeconds = Math.trunc((duration * newValue) / 100)
      audio.currentTime = PercentageToSeconds
      if (isPlaying) {
        audio.play()
      }
    }
  }

  const valuee = typeof value === 'number' ? value : 0

  return (
    <Box
      alignItems="center"
      appearance="none"
      as="input"
      background={`linear-gradient(to right, ${vars('colors-main-deepSkyBlue')} ${value}%, ${vars(
        'colors-neutral-gray'
      )} 0)`}
      borderRadius={vars('radii-big')}
      cursor="pointer"
      display="flex"
      h="4px"
      min={0}
      max={100}
      type="range"
      value={valuee}
      onChange={onChange}
      _hover={{
        '&::-webkit-slider-thumb': {
          bg: vars('colors-main-azureRadiance'),
          transform: 'scale(1.15)',
          transition: 'all .3s',
        },
      }}
      sx={{
        '&::-webkit-slider-thumb': {
          appearance: 'none',
          w: '14px',
          borderWidth: 0,
          borderRadius: vars('radii-rounded'),
          h: '14px',
          bg: vars('colors-main-deepSkyBlue'),
        },
        '@media screen and (min-width: 364px)': {
          w: '180px',
        },
      }}
    />
  )
}
