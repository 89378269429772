export const emojis = [
  {
    emoji: '🙁',
  },
  {
    emoji: '🙁',
  },
  {
    emoji: '🙁',
  },
  {
    emoji: '🙁',
  },
  {
    emoji: '🙁',
  },
  {
    emoji: '🙁',
  },
  {
    emoji: '🙁',
  },
  {
    emoji: '😐',
  },
  {
    emoji: '😐',
  },
  {
    emoji: '😄',
  },
  {
    emoji: '😄',
  },
]
