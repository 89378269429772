import { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { getCountries } from 'app/Redux/Actions'
import { Types } from '../types'
import { useData } from './useData'
import { getValue } from '../../Confirmation/Helper'

/**
 * hooks que obtine los paises,
 * siempre y cuando no tenga datos en la store
 * @example
 * const { countries, nation, status } = useCountries()
 */
export const useCountries = () => {
  const dispatch = useDispatch()
  const { data, profile, setProfile } = useData()
  const [state, setState] = useState(profile.countries)
  const [nation, setNation] = useState({ label: '', value: 0 })
  const [status, setStatus] = useState('init')

  const loadCountries = useCallback(async () => {
    setStatus('loading')
    const response = await dispatch<any>(getCountries())
    if (response && response.status.success) {
      const countriesList = response.data.map((country: Types.Countries) => {
        return { label: country.name, value: country.id }
      })
      setProfile({ countries: countriesList })
      setState(countriesList)
      setNation(getValue(countriesList, data!.nationId))
      setStatus('success')
    } else {
      setStatus('error')
    }
  }, [dispatch, data, setProfile])

  useEffect(() => {
    if (!state) {
      loadCountries()
    } else if (nation.label === '' || (data && data.nationId)) {
      setNation(getValue(state, data!.nationId))
    }
  }, [state, loadCountries, nation, data])

  return { countries: state, nation, status }
}
