import { Indicator } from '@eclass/api'
import { useTranslation } from 'react-i18next'
import * as API from '@eclass/api'

import { Icon } from 'app/Components/UI/Icons'
import { Wrapper } from './style'
import { useDateFormat } from 'app/Services'
// import { ResourceIndicator } from './ResourceIndicator'
import { vars } from '@eclass/ui-kit'

const LUDICO = 3
interface IndicatorsProps {
  unit?: API.UnitBlock
  indicators: Indicator[]
  showName?: boolean
  activeNavigation?: boolean
  type?: string
}

const ItemIndicators = ({
  indicators,
  showName = true,
  activeNavigation = false,
  type,
  unit,
}: IndicatorsProps) => {
  const { dateFormat } = useDateFormat()
  const { t } = useTranslation()

  if (!indicators || (indicators && indicators.length === 0)) {
    return <></>
  }

  const showIndicator = (indicator: any) => {
    if (indicator.image === 'calendar') {
      const date = dateFormat({ date: indicator.startDate, format: 'dmyh' })
      if (type === 'WORK') {
        return t('ContentWorkDue', { date })
      } else {
        return date
      }
    }
    if (indicator.image === 'estrellas') {
      const indicatorString = indicator.name
      const [completedStars, totalStars] = indicatorString.split('/')
      return t('ContentStars', { count: completedStars, total: totalStars })
    }
    if (indicator.image === 'resources') {
      // return <ResourceIndicator unit={unit} />
    }
    return indicator.name
  }

  const cev = (indicatorList: Indicator[]) => {
    const indicatorClass = indicatorList.find((elem) => elem.image === 'calendar')
    if (indicatorClass) {
      return indicatorClass.image
    }
    return ''
  }

  return (
    <Wrapper data-testid="ItemIndicators" className="indicators">
      <ul
        className={`Classes__Indicators ${indicators.length > 1 ? 'Multiple' : ''} ${cev(
          indicators
        )}`}
      >
        {indicators
          .filter((indicator) => indicator.id !== LUDICO || !activeNavigation)
          .map(
            (indicator, key) =>
              indicator &&
              indicator.image !== 'goals' && (
                <li key={key} className={`Indicator Indicator-li ${indicator.image}`}>
                  <Icon
                    name={indicator.image}
                    fill={
                      ['stars', 'estrellas'].includes(indicator.image)
                        ? vars('colors-alert-orangeyYelow')
                        : vars('colors-main-ziggurat')
                    }
                  />
                  {showName && <span className="Indicator__Name">{showIndicator(indicator)}</span>}
                </li>
              )
          )}
      </ul>
    </Wrapper>
  )
}

export default ItemIndicators
