import styled from 'styled-components/macro'

import { UIVars } from 'app/Components/UI'
import { setMultimediaV8 } from '../Style'

export const Wrapper = styled.div`
  .form-check {
    padding-left: 0px;
    margin-bottom: 30px;
  }
`

type ContentProps = {
  correct?: boolean
}
export const Content = styled.div<ContentProps>`
  width: 100%;
  .Article__audio {
    audio {
      max-width: 100%;
      width: revert;
    }
  }
  &,
  p {
    color: ${({ correct }: ContentProps) =>
      correct ? UIVars.setColors.checkGreen : UIVars.setColors.black};
  }
  p {
    margin-bottom: 0;
  }
  ${setMultimediaV8}
`

export const Number = styled.div`
  margin-right: 30px;
`
