import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import ReactHtmlParser from 'react-html-parser'

import { Select } from '../Common'
import { useTimeZone } from './useTimeZone'
import * as Style from './style'
import { Types } from 'app/Views/Users/Profile/types'
import { useDateFormat } from 'app/Services'
import * as Redux from 'app/Redux/types'
import { useSelector } from 'react-redux'

interface ITimeZone {
  profile: boolean
  isSubmit: boolean
  inCoursesView?: boolean
  context: Types.ModalProps['context']
}
/**
 * Campos para agregar la zona horaria
 */
export const TimeZone = ({
  profile = false,
  isSubmit = false,
  inCoursesView = false,
  context = 'PROFILE',
}: ITimeZone) => {
  const { t } = useTranslation()
  const {
    loggedUser: { user },
  } = useSelector((state: Redux.Store) => state)
  const FormContext = useFormContext()
  const { getValues, setValue } = FormContext

  const { timeZoneList } = useTimeZone()

  if (!getValues().timeZone) {
    setValue('timeZone', 'America/Santiago')
  }

  const classIsProfile = profile ? 'profile' : ''

  const { dateFormat } = useDateFormat()
  const gmt = dateFormat({ date: new Date(), format: 'z' }) // Dejo es new Date() ya que es solo para obtener zona horaria

  const text = {
    PROFILE: <p>{t('ProfileTimeZoneDescription')}</p>,
    WITH_OUT: <p>{t('ProfileTimeZoneCoursesListModalDescription')}</p>,
    TO_CHANGE: (
      <>
        <p>
          {ReactHtmlParser(
            t('ProfileTimeZoneModalInfoFirstParagraph', {
              timeZone: `${gmt} ${user?.timeZone}.`,
            })
          )}
        </p>
        <p>{t('ProfileTimeZoneDescription')}</p>
      </>
    ),
  }

  return (
    <Style.Wrapper className={`ProfileForms-TimeZone ${classIsProfile}`}>
      <div className="profile-paragraphTimeZone">{text[context]}</div>
      <Select
        name="timeZone"
        label={t('ProfileTimeZone')}
        placeholder={t('ResourceSelect')}
        options={timeZoneList}
        disabled={isSubmit}
      />
    </Style.Wrapper>
  )
}
