import { gql, useLazyQuery, LazyQueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const GET_RANKING_DETAIL = gql`
  ${STATUS_FIELDS}
  query RankingDetail($RankingDetailInput: RankingDetailInput!) {
    getRankingDetail(input: $RankingDetailInput) {
      status {
        ...StatusFields
      }
      Person {
        id
        fullName
        picture
        position
      }
      StudentRanking {
        id
        currentPosition
        obtainedIndicator
        totalIndicator
      }
      Achievements {
        id
        type
        name
        description
        isComplete
        isReviewed
        completeDate
      }
    }
  }
`

const GET_RANKING_ACHIEVEMENT = gql`
  ${STATUS_FIELDS}
  query RankingDetail($RankingDetailInput: RankingDetailInput!) {
    getRankingDetail(input: $RankingDetailInput) {
      status {
        ...StatusFields
      }
      StudentRanking {
        obtainedIndicator
        totalIndicator
      }
      Achievements {
        id
        type
        name
        description
        isComplete
        isReviewed
        completeDate
      }
    }
  }
`

export type RankingDetailPayload = Api.RankingDetailPayload

export type RankingDetailPayloadInput = Api.RankingDetailInput

interface RankingDetailPayloadApollo {
  getRankingDetail: RankingDetailPayload
}

interface RankingDetailPayloadInputInternal {
  RankingDetailInput: RankingDetailPayloadInput
  context: 'ranking' | 'notification'
}
export interface RankingDetailPayloadInputApollo {
  RankingDetailInput: RankingDetailPayloadInput
}

export const useLazyQueryGetRankingDetail = (
  options?: LazyQueryHookOptions<RankingDetailPayloadApollo, RankingDetailPayloadInputInternal>
) => {
  const query =
    options?.variables?.context === 'ranking' ? GET_RANKING_DETAIL : GET_RANKING_ACHIEVEMENT
  return useLazyQuery<RankingDetailPayloadApollo, RankingDetailPayloadInputInternal>(query, options)
}
