export const TeamworkCheck = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" data-testid="TeamworkCheck">
      <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
      <path
        d="M22.6184 25H23.3682C23.534 25 23.693 24.9341 23.8102 24.8167C23.9274 24.6994 23.9933 24.5403 23.9933 24.3743V21H27.3755C27.4955 20.9999 27.6129 20.9652 27.7138 20.9001C27.8146 20.835 27.8946 20.7422 27.9441 20.6328C27.9937 20.5234 28.0108 20.402 27.9933 20.2832C27.9758 20.1644 27.9245 20.0531 27.8456 19.9627L23.47 15.2035C23.4097 15.1392 23.3369 15.088 23.256 15.0531C23.1752 15.0181 23.088 15 23 15C22.9119 15 22.8247 15.0181 22.7439 15.0531C22.663 15.088 22.5902 15.1392 22.5299 15.2035L18.1543 19.9627C18.0754 20.0531 18.0241 20.1644 18.0066 20.2832C17.9891 20.402 18.0062 20.5234 18.0558 20.6328C18.1053 20.7422 18.1853 20.835 18.2861 20.9001C18.387 20.9652 18.5044 20.9999 18.6244 21H21.9933V24.3743C21.9933 24.5403 22.0591 24.6994 22.1764 24.8167C22.2936 24.9341 22.4526 25 22.6184 25Z"
        fill="#0189FF"
      />
      <rect x="18" y="26" width="10" height="2" rx="1" fill="#0189FF" />
      <path
        d="M41 5H29C28.4477 5 28 5.44772 28 6V17C28 17.5523 28.4477 18 29 18H41C41.5523 18 42 17.5523 42 17V6C42 5.44772 41.5523 5 41 5Z"
        fill="#FF554D"
      />
      <path
        d="M8 12C8 10.8954 8.89543 10 10 10H35C36.1046 10 37 10.8954 37 12V33C37 34.1046 36.1046 35 35 35H10C8.89543 35 8 34.1046 8 33V12Z"
        stroke="#08044F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="4 4"
      />
      <rect x="6" y="27" width="4" height="3" fill="#E0EEFA" />
      <rect y="23" width="16" height="5" rx="2" fill="#F5A623" />
      <rect x="1" y="24" width="14" height="7" rx="1" stroke="#08044F" strokeWidth="2" />
      <path
        d="M44 30H27C26.4477 30 26 30.4477 26 31V39C26 39.5523 26.4477 40 27 40H44C44.5523 40 45 39.5523 45 39V31C45 30.4477 44.5523 30 44 30Z"
        fill="#1EBDAF"
      />
      <line
        x1="29"
        y1="33"
        x2="40"
        y2="33"
        stroke="#08044F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="29"
        y1="37"
        x2="36"
        y2="37"
        stroke="#08044F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="35" cy="35" r="8" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 35C27 30.6 30.6 27 35 27C39.4 27 43 30.6 43 35C43 39.4 39.4 43 35 43C30.6 43 27 39.4 27 35ZM30.6 35L34 38.4L39.4 33L38 31.6L34 35.6L32 33.6L30.6 35Z"
        fill="#28A745"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45 35C45 40.5228 40.5228 45 35 45C29.4772 45 25 40.5228 25 35C25 29.4772 29.4772 25 35 25C40.5228 25 45 29.4772 45 35ZM35 43C39.4183 43 43 39.4183 43 35C43 30.5817 39.4183 27 35 27C30.5817 27 27 30.5817 27 35C27 39.4183 30.5817 43 35 43Z"
        fill="white"
      />
    </svg>
  )
}
