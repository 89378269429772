// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { axiosError } from '../../../Services'

/**
 * @typedef {Object} FilterType
 * @property {string} userType - Tipo de Usuario
 * @property {string} search - Búsqueda del usuario
 */

/**
 * Busca un usuario en la base de datos según el Tipo. Si es email busca en la tabla de Personas por el email, sino lo encuentra busca entre los Usuarios de tipo Mail
 * @param {FilterType} input - Recibe el input con el Tipo Usuario y el string de búsqueda
 * @example
 * ```js
 *  const { status } = await UserRecoverPasswd(input)
 * ```
 * @returns Retorna el estado si envió el mail o no.
 */
const UserRecoverPasswd = (input) => {
  const requestId = 'UserRecoverPasswd'
  return async (dispatch) => {
    const query = `
      mutation LoginRecoverPasswd($LoginRecoverPasswdInput: LoginRecoverPasswdInput!) {
        LoginRecoverPasswd(input: $LoginRecoverPasswdInput) {
          status {
            code
            detail
            name
            success
          }
        }
      }
    `
    const variables = {
      LoginRecoverPasswdInput: input,
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then((response) => response.data.data.LoginRecoverPasswd)
      .catch((err) => {
        axiosError(err, requestId, dispatch)
      })
  }
}

export default UserRecoverPasswd
