import { useHistory } from 'react-router-dom'
import config from 'Config/domains'

export const useLoginRedirect = () => {
  const history = useHistory()

  const isLoginRedirect = config.loginRedirect === 'true'

  const redirectToUrl = () => {
    if (isLoginRedirect && config.urlRedirect) {
      window.location.assign(config.urlRedirect)
    } else {
      history.push('/')
    }
  }

  return {
    isLoginRedirect,
    redirectToUrl,
  }
}
