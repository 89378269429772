export const ArrowBack = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        d="M20 1.52301e-06C8.9543 2.36415e-06 -3.11586e-06 8.95431 -2.00727e-06 20C-8.98687e-07 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 6.81875e-07 20 1.52301e-06Z"
        fill="#0189FF"
      />
      <path d="M21.9259 14L16 20L21.9259 26L24 23.9L20.1481 20L24 16.1L21.9259 14Z" fill="white" />
    </svg>
  )
}
