/** Función para obtener el id del botón dropdown en casos definidos para rastrear clic en google tag manager */
export const getIdButtonDropdown = (dropdownType) => {
  switch (dropdownType) {
    case 'navigation':
      return 'ResourceMaterialsMenu'
    case 'feedback':
    case 'feedbackLevels':
      return 'ControlMenuFeedback'
    case 'test':
      return 'ControlMenu'
    case 'exercise':
      return 'ExerciseMenu'
    default:
      return ''
  }
}
