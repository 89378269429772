export const StopWatch = (props) => {
  return (
    <g {...props}>
      <path
        d="M12.7 0.3C12.3 -0.1 11.7 -0.1 11.3 0.3C10.9 0.7 10.9 1.3 11.3 1.7L12.1 2.5L11.2 3.4C10 2.5 8.6 2 7 2C3.1 2 0 5.1 0 9C0 12.9 3.1 16 7 16C10.9 16 14 12.9 14 9C14 7.4 13.5 6 12.6 4.8L13.5 3.9L14.3 4.7C14.5 4.9 14.7 5 15 5C15.3 5 15.5 4.9 15.7 4.7C16.1 4.3 16.1 3.7 15.7 3.3L12.7 0.3ZM7 14C4.2 14 2 11.8 2 9C2 6.2 4.2 4 7 4C9.8 4 12 6.2 12 9C12 11.8 9.8 14 7 14Z"
        fill="#B0CFE0"
      />
      <path d="M8 6H6V10H10V8H8V6Z" fill="#B0CFE0" />
    </g>
  )
}
