import { vars } from '@eclass/ui-kit'

export const On = () => (
  <svg width={16} height={16} fill="none">
    <path
      fill={vars('colors-main-blueGrey')}
      d="M8.965.737A.67.67 0 0 0 8.267.8L3.11 4.667H.833c-.368 0-.833.298-.833.666v5.334c0 .368.465.666.833.666h2.278L8.267 15.2a.663.663 0 0 0 .698.063.667.667 0 0 0 .368-.596V1.333a.667.667 0 0 0-.368-.596ZM11 11.646a1 1 0 0 1-.75-1.662 3 3 0 0 0 .001-3.967 1 1 0 0 1 1.5-1.324A4.993 4.993 0 0 1 13 8a4.994 4.994 0 0 1-1.25 3.307 1 1 0 0 1-.75.339Z"
    />
    <path
      fill={vars('colors-main-blueGrey')}
      d="M12.001 15.707a1 1 0 0 1-.667-1.745A8.014 8.014 0 0 0 14 8a8.014 8.014 0 0 0-2.666-5.962 1 1 0 0 1 1.334-1.49A10.014 10.014 0 0 1 16 8c0 2.841-1.215 5.557-3.332 7.453-.19.17-.43.254-.667.254Z"
    />
  </svg>
)
