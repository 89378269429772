import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import * as ItemStyle from './ItemStyle'
import { Icon } from 'app/Components/UI/Icons'
import { IconProps, PropsMenuItemNavigation } from './Types'
import { UIVars, ItemIndicators } from 'app/Components/UI'
import CountStars from '../CountStars'
import { RouteParams } from 'Config/routes/types'
import * as Redux from 'app/Redux/types'

const ITEM_COMPLETED = [3, 8, 9]

const SetIcon = ({ link, index }: IconProps) => {
  const { answerId } = useParams<RouteParams>()
  const ITEM_PRESENTIAL_COMPLETED =
    link.status?.style === 'attend' || link.status?.style === 'absent'

  if (link.status?.enabled) {
    if (link.status?.style === 'on' || link.id! === Number(answerId)) {
      return <Icon fill={UIVars.setColors.primary} name="in-progress" />
    } else {
      const styles = [link.status!.detail, link.status!.style]
      return styles.includes('completed') ||
        ITEM_COMPLETED.includes(link.status?.id) ||
        ITEM_PRESENTIAL_COMPLETED ? (
        <Icon fill={UIVars.setColors.completed} name="completed" />
      ) : (
        index + 1
      )
    }
  } else {
    return <Icon fill={UIVars.setColors.veryLightPink} name="blocked" />
  }
}

const MenuItemNavigation = ({
  link,
  index,
  setActive,
  activeNavigation,
  setNavId,
  ariaLabel,
  itemRole = 'menuitem',
}: PropsMenuItemNavigation) => {
  const { score } = useSelector((store: Redux.Store) => store.exercises)
  const history = useHistory()
  const { resourceId, answerId } = useParams<RouteParams>()

  const navigate = () => {
    setActive(true)
    if (setNavId) {
      setNavId(link.id!)
    }
  }

  const goTo = () => {
    if (!link.Childrens) {
      history.push(link.href!)
    }
  }

  const stars = link.indicators?.find((indicator) => indicator.image === 'estrellas')
  let links = link.indicators?.filter((indicator) => indicator.image !== 'estrellas') || []
  if (link.Childrens) {
    links = link.indicators || []
  }
  const action = !activeNavigation ? navigate : !link.status?.enabled ? () => {} : goTo

  const updatedStars = {
    obtained: stars?.obtained || 0,
    max: stars?.max || 0,
  }

  if (Number(answerId) === link.id) {
    // si pregunto por el set en el que estoy, las estrellas se obtienen de redux
    updatedStars.obtained = score.obtained !== stars?.obtained ? score.obtained : stars?.obtained
  } else if (!answerId && index === 0 && link.href?.includes(resourceId!)) {
    // si pregunto por el primer ejercicio del set en el que estoy, las estrellas se obtienen de redux
    updatedStars.obtained = score.obtained
  }

  return (
    <ItemStyle.Navigation
      aria-label={ariaLabel}
      className={link.status?.detail}
      type="navigation"
      total={(link.Childrens && link.Childrens!.length) || 0}
      onClick={action}
      role={itemRole}
    >
      <div className="resourceState" aria-hidden>
        <SetIcon link={link} index={index} resourceId={resourceId} />
      </div>
      <div className="menu_container" aria-hidden>
        <div className={`${activeNavigation && stars ? 'max-width' : ''} exercise_title`}>
          <span
            className={`${activeNavigation && stars ? 'children-max-width' : ''} title_overflow`}
          >
            <span>{link.name}</span>
            <ItemIndicators indicators={links} activeNavigation={activeNavigation} />
          </span>
        </div>
        {link.Childrens && link.Childrens.length > 0 && (
          <span className="arrow">
            <Icon name="arrow-in" />
          </span>
        )}
        {activeNavigation && stars && (
          <CountStars obtained={updatedStars.obtained} max={updatedStars.max} />
        )}
      </div>
    </ItemStyle.Navigation>
  )
}

export default MenuItemNavigation
