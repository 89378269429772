import { gql, useLazyQuery, LazyQueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

export const GET_RANKING_LIST = gql`
  ${STATUS_FIELDS}
  query getRankingList($RankingListInput: RankingListInput!) {
    getRankingList(input: $RankingListInput) {
      status {
        ...StatusFields
      }
      totalStudents
      totalIndicator
      pages
      StudentRankings {
        id
        name
        occupation
        position
        avatar
        indicator
        isFeatured
      }
    }
  }
`

export type RankingListPayload = Api.RankingListPayload

export type RankingListPayloadInput = Api.RankingListInput

interface RankingListPayloadApollo {
  getRankingList: RankingListPayload
}

interface RankingListPayloadInputApollo {
  RankingListInput: RankingListPayloadInput
}

export const useLazyQueryGetRankingList = (
  options?: LazyQueryHookOptions<RankingListPayloadApollo, RankingListPayloadInputApollo>
) => {
  return useLazyQuery<RankingListPayloadApollo, RankingListPayloadInputApollo>(
    GET_RANKING_LIST,
    options
  )
}
