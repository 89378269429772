import { PDFAction, PDFTypes } from './types'
import { initialState } from './initialState'

/**
 * Guarda contenido recursos y estados de render y descarga
 */
export const pdf = (state: PDFTypes = initialState, action: PDFAction): any => {
  switch (action.type) {
    case 'PDF_MODAL':
      return { ...state, ...action.payload }
    case 'PDF_STATUS':
      return { ...state, status: action.payload }
    case 'PDF_RESET':
      return initialState
    default:
      return state
  }
}
