import styled, { css } from 'styled-components/macro'

import { layout } from '../Common'

export const Wrapper = styled.div`
  ${layout(css`
    .alertContainer {
      grid-area: alertContainer;
    }
    .profile-documentNumberId {
      grid-area: documentNumberId;
    }
    .profile-documentNumber {
      grid-area: documentNumber;
    }
    .profile-nationId {
      grid-area: nationId;
    }
    .profile-name {
      grid-area: name;
    }
    .profile-firstSurname {
      grid-area: firstSurname;
    }
    .profile-lastSurname {
      grid-area: lastSurname;
    }
    .profile-birthday {
      grid-area: birthday;
    }
    .profile-gender {
      grid-area: gender;
    }
    .profile-email {
      grid-area: email;
    }
    .profile-emailPrivate {
      grid-area: emailPrivate;
    }
    .profile-professionalResume {
      grid-area: professionalResume;
    }

    grid-template-areas:
      'documentNumberId documentNumber'
      'nationId .'
      'name name'
      'firstSurname firstSurname'
      'lastSurname lastSurname'
      'birthday gender'
      'email email';

    &.profile {
      grid-template-areas:
        'documentNumberId documentNumber'
        'nationId .'
        'name name'
        'firstSurname firstSurname'
        'lastSurname lastSurname'
        'birthday gender'
        'email email'
        'emailPrivate emailPrivate'
        'professionalResume professionalResume';
    }
  `)}
`
