import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { UIVars } from 'app/Components/UI'
import { Icon } from 'app/Components/UI/Icons'
import { ModalQuestion, useModalQuestion } from '../ModalQuestion'
import * as Style from './style'
import * as Types from './types'
import { useContext } from 'react'
import { ProvisionalContext } from 'app/Components/ProvisionalStudent'
import { BtnPrimary, BtnSecondary } from '@eclass/ui-kit'
import { useImpersonate } from 'app/Components/Impersonate'

export function AddQuestion({ resourceName, type, addQuestionInfo }: Types.AddQuestion) {
  const { isOpen, toggleModal } = useModalQuestion()
  const { isProvisional, openModal } = useContext(ProvisionalContext)
  const { impersonate } = useImpersonate()

  if (impersonate) return <></>

  const hasFeaturedQuestion =
    addQuestionInfo?.context?.own.style === 'featured' ||
    addQuestionInfo?.context?.public.style === 'featured'

  const handleToggleModal = () => {
    if (isProvisional) {
      openModal()
    } else {
      toggleModal()
    }
  }

  return (
    <>
      <ResourceOrHelperButton
        type={type}
        toggleModal={handleToggleModal}
        hasFeaturedQuestion={hasFeaturedQuestion}
      />
      <ModalQuestion
        isOpen={isOpen}
        toggleModal={handleToggleModal}
        type={type}
        addQuestionInfo={addQuestionInfo}
        resourceName={resourceName}
      />
    </>
  )
}

const ResourceOrHelperButton = ({ type, toggleModal, hasFeaturedQuestion }) => {
  const {
    ui: { isMobile },
  } = useSelector((store: Types.Store) => store)
  const { t } = useTranslation()

  if (type !== 'helper') {
    return (
      <Style.Wrapper>
        <BtnSecondary
          id="helperButton"
          onClick={() => toggleModal()}
          leftIcon={<Icon name="presentation" fill={UIVars.setColors.deepSkyBlue} />}
          size="small"
        >
          {!isMobile && t('QuestionAsk')}
          {hasFeaturedQuestion && <Style.WrapperDot />}
        </BtnSecondary>
      </Style.Wrapper>
    )
  }

  return <BtnPrimary onClick={() => toggleModal()}>{t('QuestionAsk')}</BtnPrimary>
}
