import jwtDecode from 'jwt-decode'
import { ConfigType } from './domains/types'
import * as constants from './constants'

/**
 * Devuelve el objeto que tiene las configuraciones de la implementación
 * por plataforma de los dominios
 *
 * @example
 * import getToken from '../getDomainConfig'
 * const decodedConfig = getDomainConfig()
 */
export const getDomainConfig = (): Partial<ConfigType> => {
  const root = document.getElementById('root')
  const codedDomain = root?.getAttribute('domainconfig')

  if (codedDomain) {
    try {
      const decodedDomain: ConfigType = jwtDecode(codedDomain)

      if (typeof decodedDomain.showHeaderLogo === 'string') {
        decodedDomain.showHeaderLogo = decodedDomain.showHeaderLogo === 'true'
      }
      if (typeof decodedDomain.coursesCampus === 'string') {
        decodedDomain.coursesCampus = decodedDomain.coursesCampus === 'true'
      }
      if (typeof decodedDomain.appMobile === 'string') {
        decodedDomain.appMobile = decodedDomain.appMobile === 'true'
      }
      if (typeof decodedDomain.apiURL !== 'string' || !decodedDomain.apiURL?.length) {
        decodedDomain.apiURL = constants.apiURL
      }
      return decodedDomain
    } catch {
      return {
        apiURL: constants.apiURL,
      }
    }
  } else {
    return {
      apiURL: constants.apiURL,
    }
  }
}

export const { apiURL } = getDomainConfig()
