import { useContext } from 'react'
import { Box, VStack, Heading, Text, Button } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import ReactHtmlParser from 'react-html-parser'
import { vars } from '@eclass/ui-kit'
import { createGlobalStyle } from 'styled-components'

import { Modal as UIModal } from 'app/Components/UI'
import { modalInfo } from 'assets/svg'
import { ProvisionalContext } from '../../context/ProvisionalContext'
import { useContainer } from './useContainer'

export const Modal = () => {
  const { t } = useTranslation()

  const { status, closeModal } = useContext(ProvisionalContext)

  const content = useContainer()

  return (
    <UIModal type="info" isOpen={status} toggle={closeModal} closeModal={closeModal}>
      <ModalStyleReset />
      <VStack p="2rem" textAlign="center" gap="1rem">
        <img alt="Icono de informacion" src={modalInfo} />
        <Heading
          fontSize="1.125rem"
          m="0!important"
          sx={{
            '@media screen and (min-width: 769px)': {
              fontSize: '1.25rem',
            },
          }}
        >
          {content.title}
        </Heading>
        <Text fontSize="1rem!important" m="0!important">
          {ReactHtmlParser(content.description)}
        </Text>
      </VStack>
      <Box
        as="footer"
        p="0"
        borderTop={`1px solid ${vars('colors-neutral-platinum')}`}
        cursor="pointer"
      >
        <Button
          w="100%"
          color={vars('colors-main-deepSkyBlue')}
          fontWeight="500"
          fontSize="1rem"
          p="1rem"
          height="auto"
          bg="transparent"
          onClick={closeModal}
          _hover={{ bg: 'transparent' }}
          _focus={{ border: 0 }}
          _active={{ bg: 'transparent' }}
        >
          {/* Si se requiere cambiar el significado de la siguiente traducción, se debe crear una llave nueva */}
          {t('CEVUnderstand')}
        </Button>
      </Box>
    </UIModal>
  )
}

const ModalStyleReset = createGlobalStyle`
  .modal{
    padding: 1rem;

    .modal-dialog{
      margin-left: 0;

      .modal-content{
        margin: 0 auto;
        max-width: 36.75rem!important;
        width: 100%;

        .close-modal {
          height: 10px;
          right: 17px;
          top: 17px;
          width: 10px;

          path{
            fill: #60798E; // vars('colors-main-blueGrey')
          }
        }
      }
    }



    @media screen and (min-width: 769px){
      .modal-dialog{
        margin-left: auto;
      }
    }
  }
`
