import { analytic } from 'app/Services'

import * as Styles from './style'
import * as Types from './types'

/**
 * Dibujo el logo del branding,
 * si este posee mas de uno estos igual los dibujo
 * @example <BrandingLogos logos={[]} />
 */
export const BrandingLogos = ({ defaultLogo, logos, sameSize }: Types.BrandingLogos) => {
  /**
   * si es un solo logo, lo dejo en grande menos dentro de /courses
   */
  const isMain = (index: number) => {
    if (!sameSize && index === 0 && logos.length === 1) {
      return 'main'
    }
    return ''
  }

  if (logos?.length === 0 && !defaultLogo) {
    return null
  }

  const onClick = () => {
    analytic.customEvent({
      category: 'demo category',
      action: 'demo action',
      value: 'demo valor',
    })
  }

  return (
    <Styles.Wrapper data-testid="brandingLogos" onClick={onClick}>
      {logos && logos.length > 0 ? (
        logos.map((logo: Types.BrandingLogoAttributes, index: number) => (
          <figure data-testid="brandingImages" className={isMain(index)} key={logo.name}>
            <img src={logo.url} alt={logo.name} />
          </figure>
        ))
      ) : (
        <figure className="main">
          <img src={defaultLogo} alt="Logo" data-testid="defaultLogo" />
        </figure>
      )}
    </Styles.Wrapper>
  )
}
