import { exerciseWithAQuestion } from 'app/Views/Tests/Questions/QuestionWrapper'
import { multipleAnswer, reorder } from 'app/Views/Tests/Questions/QuestionWrapper/idsQuetionTypes'

export const handleInitialAnswers = ({ data, store }: any) => {
  if (exerciseWithAQuestion.includes(data.config.ItemType.id)) {
    const answers = JSON.parse(data.answer)
    return answers.map((answer: any, index: number) => {
      answer.answerId = index
      return answer
    })
  }
  return store.map((answer: any, index: number) => {
    if (data.answered) {
      const answersOption = JSON.parse(data.answer)

      // si es checkbox seteo opciones con los valores seleccionados
      if (data.config.ItemType === multipleAnswer) {
        let myoption: any = []
        answersOption.forEach((item: any) => {
          if (answer.answerId === item.answerId) {
            myoption = item.options
          }
          return item
        })
        answer.options = myoption
      }
    }
    if (data.config.ItemType.id === reorder) {
      // respuestas guardadas
      const answersOption = JSON.parse(data.answer)
      if (answersOption.length > 0) {
        // si tiene datos guardados en la BDD
        const content = JSON.parse(data.content)
        const { correct, items: options } = answersOption[index]
        if (correct) {
          answer = {
            correct,
            options,
            tipo_respuesta: content[index].tipo_respuesta,
          }
          answer.options.forEach((item: any) => {
            item.selected = true
          })
        } else {
          answer.correct = false
          answer.options = content[index].options
        }
      } else {
        answer.options.forEach((item: any) => {
          item.selected = false
        })
      }
    }
    delete answer.answerTypeId
    return answer
  })
}
