import { EvaluationCreatePayload, EvaluationAnswerPayload, Indicator } from '@eclass/api'

type Props = {
  answer: EvaluationAnswerPayload
  resourceId: number
}

/**
 * Da por finalizado el test y lo cambia al estado completado = true y state = 3.
 */
export const updateMenu = (testState: EvaluationCreatePayload, props: Props) => {
  const links = testState.Evaluation?.Menu?.Links?.map((link) => {
    if (link.id === props.resourceId) {
      link.indicators = link.indicators?.map((indicator) => parseIndicator(indicator, props.answer))
      link.Childrens = link.Childrens?.map((child) => {
        if (child.id === props.answer?.Answer?.config?.id) {
          child.indicators?.map((indicator) => {
            return parseIndicator(indicator, props.answer, true)
          })
        }
        return { ...child }
      })
    }
    return { ...link }
  })

  if (testState.Evaluation?.Menu?.Links) {
    testState.Evaluation.Menu.Links = links!
  }

  return {
    ...testState,
  }
}

/**
 * Parse indicador
 * @param {Indicator} indicator - Indicador a actualizarse.
 * @param {EvaluationAnswerPayload} answer - Pregunta con nuevos valores.
 */
const parseIndicator = (indicator: Indicator, answer: EvaluationAnswerPayload, isItem = false) => {
  const STAR_INDICATOR = 3
  if (indicator.id === STAR_INDICATOR) {
    let obtained = isItem
      ? answer?.Answer?.config?.indicator?.obtained!
      : indicator.obtained! + answer?.Answer?.config?.indicator?.obtained!
    const max = indicator.max!

    if (obtained > max) {
      obtained = max
    }

    indicator.name = `${obtained}/${max}`
    indicator.obtained = obtained
  }
  return { ...indicator }
}
