export const ProfileIcon = (props) => {
  return (
    <path
      {...props}
      d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M12.1,12.3c-0.4-0.7-1.2-1-2.1-1.3
	  c-0.7-0.2-0.9-0.6-1-1.2c1.1-0.4,2-1.5,2-2.8c0-1.7-1.3-3-3-3S5,5.3,5,7c0,1.3,0.8,2.4,2,2.8c-0.1,0.6-0.3,1-1,1.2
	  c-0.9,0.3-1.7,0.6-2.1,1.3C2.7,11.2,2,9.7,2,8c0-3.3,2.7-6,6-6s6,2.7,6,6C14,9.7,13.3,11.2,12.1,12.3z"
    />
  )
}
