import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { exerciseWithAQuestion } from 'app/Views/Tests/Questions/QuestionWrapper'
import { handleInitialAnswers } from './handleInitialAnswers'
import {
  reorder,
  textAndMultimedia,
} from 'app/Views/Tests/Questions/QuestionWrapper/idsQuetionTypes'
import { Types } from './types'
import { useRedo } from './hooks/useRedo'

/**
 * Hook exercises
 */
const useExercise = () => {
  const state = useSelector((store: Types.Store) => store.exercises)
  const dispatch = useDispatch()

  const { redo } = useRedo()

  /**
   * Guarda respuestas en store.answers; en caso Reorder lo guarda en store.reorder
   */
  const handleDispatch = useCallback(
    (data: any) => {
      // si es tipo reorder se debe guardar de manera diferente(en su espacio especifico de la store)
      if (state.answerType.id === reorder) {
        dispatch({
          type: 'EXERCISE_UPDATE',
          payload: { reorder: data },
        })
      } else {
        const upgradeData = Array.from(state.answers!).map((item: any) => {
          if (item.answerId === data.answerId) {
            if (exerciseWithAQuestion.includes(state.answerType.id)) {
              item = data
            } else {
              item.answers = data.answers
            }
          }
          return item
        })
        dispatch({
          type: 'EXERCISE_SET',
          payload: upgradeData,
        })
      }
    },
    [state, dispatch]
  )

  /**
   * Retorna el número de correctas
   * @param answers
   * @param Answer
   */
  const handleCorrect = (answers: any, Answer: Types.Answer) => {
    return answers.filter(({ correct }: any) => correct).length
  }

  /**
   * Setea el estado con los nuevos parametros,
   * una vez que se guarda la(s) respuesta(s)
   */
  const verify = useCallback(
    ({ Answer, CallToAction }: any) => {
      let answerUpdate: Types.Answer[]
      if (
        exerciseWithAQuestion.includes(state.answerType.id) &&
        state.answerType.id !== textAndMultimedia
      ) {
        const re = JSON.parse(Answer.answer)
        answerUpdate = Array.from(re!).map((item: any, index: number) => {
          item.answerId = index
          return item
        })
      } else {
        if (state.answerType.id === reorder) {
          // Valores que guardo los paso a formato de datos iniciales
          const content = JSON.parse(Answer.content)
          const ans = JSON.parse(Answer.answer)
          answerUpdate = Array.from(content!).map((item: any, index: number) => {
            item.options = ans[index].items
            item.correct = ans[index].correct
            return item
          })
        } else if (state.answerType.id === textAndMultimedia) {
          answerUpdate = []
        } else {
          const re = JSON.parse(Answer.content)
          answerUpdate = Array.from(re!).map((item: any) => {
            re.forEach((jo: any) => {
              if (item.answerId === jo.answerId) {
                item.correct = jo.correct
              }
            })
            return item
          })
        }
      }
      dispatch({
        type: 'EXERCISE_UPDATE',
        payload: {
          answered: true,
          send: false,
          answers: answerUpdate,
          hint: JSON.parse(Answer.feedback.hint),
          deleteIncorrect: false,
          score: { ...Answer.config.indicator },
          callToAction: CallToAction ? CallToAction.href : null,
          alert: true,
          answer: Answer,
          feedback: {
            correct: handleCorrect(answerUpdate, Answer),
            total: answerUpdate.length,
          },
        },
      })
    },
    [dispatch, state.answerType]
  )

  /**
   * Guardo el estado inicial de la pregunta en la store
   */
  const initialState = useCallback(
    ({ Answer, CallToAction, Evaluation }: any) => {
      let setScore = {
        obtained: 0,
        max: 0,
      }
      if (Evaluation?.config?.indicator) {
        const { max, obtained } = Evaluation?.config?.indicator
        setScore = { obtained, max }
      }

      if (Answer.config.ItemType.id !== textAndMultimedia) {
        const answers = JSON.parse(Answer.content)

        /**
         * solo si es reorder sino retorno []
         * si tengo data inicial y esta correcta retorno los "codes" correspondientes
         */
        const setCodesReorder = () => {
          const codes: any = []
          if (Answer.config.ItemType.id === reorder) {
            const elements = JSON.parse(Answer.answer)
            elements.forEach((element: any) => {
              const innerCodes: any = []
              if (element.correct) {
                element.items.forEach((item: any) => {
                  innerCodes.push(item.code)
                })
              }
              codes.push(innerCodes)
            })
          }
          return codes
        }

        dispatch({
          type: 'EXERCISE_INITIAL',
          payload: {
            answerType: Answer.config.ItemType,
            answers: handleInitialAnswers({ data: Answer, store: answers }),
            answered: Answer.answered,
            hint: JSON.parse(Answer.feedback.hint),
            callToAction: CallToAction ? CallToAction.href : null,
            score: { ...Answer.config.indicator },
            embeds: Answer.embeds,
            feedback: {
              correct: 0,
              total: answers.length,
            },
            reorder: setCodesReorder(),
            setScore,
          },
        })
      } else {
        dispatch({
          type: 'EXERCISE_INITIAL',
          payload: {
            answerType: Answer.config.ItemType,
            answers: {
              data: Answer,
              store: Answer.content,
            },
            answered: Answer.answered,
            hint: JSON.parse(Answer.feedback.hint),
            callToAction: CallToAction ? CallToAction.href : null,
            score: { ...Answer.config.indicator },
            embeds: Answer.embeds,
            feedback: {
              correct: 0,
              total: Answer.length,
            },
            reorder: null,
            setScore,
          },
        })
      }
    },
    [dispatch]
  )

  /**
   * se encarga de resetear los datos en la store
   */
  const clean = useCallback(() => {
    dispatch({
      type: 'EXERCISE_EXIT',
    })
  }, [dispatch])

  /**
   * cambia el estado de ver correctas en la store
   */
  const see = useCallback(
    (payload) => {
      dispatch({
        type: 'EXERCISE_SEE',
        payload,
      })
    },
    [dispatch]
  )

  /**
   * cambia el estado de enviar en la store
   */
  const send = useCallback(
    (config?: string) => {
      dispatch({
        type: 'EXERCISE_SEND',
        payload: {
          status: true,
          type: config || '',
        },
      })
    },
    [dispatch]
  )

  /**
   * Cierra el alert que muestra las estrellas
   */
  const closeAlert = useCallback(() => {
    const element: any = document.getElementById('alert')
    if (element) {
      element.parentNode.removeChild(element)
    }
    dispatch({
      type: 'EXERCISE_ALERT_CLOSE',
    })
  }, [dispatch])

  /** Manejo si es que no se llega guardar la respuesta */
  const errorSave = useCallback((status) => {
    // Esto quita el loading del button
    dispatch({
      type: 'EXERCISE_REDO',
    })
    // Esto levanta alert con el error
    dispatch({
      type: 'SET_ERROR',
      payload: {
        ...status,
        detail: 'Ha ocurrido un error al guardar la respuesta. Inténtalo más tarde.',
      },
    })
  }, [])

  return {
    state,
    dispatch: handleDispatch,
    clean,
    initialState,
    see,
    redo,
    verify,
    send,
    closeAlert,
    errorSave,
  }
}

export default useExercise
