// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { axiosError, getToken } from '../../../Services'
import { GetScheduleInput } from '@eclass/api'

/**
 * Obtiene el calendario para agendar
 *
 * @example
 * ```
 *   await getUnitSchedule(this.props.config)
 * ```
 */
const getUnitSchedule = (input: GetScheduleInput) => {
  const requestId = 'getUnitSchedule'
  return async (dispatch: any) => {
    getToken()
    const query = `
      query getUnitSchedule($UnitGetScheduleInput: UnitGetScheduleInput!) {
        getUnitSchedule(input: $UnitGetScheduleInput) {
          status {
            code
            name
            detail
            success
          }
          week{
            date
            availables
            blocks{
              start
              startUTC
              end
              endUTC
              available
              rawStart
              startDate
            }
          }
          data{
            previousMonday
            nextMonday
            remaining
            duration
          }
          Menu{
            Navigation {
              prev {
                href
                name
                status {
                  id
                  detail
                  style
                  enabled
                }
              }
              next {
                href
                name
                status {
                  id
                  detail
                  style
                  enabled
                }
              }
            }
            Links {
              id
              name
              href
              indicators {
                id
                name
                image
                obtained
                max
              }
              status {
                id
                name
                detail
                style
                enabled
              }
              Childrens {
                id
                name
                href
                status {
                  id
                  name
                  detail
                  style
                  enabled
                }
                indicators {
                  id
                  name
                  image
                  obtained
                  max
                }
              }
            }
            Unit {
              id
              name
              title
            }
          }
        }
      }

    `
    const variables = {
      UnitGetScheduleInput: input,
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response: any) => {
        return response.data.data.getUnitSchedule
      })
      .catch((err: any) => {
        axiosError(err, requestId, dispatch)
      })
  }
}
export default getUnitSchedule
