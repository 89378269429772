import { CEVReducerAction, CEVReducer } from './types'
import { initialState } from './initialState'

/**
 * Guarda el estado del modal de los comentarios
 */
export const cev = (state: CEVReducer = initialState, action: CEVReducerAction): any => {
  switch (action.type) {
    case 'CEV_FEEDBACK':
      return { ...state, feedback: { ...state.feedback, ...action.payload } }
    case 'CEV_FEEDBACK_RESET':
      return { ...state, feedback: {} }
    case 'CEV_FEEDBACK_SENDING':
      return { ...state, feedback: { ...state.feedback, sending: action.payload } }
    case 'CEV_FEEDBACK_FORCE_MODAL':
      return {
        ...state,
        feedback: {
          ...state.feedback,
          forceModal: action.payload,
        },
      }
    default:
      return state
  }
}
