import React from 'react'
import styled from 'styled-components/macro'
import Select, { components } from 'react-select'

import { Icon } from 'app/Components/UI/Icons'
import { Types } from './types'

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <Icon name="filterNewOld" />
  </components.DropdownIndicator>
)

const SelectedOption = (props: any) => (
  <components.SingleValue {...props}>
    <SingleValueStyle>
      <Icon name="filterNewOld" />
      {props.data.name}
    </SingleValueStyle>
  </components.SingleValue>
)

const ShowIcon = (props: any) => (
  <components.Option {...props}>
    <OptionStyle>
      <Icon name="justCheck" />
      {props.data.name}
    </OptionStyle>
  </components.Option>
)

export const CustomFilter = ({
  options,
  placeholder,
  value,
  onChange,
  disabled,
  name,
}: Types.Filter) => {
  const styles = {
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: (provided) => ({
      ...provided,
      background: '#e0eefa',
      width: 157,
      height: 30,
      border: 'none',
      fontSize: 14,
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      color: '#0189ff',
      cursor: 'pointer',
      boxShadow: 'none',
      float: 'left',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: '#0189ff',
      marginLeft: 20,
    }),
    menu: (provided, state) => ({
      ...provided,
      marginTop: '2px',
      zIndex: 9999,
      top: 38,
      width: 162,
      background: '#ffffff',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      maxHeight: 37,
      maxWidth: 162,
      fontSize: 14,
      fontFamily: 'Roboto',
      background: '#ffffff',
      color: state.isSelected ? '#0189ff' : 'unset',
      '&:hover': {
        background: '#e0eefa',
        color: '#0189ff',
      },
      cursor: 'pointer',
      boxShadow: 'none',
      div: {
        svg: {
          height: 'unset',
          width: 'unset',
          margin: 0,
          visibility: state.isSelected ? 'unset' : 'hidden',
        },
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#0189ff',
      display: 'inline-flex',
      svg: {
        height: 'unset',
        width: 'unset',
        margin: 0,
      },
      div: {
        width: '100%',
      },
    }),
    input: (provided, state) => ({
      ...provided,
      color: '#0189ff',
      marginLeft: -78,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      justifyContent: 'center',
    }),
  }

  return (
    <Select
      data-testid="select"
      name={name}
      options={options}
      onChange={onChange}
      // @ts-ignore
      value={value}
      isSearchable={false}
      // @ts-ignore
      getOptionLabel={(option) => option.name}
      // @ts-ignore
      getOptionValue={(option) => option.id}
      components={{
        DropdownIndicator,
        Option: ShowIcon,
        SingleValue: SelectedOption,
      }}
      styles={styles}
    />
  )
}

export const OptionStyle = styled.div`
  display: flex;
  height: 21px;
  width: 138px;
  svg {
    visibility: hidden;
    margin-right: 2px !important;
  }
`
export const SingleValueStyle = styled.div`
  height: 21px;
  width: 105px;
`
