import axios, { AxiosRequestConfig } from 'axios'

import { apiURL } from '../../../../Config/getDomainConfig'
import { getToken, axiosError } from '../../../Services'
import { retryRequest } from 'app/Views/Tests/Questions/QuestionTypes/Cloze/services/retryRequest'

export interface FilterType {
  /**
   * Programa
   */
  programId: number
  /**
   * Alumno Curso
   */
  studentId: number
  /**
   * Curso
   */
  courseId: number
  /**
   * Unidad
   */
  unitId: number
  /**
   * Recurso
   */
  resourceId: number
  /**
   * Id Respuesta
   */
  answerId?: number
  /**
   * Id Evaluacion
   */
  evaluationId: number
  /**
   * Opción seleccionada por el alumno
   */
  response?: string
  /**
   * Archivo
   */
  file?: any
}

/**
 * Permite guardar una respuesta de un alumno.
 *
 * @example
 *
js
 *  const params = { programId, studentId, courseId, unitId, resourceId, answerId, response }
 *  const response = await EvaluationAnswer(params)
 *

 * @returns Guarda y evalú una respuesta.
 */
const EvaluationAnswer = (input: FilterType, file?: any) => {
  const requestId = 'EvaluationAnswer'
  return async (dispatch: any) => {
    getToken()

    const query = `
      mutation EvaluationAnswer($EvaluationAnswerInput: EvaluationAnswerInput!) {
        EvaluationAnswer(input: $EvaluationAnswerInput) {
          status {
            success
            name
            detail
            code
            refCode
          }
          CallToAction{
            href
          }
          Answer{
            answer
            config {
              id
              ItemType {
                id
                name
              }
              indicator{
                name
                obtained
                max
              }
            }
            feedback{
              hint
            }
            content
          }
          pendingAnswers{
            answered
            total
            pending
            pendings
          }
        }
      }
`

    const variables = {
      EvaluationAnswerInput: input,
    }

    if (file) {
      const form = new FormData()
      variables.EvaluationAnswerInput.file = {
        id: 1,
        type: 'ANSWER',
        name: '',
        file: undefined,
      }

      const operations = { query, variables }
      form.append('operations', JSON.stringify(operations))
      const map = {
        0: ['variables.EvaluationAnswerInput.file.file'],
      }
      form.append('map', JSON.stringify(map))
      form.append('0', file)
      return axios({
        url: `${apiURL}/graphql`,
        method: 'POST',
        data: form,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then((response: any) => response.data.data.EvaluationAnswer)
        .catch((err) => axiosError(err, requestId, dispatch))
    }

    if (input.response && /"configId":.*,"response":/.test(input.response)) {
      // CASO CLOSE HACER REINTENTOS SI LA PETICION NO RESPONDE
      const config: AxiosRequestConfig = {
        method: 'POST',
      }
      return retryRequest(`${apiURL}/graphql`, { query, variables }, config)
        .then((response) => response)
        .catch((err) => axiosError(err, requestId, dispatch))
    } else {
      return axios
        .post(`${apiURL}/graphql`, {
          query,
          variables,
        })
        .then((response: any) => response.data.data.EvaluationAnswer)
        .catch((err) => axiosError(err, requestId, dispatch))
    }
  }
}

export default EvaluationAnswer
