import styled, { css } from 'styled-components/macro'

import { layout } from '../Common'

export const Wrapper = styled.div`
  ${layout(css`
    .alertContainer {
      grid-area: alertContainer;
    }
    .profile-isNotJob {
      grid-area: isNotJob;
    }
    .profile-jobTypeId {
      grid-area: jobTypeId;
    }
    .profile-headingId {
      grid-area: headingId;
    }
    .profile-executivePositionId {
      grid-area: executivePositionId;
    }
    .profile-position {
      grid-area: position;
    }
    .profile-name {
      grid-area: name;
    }
    .profile-industryId {
      grid-area: industryId;
    }
    .profile-startDate {
      grid-area: startDate;
    }
    .profile-endDate {
      grid-area: endDate;
    }
    .profile-current {
      grid-area: current;
    }
    .profile-private {
      grid-area: private;
    }

    grid-template-areas:
      'isNotJob .'
      'jobTypeId headingId'
      'executivePositionId position'
      'name name'
      'industryId .';

    &.profile {
      grid-template-areas:
        'jobTypeId headingId'
        'executivePositionId position'
        'name name'
        'industryId industryId'
        'startDate endDate'
        'current current'
        'private private';
    }
    &.noJob {
      max-height: 17px;
      overflow-y: hidden;
    }
  `)}
`
