import React from 'react'
import { FormProvider } from 'react-hook-form'

import { Modal } from 'app/Components/UI'
import { ProfileForms } from 'app/Components'
import { FooterActions } from '../Utils'
import { useTimeZoneInfo } from './useTimeZoneInfo'
import * as Style from '../style'
import { Types } from '../../types'

export const TimeZoneInfoModal = ({
  isOpen,
  toggleModal,
  modalName,
  context = 'PROFILE',
}: Types.ModalProps) => {
  const inCoursesContentProps = {
    PROFILE: { cancel: toggleModal },
    WITH_OUT: { showCancel: false },
    TO_CHANGE: { toggle: undefined, closeModal: undefined, btnClose: false, keyboard: false },
  }

  return (
    <Modal
      type="full"
      isOpen={isOpen}
      toggle={toggleModal}
      closeModal={toggleModal}
      header={modalName}
      {...inCoursesContentProps[context!]}
    >
      <ModalContent toggleModal={toggleModal} context={context!} />
    </Modal>
  )
}

type props = {
  toggleModal?: any
  context: Types.ModalProps['context']
}

export const ModalContent = ({ toggleModal, context = 'PROFILE' }: props) => {
  const { methods, onSubmit, status } = useTimeZoneInfo({ toggleModal })

  const inCoursesContentProps = {
    PROFILE: { cancel: toggleModal },
    WITH_OUT: { showCancel: false },
    TO_CHANGE: { cancel: toggleModal },
  }

  const loading = status === 'LOADING'

  return (
    <Style.WrapperForm>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ProfileForms.TimeZone profile isSubmit={loading} context={context} />
          <FooterActions loading={loading} {...inCoursesContentProps[context]} />
        </form>
      </FormProvider>
    </Style.WrapperForm>
  )
}
