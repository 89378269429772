import React from 'react'
import { useTranslation } from 'react-i18next'

import { Select, Input, CheckBox } from '../Common'
import { useLogic } from './useLogic'
import * as Style from './style'
import * as Types from '../types'

/**
 * Campos para agregar número téfonico
 */
export const Academic = ({ profile = false, isSubmit = false }: Types.formBase) => {
  const { t } = useTranslation()
  const {
    institutionsTypes,
    countriesList,
    institutions,
    studyLevels,
    clean,
    getInstitutions,
    blockedCountries,
    blockedInstitution,
    isSelectInstitution,
  } = useLogic(profile)

  return (
    <Style.Wrapper
      className={`
      ProfileForms-Academic
      ${profile ? 'profile' : ''}
      `}
    >
      {profile && (
        <React.Fragment>
          {/* TIPO DE INSTITUCIÓN */}
          <Select
            name="institutionTypeId"
            label={t('ProfileInstitutionType')}
            placeholder={t('ProfileSelectInstitution')}
            options={institutionsTypes}
            onChange={clean}
            disabled={isSubmit}
          />
          {/* PAÍS */}
          <Select
            name="countryId"
            label={t('ProfileCountry')}
            placeholder={t('ProfileSelectCountry')}
            disabled={blockedCountries || isSubmit}
            options={countriesList}
            onChange={getInstitutions}
          />
          {/* INSTITUCIÓN */}
          {/* Si tiene opciones de instituciones de muestra el select si no el input */}
          <div
            className={`profile-institutionId-otherInstitution ${
              !isSelectInstitution ? 'otherInstitution' : ''
            }`}
          >
            <Select
              name="institutionId"
              label={t('ProfileInsitution')}
              placeholder={t('ProfileSelectInstitution')}
              disabled={blockedInstitution || isSubmit}
              options={institutions}
              required={isSelectInstitution}
            />
            <Input
              name="otherInstitution"
              label={t('ProfileInsitution')}
              placeholder={t('ProfileSelectInstitution')}
              disabled={blockedInstitution || isSubmit}
              type="NOVALIDATE"
              required={!isSelectInstitution}
            />
          </div>
        </React.Fragment>
      )}
      {/* NIVEL DE ESTUDIO */}
      <Select
        name="studyType"
        label={t('ProfileLevelStudy')}
        placeholder={t('ProfileSelectLevel')}
        options={studyLevels}
        disabled={isSubmit}
      />
      {profile && (
        <React.Fragment>
          {/* CARRERA */}
          <Input
            name="career"
            type="NOVALIDATE"
            label={t('ProfileField')}
            placeholder={t('ProfileEnterField')}
            disabled={isSubmit}
          />
          {/* AÑO DE PROMOCIÓN */}
          <Input
            name="promotion"
            type="NUMBERS"
            label={t('ProfileGraduation')}
            placeholder={t('ProfileEnterGraduation')}
            disabled={isSubmit}
          />
          {/* PRIVADA */}
          <CheckBox name="isPrivate" text={t('ProfilePrivateInfo')} disabled={isSubmit} />
        </React.Fragment>
      )}
    </Style.Wrapper>
  )
}
