import * as React from 'react'
import { useSelector } from 'react-redux'

import { UnitBlockItem } from '@eclass/api'
import { Store } from 'app/Redux/types'
import Types from './types'

interface usePrerequisitesProps {
  prerequisites: UnitBlockItem['Requirements']
}

export const usePrerequisites = ({ prerequisites }: usePrerequisitesProps) => {
  const { getUnitList } = useSelector((state: Store) => state.units)

  const prerequisitesIds = React.useCallback(
    () => prerequisites?.map((prerequisite) => prerequisite.resourceRequiredId),
    [prerequisites]
  )

  const prerequisitesStatus = React.useCallback(() => {
    const prerequisitesWithStatus: Types.Prerequisites[] = []

    getUnitList?.Units?.forEach((unit) => {
      if (unit.items && unit.items.length > 0) {
        unit.items.forEach((item) => {
          if (prerequisitesIds()?.includes(item.id)) {
            prerequisitesWithStatus.push({
              id: item.id,
              name: item.name,
              completed: item.completed,
              href: item.href,
            })
          }
        })
      }
    })

    return prerequisitesWithStatus
  }, [prerequisites])

  return {
    prerequisitesStatus: prerequisitesStatus,
  }
}
