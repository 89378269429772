import styled from 'styled-components/macro'

import { UIVars } from 'app/Components/UI'

export const Wrapper = styled.div`
  input {
    height: 48px;
    width: -webkit-fill-available;
    animation-timing-function: 0s;

    &[disabled] {
      border: 1px solid hsl(0, 0%, 90%);
      color: ${UIVars.setColors.brownGreyTwo};
      cursor: no-drop;
    }

    ::placeholder {
      color: ${UIVars.setColors.brownGreyTwo};
      font-size: 1rem;
      font-style: normal;
    }

    :active {
      border-width: 1px;
    }
  }

  .optional {
    margin-left: 8px;
    color: ${UIVars.setColors.brownGreyTwo};
    font-size: 14px;
    font-weight: 500;
  }

  .form-control {
    color: ${UIVars.setColors.black};
  }

  .form-control:focus {
    background-color: #fff;
    border: solid 1px ${UIVars.setColors.deepSkyBlue};
    box-shadow: none;
    color: ${UIVars.setColors.black};
    outline: 0;
  }

  &.status-error {
    input {
      border: 1px ${UIVars.setColors.checkError} solid;
    }
  }
`
