import React from 'react'
import { useFormContext, Controller, Validate as TypeValidate } from 'react-hook-form'
import { Input as BaseInput } from 'reactstrap'
import ReactHtmlParser from 'react-html-parser'

import { Label, handleRequired, TypeError } from '../'
import { useValidations, TypesInput } from './useValidations'
import * as Style from './style'

type props = {
  type?: TypesInput
  disabled?: boolean
  name: string
  placeholder?: string
  label?: string
  required?: boolean
}

type typeRequired = {
  value: boolean
  message: string
}

type TypeRules = {
  required: typeRequired | boolean
  pattern?: {
    value: RegExp
    message: string
  }
  validate?: TypeValidate
}

export const Input = ({
  type = 'ALPHANUMERIC',
  disabled = false,
  name,
  placeholder,
  label,
  required,
}: props) => {
  const { control, formState, errors } = useFormContext()

  let error = errors[name]?.message

  const { regExp, text: textValidation, htmlType, validate } = useValidations(type)

  const statusRequired = handleRequired(required)

  const rules: TypeRules = { required: statusRequired }

  if (regExp && textValidation) {
    rules.pattern = { value: regExp, message: textValidation }
  }

  if (validate && textValidation) {
    rules.validate = validate
  }

  if (errors[name]?.type === 'validate') {
    error = ReactHtmlParser(textValidation!)
  }

  const showError = statusRequired !== false
  const classStatus = showError && error ? 'status-error' : ''
  /**
   * Esto valida si estoy en un test de nivel con alumno anónimo
   */
  const isLevelTest = window.location.href.includes('test-level')

  return (
    <Style.Wrapper className={`profile-${name} ${classStatus}`}>
      {label && <Label htmlFor={name}>{label}</Label>}
      {!required && isLevelTest && <span className="optional">(opcional)</span>}
      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={rules}
        render={({ value, onChange }) => (
          <BaseInput
            name={name}
            value={value}
            type={htmlType}
            disabled={disabled || formState.isSubmitting}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder || name}
          />
        )}
      />
      {showError && <TypeError error={error} />}
    </Style.Wrapper>
  )
}
