import React from 'react'

export const MaterialSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="30"
    data-testid="MaterialIcon"
    viewBox="0 0 32 30"
  >
    <g fill="#E0EEFA" fillRule="nonzero" data-testid="MaterialSvg">
      <path d="M16 18.236L4.764 12.618 0 15l16 8 16-8-4.764-2.382z" />
      <path d="M16 25.236L4.764 19.618 0 22l16 8 16-8-4.764-2.382zM16 0L0 8l16 8 16-8z" />
    </g>
  </svg>
)
