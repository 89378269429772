import React from 'react'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'

import * as Apollo from 'app/Apollo'
import { TypesInput } from '../Common/Input/useValidations'
import { useSetSelect } from '../Common'
import { Types } from '../types'

type Typesdocuments = {
  label: string
  placeholder: string
  type: TypesInput
}

export const useDocuments = () => {
  const { t } = useTranslation()
  const {
    loggedUser: { user },
  } = useSelector((state: Types.Store) => state)
  const { getValues } = useFormContext()
  const [isBloqued, setIsBloqued] = React.useState(false)
  const [typeDocument, setTypeDocument] = React.useState<Typesdocuments>({
    label: t('ProfileDocuNumber'),
    placeholder: t('ProfileEnterDocument'),
    type: 'NOVALIDATE',
  })
  const [state, setState] = React.useState<Types.select[]>([])

  const { data } = useQuery<Apollo.ALL_IDENTIFICATION_DOCUMENTS_TYPE>(
    Apollo.ALL_IDENTIFICATION_DOCUMENTS
  )

  const { setOption } = useSetSelect(state, 'documentNumberId')

  const handleBloqued = React.useCallback(() => {
    const documentNumberId = user?.documentNumberId
    const documentNumber = user?.documentNumber || ''
    if (documentNumberId !== null && documentNumber.length > 0) {
      setIsBloqued(true)
    }
  }, [user])

  const load = React.useCallback(async () => {
    const list = data?.allIdentificationDocuments.map((x) => {
      return {
        label: x.name,
        value: x.id,
      }
    })
    if (list) {
      setState(list)
      setOption(list)
      handleBloqued()
    }
  }, [data])

  React.useEffect(() => {
    if (data && data?.allIdentificationDocuments?.length > 0 && state.length === 0) {
      load()
    }
  }, [data, state])

  const handleTypeDocument = (name: string) => {
    if (name && getValues()[name].label !== typeDocument) {
      let newType: TypesInput = 'NOVALIDATE'
      let placeholder = t('ProfileAddNumber', {
        value: getValues()[name].label,
      })
      switch (getValues()[name].label) {
        case 'Rut':
          newType = 'RUT'
          placeholder = t('LogInRUT')
          break
        case 'Email':
          newType = 'EMAIL'
          placeholder = t('ProfileAddAdress', {
            value: getValues()[name].label,
          })
          break
        default:
          newType = 'NOVALIDATE'
          break
      }
      setTypeDocument({
        label: getValues()[name].label,
        placeholder,
        type: newType,
      })
    }
  }

  return { documents: state, isBloqued, typeDocument, handleTypeDocument }
}
