import React from 'react'
import styled from 'styled-components/macro'

type props = {
  children: JSX.Element | JSX.Element[] | string
  htmlFor: string
  className?: string
}

export const Label = ({ children, htmlFor, className }: props) => {
  return (
    <Wrapper className={className || ''} htmlFor={htmlFor}>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.label`
  font-weight: bold;
  line-height: 1;
  margin-bottom: 10px;
`
