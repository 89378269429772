import { EvaluationCreatePayload } from '@eclass/api'
import { testsStates } from '../../testsStates'
import scrollmove from '@eclass/scrollmove'

/**
 * Al completar una Prueba disparamos este evento.
 */
const completed = (
  test: EvaluationCreatePayload,
  levelId: number,
  stageId: number,
  isEdit: boolean
) => {
  const stages = test.Evaluation!.Levels[levelId].Stages!

  /**
   * Si estoy editando la respuestas hacemos scroll a la primera pregunta del nivel/etapa.
   */
  if (isEdit) {
    const answers = stages[stageId].Answers!
    scrollmove(`#question-${answers[0].config!.id}`)
  }

  test.Evaluation!.config.state = testsStates.COMPLETED
  // @ts-ignore
  stages[stageId].state = testsStates.COMPLETED

  return { ...test }
}

export default completed
