import { useTranslation } from 'react-i18next'
import { Alert } from '@eclass/ui-kit'
import { Box } from '@chakra-ui/react'

type AlertTypes = {
  show: boolean
}

export const MandatoryAlert = ({ show }: AlertTypes) => {
  const { t } = useTranslation()

  if (show) {
    return (
      <Box
        sx={{
          p: {
            marginBottom: '0',
          },
        }}
      >
        <Alert state="error" m="20px auto 0">
          {t('ProfileMandatoryWarning')}
        </Alert>
      </Box>
    )
  }
  return null
}
