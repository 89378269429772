import * as React from 'react'

export const RequirementsIcon = () => (
  <svg
    width="45"
    height="45"
    role="RequirementsIcon"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
    <path
      d="M37 26H25C24.4477 26 24 26.4477 24 27V29C24 29.5523 24.4477 30 25 30H37C37.5523 30 38 29.5523 38 29V27C38 26.4477 37.5523 26 37 26Z"
      fill="#FFBC27"
    />
    <rect x="14" y="10" width="24" height="8" rx="2" fill="#FF554D" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36 12H9V16H36V12ZM9 10C7.89543 10 7 10.8954 7 12V16C7 17.1046 7.89543 18 9 18H36C37.1046 18 38 17.1046 38 16V12C38 10.8954 37.1046 10 36 10H9Z"
      fill="#08044F"
    />
    <path
      d="M37 20H25C24.4477 20 24 20.4477 24 21V23C24 23.5523 24.4477 24 25 24H37C37.5523 24 38 23.5523 38 23V21C38 20.4477 37.5523 20 37 20Z"
      fill="#0189FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 22C16 21.4477 16.4477 21 17 21L19 21C19.5523 21 20 21.4477 20 22C20 22.5523 19.5523 23 19 23L17 23C16.4477 23 16 22.5523 16 22Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 27C16 26.4477 16.4477 26 17 26L19 26C19.5523 26 20 26.4477 20 27C20 27.5523 19.5523 28 19 28L17 28C16.4477 28 16 27.5523 16 27Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 6C3.34315 6 2 7.34315 2 9V22C2 22.5523 2.44772 23 3 23C3.55228 23 4 22.5523 4 22V9C4 8.44772 4.44772 8 5 8H40C40.5523 8 41 8.44772 41 9V31C41 31.5523 40.5523 32 40 32H17H15C14.4477 32 14 32.4477 14 33C14 33.5523 14.4477 34 15 34H16V38H13C12.4477 38 12 38.4477 12 39C12 39.5523 12.4477 40 13 40H28H32C32.5523 40 33 39.5523 33 39C33 38.4477 32.5523 38 32 38H29V34H40C41.6569 34 43 32.6569 43 31V9C43 7.34315 41.6569 6 40 6H5ZM27 38V34H18V38H27Z"
      fill="#08044F"
    />
    <path
      d="M12.6883 28.6879H12.0745C11.9442 28.2065 11.7686 27.7693 11.5069 27.3311L11.9442 26.8939C12.4686 26.3694 12.4686 25.5381 11.9442 25.0568C11.4628 24.5754 10.5872 24.5754 10.1069 25.0568L9.66862 25.494C9.23139 25.2323 8.79415 25.0568 8.31277 24.9254V24.3137C8.31277 23.5696 7.74415 23 7 23C6.25692 23 5.68831 23.5696 5.68831 24.3137V24.9254C5.20692 25.0568 4.76861 25.2323 4.33138 25.494L3.89415 25.0568C3.41277 24.5754 2.53723 24.5754 2.05692 25.0568C1.53138 25.5823 1.53138 26.4136 2.05692 26.8939L2.49415 27.3321C2.23138 27.7693 2.05692 28.2065 1.92554 28.6879H1.31277C0.56969 28.6879 0 29.2564 0 30.0005C0 30.7436 0.568613 31.3132 1.31277 31.3132H1.92554C2.05692 31.7935 2.23138 32.2317 2.49415 32.6689L2.05692 33.1061C1.53138 33.6316 1.53138 34.463 2.05692 34.9432C2.53831 35.4246 3.41277 35.4246 3.89415 34.9432L4.33138 34.506C4.76861 34.7688 5.20692 34.9432 5.68831 35.0746V35.6873C5.68831 36.4304 6.25692 37 7 37C7.74308 37 8.31277 36.4314 8.31277 35.6873V35.0746C8.79415 34.9432 9.23139 34.7688 9.66969 34.506L10.1069 34.9432C10.5883 35.4246 11.4628 35.4246 11.9442 34.9432C12.4686 34.4188 12.4686 33.5875 11.9442 33.1061L11.5069 32.6689C11.7686 32.2317 11.9442 31.7935 12.0755 31.3121H12.6883C13.4314 31.3121 14 30.7436 14 30.0005C14 29.2575 13.4314 28.6879 12.6883 28.6879ZM6.85031 31.9507C5.67431 31.9507 4.75031 31.0257 4.75031 29.8509C4.75031 28.6739 5.67431 27.751 6.85031 27.751C8.02631 27.751 8.95031 28.6739 8.95031 29.8509C8.95031 31.0257 8.02631 31.9507 6.85031 31.9507Z"
      fill="#1EBDAF"
    />
  </svg>
)
