import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import MenuItemTest from './MenuItemTest'
import MenuItemResource from './MenuItemResource'
import MenuItemFeedBack from './MenuItemFeedBack'
import MenuItemAvatar from './MenuItemAvatar'
import MenuItemNavigation from './MenuItemNavigation'
import { MenuItemFeedBackLevels } from './MenuItemFeedBackLevel'
import { MenuItemLevel } from './MenuItemLevel'
import { ItemsProps } from './Types'
import { RouteParams } from 'Config/routes/types'
import { MenuItemHelpCenter } from './MenuItemHelpCenter'
import { getMenuItemProps } from './menuUtils'

/**
 * Este componente retorna el item del tipo de dropdown que corresponda
 * @example
 *  <Items
 *    dropdownType={dropdownType}
 *    items={items}
 *    resourceId={resourceId}
 *    activeQuestion={activeQuestion}
 *  />
 */
export const Items = ({
  dropdownType,
  items,
  activeQuestion,
  activeNavigation,
  setActive,
  setNavId,
  activeNavId = 0,
  level = 0,
  levels,
}: ItemsProps) => {
  const newItems: any = []
  const history = useHistory()
  const { t } = useTranslation()
  const { resourceId } = useParams<RouteParams>()
  const { answerId } = useParams<RouteParams>()

  /**
   * Obtenemos los items en caso de drop navegación ya que estos cambian según el usuario navega.
   * @todo Pasar el resultado a un useState [links] para evitar la sobrecarga, así se podría quitar la linea :43
   */
  const getActiveItem = useCallback(() => {
    if (activeNavigation && activeNavId) {
      const itemActive = items.find((item) => item.id === activeNavId)!

      if (itemActive?.Childrens) {
        if (itemActive.Childrens?.length === 0) {
          if (itemActive.id !== Number(resourceId) && itemActive.href!.length > 0) {
            history.push(itemActive.href!)
            return items
          } else {
            setActive(false)
            return items
          }
        } else if (itemActive.Childrens?.length > 0) {
          return itemActive.Childrens
        }
      } else {
        return []
      }
    } else {
      return items
    }
  }, [activeNavId, activeNavigation, history, items, setActive, resourceId])

  switch (dropdownType) {
    case 'materials':
      items.map((resource: any, index: number) => {
        return newItems.push(<MenuItemResource link={resource} index={index} key={index} />)
      })
      break
    case 'test':
      items.map((resource: any, index: number) => {
        return newItems.push(
          <MenuItemTest
            activeQuestion={activeQuestion}
            key={resource.config.id}
            number={index}
            answer={resource}
          />
        )
      })
      break
    case 'feedback':
      items.map((resource: any, index: number) => {
        return newItems.push(
          <MenuItemFeedBack key={resource.config.id} numberQuestion={index} answer={resource} />
        )
      })
      break
    case 'feedbackLevels':
      newItems.push(<MenuItemFeedBackLevels key={0} level={items[level]} items={items} />)
      break
    case 'avatar':
      newItems.push(<MenuItemAvatar key={9} />)
      break
    case 'exercise':
    case 'navigation': {
      const links = activeNavigation ? getActiveItem() : items
      links!.forEach((link, key) => {
        const { ariaLabelText, role } = getMenuItemProps(link, t, answerId, activeNavigation)

        newItems.push(
          <MenuItemNavigation
            key={key}
            link={link}
            index={key}
            setActive={setActive}
            activeNavigation={activeNavigation}
            setNavId={setNavId}
            ariaLabel={ariaLabelText}
            itemRole={role}
          />
        )
      })
      break
    }

    case 'levels':
      newItems.push(
        <MenuItemLevel
          items={items}
          level={items[level]}
          activeQuestion={activeQuestion}
          key={0}
          levels={levels}
        />
      )
      break
    case 'helpCenter':
      newItems.push(<MenuItemHelpCenter key={'help'} />)
      break
    default:
      return newItems.push(<div>Items a mostrar</div>)
  }

  return newItems
}
