import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import * as Apollo from 'app/Apollo'
import { RequestError } from 'app/Components'
import { RouteParams } from 'Config/routes/types'

export const useGetRankingDetail = (context) => {
  const { courseId, studentId } = useParams<RouteParams>()
  const dispatch = useDispatch()

  const [getRankingDetail, { data: dataDetails, loading: loadingDetail, refetch }] =
    Apollo.useLazyQueryGetRankingDetail({
      variables: {
        RankingDetailInput: {
          studentId: Number(studentId),
          courseId: Number(courseId),
        },
        context,
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ getRankingDetail: dataCompleted }) => {
        if (!dataCompleted.status.success && dataCompleted.status.refCode !== 'V8-RDTL-003') {
          RequestError.handle(dispatch, dataCompleted)
        }
      },
    })

  const dataDetail = dataDetails?.getRankingDetail

  return {
    dataDetail,
    loadingDetail,
    getRankingDetail,
    refetch,
  }
}
