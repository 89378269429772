import React from 'react'
import { useFormContext } from 'react-hook-form'

import { tzCodesSelect } from 'app/Services'
import { useSetSelect } from '../Common'
import { Types } from '../types'

export const useTimeZone = () => {
  const FormContext = useFormContext()
  const { getValues, setValue } = FormContext

  const [state, setState] = React.useState<Types.selectString[]>([])
  const { setOption } = useSetSelect(state, 'timeZone')

  const data = tzCodesSelect

  const load = React.useCallback(async () => {
    const list = data
    if (list) {
      setState(list)
      setOption(list)

      if (!getValues().timeZone) {
        setValue('timeZone', 'America/Santiago')
      }
    }
  }, [data])

  React.useEffect(() => {
    if (data && data?.length > 0 && state.length === 0) {
      load()
    }
  }, [data, state])

  return { timeZoneList: state }
}
