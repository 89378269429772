import { EvaluationCreatePayload } from '@eclass/api'

import { updateAnswer } from './updateAnswer'
import { Props } from './'

/**
 * Luego de intentar guardar la respuesta del alumno si falló por algún motivo se dispara este evento.
 *
 * Si hay un error entonces dejamos la opción respondida como nula, y el estado vuelve al inicial: 0 (sin responder).
 */
const answeredError = (
  test: EvaluationCreatePayload,
  levelId: number,
  stageId: number,
  id: number,
  params: Props['params']
) => {
  // Actualizo datos de pregunta seleccionada en Levels y Answer
  test.Evaluation! = updateAnswer({
    id,
    levelId,
    Evaluation: test.Evaluation!,
    setAnswer,
  })

  /**
   * Datos a actualizar en la pregunta
   * @param currentAnswer {any[]}
   */
  function setAnswer(answer: any) {
    handleClozeError({
      configClose: answer.config,
      answerRequest: params?.response,
      disabled: false,
    })

    return {
      ...answer,
      state: 0,
      option: null,
      loading: false,
      errorSave: true,
    }
  }

  return { ...test }
}

export default answeredError

/**
 * Manejo estados de input en Cloze al ocurrir error
 */
const handleClozeError = ({ configClose, answerRequest, disabled }) => {
  if (
    configClose.ItemType?.id === 24 &&
    answerRequest &&
    /"configId":.*,"response":/.test(answerRequest)
  ) {
    const respond = JSON.parse(answerRequest)
    if (respond.configId >= 0) {
      const field = document.getElementById(`${configClose.id}_${respond.configId}`) as
        | HTMLInputElement
        | HTMLSelectElement
      if (field) {
        field.disabled = false
        if (field.tagName === 'INPUT') {
          field.setAttribute('data-status', 'error')
        }
      }
    }
  }
}
