import { useForm } from 'react-hook-form'

import { ProfileForms } from 'app/Components'

/**
 * Setea valores iniciales de los campos
 */
export const useInitTimeZoneInfo = () => {
  const { data } = ProfileForms.useDataUser()

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      timeZone: data?.timeZone || 'America/Santiago',
    },
  })

  return {
    methods,
  }
}
