// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../Config/getDomainConfig'
import { axiosError, getToken } from '../../Services'

const cleanCache = (keys) => {
  return async (dispatch) => {
    getToken()
    const query = `
      mutation cleanCache($CleanCacheInput: CleanCacheInput!) {
        cleanCache(input: $CleanCacheInput) {
          success
        }
      }
    `
    const variables = {
      CleanCacheInput: {
        keys,
      },
    }
    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response) => response.data.data.cleanCache)
      .catch((err) => axiosError(err, dispatch))
  }
}

export default cleanCache
