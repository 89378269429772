import styled from 'styled-components/macro'
import { UIVars } from 'app/Components/UI'

type Props = {
  border?: string
}

export const WrapperSelectForm = styled.div`
  .optional {
    margin-left: 8px;
    color: ${UIVars.setColors.brownGreyTwo};
    font-size: 14px;
    font-weight: 500;
  }

  &.status-error {
    .wrapper-react-select {
      > div {
        border: 1px ${UIVars.setColors.checkError} solid !important;
      }
    }
  }
`

export const Wrapper = styled.div<Props>`
  div[class$='feedback'] {
    margin-top: 4px;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  .invalid_feedback {
    color: ${UIVars.setColors.red};
  }
  .valid_feedback {
    color: ${UIVars.setColors.jadeGreen};
  }
`
