import React from 'react'
import { useTranslation } from 'react-i18next'

import * as Style from './style'
import { Select, Input, CheckBox, Date } from '../Common'
import { useLogic } from './useLogic'
import Types from '../types'

/**
 * Campos para agregar número téfonico
 */

export const Job = ({ profile = false, isSubmit = false }: Types.formBase) => {
  const { t } = useTranslation()
  const {
    industries,
    jobTypes,
    headings,
    executivePositions,
    onChangeHeading,
    disabled,
    onChangeActualJob,
    endDateBlock,
    currentWork,
  } = useLogic()

  return (
    <Style.Wrapper
      className={`ProfileForms-Job ${disabled ? 'noJob' : ''} ${profile ? 'profile' : ''} `}
    >
      {!profile && (
        <CheckBox
          name="isNotJob"
          text={t('ProfileNotWorking')}
          onChange={onChangeActualJob}
          disabled={isSubmit}
        />
      )}
      <Select
        required={!disabled}
        disabled={disabled || isSubmit}
        name="jobTypeId"
        label={t('ProfileTypeEmploy')}
        placeholder={t('ResourceSelect')}
        options={jobTypes}
      />
      <Select
        disabled={disabled || isSubmit}
        required={!disabled}
        name="headingId"
        label={t('ProfileRubro')}
        placeholder={t('ResourceSelect')}
        options={headings}
        onChange={onChangeHeading}
      />
      <Select
        disabled={disabled || isSubmit}
        required={!disabled}
        name="executivePositionId"
        label={t('ProfileExecutivePosition')}
        placeholder={t('ResourceSelect')}
        options={executivePositions}
      />
      <Input
        disabled={disabled || isSubmit}
        required={!disabled}
        name="position"
        type="NOVALIDATE"
        label={t('ProfilePosition')}
        placeholder={t('ProfileEnterPosition')}
      />
      <Input
        disabled={disabled || isSubmit}
        required={!disabled}
        name="name"
        type="NOVALIDATE"
        label={t('ProfileCompany')}
        placeholder={t('ProfileEnterCompany')}
      />
      <Select
        disabled={disabled || isSubmit}
        required={!disabled}
        name="industryId"
        label={t('ProfileIndustry')}
        placeholder={t('ResourceSelect')}
        options={industries}
      />
      {profile && (
        <React.Fragment>
          <Date
            label={t('ProfileStartDate')}
            name="startDate"
            placeholder={t('ProfileDateFormat')}
            disabled={isSubmit}
          />
          <Date
            label={t('ProfileEndDate')}
            name="endDate"
            placeholder={t('ProfileDateFormat')}
            required={!currentWork}
            disabled={isSubmit || endDateBlock || currentWork}
          />
          <CheckBox name="current" text={t('ProfileCurrentWork')} disabled={isSubmit} />
          <CheckBox name="private" text={t('ProfilePrivateInfo')} disabled={isSubmit} />
        </React.Fragment>
      )}
    </Style.Wrapper>
  )
}
