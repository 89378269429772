import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import * as Style from './Style'

interface Props {
  onClick: ((e: any) => Promise<void>) | (() => void)
  count: number
}
export const SearchResume = ({ onClick, count }: Props) => {
  const { t } = useTranslation()

  if (!count) {
    return null
  }

  return (
    <Style.Wrapper>
      <div>{ReactHtmlParser(t('AcademicHistorySearchResume', { count }))}</div>
      <Style.Refresh onClick={onClick}>{t('AcademicHistoryCleanResults')}</Style.Refresh>
    </Style.Wrapper>
  )
}
