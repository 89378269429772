const initialState = {
  getProgramsList: {},
}

/**
 * Export reducer de courses.
 *
 * @param {Object} state - Estado inicial
 * @param {string} action - Acción a procesar
 */
export const programs = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case 'GET_PROGRAM_LIST_OK':
      return {
        ...state,
        getProgramsList: payload,
      }
    default:
      return state
  }
}
