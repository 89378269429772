export const CalendarTime = (props) => {
  return (
    <g {...props} fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zm-1 12H2V5h12v9z"
      />
      <path d="M11 11H7V7h2v2h2z" />
    </g>
  )
}
