import React from 'react'

import useExercise from 'app/Views/Exercise/Practice/useExercise'
import BaseMultiple from './BaseMultiple'

const RadioExercise = ({ option, answer, optionValue, optionDisabled, id }: any) => {
  const {
    state: { answers, answered, seeCorrect, hint, embeds, send },
    dispatch,
  } = useExercise()

  const answerActive = answers.filter((item: any) => item.answerId === id)

  const handleChecked = () => {
    let active = -1
    answerActive[0]?.options?.forEach((item: any, index: number) => {
      if (item.selected) active = index
    })
    // // si viene la opcion seeCorrect, no lo deseleccionado
    return seeCorrect ? false : active === optionValue
  }

  const handleChange = (e: any) => {
    if (answerActive?.[0]?.options) {
      const options = answerActive[0].options.map((optionMap: any, index: number) => {
        optionMap.selected = index === optionValue
        return optionMap
      })
      dispatch({
        answerId: id,
        options,
      })
    }
  }

  let correctOption = false

  if (hint) {
    const hindActive = hint.filter((item: any) => item.answerId === id)
    hindActive?.[0]?.options?.forEach((item: any, index: number) => {
      if (item.selected && optionValue === index) {
        correctOption = true
      }
    })
  }

  return (
    <BaseMultiple
      option={option}
      seeCorrect={seeCorrect}
      correctOption={correctOption}
      handleChecked={handleChecked}
      optionDisabled={optionDisabled}
      answered={answered}
      optionValue={optionValue}
      id={id}
      handleChange={handleChange}
      answer={answer}
      type="radio"
      embeds={embeds}
      disabled={send.status}
    />
  )
}

export default RadioExercise
