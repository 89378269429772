import { Flex, Text, Box } from '@chakra-ui/react'
import { vars } from '@eclass/ui-kit'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'

import { Medal } from './Medal'
import { BaseMedalProps } from './types'
import { useDateFormat } from 'app/Services'

export const BaseMedal = ({ medalInfo, total }: BaseMedalProps) => {
  const { dateFormat } = useDateFormat()
  const { t } = useTranslation()

  const calcPerc = (numero, porcentaje) => {
    return Math.floor((numero / 100) * porcentaje)
  }

  switch (medalInfo?.type) {
    case 'BLAST_OFF':
      medalInfo.isComplete && !medalInfo.isReviewed
        ? (medalInfo.description = t('RankingYouHaveStarted'))
        : (medalInfo.description = t('RankingBlastOffDetail'))
      break
    case 'TRAILBLAZER':
      medalInfo.isComplete && !medalInfo.isReviewed
        ? (medalInfo.description = t('RankingTirthyOfTotal', {
            obtained: calcPerc(total, 30),
          }))
        : medalInfo.isComplete
        ? (medalInfo.description = t('RankingYouHaveAccumulatedThirtyPerc', {
            obtained: calcPerc(total, 30),
          }))
        : (medalInfo.description = t('RankingAccumulateThirtyPerc', {
            total: calcPerc(total, 30),
          }))
      break
    case 'STAR_MASTER':
      medalInfo.isComplete && !medalInfo.isReviewed
        ? (medalInfo.description = t('RankingSixtyOfTotal', {
            obtained: calcPerc(total, 60),
          }))
        : medalInfo.isComplete
        ? (medalInfo.description = t('RankingYouHaveAccumulatedSixtyPerc', {
            obtained: calcPerc(total, 60),
          }))
        : (medalInfo.description = t('RankingAccumulateSixtyPerc', {
            total: calcPerc(total, 60),
          }))
      break
    case 'STAR_LEYEND':
      medalInfo.isComplete && !medalInfo.isReviewed
        ? (medalInfo.description = t('RankingNinetyOfTotal', {
            obtained: calcPerc(total, 90),
          }))
        : medalInfo.isComplete
        ? (medalInfo.description = t('RankingYouHaveAccumulatedNinetyPerc', {
            obtained: calcPerc(total, 90),
          }))
        : (medalInfo.description = t('RankingAccumulateNinetyPerc', {
            total: calcPerc(total, 90),
          }))
      break
    case 'RULER_GALAXY':
      medalInfo.isComplete && !medalInfo.isReviewed
        ? (medalInfo.description = t('RankingCongratsAccumulateAllStarsCourse'))
        : medalInfo.isComplete
        ? (medalInfo.description = t('RankingYouHaveAccumulatedAll'))
        : (medalInfo.description = t('RankingAccumulateAll'))
      break
    case 'QUIZ_MENACE':
      medalInfo.isComplete && !medalInfo.isReviewed
        ? t('RankingCompleteHalfQuizzesCourse')
        : medalInfo.isComplete
        ? (medalInfo.description = t('RankingYouHaveCompleteQuizMenace'))
        : (medalInfo.description = t('RankingCompleteHalfQuizMenace'))
      break
    case 'QUIZ_MONARCH':
      medalInfo.isComplete && !medalInfo.isReviewed
        ? t('RankingCongratsCompleteAllStarsCourse')
        : medalInfo.isComplete
        ? (medalInfo.description = t('RankingYouhaveCompleteQuizMonarch'))
        : (medalInfo.description = t('RankingCompleteQuizMonarch'))
      break
    case 'SPEAKING_ACE':
      medalInfo.isComplete && !medalInfo.isReviewed
        ? t('RankingAttendedHalfLiveClassesCourse')
        : medalInfo.isComplete
        ? (medalInfo.description = t('RankingYouHaveAttendedSpeakingAce'))
        : (medalInfo.description = t('RankingAttendHalfSpeakingAce'))
      break
    case 'SPEAKING_PRO':
      medalInfo.isComplete && !medalInfo.isReviewed
        ? t('RankingAttendedAllLiveClassesCourse')
        : medalInfo.isComplete
        ? (medalInfo.description = t('RankingYouHaveAttendedSpeakingPro'))
        : (medalInfo.description = t('RankingAttendAllSpeakingPro'))
      break
    default:
      break
  }

  return (
    <Flex flexDir="column" justifyContent="start" alignItems="center">
      <Medal size={'100'} obtained={medalInfo?.isComplete} name={medalInfo?.name} />
      <Text
        m="0"
        mt=".25rem"
        fontSize=".75rem !important"
        fontWeight={700}
        lineHeight="1rem !important"
      >
        {medalInfo?.name}
      </Text>
      <Box
        m="0"
        mt=".25rem"
        fontSize=".75rem !important"
        lineHeight="1rem !important"
        color={vars('colors-neutral-gray')}
        textAlign="center"
      >
        {ReactHtmlParser(medalInfo?.description!)}
      </Box>
      <Text
        m="0"
        className="date"
        color={vars('colors-neutral-silverSand')}
        fontSize=".75rem !important"
        lineHeight="1rem !important"
      >
        {medalInfo?.isComplete
          ? dateFormat({
              date: new Date(medalInfo?.completeDate!),
              format: 'dmy',
            })
          : ''}
      </Text>
    </Flex>
  )
}
