import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getCountries } from 'app/Redux/Actions'
import { Types } from '../types'

/**
 * hooks que obtiene los paises,
 * @example
 * const { countries, status } = useListCountries()
 */
export const useListCountries = (toCall: boolean = true) => {
  const dispatch = useDispatch()
  const {
    profile: { countries: reduxCountries },
  } = useSelector((state: Types.Store) => state)

  const [countries, setCountries] = React.useState<Types.select[]>([])
  const [status, setStatus] = React.useState('init')

  /**
   * guardo lista de datos obtenidas en estado que retorno
   */
  const setValues = React.useCallback((options: Types.IDataCountry[]) => {
    const newOptions: Types.select[] = options.map((type: Types.IDataCountry) => {
      return {
        label: type.name || type.label!,
        value: type.id || type.value!,
      }
    })
    setCountries(newOptions)
    setStatus('success')
  }, [])

  /**
   * Llamo a query para obtener la lista de países
   */
  const getCountriesList = React.useCallback(async () => {
    setStatus('loading')
    const response = await dispatch<any>(getCountries())
    if (response && response.status.success) {
      setValues(response.data)
    } else {
      setStatus('error')
    }
  }, [dispatch, setValues])

  /**
   * obtengo lista de países que estan en la store
   */
  const dataStore = React.useCallback(async () => {
    if (reduxCountries?.length > 0) {
      setValues(reduxCountries)
    }
  }, [reduxCountries, setValues])

  React.useEffect(() => {
    if (!reduxCountries && toCall) {
      getCountriesList()
    } else {
      dataStore()
    }
  }, [getCountriesList, reduxCountries, dataStore])

  return { countries, status }
}
