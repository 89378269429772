import { gql, useLazyQuery, LazyQueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const GetResourceMeetFeedback = gql`
  ${STATUS_FIELDS}
  query getResourceMeetFeedback($ResourceMeetFeedbackInput: ResourceMeetFeedbackInput!) {
    getResourceMeetFeedback(input: $ResourceMeetFeedbackInput) {
      status {
        ...StatusFields
      }
      Questions {
        questionId
        title
        type
        range
        isMandatory
      }
    }
  }
`

export type GetResourceMeetFeedbackPayload = Api.ResourceMeetFeedbackPayload

export type GetResourceMeetFeedbackInput = Api.ResourceMeetFeedbackInput

interface GetresourceMeetFeedbackPayloadApollo {
  getResourceMeetFeedback: GetResourceMeetFeedbackPayload
}

interface GetResourceMeetFeedbackInputApollo {
  ResourceMeetFeedbackInput: GetResourceMeetFeedbackInput
}

export const useLazyGetResourceMeetFeedback = (
  options?: LazyQueryHookOptions<
    GetresourceMeetFeedbackPayloadApollo,
    GetResourceMeetFeedbackInputApollo
  >
) => {
  return useLazyQuery<GetresourceMeetFeedbackPayloadApollo, GetResourceMeetFeedbackInputApollo>(
    GetResourceMeetFeedback,
    options
  )
}
