import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Store } from 'app/Redux/types'
import { Types } from './types'

/**
 * Logica para levantar el modal en comentario
 * @example
 * const { modalInit } = useAlerts()
 */
export const useAlerts = () => {
  const dispatch = useDispatch()
  const { comments } = useSelector((state: Store) => state)

  /** Levanto modal y seteo valores del mismo */
  const open = useCallback(
    ({ type, url }: Types.useAlert) => {
      dispatch({
        type: 'COMMENT_MODAL_OPEN',
        payload: { type, path: url },
      })
    },
    [dispatch]
  )

  /** Cierro modal y reseteo valores */
  const closed = useCallback(() => {
    dispatch({ type: 'COMMENT_MODAL_CLOSED' })
  }, [dispatch])

  /**
   * Cuando clickeo "agregar" del modal,
   * si no es de tipo size valida el estado del check,
   * y si el valor del check es true lo guardo en localStorage
   */
  const handleAgreed = useCallback(
    (check: boolean) => {
      if (comments.type === 'size' || comments.type === 'delete') {
        closed()
      } else {
        if (check) {
          localStorage.setItem(comments.type, 'true')
        }
        closed()
        window.open(comments.path)
      }
    },
    [comments, closed]
  )

  /**
   * Se pasa este evento al elemento que quiero que tenga lo de abrir un modal,
   * dependiendo del tipo abre el modal, si es distinto a tipo type
   * verifica si tiene datos guardados en localStorage
   * si no tiene levanto la modal pero en caso contratio
   * no ejecuta el modal abriendo una ventana con la url correspondiente
   */
  const modalInit = useCallback(
    ({ event, type, url }: Types.useAlert) => {
      if (type === 'size') {
        open({ type })
      } else if (type === 'delete') {
        open({ type })
      } else {
        event.preventDefault()
        const target = event.target as HTMLAnchorElement
        const pathFinal = url || target.href
        if (localStorage.getItem(type) === 'true') {
          window.open(pathFinal)
        } else {
          open({ type, url: pathFinal })
        }
      }
    },
    [open]
  )

  return {
    modalInit,
    handleAgreed,
    closed,
  }
}
