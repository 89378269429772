import { Pressed, UIVars } from 'app/Components/UI'
import { Icon } from 'app/Components/UI/Icons'
import { Props, Types } from '../../types'
import { ButtonCopy } from './ButtonCopy'
import * as Style from '../../style'

/**
 * Renderizo link con el efecto presionar
 * Posee boton para copiar texto y abrir link en otra pestaña si lo trae
 */
export const Link = ({ data }: Props.Link) => {
  let newTab: Types.Anchor = {}
  if (data.href) {
    newTab = {
      href: data.href,
      target: '_blank',
    }
  }
  return (
    <li data-testid="CoordinatorLink">
      <Pressed dark>
        <Style.Anchor className="flex" {...newTab}>
          <div>
            <Icon name={data.icon || ''} fill={UIVars.setColors.icons} />
            {data.text}
          </div>
          {data.buttonCopy && <ButtonCopy text={data.text || ''} />}
        </Style.Anchor>
      </Pressed>
    </li>
  )
}
