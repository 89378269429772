import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Types } from '../types'

/**
 * Hooks que setea el valor que tiene que ir seleccionado en el select
 * @example const { setOption } = useSetSelect(genderList,'gender')
 */

export const useSetSelect = (
  options: Types.select[] | Types.selectString[],
  input: string
): { setOption: (value: Types.select[] | Types.selectString[]) => void } => {
  const { getValues, setValue, watch } = useFormContext()

  const setOption = React.useCallback(
    (newOptions: Types.select[] | Types.selectString[]) => {
      const inputValue = getValues()[input]

      // @ts-ignore
      const newValue = newOptions.find(
        (option: Types.select | Types.selectString) => option.value === inputValue
      )

      if (newValue !== getValues()[input]) {
        setValue(input, newValue)
      }
    },
    [input]
  )

  /**
   * Se ejecuta cuando el valor del select que viene de la base de datos
   * se guarda antes de tener el listado de opciones
   */
  React.useEffect(() => {
    if (typeof watch(input) === 'number' && options.length > 0) {
      setOption(options as Types.select[])
    } else if (typeof watch(input) === 'string' && options.length > 0) {
      setOption(options as Types.selectString[])
    }
  }, [watch, options])

  return { setOption }
}
