import styled from 'styled-components/macro'
import { IWrapper } from '../Avatar/types'
import { UIVars } from 'app/Components/UI'

const Wrapper = styled.figure`
  border-radius: 50%;
  flex-shrink: 0;
  height: ${(prop: IWrapper) => `${prop.size}px`};
  margin: 0px 0px 0px 0px;
  overflow: hidden;
  width: ${(prop: IWrapper) => `${prop.size}px`};
  background-color: ${(props: IWrapper) => props.selectedColor};
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: white;
    text-transform: uppercase;
    font: bold ${(props: IWrapper) => `${props.fontSize}px`} / 30px Roboto;
  }
  img {
    height: auto;
    max-width: 100%;
  }
  .test-img {
    display: none;
  }
  border: ${({ borderDecoration }: IWrapper) =>
    borderDecoration ? `3px solid ${UIVars.setColors.primary}` : 'none'};
`

export { Wrapper }
