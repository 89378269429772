/**
 * Valida que la transcripcion sea igual a algunas de las opciones que se le pasa
 */
export const validate = (words: string[], transcript: string) => {
  const correct = words.map((word) => cleanString(word))
  const cleanTranscript = cleanString(transcript)
  if (correct.includes(cleanTranscript)) {
    return true
  }
  return false
}

const cleanString = (text: string) =>
  text
    .toLowerCase()
    .replaceAll('.', '')
    .replaceAll(' ', '')
    .replaceAll('\r', '')
    .replaceAll('\n', '')
    .replace(/[^0-9a-z]/gi, '')
