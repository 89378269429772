import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { AlertNotification } from 'app/Components/UI/Alert'

/** Alert que muestra mensaje de guardado, editado y borrado exitosamente */
export const Alert = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const state: any = history.location.state

  const alertToastedManager = document.querySelector<HTMLElement>('.Toaster__manager-top')

  const message = (states: string) => {
    let alertMessage = ''
    switch (states) {
      case 'add':
        alertMessage = t('QuestionWeReceived')
        history.push({
          pathname: history.location.pathname,
          state: '',
        })
        break
      case 'edit':
        alertMessage = t('QuestionUpdated')
        setTimeout(() => {
          history.push({
            pathname: history.location.pathname,
            state: '',
          })
        }, 4000)
        break
      case 'delete':
        alertMessage = t('QuestionBeenDeleted')
        history.push({
          pathname: history.location.pathname,
          state: '',
        })
        break
      default:
        alertMessage = ''
    }
    return alertMessage
  }

  React.useEffect(() => {
    if (alertToastedManager) {
      alertToastedManager.style.maxWidth = '100%'
    }
  }, [])

  if (!state || state === '') {
    return null
  }

  return (
    <AlertNotification
      message={message(state)}
      type={'success'}
      duration={5000}
      show={true}
      maxWidth={false}
      closed={() => {
        history.push({
          pathname: history.location.pathname,
          state: '',
        })
      }}
    />
  )
}
