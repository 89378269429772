export const Back = (props) => {
  return (
    <g {...props}>
      <g id="c-info-(5)" fillRule="nonzero">
        <path
          fillRule="evenodd"
          d="M0.000122411 8.39396C0.000122401 8.18414 0.0732025 7.97522 0.220275 7.80519L6.29502 0.787903L7.68262 1.96544L2.11669 8.39396L7.68262 14.8225L6.29502 16L0.220275 8.98273C0.0732025 8.8127 0.00012242 8.60378 0.000122411 8.39396Z"
        />
      </g>
    </g>
  )
}
