import { useTranslation } from 'react-i18next'

interface option {
  label?: string
  value?: number
}

/**
 * Opciones del tipo de direccion.
 *
 * Particular | Comercial
 * @example
 * const AddressTypes = useAddressTypes()
 */
export const useAddressTypes = (): option[] => {
  const { t } = useTranslation()
  return [
    { label: 'Particular', value: 1 },
    { label: t('ProfileCommercial'), value: 2 },
  ]
}
