import { useReducer, useEffect } from 'react'
import { fileReducer, fileInitialState } from './fileReducer'

export const getFileName = (path: string) => {
  const fileRoute = path.split('/')
  return fileRoute[fileRoute.length - 1]
}

export const getFileExt = (name: string) => {
  const fileName = name.split('.')
  return fileName[fileName.length - 1]
}
// id: number, response: string, option:any
export const useFile = (action: any, data: any) => {
  const id = data.config?.id
  const response = data.answer!
  const option = data.option

  const [file, fileDispatch] = useReducer(fileReducer, fileInitialState)
  useEffect(() => {
    if (file.remove) {
      action({
        id,
        option: 'removeFile',
        type: 'ANSWER',
      })
    }
  }, [file.remove, action, id])

  useEffect(() => {
    if (response.length > 0) {
      let newFile = response
      // cuando el archivo se carga, no tiene el nombre como se almacena en la BDD,
      // por eso le agrego el nombre de forma manual
      if (typeof option === 'object' && option.name !== '') {
        newFile = option.name
      }
      fileDispatch({
        type: 'loaded',
        payload: { name: getFileName(newFile), extension: getFileExt(newFile) },
      })
    }
  }, [response, fileDispatch, option])
  return { file, fileDispatch }
}
