import { Types } from '../types'
import { stateQuestion } from './stateQuestion'

/**
 * Parser de las opciones de una pregunta
 */
const parseAnswer = (answer: any, evaluationConfig: Types.EvaluationConfig) => {
  const { config } = answer

  let state = stateQuestion.INITIAL
  let isDisabled = false
  let option
  const skipped = answer.state === stateQuestion.OMITTED

  /**
   * Por tipo de pregunta se deben parsear los valores
   */
  switch (config.ItemType.id) {
    case 3:
      state = checkState(answer, evaluationConfig)
      isDisabled = !evaluationConfig.completed
      answer = answer.answer
      break
    case 2:
    case 5:
    case 6:
    case 8:
    case 23:
    case 24:
      state = checkState(answer, evaluationConfig)
      isDisabled = !evaluationConfig.completed
      option = Number(answer.answer)
      break

    default:
      break
  }

  return {
    state,
    isDisabled,
    option,
    loading: false,
    skipped,
  }
}

export default parseAnswer

/**
 * Verifica el estado de una pregunta
 */
const checkState = (answer: any, config: Types.EvaluationConfig): number => {
  let state = stateQuestion.INITIAL

  if (config.completed) {
    state = answer.feedback.correct ? stateQuestion.ANSWERED_GOOD : stateQuestion.ANSWERED_BAD
    if (answer.feedback.skipped) {
      state = stateQuestion.OMITTED
    }
    if (!answer.feedback.skipped && answer.feedback.score.total === 0) {
      state = stateQuestion.NOT_KNOWN
    }
  } else if (answer.state === 4) {
    state = stateQuestion.OMITTED
  } else if (answer.answer) {
    // Cuando la pregunta ya tiene una respuesta
    state = answer.answer.trim().length > 0 ? stateQuestion.ANSWERED : stateQuestion.INITIAL

    // Caso Cloze, para validar que deben venir todas con respuesta en la carga incial para cambiarle el estado answer)
    if (answer?.config?.ItemType?.id === 24) {
      const respuestas = JSON.parse(answer.answer)
      const campos = JSON.parse(answer.content)

      if (respuestas) {
        state = respuestas.length === campos.length ? 1 : 0
      }
    }
  } else if (!config.canAnswer) {
    state = stateQuestion.CANT_ASNWER
  }

  return state
}
