// @External Dependencies
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'reactstrap/lib/Tooltip'

// @Component
class TooltipTrigger extends Component {
  state = {
    tooltipOpen: false,
  }

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    })
  }

  render() {
    const { target, children, placement, trigger, className } = this.props
    const { tooltipOpen } = this.state

    return (
      <Tooltip
        className={`Option__Tooltip ${className}`}
        placement={placement}
        isOpen={tooltipOpen}
        delay={0}
        target={`Tooltip-${target}`}
        toggle={this.toggle}
        trigger={trigger}
      >
        {children}
      </Tooltip>
    )
  }
}

// @Proptypes
TooltipTrigger.propTypes = {
  target: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  className: PropTypes.string,
  trigger: PropTypes.string,
}

TooltipTrigger.defaultProps = {
  target: 0,
  children: 'Tooltip',
  placement: 'top',
  trigger: 'click',
}

// @Export Component
export default TooltipTrigger
