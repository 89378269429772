import { gql, useQuery, QueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const GET_TERMS_CONDITIONS = gql`
  ${STATUS_FIELDS}
  query getTermsConditions {
    getProgramList {
      status {
        ...StatusFields
      }
      Programs {
        id
      }
      Terms {
        id
        text
        programId
        Program {
          name
        }
      }
    }
  }
`

export type GetTermsConditionsPayload = Api.ProgramListPayload

export type GetTermsConditionsInput = Api.ProgramListPayload

interface GetTermsConditionsPayloadApollo {
  getProgramList: GetTermsConditionsPayload
}

export const useQueryGetTermsConditions = (
  options?: QueryHookOptions<GetTermsConditionsPayloadApollo>
) => {
  return useQuery<GetTermsConditionsPayloadApollo>(GET_TERMS_CONDITIONS, options)
}
