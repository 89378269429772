// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { axiosError, getToken } from 'app/Services'
import { UnitResourcesInput } from '@eclass/api'

/**
 * Trae recurso pero acotado a solo el contenido para la descarga de él
 *
 * @param {import('@eclass/api').ResourceViewInput} input - Parámetros para buscar los comentarios.
 * @returns {Promise<import('@eclass/api').ApiRequest>}
 */
export const getResourcePDF = (input: UnitResourcesInput) => {
  const requestId = 'getResourcePDF'
  return async (dispatch: any) => {
    getToken()
    const query = `
      query getUnitResources($UnitResourcesInput: UnitResourcesInput!) {
        getUnitResources(input: $UnitResourcesInput) {
          status {
            name
            success
            code
            detail
          }
          Unit{
            name
          }
          ResourceList{
            Resource {
              header {
                title
                source {
                  url
                  poster
                }
                description
                boxType
              }
              config {
                License {
                  name
                  detail
                  icon
                  LicenseType {
                    name
                  }
                }
              }
              content {
                text
                timelines {
                  id
                  title
                  isPortrait
                  Milestones {
                    label
                    text
                    sortOrder
                  }
                }
                embeds {
                  id
                  name
                  configurations
                  typeId
                }
                footnotes
                definitions{
                  name
                  description
                }
              }
            }
            Evaluations {
              qualification {
                valueFormatted
                status {
                  name
                  detail
                  style
                }
              }
              config {
                id
                testId
                hasTime
                timeRemaining
                state
                showAnswers
                score {
                  total
                  obtained
                }
                repeat {
                  hideButton
                  criterial
                  total
                  attempt
                }
                startDate
                endDate
                completedDate
                daysRemaining
                folio
                completed
                hasRevision
                showFeedback
                canAnswer
                showQualification
              }
              header {
                name
                embedTitle
                Type {
                  id
                  name
                }
              }
              Levels {
                id
                name
                Stages {
                  id
                  name
                  Answers {
                    instructions
                    content
                    answer
                    embeds {
                      id
                      name
                      configurations
                      typeId
                    }
                    config {
                      id
                      evaluationId
                      levelId
                      stageId
                      mandatory
                      sortOrder
                      ItemType {
                        id
                        name
                      }
                    }
                    feedback {
                      correct
                      score {
                        total
                        obtained
                      }
                      skipped
                      comment
                      text
                      status {
                        id
                        enabled
                        name
                        detail
                        style
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `

    const variables = {
      UnitResourcesInput: { ...input },
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response) => response.data.data.getUnitResources)
      .catch((err: any) => axiosError(err, requestId, dispatch))
  }
}
