export const Unlocked = (props) => {
  return (
    <g {...props}>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="unlock">
          <rect id="Rectangle" x="0" y="0" width="16" height="16"></rect>
          <g
            id="unlocked-copy"
            transform="translate(8.000000, 8.000000) scale(-1, 1) translate(-8.000000, -8.000000) translate(1.000000, 0.000000)"
            fill="#B0CFE0"
            fillRule="nonzero"
          >
            <path
              d="M10,1.3 C9.2,0.5 8.1,0 7,0 C4.8,0 3,1.8 3,4 L3,5 L1,5 C0.4,5 0,5.4 0,6 L0,15 C0,15.6 0.4,16 1,16 L13,16 C13.6,16 14,15.6 14,15 L14,6 C14,5.4 13.6,5 13,5 L11,5 L5,5 L5,4 C5,2.9 5.9,2 7,2 C7.6,2 8.1,2.2 8.5,2.7 L10,1.3 Z M7,8 C8.1,8 9,8.9 9,10 C9,10.7 8.6,11.4 8,11.7 L8,13 L6,13 L6,11.7 C5.4,11.4 5,10.7 5,10 C5,8.9 5.9,8 7,8 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  )
}
