/**
 * Validación de RUT
 *
 * @param {boolean} state - Valida si es formato rut o solo un documento simple.
 * @param {string} nDocument - Número de documento
 * @param {string} digit - Dígito verigicador ingresado
 * @returns {string} Rut validado
 */
const validateRut = (state: boolean, nDocument: string, digit: string): string => {
  const rut = (rutCompleto: string) => {
    rutCompleto = rutCompleto.replace('‐', '-')
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) {
      return false
    }
    const tmp = rutCompleto.split('-')
    const digv = tmp[1].toLowerCase()
    const documentNumber = parseInt(tmp[0], 10)
    return checkDigit(documentNumber).toString() === digv
  }
  if (state) {
    const rutValido = rut(`${nDocument}-${digit}`)
    if (!rutValido) {
      nDocument = ''
      return nDocument
    } else {
      nDocument = `${nDocument}-${digit}`.split('-').join('')
      return nDocument
    }
  } else {
    return nDocument
  }
}

/**
 * Calcula el dígito verificador del Rut
 */
export const checkDigit = (T: number) => {
  let M = 0
  let S = 1
  for (; T; T = Math.floor(T / 10)) {
    S = (S + (T % 10) * (9 - (M++ % 6))) % 11
  }
  return S ? S - 1 : 'k'
}

export default validateRut
