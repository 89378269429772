import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'reactstrap'
import { useSelector } from 'react-redux'

import { Types } from './types'
import {
  AcademicHistorialIcon,
  CommentsIcon,
  ProfileIcon,
  Question,
  RequirementsIcon,
  TermsIcon,
} from 'app/Components/UI/Icons'
import * as Style from './Style'
import { Store } from 'app/Redux/types'
import { BreadCrumb } from 'app/Components/UI/Nav/Breadcrumb/Breadcrumb'

/**
 * Componente HeaderPage para vistas dependiendo del tipo se muestra texto e icono correspondiente
 * @example <HeaderPage type={"academicHistory | profile | terms"} hasContainer={true|false} back={} />
 */

export const HeaderPage = ({ type, hasContainer, total, className }: Types.Header) => {
  const { t } = useTranslation()
  const {
    ui: { isMobile },
  } = useSelector((state: Store) => state)

  const types = new Map([
    [
      'comments',
      {
        text: t('ResourceComments', {
          context: total ? 'more' : 'none',
          count: Number(total),
        }),
        icon: <CommentsIcon />,
        showGoBack: false,
      },
    ],
    [
      'profile',
      {
        text: t('CrossMyProfile'),
        icon: <ProfileIcon />,
        showGoBack: true,
      },
    ],
    [
      'academicHistory',
      {
        text: t('AcademicHistory'),
        icon: <AcademicHistorialIcon />,
        showGoBack: true,
      },
    ],
    [
      'terms',
      {
        text: t('ContentTermsConditions'),
        icon: <TermsIcon />,
        showGoBack: true,
      },
    ],
    [
      'question',
      {
        text: t('ResourceQuestion'),
        icon: <Question />,
        showGoBack: false,
      },
    ],
    [
      'requirements',
      {
        text: t('TechnicalRequirements'),
        icon: <RequirementsIcon />,
        showGoBack: true,
      },
    ],
  ])

  const Content = () => (
    <Style.Wrapper>
      <Style.Align>
        <Style.Title>
          {types.get(type)?.icon && <Style.Icon>{types.get(type)!.icon}</Style.Icon>}
          <Style.H1>{types.get(type)!.text}</Style.H1>
        </Style.Title>
      </Style.Align>
    </Style.Wrapper>
  )

  return (
    <Style.Header className={className} data-testid="HeaderPage">
      {types.get(type)?.showGoBack && <BreadCrumb />}
      {hasContainer ? (
        <div data-testid="headerHasContainer">{Content()}</div>
      ) : (
        <Container fluid={isMobile}>
          <Row>
            <Col md="12">{Content()}</Col>
          </Row>
        </Container>
      )}
    </Style.Header>
  )
}
