import styled, { keyframes } from 'styled-components'

const entry = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const entryDesktop = keyframes`
  0% {
    opacity: 0;
    top: 0px;
  }
  100% {
    opacity: 1;
    top: 30px;
  }
`

export const Wrapper = styled.div`
  animation: ${entry} 0.5s;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
  .alert {
    border-radius: 0;
    margin: 0 auto;
    max-width: 100%;
  }

  @media screen and (min-width: 640px) {
    animation: ${entryDesktop} 0.5s;
    left: 50%;
    top: 30px;
    transform: translatex(-50%);
    .alert {
      border-radius: 10px;
      max-width: 540px;
    }
  }
`
