import { UIVars } from 'app/Components/UI'
import * as type from '../types'

export const Check = ({
  className = '',
  fill = UIVars.setColors.iconFill,
  height = 17,
  width = 24,
}: type.basic) => {
  return (
    <svg
      data-testid="icon-stop"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={`0 0 ${width} ${height}`}
    >
      <path
        fill={fill}
        d="M8.5 17a1.5 1.5 0 01-1.061-.439L.379 9.5l2.12-2.121 6 6 13-13L23.622 2.5 9.56 16.561A1.501 1.501 0 018.5 17z"
      ></path>
    </svg>
  )
}
