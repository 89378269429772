// @External Dependencies
import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { UIVars } from 'app/Components/UI'
import { Store } from 'app/Redux/types'
import { ComponentProps } from './types'
import { StyleWrapper, StyleEmpty } from './Style'
import Progress from './Progress'
import { IconCircularProgressBar } from './Icon'

const strokeWidthSM = 3
const strokeWidthLG = 7
const sizeSM = 16
const sizeLG = 44

// @Component
export const CircularProgressBar = (props: ComponentProps) => {
  const initial = {
    color: props.color || UIVars.setColors.primary,
    colorEmpty: props.colorEmpty || '#d6d6d6',
    icon: props.icon || false,
    iconName: props.iconName || undefined,
    progress: props.progress || 0,
    size: props.size || (props.icon ? sizeLG : sizeSM),
    sizeIcon: props.sizeIcon || sizeSM,
    strokeWidth: props.strokeWidth || (props.icon ? strokeWidthLG : strokeWidthSM),
  }

  const {
    courses: {
      getCourseInfo: { Course },
    },
  } = useSelector((state: Store) => state)

  const [state, setState] = useState(initial)

  // actualizo datos iniciales
  const toUpdate = useCallback(() => {
    const withIcon = props.icon
      ? {
          size: props.size || sizeLG,
          strokeWidth: props.strokeWidth || strokeWidthLG,
        }
      : {}
    setState({ ...initial, ...withIcon })
  }, [props, setState, initial])

  useEffect(() => {
    if (props.progress !== state.progress) {
      toUpdate()
    }
  }, [props.progress, state.progress, toUpdate])

  return (
    <StyleWrapper
      className="CircularProgressBar"
      size={state.size}
      strokeWidth={state.strokeWidth}
      data-testid="CircularProgressBar"
    >
      <Progress
        color={state.color}
        progress={Course?.hasScorm ? 0 : state.progress}
        size={state.size}
        strokeWidth={state.strokeWidth}
      />
      <StyleEmpty colorEmpty={state.colorEmpty} size={state.size} strokeWidth={state.strokeWidth} />
      <IconCircularProgressBar
        visible={state.icon}
        progress={state.progress}
        color={state.color}
        name={state.iconName}
        sizeIcon={state.sizeIcon}
      />
    </StyleWrapper>
  )
}
