export const AcademicHistorialIcon = () => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="AcademicHistorialIcon"
  >
    <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
    <path
      d="M11 8H4C3.44772 8 3 8.44772 3 9V14C3 14.5523 3.44772 15 4 15H11C11.5523 15 12 14.5523 12 14V9C12 8.44772 11.5523 8 11 8Z"
      fill="#FFBC27"
    />
    <path
      d="M40 8H17C16.4477 8 16 8.44772 16 9V14C16 14.5523 16.4477 15 17 15H40C40.5523 15 41 14.5523 41 14V9C41 8.44772 40.5523 8 40 8Z"
      fill="#0189FF"
    />
    <rect x="4" y="8" width="37" height="26" rx="1" stroke="#08044F" strokeWidth="2" />
    <path d="M15 35L15 39" stroke="#08044F" strokeWidth="2" strokeLinecap="square" />
    <path d="M30 35L30 40" stroke="#08044F" strokeWidth="2" strokeLinecap="round" />
    <path d="M13 40L32 40" stroke="#08044F" strokeWidth="2" strokeLinecap="round" />
    <path
      d="M31 19H14C13.4477 19 13 19.4477 13 20V22C13 22.5523 13.4477 23 14 23H31C31.5523 23 32 22.5523 32 22V20C32 19.4477 31.5523 19 31 19Z"
      fill="#1EBDAF"
    />
    <path
      d="M31 26H14C13.4477 26 13 26.4477 13 27V29C13 29.5523 13.4477 30 14 30H31C31.5523 30 32 29.5523 32 29V27C32 26.4477 31.5523 26 31 26Z"
      fill="#FF554D"
    />
    <path d="M4 15L41 15" stroke="#08044F" strokeWidth="2" strokeLinecap="round" />
  </svg>
)
