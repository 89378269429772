import { useReducer, useEffect, useState, useCallback } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { file, text, freeWrite } from '../Questions/QuestionWrapper/idsQuetionTypes'
import setLevelsInitialState from '../helpers/setLevelsInitialState'
import testReducer from './reducer'
import {
  EvaluationAnswer,
  EvaluationCreate,
  EvaluationQualify,
  getExerciseQuestion,
  addHistory,
} from 'app/Redux/Actions'
import { toScroll } from '../helpers/toScroll'
import { testsStates } from '../testsStates'
import { initialValue } from '../testInitialValue'
import useExercise from '../../Exercise/Practice/useExercise'
import { useGetQuestionInfo } from 'app/Views/Helper/ModalQuestion'
import { Hooks, Types } from '../types'
import { usePrerequisitesAndRedirect } from 'app/Components/Prerequisites/usePrerequisitesAndRedirect'
import { RequestError } from 'app/Components'

const QUALIFICATION_STATUS_FAIL = 2
const EVALUATION_EVALUATED_STATE = 5

/**
 * Hook que maneja el estado del Test
 */
export function useTest(embedEvaluation?: Hooks.TestState): Hooks.TestReducer {
  const {
    loggedUser,
    courses: { getCourseInfo },
    exercises: exercisesRedux,
  } = useSelector((state: Types.Store) => state)

  const history = useHistory()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { programId, studentId, courseId, unitId, resourceId, answerId } =
    useParams<Types.RouteParams>()
  const { hasPrerequisites, redirectToPrerequisites } = usePrerequisitesAndRedirect()

  const [loading, setLoading] = useState(true)
  const [loadEmbed, setLoadEmbed] = useState(false)
  const [alert, setAlert] = useState({ code: 'fail', name: '', detail: '' })
  const [answerQuestion, setAnswerQuestion] = useState<any>()
  const [answered, setAnswered] = useState(0)
  const [mandatoryAnswered, setMandatoryAnswered] = useState(0)
  const [level, setLevel] = useState(0)
  const [showMissingAlert, setShowMissingAlert] = useState(false)
  /** estado que muestra si estan respondidas todas las preguntas del level */
  const [missingAnswers, setMissingAnswers] = useState(false)
  /** total de preguntas por level y respondidas */
  const [answeredLevel, setAnsweredLevel] = useState<Hooks.answeredLevel>({
    total: 0,
    answers: 0,
    totalMandatory: 0,
    answeredMandatory: 0,
  })
  /** si el test tiene levels o stages */
  const [hasLevelsStages, setHasLevelsStages] = useState(false)
  const [stage] = useState(0)
  const [test, setTest] = useState(initialValue)
  const [testState, testActions] = useReducer(testReducer(level, stage, setAnswerQuestion), test)
  const { initialState: exerciseAnswers, verify, errorSave } = useExercise()
  const isTest = pathname.search('test') === 1
  const isResource = pathname.search('resource') === 1

  const { data: dataGetQuestionInfo, loading: loadingGetQuestionInfo } = useGetQuestionInfo()

  /**
   * Cuando está finalizada la evaluación, entonces vamos al API a calcular la nota.
   */
  const qualifyEvaluation = useCallback(async () => {
    if (testState.Evaluation!.config.completed) {
      setLoading(true)
      const params = {
        programId: Number(programId),
        studentId: Number(studentId),
        courseId: Number(courseId),
        unitId: Number(unitId),
        resourceId: Number(resourceId),
      }

      if (
        testState.Evaluation!.config.state === testsStates.FINISHED ||
        testState.Evaluation!.config.state === testsStates.PENDING_EVALUATION
      ) {
        const finalize = testState.Evaluation!.config.finalize === 1
        const result: Types.EvaluationQualify = await dispatch<any>(
          EvaluationQualify({
            ...params,
            evaluationId: testState.Evaluation?.config.id!,
            finalize,
          })
        )
        if (result && result.status.success) {
          if (isTest) {
            if (result.Evaluation?.config.state === testsStates.PENDING_EVALUATION) {
              history.push(`/test/pending/${Object.values({ ...params }).join('/')}`)
              return true
            }

            /**
             * Si la prueba está Evaluada y no muestra la nota, entonces redireccionamos al feedback de la prueba.
             */
            if (
              result.Evaluation?.config.state === testsStates.EVALUATED &&
              !result.Evaluation?.config.showQualification
            ) {
              const url = `/test/review/${Object.values({
                ...params,
                evaluationId: testState.Evaluation?.config.id!,
              }).join('/')}`
              history.push(url)
              return true
            }
            /**
             * Esta redirección se produce en el contexto de repetición de evaluación para simplificar la navegación enviando directamente al usuario al review
             * Las condiciones para esto son:
             * - Que se pueda mostrar el feedback
             * - Que la cantidad de intentos de la evaluación sea menor que el total
             * - Que la nota de la evaluación este reprobada
             * - Que el estado de la evaluación este terminada
             */
            if (
              result.Evaluation?.config.showFeedback &&
              result.Evaluation?.config.repeat.attempt < result.Evaluation?.config.repeat.total &&
              result.Evaluation?.qualification.status.id === QUALIFICATION_STATUS_FAIL &&
              result.Evaluation?.config.state === EVALUATION_EVALUATED_STATE
            ) {
              const url = `/test/review/${Object.values({
                ...params,
                evaluationId: testState.Evaluation?.config.id!,
              }).join('/')}`
              history.push(url)
              return true
            }

            const url = `/test/resume/${Object.values({
              ...params,
              evaluationId: testState.Evaluation?.config.id!,
            }).join('/')}`
            history.push(url)
            setLoading(false)
          } else if (isResource) {
            setLoading(false)
            testActions({
              type: 'EMBED_RESPONSE',
              payload: result.Evaluation,
            })
          }
        }
      }
      setLoading(false)
    }
  }, [
    courseId,
    dispatch,
    history,
    programId,
    resourceId,
    setLoading,
    studentId,
    testState.Evaluation,
    unitId,
    isTest,
    isResource,
  ])

  /**
   * Cuando las preguntas están todas respondidas entonces la evaluación cambia a estado completada y se habilitan botones en el footer.
   */
  useEffect(() => {
    if (testState.Evaluation!.Levels?.length > 0) {
      const answers = testState.Evaluation!.Answers
      const mandatoryQuestions = testState.Evaluation!.Answers
        ? testState.Evaluation!.Answers.filter(({ config }: any) => config.mandatory).length
        : 0
      if (answers) {
        const answeredQuestions = testState.Evaluation!.Answers.filter(
          ({ state }: any) => state >= 1
        ).length
        const answeredMandatoryQuestions = testState.Evaluation!.Answers.filter(
          ({ state, config }: any) => state >= 1 && config.mandatory
        ).length
        // si el test tiene levels o stages, calculo el total de preguntas y respondias,
        // adicionalmente retorno el número de preguntas obligatorias y obligatorias respondidas
        if (hasLevelsStages) {
          if (testState.Evaluation!.Levels[level]) {
            setAnsweredLevel(
              testState.Evaluation!.Levels[level].Stages?.reduce(
                (acc: Hooks.answeredLevel, Stage: Types.Stage) => {
                  let answeredAccumulator = 0
                  let totalMandatory = 0
                  let answeredMandatory = 0
                  const answersArray = Stage!.Answers || []
                  answersArray.forEach((answer: Types.Answer) => {
                    // si es obligatoria y si esta respondida
                    if (answer?.config?.mandatory) {
                      totalMandatory++
                      if (answer?.state! >= 1) {
                        answeredMandatory++
                      }
                    }
                    if (answer?.state! >= 1) {
                      answeredAccumulator++
                    }
                  })
                  return (acc = {
                    total: answersArray.length + (acc.total || 0),
                    answers: answeredAccumulator + (acc.answers || 0),
                    totalMandatory,
                    answeredMandatory,
                  })
                },
                {}
              )
            )
          }
        }
        setMandatoryAnswered(answeredMandatoryQuestions)
        setAnswered(answeredQuestions)
        if (answers.length > 0) {
          if (
            mandatoryQuestions === answeredMandatoryQuestions &&
            testState.Evaluation!.config.state === testsStates.ANSWERING
          ) {
            testActions({ type: 'COMPLETED' })
          } else if (
            mandatoryQuestions !== answeredMandatoryQuestions &&
            testState.Evaluation!.config.state === testsStates.COMPLETED
          ) {
            testActions({ type: 'UNCOMPLETED' })
          }
        }
      }
    }
  }, [
    level,
    stage,
    testActions,
    testState.Evaluation,
    testState.Evaluation.config.state,
    testState.Evaluation.Levels,
    testState.Evaluation.Answers,
    setAnswered,
    hasLevelsStages,
  ])

  const saveAlert = useCallback(
    ({
      status,
      excercises,
      extra,
      skip,
      params,
    }: {
      status: any
      excercises?: any
      extra: any
      skip?: any
      params?: any
    }) => {
      if (status?.success) {
        if (excercises) {
          verify(extra)
        } else {
          testActions({
            id: answerQuestion?.config.id,
            status,
            type: 'ANSWERED',
            params,
            extra,
          })

          const detailText = () => {
            if (skip?.isRespond) {
              return 'respond'
            }
            if (skip?.skipped) {
              return 'skip'
            } else {
              return status.detail
            }
          }
          setAlert({
            code: status.code === 201 ? 'warning' : 'success',
            name: status.name,
            detail: detailText(),
          })
        }
        // este nuevo setAlert resetea al estado inicial justo después de la respuesta para evitar la repeticion de la alerta
        setAlert({ code: 'fail', name: '', detail: '' })
      } else {
        testActions({
          id: excercises ? excercises.id : answerQuestion?.config.id,
          type: 'ERROR_ANSWERED',
          params,
        })
        setAlert({
          code: status?.code === 403 ? 'timeExpired' : 'warning',
          name: status?.name || '',
          detail: status?.detail || '',
        })

        // Manejador de errores en los ejercicios
        if (excercises) {
          errorSave(status)
        }

        // este nuevo setAlert resetea al estado inicial justo después de la respuesta para evitar la repeticion de la alerta
        setAlert({ code: 'fail', name: '', detail: '' })
      }
    },
    [answerQuestion, verify]
  )

  /**
   * Recibe una respuesta y envía la petición al API para guardarla.
   */
  const saveAnswer = useCallback(
    async (excercises: any) => {
      const params: Hooks.AnswerParams = {
        token: testState.token,
        programId: Number(programId),
        studentId: Number(studentId),
        courseId: Number(courseId),
        unitId: Number(unitId),
        resourceId: Number(resourceId),
        evaluationId: testState.Evaluation?.config.id,
        testId: testState.Evaluation?.config?.testId ?? 0,
        testTypeId: testState?.Evaluation?.config?.testTypeId || 0,
      }
      // Guardo respuesta solo si la evaluación no esta finalizada
      if (!testState.Evaluation?.config.completed) {
        if (answerQuestion) {
          params.answerId = answerQuestion.config.id
          params.response = answerQuestion.option!.toString()
          params.testItemTypeId = answerQuestion.config?.ItemType?.id || 0
          const defaultStatus = { success: false }
          const defaultExtra = {}
          if (answerQuestion.option.file) {
            params.response = answerQuestion.option.name

            const result = await dispatch<any>(EvaluationAnswer(params, answerQuestion.option.file))
            const { status, ...extra } = result || {
              status: defaultStatus,
              extra: defaultExtra,
            }
            saveAlert({ status, extra, params })
          } else {
            if (answerQuestion.option === 'removeFile') {
              params.deleteFile = true
            }
            if (answerQuestion!.config.canSkip && answerQuestion!.skipped) {
              params.skipped = true
            }
            const result = await dispatch<any>(EvaluationAnswer(params))
            const { status, ...extra } = result || {
              status: defaultStatus,
              extra: defaultExtra,
            }
            const skipState = answerQuestion!.config.canSkip ? answerQuestion : null
            saveAlert({ status, extra, skip: skipState, params })
          }
        } else if (excercises) {
          params.answerId = excercises.id
          params.isRepetition = exercisesRedux.isRepetition || false
          params.response = JSON.stringify(excercises.option)

          const request = await dispatch<any>(EvaluationAnswer(params))
          if (request?.status.success) {
            const { ...extra } = request
            testActions({
              type: 'UPDATE_MENU',
              payload: {
                answer: request,
                resourceId: Number(resourceId),
              },
            })

            const newObtained = request?.Answer?.config?.indicator?.obtained || 0

            dispatch({
              type: 'EXERCISE_SET_SCORE',
              payload: {
                obtained: exercisesRedux.setScore.obtained + newObtained,
                max: exercisesRedux.setScore.max,
              },
            })
            saveAlert({ status: request.status, excercises, extra, params })
          } else {
            saveAlert({ status: request?.status, excercises, extra: null, params })
          }
        }
      }
    },
    [
      testState.Evaluation,
      answerQuestion,
      courseId,
      dispatch,
      programId,
      resourceId,
      saveAlert,
      studentId,
      unitId,
      exercisesRedux,
    ]
  )

  /**
   * Cuando se cambia una pregunta entonces se guarda su respuesta.
   */
  useEffect(() => {
    saveAnswer(null)
  }, [answerQuestion, saveAnswer])

  /**
   * Redirect en caso de haber finalizado el curso y la evaluacion no esté completada (fecha desactivación)
   * si no cumple la condición sigue avanzando.
   */
  const redirect = useCallback(
    async (evStatus: number) => {
      if (!getCourseInfo.Student?.dates?.canGenerateActivity && evStatus === testsStates.ALERT) {
        history.push({
          pathname: getCourseInfo.Course?.href,
          state: 'evaluation',
        })
      }
    },
    [getCourseInfo.Student, getCourseInfo.Course, history]
  )

  /**
   * Busca la Evaluación para el alumno y la setea en el estado Test
   */
  const getTest = useCallback(
    async (repeat = false) => {
      setLoading(true)

      const shotParams: any = {
        programId: Number(programId),
        studentId: Number(studentId),
        courseId: Number(courseId),
        unitId: Number(unitId),
        resourceId: Number(resourceId),
      }

      const params = {
        ...shotParams,
        alerts: !repeat ? pathname.includes('/test/answer/') : repeat,
        repeat,
      }

      if (!isTest && answerId) {
        params.answerId = Number(answerId)
      }

      const response = await dispatch<any>(
        isTest ? EvaluationCreate(params) : getExerciseQuestion(params)
      )

      if (!response || [404, 500].includes(response.status.code)) {
        history.push(getCourseInfo.Course?.href!)
        return null
      }

      if (hasPrerequisites(response)) {
        redirectToPrerequisites()
        return null
      }

      if (response.status.success) {
        redirect(response.Evaluation.config.state)
        if (response.Evaluation.config.state === testsStates.PENDING_EVALUATION) {
          history.push(`/test/pending/${Object.values({ ...shotParams }).join('/')}`)
          return true
        }
        if (isTest) {
          const { Levels, Answers } = setLevelsInitialState(
            response.Evaluation.Levels,
            response.Evaluation.config
          )
          Answers.forEach((answer: Types.Answer) => {
            if ([file, text, freeWrite].includes(answer!.config!.ItemType.id || 0)) {
              response.Evaluation.config.needRevisionAfter = true
            }
          })
          response.Evaluation.Levels = Levels
          response.Evaluation.Answers = Answers

          // valido si el test tiene mas de un levels o stages o si alguno de estos traen description
          const hasDescriptionLevelsStages = response.Evaluation.Levels.some((levelEvaluation) => {
            if (levelEvaluation.description) {
              return true
            }

            return levelEvaluation.Stages?.some((stageEvaluation) =>
              Boolean(stageEvaluation.description)
            )
          })

          if (response.Evaluation.Levels.length > 1 || hasDescriptionLevelsStages) {
            setHasLevelsStages(true)
          }
        } else {
          exerciseAnswers(response)
        }
        testActions({
          type: 'LOAD_TEST',
          payload: response,
        })
        setTest(response)
        setLoading(false)
      } else {
        if (response.Evaluation) {
          history.push(
            `/test/resume/${Object.values({
              ...params,
              evaluationId: response.Evaluation?.config.id,
            }).join('/')}`
          )
        } else if (response.status.code === 401) {
          // Este caso es para cubrir el caso de alumno provisorio
          RequestError.handle(dispatch, response, `/units/${programId}/${studentId}/${courseId}`)
        } else {
          history.push('/courses')
        }
      }
    },
    [
      answerId,
      pathname,
      courseId,
      dispatch,
      isTest,
      history,
      programId,
      resourceId,
      setTest,
      studentId,
      unitId,
      exerciseAnswers,
      getCourseInfo.Course,
      redirect,
    ]
  )

  /**
   * Cuando se cambie el config.state a 3 entonces calificamos la prueba.
   */
  useEffect(() => {
    if (
      testState.Evaluation!.config.state === testsStates.FINISHED ||
      testState.Evaluation!.config.state === testsStates.PENDING_EVALUATION
    ) {
      qualifyEvaluation()
    } else if (testState.Evaluation!.config.state === testsStates.REPEAT_NOW) {
      getTest(true)
    }
  }, [testState.Evaluation, testState.Evaluation.config.state, qualifyEvaluation, getTest, history])

  /**
   * Si viene el test por parámetro al hook, entonces lo parsea.
   */
  const setEmbed = useCallback(() => {
    if (!loadEmbed && embedEvaluation) {
      const { Levels, Answers } = setLevelsInitialState(
        embedEvaluation!.Evaluation!.Levels!,
        embedEvaluation!.Evaluation!.config
      )

      const embedEvaluationFormatted = {
        ...embedEvaluation,
        Evaluation: {
          ...embedEvaluation.Evaluation,
          Levels,
          Answers,
        },
      }

      testActions({
        type: 'LOAD_TEST',
        payload: embedEvaluationFormatted,
      })

      // @ts-ignore
      setTest(embedEvaluationFormatted)
      setLoadEmbed(true)
      setLoading(false)
    }
  }, [embedEvaluation, loadEmbed])

  /**
   * Busca el test y lo carga al componente cuando se inicia el componente.
   */
  useEffect(() => {
    if (!isResource) {
      getTest()
    } else {
      setEmbed()
    }
  }, [isResource, getTest, setEmbed, redirect])

  /*
   * Seteo level activo y hago scroll al top
   * Registro la navegación entre niveles
   */
  const handleHistory = useCallback(
    async (page: number) => {
      window.scroll(0, 0)
      setLevel(page)
      const levelId = testState.Evaluation.Levels[page].id

      let action = 'answer'
      switch (testState.Evaluation?.config.state) {
        case 1:
          action = 'answer'
          break
        case 2:
          action = 'review'
          break
        case 5:
          action = 'resume'
          break
        default:
          action = 'answer'
          break
      }

      testActions({
        type: 'NAVIGATION_LEVEL',
        payload: { action, page },
      })

      await dispatch<any>(
        addHistory({
          userData: loggedUser,
          courseData: getCourseInfo,
          route: {
            url: `${window.location.href}/${levelId}`,
            params: {
              programId,
              studentId,
              courseId,
              unitId,
              resourceId,
              levelId,
            },
          },
          type: {
            controller: 'test',
            action,
          },
        })
      )
    },
    [
      programId,
      studentId,
      courseId,
      getCourseInfo,
      loggedUser,
      unitId,
      resourceId,
      dispatch,
      testState.Evaluation,
    ]
  )
  /**
   *  Se encarga de buscar la primera pregunta que tenga una respuesta no contestada y de llevarnos
   *  a esta cuando se navegan los niveles.
   */

  const goToTheNotAnswered = () => {
    const SearchAllAnswers = testState.Evaluation.Levels[level].Stages[stage].Answers
    const SetTheFirstWithNoAnswer = SearchAllAnswers.find((answer) => answer.state === 0)
    if (SetTheFirstWithNoAnswer) {
      toScroll(SetTheFirstWithNoAnswer?.config?.id)
    }
  }

  /**
   * Si el test tiene levels y etapas, entonces este método se encarga de navegar entre levels,
   * si no estan contestadas todas las preguntas obligatorias del level no puedo avanzar al siguiente
   */
  const navigationLevels = (page: number) => {
    if (hasLevelsStages) {
      let missing = false
      // si page > level, estoy avanzado al siguiente level
      if (page > level) {
        // si el número de preguntas obligatoria es igual al numero de obligatorias respondidas
        if (answeredLevel.totalMandatory === answeredLevel.answeredMandatory) {
          handleHistory(page)
        } else {
          missing = true
          goToTheNotAnswered()
        }
      } else {
        handleHistory(page)
      }
      setMissingAnswers(missing)
      if (missing) {
        setShowMissingAlert(true)
        setTimeout(() => {
          setShowMissingAlert(false)
        }, 1000)
      }
    }
  }

  return {
    testState,
    testActions,
    answered,
    mandatoryAnswered,
    level,
    stage,
    loading,
    alert,
    saveExercise: saveAnswer,
    hasLevelsStages,
    navigationLevels,
    missingAnswers,
    answeredLevel,
    showMissingAlert,
    dataGetQuestionInfo,
    loadingGetQuestionInfo,
  }
}
