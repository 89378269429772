import { useTranslation } from 'react-i18next'
import { Validate as TypeValidate } from 'react-hook-form'

import { validateRut } from 'app/Services'

export type TypesInput =
  | 'PHONE'
  | 'NUMBERS'
  | 'EMAIL'
  | 'ALPHABETIC'
  | 'ALPHANUMERIC'
  | 'PASS'
  | 'NOVALIDATE'
  | 'RUT'
  | 'TEXTAREA'

type TypesInputHTML = 'text' | 'email' | 'tel' | 'number' | 'textarea' | 'password'

type values = {
  regExp?: RegExp
  validate?: TypeValidate
  text?: string
  htmlType: TypesInputHTML
}

/**
 * Retorno expresion regular para el tipo de input requerido
 * @param type {Types} PHONE, NUMBERS, EMAIL, ALPHABETIC, ALPHANUMERIC, PASS
 * @returns regExp
 */
export const useValidations = (type: TypesInput): values => {
  const { t } = useTranslation()

  switch (type) {
    case 'PHONE':
      // en esta expresión regular el + es opcional (?), acepta del 0-9 entre 9 y 10 caracteres
      return {
        regExp: /^([+])?([0-9]{9,13})$/,
        text: t('ProfileOnlyNumbers11'),
        htmlType: 'tel',
      }

    case 'NUMBERS':
      // Sólo acepta números.
      return {
        regExp: /^[0-9]*$/,
        text: t('ProfileOnlyNumbers'),
        htmlType: 'number',
      }

    case 'EMAIL':
      // en esta expresión regular la "@" es obligatoria y caracteres afabeticos seguidos de un punto
      return {
        regExp:
          /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,63}$/i,
        text: t('ProfileValidMail'),
        htmlType: 'email',
      }

    case 'ALPHABETIC':
      // en esta acepta de la a-z mayuscula minuscula tildes y espacios sin limite
      return {
        regExp: /^[a-zA-ZñÑáéíóúÁÉÍÓÚ\\?!\s]+$/,
        text: t('CrossOnlyLetters'),
        htmlType: 'text',
      }

    case 'ALPHANUMERIC':
      // esta expresión acepta mayus min a-z números 0-9, simbolos espacios y tildes sin limite
      return {
        regExp: /^[a-zA-Z0-9!@#$&()\\-`.+,/"ñÑáéíóúÁÉÍÓÚ\s]+$/,
        text: t('ResourceErrorTryAgain'),
        htmlType: 'text',
      }

    case 'TEXTAREA':
      // esta expresión acepta mayus min a-z números 0-9, simbolos espacios y tildes sin limite
      return {
        htmlType: 'textarea',
      }

    case 'PASS':
      // esta expresión acepta mayus min a-z  simbolos específicos espacios sin límite
      return {
        regExp: /^[a-zA-Z0-9!@#$%&·()“”\\-`.+,/"]+$/,
        text: t('ResourceErrorTryAgain'),
        htmlType: 'password',
      }

    case 'RUT':
      // esta expresión acepta mayus min a-z  simbolos específicos espacios sin límite
      return {
        validate: rut,
        text: t('ProfileInvalidDocument'),
        htmlType: 'text',
      }

    default:
      return {
        htmlType: 'text',
      }
  }
}

function rut(value) {
  const dv = value.trim().replace(/\./g, '').replace('-', '')
  const complete = validateRut(true, dv.slice(0, -1), dv.slice(-1))
  return !!complete && complete.length > 7
}
