// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { getToken, axiosError } from '../../../Services'

/**
 * Lista las comunas de una región, ordenadas por nombre ascendente
 * @param {number} regionId - Id de la Región con la que se filtrarán las Localidades
 * @example
 * ```js
 *    const { data } = await getLocations(33)
 * ```
 * @returns {Promise<any>} Listado de Paises (id, name)
 */
const getLocations = (regionId) => {
  const requestId = 'getLocations'
  return async (dispatch) => {
    getToken()

    const query = `
      query allLocations(
        $paginate: Int
        $filter: LocationFilterFilter
        $orderBy: LocationFilterOrderBy
      ){
        allLocations(
          paginate: $paginate
          filter: $filter
          orderBy: $orderBy
          ){
          name
          id
        }
      }
    `
    const variables = {
      paginate: 1000,
      orderBy: 'name_ASC',
      filter: {
        regionId: regionId === '' ? 0 : regionId,
      },
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then((response) => {
        const { allLocations } = response.data.data
        if (response.status === 200 && allLocations) {
          return {
            data: allLocations,
            status: {
              success: true,
              detail: 'Petición exitosa',
            },
          }
        } else {
          return {
            data: [],
            status: {
              success: false,
              detail: 'Error al buscar las comunas de la región.',
            },
          }
        }
      })
      .catch((err) => axiosError(err, requestId, dispatch))
  }
}

export default getLocations
