import React from 'react'

export const AppliedQuestion = () => (
  <svg
    width="45"
    role="iconAppliedQuestion"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
    <rect x="13" y="23" width="12" height="9" rx="2" fill="#FFBC27" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 27C17 26.4477 17.4477 26 18 26L20 26C20.5523 26 21 26.4477 21 27C21 27.5523 20.5523 28 20 28L18 28C17.4477 28 17 27.5523 17 27Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 24H15L15 34H23V24ZM15 22C13.8954 22 13 22.8954 13 24V34C13 35.1046 13.8954 36 15 36H23C24.1046 36 25 35.1046 25 34V24C25 22.8954 24.1046 22 23 22H15Z"
      fill="#08044F"
    />
    <path
      d="M24 15H14C13.4477 15 13 15.4477 13 16V18C13 18.5523 13.4477 19 14 19H24C24.5523 19 25 18.5523 25 18V16C25 15.4477 24.5523 15 24 15Z"
      fill="#FF554D"
    />
    <path
      d="M31 9H28C27.4477 9 27 9.44772 27 10V12C27 12.5523 27.4477 13 28 13H31C31.5523 13 32 12.5523 32 12V10C32 9.44772 31.5523 9 31 9Z"
      fill="#1EBDAF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 11C13 10.4477 13.4477 10 14 10L24 10C24.5523 10 25 10.4477 25 11C25 11.5523 24.5523 12 24 12L14 12C13.4477 12 13 11.5523 13 11Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 7C11.4477 7 11 7.44772 11 8V36.5375C11 37.3793 11.6494 38 12.3757 38H33C33.5523 38 34 37.5523 34 37V29C34 28.4477 34.4477 28 35 28C35.5523 28 36 28.4477 36 29V37C36 38.6569 34.6569 40 33 40H12.3757C10.4779 40 9 38.4157 9 36.5375V8C9 6.34315 10.3431 5 12 5H32.6243C34.5221 5 36 6.58434 36 8.46252V15C36 15.5523 35.5523 16 35 16C34.4477 16 34 15.5523 34 15V8.46252C34 7.62067 33.3505 7 32.6243 7H12Z"
      fill="#08044F"
    />
    <path
      d="M42 18H28C27.4477 18 27 18.4477 27 19V25C27 25.5523 27.4477 26 28 26H29.4648C29.7992 26 30.1114 26.1671 30.2969 26.4453L31.1679 27.7519C31.5638 28.3457 32.4362 28.3457 32.832 27.7519L33.7031 26.4453C33.8886 26.1671 34.2008 26 34.5352 26H42C42.5523 26 43 25.5523 43 25V19C43 18.4477 42.5523 18 42 18Z"
      fill="#0189FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37 22C37 21.4477 37.4477 21 38 21L44 21C44.5523 21 45 21.4477 45 22C45 22.5523 44.5523 23 44 23L38 23C37.4477 23 37 22.5523 37 22Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31 22C31 21.4477 31.4477 21 32 21L34 21C34.5523 21 35 21.4477 35 22C35 22.5523 34.5523 23 34 23L32 23C31.4477 23 31 22.5523 31 22Z"
      fill="#08044F"
    />
  </svg>
)
