import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import * as Apollo from 'app/Apollo'
import { RequestError } from 'app/Components'
import * as Types from './types'

export function useModalQuestion() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { studentId, courseId, questionId, resourceId, unitId } = useParams<Types.RouteParams>()
  const [isOpen, setIsOpen] = React.useState(false)

  const toggleModal = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen)
  }

  const [mutationQuestionCreate, { loading: questionCreateLoading }] =
    Apollo.useMutationQuestionCreate({
      onCompleted: (dataCompleted) => {
        if (!dataCompleted || !dataCompleted.QuestionCreate.status.success) {
          RequestError.handle(dispatch, dataCompleted.QuestionCreate)
        }
      },
    })

  const saveQuestion = async ({ type, title, text, publicQuestion }: Types.SaveQuestion) => {
    const { data } = await mutationQuestionCreate({
      variables: {
        QuestionCreateInput: {
          studentId: Number(studentId),
          courseId: Number(courseId),
          resourceId: resourceId ? Number(resourceId) : undefined,
          unitId: unitId ? Number(unitId) : undefined,
          questionId: questionId ? Number(questionId) : undefined,
          title,
          text,
          publicQuestion: publicQuestion,
        },
      },
      update: (cache, { data: updatedResponse }) => {
        const updatedResponseTest = {
          id: updatedResponse?.QuestionCreate.Question?.id,
          name: updatedResponse?.QuestionCreate.Question?.title,
          url: updatedResponse?.QuestionCreate.url,
          __typename: 'LinkTypeObject',
        }

        // Si existe questionId estoy editando una pregunta existente
        if (questionId) {
          cache.modify({
            id: cache.identify(updatedResponseTest),
            fields: {
              name() {
                return updatedResponseTest.name
              },
            },
          })

          return history.push({
            state: 'edit',
          })
        } else {
          // TODO: Investigar como eliminar el cache que afecta la pregunta creada con argumentos especificos
          cache.evict({ fieldName: 'getQuestionList' })
        }
      },
    })

    // Caso para llamada desde la ruta helper
    if (type === 'helper') {
      // Al crear una pregunta
      if (data?.QuestionCreate?.url) {
        history.push({
          pathname: data?.QuestionCreate.url,
          state: 'add',
        })
      }
    } else {
      // Caso para llamada desde un recurso o una evaluación
      history.push({
        state: 'add',
      })
    }
  }

  return {
    isOpen,
    toggleModal,
    saveQuestion,
    questionCreateLoading,
  }
}
