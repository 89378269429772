/**
 * Cambia en el Storage de la Aplicación si muestra u oculta la bienvenida del curso.
 *
 * @param {boolean} setRevisedWelcome - Muestra la bienvenida del curso
 * @returns String con el estado del cambio
 */
const setRevisedWelcome = (showWelcome) => {
  return (dispatch) => {
    dispatch({
      type: 'COURSE_REVISED_WELCOME',
      payload: showWelcome,
    })
    return showWelcome ? 'COURSE_REVISED_WELCOME_OPEN' : 'COURSE_REVISED_WELCOME_CLOSE'
  }
}

export default setRevisedWelcome
