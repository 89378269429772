import { gql, useMutation, MutationHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const PERSON_TIME_ZONE = gql`
  ${STATUS_FIELDS}
  mutation PersonTimeZone($PersonTimeZoneInput: PersonTimeZoneInput!) {
    PersonTimeZone(input: $PersonTimeZoneInput) {
      status {
        ...StatusFields
      }
    }
  }
`

export type PersonTimeZonePayload = Api.PersonTimeZonePayload

export type PersonTimeZoneInput = Api.PersonTimeZoneInput

interface PersonTimeZonePayloadApollo {
  PersonTimeZone: PersonTimeZonePayload
}

interface PersonTimeZoneInputApollo {
  PersonTimeZoneInput: PersonTimeZoneInput
}

export const useMutationPersonTimeZone = (
  options?: MutationHookOptions<PersonTimeZonePayloadApollo, PersonTimeZoneInputApollo>
) => {
  return useMutation<PersonTimeZonePayloadApollo, PersonTimeZoneInputApollo>(
    PERSON_TIME_ZONE,
    options
  )
}
