import * as Api from '@eclass/api'
import { ModalType } from '../types'

/** Dependiendo del tipo del recurso calcula el tipo de modal
 *  @param {string} universalType - Tipo del recurso
 *  @returns {string} Tipo de modal
 */
export const universalTypeToModalType = (universalType?: Api.UniversalTypeUnion): ModalType => {
  const universalTypeOptionsToModalType = {
    ARTICLE: 'resource',
    TEST: 'test',
    EXAM: 'test',
    COURSE: 'helper',
  }

  if (!universalType) {
    return 'helper'
  }

  return universalTypeOptionsToModalType[universalType]
}
