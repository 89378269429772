import { gql, LazyQueryHookOptions, useLazyQuery } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const getUnitCEVStatus = gql`
  ${STATUS_FIELDS}
  query getUnitCEVStatus($UnitCEVStatusInput: UnitCEVStatusInput!) {
    getUnitCEVStatus(input: $UnitCEVStatusInput) {
      hasCevs
      totalCevs
      hasCevsSelected
      status {
        ...StatusFields
      }
    }
  }
`

export type GetUnitCEVStatusPayload = Api.UnitCEVStatusPayload

export type GetUnitCEVStatusInput = Api.UnitCEVStatusInput

interface GetUnitCEVStatusPayloadApollo {
  getUnitCEVStatus: GetUnitCEVStatusPayload
}

interface GetUnitCEVStatusInputApollo {
  UnitCEVStatusInput: GetUnitCEVStatusInput
}

export const useLazyGetUnitCEVStatus = (
  options?: LazyQueryHookOptions<GetUnitCEVStatusPayloadApollo, GetUnitCEVStatusInputApollo>
) => {
  return useLazyQuery<GetUnitCEVStatusPayloadApollo, GetUnitCEVStatusInputApollo>(
    getUnitCEVStatus,
    options
  )
}
