import { useEffect, useState } from 'react'
import { ModalMedalAchieved } from '../UI'
import { useInfoMedals } from './useInfoMedals'
import { useSnmSuscribe } from './useSnmSuscribe'

export const NotificationLeaderboard = () => {
  const [isOpen, setModal] = useState(false)
  const [notification, setNotification] = useState<string>('')
  const { snmData } = useSnmSuscribe()
  const { queueMedals, total } = useInfoMedals()

  const toggleModalMedals = () => {
    setModal(!isOpen)
  }

  useEffect(() => {
    if (snmData && Object.entries(snmData)?.length > 0) {
      setNotification('pusher')
      setModal(true)
    }
  }, [snmData])

  useEffect(() => {
    if (queueMedals && queueMedals?.length > 0) {
      setNotification('queue')
      setModal(true)
    }
  }, [queueMedals])

  return (
    <ModalMedalAchieved
      isOpen={isOpen}
      toggle={toggleModalMedals}
      type={notification}
      total={total}
      medalData={notification === 'queue' ? queueMedals : snmData}
    />
  )
}
