import { EvaluationCreatePayload } from '@eclass/api'
import { testsStates } from '../../testsStates'
import scrollmove from '@eclass/scrollmove'

/**
 * Si la prueba esta completada y se borra una pregunta
 */
const uncompleted = (
  test: EvaluationCreatePayload,
  levelId: number,
  stageId: number,
  isEdit: boolean
) => {
  const stages = test.Evaluation!.Levels[levelId].Stages!

  /**
   * Si estoy editando la respuestas hacemos scroll a la primera pregunta del nivel/etapa.
   */
  if (isEdit) {
    const answers = stages[stageId].Answers!
    scrollmove(`#question-${answers[0].config!.id}`)
  }

  test.Evaluation!.config.state = testsStates.ANSWERING
  // @ts-ignore
  stages[stageId].state = testsStates.ANSWERING

  return { ...test }
}

export default uncompleted
