import { useState } from 'react'
import { vars } from '@eclass/ui-kit'

import { BaseIcons } from './BaseIcons'
import { IAudio } from '../Podcast'
import { Text } from '.'

export const Speed = ({ audio }: IAudio) => {
  const config = [0.5, 1.0, 1.5, 2.0]
  const [active, setActive] = useState(1)

  const onClick = () => {
    if (audio) {
      let velocity
      if (active > 2) {
        velocity = 0
      } else {
        velocity = active + 1
      }
      audio.playbackRate = config[velocity]
      setActive(velocity)
    }
  }
  return (
    <BaseIcons
      borderRadius="14px"
      onClick={onClick}
      h="28px"
      w="38px"
      sx={{
        span: {
          color: vars('colors-main-blueGrey'),
          zIndex: 1,
        },
      }}
    >
      <Text>{config[active].toFixed(1)}x</Text>
    </BaseIcons>
  )
}
