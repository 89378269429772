// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

// @Component
const Info = ({ value, label, inputSize }) => (
  <StyledInfo className={`col-sm-${inputSize} ${label && 'col-sm-offset-2'} form-info`}>
    {' '}
    {value}{' '}
  </StyledInfo>
)

// @Proptypes
Info.propTypes = {
  value: PropTypes.node,
  label: PropTypes.string,
}

// @Export Component
export default Info

const StyledInfo = styled.div`
  margin-top: 10px;
`
