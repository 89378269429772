import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactHtmlParser from 'react-html-parser'

import { android, ios } from 'assets/img'
import { Icon } from 'app/Components/UI/Icons'
import * as Style from './style'
import Types from './types'

/**
 * Componente que muestra el array de recomendaciones en la vista detalle de clase en vivo y clase presencial
 * @example <ClassRecommendations recommendations={recommendations[]} provider={provider} />
 */
export const Recommendations = ({
  recommendations,
  provider,
  considerations,
}: Types.Recommendations) => {
  const { t } = useTranslation()

  if (!recommendations) {
    return null
  }

  if (recommendations?.length <= 0) {
    return null
  }

  return (
    <Style.ClassRecommendations data-testid="Recommendations" className="RecommendationsClass">
      <h3 className="title">{t('F2FRecommendation')}</h3>
      <ul className="recommendations" data-testid="recommendations">
        {recommendations?.map(({ icon, message }: any, key: number) => (
          <li key={key}>
            <Icon name={icon} fill={'#B0CFE0'} />
            <span className="text">{ReactHtmlParser(message)}</span>
          </li>
        ))}
      </ul>
      {provider && (
        <Style.LinksStores>
          <a href={provider?.urlIos} target="_blank" rel="noopener noreferrer">
            <img src={ios} alt="App Store" />
          </a>
          <a href={provider?.urlAndroid} target="_blank" rel="noopener noreferrer">
            <img src={android} alt="Google play" />
          </a>
        </Style.LinksStores>
      )}
      <br></br>
      {considerations && considerations.length > 0 && (
        <h3 className="title">{t('F2FConsideration')}</h3>
      )}
      <ul className="recommendations" data-testid="recommendations">
        {considerations &&
          considerations!.map(({ icon, message }, key) => (
            <li key={key}>
              <Icon name={icon} fill={'#B0CFE0'} />
              <span className="text">{ReactHtmlParser(message)}</span>
            </li>
          ))}
      </ul>
    </Style.ClassRecommendations>
  )
}
