import { FooterBoxes } from '@eclass/api'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Store } from 'app/Redux/types'

/**
 * Obtengo los datos de las cajas del centro de ayuda
 */
export const useHelpCenter = (isSence = false) => {
  const {
    courses: {
      getCourseInfo: { footerBoxes },
    },
    ui: { HELP_CENTER },
  } = useSelector((state: Store) => state)
  const location = useLocation()
  const [data, setData] = useState<FooterBoxes[]>([])

  /**
   * Url que no tienen que ver con un curso
   */
  const outsideCourse = ['/courses', '/profile', '/courses-past']

  useEffect(() => {
    /**
     * Si estoy fuera del curso, tomo la info desde el campus,
     * sino desde la info del curso
     */
    if (outsideCourse.includes(location.pathname)) {
      setData(HELP_CENTER?.FooterBoxes || [])
    } else {
      setData(footerBoxes || [])
    }
  }, [HELP_CENTER, footerBoxes])

  return { data, isSence }
}
