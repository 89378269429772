export const AlertSuccess = (props) => {
  return (
    <g {...props}>
      <circle cx="6" cy="6" r="6" fill="#28A745" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.41421 5.53549L2 6.94971L3.41421 8.36392L4.82843 9.77813L9.77817 4.82839L8.36396 3.41417L4.82843 6.94971L3.41421 5.53549Z"
        fill="white"
      />
    </g>
  )
}
