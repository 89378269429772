export const RankingStars = (props) => {
  return (
    <g {...props} fillRule="evenodd">
      <path
        {...props}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.46353 11.2548C7.17317 11.1029 6.82682 11.1029 6.53647 11.2548L4.08389 12.5378C3.35115 12.9211 2.49629 12.3 2.63441 11.4846L3.09672 8.75564C3.15145 8.43256 3.04443 8.10316 2.81025 7.87395L0.832155 5.93788C0.241176 5.35945 0.567704 4.3545 1.38581 4.23391L4.12411 3.83029C4.44829 3.7825 4.72849 3.57892 4.87412 3.28538L6.10417 0.80582C6.47166 0.0650214 7.52833 0.0650218 7.89583 0.80582L9.12588 3.28538C9.2715 3.57892 9.5517 3.7825 9.87588 3.83029L12.6142 4.23391C13.4323 4.3545 13.7588 5.35945 13.1678 5.93788L11.1897 7.87395C10.9556 8.10316 10.8485 8.43256 10.9033 8.75564L11.3656 11.4846C11.5037 12.3 10.6488 12.9211 9.91611 12.5378L7.46353 11.2548Z"
        fill="#FFBC27"
      />
    </g>
  )
}
