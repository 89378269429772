import React from 'react'
import { useSelector } from 'react-redux'

import { ContentParser, ContentArticle } from 'app/Components'
import { Wrapper } from './Style'
import { Question } from '../types'
import { Store } from '../../../../Courses/types'

export const TextAndMultimedia = ({ answer }: Question) => {
  const { isMobile } = useSelector((store: Store) => store.ui)

  return (
    <Wrapper
      className={`Question ${isMobile ? 'Mobile' : ''}`}
      id={`question-${answer.config?.id!}`}
    >
      <ContentArticle className="Question__Content">
        {ContentParser({
          content: answer.content!,
          embeds: answer.embeds,
        })}
      </ContentArticle>
    </Wrapper>
  )
}
