import { Button } from './Button'
import { ModalUnit } from './ModalUnit'
import { useLogic } from './useLogic'
import { Props } from './types'
import { Download as Style } from './style'
import { useViewDownload } from './useViewDownload'

export const Download = ({ unit, center, isMaterial }: Props.Download) => {
  const { clickButton, getResources, storePDF } = useLogic({ unit })
  const isViewDownload = useViewDownload()

  if (isViewDownload) {
    return <></>
  }

  // Classes que puede tener el componente
  const isOfUnit = unit ? 'isOfUnit' : ''
  const isCenter = center ? 'isCenter' : ''

  return (
    <Style className={`download ${isOfUnit} ${isCenter}`} data-testid="DownloadPDF">
      {/* boton de descarga */}
      <Button onClick={clickButton} unit={unit} isMaterial={isMaterial} />

      {/* modal unidad */}
      {storePDF.id === unit?.id && (
        <ModalUnit getResources={() => getResources(unit?.id!)} unit={unit} />
      )}
    </Style>
  )
}
