import { useSelector } from 'react-redux'

import { Store } from 'app/Redux/types'

export const useShowProfile = () => {
  const { ui } = useSelector((state: Store) => state)

  let showProfile = true
  if (!ui.showProfile) {
    showProfile = false
  }

  return { showProfile }
}
