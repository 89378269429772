import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useFormContext } from 'react-hook-form'

import { getAllInstitutions } from 'app/Redux/Actions'
import { getOption } from '../Common'
import { Types } from '../types'

/**
 * hooks que obtine lista paises e intituciones
 * @example
 * const { institutions, status } = useAllInstitution()
 */
export const useAllInstitutions = (toCall: boolean = true) => {
  const dispatch = useDispatch()
  const FormContext = useFormContext()
  const { getValues } = FormContext

  const [institutions, setInstitutions] = React.useState([])
  const [status, setStatus] = React.useState('initial')

  const load = React.useCallback(
    async (input?: string) => {
      const { institutionTypeId, countryId, institutionId, otherInstitution } = getValues()

      if (institutionTypeId && countryId) {
        setStatus('loading')
        const response = await dispatch<any>(
          getAllInstitutions(
            institutionTypeId.value || institutionTypeId,
            countryId.value || countryId
          )
        )
        if (response && response.status) {
          const list = response.data.map((item: Types.InstitutionType) => {
            return {
              label: item.name,
              value: item.id,
            }
          })
          getOption({ FormContext, options: list, input: 'institutionId' })
          setInstitutions(list)
          setStatus('success')
          if (input && (institutionId || otherInstitution)) {
            const cleanOptions = [
              { name: 'institutionId', value: null },
              { name: 'otherInstitution', value: '' },
            ]
            cleanOptions.forEach(({ name, value }) => FormContext.setValue(name, value))
          }
        } else {
          setStatus('error')
        }
      }
    },
    [dispatch]
  )

  React.useEffect(() => {
    if (
      toCall &&
      status === 'initial' &&
      FormContext.watch('countryId') &&
      FormContext.watch('institutionTypeId')
    ) {
      load()
    }
  }, [status])

  return {
    institutions,
    load,
    status,
  }
}
