import { required as defaultRequired } from './'

type typeRequired = {
  value: boolean
  message: string
}

export const handleRequired = (required?: typeRequired | boolean) => {
  let statusRequired: typeRequired | boolean = defaultRequired

  if (required !== undefined && !required) {
    statusRequired = false
  }

  return statusRequired
}
