import i18n from 'i18next'
import { spa, eng, por } from '@eclass/translations'

import localSpa from './spa'
import localEng from './eng'
import localPor from './por'

i18n.init({
  // we init with resources
  resources: {
    'es-419': {
      translations: {
        ...localSpa,
        ...spa,
      },
    },
    'en-EN': {
      translations: {
        ...localEng,
        ...eng,
      },
    },
    por: {
      translations: {
        ...localPor,
        ...por,
      },
    },
  },
  lng: 'es',
  fallbackLng: {
    'es-ES': ['es-419'],
    spa: ['es-419'],
    'es-CL': ['es-419'],
    eng: ['en-EN'],
    'en-US': ['en-EN'],
    default: ['es-419'],
  },
  debug: false,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },

  react: {
    useSuspense: true,
  },
})

i18n.services.pluralResolver.addRule('por', i18n.services.pluralResolver.getRule('en'))

export default i18n
