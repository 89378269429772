import React from 'react'

export const PersonalClassIcon = () => (
  <svg
    width="46"
    data-testid="PresencialIcon"
    height="45"
    viewBox="0 0 46 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="23.3323" cy="22.5" r="22.5" fill="#E0EEFA" />
    <path
      d="M28.8323 29C28.8323 27.8954 29.7277 27 30.8323 27H32.8323C33.9368 27 34.8323 27.8954 34.8323 29V32H28.8323V29Z"
      fill="#1EBDAF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.8323 35C34.0414 35 35.8323 33.2091 35.8323 31C35.8323 28.7909 34.0414 27 31.8323 27C29.6231 27 27.8323 28.7909 27.8323 31C27.8323 33.2091 29.6231 35 31.8323 35Z"
      stroke="#08044F"
      strokeWidth="2"
    />
    <path
      d="M39.8323 41.999C38.0078 39.5707 35.1035 38 31.8323 38C28.5611 38 25.6567 39.5707 23.8323 41.999"
      stroke="#08044F"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M32.8323 9H26.8323C26.28 9 25.8323 9.44772 25.8323 10V12C25.8323 12.5523 26.28 13 26.8323 13H32.8323C33.3846 13 33.8323 12.5523 33.8323 12V10C33.8323 9.44772 33.3846 9 32.8323 9Z"
      fill="#FF554D"
    />
    <path
      d="M41.8323 15H26.8323C26.28 15 25.8323 15.4477 25.8323 16V18C25.8323 18.5523 26.28 19 26.8323 19H41.8323C42.3846 19 42.8323 18.5523 42.8323 18V16C42.8323 15.4477 42.3846 15 41.8323 15Z"
      fill="#0189FF"
    />
    <path
      d="M12.8323 16C12.8323 14.8954 13.7277 14 14.8323 14H18.8323C19.9368 14 20.8323 14.8954 20.8323 16V20H12.8323V16Z"
      fill="#FFBC27"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8323 23C19.5937 23 21.8323 20.7614 21.8323 18C21.8323 15.2386 19.5937 13 16.8323 13C14.0709 13 11.8323 15.2386 11.8323 18C11.8323 20.7614 14.0709 23 16.8323 23Z"
      stroke="#08044F"
      strokeWidth="2"
    />
    <path
      d="M7.83227 34C7.83227 29.5817 11.7125 26 16.4989 26C18.9983 26 21.2505 26.9766 22.8323 28.539"
      stroke="#08044F"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M7.83228 17.7895V8C7.83228 6.89543 8.72771 6 9.83228 6H37.8323C38.9368 6 39.8323 6.89543 39.8323 8V20C39.8323 21.1046 38.9368 22 37.8323 22H28.8323"
      stroke="#08044F"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)
