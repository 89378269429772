/**
 * Obtengo la data actual que tiene el servidor,
 * si llegase a fallar la api devuelvo hora del cliente.
 */
export const useNewDate = () => {
  /**
   * Obtengo hora y ejecuto el callback
   */
  const getDate = async (callback: (newDate: Date) => void) => {
    try {
      const ApiServerTime = process.env.REACT_APP_API_URL + '/wtit'
      const response = await fetch(ApiServerTime!)
      if (response.ok) {
        const data = (await response.json()) as {
          currentTime: Date
          fullFormat: string
          offsetMS: number
        }
        callback(data.currentTime)
      } else {
        throw new Error('Error al obtener la hora desde la API')
      }
    } catch (error) {
      callback(new Date())
    }
  }

  return {
    getDate,
  }
}
