import { useSelector, useDispatch } from 'react-redux'

import * as Redux from 'app/Redux/types'
import {
  multipleAnswer,
  multipleChoice,
  typeIn,
  selectInTheBlank,
  reorder,
} from '../../../Tests/Questions/QuestionWrapper/idsQuetionTypes'
import { getOptionsRandom } from './getOptionsRandom'

/**
 * Lógica para rehacer o borrar incorrectas de un ejercicio
 *
 * Se trata cada tipo de ejercicio individualmente, si se llega a crear otro ejercicio se debe validar sus datos
 */
export const useRedo = () => {
  const state = useSelector((store: Redux.Store) => store.exercises)
  const dispatch = useDispatch()

  /**
   * Modifica los valores de los ejercicios al estado del caso correspondiente: Rehacer y Borrar incorrectas
   *
   * Rehacer (isRedo)
   * Limpia el ejercicio a los valores iniciales (si nada seleccionado)
   *
   * Borrar incorrectas (!isRedo)
   * Borra solo las respuestas malas, dejando los valores de las correctas
   */
  const redo = (isRedo: boolean) => {
    const isDeleteWrong = !isRedo
    const typeId = state.answerType.id

    // Array para limpiar las opciones seleccionadas del reorder
    let reorderArr: string[][] = []

    // Array con las preguntas, estan quedan como no responidas y con su valor modificado dependiendo de si esta correcta o no
    const answersClean = [...state.answers].map((answer) => {
      if ([multipleChoice, multipleAnswer].includes(typeId)) {
        // Este caso abarca cuando vienen opciones dentro de la pregunta
        if (isRedo || (isDeleteWrong && !answer.correct)) {
          // Rehacer y borrar incorrecta
          return {
            ...answer,
            answered: false,
            correct: false,
            options: answer.options.map((option) => ({
              ...option,
              selected: false,
            })),
          }
        } else if (isDeleteWrong && answer.correct) {
          // caso correcto, solo cambio el estado de si esta respondida
          return { ...answer, answered: false, correct: false }
        }
      } else if (
        [typeIn, selectInTheBlank].includes(typeId) &&
        (isRedo || (isDeleteWrong && !answer.correct))
      ) {
        // Este caso es solo cuando tiene un valor como opción

        return { ...answer, value: '', correct: null }
      } else if (typeId === reorder) {
        // Caso especial reorder, ya que se debe tocar otra llave de la store de redux
        if (isRedo || (isDeleteWrong && !answer.correct)) {
          // Esto vuelve todo al estado inicial(opciones vacias)
          reorderArr = [...reorderArr, []]

          // obtengo el array desordenado
          const optionsRandom = getOptionsRandom(answer.options)

          return {
            ...answer,
            correct: null,
            options: optionsRandom.map((option) => ({
              ...option,
              selected: false,
            })),
          }
        } else if (isDeleteWrong && answer.correct) {
          // Modifica las correctas manteniendo las opciones seleccionas
          reorderArr = [
            ...reorderArr,
            answer.options.map((option) => {
              return option.code
            }),
          ]

          return {
            ...answer,
            correct: true,
            options: answer.options.map((option) => ({
              ...option,
              selected: true,
            })),
          }
        }
      }
      return answer
    })

    dispatch({
      type: 'EXERCISE_REDO',
      payload: {
        answers: answersClean,
        reorder: reorderArr,
        score: {
          ...state.score,
          obtained: 0,
        },
        setScore: {
          obtained: state.setScore.obtained - state.score.obtained,
          max: state.setScore.max,
        },
      },
    })
  }

  return { redo }
}
