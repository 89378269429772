import { useEffect, useState } from 'react'

import { TAudio } from './Podcast'

export const useLogicProgress = (audioRef: TAudio) => {
  const [isPlaying, setisPlaying] = useState(false)
  const [time, setTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [successful, setSuccessful] = useState(false)
  const [attempts, setAttempts] = useState(0)

  useEffect(() => {
    const handleTimeUpdate = () => {
      if (audioRef) {
        setTime(Math.trunc(audioRef.currentTime))
      }
    }

    const handleSeeked = () => {
      if (audioRef) {
        setTime(Math.trunc(audioRef.currentTime))
      }
    }

    if (audioRef) {
      audioRef.addEventListener('timeupdate', handleTimeUpdate)
      audioRef.addEventListener('seeked', handleSeeked)

      return () => {
        audioRef.removeEventListener('timeupdate', handleTimeUpdate)
        audioRef.removeEventListener('seeked', handleSeeked)
      }
    }
  }, [audioRef])

  useEffect(() => {
    const maxAttempts = 3 // Número máximo de intentos
    const waitingTime = 2000 // Tiempo de espera en milisegundos (5 segundos)

    if (!successful && attempts < maxAttempts) {
      // Configura un temporizador para volver a cargar el audio después del tiempo de espera
      const temporizador = setTimeout(() => {
        handleReloadAudio()
      }, waitingTime)

      return () => clearTimeout(temporizador) // Limpia el temporizador al desmontar el componente
    }
  }, [successful, attempts])

  const handleLoadedMetadata = (event) => {
    const audioElement = event.target
    const duracionEnSegundos = Math.floor(audioElement.duration)
    setDuration(duracionEnSegundos)
    !successful && setSuccessful(true)
  }

  const handleReloadAudio = () => {
    if (audioRef) {
      audioRef.load() // Carga el audio nuevamente
      setAttempts(attempts + 1) // Incrementa el contador de attempts
    }
  }
  return {
    time,
    duration,
    handleLoadedMetadata,
    handleReloadAudio,
    isPlaying: { value: isPlaying, set: setisPlaying },
  }
}
