import React from 'react'

export const GradeAverageIcon = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 3H29C29.5523 3 30 3.44772 30 4V8C30 8.55228 29.5523 9 29 9H17C16.4477 9 16 8.55228 16 8V4C16 3.44772 16.4477 3 17 3H20C20 1.34315 21.3431 0 23 0C24.6569 0 26 1.34315 26 3Z"
        fill="#FF554D"
      />
      <rect x="15" y="29" width="11" height="4" rx="1" fill="#FFBC27" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 22C15 21.4477 15.4477 21 16 21L20 21C20.5523 21 21 21.4477 21 22C21 22.5523 20.5523 23 20 23L16 23C15.4477 23 15 22.5523 15 22Z"
        fill="#08044F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 22C23 21.4477 23.4477 21 24 21L25 21C25.5523 21 26 21.4477 26 22C26 22.5523 25.5523 23 25 23L24 23C23.4477 23 23 22.5523 23 22Z"
        fill="#08044F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 26C23 25.4477 23.4477 25 24 25L25 25C25.5523 25 26 25.4477 26 26C26 26.5523 25.5523 27 25 27L24 27C23.4477 27 23 26.5523 23 26Z"
        fill="#08044F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 26C15 25.4477 15.4477 25 16 25L20 25C20.5523 25 21 25.4477 21 26C21 26.5523 20.5523 27 20 27L16 27C15.4477 27 15 26.5523 15 26Z"
        fill="#08044F"
      />
      <path
        d="M30 13H16C15.4477 13 15 13.4477 15 14V18C15 18.5523 15.4477 19 16 19H30C30.5523 19 31 18.5523 31 18V14C31 13.4477 30.5523 13 30 13Z"
        fill="#1EBDAF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 16C13 15.4477 13.4477 15 14 15L20 15C20.5523 15 21 15.4477 21 16C21 16.5523 20.5523 17 20 17L14 17C13.4477 17 13 16.5523 13 16Z"
        fill="#08044F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7C11.4477 7 11 7.44772 11 8V36.5375C11 37.3793 11.6494 38 12.3757 38H33C33.5523 38 34 37.5523 34 37V31C34 30.4477 34.4477 30 35 30C35.5523 30 36 30.4477 36 31V37C36 38.6569 34.6569 40 33 40H12.3757C10.4779 40 9 38.4157 9 36.5375V8C9 6.34315 10.3431 5 12 5H32.6243C34.5221 5 36 6.58434 36 8.46252V13C36 13.5523 35.5523 14 35 14C34.4477 14 34 13.5523 34 13V8.46252C34 7.62067 33.3505 7 32.6243 7H12Z"
        fill="#08044F"
      />
      <path
        d="M39.1421 14.4142C39.9232 13.6332 41.1895 13.6332 41.9705 14.4142L43.3848 15.8284C44.1658 16.6095 44.1658 17.8758 43.3848 18.6569L41.9705 20.0711L37.7279 15.8284L39.1421 14.4142Z"
        fill="#0189FF"
      />
      <path
        d="M36.3137 17.2426L40.5564 21.4853L33.2701 28.7715C33.0203 29.0213 32.7087 29.2005 32.3671 29.2908L28.783 30.2384C28.0416 30.4343 27.3646 29.7573 27.5606 29.016L28.5081 25.4319C28.5985 25.0903 28.7777 24.7787 29.0275 24.5288L36.3137 17.2426Z"
        fill="#0189FF"
      />
    </svg>
  )
}
