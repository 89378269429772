import { UIVars } from 'app/Components/UI'

export const CircularNeutral = (props) => {
  return (
    <g {...props} stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect
        id="Rectangle"
        fill={UIVars.setColors.icons}
        x="0"
        y="0"
        width="16"
        height="16"
        rx="8.5"
      ></rect>
      <g
        id="Group"
        transform="translate(8.500000, 8.500000) rotate(-47.000000) translate(-8.500000, -8.500000) translate(5.000000, 5.000000)"
        stroke="#FFF"
        strokeLinecap="square"
        strokeWidth="1.5"
      >
        <line x1="0.963501331" y1="0.726786907" x2="6.09704098" y2="6.2318342" id="Line-17"></line>
      </g>
    </g>
  )
}
