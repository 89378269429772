import { Message } from '@aws-sdk/eventstream-marshaller'
import Mic from 'microphone-stream'

export const convertAudioToBinaryMessage = ({
  audioChunk,
  inputSampleRate,
  sampleRate,
  eventStreamMarshaller,
}: any): any => {
  const raw = Mic.toRaw(audioChunk)
  // eslint-disable-next-line eqeqeq
  if (raw == null) return

  // downsample and convert the raw audio bytes to PCM
  const downsampledBuffer = downsampleBuffer(raw, inputSampleRate, sampleRate)
  const pcmEncodedBuffer = pcmEncode(downsampledBuffer)

  // add the right JSON headers and structure to the message
  const audioEventMessage = getAudioEventMessage(Buffer.from(pcmEncodedBuffer))

  // convert the JSON object + headers into a binary event stream message
  const binary = eventStreamMarshaller.marshall(audioEventMessage)

  return binary
}

const downsampleBuffer = (
  buffer: any,
  inputSampleRate2: number = 44100,
  outputSampleRate: number = 16000
) => {
  if (outputSampleRate === inputSampleRate2) {
    return buffer
  }

  const sampleRateRatio = inputSampleRate2 / outputSampleRate
  const newLength = Math.round(buffer.length / sampleRateRatio)
  const result = new Float32Array(newLength)
  let offsetResult = 0
  let offsetBuffer = 0

  while (offsetResult < result.length) {
    const nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio)

    let accum = 0
    let count = 0

    for (let i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
      accum += buffer[i]
      count++
    }

    result[offsetResult] = accum / count
    offsetResult++
    offsetBuffer = nextOffsetBuffer
  }

  return result
}

const getAudioEventMessage = (buffer: Buffer): Message => {
  // wrap the audio data in a JSON envelope
  return {
    headers: {
      ':message-type': {
        type: 'string',
        value: 'event',
      },
      ':event-type': {
        type: 'string',
        value: 'AudioEvent',
      },
    },
    body: buffer,
  }
}

const pcmEncode = (input: any) => {
  let offset = 0
  const buffer = new ArrayBuffer(input.length * 2)
  const view = new DataView(buffer)
  for (let i = 0; i < input.length; i++, offset += 2) {
    const s = Math.max(-1, Math.min(1, input[i]))
    view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true)
  }
  return buffer
}
