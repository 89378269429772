import React from 'react'

export const MeetEclassIcon = () => (
  <svg
    width="45"
    height="45"
    data-testid="MeetEclassIcon"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
    <path
      d="M27 16C27 14.8954 27.8954 14 29 14H33C34.1046 14 35 14.8954 35 16V21H27V16Z"
      fill="#0189FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 19C25 15.6863 27.6863 13 31 13C34.3137 13 37 15.6863 37 19C37 22.3137 34.3137 25 31 25C27.6863 25 25 22.3137 25 19ZM31 15C28.7909 15 27 16.7909 27 19C27 21.2091 28.7909 23 31 23C33.2091 23 35 21.2091 35 19C35 16.7909 33.2091 15 31 15Z"
      fill="#08044F"
    />
    <path
      d="M9.85715 9.85712C9.85715 8.75255 10.7526 7.85712 11.8571 7.85712H18.1429C19.2474 7.85712 20.1429 8.75255 20.1429 9.85712V15.5714H9.85715V9.85712Z"
      fill="#FFBC27"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 13C8 9.13401 11.134 6 15 6C18.866 6 22 9.13401 22 13C22 16.866 18.866 20 15 20C11.134 20 8 16.866 8 13ZM15 8C12.2386 8 10 10.2386 10 13C10 15.7614 12.2386 18 15 18C17.7614 18 20 15.7614 20 13C20 10.2386 17.7614 8 15 8Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0222 22C10.5673 22 7.4532 23.4667 5.26869 25.809C4.89201 26.2129 4.91407 26.8457 5.31796 27.2224C5.72185 27.5991 6.35463 27.577 6.73131 27.1731C8.55385 25.2189 11.1454 24 14.0222 24C19.5309 24 24 28.4752 24 34C24 34.5523 24.4477 35 25 35C25.5523 35 26 34.5523 26 34C26 32.8752 25.8455 31.7864 25.5566 30.7539C27.0606 29.6516 28.9192 29 30.9345 29C33.8393 29 36.4232 30.3551 38.0831 32.4645C38.4246 32.8986 39.0533 32.9736 39.4873 32.632C39.9214 32.2905 39.9963 31.6618 39.6548 31.2278C37.6302 28.6548 34.4756 27 30.9345 27C28.6804 27 26.5812 27.671 24.8311 28.8232C22.9022 24.7879 18.788 22 14.0222 22Z"
      fill="#08044F"
    />
    <path
      d="M18.5 41L18.5 33C18.5 32.4477 18.0523 32 17.5 32L11.5172 32L5.27298 32C4.90407 32 4.56512 32.2031 4.39115 32.5284L2.25216 36.5284C2.09461 36.8231 2.09461 37.1769 2.25216 37.4716L4.39115 41.4716C4.56512 41.7969 4.90407 42 5.27298 42L11.5172 42L17.5 42C18.0523 42 18.5 41.5523 18.5 41Z"
      fill="#1EBDAF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.12133 40.364C9.51185 40.7545 10.145 40.7545 10.5355 40.364C10.9261 39.9734 10.9261 39.3403 10.5355 38.9498L9.41422 37.8284L15.8285 37.8284C16.3808 37.8284 16.8285 37.3807 16.8285 36.8284C16.8285 36.2761 16.3808 35.8284 15.8285 35.8284L9.41422 35.8284L10.5355 34.7071C10.9261 34.3166 10.9261 33.6834 10.5355 33.2929C10.145 32.9024 9.51185 32.9024 9.12133 33.2929L7.00001 35.4142C6.21896 36.1953 6.21896 37.4616 7.00001 38.2426L9.12133 40.364Z"
      fill="#08044F"
    />
    <path
      d="M25 2V10C25 10.5523 25.4477 11 26 11H31H40.2248C40.5642 11 40.8805 10.8278 41.0647 10.5427L43.6493 6.54272C43.8628 6.21238 43.8628 5.78762 43.6493 5.45728L41.0647 1.45728C40.8805 1.17219 40.5642 1 40.2248 1H31H26C25.4477 1 25 1.44772 25 2Z"
      fill="#FF554D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.7072 2.46446C35.3166 2.07394 34.6835 2.07394 34.2929 2.46446C33.9024 2.85499 33.9024 3.48815 34.2929 3.87868L35.4143 5H29C28.4477 5 28 5.44771 28 6C28 6.55228 28.4477 7 29 7H35.4143L34.2929 8.12132C33.9024 8.51184 33.9024 9.14501 34.2929 9.53553C34.6835 9.92605 35.3166 9.92605 35.7072 9.53553L37.8285 7.41421C38.6095 6.63316 38.6095 5.36683 37.8285 4.58578L35.7072 2.46446Z"
      fill="#08044F"
    />
  </svg>
)
