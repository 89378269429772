// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { getToken, axiosError } from '../../../Services'

/**
 * Lista las regiones de un país ordenadas por id ascendente
 * @param {number} countryId - Id del País con el que se filtrarán las regiones.
 * @example
 * ```js
 *    const { data } = await getRegions(33)
 * ```
 * @returns {Promise<any>} Listado de Regiones por país (id, name)
 */
const getRegions = (countryId) => {
  const requestId = 'getRegions'
  return async (dispatch) => {
    getToken()

    const query = `
      query allRegion2s {
        allRegion2s(
          paginate: 1000
          filter: {
            countryId: ${countryId}
          }
          orderBy: id_ASC
        ){
          name
          id
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then((response) => {
        const { allRegion2s } = response.data.data
        if (response.status === 200 && allRegion2s) {
          return {
            data: allRegion2s,
            status: {
              success: true,
              detail: 'Petición exitosa',
            },
          }
        } else {
          return {
            data: [],
            status: {
              success: false,
              detail: 'Error al buscar las regiones del país.',
            },
          }
        }
      })
      .catch((err) => axiosError(err, requestId, dispatch))
  }
}

export default getRegions
