import { ModalType } from '../types'

/** Dependiendo del tipo de modal retorna el nombre del icono
 * @param {ModalType} typeModal - Tipo de modal
 * @returns {string} - Nombre del icono
 */
export const modalTypeToIcon = (typeModal: ModalType) => {
  const modalOptionsToIcon = {
    helper: 'courseIcon',
    test: 'evaluation',
    resource: 'courseIcon',
    work: 'evaluation',
  }

  return modalOptionsToIcon[typeModal]
}
