import React from 'react'

export const WelcomeFooterIcon = () => (
  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="22.5" cy="22.5" r="22.5" fill="#ffffff" />
    <path
      d="M25 10H15C13.8954 10 13 10.8954 13 12V16C13 17.1046 13.8954 18 15 18H25V10Z"
      fill="#FFBC27"
    />
    <path
      d="M31 28C32.1046 28 33 28.8954 33 30L33 34C33 35.1046 32.1046 36 31 36L21 36L21 28L31 28Z"
      fill="#FF554D"
    />
    <path
      d="M11 28H3C1.89543 28 1 28.8954 1 30V32V34C1 35.1046 1.89543 36 3 36H11C12.1046 36 13 35.1046 13 34V30C13 28.8954 12.1046 28 11 28Z"
      fill="#1EBDAF"
    />
    <path
      d="M34 18L40.1716 18C40.702 18 41.2107 17.7893 41.5858 17.4142L43.5858 15.4142C44.3668 14.6332 44.3668 13.3668 43.5858 12.5858L41.5858 10.5858C41.2107 10.2107 40.702 10 40.1716 10L34 10C33.4477 10 33 10.4477 33 11L33 17C33 17.5523 33.4477 18 34 18Z"
      fill="#0189FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27 14C27 16.2091 25.2091 18 23 18C20.7909 18 19 16.2091 19 14C19 11.7909 20.7909 10 23 10C25.2091 10 27 11.7909 27 14ZM28.9176 14.9967C28.4973 17.5107 26.5136 19.4953 24 19.917V26.083C26.8377 26.559 29 29.027 29 32C29 35.3137 26.3137 38 23 38C20.027 38 17.559 35.8377 17.083 33H11C10.4477 33 10 32.5523 10 32C10 31.4477 10.4477 31 11 31H17.083C17.5045 28.4875 19.4875 26.5045 22 26.083V19.917C19.1623 19.441 17 16.973 17 14C17 10.6863 19.6863 8 23 8C25.9742 8 28.4429 10.164 28.9176 13.0033C28.9448 13.0011 28.9723 13 29 13H35C35.5523 13 36 13.4477 36 14C36 14.5523 35.5523 15 35 15H29C28.9723 15 28.9448 14.9989 28.9176 14.9967ZM19 32C19 34.2091 20.7909 36 23 36C25.2091 36 27 34.2091 27 32C27 29.7909 25.2091 28 23 28C20.7909 28 19 29.7909 19 32ZM41 15C41.5523 15 42 14.5523 42 14C42 13.4477 41.5523 13 41 13H39C38.4477 13 38 13.4477 38 14C38 14.5523 38.4477 15 39 15H41ZM8 32C8 32.5523 7.55228 33 7 33H5C4.44772 33 4 32.5523 4 32C4 31.4477 4.44772 31 5 31H7C7.55228 31 8 31.4477 8 32Z"
      fill="#08044F"
    />
  </svg>
)
