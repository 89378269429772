// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { getToken, axiosError } from '../../../Services'

/**
 * Busca todos los tipos de teléfonos ordenados por nombre Ascendente
 *
 * @example
 * ```js
 *    const response = await getPhoneType()
 * ```
 * @returns Retorna una Lista con los Tipos de Teléfono (id, name)
 */
const getPhoneType = () => {
  const requestId = 'getPhoneType'
  return async (dispatch) => {
    getToken()

    const query = `
      query allPhoneType2s{
        allPhoneType2s(
          orderBy: name_ASC
        ){
          name
          id
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then((response) => {
        const { allPhoneType2s } = response.data.data
        if (response.status === 200 && allPhoneType2s) {
          return {
            data: allPhoneType2s,
            status: {
              success: true,
              detail: 'Petición exitosa',
            },
          }
        } else {
          return {
            data: [],
            status: {
              success: false,
              detail: 'Error al buscar las Industrias.',
            },
          }
        }
      })
      .catch((err) => axiosError(err, requestId, dispatch))
  }
}

export default getPhoneType
