import React from 'react'
import { DropdownItem } from 'reactstrap'
import { useParams } from 'react-router-dom'

import { Icon } from 'app/Components/UI/Icons'
import { UIVars, Pressed, DelayLink } from 'app/Components/UI'
import { IconProps } from './Types'
import * as ItemStyle from './ItemStyle'
import { RouteParams } from 'Config/routes/types'

const ITEM_COMPLETED = [3, 8, 9]

const SetIcon = ({ link, index }: IconProps) => {
  const paramsSplit = link.href!.split('/')
  const resourceParam = paramsSplit[paramsSplit.length - 1]
  const { resourceId } = useParams<RouteParams>()

  if (link.status!.enabled) {
    if (link.status!.style === 'on' || resourceParam === resourceId) {
      return <Icon fill={UIVars.setColors.primary} name="in-progress" />
    } else {
      return link.status!.detail === 'completed' || ITEM_COMPLETED.includes(link.status!.id) ? (
        <Icon fill={UIVars.setColors.completed} name="completed" />
      ) : (
        index + 1
      )
    }
  } else {
    return <Icon fill={UIVars.setColors.veryLightPink} name="blocked" />
  }
}

function MenuItemResource({ link, index }: IconProps) {
  return (
    <ItemStyle.Resource key={index}>
      <DropdownItem
        key={index}
        className={`DropdownItem  ${link.status!.style} ${link.status!.detail}`}
      >
        <Pressed dark>
          <DelayLink disabled={!link.href} to={link.href!} className="cursor">
            <span className="resourceState">
              <SetIcon link={link} index={index} />
            </span>
            <span className={`resourceTitle ${link.status!.style}`}>{link.name}</span>
          </DelayLink>
        </Pressed>
      </DropdownItem>
    </ItemStyle.Resource>
  )
}

export default MenuItemResource
