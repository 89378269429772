import React from 'react'
import { Input, Label } from 'reactstrap'

import * as Style from './Style'
import './style.scss'
import { ContentParser } from 'app/Components'
import { IconStatus } from '../../../IconStatus'

const BaseMultiple = ({
  option,
  seeCorrect,
  correctOption,
  handleChecked,
  optionDisabled,
  answered,
  disabled,
  optionValue,
  id,
  handleChange,
  answer,
  type,
  embeds,
}: any) => {
  const correct = seeCorrect && correctOption
  return (
    <li className="QuestionAlternatives">
      <Label check>
        <div className="QuestionAlternatives__label">
          <span>{option.tag}</span>
          {correct ? (
            <IconStatus type={type} />
          ) : (
            <Input
              checked={handleChecked()}
              disabled={disabled || answered || optionDisabled}
              id={`${id}-option${optionValue}`}
              name={`question-${id}`}
              type={type}
              onChange={handleChange}
            />
          )}
        </div>
        <Style.Content correct={correct}>
          {ContentParser({
            content: option.value,
            embeds: embeds || answer.embeds,
          })}
        </Style.Content>
      </Label>
    </li>
  )
}

export default BaseMultiple
