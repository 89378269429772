import { ApolloClient, HttpLink, InMemoryCache, ApolloLink } from '@apollo/client'

import { auth } from './auth'
import { errors } from './errors'
import { apiURL } from '../../../Config/getDomainConfig'

const link = ApolloLink.from([auth, errors, new HttpLink({ uri: `${apiURL}/graphql` })])

export const client = new ApolloClient({
  connectToDevTools: process.env.NODE_ENV !== 'production',
  link,
  cache: new InMemoryCache(),
})
