interface freeFormat {
  free: string
}

/**
 * @param ddmmyy > jueves 30 de septiembre de 2021
 * @param dmy > 30 sep 2021
 * @param dmyh > 30 sep 2021, 16:00 hrs.
 * @param ddm > jue 30 sep
 * @param h > 16:00 hrs
 * @param m mes > septiembre
 * @param d día > jue
 * @param n día > 30
 * @param z (GMT nº) > (GMT -3)
 * @param {free} > formatea fecha a formato enviado
 * @param ddn (CEV) > jueves 30
 */
export type TFormat =
  | 'ddmmyy'
  | 'dmy'
  | 'dmyh'
  | 'ddm'
  | 'h'
  | 'm'
  | 'd'
  | 'n'
  | 'z'
  | freeFormat
  | 'ddn'

/**
 * Idiomas que se soporta en la plataforma
 *
 * español (es) - ingles (en) - portugués (pt)
 */
export type TLanguage = 'es' | 'en' | 'pt' | 'enUS'

/**
 * Devuelve string con formato de fecha
 * @param type formatos definidos
 * @see https://docs.google.com/document/d/1bNMV49CMXbxtxYhDpzrxWbdD3S9xYq1Lvfz92gFV94E/edit#heading=h.qzf3athftz45
 */
export function formats(type: TFormat = 'dmy', language: TLanguage = 'es') {
  const h = `H:mm 'hrs.'`
  const dmy = 'd MMM yyy'
  const ddmmyy = `EEEE d 'de' MMMM 'de' yyy`
  const m = 'MMMM'
  const d = 'EEE'
  const n = 'd'
  const z = '(OOOO)'
  const ddn = 'EEEE d'

  const base = {
    ddmmyy,
    dmy,
    // dmyh,
    ddm: `EEE d 'de' MMM`,
    h,
    m,
    d,
    n,
    z,
    ddn,
  }

  const typeFormat = {
    es: {
      ...base,
      dmyh: `${dmy}, ${h}`,
    },
    enUS: {
      ...base,
      ddmmyy: 'EEEE MMMM d, yyy',
      dmy: 'MMM do yyy',
      dmyh: `MMM do yyy, h:mm a`,
      ddm: `EEE MMM d`,
      h: 'h:mm a',
    },
    pt: {
      ...base,
      dmyh: `${dmy}, H'h'mm`,
      d: `${d}`,
      h: `H'h'mm`,
    },
  }
  if (typeFormat[language]) {
    if (typeof type !== 'string') {
      return type.free
    } else {
      return typeFormat[language][type] ?? ''
    }
  }
  return ''
}
