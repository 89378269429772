import styled, { css } from 'styled-components/macro'

import { UIVars } from 'app/Components/UI'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px 0;
  width: 100%;

  header {
    color: ${UIVars.setColors.brownGreyTwo};
    display: flex;
    font-size: 16px;
    justify-content: center;
    line-height: 18.75px;
  }
`

/**
 * Wrapper de iconos redondo
 */
const shape = () => css`
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
`

/**
 * Devuelve animación de entrada y salida de los iconos que correspondan al estado del componente
 */
const animation = (start: string, end: string) => {
  return css`
    @keyframes fadeInVoice {
      from {
        opacity: 0;
        top: 80%;
      }
      to {
        opacity: 1;
        top: 50%;
      }
    }

    @keyframes fadeOutVoice {
      from {
        opacity: 1;
        top: 50%;
      }
      to {
        opacity: 0;
        top: 20%;
      }
    }

    .icon-${start} {
      animation: fadeOutVoice 0.2s linear forwards;
      z-index: 1;
    }
    .icon-${end} {
      animation: fadeInVoice 0.2s linear forwards;
      z-index: 2;
    }
  `
}

export const Buttons = styled.div`
  ${shape()}
  background: ${UIVars.setColors.primary};
  cursor: pointer;
  height: 70px;
  margin: 20px auto 0;
  position: relative;
  transition: background 0.2s;
  width: 70px;

  .myIcon {
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }

  &.INIT {
    .icon-init {
      opacity: 1;
      z-index: 1;
    }
  }

  &.RECORDING {
    ${animation('init', 'recording')}

    @keyframes pulse {
      0% {
        box-shadow: 0 0 0 7px ${UIVars.setColors.blueThree};
      }
      70% {
        box-shadow: 0 0 0 10px ${UIVars.setColors.blueThree};
      }
      100% {
        box-shadow: 0 0 0 7px ${UIVars.setColors.blueThree};
      }
    }

    :before {
      animation: pulse 1s ease-in-out infinite;
      border-radius: 50%;
      box-shadow: 0 0 0 7px ${UIVars.setColors.blueThree};
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &.LOADING {
    cursor: no-drop;
    ${animation('recording', 'loading')}
  }

  &.SUCCESS {
    background: ${UIVars.setColors.success};
  }

  &.ERROR {
    background: ${UIVars.setColors.veryLightPinkThree};
  }

  &.NOT_UNDERSTAND {
    background: ${UIVars.setColors.warning};
  }

  &.SUCCESS,
  &.ERROR,
  &.NOT_UNDERSTAND {
    border-radius: 8px;
    height: auto;
    max-width: 388px;
    min-width: 388px;
    min-height: 60px;
    padding-right: 72px;
    padding: 8px 16px;
    transition: opacity 0.3s;
    width: 100%;

    .myIcon {
      display: none;
    }
  }

  &.WAITING {
    cursor: no-drop;
    ${animation('init', 'loading')}
  }

  &.DENIED {
    ${animation('loading', 'init')}
  }

  @media screen and (max-width: 425px) {
    &.SUCCESS,
    &.ERROR,
    &.NOT_UNDERSTAND {
      min-width: auto;
    }
  }
`

export const Redo = styled.div`
  ${shape()}
  background: ${UIVars.setColors.primary};
  cursor: pointer;
  height: 40px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
`
export const FeedbackBox = styled.section`
  transition: opacity 0.3s;
  width: 100%;
  user-select: none;

  &.SUCCESS {
    color: ${UIVars.setColors.jadeGreen};
  }

  &.ERROR {
    color: ${UIVars.setColors.red};
  }

  &.NOT_UNDERSTAND {
    color: #e99e21;
  }
`

export const FeedbackText = styled.span`
  font-size: 14px;
`
export const TranscriptBox = styled.div`
  align-items: baseline;
  display: flex;
  margin-right: 60px;

  .circularIcon {
    color: #e99e21;
  }
`
export const Transcript = styled.div`
  font-weight: 700;
`
