import React from 'react'

export const Question = () => (
  <svg
    width="46"
    height="45"
    viewBox="0 0 46 45"
    fill="none"
    data-testid="QuestionAppliedIcon"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22.8323" cy="22.5" r="22.5" fill="#E0EEFA" />
    <rect x="13.3323" y="23" width="12" height="9" rx="2" fill="#FFBC27" />
    <line
      x1="18.3323"
      y1="27"
      x2="20.3323"
      y2="27"
      stroke="#08044F"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <rect x="14.3323" y="23" width="10" height="12" rx="1" stroke="#08044F" strokeWidth="2" />
    <path
      d="M24.3323 15H14.3323C13.78 15 13.3323 15.4477 13.3323 16V18C13.3323 18.5523 13.78 19 14.3323 19H24.3323C24.8846 19 25.3323 18.5523 25.3323 18V16C25.3323 15.4477 24.8846 15 24.3323 15Z"
      fill="#FF554D"
    />
    <path
      d="M31.3323 9H28.3323C27.78 9 27.3323 9.44772 27.3323 10V12C27.3323 12.5523 27.78 13 28.3323 13H31.3323C31.8846 13 32.3323 12.5523 32.3323 12V10C32.3323 9.44772 31.8846 9 31.3323 9Z"
      fill="#1EBDAF"
    />
    <line
      x1="14.3323"
      y1="11"
      x2="24.3323"
      y2="11"
      stroke="#08044F"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M35.3323 29V37C35.3323 38.1046 34.4368 39 33.3323 39H12.708C11.3959 39 10.3323 37.8975 10.3323 36.5375L10.3323 21V8C10.3323 6.89543 11.2277 6 12.3323 6H19.3323H31.3323H32.9565C34.2686 6 35.3323 7.10251 35.3323 8.46252V15"
      stroke="#08044F"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M42.3323 18H28.3323C27.78 18 27.3323 18.4477 27.3323 19V25C27.3323 25.5523 27.78 26 28.3323 26H29.7971C30.1314 26 30.4437 26.1671 30.6291 26.4453L31.5002 27.7519C31.896 28.3457 32.7685 28.3457 33.1643 27.7519L34.0354 26.4453C34.2209 26.1671 34.5331 26 34.8675 26H42.3323C42.8846 26 43.3323 25.5523 43.3323 25V19C43.3323 18.4477 42.8846 18 42.3323 18Z"
      fill="#0189FF"
    />
    <line
      x1="38.3323"
      y1="22"
      x2="44.3323"
      y2="22"
      stroke="#08044F"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="32.3323"
      y1="22"
      x2="34.3323"
      y2="22"
      stroke="#08044F"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)
