import { createHash, createCipheriv } from 'crypto'

const config = {
  secret_key: process.env.REACT_APP_ECRYPTO_SECRET_KEY || 'superinsegurekey',
  secret_iv: process.env.REACT_APP_ECRYPTO_SECRET_IV || 'ultrasuperinsecuresecretiv123418',
  ecnryption_method: 'aes-256-cbc',
}
// Generate secret hash with crypto to use for encryption
const key = createHash('sha512').update(config.secret_key).digest('hex').substring(0, 32)
const encryptionIV = createHash('sha512').update(config.secret_iv).digest('hex').substring(0, 16)

/**
 * Método encriptación.
 */
export const encryptData = (data: string) => {
  const cipher = createCipheriv(config.ecnryption_method, key, encryptionIV)
  return Buffer.from(cipher.update(data, 'utf8', 'hex') + cipher.final('hex')).toString('base64') // Encrypts data and converts to hex and base64
}
