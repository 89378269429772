import styled from 'styled-components/macro'

import { UIVars } from 'app/Components/UI'

type EnunciatedProps = {
  status?: string | null
}
export const Enunciated = styled.div<EnunciatedProps>`
  color: ${({ status }: EnunciatedProps) => (status ? `${status}` : UIVars.setColors.brownGreyTwo)};
  line-height: 19px;
  max-height: 19px;
  svg {
    margin-right: 8px;
  }
`

export const Content = styled.div`
  display: flex;
  margin-top: 16px;
  & > div {
    width: 50%;
  }
  &.horizontal {
    flex-direction: column;
    & > div {
      width: 100%;
    }
    div.Reorder-Slots {
      display: flex;
      padding-bottom: 0;
      padding-right: 0;
      flex-wrap: wrap;
    }
    div.Reorder-Item {
      margin-bottom: 8px;
      margin-right: 8px;
      width: initial;
    }
    div.Reorder-List {
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;
      min-height: 62px;
      padding-bottom: 8px;
    }
  }
  @media screen and (max-width: 640px) {
    flex-direction: column;
    & > div {
      width: 100%;
    }
    div.Reorder-Item {
      margin-bottom: 8px;
    }
    div.Reorder-Slots {
      padding-bottom: 0;
    }
    div.Reorder-List {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-top: 8px;
      min-height: 62px;
      padding-bottom: 8px;
    }
    &.horizontal {
      div.Reorder-Item {
        min-width: 30px;
        .empty {
          min-width: 73px;
        }
      }
      div.Reorder-List {
        flex-direction: row;
      }
    }
  }
`

export const Slots = styled.div.attrs({ className: 'Reorder-Slots' })`
  padding-bottom: 16px;
  padding-right: 20px;
`

export const Item = styled.div.attrs({ className: 'Reorder-Item' })`
  display: flex;
  margin-bottom: 1rem;
  margin-right: 10px;
  user-select: none;
  vertical-align: top;
`

export const Inner = styled.div`
  background-color: ${UIVars.setColors.veryLightBlue};
  border-radius: 20px;
  border: 1px dashed ${UIVars.setColors.veryLightPink};
  font-weight: bold;
  line-height: 19px;
  min-height: 30px;
  padding: 5px 16px;
  position: relative;
  & > span {
    line-height: 19px !important;
  }
  &.selected,
  &.unselected,
  &.correct {
    border: 0;
    color: ${UIVars.setColors.whiteThree};
    cursor: pointer;
    display: flex;
    padding-right: 16px;
  }
  &.selected {
    background-color: #394855;
    padding-right: 36px;
    &.answered {
      cursor: not-allowed;
    }
  }
  &.unselected {
    background-color: ${UIVars.setColors.completed};
  }
  &.correct {
    background-color: #aadbb6;
    color: ${UIVars.setColors.black};
  }
  &.empty {
    min-width: 130px;
    width: 100%;
  }
  &.blocked {
    background-color: ${UIVars.setColors.veryLightPink};
  }
  &.answered {
    padding-right: 16px;
  }
`

export const Close = styled.div`
  height: 16px;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`

export const List = styled.div.attrs({ className: 'Reorder-List' })`
  background-color: ${UIVars.setColors.whiteTwo};
  border-radius: 10px;
  min-height: 30px;
  padding: 16px 16px 0;
`
