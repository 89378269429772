// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { axiosError, getToken } from '../../../Services'

/**
 * Guardo respuesta en la pregunta aplicada
 *
 * @param {import('@eclass/api').ExerciseAnswerInput} input - Parámetros para guardar repuesta de pregunta aplicada.
 * @example
 * ```js
 *    const input = {
 *      studentId: 111,
 *      courseId: 222,
 *      unitId: 333,
 *      resourceId: 444,
 *      programId: 555
 *      response: 'aaa'
 *    }
 *    const response = await addExerciseAnswer(input)
 * ```
 * @returns {Promise<import('@eclass/api').ExerciseAnswerPayload>} Retorna el Recurso con sus partes (status, Exercise, ExerciseAnswers).
 */

const addExerciseAnswer = (input) => {
  const requestId = 'addExerciseAnswer'
  return async (dispatch) => {
    getToken()
    const answer = `
      id
      text
      modified
      studentId
      User {
        Person {
          id
          name
          fullName
          picture
        }
      }
    `

    /** Quitamos las sub respuestas para mejorar el performance y no muera el esclavo de la bse.
     * @todo refactorizar esto para paginar las respuestas.
     */
    // SubExerciseAnswers{
    //   ${ answer }
    // }

    const query = `
      mutation ExerciseAnswer($ExerciseAnswerInput: ExerciseAnswerInput!) {
        ExerciseAnswer(input: $ExerciseAnswerInput) {
          teacherAnswer
          embeds {
            id
            name
            configurations
            typeId
          }
          qualification {
            value
            valueFormatted
            status {
              id
              enabled
              name
              detail
              style
            }
          }
          ExerciseAnswers {
            ${answer}
            SubExerciseAnswers{
              ${answer}
            }
          }
          status {
            success
            name
            detail
            code
          }
        }
      }
    `

    const variables = {
      ExerciseAnswerInput: {
        studentId: parseInt(input.studentId, 10),
        courseId: parseInt(input.courseId, 10),
        unitId: parseInt(input.unitId, 10),
        resourceId: parseInt(input.resourceId, 10),
        programId: parseInt(input.programId, 10),
        response: input.response,
        limit: input.limit || 10,
      },
    }

    if (input.edit) {
      variables.ExerciseAnswerInput.edit = true
    }
    if (input.answerId) {
      variables.ExerciseAnswerInput.answerId = parseInt(input.answerId, 10)
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then((response) => response.data.data.ExerciseAnswer)
      .catch((err) => axiosError(err, requestId, dispatch))
  }
}

export default addExerciseAnswer
