import { useTranslation } from 'react-i18next'

import { useDateFormat } from 'app/Services'

export const useFormatDates = ({ start, end }: { start: Date; end: Date }) => {
  const { t } = useTranslation()
  const { dateFormat } = useDateFormat()

  const format = (date: Date) =>
    `${dateFormat({
      date,
      format: { free: `d MMM` },
    })}, ${dateFormat({
      date,
      format: 'h',
    })}`

  const dateStart = format(start)
  const dateEnd = format(end)

  const dateTooltip = `<div> <div style="font-weight: 700">${t(
    'ResourceStart'
  )}: ${dateStart}</div>  <div style="font-weight: 700">${t(
    'ResourceEnd'
  )}: ${dateEnd} </div> </div>`

  return {
    dateTooltip,
    dateStart,
    dateEnd,
  }
}
