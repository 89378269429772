// @External Dependencies
import axios from 'axios'

// @Dependencies
// import { PersonJobsInput } from '@eclass/api'
import { apiURL } from '../../../../Config/getDomainConfig'
import { axiosError } from '../../../Services'

// const PersonJobs = (input: PersonJobsInput) => {
const PersonJobs = (input) => {
  const requestId = 'PersonJobs'
  return async (dispatch: any) => {
    const query = `
    mutation PersonJobs($PersonJobsInput: PersonJobsInput!) {
        PersonJobs(input: $PersonJobsInput) {
          job {
            id
            personId
            jobTypeId
            executivePositionId
            name
            position
            startDate
            endDate
            place
            current
            industryId
            private
            headingId
          }
          status {
            success
            name
            detail
            code
          }
        }
      }
      `

    const variables = {
      PersonJobsInput: input,
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response) => response.data.data.PersonJobs)
      .catch((err) => axiosError(err, requestId, dispatch))
  }
}

export default PersonJobs
