import React from 'react'

export const SearchCourses = () => {
  return (
    <svg
      width="197"
      height="197"
      viewBox="0 0 197 197"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M98.5 197C152.9 197 197 152.9 197 98.5C197 44.1 152.9 0 98.5 0C44.1 0 0 44.1 0 98.5C0 152.9 44.1 197 98.5 197Z"
        fill="#F4F4F4"
      />
      <rect x="123" y="20" width="74" height="29" rx="5" fill="#FF554D" />
      <rect y="152" width="106" height="29" rx="5" fill="#1EBDAF" />
      <rect x="139" y="32" width="40" height="6" rx="3" fill="#08044F" />
      <rect x="10" y="164" width="67" height="6" rx="3" fill="#08044F" />
      <rect x="184" y="32" width="6" height="6" rx="3" fill="#08044F" />
      <rect x="88" y="164" width="6" height="6" rx="3" fill="#08044F" />
      <path
        d="M109.385 103.121C110.556 101.95 112.456 101.95 113.627 103.121L153.613 143.107C159.276 148.77 159.276 157.951 153.613 163.613C147.951 169.276 138.77 169.276 133.107 163.613L93.1213 123.627C91.9498 122.456 91.9498 120.556 93.1213 119.385L109.385 103.121Z"
        fill="#FFBC27"
      />
      <circle cx="71.5" cy="80.5" r="45" stroke="#0189FF" strokeWidth="13" />
      <path
        d="M74 57C59.6409 57 48 68.6409 48 83"
        stroke="white"
        strokeWidth="10"
        strokeLinecap="round"
      />
    </svg>
  )
}
