/**
 * Resetea valores de RequestError
 * Hace el dispatch a redux.
 */
const requestError = () => {
  return (dispatch) => {
    dispatch({
      type: 'RESET_ERROR',
    })
  }
}

export default requestError
