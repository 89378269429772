export const openNewTab = (url) => {
  const link = document.createElement('a')
  document.body.appendChild(link)
  link.setAttribute('style', 'display: none;')

  if (url) {
    link.href = url
    link.target = '_blank'
    link.click()
  }

  document.body.removeChild(link)
}
