import { Box } from '@chakra-ui/react'
import { vars } from '@eclass/ui-kit'

import { ContentParser } from 'app/Components'
import { EllipseBlue } from 'app/Components/UI/Icons/Timeline/EllipseBlue'
import { LabelIndex } from '../../LabelIndex'
import * as Type from '../../types'

export const Content = ({ item, index, total, data }: Type.content) => {
  return (
    <Box
      bg={vars('colors-neutral-cultured2')}
      h="fit-content"
      _notLast={{
        mb: '2rem',
      }}
      padding="24px 20px"
      sx={{
        '@media screen and (min-width: 1030px)': {
          padding: '24px 102px',
        },
      }}
    >
      <Box
        alignItems="baseline"
        display="flex"
        ml="-19px"
        position="sticky"
        zIndex={1}
        sx={{
          '@media screen and (min-width: 1030px)': {
            ml: '-30px',
          },
        }}
        top="74px"
      >
        <EllipseBlue />
        <Box
          alignItems="baseline"
          bg="rgba(248, 248, 248, 0.7)"
          display="flex"
          m="0 0 24px 0"
          padding="8px 16px 8px 0"
        >
          <Box
            as="h6"
            fontWeight="700"
            fontSize="18px"
            ml="9px"
            mb="0"
            pr="1rem"
            sx={{
              '@media screen and (min-width: 768px)': {
                fontSize: '20px',
              },
              '@media screen and (min-width: 1030px)': {
                ml: '20px',
              },
            }}
            zIndex="1"
          >
            {item.label}
          </Box>
          <LabelIndex index={index} total={total} />
        </Box>
      </Box>
      <Box className="ContentArticleChild">
        {ContentParser({ content: item.text, embeds: data.embeds, changeTypeNode: false })}
      </Box>
    </Box>
  )
}
