import { gql, useQuery, QueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../../fragments'

export const GET_RESOURCE_FEEDBACK = gql`
  ${STATUS_FIELDS}
  query getResourceFeedback($ResourceFeedbackInput: ResourceFeedbackInput!) {
    getResourceFeedback(input: $ResourceFeedbackInput) {
      status {
        ...StatusFields
      }
      title
    }
  }
`

export type ResourceFeedbackPayload = Api.ResourceFeedbackPayload

export type ResourceFeedbackInput = Api.ResourceFeedbackInput

interface ResourceFeedbackPayloadApollo {
  getResourceFeedback: ResourceFeedbackPayload
}

interface ResourceFeedbackPayloadInputApollo {
  ResourceFeedbackInput: ResourceFeedbackInput
}

export const useQueryGetResourceFeedback = (
  options?: QueryHookOptions<ResourceFeedbackPayloadApollo, ResourceFeedbackPayloadInputApollo>
) => {
  return useQuery<ResourceFeedbackPayloadApollo, ResourceFeedbackPayloadInputApollo>(
    GET_RESOURCE_FEEDBACK,
    options
  )
}
