// @External Dependencies
import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

// @Dependencies
import BaseForm from './BaseForm'
import { Icon } from '../../UI/Icons'
import vars from '../variables'

// @Component
const Toggle = ({
  className,
  checked,
  disabled,
  info,
  label,
  name,
  onChange,
  size,
  required,
  tooltip,
  icon,
}) => {
  const { t } = useTranslation()
  const handleChange = (e) => {
    if (onChange !== undefined) {
      onChange(e)
    }
  }

  return (
    <StyledToggle className={className}>
      <BaseForm info={info} label={label} tooltip={tooltip} required={required}>
        <div className="toggle-box">
          <label className={`${disabled ? 'is-disabled' : ''} flex-center`}>
            <div className="relative">
              <input
                disabled={disabled}
                checked={checked}
                name={name}
                onChange={handleChange}
                type="checkbox"
              />
              <span className={size}>
                <small />
              </span>
            </div>
            {icon && <Icon name={icon} fill="#b0cfe0" />}
            <Message>{name === 'see' ? t('ResourceViewCorrect') : name}</Message>
          </label>
        </div>
      </BaseForm>
    </StyledToggle>
  )
}

// @Proptypes
Toggle.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  info: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  size: PropTypes.string,
  tooltip: PropTypes.string,
  icon: PropTypes.string,
}

Toggle.defaultProps = {
  size: 'md',
  required: false,
}

// @Export Component
export default Toggle

// @Styles
const StyledToggle = styled.div`
  .container-input {
    padding-right: 1rem;
    width: auto;
  }
  .input-box {
    align-items: center;
    display: flex;
    width: auto;
  }
  .toggle-box {
    position: relative;
    label {
      margin-bottom: 0;
      position: relative;
    }
  }
  .is-disabled {
    opacity: 0.3;
    span {
      cursor: no-drop;
    }
  }
  input {
    left: 50%;
    margin: 1px 0 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    & + span {
      background-color: ${vars.setColors.backgroundGrey};
      border-radius: ${vars.borderRadius};
      cursor: pointer;
      display: inline-block;
      height: 18px;
      margin: 0;
      overflow: hidden;
      padding: 0.2143rem;
      position: relative;
      top: 0;
      vertical-align: middle;
      width: 35px;

      small {
        align-items: center;
        background-color: ${vars.setColors.whiteThree};
        border-radius: ${vars.borderRadius};
        box-shadow: 0px 2px 4px ${vars.setColors.veryLightPink};
        display: flex;
        height: 14px;
        justify-content: center;
        left: 18px;
        margin: 0;
        position: absolute;
        top: 50%;
        transition: all 0.2s ease;
        transform: translateY(-50%);
        width: 14px;
      }
      &.sm {
        height: 2rem;
        width: 4.2857rem;
        :before {
          font-size: 0.7857rem;
          left: 0.0714rem;
        }
        small {
          height: 1.5714rem;
          left: 2.3571rem;
          width: 1.5714rem;
        }
      }
    }

    &:checked {
      & + span {
        background-color: ${vars.setColors.primary};
        small {
          background-color: ${vars.setColors.whiteThree};
          box-shadow: none;
          left: 3px;
        }
      }
    }
  }
  .relative {
    position: relative;
  }
`
const Message = styled.span`
  color: ${vars.setColors.primary};
  cursor: pointer;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-left: 8px;
`
