import parseAnswer from './parseAnswer'
import { Types } from '../types'

/**
 * Recibe los niveles y ordena los ordena
 */
const setLevelsInitialState = (levels: Types.TestLevel[], config: Types.EvaluationConfig) => {
  const extractAnswers: any = []

  const formattedLevels = levels.map((level) => {
    const stagesState = level.Stages?.map((stage) => {
      const Answers = stage.Answers?.map((answer) => {
        const formattedAnswer = {
          ...answer,
          ...parseAnswer(answer, config),
        }

        extractAnswers.push(formattedAnswer)
        return formattedAnswer
      })

      return {
        ...stage,
        Answers,
        state: 0,
      }
    })

    return {
      ...level,
      Stages: stagesState,
      stage: 0,
    }
  })

  return {
    Levels: formattedLevels,
    Answers: extractAnswers,
  }
}

export default setLevelsInitialState
