const initialState = {
  answerType: {},
  answers: [],
  answered: false,
  seeCorrect: false,
  deleteIncorrect: false,
  questionCorrect: false,
  send: {
    status: false,
    type: '',
  },
  hint: null,
  callToAction: null,
  score: {
    obtained: 0,
    max: 3,
  },
  alert: false,
  embeds: undefined,
  reorder: [],
  redo: false,
  answer: {},
  isRepetition: false,
  setScore: {
    obtained: 0,
    max: 0,
  },
}

export const exercises = (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case 'EXERCISE_INITIAL':
      return {
        ...initialState,
        ...payload,
      }
    case 'EXERCISE_UPDATE':
      return {
        ...state,
        ...payload,
      }
    case 'EXERCISE_SET':
      return { ...state, answers: [...payload] }
    case 'EXERCISE_SEE':
      return { ...state, seeCorrect: payload }
    case 'EXERCISE_EXIT':
      return initialState
    case 'EXERCISE_REDO':
      return {
        ...state,
        ...payload,
        answered: false,
        deleteIncorrect: false,
        hint: null,
        redo: false,
        seeCorrect: false,
        send: { status: false, type: '' },
        isRepetition: true,
      }
    case 'EXERCISE_SEND':
      return { ...state, send: payload }
    case 'EXERCISE_ALERT_CLOSE':
      return { ...state, alert: false }
    case 'EXERCISE_SET_SCORE':
      return { ...state, setScore: payload }
    default:
      return state
  }
}
