import { gql } from '@apollo/client'

import * as Type from '../types'

export const ALL_INDUSTRYS = gql`
  query allIndustrys {
    allIndustrys(orderBy: name_ASC) {
      name
      id
    }
  }
`

export interface ALL_INDUSTRYS_TYPE {
  allIndustrys: Type.list[]
}
