import { gql, useQuery, QueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const CampusBranding = gql`
  ${STATUS_FIELDS}
  query CampusBranding($CampusBrandingInput: CampusBrandingInput!) {
    CampusBranding(input: $CampusBrandingInput) {
      CampusEmail
      CampusLoginTypes
      CampusBranding {
        id
        campusId
        text
        textColor
        desktopImage
        tabletImage
        mobileImage
        loginImage
        BrandingLogos {
          id
          name
          url
        }
        Campus2 {
          name
        }
      }
      showProfile
      PDF
      i18nTag
      status {
        ...StatusFields
      }
    }
  }
`

export type CampusBrandingPayload = Api.CampusBrandingPayload

export type CampusBrandingInput = Api.CampusBrandingInput

interface CampusBrandingPayloadApollo {
  CampusBranding: CampusBrandingPayload
}

interface CampusBrandingInputApollo {
  CampusBrandingInput: CampusBrandingInput
}

export const useQueryCampusBranding = (
  options?: QueryHookOptions<CampusBrandingPayloadApollo, CampusBrandingInputApollo>
) => {
  return useQuery<CampusBrandingPayloadApollo, CampusBrandingInputApollo>(CampusBranding, options)
}
