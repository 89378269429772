import { useViewDownload } from 'app/Views/Resources/PDF'
import { Parallax } from 'app/Components/UI'
import { ParallaxPdfView } from './ParallaxPdfView'
import { Props } from '../types'
import { useBackground } from './utils'
import './style.scss'

export const ParallaxModule = ({ config, id }: Props.Parallax) => {
  const isViewDownload = useViewDownload()
  const background = useBackground(config)

  if (isViewDownload) {
    return <ParallaxPdfView config={config} id={id} />
  }

  return (
    <section className="Parallax breakerContainer-full">
      <Parallax height={290} background={background}>
        <div className={`Content ${config.color} ${config.background === 0 ? 'none' : ''}`}>
          {config.title && <h3>{config.title}</h3>}
          {config.subtitle && <p>{config.subtitle}</p>}
        </div>
      </Parallax>
    </section>
  )
}
