import React, { useEffect, useState, useCallback } from 'react'
import draftToHtml from 'draftjs-to-html'
import { convertToRaw, EditorState, ContentState } from 'draft-js'
import { useSelector } from 'react-redux'
import htmlToDraft from 'html-to-draftjs'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import VisibilitySensor from 'app/Components/VisibilitySensor'
import useDebounce from '../../../hooks/useDebounce'
import { ContentParser } from 'app/Components'
import { SideInfo } from '../../SideInfo'
import { QuestionAlert } from '../../QuestionAlert/QuestionAlert'
import { Feedback } from '../../Feedback'
import { Wrapper } from './Style'
import { Question } from '../types'
import { TextEditor } from 'app/Components/UI'
import { Store } from '../../../../Courses/types'
import { cleanTags } from 'app/Services'
import { Skip, classOmitted } from '../../Skip'
import { LabelOmitted } from '../../LabelOmitted'
import { valueEmpty } from 'app/Views/Tests/helpers/valueEmpty'
import { testsStates } from 'app/Views/Tests/testsStates'
import { TinyAlert } from '@eclass/ui-kit'
import { Instruction } from '../Instruction'

/**
 * Procesa un string de la siguiente manera:
 * - Remueve todos los tags html
 * - reemplaza los saltos de línea
 * - Separa las palabras por espacios
 * - Los índices vacíos los omite
 *
 * @returns {number} Total de palabras.
 */
const filterString = (text: string): number => {
  return text
    .replace(/(<([^>]+)>)/gi, '')
    .replace('\n', '')
    .split(' ')
    .filter((s) => s.length > 0).length
}

/**
 * La pregunta de tipo texto se guardara en los siguientes casos:
 * - Si el usuario para de escribir y pasan dos segundos.
 * - Si el usuario escribio mas de 16 nuevas palabras o borro mas de 10 palabras, logica replicada de v6.
 * - Al ejecutar el evento onBlur
 */
export const Text = ({
  id,
  answer,
  position,
  action,
  evaluationState,
  optionDisabled,
  showFeedback,
  setActiveQuestion,
  hasLevelsStages,
  missingAnswers,
  feedback,
}: Question) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { isMobile } = useSelector((store: Store) => store.ui)
  const [editorValue, setEditorValue] = useState(EditorState.createEmpty())
  const [totalWords, setTotalWords] = useState(0)
  const [questionText, setQuestionText] = useState('')
  const questionTextDebounce = useDebounce(questionText, 2000)
  const questionTextAfterSave = React.useRef('')

  const isResource = pathname.includes('resource/view')
  const classFeedback = evaluationState === testsStates.EVALUATED ? 'feedback' : ''
  const placeholder =
    optionDisabled && valueEmpty(evaluationState, answer.state!)
      ? cleanTags(answer?.answer!)
      : t('ResourceWriteAnswer')

  useEffect(() => {
    if (valueEmpty(evaluationState, answer.state!) && answer.answer) {
      const { contentBlocks, entityMap } = htmlToDraft(answer.answer)
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
      const rawResponse = draftToHtml(convertToRaw(contentState))

      setQuestionText(rawResponse)
      setEditorValue(EditorState.createWithContent(contentState))
      setTotalWords(filterString(answer.answer))

      questionTextAfterSave.current = rawResponse
    } else {
      const rawResponse = draftToHtml(convertToRaw(editorValue.getCurrentContent()))

      questionTextAfterSave.current = rawResponse
    }
  }, [])

  /**
   * Se ejecuta cuando el usuario escribe en el editor y se encarga de guardar el texto cada 16 palabras nuevos
   * o cuando se borran 10 palabras, logica replicada de v6.
   */
  useEffect(() => {
    if ((editorValue?.getCurrentContent, draftToHtml, convertToRaw)) {
      const rawResponse = draftToHtml(convertToRaw(editorValue.getCurrentContent()))
      const countWords = filterString(rawResponse)

      const diff = countWords - totalWords

      if (totalWords < countWords && diff > 16 && questionTextAfterSave.current !== rawResponse) {
        action({
          id,
          option: rawResponse,
          type: 'ANSWER',
        })

        questionTextAfterSave.current = rawResponse
        setTotalWords(countWords)
      } else if (
        totalWords > countWords &&
        Math.abs(diff) > 10 &&
        questionTextAfterSave.current !== rawResponse
      ) {
        action({
          id,
          option: rawResponse,
          type: 'ANSWER',
        })

        questionTextAfterSave.current = rawResponse
        setTotalWords(countWords)
      }
    }
  }, [editorValue])

  /**
   * Se ocupa para mantener el estado de lo que esta escrito en el editor y ejecutar el debounce
   */
  useEffect(() => {
    const rawResponse = draftToHtml(convertToRaw(editorValue.getCurrentContent()))

    setQuestionText(rawResponse)
  }, [editorValue])

  /**
   * Se ejecuta cuando el usuario escribe en el editor, se encarga de guardar el texto cuando
   * el usuario para de escribir y pasan 2 segundos.
   */
  useEffect(() => {
    if (questionTextDebounce && questionTextAfterSave.current !== questionTextDebounce) {
      const countWords = filterString(questionTextDebounce)

      action({
        id,
        option: questionTextDebounce,
        type: 'ANSWER',
      })

      questionTextAfterSave.current = questionTextDebounce
      setTotalWords(countWords)
    }
  }, [questionTextDebounce, action, id])

  const onBlur = useCallback(() => {
    const rawResponse = draftToHtml(convertToRaw(editorValue.getCurrentContent()))
    const countWords = filterString(rawResponse)

    if (questionTextAfterSave.current !== rawResponse) {
      action({
        id,
        option: rawResponse,
        type: 'ANSWER',
      })

      questionTextAfterSave.current = rawResponse
      setTotalWords(countWords)
    }
  }, [editorValue])

  return (
    <VisibilitySensor setter={setActiveQuestion} id={`question-${answer.config?.id!}`}>
      <Wrapper
        className={`
          Question
          ${isMobile ? 'Mobile' : ''}
          ${optionDisabled || answer.skipped ? 'optionDisabled' : ''}
        `}
        id={`question-${answer.config?.id!}`}
      >
        <div className={`Question__Data ${classOmitted(answer.skipped!, evaluationState)}`}>
          <SideInfo
            status={answer.state}
            position={position}
            evaluationState={evaluationState}
            score={answer.feedback?.score}
          />
          <div className={`Question__Content ${classFeedback}`}>
            {answer.config && !answer.config.mandatory && !isResource && (
              <>
                <TinyAlert status="warning" text={t('ResourceOptimalQuestion')} margin="0 0 4px" />
              </>
            )}
            <LabelOmitted answer={answer.state!} evaluation={evaluationState} />
            <QuestionAlert show={(hasLevelsStages && missingAnswers)!} answer={answer} />
            <Instruction>
              {ContentParser({
                content: answer.instructions!,
                embeds: answer.embeds,
              })}
            </Instruction>
            <TextEditor
              loading={answer.loading}
              onBlur={onBlur}
              placeholder={placeholder}
              readOnly={optionDisabled || answer.skipped}
              setValue={setEditorValue}
              showCounter={!optionDisabled}
              value={editorValue}
            />
            <Skip answer={answer} action={action} evaluationState={evaluationState} />
          </div>
        </div>
        <Feedback
          answer={answer}
          evaluationState={evaluationState}
          showFeedback={showFeedback}
          type={feedback}
        />
      </Wrapper>
    </VisibilitySensor>
  )
}
