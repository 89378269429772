export const AlarmDisable = () => {
  return (
    <>
      <path
        d="M10 14H6C6 14.5304 6.21071 15.0391 6.58579 15.4142C6.96086 15.7893 7.46957 16 8 16C8.53043 16 9.03914 15.7893 9.41421 15.4142C9.78929 15.0391 10 14.5304 10 14Z"
        fill="#B0CFE0"
      />
      <path
        d="M15.0994 12.98C15.3549 12.9572 15.5916 12.8363 15.7598 12.6428C15.9281 12.4492 16.0149 12.198 16.0019 11.9419C15.989 11.6858 15.8774 11.4446 15.6904 11.269C15.5035 11.0934 15.2559 10.997 14.9994 11H14.4994C14.0491 10.6366 13.6822 10.1806 13.4234 9.66303C13.1646 9.14545 13.02 8.57831 12.9994 8.00001V5.00001C13.0028 3.95791 12.6794 2.94097 12.0747 2.09225C11.47 1.24353 10.6145 0.605755 9.62841 0.268639C8.64234 -0.0684758 7.5754 -0.0879597 6.57768 0.212929C5.57996 0.513817 4.70168 1.11993 4.06641 1.94601L15.0994 12.98Z"
        fill="#B0CFE0"
      />
      <path
        d="M3 5.121V8C2.97941 8.57831 2.83483 9.14544 2.57604 9.66302C2.31725 10.1806 1.95029 10.6365 1.5 11H1C0.734784 11 0.48043 11.1054 0.292893 11.2929C0.105357 11.4804 0 11.7348 0 12C0 12.2652 0.105357 12.5196 0.292893 12.7071C0.48043 12.8946 0.734784 13 1 13H10.879L3 5.121Z"
        fill="#B0CFE0"
      />
      <path
        d="M15.0006 15.5C14.9349 15.5001 14.8698 15.4873 14.8091 15.4623C14.7484 15.4372 14.6932 15.4004 14.6466 15.354L0.646632 1.35402C0.552745 1.26013 0.5 1.1328 0.5 1.00002C0.5 0.867245 0.552745 0.739908 0.646632 0.646021C0.740518 0.552135 0.867856 0.49939 1.00063 0.49939C1.13341 0.49939 1.26074 0.552135 1.35463 0.646021L15.3546 14.646C15.4247 14.716 15.4725 14.8051 15.4919 14.9022C15.5112 14.9993 15.5013 15.1 15.4634 15.1915C15.4255 15.283 15.3613 15.3611 15.2789 15.416C15.1965 15.471 15.0997 15.5002 15.0006 15.5Z"
        fill="#B0CFE0"
      />
    </>
  )
}
