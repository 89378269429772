export const Teamwork = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} fill="none">
    <circle cx={22.5} cy={22.5} r={22.5} fill="#E0EEFA" />
    <path
      fill="#0189FF"
      d="M22.618 25h.75a.624.624 0 0 0 .625-.626V21h3.383a.625.625 0 0 0 .47-1.037l-4.376-4.76a.642.642 0 0 0-.94 0l-4.376 4.76a.626.626 0 0 0 .47 1.037h3.37v3.374a.625.625 0 0 0 .624.626Z"
    />
    <rect width={10} height={2} x={18} y={26} fill="#0189FF" rx={1} />
    <path
      fill="#FF554D"
      d="M41 5H29a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1Z"
    />
    <path
      stroke="#08044F"
      strokeDasharray="4 4"
      strokeLinecap="round"
      strokeWidth={2}
      d="M8 12a2 2 0 0 1 2-2h25a2 2 0 0 1 2 2v21a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2V12Z"
    />
    <path fill="#E0EEFA" d="M6 27h4v3H6z" />
    <rect width={16} height={5} y={23} fill="#F5A623" rx={2} />
    <rect width={14} height={7} x={1} y={24} stroke="#08044F" strokeWidth={2} rx={1} />
    <path
      fill="#1EBDAF"
      d="M44 30H27a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h17a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1Z"
    />
    <path stroke="#08044F" strokeLinecap="round" strokeWidth={2} d="M29 33h11M29 37h7" />
  </svg>
)
