import React, { memo, Fragment } from 'react'
import ReactHtmlParser from 'react-html-parser'

import { Types, SlideshowConfig } from '../types'
import './stylePDF.scss'

export type IViewPDF = {
  slides: Types.Slide[]
  type: SlideshowConfig['type']
}

/**
 * Diagramación del slider cuando se encuentre en su versión PDF
 * @param slides {typeSlide[]} array con slide y su contenido (titulo, imagen, posición, texto y salto a nueva página)
 */
export const ViewPDF = memo(({ slides, type }: IViewPDF) => {
  if (slides.length === 0) {
    return null
  }

  return (
    <div className={`SlideshowPDF type-${type}`} data-testid="SlideshowPDF">
      {slides.map((slide: Types.Slide, key: number) => (
        <Fragment key={key}>
          {!!slide.new_page && <hr className="pdfpagebreak" />}
          <div className={`slidePDF order-${slide.orden}`}>
            <div>
              <figure>
                <img src={`https://cdn.eclass.com/${slide.image}`} alt={slide.title} />
              </figure>
            </div>
            <div>
              <h5>{slide.title}</h5>
              {ReactHtmlParser(slide.text)}
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  )
})
