/**
 * Función para obtener los campos de entradas
 */
export const getFields = (id?: number) => {
  let fields: [] | NodeListOf<HTMLInputElement | HTMLSelectElement> = []
  const questionCloze = document.querySelector(`.cloze-${id}`) as HTMLElement
  if (questionCloze) {
    const itemsCloze = questionCloze.querySelectorAll('input, select') as NodeListOf<
      HTMLInputElement | HTMLSelectElement
    >
    if (itemsCloze) {
      fields = itemsCloze
    }
  }

  return fields
}
