import { gql, useMutation, MutationHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const QUESTION_CREATE = gql`
  ${STATUS_FIELDS}
  mutation QuestionCreate($QuestionCreateInput: QuestionCreateInput!) {
    QuestionCreate(input: $QuestionCreateInput) {
      status {
        ...StatusFields
      }
      url
      Question {
        id
        text
        title
        public
        created
        isAnswered
        Student {
          Person {
            id
            fullName
            picture
          }
        }
      }
    }
  }
`

export type QuestionCreatePayload = Api.QuestionCreatePayload

export type QuestionCreateInput = Api.QuestionCreateInput

interface QuestionCreatePayloadApollo {
  QuestionCreate: QuestionCreatePayload
}

interface QuestionCreateInputApollo {
  QuestionCreateInput: QuestionCreateInput
}

export const useMutationQuestionCreate = (
  options?: MutationHookOptions<QuestionCreatePayloadApollo, QuestionCreateInputApollo>
) => {
  return useMutation<QuestionCreatePayloadApollo, QuestionCreateInputApollo>(
    QUESTION_CREATE,
    options
  )
}
