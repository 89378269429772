/**
 * @todo
 * - Cuando se cierre las respuesta, el comentario que viste debe quedar al top
 * - Hacer test
 */
import React, { useRef } from 'react'
import { Icon } from 'app/Components/UI/Icons'

import { ListComment, toScroll } from '../'
import { Props } from '../types'

import { useFixed } from './useFixed'
import { Footer } from './Footer'
import * as Style from './style'

/**
 * Componente Wrapper de las respuesta.
 *
 * Recibe un children con las respuestas al comentario,
 * al cual se le incluye la botonera donde puede mostrar o ocultar las respuestas,
 * ademas de un icono ancla que sigue el avance del scroll
 * @example
 * <CommentSystem.Answers answers={answers}>
 *   <>
 *      {answers.map((response) => (
 *        <Answer />
 *      ))}
 *   </>
 * </CommentSystem.Answers>
 */
export const Answers = ({ answers, children, idParent, show, setShow }: Props.Answers) => {
  const refsAnswers = useRef<HTMLDivElement>(null)
  const { fixed } = useFixed({
    refsAnswers: refsAnswers.current,
    show,
  })

  const handleShow = () => {
    if (show) {
      toScroll(idParent)
    }
    setShow(!show)
  }

  if (answers && answers.length === 0) {
    return null
  }

  const showClass = show ? 'show' : ''

  return (
    <Style.Section role="Answers" className={`Answers ${showClass}`}>
      <Style.Answers role="AnswersList" className={`answers ${fixed}`} ref={refsAnswers}>
        <Icon name="anchorComment" />
        {show && <ListComment>{children}</ListComment>}
      </Style.Answers>
      <Footer answers={answers} setShow={handleShow} show={show} showClass={showClass} />
    </Style.Section>
  )
}
