import { vars } from '@eclass/ui-kit'

export const Pause = () => (
  <svg width={14} height={14} fill="none" data-testid="podcastIcon-pause">
    <path
      fill={vars('colors-neutral-white')}
      d="M4 0H1C.4 0 0 .4 0 1v12c0 .6.4 1 1 1h3c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1ZM13 0h-3c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h3c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1Z"
    />
  </svg>
)
