import { gql, useMutation, MutationHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

/**
 * Obtiene la url para la cev
 */
export const RESOURCE_ATTEND = gql`
  ${STATUS_FIELDS}
  mutation ResourceAttend($ResourceAttendInput: ResourceAttendInput!) {
    ResourceAttend(input: $ResourceAttendInput) {
      url
      status {
        ...StatusFields
      }
    }
  }
`

export type ResourceAttendPayload = Api.ResourceAttendPayload

export type ResourceAttendInput = Api.ResourceAttendInput

interface ResourceAttendPayloadApollo {
  ResourceAttend: ResourceAttendPayload
}

interface ResourceAttendInputApollo {
  ResourceAttendInput: ResourceAttendInput
}

export const useMutationResourceAttend = (
  options?: MutationHookOptions<ResourceAttendPayloadApollo, ResourceAttendInputApollo>
) => {
  return useMutation<ResourceAttendPayloadApollo, ResourceAttendInputApollo>(
    RESOURCE_ATTEND,
    options
  )
}
