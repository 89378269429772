import React from 'react'
import { useFormContext } from 'react-hook-form'

import { useIndustries } from './useIndustries'
import { useJobTypes } from './useJobTypes'
import { useHeadingAndPositions } from './useHeadingAndPositions'

export const useLogic = () => {
  const { getValues, watch } = useFormContext()
  const { industries } = useIndustries()
  const { jobTypes } = useJobTypes()
  const { headings, executivePositions, onChangeHeading } = useHeadingAndPositions()

  const [disabled, setDisabled] = React.useState(getValues().ActualJob || false)

  const onChangeActualJob = (value: boolean) => {
    setDisabled(value)
  }

  const endDateBlock = !watch('startDate')
  const currentWork = watch('current')

  return {
    industries,
    jobTypes,
    headings,
    executivePositions,
    onChangeHeading,
    disabled,
    onChangeActualJob,
    endDateBlock,
    currentWork,
  }
}
