import { useState, useCallback, useEffect } from 'react'

import useExercise from 'app/Views/Exercise/Practice/useExercise'
import { UIVars } from 'app/Components/UI'

import { Types } from './types'

export const useLogic = (props: any) => {
  const {
    position,
    excercises: { Answer },
  } = props
  const {
    state: { answers, answered, seeCorrect, hint, reorder, send },
    dispatch,
  } = useExercise()

  const isEmptyObject = (obj: Types.slot) =>
    Object.keys(obj).length === 0 && obj.constructor === Object

  const [items, setItems] = useState<Types.slot[]>([])
  const [itemsSelected, setItemSelected] = useState<Types.slot[]>([])

  /**
   * Guardo en la store los códigos de cada item
   */
  const saveStore = useCallback(
    (listItemsSelected: Types.slot[]) => {
      let arrayCodes = [...reorder]
      // si no es solo un reorder
      if (arrayCodes.length !== answers.length) {
        arrayCodes = answers.map(() => [])
      }
      // guardo en code solo los ids
      const codes: string[] = []
      listItemsSelected.forEach((item: Types.slot) => {
        if (Object.keys(item).length > 0 && item.code) codes.push(item.code)
      })
      // le asigno al reorder correspondiente sus codes actualizados
      arrayCodes[position - 1] = codes
      dispatch(arrayCodes)
    },
    [reorder, dispatch, position, answers]
  )

  /**
   * veo cual es la posicion del array de data es el que necesito para la pregunta
   * y desde hay creo dos estados con los seleccionados y lo que no estan seleccionados
   * por defecto los que son fijo ya vienen en seleccionados
   */
  const listInitial = useCallback(() => {
    const arrayAnswer = answers[position - 1]
    const mockDataDots: Types.slot[] = arrayAnswer ? arrayAnswer.options : []
    const newMockData = mockDataDots.filter(
      (item: Types.slot) => item.draggable === '1' && !item.selected
    )
    const arr = mockDataDots.map((item: Types.slot) => {
      if (item.draggable === '0' || item.selected || answered) {
        return item
      } else {
        return {}
      }
    })
    setItems(newMockData)
    setItemSelected(arr)
  }, [position, answered, answers])

  useEffect(() => {
    listInitial()
  }, [answers, listInitial])

  const selectItem = (newItem: Types.slot, key: number) => {
    const findEmptyIndex = itemsSelected.findIndex((item: Types.slot) => isEmptyObject(item))
    itemsSelected[findEmptyIndex] = newItem
    setItemSelected([...itemsSelected])
    const newArr = items.filter((item: Types.slot, index: number) => index !== key)
    newArr.forEach((element: Types.slot) => {
      element.selected = true
    })
    setItems([...newArr])
    saveStore(itemsSelected)
  }

  const removeItem = useCallback(
    (selectedItem: Types.slot) => {
      const findIndex = itemsSelected.findIndex(
        (item: Types.slot) => item.text === selectedItem.text
      )
      itemsSelected[findIndex] = {}
      setItemSelected([...itemsSelected])
      setItems([...items, selectedItem])
      saveStore(itemsSelected)
    },
    [itemsSelected, items, saveStore]
  )

  // estado para marcar la pregunta esto funciona cuando es repondida,
  // o cuando se quiere ver las correctas
  const options = seeCorrect ? hint[position - 1].options : itemsSelected
  if (answered && answers[position - 1]) {
    options.correct = answers[position - 1].correct
  }

  const status = {
    icon: options.correct ? 'circularCheck' : 'circularError',
    color: options.correct ? UIVars.setColors.checkGreen : UIVars.setColors.checkError,
  }

  const showListOfAlternatives = !answered && !seeCorrect

  /** Manejo condiciones para la visualización y evento del Inner */
  const handleInner = {
    showCross: useCallback(
      (item: Types.slot) => {
        if (Object.keys(item).length > 0 && item.draggable === '1' && !answered && !seeCorrect) {
          return true
        } else {
          return false
        }
      },
      [answered, seeCorrect]
    ),
    onclick: useCallback(
      (item: Types.slot) => {
        if (
          item.draggable === '0' ||
          isEmptyObject(item) ||
          answered ||
          send.status ||
          seeCorrect
        ) {
          return null
        } else {
          removeItem(item)
        }
      },
      [answered, send, seeCorrect, removeItem]
    ),
    classCss: useCallback(
      (item: Types.slot) => {
        let itemStatus = 'empty'
        if (Object.keys(item).length > 0) {
          itemStatus = item.draggable === '0' ? 'blocked' : 'selected'
        }
        if (answered) itemStatus = `${itemStatus} answered`
        if (seeCorrect) itemStatus = 'correct'
        return itemStatus
      },
      [answered, seeCorrect]
    ),
  }

  const enunciatedShow = answered && !seeCorrect
  const layoutHorizontal = Answer.config.answersInHorizontal ? 'horizontal' : ''

  return {
    status,
    layoutHorizontal,
    position,
    options,
    items,
    selectItem,
    handleInner,
    showListOfAlternatives,
    enunciatedShow,
  }
}
