import { useEffect } from 'react'
import { Stream } from '@cloudflare/stream-react'

import { Props } from '../types'
import { setCdnUrl } from '../../utils'
import './style.scss'

/** Componente que dibuja el video en los recursos */
export const ArticleVideo = ({ config, id }: Props.Video) => {
  const denegateAccess = config.deny_download === 1

  useEffect(() => {
    const elementHtml = document.getElementById(`videoModule${id}`)

    if (elementHtml && denegateAccess) {
      elementHtml.setAttribute('controlsList', 'nodownload')
    }
  }, [denegateAccess])

  if (!config) {
    return null
  }

  /**
   * Si el video es de tipo Stream entonces cargamos
   * el Stream Cloudflare plugin y le pasamos el ID.
   */
  if (config.videoType === 'stream') {
    return (
      <section className="Article__video v8 eclass-elements-kit-video">
        <Stream
          className={'Stream'}
          controls
          src={config.video}
          poster={config.cover ? setCdnUrl(config.cover) : ''}
        />
      </section>
    )
  } else {
    return (
      <section className="Article__video v8 eclass-elements-kit-video">
        <video
          key={id}
          controls
          id={`videoModule${id}`}
          onContextMenu={denegateAccess ? (e) => e.preventDefault() : undefined}
          poster={config.cover ? setCdnUrl(config.cover) : ''}
          data-testid="article-video"
        >
          <source src={setCdnUrl(config.video)} />
        </video>
      </section>
    )
  }
}
