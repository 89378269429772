// @External Dependencies
import axios from 'axios'
import { PersonUpdatePictureInput, InputFile } from '@eclass/api'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { axiosError, getToken } from '../../../Services'

const userUpdateProfilePic = (input: PersonUpdatePictureInput, file: InputFile) => {
  const requestId = 'PersonUpdatePicture'
  return async (dispatch: any) => {
    getToken()
    const query = `
      mutation PersonUpdatePicture($PersonUpdatePictureInput: PersonUpdatePictureInput!) {
        PersonUpdatePicture(input: $PersonUpdatePictureInput) {
          status {
            success
            name
          detail
          code
          }
          person {
            picture
          }
        }
      }
    `

    const variables = {
      PersonUpdatePictureInput: input,
    }

    if (file) {
      const form = new FormData()
      variables.PersonUpdatePictureInput.file = {
        id: 0,
        // @ts-ignore
        type: 'ANSWER',
        name: '',
        file,
      }

      const operations = { query, variables }
      form.append('operations', JSON.stringify(operations))
      const map = {
        0: ['variables.PersonUpdatePictureInput.file.file'],
      }
      form.append('map', JSON.stringify(map))
      // @ts-ignore
      form.append('0', file)
      return axios({
        url: `${apiURL}/graphql`,
        method: 'POST',
        data: form,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then((response) => response.data.data.PersonUpdatePicture)
        .catch((err) => axiosError(err, requestId, dispatch))
    }
  }
}

export default userUpdateProfilePic
