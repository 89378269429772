import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Avatar } from 'app/Components/UI'
import { Avatar as Style } from './style'
import { Store } from 'app/Redux/types'
import { Props } from './types'

/**
 * Componente que muestra el avatar y el tamaño de este
 * @example <Header user={user}/>
 */
export const MyAvatar = ({ user, small }: Props.MyAvatar) => {
  const { t } = useTranslation()
  const { loggedUser } = useSelector((state: Store) => state)

  const itsMe = loggedUser.user?.id === user?.id ? 'itsMe' : ''
  const avatarSize = small ? 'small' : ''

  return (
    <Style.MyAvatar role="AvatarPic" className={`${itsMe} ${avatarSize}`}>
      <Avatar
        fullName={user?.fullName || t('CommentSystemAnonymous')}
        userId={user?.id || 0}
        picture={user?.picture || ''}
        size={45}
        fontSize={20}
      />
    </Style.MyAvatar>
  )
}
