import { useState, useEffect, useRef, useCallback } from 'react'
import useDebounce from 'app/Views/Tests/hooks/useDebounce'
import { IInputsCommon, IInput } from '../types'

export const Input = ({
  answer,
  disabled,
  save,
  configId,
  value: valueSelect,
  id,
  config,
}: IInputsCommon<IInput>) => {
  const handleWheel = (event) => {
    event.preventDefault() // Prevenir el comportamiento predeterminado del desplazamiento
    event.target.blur() // Desenfocar el input para evitar la interacción
  }

  const width = 32.5 + 8 * (answer.inputSize ?? 1)
  const [value, setValue] = useState(valueSelect)
  const [, setResetFlag] = useState(false) // Estado para forzar el reset
  const textoDebounced = useDebounce(value, 2000)
  const questionTextAfterSave = useRef(value)

  useEffect(() => {
    if (valueSelect === '') {
      setValue(valueSelect)
    }
  }, [valueSelect])

  useEffect(() => {
    if (
      textoDebounced &&
      textoDebounced !== '' &&
      questionTextAfterSave.current !== textoDebounced
    ) {
      save(configId, textoDebounced)
      questionTextAfterSave.current = textoDebounced
    }
  }, [textoDebounced])

  useEffect(() => {
    const inputElement = document.getElementById(id) as HTMLInputElement

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'data-status') {
          const newStatus = inputElement.getAttribute('data-status')
          if (newStatus === 'error' && !disabled) {
            // RESET TODOS LAS INSTANCIAS QUE GUARDAN EL VALUE DEL INPUT
            inputElement.removeAttribute('data-status')
            questionTextAfterSave.current = ''
            setResetFlag((prev) => !prev) // Forzar el reset del debounce
            setValue('')
          }
        }
      })
    })

    if (inputElement) {
      observer.observe(inputElement, {
        attributes: true,
      })
    }

    return () => {
      if (inputElement) {
        observer.disconnect()
      }
    }
  }, [id])

  const onBlur = useCallback(
    (e) => {
      if (e.target.value !== '' && questionTextAfterSave.current !== e.target.value) {
        save(configId, e.target.value)
        questionTextAfterSave.current = e.target.value
      }
    },
    [configId, save]
  )

  const onChange = useCallback((e) => {
    setValue(e.target.value)
    setResetFlag((prev) => !prev)
  }, [])

  const onKeyDown = useCallback(
    (e) => {
      const isNumerical = answer.type === 'NUMERICAL'
      const exceptThisSymbols = ['e', 'E', 'Dead', 'Backquote', 'Quote', '+']
      if (isNumerical && exceptThisSymbols.includes(e.key)) {
        e.preventDefault()
      } else {
        const symbolsInitial = ['-']
        // solo permito estos caracteres si van al inicio
        if (symbolsInitial.includes(e.key) && e.target.value.length > 0) {
          e.preventDefault()
        }
      }
    },
    [answer.type]
  )

  const types = {
    NUMERICAL: 'number',
    SHORTANSWER: 'text',
  }

  return (
    <input
      id={id}
      data-testid={`cloze-${answer.type}`}
      disabled={disabled}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onChange={onChange}
      onWheel={handleWheel}
      size={answer.inputSize}
      style={{ width: `${width}px` }}
      type={types[answer.type ?? 'text']}
      value={value || ''}
    />
  )
}
