import { useParams } from 'react-router-dom'
import * as Apollo from 'app/Apollo'
import { RouteParams } from 'Config/routes/types'
import { RequestError } from 'app/Components'
import { useDispatch } from 'react-redux'

export const useRankingAchievement = (achievementId?: number) => {
  const { courseId, studentId } = useParams<RouteParams>()
  const dispatch = useDispatch()

  const [mutationRankingAchievement, { loading }] = Apollo.useMutationRankingAchievement({
    variables: {
      RankingAchievementInput: {
        studentId: Number(studentId),
        courseId: Number(courseId),
        achievementId: achievementId ?? 0,
      },
    },
    onCompleted: (data) => {
      if (!data || !data.RankingAchievement.status.success) {
        RequestError.handle(dispatch, data.RankingAchievement)
      }
    },
  })

  return { loading, mutationRankingAchievement }
}
