import { useRecorderSpeechToText } from './useRecorderSpeechToText'
import { CommonVoiceRecognition } from '../CommonVoiceRecognition'

const SpeechToTextRecognition = ({ config, id }) => {
  // Nuevo hook solo para speech to text
  const { browserSupport, onClick, state, redo, alertError, closedError, transcript } =
    useRecorderSpeechToText({ config, id })

  return (
    <CommonVoiceRecognition
      browserSupport={browserSupport}
      onClick={onClick}
      state={state}
      redo={redo}
      alertError={alertError}
      closedError={closedError}
      transcript={transcript}
    />
  )
}

export default SpeechToTextRecognition
