import styled from 'styled-components/macro'

import { UIVars } from 'app/Components/UI'
import { externalLink } from 'assets/svg'
import { vars } from '@eclass/ui-kit'

export const ClassRecommendations = styled.div`
  background-color: ${UIVars.setColors.whiteTwo};
  border-radius: ${UIVars.borderRadius};
  border: 1px solid ${UIVars.setColors.backgroundGrey};
  margin-top: 40px;
  padding: 25px;

  .recommendations {
    a {
      text-decoration: underline;
      color: ${UIVars.setColors.primary};
      font-weight: 500;
    }

    a:hover {
      color: ${vars('colors-neutral-darkCharcoal')};
    }
    a::after {
      -webkit-mask: url(${externalLink}) no-repeat center left;
      background-color: #b0cfe0;
      content: '';
      display: inline-block;
      height: 12px;
      margin-left: 5px;
      mask: url(${externalLink}) no-repeat center left;
      min-width: 15px;
    }
  }

  @media screen and (min-width: 768px) {
    margin-top: 0;
  }

  > .title {
    color: #000;
    font-size: ${UIVars.pSize.big}px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  > .recommendations {
    margin-bottom: 0;
    padding-left: 0;

    > li {
      line-height: 1.4;
      list-style: none;
      margin-bottom: 20px;
      margin-left: 0;

      @media screen and (min-width: 768px) {
        line-height: 1.5;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:after {
        clear: both;
        content: '';
        display: block;
      }

      svg {
        float: left;
        margin-top: 3px;
      }
    }

    .text {
      float: left;
      width: 87%;
    }
  }
`

export const LinksStores = styled.div`
  margin-top: 25px;
  padding-left: 25px;

  a {
    &:first-child {
      img {
        margin-right: 5px;
      }
    }
  }

  img {
    height: 45px;
  }

  @media screen and (max-width: 420px) {
    img {
      height: 37px;
    }
  }
  @media screen and (max-width: 360px) {
    img {
      height: 32px;
    }
  }
`
