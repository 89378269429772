import { CSSProperties } from 'styled-components'

import { useViewDownload } from 'app/Views/Resources/PDF'

const DEFAULT_WIDTH = 80

/**
 * Devuelvo ancho máximo de la imagen, solo si es descarga del PDF.
 * El valor por defecto es 80% pero puede venir configurado desde platbe
 */
export const useWidthImagePDF = (width?: string): CSSProperties => {
  const isViewDownload = useViewDownload()

  const stylePDF: CSSProperties = {}
  if (isViewDownload) {
    stylePDF.maxWidth = `${width || DEFAULT_WIDTH}%`
  }

  return stylePDF
}
