export const PresencialCheck = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" data-testid="PresencialCheck">
      <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
      <path
        d="M28 29C28 27.8954 28.8954 27 30 27H32C33.1046 27 34 27.8954 34 29V32H28V29Z"
        fill="#1EBDAF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 35C33.2091 35 35 33.2091 35 31C35 28.7909 33.2091 27 31 27C28.7909 27 27 28.7909 27 31C27 33.2091 28.7909 35 31 35Z"
        stroke="#08044F"
        strokeWidth="2"
      />
      <path
        d="M39 41.999C37.1755 39.5707 34.2712 38 31 38C27.7288 38 24.8245 39.5707 23 41.999"
        stroke="#08044F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M32 9H26C25.4477 9 25 9.44772 25 10V12C25 12.5523 25.4477 13 26 13H32C32.5523 13 33 12.5523 33 12V10C33 9.44772 32.5523 9 32 9Z"
        fill="#FF554D"
      />
      <path
        d="M41 15H26C25.4477 15 25 15.4477 25 16V18C25 18.5523 25.4477 19 26 19H41C41.5523 19 42 18.5523 42 18V16C42 15.4477 41.5523 15 41 15Z"
        fill="#0189FF"
      />
      <path
        d="M12 16C12 14.8954 12.8954 14 14 14H18C19.1046 14 20 14.8954 20 16V20H12V16Z"
        fill="#FFBC27"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 23C18.7614 23 21 20.7614 21 18C21 15.2386 18.7614 13 16 13C13.2386 13 11 15.2386 11 18C11 20.7614 13.2386 23 16 23Z"
        stroke="#08044F"
        strokeWidth="2"
      />
      <path
        d="M7 34C7 29.5817 10.8802 26 15.6667 26C18.166 26 20.4183 26.9766 22 28.539"
        stroke="#08044F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M7 17.7895V8C7 6.89543 7.89543 6 9 6H37C38.1046 6 39 6.89543 39 8V20C39 21.1046 38.1046 22 37 22H28"
        stroke="#08044F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="35" cy="35" r="8" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 35C27 30.6 30.6 27 35 27C39.4 27 43 30.6 43 35C43 39.4 39.4 43 35 43C30.6 43 27 39.4 27 35ZM30.6 35L34 38.4L39.4 33L38 31.6L34 35.6L32 33.6L30.6 35Z"
        fill="#28A745"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45 35C45 40.5228 40.5228 45 35 45C29.4772 45 25 40.5228 25 35C25 29.4772 29.4772 25 35 25C40.5228 25 45 29.4772 45 35ZM35 43C39.4183 43 43 39.4183 43 35C43 30.5817 39.4183 27 35 27C30.5817 27 27 30.5817 27 35C27 39.4183 30.5817 43 35 43Z"
        fill="white"
      />
    </svg>
  )
}
