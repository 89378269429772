import React from 'react'
import styled from 'styled-components/macro'
import { UIVars } from 'app/Components/UI'

import getIcon from './helpers/getIcon'
import { BaseUIProps } from './helpers/types'

export const Alert = ({
  children = 'Texto para mensaje flash. Lorem ipsum dolor sit amet',
  type = 'success',
}: BaseUIProps) => {
  return (
    <Wrapper>
      <div id="alert" className={`alert ${type} visible`}>
        <img className="image" src={getIcon(type)} alt={type} />
        <div className="message" data-testid="alert-message">
          {children}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .alert {
    align-items: center;
    border-radius: 10px;
    display: flex;
    padding: 10px 10px 10px 20px;
    width: 100%;

    .image {
      margin-right: 15px;
      height: 48px;
    }
    .message {
      flex-grow: 2;
      font-size: 14px;
      line-height: 1.29;
      margin-right: 20px;
    }
    &.success {
      background-color: ${UIVars.setColors.success};
    }
    &.info {
      background-color: ${UIVars.setColors.info};
    }
    &.warning {
      background-color: ${UIVars.setColors.warning};
    }
    &.error {
      background-color: ${UIVars.setColors.danger};
    }
  }
`
