import React from 'react'
import { useTranslation } from 'react-i18next'

import { CheckBox } from '../Common'
import * as Style from './style'

/**
 * Check de terminos y check de subscripcion
 */
export const PersonContactability = () => {
  const { t } = useTranslation()

  return (
    <Style.Wrapper className="ProfileForms-PersonContactability">
      <CheckBox name="agree" text={t('ProfileAgreeTerms')} />
      <CheckBox name="allowContact" text={t('ProfileIWantInfo')} />
    </Style.Wrapper>
  )
}
