import React from 'react'
import { useTranslation } from 'react-i18next'
import Types from './types'

export const ClassDateDetail = ({ data }: Types.ClassDateDetails) => {
  const { t } = useTranslation()
  return (
    <ul className="details">
      {data.map((detail, key) => {
        return (
          <li key={key}>
            <img
              src={detail.icon}
              className="detailicon"
              data-testid="detailIcon"
              alt={detail.description}
            />
            <span className={`detailinfo ${detail.classes}`}>
              <p>{t(detail.label)}</p>
              <b>{detail.value}</b>
            </span>
          </li>
        )
      })}
    </ul>
  )
}
