import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { PhoneInputCall } from './PhoneInput'
import { Label, handleRequired, TypeError } from '../'
import * as Styles from './Styles'
import { Types } from './types'

export const Phone = ({ name, label, required, onChange: handleChange, disabled }: Types.Phone) => {
  const { t } = useTranslation()

  const { control, formState, register, errors } = useFormContext()

  const error = errors[name]?.message

  const statusRequired = handleRequired(required)

  return (
    <Styles.GlobalWrapper className={`profile-${name} ${error ? 'error' : ''}`}>
      <Label htmlFor={name}>{label}</Label>
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        rules={{
          required: statusRequired,
          pattern: {
            value: /^([+])?([0-9]{11,13})$/,
            message: t('ProfileOnlyNumbers11'),
          },
        }}
        render={({ value, onChange }) => (
          <PhoneInputCall
            value={value || ''}
            disabled={formState.isSubmitting || disabled}
            onChange={(e: string, data: Types.dataFull) => {
              onChange(e)
              handleChange && handleChange(e, data)
            }}
          />
        )}
      />
      <input type="hidden" name="countryCode" ref={register} />
      {statusRequired !== false && <TypeError error={error} />}
    </Styles.GlobalWrapper>
  )
}
