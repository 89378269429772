import { formattedSeconds } from 'app/Services'
import { Text } from '.'

export const Time = ({
  seconds,
  isNegative = false,
}: {
  seconds: number
  isNegative?: boolean
}) => {
  if (seconds === undefined || typeof seconds !== 'number') {
    return <></>
  }

  const { seconds: sec, minutes } = formattedSeconds(seconds)

  return (
    <Text>
      {isNegative && '-'}
      {minutes}:{sec}
    </Text>
  )
}
