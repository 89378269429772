import styled, { css } from 'styled-components/macro'

import { layout } from '../Common'

export const Wrapper = styled.div`
  ${layout(
    css`
      grid-template-columns: 1fr;

      .profile-agree {
        grid-area: agree;
      }

      .profile-allowContact {
        grid-area: allowContact;
      }

      grid-template-areas:
        'agree'
        'allowContact';
      row-gap: 20px;
    `,
    css`
      row-gap: 20px;
    `
  )}
`
