import { useTranslation } from 'react-i18next'
import { List, ListItem, Text, Flex, HStack, Divider, Box } from '@chakra-ui/react'
import { BtnLink, vars } from '@eclass/ui-kit'
import ReactHtmlParser from 'react-html-parser'

import { Modal } from 'app/Components/UI'
import { Icon } from 'app/Components/UI/Icons'
import Types from './types'

/**
 *
 * Componente que dibuja el modal para pre requisitos
 */
export const PrerequisitesModal = ({
  isOpen,
  onClose,
  materialName,
  prerequisites,
}: Types.PrerequisitesModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal
      type={'full'}
      isOpen={isOpen}
      fade={true}
      btnClose={true}
      header={t('ResourcePrerequisitesHeader')}
      className="massive-alert"
      toggle={onClose}
      closeModal={onClose}
    >
      <Text sx={{ fontSize: '1rem !important' }}>
        {ReactHtmlParser(t('ResourcePrerequisitesModal', { name: materialName }))}
      </Text>
      <HStack spacing={6} mt="4" aria-hidden>
        <Flex align="center" justify="center">
          <Icon name="circle-check" fill={vars('colors-alert-jadeGreen')} />
          <Text as="span" fontSize="xs">
            {t('ResourcePrerequisitesCompeted')}
          </Text>
        </Flex>
        <Flex align="center" justify="center">
          <Icon name="warning" fill={vars('colors-icon-ripeMango')} />
          <Text as="span" fontSize="xs">
            {t('ResourcePrerequisitesPending')}
          </Text>
        </Flex>
      </HStack>
      <Divider ml={0} mr={0} mt={4} mb={4} color={vars('colors-neutral-platinum')} aria-hidden />
      <List spacing={4} pl={0}>
        {prerequisites.map((prerequisite) => {
          return (
            <ListItem key={prerequisite.id} fontSize="md" display="flex" alignItems="center">
              <BtnLink
                as="a"
                ariaLabel={`${
                  prerequisite.completed
                    ? t('ResourcePrerequisitesCompeted')
                    : t('ResourcePrerequisitesPending')
                } ${ReactHtmlParser(prerequisite.name)}`}
                href={prerequisite.href}
              >
                <Box fontSize="md" aria-hidden>
                  {prerequisite.completed ? (
                    <Icon name="circle-check" fill={vars('colors-alert-jadeGreen')} />
                  ) : (
                    <Icon name="warning" fill={vars('colors-icon-ripeMango')} />
                  )}
                  {ReactHtmlParser(prerequisite.name)}
                </Box>
              </BtnLink>
            </ListItem>
          )
        })}
      </List>
    </Modal>
  )
}
