import { Box, CSSObject } from '@chakra-ui/react'
import classNames from 'classnames'
import ReactHtmlParser from 'react-html-parser'

import { useWidthImagePDF } from '../useWidthImagePDF'
import { Props } from '../../types'
import './style.scss'

/**
 *
 * @example
 *    <ImageBreak config={options} id={62} />
 */
export const ImageBreak = ({ config, id }: Props.ImageBreak) => {
  const stylePDF = useWidthImagePDF(config.pdf_width!)
  const hasParagraph =
    config.text.replace(/<p><br><\/p>/g, '').replace(/<p>(&nbsp;)+<\/p>/gi, '').length > 0

  const custom: CSSObject = {}
  const customImg: CSSObject = {}

  if (config.height) {
    custom.height = 'max-content'
    customImg.maxHeight = config.height + 'px'
  }
  if (config.width) {
    custom.maxWidth = config.width + 'px'
    custom.width = '100%'
  }

  const hasFixedSize = config.height || config.width

  return (
    <Box
      as="section"
      className={classNames('Article__image_break', {
        'float right': config.position === 'right',
        'float left': config.position === 'left',
        hasFixedSize,
      })}
      data-testid="imageBreak"
      style={stylePDF}
      sx={custom}
      key={id}
    >
      <Box
        as="img"
        sx={customImg}
        src={`https://cdn.eclass.com/${config.image}`}
        alt={`https://cdn.eclass.com/${config.image}`}
      />
      {config.text !== '' && hasParagraph && ReactHtmlParser(config.text)}
    </Box>
  )
}
