// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { axiosError } from '../../../Services'
import { LoginPayload, LoginInput } from '@eclass/api'
import { encryptData } from './ecrypto'
type Response = {
  data: {
    data: {
      UserLogin: LoginPayload
    }
  }
}

const UserLogin = (input: LoginInput) => {
  const requestId = 'UserLogin'
  return async (dispatch: any) => {
    const query = `
      mutation UserLogin ($LoginInput: LoginInput!) {
        UserLogin (input: $LoginInput) {
          token
          status {
            code
            name
            success
            detail
          }
        }
      }
    `

    const variables = {
      LoginInput: {
        ...input,
        password: encryptData(input.password),
        forceSecure: true,
      },
    }
    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response: Response) => {
        if (response.data.data?.UserLogin?.status?.success) {
          dispatch({
            type: 'LOGIN',
            payload: response.data.data.UserLogin,
          })

          localStorage.setItem('platform:apollo:token', response.data.data.UserLogin.token)
        }
        return response.data.data.UserLogin
      })
      .catch((err) => axiosError(err, requestId, dispatch))
  }
}

export default UserLogin
