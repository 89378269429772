import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as Style from './style'
import * as Types from './types'

export function QuestionsResource({ context }: Types.QuestionsResource) {
  const { t } = useTranslation()

  if (!context) {
    return null
  }

  return (
    <Style.QuestionsResourceWrapper>
      <Style.MainSection>
        <span>{t('QuestionAboutResource')}</span>
        {context.own.total > 0 && (
          <Style.MyQuestions>
            <Link to={context.own.link}>{t('QuestionSeeMyQuestions')}</Link>
            {context.own.style === 'featured' && <Style.WrapperDot />}
          </Style.MyQuestions>
        )}
      </Style.MainSection>
      {context.public.total > 0 && (
        <Style.OthersQuestions>
          <hr />
          <span>
            {/**
             * El componente Trans es necesario cuando se quiere inyectar en una traducción un componente de react. Es necesario mantener
             * la cantidad de nodos dentro del componente Trans por lo que se decidió mantener el texto de la traducción en ingles.
             * Al editar la traducción hay que revisar como afectara la cantidad de nodos a la posición del componente inyectado
             *
             * Se explica en el link la relación con la cantidad de nodos y los index del children.
             * @see https://react.i18next.com/latest/trans-component#how-to-get-the-correct-translation-string
             *
             * Link a la llave de la traducción
             * @see https://gitlab.com/eclass/translations/blob/e1b86b8f63ff1a088d834749d33ae66a580d5a2d/src/eng/Questions.ts#L70
             */}
            <Trans i18nKey="QuestionNAboutResource" count={context.public.total}>
              There is <strong>{{ count: context.public.total }} more question</strong> about this
              resource, review it in the, <Link to={context.public.link}>Question</Link> section
            </Trans>
          </span>
        </Style.OthersQuestions>
      )}
    </Style.QuestionsResourceWrapper>
  )
}
