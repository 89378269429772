// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

// @Dependencies
import vars from '../variables'

// @Component
const Loader = ({ color, opacity, speed, text, size }) => (
  <StyledLoader color={color} speed={speed} className={`${size}`}>
    <StyledLoaderAnimate
      color={color}
      speed={speed}
      opacity={opacity}
      className={`loader loader-${size}`}
    />
    {text}
  </StyledLoader>
)

// @Proptypes
Loader.propTypes = {
  color: PropTypes.string,
  opacity: PropTypes.number,
  size: PropTypes.string,
  speed: PropTypes.string,
  text: PropTypes.string,
}
Loader.defaultProps = {
  color: vars.setColors.primary,
  opacity: 0.5,
  size: 'md',
  speed: '1.1s',
  text: 'Cargando...',
}

// @Export Component
export default Loader

// @Styles
const StyledLoader = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.3571rem;
  margin: 1rem 1rem 1rem 0;
  &.sm {
    font-size: 1.1429rem;
  }
  &.lg {
    font-size: 1.5714rem;
  }
  .loader {
    display: inline-block;
    flex-shrink: 0;
    margin-right: 1rem;
    position: relative;
    transform: translateZ(0);
    vertical-align: middle;

    &,
    &:after {
      border-radius: 50%;
      height: 3rem;
      width: 3rem;
    }
    &-sm {
      height: 2rem;
      width: 2rem;
      border-width: 0.3rem;
    }
    &-lg {
      height: 4rem;
      width: 4rem;
      border-width: 0.5rem;
    }
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

StyledLoader.displayName = 'Loader'

const StyledLoaderAnimate = styled.div`
  animation: load8 ${(props) => props.speed} infinite linear;
  border-bottom: 0.4rem solid ${(props) => props.color};
  border-left: 0.4rem solid ${(props) => vars.setColors.hexToRgbA(props.color, props.opacity)};
  border-right: 0.4rem solid ${(props) => props.color};
  border-top: 0.4rem solid ${(props) => props.color};
`
