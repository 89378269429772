import { IDModules } from '../'
import { props } from '../../'

/**
 * Busca si el contenido tiene timeline, si es así, modifica el contenido y embeds para que el timeline se comporte como un modulo normal
 * @returns content
 * @returns embeds
 */
export const parserTimeline = ({ content, embeds, data }: props) => {
  let newContent = content
  let newEmbeds: props['embeds'] = embeds

  if (data?.timelines && data.timelines.length > 0 && content.search('REPLACE_TIMELINE')) {
    // Agrego a embeds los Timelines, pasandolos al formato de los embeds
    // Para evitar conflictos entre Ids, seteo en numero negativo los Ids de los Timelines
    const convertTimelineConfig = data.timelines.map((timeline) => ({
      id: Number(-timeline.id),
      name: 'Timeline',
      configurations: JSON.stringify(timeline),
      typeId: IDModules.TIMELINE,
    }))

    newEmbeds = [...(embeds ?? []), ...convertTimelineConfig]

    // Agrego caracter "-" en el id del timeline para hacer match con el embeds
    const TEXT_TIMELINE = '___REPLACE_TIMELINE___'
    const startModuleTimelineRegExp = `${TEXT_TIMELINE}(?=[0-9])`
    const startModuleTimeline = new RegExp(startModuleTimelineRegExp, 'g')
    const reemplazo = content.replace(startModuleTimeline, `${TEXT_TIMELINE}-`)

    // convierto timeline en tipo modulo
    const timelineRegExp = new RegExp(TEXT_TIMELINE, 'g')
    newContent = reemplazo.replace(timelineRegExp, `___REPLACE_CONTENT_MODULE___`)
  }

  return {
    content: newContent,
    embeds: newEmbeds,
  }
}
