import { isValid } from 'date-fns'

/**
 * Valida la fecha, si es valida la retorna si no retorna null
 * @example
 * initDateValue(data?.startDate)
 */
export const initDateValue = (value?: string | Date) => {
  if (!value) {
    return null
  }
  const valueFormat = typeof value === 'string' ? value + 'T00:00:00' : value
  const date = new Date(valueFormat)
  return isValid(date) ? date : null
}
