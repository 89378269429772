import { Box } from '@chakra-ui/react'
import { useHelpCenter } from './hooks/useHelpCenter'
import { HandleTypeBox } from './Components/HandleTypeBox'

/**
 * Caja que muestra los contenedores de contacto a cordinación y preguntas frecuentes
 */

type HelpCenterProps = {
  type: 'footer' | 'dropdown'
}

/**
 * Listado de cajas con la información de ayuda
 */
export const HelpCenter = ({ type = 'footer' }: HelpCenterProps) => {
  const { data, isSence } = useHelpCenter()

  const templateColumns = data.length === 1 ? 'repeat(1, 1fr)' : 'repeat(2, 2fr)'

  return (
    <Box
      className={`HelpCenter ${data.length}_items`}
      display={type === 'footer' ? 'flex' : 'grid'}
      gridTemplateColumns={type === 'footer' ? '' : templateColumns}
    >
      {data?.map((box, key) => {
        return <HandleTypeBox box={box} isSence={isSence} key={key} />
      })}
    </Box>
  )
}
