import { Box } from '@chakra-ui/react'

import { ContentParser } from 'app/Components'
import { LabelIndex } from '../../LabelIndex'
import * as Type from '../../types'

export const Content = ({ item, index, total, data }: Type.content) => {
  return (
    <Box key={item.label} className="content" mt="2rem">
      <Box display="flex" alignItems="baseline" mb="24px">
        <Box as="h6" fontStyle="normal" fontWeight="700" fontSize="1.25rem" mr="16px">
          {item.label}
        </Box>
        <LabelIndex index={index} total={total} />
      </Box>
      <Box className="ContentArticleChild">
        {ContentParser({ content: item.text, embeds: data.embeds, changeTypeNode: false })}
      </Box>
    </Box>
  )
}
