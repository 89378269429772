import { RequestErrorReducer } from 'app/Redux/Reducers/RequestError/types'
import { ApiRequest } from '@eclass/api'

/**
 * Función que ejecuta el dispatch a redux con lo errores
 *
 * @param {any} dispatch - dispatch aplicación.
 * @param {ApiRequest} response - Petición API.
 * @param {string} [redirect] - Url a redirigir.
 * @example
 * ```js
 *    RequestError.handle(dispatch, response)
 * ```
 */
export const handle = (dispatch: any, response?: ApiRequest, redirect?: string) => {
  let payload: RequestErrorReducer = {
    success: false,
    code: 500,
    name: '',
    detail: '',
  }
  if (response) {
    payload = response.status
  }

  if (redirect) {
    payload.redirect = redirect
  }

  dispatch({
    type: 'SET_ERROR',
    payload,
  })
}
