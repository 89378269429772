import Pusher from 'pusher-js/types/src/core/pusher'

export interface INotification {
  leaderboard?: Pusher
}

const initialState: INotification = {
  leaderboard: undefined,
}

/**
 * Export reducer de leaderboard.
 *
 * @param {Object} state - Estado inicial
 * @param {string} action - Acción a procesar
 */
export const notification = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case 'SET_NOTIFICATION_LEADERBOARD':
      return {
        ...state,
        leaderboard: payload,
      }
    default:
      return state
  }
}
