import { Box, ChakraProps } from '@chakra-ui/react'
import './style.css'

interface ISkeleton extends ChakraProps {
  /** si no se quiere los bordes redondeados */
  borderless?: boolean
  dark?: boolean
  /** Es necesario que lleve el mismo width y height para que dibuje un circulo */
  isCircle?: boolean
}

/**
 * Componente visual que dibuja caja con color, bordes y animación del skeleton
 */
export const Skeleton = ({
  w = '100%',
  h = '2rem',
  isCircle,
  borderless,
  borderRadius,
  dark,
}: ISkeleton) => {
  let radius = borderRadius ?? '.5em'

  if (isCircle) {
    radius = '50%'
  } else if (borderless) {
    radius = '0'
  }

  const config = {
    default: {
      bg: '#ebebeb',
      glow: {
        start: '#f5f5f5',
        end: '#ebebeb',
      },
    },
    dark: {
      bg: '#3c3c3c',
      glow: {
        start: '#464646',
        end: '#4b4b4b',
      },
    },
  }

  const colors = config[dark ? 'dark' : 'default']

  return (
    <Box
      w={w}
      h={h}
      bg={colors.bg}
      borderRadius={radius}
      position="relative"
      overflow="hidden"
      className="skeletonBox"
      _before={{
        animationDuration: '1s',
        animationFillMode: 'forwards',
        animationIterationCount: 'infinite',
        animationName: 'skeletonAnimation',
        animationTimingFunction: 'linear',
        background: `linear-gradient(to right, transparent 30%, ${colors.glow.start}  48%, ${colors.glow.end}  52%, transparent 70%)`,
        content: `""`,
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '2000px',
      }}
    />
  )
}
