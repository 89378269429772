import { gql, useQuery, QueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const GET_QUESTION_INFO = gql`
  ${STATUS_FIELDS}
  query getQuestionInfo($QuestionInfoInput: QuestionInfoInput!) {
    getQuestionInfo(input: $QuestionInfoInput) {
      status {
        ...StatusFields
      }
      context {
        public {
          total
          link
          style
        }
        own {
          total
          link
          style
        }
      }
      canPublic
    }
  }
`

export type QuestionInfoPayload = Api.QuestionInfoPayload

export type QuestionInfoInput = Api.QuestionInfoInput

interface QuestionInfoPayloadApollo {
  getQuestionInfo: QuestionInfoPayload
}

interface QuestionInfoInputApollo {
  QuestionInfoInput: QuestionInfoInput
}

export const useQueryGetQuestionInfo = (
  options?: QueryHookOptions<QuestionInfoPayloadApollo, QuestionInfoInputApollo>
) => {
  return useQuery<QuestionInfoPayloadApollo, QuestionInfoInputApollo>(GET_QUESTION_INFO, options)
}
