import { useTranslation } from 'react-i18next'

const useTranslationEditor = () => {
  const { t } = useTranslation()
  const translations = {
    // Generic
    'generic.add': t('CommentSystemAdd'),
    'generic.cancel': t('LogInCancel'),

    // Image
    'components.controls.image.dropFileText': t('CommentSystemDropOrClick'),

    // Link
    'components.controls.link.linkTitle': t('CommentSystemTitleLink'),
    'components.controls.link.linkTarget': t('CommentSystemLink'),

    // Remove
    'components.controls.remove.remove': t('ProfileDelete'),
  }
  return translations
}

export default useTranslationEditor
