import { gql, useQuery, QueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const GET_RESOURCE_MODULE = gql`
  ${STATUS_FIELDS}
  query getResourceModule($ResourceModuleInput: ResourceModuleInput!) {
    getResourceModule(input: $ResourceModuleInput) {
      status {
        ...StatusFields
      }
      Module {
        id
        configurations
        typeId
      }
    }
  }
`

export type GetResourceModulePayload = Api.ResourceModulePayload

export type GetResourceModuleInput = Api.ResourceModuleInput

interface GetResourceModulePayloadApollo {
  getResourceModule: GetResourceModulePayload
}

interface GetResourceModuleInputApollo {
  ResourceModuleInput: GetResourceModuleInput
}

export const useQueryGetResourceModule = (
  options?: QueryHookOptions<GetResourceModulePayloadApollo, GetResourceModuleInputApollo>
) => {
  return useQuery<GetResourceModulePayloadApollo, GetResourceModuleInputApollo>(
    GET_RESOURCE_MODULE,
    options
  )
}
