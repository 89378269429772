// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { getToken, axiosError } from '../../../Services'

/**
 * Lista los paises ordenados por nombre de forma ascendente
 * @example
 * ```js
 *    const { data } = await getCountries()
 * ```
 * @returns {Promise<any>} Listado de Paises (id, name)
 */
const getCountries = () => {
  const requestId = 'getCountries'
  return async (dispatch) => {
    getToken()

    const query = `
      query allCountrys {
        allCountrys(
          paginate: 1000
          orderBy: name_ASC
        ){
          name
          id
        }
      }
    `

    return axios
      .post(`${apiURL}/graphql`, { query }, { requestId })
      .then((response) => {
        const { allCountrys } = response.data.data
        if (response.status === 200 && allCountrys) {
          dispatch({
            type: 'PROFILE_COUNTRIES',
            payload: allCountrys,
          })
          return {
            data: allCountrys,
            status: {
              success: true,
              detail: 'Petición exitosa',
            },
          }
        } else {
          return {
            data: [],
            status: {
              success: false,
              detail: 'Error al buscar los países.',
            },
          }
        }
      })
      .catch((err) => axiosError(err, requestId, dispatch))
  }
}

export default getCountries
