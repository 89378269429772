import { gql, useQuery, QueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const GET_COURSE_PERFORMANCE = gql`
  ${STATUS_FIELDS}
  query getCoursePerformance($CoursePerformanceInput: CoursePerformanceInput!) {
    getCoursePerformance(input: $CoursePerformanceInput) {
      status {
        ...StatusFields
      }
      average {
        type
        valueFormatted
        status {
          id
          enabled
          name
          style
        }
      }
      constancy {
        valueFormatted
        value
        status {
          id
          enabled
          name
          style
        }
      }
      approved
      showFinalAverage
      weighing
      showWeighing
      CalificationGroups {
        id
        name
        weighing
        totalCompleted
        totalTest
        calificationGroupTypeId
        average {
          valueFormatted
          status {
            id
            enabled
            name
            style
          }
        }
        Tests {
          id
          title
          description
          qualification {
            valueFormatted
            status {
              id
              enabled
              name
              style
            }
          }
        }
      }
    }
  }
`

export type GetCoursePerformancePayload = Api.CoursePerformancePayload

export type GetCoursePerformanceInput = Api.CoursePerformanceInput

interface GetCoursePerformancePayloadApollo {
  getCoursePerformance: GetCoursePerformancePayload
}

interface GetCoursePerformanceInputApollo {
  CoursePerformanceInput: GetCoursePerformanceInput
}

export const useQueryGetCoursePerformance = (
  options?: QueryHookOptions<GetCoursePerformancePayloadApollo, GetCoursePerformanceInputApollo>
) => {
  return useQuery<GetCoursePerformancePayloadApollo, GetCoursePerformanceInputApollo>(
    GET_COURSE_PERFORMANCE,
    options
  )
}
