import styled from 'styled-components/macro'

import { UIVars } from 'app/Components/UI'

export const Wrapper = styled.footer`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 0;
  position: relative;

  .btn_container {
    width: 100%;

    div {
      width: 100%;
    }
  }
  @media screen and (min-width: 480px) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    .btn_container {
      div {
        width: 144px;
      }
    }
  }
`

export const ButtonDelete = styled.button`
  background: transparent;
  border: 0;
  margin-top: 20px;

  svg g {
    fill: ${UIVars.setColors.red};
  }

  span {
    color: ${UIVars.setColors.red};
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
  }

  @media screen and (min-width: 480px) {
    margin-top: 0;
  }
`
