// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../Config/getDomainConfig'
import { axiosError, getToken } from 'app/Services'
import { RouteParams } from 'Config/routes/types'
import { UserReducer } from 'app/Redux/Reducers/Users/types'
import { CourseInfoPayload } from '@eclass/api'

type props = {
  userData: UserReducer
  courseData: CourseInfoPayload
  route: {
    url: string
    params: RouteParams
  }
  type: {
    controller: string
    action: string
  }
}

/**
 * Valido si viene el valor y lo convierto en número,
 * sino retorno un string vacio
 */
const validateAndParse = (value: string) => {
  return value ? Number(value) : ''
}

const addHistory = ({ userData, courseData, route, type }: props) => {
  const requestId = 'addHistory'
  return async (dispatch: any) => {
    const { entryId } = getToken()!

    if (entryId > 0) {
      const query = `
        mutation createHistory {
          createHistory(
            entryId: ${entryId},
            userId: ${userData.id},
            campusId: ${courseData.Course?.campusId},
            courseId: ${courseData.Course?.id},
            unitId: ${route.params.unitId || 0},
            controller: "${type.controller}",
            action: "${type.action}",
            url: "${route.url || ''}",
            pass1: "${Number(route.params.programId!)}",
            pass2: "${Number(route.params.studentId!)}",
            pass3: "${Number(route.params.courseId!)}",
            pass4: "${validateAndParse(route.params.unitId!)}",
            pass5: "${validateAndParse(route.params.resourceId!)}",
            pass6: "${validateAndParse(route.params.levelId!)}",
            pass7: "",
            pass8: "",
            platform: "8.0"
          ) {
            id
          }
        }
      `

      return axios
        .post(`${apiURL}/graphql`, { query })
        .then((response) => {
          if (response.data.errors) {
            return {
              status: false,
              message: response.data.errors[0].message,
              data: [],
            }
          } else if (response.status === 200 && response.data.data) {
            return {
              status: true,
              message: response.statusText,
              data: response.data.data,
            }
          }
        })
        .catch((err: any) => {
          axiosError(err, requestId, dispatch)
        })
    }
  }
}

export default addHistory
