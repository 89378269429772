import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoaderAsync } from 'app/Components/UI'
import { AlertNotification } from 'app/Components/UI/Alert'
import { SpeechRecognition } from 'app/Components/UI/Icons'
import { Feedback } from './Feedback'
import * as Style from './style'

export const CommonVoiceRecognition = ({
  browserSupport,
  onClick,
  state,
  redo,
  alertError,
  closedError,
  transcript,
}) => {
  const { t } = useTranslation()

  const configStatus = {
    INIT: t('ResourceVoiceRecord'),
    RECORDING: t('ResourceVoiceFinishRecording'),
    LOADING: t('ResourceVoiceValidating'),
    SUCCESS: t('ResourceVoiceRedo'),
    ERROR: t('ResourceVoiceRedo'),
    WAITING: t('ResourceVoiceWaiting'),
    DENIED: t('ResourceVoicePermission'),
    API_ERROR: t('ResourceVoiceRedo'),
    NOT_SUPPORTED: t('ResourceVoiceNotSupported'),
    NOT_UNDERSTAND: t('ResourceVoiceRedo'),
  }

  const showRedo = ['SUCCESS', 'ERROR', 'NOT_UNDERSTAND'].includes(state)

  return (
    <Style.Wrapper>
      <AlertNotification
        message={alertError.isSupported ? alertError.text ?? '' : configStatus.NOT_SUPPORTED}
        type="error"
        show={alertError.show}
        closed={() => closedError()}
        cleanUp
      />
      <div>
        <header>{browserSupport ? configStatus[state] : configStatus.NOT_SUPPORTED}</header>
        <Style.Buttons className={state} onClick={() => !showRedo && onClick()}>
          <SpeechRecognition.Microphone className="myIcon icon-init" />
          <SpeechRecognition.Stop className="myIcon icon-recording" />
          <LoaderAsync className="myIcon icon-loading" />
          {showRedo && <Feedback transcript={transcript} state={state} redo={redo} />}
        </Style.Buttons>
      </div>
    </Style.Wrapper>
  )
}
