export const Info = (props) => {
  return (
    <g {...props} className="info" fill="none" fillRule="evenodd">
      <g id="c-info-(5)" fill="#B0CFE0" {...props} fillRule="nonzero">
        <path
          d="M8,0 C3.6,0 0,3.6 0,8 C0,12.4 3.6,16 8,16 C12.4,16 16,12.4 16,8 C16,3.6 12.4,0 8,0 Z M9,12 L7,12 L7,7 L9,7 L9,12 Z M8,6 C7.4,6 7,5.6 7,5 C7,4.4 7.4,4 8,4 C8.6,4 9,4.4 9,5 C9,5.6 8.6,6 8,6 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  )
}
