import * as React from 'react'

export const TermsIcon = () => (
  <svg
    width="45"
    height="45"
    role="TermsIcon"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
    <path
      d="M18 10H14C13.4477 10 13 10.4477 13 11V15C13 15.5523 13.4477 16 14 16H18C18.5523 16 19 15.5523 19 15V11C19 10.4477 18.5523 10 18 10Z"
      fill="#FF554D"
    />
    <path
      d="M18 20H14C13.4477 20 13 20.4477 13 21V25C13 25.5523 13.4477 26 14 26H18C18.5523 26 19 25.5523 19 25V21C19 20.4477 18.5523 20 18 20Z"
      fill="#1EBDAF"
    />
    <path
      d="M18 30H14C13.4477 30 13 30.4477 13 31V35C13 35.5523 13.4477 36 14 36H18C18.5523 36 19 35.5523 19 35V31C19 30.4477 18.5523 30 18 30Z"
      fill="#FFBC27"
    />
    <line x1="16" y1="13" x2="29" y2="13" stroke="#08044F" strokeWidth="2" strokeLinecap="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 23C15 22.4477 15.4477 22 16 22L25 22C25.5523 22 26 22.4477 26 23C26 23.5523 25.5523 24 25 24L16 24C15.4477 24 15 23.5523 15 23Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 33C15 32.4477 15.4477 32 16 32L25 32C25.5523 32 26 32.4477 26 33C26 33.5523 25.5523 34 25 34L16 34C15.4477 34 15 33.5523 15 33Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 8C11.4477 8 11 8.44772 11 9V36.5375C11 37.4188 11.6442 38 12.2807 38H32C32.5523 38 33 37.5523 33 37V30C33 29.4477 33.4477 29 34 29C34.5523 29 35 29.4477 35 30V37C35 38.6569 33.6569 40 32 40H12.2807C10.398 40 9 38.3762 9 36.5375V9C9 7.34315 10.3431 6 12 6H31.7193C33.602 6 35 7.62383 35 9.46252V12C35 12.5523 34.5523 13 34 13C33.4477 13 33 12.5523 33 12V9.46252C33 8.58118 32.3558 8 31.7193 8H12Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38 21C38 23.2091 36.2091 25 34 25C31.7909 25 30 23.2091 30 21C30 18.7909 31.7909 17 34 17C36.2091 17 38 18.7909 38 21ZM37.7806 25.6593C36.7488 26.4976 35.4331 27 34 27C30.6863 27 28 24.3137 28 21C28 17.6863 30.6863 15 34 15C37.3137 15 40 17.6863 40 21C40 22.1574 39.6723 23.2383 39.1046 24.1549L41.7262 26.7765C42.1168 27.167 42.1168 27.8002 41.7262 28.1907C41.3357 28.5812 40.7025 28.5812 40.312 28.1907L37.7806 25.6593Z"
      fill="#0189FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 43C26 43.5523 25.5523 44 25 44L7.99997 44C6.34312 44 4.99997 42.6569 4.99997 41L5 13C5 12.4477 5.44772 12 6 12C6.55228 12 7 12.4477 7 13L6.99997 41C6.99997 41.5523 7.44769 42 7.99997 42L25 42C25.5523 42 26 42.4477 26 43Z"
      fill="#08044F"
    />
  </svg>
)
