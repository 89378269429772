import { gql, useLazyQuery, LazyQueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const getCourseClassmates = gql`
  ${STATUS_FIELDS}
  query getCourseClassmates($CourseClassmateInput: CourseClassmateInput!) {
    getCourseClassmates(input: $CourseClassmateInput) {
      status {
        ...StatusFields
      }
      pages
      totalClassmates
      Classmates {
        id
        name
        showModal
        avatar
        occupation
        resume
        generalInfo {
          icon
          value
          isPrincipal
        }
      }
    }
  }
`

export type GetCourseClassmatesPayload = Api.CourseClassmatePayload

export type GetCourseClassmatesInput = Api.CourseClassmateInput

interface GetCourseClassmatesPayloadApollo {
  getCourseClassmates: GetCourseClassmatesPayload
}

interface GetCourseClassmatesInputApollo {
  CourseClassmateInput: GetCourseClassmatesInput
}

export const useQueryGetCourseClassmates = (
  options?: LazyQueryHookOptions<GetCourseClassmatesPayloadApollo, GetCourseClassmatesInputApollo>
) => {
  return useLazyQuery<GetCourseClassmatesPayloadApollo, GetCourseClassmatesInputApollo>(
    getCourseClassmates,
    options
  )
}
