import { differenceInCalendarDays } from 'date-fns'
import { useDateFormat } from 'app/Services'

/**
 * Retorno la fecha del comentario.
 *
 * Si el comentario es de hoy, retorno la hora
 * @example
 * useSetDate(date)
 */
export function useSetDate(date?: Date) {
  const { dateFormat } = useDateFormat()
  if (date) {
    const today = differenceInCalendarDays(new Date(date), new Date())
    if (today === 0) {
      return `${dateFormat({ date, format: 'h' })}`
    } else {
      return `${dateFormat({ date, format: 'dmy' })}`
    }
  } else {
    return ''
  }
}
