import styled from 'styled-components/macro'

import { UIVars } from 'app/Components/UI'

export const Wrapper = styled.footer`
  background-color: white;
  border-top: 1px solid ${UIVars.setColors.veryLightPinkTwo};
  bottom: 0;
  display: flex;
  height: 104px;
  padding: 0;
  position: absolute;
  width: 100%;

  &.layout {
    &-both {
      justify-content: space-between;
    }

    &-next {
      justify-content: flex-end;
    }

    &-prev {
      justify-content: flex-start;
    }
  }
  @media screen and (max-width: 768px) {
    -webkit-flex-direction: column;
  }
`

export const Button = styled.div`
  transition: background 0.25s;
  width: 50%;

  .Pressed__content {
    height: 100%;
  }

  a {
    display: flex;

    &.prev {
      justify-content: flex-start;
    }

    &.next {
      justify-content: flex-end;
    }

    &:hover {
      color: ${UIVars.setColors.blackTwo};
      background: rgba(47, 47, 47, 0.04);

      .Arrow {
        background: #0074d8;
      }

      .isExercise {
        background: ${UIVars.setColors.veryLightBlue};
      }
    }
  }
  &.disabled {
    a {
      cursor: no-drop;
    }

    div {
      cursor: no-drop;
    }

    .Arrow,
    &:hover .Arrow {
      background: ${UIVars.setColors.veryLightPink};
    }

    .content__text > {
      & span {
        color: ${UIVars.setColors.brownThree};
      }

      & p {
        color: ${UIVars.setColors.brownGreyTwo};
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;

    &.prev {
      border-bottom: 1px solid ${UIVars.setColors.veryLightPinkTwo};
    }

    a {
      &:hover {
        .Arrow.prev {
          transition: background 0s;
          background: transparent;
        }
      }
    }
  }
`
export const Content = styled.div`
  align-items: center;
  display: flex;
  max-width: 350px;
  padding: 15px;

  .content__text > {
    & p {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 0;
    }

    & span {
      color: ${UIVars.setColors.brownThree};
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }

  &.prev svg {
    margin-left: 20px;
  }

  &.next {
    text-align: right;

    svg {
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    &.prev {
      height: 65px;
      padding: 8px 15px;
    }

    &.next {
      height: 93px;
    }
  }
`

export const Arrow = styled.div`
  align-items: center;
  background: ${UIVars.setColors.primary};
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  transition: background 0.25s;
  width: 90px;

  svg {
    margin-right: 0;
  }

  &.prev svg {
    transform: rotate(90deg);
  }

  &.next svg {
    transform: rotate(-90deg);
  }

  &.isExercise {
    background: ${UIVars.setColors.veryLightBlue};

    path {
      fill: #1d96fe;
    }
  }

  @media screen and (max-width: 768px) {
    width: 70px;

    &.prev {
      background: none;
      justify-content: flex-end;
      width: 28px;

      path {
        fill: ${UIVars.setColors.primary};
      }
    }
  }
`
