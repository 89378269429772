import { Box } from '@chakra-ui/react'
import { vars } from '@eclass/ui-kit'

interface props {
  active: number
  label: string
  index: number
}

/**
 * Componente que muestra el nombre del Hito
 */
export const Nav = ({ active, label, index }: props) => {
  const activeColor = vars('colors-icon-deepSkyBlue')

  const isActive = active === index

  return (
    <Box>
      <Box
        alignItems="center"
        color={vars('colors-neutral-darkCharcoal')}
        cursor="pointer"
        display="flex"
        flexDirection="column"
        fontWeight={isActive ? 700 : 400}
        gap="10px"
        key={index}
        position="relative"
        width="100%"
      >
        <Box height="48px" p="0 1rem" textAlign="center" display="flex" alignItems="flex-end">
          {label}
        </Box>
        <Box
          bg={isActive ? activeColor : 'white'}
          borderRadius="50%"
          boxShadow={
            isActive
              ? 'none'
              : `inset 0px 0px 0px 3px ${
                  // Si la activa es mayor a la posicion del slider, el circulo queda azul
                  active > index ? activeColor : vars('colors-neutral-silverSand')
                }`
          }
          h="10px"
          w="10px"
          zIndex={2}
        />
      </Box>
    </Box>
  )
}
