import { Avatar } from 'app/Components/UI'
import { Box } from '@chakra-ui/react'
import { Icon } from 'app/Components/UI/Icons'
import { useTranslation } from 'react-i18next'

import * as Style from '../style'
import { Types, Props } from '../types'
import { Header } from '../Header'
import { Link } from './Components/Link'

/**
 * Caja que muestra la información de coordinador academico del curso
 */
export const Coordinator = ({ data }: Props.Coordinator) => {
  const { t } = useTranslation()

  const links: Types.Link[] = []

  if (data.email) {
    links.push({
      icon: 'letter',
      text: data.email,
      buttonCopy: true,
    })
  }

  if (data.phone) {
    links.push({
      icon: 'phone',
      text: `${t('DashboardCallUs')} ${data.phone}`,
    })
  }

  if (data.whatsapp) {
    const numberLink = data.whatsapp.replace(/\s/g, '')
    links.push({
      href: `http://wa.me/${numberLink}`,
      icon: 'whatsapp',
      text: `WhatsApp ${data.whatsapp}`,
    })
  }

  if (links.length === 0) {
    return null
  }

  return (
    <Box textAlign="center" className="coordinatorBox">
      <Style.Box data-testid="BoxCoordinator">
        <Header
          type="COORDINATOR"
          title={data.title}
          attentionTime={data.attentionTime}
          className="coordinatorHeader"
        />
        {data?.fullName && (
          <Style.CoordinatorInfo className="coordinadorInfo" data-testid="CoordinatorInfo">
            <Avatar fullName={data.fullName} userId={0} picture={data?.photo || ''} size={40} />
            <div className="info">
              <p>
                <strong>{data.fullName}</strong>
              </p>
              <p className="profile">
                <Icon name="coordinator" />
                {t('CrossNameCordinator')}
              </p>
            </div>
          </Style.CoordinatorInfo>
        )}
        <Style.List>
          {links.map((link: Types.Link) => (
            <Link key={link.text} data={link} />
          ))}
        </Style.List>
      </Style.Box>
    </Box>
  )
}
