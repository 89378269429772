import { gql, useMutation, MutationHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const QUESTION_DELETE = gql`
  ${STATUS_FIELDS}
  mutation QuestionDelete($QuestionDeleteInput: QuestionDeleteInput!) {
    QuestionDelete(input: $QuestionDeleteInput) {
      status {
        ...StatusFields
      }
      url
    }
  }
`

export type QuestionDeletePayload = Api.QuestionDeletePayload

export type QuestionDeleteInput = Api.QuestionDeleteInput

interface QuestionDeletePayloadApollo {
  QuestionDelete: QuestionDeletePayload
}

interface QuestionDeleteInputApollo {
  QuestionDeleteInput: QuestionDeleteInput
}

export const useMutationQuestionDelete = (
  options?: MutationHookOptions<QuestionDeletePayloadApollo, QuestionDeleteInputApollo>
) => {
  return useMutation<QuestionDeletePayloadApollo, QuestionDeleteInputApollo>(
    QUESTION_DELETE,
    options
  )
}
