// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { axiosError, getToken } from '../../../Services'

/**
 * Obtiene los datos de la persona
 * @example
 * ```js
 *   const response = await getUser()
 * ```
 * @returns Retorna los datos del Usuario
 */
const getUser = (input) => {
  const requestId = 'getUser'
  return async (dispatch) => {
    getToken()

    const query = `
      query me($MeInput: MeInput) {
        me(input: $MeInput) {
          User {
            id
            Person {
              id
              name
              firstSurname
              lastSurname
              fullName
              email
              birthday
              picture
              gender
              timeZone
            }
          }
          needConfirmation
        }
      }
    `

    const variables = {
      MeInput: input,
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables }, { requestId })
      .then((response) => {
        const { me } = response.data.data
        if (me) {
          const person = {
            id: me.User.id,
            user: me.User.Person,
            needConfirmation: me.needConfirmation,
          }
          dispatch({
            type: 'GET_USER',
            payload: person,
          })
          return {
            status: {
              success: true,
              detail: 'Petición exitosa',
              user: person.user,
              needConfirmation: me.needConfirmation,
            },
          }
        } else {
          return {
            status: {
              success: false,
              detail: 'Ocurrió un error al obtener los datos del usuario.',
            },
          }
        }
      })
      .catch((err) => axiosError(err, requestId, dispatch))
  }
}

export default getUser
