import React from 'react'

export const FaqIcon = () => (
  <svg
    width="45"
    height="45"
    data-testid="FaqIcon"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
    <path
      d="M20 5H13C12.4477 5 12 5.44772 12 6V12C12 12.5523 12.4477 13 13 13H20C20.5523 13 21 12.5523 21 12V6C21 5.44772 20.5523 5 20 5Z"
      fill="#FFBC27"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 9C19 9.55228 18.5523 10 18 10L9 10C8.44772 10 8 9.55228 8 9C8 8.44772 8.44772 8 9 8L18 8C18.5523 8 19 8.44772 19 9Z"
      fill="#08044F"
    />
    <path
      d="M20 15H6C5.44772 15 5 15.4477 5 16V22C5 22.5523 5.44772 23 6 23H20C20.5523 23 21 22.5523 21 22V16C21 15.4477 20.5523 15 20 15Z"
      fill="#0189FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 19C19 19.5523 18.5523 20 18 20H13C12.4477 20 12 19.5523 12 19C12 18.4477 12.4477 18 13 18H18C18.5523 18 19 18.4477 19 19Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 19C10 19.5523 9.55228 20 9 20H8C7.44772 20 7 19.5523 7 19C7 18.4477 7.44772 18 8 18H9C9.55228 18 10 18.4477 10 19Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 44C16.9706 44 21 39.9706 21 35C21 30.0294 16.9706 26 12 26C7.02944 26 3 30.0294 3 35C3 39.9706 7.02944 44 12 44ZM12 40C12.5523 40 13 39.5523 13 39C13 38.4477 12.5523 38 12 38C11.4477 38 11 38.4477 11 39C11 39.5523 11.4477 40 12 40ZM12.0492 32C11.6557 32 11.3607 32.2 11.1639 32.5L10.6721 33.4L9 32.4L9.4918 31.5C9.98361 30.6 10.9672 30 12.0492 30C13.7213 30 15 31.3 15 33C15 34.4 14.1148 35 13.5246 35.4C13.0328 35.7 13.0328 35.8 13.0328 36V37H11.0656V36C11.0656 34.7 11.8525 34.1 12.4426 33.7C12.9344 33.4 13.0328 33.3 13.0328 33C13.0328 32.4 12.6393 32 12.0492 32Z"
      fill="#FF554D"
    />
    <path d="M32 33H41V42H32V33Z" fill="#1EBDAF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.8268 22.6866L28.8244 22.6849L25.4127 20.2062C24.9659 19.8815 24.8664 19.2558 25.191 18.809C25.5157 18.3622 26.141 18.2632 26.5878 18.5878L30 21.0669V9C30 8.44771 30.4477 8 31 8C31.5523 8 32 8.44772 32 9V15H32V17C32 17.5523 32.4477 18 33 18C33.5523 18 34 17.5523 34 17V11L34 10.9954V6C34 5.44772 34.4477 5 35 5C35.5523 5 36 5.44772 36 6V15H36V17C36 17.5523 36.4477 18 37 18C37.5523 18 38 17.5523 38 17V11L38 10.9954V9C38 8.44771 38.4477 8 39 8C39.5523 8 40 8.44772 40 9V15L40 17C40 17.5523 40.4477 18 41 18C41.5523 18 42 17.5523 42 17L42 11C42.5523 11 43 11.4477 43 12V25C43 26.6569 41.6568 28 40 28H33C31.3431 28 30 26.6569 30 25V23.539L28.8268 22.6866ZM31 29.584C29.2341 28.8124 28 27.0503 28 25V24.558L24.2367 21.8239C22.8963 20.85 22.5991 18.9739 23.573 17.6334C24.5469 16.293 26.423 15.9959 27.7634 16.9698L28 17.1416V9C28 7.34315 29.3431 6 31 6C31.3506 6 31.6872 6.06015 32 6.17071V6C32 4.34315 33.3431 3 35 3C36.6568 3 38 4.34315 38 6V6.17071C38.3128 6.06015 38.6494 6 39 6C40.6568 6 42 7.34315 42 9C43.6568 9 45 10.3431 45 12V25C45 27.0503 43.7659 28.8124 42 29.584V41C42 41.5523 41.5523 42 41 42C40.4477 42 40 41.5523 40 41L40 30H33L33 41C33 41.5523 32.5523 42 32 42C31.4477 42 31 41.5523 31 41V29.584ZM31.6033 21C31.6033 20.4477 32.051 20 32.6033 20C35.6408 20 38.1033 22.4624 38.1033 25.5C38.1033 26.0523 37.6556 26.5 37.1033 26.5C36.551 26.5 36.1033 26.0523 36.1033 25.5C36.1033 23.567 34.5363 22 32.6033 22C32.051 22 31.6033 21.5523 31.6033 21Z"
      fill="#08044F"
    />
  </svg>
)
