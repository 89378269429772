import React from 'react'

import * as Apollo from 'app/Apollo'
import { useData } from '../../Utils'
import { useInitTimeZoneInfo } from './useInitTimeZoneInfo'

export const useTimeZoneInfo = ({ toggleModal }) => {
  const [status, setStatus] = React.useState('INIT')

  const { setAlert, setData } = useData()
  const { methods } = useInitTimeZoneInfo()

  const [mutationPersonTimeZone] = Apollo.useMutationPersonTimeZone()

  /**
   * Función que manda datos a la API esperando la respuesta de esta
   *
   * También se encarga de ejecutar la actualización de la store
   */
  const save = async (newParameters) => {
    setStatus('LOADING')

    const { data: dataPersonTimeZone } = await mutationPersonTimeZone({
      variables: {
        PersonTimeZoneInput: {
          timeZone: newParameters.timeZone?.value ?? '',
        },
      },
    })

    if (dataPersonTimeZone?.PersonTimeZone?.status?.success) {
      // Ahora paso a actualizar los datos de la store de redux
      setData({ timeZone: newParameters.timeZone?.value ?? '' })

      setStatus('SUCCESS')
      setAlert('success')
      toggleModal()
    } else {
      setStatus('ERROR')
      setAlert('error')
    }
  }

  /**
   * A esta función se cae presionar guardar en el modal.
   *
   * Cubre el caso de crear y editar
   */
  const onSubmit = (newParameters) => {
    if (Object.keys(methods.errors).length === 0) {
      save(newParameters)
    }
  }

  return {
    status,
    methods,
    onSubmit,
  }
}
