import React from 'react'
import ReactHtmlParser from 'react-html-parser'

export const Content = ({ config }: any) => {
  // si no tiene contenido alternativo no muestro nada
  if (config.has_alternative === 0 || !config.alternative_content) {
    return null
  }
  return (
    <div className="AlternativeContentPDF__content" data-testid="AlternativeContent-Content">
      {ReactHtmlParser(config.alternative_content)}
      <div style={{ clear: 'both' }}></div>
    </div>
  )
}
