import { Box } from '@chakra-ui/react'

import { ArrowBack } from 'app/Components/UI/Icons/Timeline/ArrowBack'
import { ArrowNext } from 'app/Components/UI/Icons/Timeline/ArrowNext'

export type TArrow = 'prev' | 'next'

interface props {
  type?: TArrow
  onClick: any
  className?: string
}

export const Arrow = ({ type = 'prev', onClick, className }: props) => {
  return (
    <Box
      bottom={'-14px'}
      className={`${className} arrow ${type}`}
      cursor="pointer"
      onClick={onClick}
      position="absolute"
      title="arrow"
      zIndex={2}
    >
      {type === 'prev' ? <ArrowBack /> : <ArrowNext />}
    </Box>
  )
}
