import { useEffect } from 'react'

import domainConfig from '../../Config/domains'

/**
 * Cambio el favicon dependiendo de la configuración del dominio
 *
 * El meta del favicon debe tener el siguiente ID para que funcione:
 *
 * id="domainFavicon"
 */
export const useFavicon = () => {
  const handleFavicon = async () => {
    const element = document.getElementById('domainFavicon')
    if (element && domainConfig.faviconUrl) {
      try {
        const url = new URL(domainConfig.faviconUrl)
        element.setAttribute('href', url.href)
      } catch (err) {
        // URL no valida
      }
    }
  }

  useEffect(() => {
    handleFavicon()
  }, [])
}
