import styled, { css } from 'styled-components'

import { UIVars } from 'app/Components/UI'

export const Data = styled.div`
  display: flex;
  margin-top: 30px;
  p {
    font-size: 18px;
  }
  @media screen and (max-width: 990px) {
    flex-direction: column;
  }
`

export const Content = styled.div`
  color: ${UIVars.setColors.black};
  font-size: 18px;
  line-height: 31px;
  width: 100%;
  .Article__image_zoom {
    max-width: 100%;
    margin: 0;
  }
  .Pre_Options {
    margin: 16px 0;
  }
  .Article__quote,
  .Article__outstanding {
    margin: 30px 0;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 26px;
    p {
      font-size: 16px;
    }
  }
`

export const Enunciated = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  position: relative;

  &.correct {
    &,
    > div > span {
      color: ${UIVars.setColors.checkGreen};
    }
  }
  &.error {
    &,
    > div > span {
      color: ${UIVars.setColors.checkError};
    }
  }
  > svg {
    margin: 0 0 16px 0;
  }
`
export const Question = styled.div`
  align-items: flex-start;
  display: flex;

  > span {
    color: ${UIVars.setColors.brownGreyTwo};
    margin-right: 8px;
  }
  .question {
    width: 100%;
  }
  img,
  video,
  audio {
    max-width: 100%;
    opacity: 1 !important;
    width: 100%;
  }
  p {
    margin-bottom: 0;
  }
  ${setMultimediaV8()}

  .exerciseQuestion {
    .Article__audio {
      audio {
        display: block;
      }
    }

    & > * {
      margin-bottom: 32px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

export function setMultimediaV8() {
  return css`
    .Article__image_box,
    .Article__image_break,
    .Article__image_zoom {
      float: inherit !important;
      opacity: 1 !important;
      width: 100%;
      img {
        max-width: 100% !important;
        image-rendering: -webkit-optimize-contrast;
      }
      p {
        display: none;
      }
      &.framed {
        figure img {
          max-width: 100%;
          image-rendering: -webkit-optimize-contrast;
        }
      }
      &.round {
        figure img {
          border-radius: 0;
          image-rendering: -webkit-optimize-contrast;
        }
      }
    }
    .Article__image_box {
      margin-top: 0;
      figure {
        margin-right: 0;
      }
    }
    .Article__video {
      video {
        width: 100%;
      }
    }
    @media screen and (max-width: 900px) {
      .Article__image_box {
        margin-top: 0;
      }
    }
    @media screen and (max-width: 768px) {
      .Article__image_box,
      .Article__image_break {
        margin: 0;
      }
      .Article__image_box {
        figure {
          margin-right: 0;
        }
      }
    }
    @media screen and (max-width: 640px) {
      .Article__image_box {
        figure {
          margin-bottom: 0;
          margin-right: 0;
        }
      }
    }
  `
}
