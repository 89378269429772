// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

// @Component
const NucleoIcon = ({ onClick, icon, color, size = 24, className, background = '' }) => (
  <Wrapper
    className="NucleoIcon"
    onClick={onClick}
    background={background}
    color={color}
    size={size}
  >
    <i className={`nucleo-icon nucleo-icon-${icon} ${className || ''}`} />
  </Wrapper>
)

// @Proptypes
NucleoIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  color: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
}

// @Export Component
export default NucleoIcon

const Wrapper = styled.span`
  background: ${(props) => (props.background ? props.background.color : 'none')};
  border-radius: ${(props) => (props.background ? '50%' : '0')};
  flex-shrink: 0;
  height: ${(props) => (props.background ? `${props.background.size}px` : 'auto')};
  position: relative;
  width: ${(props) => (props.background ? `${props.background.size}px` : 'auto')};

  i {
    color: ${(props) => props.color && props.color};
    font-size: ${(props) => props.size && `${props.size}px`} !important;
    left: ${(props) => (props.background ? '50%' : 0)};
    position: ${(props) => (props.background ? 'absolute' : 'static')};
    top: ${(props) => (props.background ? '50%' : 0)};
    transform: ${(props) => (props.background ? 'translate(-50%, -50%)' : 'none')};
  }
`
