import React from 'react'
import { Input, Label } from 'reactstrap'

import * as Style from './Style'
import './style.scss'
import { ContentParser } from 'app/Components'
import { AnswerType, Types } from '../../types'
import { valueEmpty } from 'app/Views/Tests/helpers/valueEmpty'
import { useViewDownload } from 'app/Views/Resources/PDF'

type Props = {
  option: {
    tag: string
    value: string
  }
  answer: AnswerType
  optionValue: number
  optionDisabled: boolean
  id?: number
  action: (param: Types.ActionType) => void
  evaluationState: number
}
const RadioTest = ({
  option,
  answer,
  optionValue,
  optionDisabled,
  id,
  action,
  evaluationState,
}: Props) => {
  const [checked, setChecked] = React.useState(false)

  const isViewDownload = useViewDownload()

  React.useEffect(() => {
    if (valueEmpty(evaluationState, answer.state!)) {
      if (isNaN(Number(answer.option)) || !answer.state || answer.answer?.length === 0) {
        /**
         * CASO:  answer.answer?.length === 0
         * Es para el feedback de los test con tiempo, que muestra la primera opción seleccionada sin que se haya guardado la respuesta (cierre por tiempo)
         */
        setChecked(false)
      } else {
        setChecked(Number(answer.option) === optionValue)
      }
    }
  }, [answer, evaluationState, optionValue])

  /**
   * Al hacer clic en el radio button disparamos el action para guardar esa respuesta
   */
  const onChange = () => {
    action({
      id: id!,
      option: optionValue,
      type: 'ANSWER',
    })
  }

  // agrego clase checked para marcar la opcion en el pdf
  const isCheckedPDF = isViewDownload && checked ? 'checked' : ''

  return (
    <li className={`QuestionAlternatives ${isCheckedPDF}`} key={optionValue}>
      <Label check>
        <div className="QuestionAlternatives__label">
          <span>{option.tag}</span>
          <Input
            checked={checked}
            disabled={optionDisabled}
            id={`${id}-option${optionValue}`}
            name={`question-${id}`}
            type="radio"
            onChange={onChange}
          />
        </div>
        <Style.Content>
          {ContentParser({
            content: option.value,
            embeds: answer.embeds!,
          })}
        </Style.Content>
      </Label>
    </li>
  )
}

export default RadioTest
