import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { RouteParams } from 'Config/routes/types'
import * as ReduxTypes from 'app/Redux/types'
import { useSelector } from 'react-redux'
import { useGetRankingDetail } from 'app/Views/LeaderBoard/hooks/useRankingDetail'
import { Achievement } from '@eclass/api'

export const useInfoMedals = () => {
  const { courseId, studentId } = useParams<RouteParams>()
  const { getRankingDetail } = useGetRankingDetail('notification')
  const [total, setTotal] = useState(0)
  const [queueMedals, setQueueMedals] = useState<Achievement[]>()
  const {
    courses: { getCourseInfo },
  } = useSelector((store: ReduxTypes.Store) => store)

  const courseRankings = getCourseInfo?.CourseRankings

  const getRankingPendingNotifications = useCallback(async () => {
    if (
      getCourseInfo.Course?.id === Number(courseId) &&
      studentId &&
      courseRankings &&
      courseRankings.length
    ) {
      const { data } = await getRankingDetail({
        variables: {
          RankingDetailInput: {
            studentId: Number(studentId),
            courseId: Number(courseId),
          },
          context: 'ranking',
        },
      })

      if (data?.getRankingDetail.status.success) {
        setTotal(data?.getRankingDetail?.StudentRanking?.totalIndicator ?? 0)
        const medalss = data?.getRankingDetail.Achievements?.filter(
          (item) => item.isReviewed === false && item.isComplete === true
        )
        setQueueMedals(medalss ?? [])
      }
    }
  }, [getCourseInfo])

  useEffect(() => {
    getRankingPendingNotifications()
  }, [])

  return { queueMedals, total }
}
