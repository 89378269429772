import styled from 'styled-components/macro'
import { UIVars } from 'app/Components/UI'

export const WrapperForm = styled.section`
  font-size: 16px;

  .form-checkbox {
    label p {
      font-size: 12px;
    }
  }
`

export const Wrapper = styled(WrapperForm)`
  line-height: 28px;
  margin-bottom: 16px;
`

export const Wrapper5 = styled(WrapperForm)`
  line-height: 28px;
  margin-bottom: 16px;

  &:not(:last-child) {
    border-bottom: 1px solid #e6e6e6;
  }

  .Topic__Title__Box {
    .form-control {
      ::placeholder {
        font-style: normal;
        color: ${UIVars.setColors.brownGreyTwo};
      }
    }
    .CurrentPass {
      height: 120px;
    }
    div {
      label {
        font-size: 16px;
        margin-top: 16px;
        margin-bottom: 5px;
        font: bold 16px 'Roboto';
      }
    }
    .NewPassContainer {
      display: flex;
    }
    .NewPass {
      padding-right: 20px;
      height: 127px;
    }
    .NewPass,
    .AgainPass {
      display: block;
      width: 50%;
      div {
        width: 100%;
      }
      .notMatch {
        font: normal 14px 'Roboto';
      }
    }
    .ModalFooter {
      display: flex;
      width: 100%;
      padding-top: 30px;
    }
  }
  @media screen and (max-width: 480px) {
    .Topic__Title__Box {
      .NewPass,
      .AgainPass {
        padding-right: 0px;
        width: 100%;
      }
      .NewPassContainer {
        display: block;
      }
      .ModalFooter {
        position: relative;
        padding-bottom: 70px;
        width: 100%;
        button:last-child {
          margin: 0;
        }
        button {
          width: 50%;
          .Pressed__content {
            .button__container {
              width: 100%;
            }
          }
        }
      }
    }
    .WrapperDelete {
      position: absolute;
      top: 100px;
    }
  }
  @media screen and (max-width: 365px) {
    .ModalFooter {
      button:first-child {
        width: 60%;
      }
      button:last-child {
        width: 40%;
      }
    }
  }
`
