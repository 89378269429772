import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Store } from 'app/Redux/types'
import { Button } from 'app/Components/UI'
import { Style } from './style'
import { testsStates } from '../../testsStates'

type props = {
  answer: any
  action: any
  evaluationState: number
}

/**
 * Componente que renderiza boton de omitir pregunta si es que viene configurado
 * @param answer {AnswerType}
 * @param action {any}
 * @param evaluationState {number}
 */
export const Skip = ({ answer, action, evaluationState }: props) => {
  const { t } = useTranslation()
  const {
    courses: { getCourseInfo: courseInfo },
  } = useSelector((state: Store) => state)

  /** estados cuando bloqueo el botton */
  const statesBlocked = [testsStates.TERMINATE, testsStates.EVALUATED].includes(evaluationState)

  // estado para bloquear el onClick
  let disabled = false
  if (statesBlocked || answer.loading) {
    disabled = true
  }

  // Envio datos para actualizar estado de la pregunta
  const handleSkip = (isRespond: string) => {
    let option = answer.option
    // si es de tipo texto tomo lo que viene en answer.answer
    if (answer.config?.ItemType.id === 3) {
      option = answer.answer!
    }

    action({
      id: answer.config?.id,
      option,
      skipped: !answer.skipped,
      type: 'ANSWER',
      isRespond: isRespond === 'primary',
    })
  }

  // textos y color del botón
  let text = answer.config?.skipText! || t('ResourceOmit')
  let state = 'info'
  let omitted = ''

  if (answer.skipped) {
    if (statesBlocked) {
      text = t('ResourceOmitted')
      // si estoy debo forzarle los colores al boton ya que queda bloqueado
      if (testsStates.TERMINATE === evaluationState) {
        omitted = 'btn-omitted'
      }
    } else {
      text = t('ResourceAnswer')
      state = 'primary'
    }
  }

  // bloqueo render si no se puede omitir o si estoy en el feedback
  if (
    !answer.config?.canSkip! ||
    evaluationState === testsStates.EVALUATED ||
    !courseInfo.Student?.dates?.canGenerateActivity
  ) {
    return null
  }

  return (
    <Style className={`skip ${omitted}`}>
      <Button
        text={text}
        onClick={() => handleSkip(state)}
        state={state}
        size="xs"
        disabled={disabled}
      />
    </Style>
  )
}
