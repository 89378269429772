export const Objectives = (props) => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
      d="M16 4L13 7L11 6.4L10 7.4C10.6 8.3 11 9.3 11 10.5C11 13.5 8.5 16 5.5 16C2.5 16 0 13.5 0 10.5C0 7.5 2.5 5 5.5 4.9C6.6 4.9 7.7 5.3 8.6 5.9L9.6 4.9L9 3L12 0L13 3L16 4ZM5.5 14C7.4 14 9 12.4 9 10.5C9 9.9 8.8 9.3 8.6 8.8L6.2 11.2C6 11.4 5.7 11.5 5.5 11.5C5.3 11.5 5 11.4 4.8 11.2C4.4 10.8 4.4 10.2 4.8 9.8L7.2 7.4C6.7 7.2 6.1 7 5.5 7C3.6 7 2 8.6 2 10.5C2 12.4 3.6 14 5.5 14Z"
    />
  )
}
