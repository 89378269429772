import styled, { css } from 'styled-components/macro'

import { layout } from '../Common'

const cssIntitution = css`
  .profile {
    &-institutionId {
      height: auto;
      overflow: visible;
    }

    &-otherInstitution {
      height: 0;
      overflow: hidden;
    }
  }

  &.otherInstitution {
    .profile {
      &-institutionId {
        height: 0;
        overflow: hidden;
      }

      &-otherInstitution {
        height: auto;
        overflow: visible;
      }
    }
  }
`

export const Wrapper = styled.div`
  ${layout(
    css`
      .profile-institutionTypeId {
        grid-area: institutionTypeId;
      }

      .profile-countryId {
        grid-area: countryId;
      }

      .profile-institutionId-otherInstitution {
        grid-area: institutionId-otherInstitution;

        ${cssIntitution}
      }

      .profile-studyType {
        grid-area: studyType;
      }

      .profile-career {
        grid-area: career;
      }

      .profile-promotion {
        grid-area: promotion;
      }

      .profile-isPrivate {
        grid-area: isPrivate;
      }

      grid-template-areas: 'studyType .';

      &.profile {
        grid-template-areas:
          'institutionTypeId institutionTypeId'
          'countryId countryId'
          'institutionId-otherInstitution institutionId-otherInstitution'
          'studyType studyType'
          'career promotion'
          'isPrivate .';
      }
    `,
    css`
      .profile-institutionId-otherInstitution {
        ${cssIntitution}
      }
    `
  )}
`
