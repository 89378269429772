import { useState, useEffect } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { useTranslation } from 'react-i18next'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { useToolbar } from './Toolbar/'
import { Wrapper } from './Style'
import { Props } from './types'
import useTranslationEditor from './useTranslationEditor'

/**
 * Limpia un string en formato html y retorna la cantidad de palabras.
 *
 * @param {string} text - texto html.
 */
export const filterString = (text: string): number => {
  const textoLimpio = text
    .replace(/<[^>]*>/g, '')
    .replace(/&nbsp;/g, '')
    .replace(/\s/g, '')
  return textoLimpio.length
}

/**
 * Componente con editor de texto.
 */
export const TextEditor = ({
  value,
  setValue,
  setHasContent,
  onBlur,
  onFocus,
  readOnly,
  loading,
  placeholder,
  showCounter = false,
  toolbar,
  setAttached,
  attachTotal,
  words,
}: Props.TextEditor) => {
  const { t } = useTranslation()
  const translations = useTranslationEditor()
  const [characterCount, setCharacterCount] = useState(0)
  const toolbarConfig = useToolbar(toolbar, setAttached!, attachTotal!)
  const onEditorStateChange = (editorState: EditorState) => {
    setValue(editorState)
    if (editorState?.getCurrentContent && draftToHtml && convertToRaw) {
      const content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      setCharacterCount(filterString(content))

      if (setHasContent) {
        setHasContent(filterString(content))
      }
    }
  }

  const handleOnBlur = () => {
    if (characterCount === 0) {
      setValue(EditorState.createEmpty())
    }
    onBlur && onBlur()
  }

  useEffect(() => {
    // Para resetear el contador de caracteres cuando se limpia desde fuera del editor
    if (showCounter && words === 0) {
      if (characterCount !== words) {
        setCharacterCount(0)
      }
    }
  }, [words])

  return (
    <Wrapper className="TextEditor">
      <Editor
        placeholder={placeholder}
        onBlur={handleOnBlur}
        onFocus={onFocus}
        readOnly={readOnly}
        wrapperClassName="wrapper-class"
        editorClassName={`editor-class ${characterCount > 400 ? 'tall' : ''}`}
        toolbarClassName="toolbar-class"
        toolbar={toolbarConfig.default}
        editorState={value}
        onEditorStateChange={onEditorStateChange}
        localization={{
          translations,
        }}
        toolbarCustomButtons={toolbarConfig.custom}
      />
      {loading || showCounter ? (
        <span className="editor-info">
          {loading ? t('CrossSaving') : showCounter && characterCount}
        </span>
      ) : (
        <></>
      )}
    </Wrapper>
  )
}
