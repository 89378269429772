import { useRef, FC } from 'react'
import {
  Box,
  Button,
  useDisclosure,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useMediaQuery,
} from '@chakra-ui/react'
import { vars, BtnSecondary, NewTooltip } from '@eclass/ui-kit'

import {
  Back,
  Download,
  Time,
  Volumen,
  Forward,
  Speed,
  PlayPause,
  Range,
  IconAudio,
} from './components'
import { useLogicProgress } from './useLogicProgress'
import { analytic } from 'app/Services'

export interface IPodcast {
  url: string
}

export type TAudio = HTMLAudioElement | null
export interface IAudio {
  audio: TAudio
}

export const Podcast = ({ url }: IPodcast) => {
  const { isOpen, onToggle, onClose } = useDisclosure()

  const audioRef = useRef<TAudio>(null)
  const { isPlaying, duration, time, handleLoadedMetadata } = useLogicProgress(audioRef.current)

  if (!url || url.length === 0) {
    return <></>
  }

  /** Parche al problema del que el tooltip queda oculto cuando el nav esta stiky */
  const onMouseEnter = () => {
    // le doy un tiempo para que se agregue en el dom antes de modificarlo
    setTimeout(() => {
      const podcastTooltip = document.querySelector('.podcastTooltip') as HTMLDivElement
      if (podcastTooltip) {
        const parentPodcastTooltip = podcastTooltip.parentElement as HTMLDivElement
        parentPodcastTooltip.style.zIndex = '999'
      }
    }, 10)
  }

  const onClick = () => {
    onToggle()
    analytic.customEvent({
      category: 'Podcast',
      action: 'Open Button',
    })
  }

  const countdown = duration - time

  return (
    <>
      <ResetCss>
        <audio controls onLoadedMetadata={handleLoadedMetadata} ref={audioRef} src={url}></audio>
        <Popover placement="bottom" isOpen={isOpen} onClose={onClose}>
          <PopoverTrigger>
            <Button className={isOpen ? 'buttonISOpen' : ''} onMouseEnter={onMouseEnter}>
              <Tooltip>
                <BtnSecondary
                  leftIcon={<IconAudio />}
                  size="small"
                  onClick={onClick}
                  id="podcastButton"
                >
                  <Time seconds={countdown} />
                </BtnSecondary>
              </Tooltip>
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverBody>
              <Box>
                <Box as="section">
                  <Box className="podcast-header">
                    <Box display="flex" gap="1rem" alignItems="center">
                      <Time seconds={time} />
                      <Range
                        audio={audioRef.current}
                        duration={duration}
                        time={time}
                        isPlaying={isPlaying.value}
                      />
                      <Time seconds={countdown} isNegative />
                    </Box>
                    <Download audio={audioRef.current} />
                  </Box>
                  <Box className="podcast-actions">
                    <Volumen audio={audioRef.current} />
                    <Box display="flex" gap="1rem" alignItems="center">
                      <Back audio={audioRef.current} />
                      <PlayPause
                        audio={audioRef.current}
                        time={countdown}
                        isPlaying={isPlaying}
                        duration={duration}
                      />
                      <Forward audio={audioRef.current} />
                    </Box>
                    <Speed audio={audioRef.current} />
                  </Box>
                </Box>
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </ResetCss>
    </>
  )
}

const Tooltip: FC = ({ children }) => {
  const [isNotSmartphone] = useMediaQuery('(min-width: 720px)')
  if (!isNotSmartphone) {
    return <>{children}</>
  }
  return (
    <NewTooltip label="Escuchar Material" className="podcastTooltip">
      {children}
    </NewTooltip>
  )
}

const ResetCss: FC = ({ children }) => {
  return (
    <Box
      position="relative"
      sx={{
        '>button': {
          h: 'auto',
          p: 0,
          '&:focus': {
            boxShadow: 'none',
          },
          '&:hover': {
            bg: 'transparent',
          },
        },
        '.chakra-popover__popper': {
          borderWidth: 0,
          boxShadow: vars('shadows-md'),
          zIndex: 99999,
        },
        '.buttonISOpen': {
          '&:after': {
            content: '""',
            background: vars('colors-neutral-davysGrey'),
            height: ' 10px',
            left: '50%',
            position: 'absolute',
            bottom: '-13px',
            transform: 'translateX(-50%) rotate(45deg)',
            width: '10px',
            zIndex: 1,
          },
        },
        '.chakra-popover__content': {
          border: 'none',
          borderRadius: 0,
          boxShadow: 'none',
          overflow: 'hidden',
          '&:focus': {
            boxShadow: 'none',
          },
        },
        '.chakra-popover__popper, .chakra-popover__content': {
          w: '100vw',
          '@media screen and (min-width: 640px)': {
            w: '372px',
          },
        },
        '.chakra-popover__body': {
          p: 0,
        },
        '.podcast-header, .podcast-actions': {
          alignItems: 'center',
          display: 'flex',
          gap: '2rem',
          justifyContent: 'center',
          p: '1rem',
          bg: vars('colors-neutral-davysGrey'),
          color: vars('colors-neutral-white'),
        },
        '.podcast-actions': {
          bg: vars('colors-neutral-white'),
        },
        '@media screen and (min-width: 640px)': {
          '.chakra-popover__popper,.chakra-popover__content': {
            borderRadius: '1rem',
          },
          '.podcast-header, .podcast-actions': {
            gap: '1rem',
            justifyContent: 'space-between',
          },
        },
        audio: {
          display: 'none',
        },
      }}
    >
      {children}
    </Box>
  )
}
