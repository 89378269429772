import React from 'react'

export const FormIcon = () => {
  return (
    <svg
      width="45"
      height="45"
      role="FormIcon"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
      <path
        d="M27 26H23C22.4477 26 22 26.4477 22 27V29C22 29.5523 22.4477 30 23 30H27C27.5523 30 28 29.5523 28 29V27C28 26.4477 27.5523 26 27 26Z"
        fill="#FFBC27"
      />
      <path
        d="M30 11H24C23.4477 11 23 11.4477 23 12V13C23 13.5523 23.4477 14 24 14H30C30.5523 14 31 13.5523 31 13V12C31 11.4477 30.5523 11 30 11Z"
        fill="#1EBDAF"
      />
      <path
        d="M31 20H23C22.4477 20 22 20.4477 22 21V23C22 23.5523 22.4477 24 23 24H31C31.5523 24 32 23.5523 32 23V21C32 20.4477 31.5523 20 31 20Z"
        fill="#FF554D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 7H14L14 33H34V7ZM14 5C12.8954 5 12 5.89543 12 7V33C12 34.1046 12.8954 35 14 35H34C35.1046 35 36 34.1046 36 33V7C36 5.89543 35.1046 5 34 5H14Z"
        fill="#08044F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 11V16H30V11H24ZM23 9C22.4477 9 22 9.44772 22 10V17C22 17.5523 22.4477 18 23 18H31C31.5523 18 32 17.5523 32 17V10C32 9.44772 31.5523 9 31 9H23Z"
        fill="#08044F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 22L17 22C16.4477 22 16 21.5523 16 21C16 20.4477 16.4477 20 17 20L19 20C19.5523 20 20 20.4477 20 21C20 21.5523 19.5523 22 19 22Z"
        fill="#08044F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 10C16 9.44772 16.4477 9 17 9L19 9C19.5523 9 20 9.44772 20 10C20 10.5523 19.5523 11 19 11L17 11C16.4477 11 16 10.5523 16 10Z"
        fill="#08044F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 27C16 26.4477 16.4477 26 17 26L19 26C19.5523 26 20 26.4477 20 27C20 27.5523 19.5523 28 19 28L17 28C16.4477 28 16 27.5523 16 27Z"
        fill="#08044F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 13C7.55228 13 8 13.4477 8 14V38C8 38.5523 8.44772 39 9 39H27C27.5523 39 28 39.4477 28 40C28 40.5523 27.5523 41 27 41H9C7.34315 41 6 39.6569 6 38V14C6 13.4477 6.44772 13 7 13Z"
        fill="#08044F"
      />
    </svg>
  )
}
