import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import * as Types from 'app/Redux/types'
import { RouteParams } from 'Config/routes/types'
import { NewDropProps } from './Types'

interface IUseGetStar {
  items: NewDropProps['items']
  activeNavId: number
}
export const useGetStar = ({ items, activeNavId }: IUseGetStar) => {
  const { setScore } = useSelector((store: Types.Store) => store.exercises)
  const params = useParams<RouteParams>()

  const [max, setMax] = useState(setScore.max)
  const [obtained, setObtained] = useState(setScore.obtained)

  const starCounting = () => {
    if (activeNavId >= 0 && items && items.length > 0) {
      let accumulatorObtained = 0
      let total = 0

      // Busco las estrellas de cada set
      items.forEach((element) => {
        if (element.indicators && element.indicators.length > 0) {
          // Si se muestran todos los set en el dropdown, cuento el todal de todo
          const isUnit = activeNavId === 0

          // Si solo muestro un set, cuento su total de estrellas
          const isSet = element.id === activeNavId

          if (isUnit || isSet) {
            element.indicators.forEach((ep) => {
              if (ep.image === 'estrellas') {
                accumulatorObtained += ep.obtained ?? 0
                total += ep.max ?? 0
              }
            })
          }
        }
      })

      if (max !== total) {
        setMax(total)
      }
      if (obtained !== accumulatorObtained) {
        if (activeNavId === Number(params.resourceId)) {
          // Este caso es para cuando rehaga el ejercicio (rehacer o borrar incorrectas), le asigno lo que esta en redux
          setObtained(setScore.obtained)
        } else {
          setObtained(accumulatorObtained)
        }
      }
    }
  }

  useEffect(() => {
    starCounting()
  }, [activeNavId, setScore.obtained])

  return { max, obtained }
}
