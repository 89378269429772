export const ArrowIn = (props) => {
  return (
    <g {...props}>
      <g id="c-info-(5)" fillRule="nonzero">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.38928 7.79113C8.38928 8.00606 8.3162 8.22006 8.16913 8.39423L2.09439 15.5823L0.706787 14.3761L6.27271 7.79113L0.706788 1.20618L2.09439 -7.56874e-06L8.16913 7.18804C8.3162 7.3622 8.38928 7.57621 8.38928 7.79113Z"
          fill="#60798E"
        />
      </g>
    </g>
  )
}
