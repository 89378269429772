// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { axiosError, getToken } from '../../../Services'
import { ConfigType } from '../../../../Config/domains/types'

/**
 * Obtiene el centro de ayuda para el footer.
 *
 * @example
 * ```
 *   await getCampusHelpCenter(this.props.config)
 * ```
 */
const getCampusHelpCenter = ({ programId }: ConfigType) => {
  const requestId = 'CampusHelpCenter'
  return async (dispatch: any) => {
    getToken()
    const query = `
      query getCampusHelpCenter($CampusHelpCenterInput: CampusHelpCenterInput!) {
        getCampusHelpCenter(input: $CampusHelpCenterInput) {
          FooterBoxes {
            type
            title
            attentionTime
            content
            photo
            fullName
            email
            phone
            whatsapp
            href
            links {
              name
              href
              status {
                enabled
              }
              type {
                id
                name
              }
            }
          }
          status {
            code
            name
            detail
            success
          }
        }
      }

    `
    const variables = {
      CampusHelpCenterInput: {
        programId,
      },
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response: any) => {
        dispatch({
          type: 'SET_HELP_CENTER',
          payload: response.data.data.getCampusHelpCenter,
        })
        return response.data.data.getCampusHelpCenter
      })
      .catch((err: any) => {
        axiosError(err, requestId, dispatch)
      })
  }
}
export default getCampusHelpCenter
