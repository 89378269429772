import { EvaluationCreatePayload } from '@eclass/api'
import scrollmove from '@eclass/scrollmove'

import { testsStates } from '../../testsStates'

// Constants to use
const FIRST_ANSWER = 0

/**
 * Cambia de estado la evaluación a "Revisando". Con este evento se bloquean las respuestas y todo queda en modo "lectura" solamente.
 */
const review = (test: EvaluationCreatePayload, levelId: number, stegeId: number) => {
  // @ts-ignore
  const answers = test.Evaluation?.Answers!

  if (answers[0].config?.ItemType?.id === 24) {
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
  } else {
    scrollmove(`#question-${answers[FIRST_ANSWER]?.config.id}`)
  }

  test.Evaluation!.config.state = testsStates.TERMINATE
  // @ts-ignore
  test.Evaluation!.Levels[levelId].Stages![stegeId].state = testsStates.TERMINATE

  return { ...test }
}

export default review
