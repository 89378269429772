import { Box } from '@chakra-ui/react'
import {
  BlastOff,
  StarMaster,
  TrailBlazer,
  StarLegend,
  RulerOfTheGalaxy,
  QuizMenace,
  QuizMonarch,
  SpeakingAce,
  SpeakingPro,
} from './PngMedals'
import { MedalProps } from './types'

export const Medal = ({ size, obtained = true, name }: MedalProps) => {
  const medalImage = (nameMedal) => {
    if (nameMedal) {
      switch (nameMedal) {
        case 'Blast off':
          return BlastOff
        case 'Star Master':
          return StarMaster
        case 'Trailblazer':
          return TrailBlazer
        case 'Star Legend':
          return StarLegend
        case 'Ruler of the Galaxy':
          return RulerOfTheGalaxy
        case 'Quiz Menace':
          return QuizMenace
        case 'Quiz Monarch':
          return QuizMonarch
        case 'Speaking Ace':
          return SpeakingAce
        case 'Speaking Pro':
          return SpeakingPro
        default:
          break
      }
    }
  }

  return (
    <Box
      sx={{
        img: {
          filter: obtained ? 'none' : 'grayscale(100%)',
          opacity: obtained ? 'none' : '0.5',
        },
      }}
    >
      <img
        src={medalImage(name)}
        width={size}
        height={size}
        data-testid="BlastOffMedal"
        alt="BlastOffMedal"
      />
    </Box>
  )
}
