import styled from 'styled-components/macro'
import variables from '../variables'

export const Wrapper = styled.div`
  font-family: ${variables.fontRoboto};
  height: 120px;

  label {
    font-size: 16px;
    font-weight: bold;
  }
  input {
    height: 50px;
    width: -webkit-fill-available;
    border: ${variables.borderSolid};

    &[disabled] {
      cursor: no-drop;
      color: ${variables.setColors.brownGreyTwo};
      border: 1px solid hsl(0, 0%, 90%);
    }
    ::placeholder {
      font-style: normal;
      font-size: 1rem;
      color: ${variables.setColors.brownGreyTwo};
    }
  }
  .form-control {
    color: ${variables.setColors.black};
  }
  .form-control:focus {
    background-color: #fff;
    border: solid 2px ${variables.setColors.deepSkyBlue};
    outline: 0;
    color: ${variables.setColors.black};
    box-shadow: none;
  }
  .valid_feedback {
    color: ${variables.setColors.jadeGreen};
    display: flex;
    margin-top: 4px;
    align-items: center;
    font-size: 12px;
    svg {
      margin-right: 10px;
    }
  }
  .invalid_feedback {
    color: ${variables.setColors.red};
    display: flex;
    margin-top: 4px;
    align-items: center;
    font-size: 12px;
    svg {
      margin-right: 10px;
    }
  }
  .borderGreen {
    border: solid 2px ${variables.setColors.jadeGreen};
  }
  .borderGreen:focus {
    border: solid 2px ${variables.setColors.jadeGreen};
  }
  .borderRed {
    border: solid 2px ${variables.setColors.red};
  }
  .borderRed:focus {
    border: solid 2px ${variables.setColors.red};
  }
  .gray {
    color: ${variables.setColors.brownGreyTwo};
  }
`
