import styled from 'styled-components/macro'
import UIVars from '../variables'

export const Test = styled.div`
  .Question__Status {
    float: right;
    border-radius: 0 3px 3px 0;
    text-align: center;
    font-size: 12px;
    font-weight: normal;
    line-height: initial;
    margin-left: 20px;
    padding: 3px 10px 3px 2px;
    text-transform: none;
    height: 20px;
    width: 90px;
    position: relative;

    .Triangle {
      border-style: solid;
      border-width: 10px 10px 10px 0;
      display: inline-block;
      height: 100%;
      left: -10px;
      position: absolute;
      top: 0;
      width: 0;
    }
  }
  .dropdown-item {
    &.Pending {
      .Question__Status {
        background-color: ${UIVars.setColors.whiteTwo};
        color: ${UIVars.setColors.black};
        .Triangle {
          border-color: transparent ${UIVars.setColors.whiteTwo} transparent transparent;
        }
      }
    }
    &.Answered {
      .Question__Status {
        background-color: ${UIVars.setColors.completed};
        color: ${UIVars.setColors.whiteThree};
        .Triangle {
          border-color: transparent ${UIVars.setColors.completed} transparent transparent;
        }
      }
    }
    &.Omitted {
      .Question__Status {
        background-color: ${UIVars.setColors.icons};
        color: ${UIVars.setColors.black};
        .Triangle {
          border-color: transparent ${UIVars.setColors.icons} transparent transparent;
        }
      }
    }
  }
`

export const Feedback = styled.div`
  .status {
    font-weight: bold;
    min-width: 90px;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .limitQuestion {
    font-weight: bold;
    margin-left: 142px;
  }
`
export const AvatarLogout = styled.div`
  text-align: center;
  .dropdown-item {
    border-bottom: none;
  }
`

export const ItemName = styled.div`
  text-transform: uppercase;
  color: ${UIVars.setColors.brownGreyTwo};
  font-weight: 500;
  font-size: 12px;

  svg {
    margin-right: 10px;
  }
`

export const Logout = styled.div`
  color: ${UIVars.setColors.deepSkyBlue};
  padding-top: 4px;
  font-weight: 500;
  padding-bottom: 4px;
`
export const Resource = styled.div`
  .dropdown-item {
    &:hover {
      background: rgba(47, 47, 47, 0.04) !important;
    }
  }

  .cursor {
    &:hover {
      color: ${UIVars.setColors.brownGreyTwo};
    }
  }

  .resourceState {
    margin-right: 15px;
    width: 16px;
    font-size: 16px;
  }

  .resourceTitle {
    color: ${UIVars.setColors.black};
    font-size: 14px;

    &.on {
      color: ${UIVars.setColors.deepSkyBlue};
    }
  }
`

type ItemDropProps = {
  total: number
  type: string
}

export const Navigation = styled.div<ItemDropProps>`
  background-color: ${UIVars.setColors.whiteThree};

  &.featured {
    background-color: rgba(247, 140, 16, 0.2);
    &:hover {
      background: rgba(247, 140, 16, 0.2) !important;
    }
  }

  padding: 8px 20px;
  min-height: 59px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${UIVars.setColors.whiteFour};
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background: rgba(47, 47, 47, 0.04) !important;
  }
  &:active,
  &:focus,
  &:hover {
    outline: none;
    background-color: ${UIVars.setColors.whiteThree};
    color: ${UIVars.setColors.black};
  }

  .menu_container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .arrow {
      svg {
        margin-right: 0px;
      }
    }
  }

  .exercise_title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 310px;
    align-items: center;

    &.max-width {
      max-width: 250px;
    }

    span {
      span {
        line-height: 0px !important;
      }
    }

    .Classes__Indicators {
      .Indicator {
        margin-bottom: 0px;
        .Indicator__Name {
          font-size: 12px !important;
        }
      }
    }

    .title_overflow {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      line-height: unset;

      span {
        text-overflow: ellipsis;
      }
    }

    .arrow {
      svg {
        margin-right: -7px;
      }
    }
  }

  .resourceState {
    text-align: center;
    margin-right: 17px;
    width: 20px;
    font-size: 16px;
    color: ${UIVars.setColors.brownGreyTwo};

    svg {
      margin-right: 0px;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 8px 15px;
    .exercise_title {
      max-width: 350px;
      &.max-width {
        max-width: 180px !important;
      }
    }

    .title_overflow {
      &.children-max-width {
        max-width: 170px;
      }
    }

    .dropdown-item {
      padding: 15px;
      &:last-child {
        border-bottom: 1px solid ${UIVars.setColors.whiteFour};
      }
    }
  }

  @media screen and (max-width: 375px) {
    .exercise_title {
      max-width: 290px;
      &.max-width {
        max-width: 230px !important;
      }
    }

    .title_overflow {
      &.children-max-width {
        max-width: inherit;
      }
    }
  }

  @media screen and (max-width: 320px) {
    .exercise_title {
      max-width: 250px;
      &.max-width {
        max-width: 170px !important;
      }
    }
  }
`
