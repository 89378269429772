import React from 'react'
import { UIVars } from 'app/Components/UI'
import { BaseUIProps } from './helpers/types'
import getIcon from './helpers/getIcon'
import styled from 'styled-components/macro'

export const TinySideFlash = ({
  children = 'Texto para mensaje flash',
  type = 'tinyInfo',
}: BaseUIProps) => {
  return (
    <Wrapper>
      <div id="alert" className={`alert ${type}`}>
        <img className="image" src={getIcon(type)} alt=" " />
        <div className="message">{children}</div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: right;
  padding-right: 20px;

  .alert {
    align-items: center;
    border-radius: 10px;
    display: flex;
    padding: 8px;
    .image {
      height: 17px;
      margin-right: 16px;
    }
    .message {
      flex-grow: 2;
      font-size: 14px;
      line-height: 1.29;
      margin-right: 8px;
    }
    &.success {
      background-color: ${UIVars.setColors.success};
    }
    &.info,
    &.tinyInfo {
      background-color: ${UIVars.setColors.info};
    }
    &.warning {
      background-color: ${UIVars.setColors.warning};
    }
    &.error {
      background-color: ${UIVars.setColors.danger};
    }
  }

  @media (max-width: 1440px) {
    .alert {
      &.tinyInfo {
        margin-left: 300px;
      }
    }
  }

  @media (max-width: 1024px) {
    .alert {
      &.tinyInfo {
        margin-left: 165px;
      }
    }
  }

  @media (max-width: 768px) {
    .alert {
      &.tinyInfo {
        margin-left: 110px;
      }
    }
  }

  @media (max-width: 425px) {
    .alert {
      .message {
        margin-right: 0;
      }
      &.tinyInfo {
        margin-left: 15px;
      }
    }
  }
`
