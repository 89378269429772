import { vars } from '@eclass/ui-kit'

export const PartialCheck = (props) => {
  return (
    <g {...props} fillRule="nonzero">
      <rect width="16" height="16" fill={vars('colors-alert-orangeyYelow')} rx="8.5" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M7.125 10.531l5.385-5.385 1.097 1.097-6.364 6.364L3 8.364l.98-.98z"
      />
    </g>
  )
}
