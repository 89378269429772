import { gql, useMutation, MutationHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const QUESTION_ANSWER = gql`
  ${STATUS_FIELDS}
  mutation QuestionAnswer($QuestionAnswerInput: QuestionAnswerInput!) {
    QuestionAnswer(input: $QuestionAnswerInput) {
      status {
        ...StatusFields
      }
      questionAnswer {
        id
        text
        created
        parentId
        viewedAt
        Childrens {
          id
          text
          created
          viewedAt
          Student {
            id
            profileId
            Person {
              fullName
              picture
              id
            }
          }
        }
        Student {
          id
          profileId
          Person {
            fullName
            picture
            id
          }
        }
      }
    }
  }
`

export type QuestionAnswerPayload = Api.QuestionAnswerPayload

export type QuestionAnswerInput = Api.QuestionAnswerInput

export interface QuestionAnswerPayloadApollo {
  QuestionAnswer: QuestionAnswerPayload
}

export interface QuestionAnswerInputApollo {
  QuestionAnswerInput: QuestionAnswerInput
}

export const useMutationQuestionAnswer = (
  options?: MutationHookOptions<QuestionAnswerPayloadApollo, QuestionAnswerInputApollo>
) => {
  return useMutation<QuestionAnswerPayloadApollo, QuestionAnswerInputApollo>(
    QUESTION_ANSWER,
    options
  )
}
