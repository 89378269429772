import { DropdownItem } from 'reactstrap'
import { Link } from '@eclass/api'

import UIVars from '../variables'
import * as Style from './Style'
import { Icon } from '../../../Components/UI/Icons'
import { useGetStar } from './useGetStar'

const HeaderDrop = ({
  dropdownType,
  userInfo,
  headerTitle,
  activeNavigation,
  setActive,
  items,
  activeNavId,
  setNavId,
  stars,
}: any) => {
  const { max, obtained } = useGetStar({ items, activeNavId })

  // activo o desactivo la navegación y reseteo su id para mostrar el titulo por defecto
  const navigate = () => {
    setActive(false)
    setNavId(0)
  }

  // obtengo el titulo del header al interior del dropdown según el caso
  const getTitleNav = () => {
    const name = userInfo?.name ? userInfo.name : ''
    const firstSurname = userInfo?.firstSurname ? userInfo.firstSurname : ''
    switch (dropdownType) {
      case 'avatar':
        return `${name} ${firstSurname}`
      case 'exercise':
      case 'navigation':
        if (activeNavId) {
          const active: Link = items.find((item: Link) => item.id === activeNavId)!

          if (active?.Childrens) {
            return active?.Childrens.length > 0 && activeNavId ? active.name : headerTitle
          } else {
            return headerTitle
          }
        } else {
          return headerTitle
        }
      default:
        return headerTitle
    }
  }

  return (
    <Style.HeaderDrop onClick={navigate}>
      <DropdownItem header aria-label={headerTitle}>
        <div className="title_container" aria-hidden>
          <div className={`${stars && stars.max > 0 ? null : 'no_stars'} title_back`}>
            {activeNavigation && (dropdownType === 'navigation' || dropdownType === 'exercise') && (
              <Icon name="back" fill={UIVars.setColors.white} />
            )}
            <div className={`title`}>{getTitleNav()}</div>
          </div>
          {dropdownType === 'exercise' && max && max > 0 ? (
            <div className="stars">
              <span>
                {obtained ?? 0}/{max}
              </span>
              <Icon fill={UIVars.setColors.orangeyYellow} name="stars" />
            </div>
          ) : null}
        </div>
      </DropdownItem>
    </Style.HeaderDrop>
  )
}

export default HeaderDrop
