import React from 'react'
import { useTranslation } from 'react-i18next'
import { Score } from '@eclass/api'

import { getQuestionStatus } from '../../helpers/questionStatus'
import { testsStates } from '../../testsStates'
import { Wrapper } from './Style'

type Props = {
  status?: number
  position: number
  score?: Score
  evaluationState: number
}

export const SideInfo = ({
  status,
  position,
  score = { total: 0, obtained: 0 },
  evaluationState,
}: Props) => {
  const { t } = useTranslation()
  const questionStatus = getQuestionStatus(t, status)
  const classEvaluationFeedback = evaluationState === testsStates.EVALUATED ? 'Feedback' : ''

  const total = score.total
  const obtained = score.obtained
  const puntos = obtained < total && obtained > 0 ? 'PartiallyCorrect' : ''

  return (
    <Wrapper className="Question__Info">
      <div
        className={`Question__Number ${questionStatus.className} ${classEvaluationFeedback} ${puntos}`}
      >
        <div className="Number">{position}</div>
      </div>
      {score.total > 0 &&
        (evaluationState === testsStates.EVALUATED ? (
          <div className="Question__Value">
            {t('ResourcePointOf', {
              count: total,
              obtained: obtained ?? 0,
              total,
            })}
          </div>
        ) : (
          <div className="Question__Value">
            {t('ResourcePoint', {
              count: total,
            })}
          </div>
        ))}
    </Wrapper>
  )
}
