import styled from 'styled-components/macro'

import { UIVars } from 'app/Components/UI'

export const Wrapper = styled.div`
  cursor: pointer;
  background-color: ${UIVars.setColors.whiteTwo};
  border-radius: 10px;
  border: 1px dotted ${UIVars.setColors.veryLightPink};
  display: flex;
  justify-content: center;
  padding: 16px;
  font-size: 16px;
  margin-bottom: 30px;

  &:hover,
  &.on-drop {
    background-color: ${UIVars.setColors.veryLightBlue};
  }

  &.disabled {
    cursor: default;
  }

  .Placeholder {
    .FileZone__Button {
      background-color: ${UIVars.setColors.deepSkyBlue};
      border-radius: 4px;
      color: ${UIVars.setColors.whiteThree};
      font-weight: bold;
      padding: 8px 16px;
      margin-left: 16px;
    }
  }

  &.isMobile {
    .Placeholder {
      display: flex;
      flex-direction: column;
      align-items: center;
      .FileZone__Button {
        margin-left: 0px;
      }
    }
  }

  &.Loaded {
    background-color: ${UIVars.setColors.whiteTwo};
    cursor: default;
    .Placeholder {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .file-info {
        display: flex;
        max-width: 80%;
        .filename {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 100%;
        }
        .extension {
          text-transform: uppercase;
          color: ${UIVars.setColors.brownThree};
        }
      }
      .trash {
        background-color: ${UIVars.setColors.red};
        border-radius: 4px;
        border: none;
        margin-left: 8px;
        padding: 2px 10px;
        svg {
          margin-bottom: 3px;
          margin-right: 0;
        }
      }
    }
  }
`
