import { useLocation } from 'react-router-dom'

/**
 * Valido si estoy en la ruta descarga de pdf
 * @example
 * const isViewDownload = useViewDownload()
 */
export const useViewDownload = () => {
  const { pathname } = useLocation()
  return pathname.includes('resource/pdf')
}
