import { useData } from '../../Utils'

import * as Types from './types'

/**
 * Funciones que permiten actualizar datos en redux del usuario.
 *
 * Estas están adaptadas para los campos de State.loggedUser.user: phoneInfo | address | academicTrainingInfo | jobInfo
 * @example
 * const { addList, updateList, deleteList } = useUpdateRedux()
 */
export const useUpdateRedux = (field: Types.ReduxFields) => {
  const { setData } = useData()

  /**
   * creo array con la data que esta en redux,
   * y le paso el nuevo item
   */
  const addList = ({ list, newItem }: Types.updateRedux) => {
    const newArray = list
    newArray.push(newItem)
    if (newArray) {
      setData({ [field]: newArray })
    }
  }

  /**
   * recorro array con data de redux, y actualizo valor del item editado
   */
  const updateList = ({ list, newItem }: Types.updateRedux) => {
    const newList = list.map((item) => {
      if (newItem.id === item.id) {
        item = newItem
      }
      return item
    })
    setData({ [field]: newList })
  }

  /**
   * borro de array de redux el item seleccionado
   */
  const deleteList = ({ list, newItem }: Types.updateRedux) => {
    const newList = list.filter((item) => item.id !== newItem.id)
    setData({ [field]: newList })
  }

  return {
    addList,
    updateList,
    deleteList,
  }
}
