import { Text, HStack } from '@chakra-ui/react'

import { Arrow } from './Arrow'

interface props {
  active: number
  total: number
  next: () => void
  prev: () => void
}
export const Nav = ({ active, total, next, prev }: props) => {
  return (
    <HStack
      as="footer"
      spacing="2.875rem"
      justify="center"
      mt="1rem"
      p="0"
      data-testid="slideshowNav"
    >
      <Arrow onClick={() => prev()} />
      <Text fontWeight="700" m="0">
        {active + 1} / {total}
      </Text>
      <Arrow onClick={() => next()} next />
    </HStack>
  )
}
