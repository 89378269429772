import { gql, useMutation, MutationHookOptions } from '@apollo/client'
// import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const WorkStudentPrivacy = gql`
  ${STATUS_FIELDS}
  mutation WorkStudentPrivacy($WorkStudentPrivacyInput: WorkStudentPrivacyInput!) {
    WorkStudentPrivacy(input: $WorkStudentPrivacyInput) {
      status {
        ...StatusFields
      }
    }
  }
`

export type WorkStudentPrivacyPayload = any // Api.WorkStudentPrivacyPayload

export type WorkStudentPrivacyInput = any // Api.WorkStudentPrivacyInput

interface WorkStudentPrivacyPayloadApollo {
  WorkStudentPrivacy: WorkStudentPrivacyPayload
}

interface WorkStudentPrivacyInputApollo {
  WorkStudentPrivacyInput: WorkStudentPrivacyInput
}

export const useMutationWorkStudentPrivacy = (
  options?: MutationHookOptions<WorkStudentPrivacyPayloadApollo, WorkStudentPrivacyInputApollo>
) => {
  return useMutation<WorkStudentPrivacyPayloadApollo, WorkStudentPrivacyInputApollo>(
    WorkStudentPrivacy,
    options
  )
}
