import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Indicator } from '@eclass/api'
import { Box } from '@chakra-ui/react'
import { BtnPrimary, BtnSecondary } from '@eclass/ui-kit'

import { UIVars, NewDropdown, ObjectivesModal } from 'app/Components/UI'
import { Icon } from 'app/Components/UI/Icons'
import { testsStates } from '../../../Views/Tests/testsStates'
import TimeProgress from './NavTest/TimeProgress'
import Wrapper from './Style'
import { useFixed } from './useFixed'
import { Types } from './types'
import { RouteParams } from 'Config/routes/types'
import { AddQuestion } from 'app/Views/Helper/AddQuestion/AddQuestion'
import { Alert } from 'app/Views/Helper/Question/Alert'
import { ProvisionalProvider } from '../../ProvisionalStudent'
import { Podcast } from './Podcast'
import { CommentButton } from 'app/Views/Resources/BoxCommentQuestion/Comment/CommentButton'
import { BreadCrumb } from './Breadcrumb/Breadcrumb'
import { Container } from 'app/Layouts'

const NAVIGATION_GRID = 2
const MICROLEARNING_NAVIGATION_ID = 5

/**
 * Nav fixed con avance, volver y dropdown con lista de enlaces
 * @example
 *  <Nav menu={menu} type="navigation-pro | test" topics={} />
 */
const Nav = ({
  activeQuestion,
  answered = null,
  answers,
  config,
  fixed = false,
  type,
  state,
  testActions,
  title,
  topics,
  menu,
  practice = false,
  level = 0,
  resourceName,
  addQuestionInfo,
  loadingMenu,
  podcastUrl,
  levels,
  comment,
}: Types.NavProps) => {
  const { t } = useTranslation()
  const { isFixed } = useFixed()
  const history = useHistory()
  const { answerId, courseId, programId, resourceId, studentId } = useParams<RouteParams>()
  const {
    ui: { isMobile },
    courses: { getCourseInfo: courseInfo },
  } = useSelector((store: Types.Store) => store)

  const [isOpen, setIsOpen] = useState(false)
  const [unitIndicator, setUnitIndicator] = useState<Indicator>({
    id: 0,
    name: 'Estrellas',
    image: 'estrellas',
    obtained: 0,
    max: 0,
  })

  /**
   * Esto valida si estoy en un test de nivel con alumno anónimo
   */
  const isLevelTest = window.location.href.includes('test-level')

  const hasTopics = topics && topics.length > 0
  const showTime =
    [testsStates.ANSWERING, testsStates.COMPLETED, testsStates.TERMINATE].includes(state) &&
    config &&
    config!.hasTime > 0

  const showAddQuestionButton =
    ([testsStates.EVALUATED].includes(state) &&
      ['ARTICLE', 'TEST', 'EXAM', 'WORK'].includes(config?.type!)) ||
    ['WORK'].includes(config?.type!) ||
    !config

  const addQuestionButtonModalType = (resourceType) => {
    if (['TEST', 'EXAM'].includes(resourceType)) {
      return 'test'
    } else if (resourceType === 'WORK') {
      return 'work'
    }

    return 'resource'
  }

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  const urlCourse = `/units/${programId}/${studentId}/${courseId}`
  useEffect(() => {
    if (Object.keys(courseInfo).length === 0) {
      history.push(urlCourse)
    }
  }, [courseInfo, history, courseId, programId, studentId])

  useEffect(() => {
    let max = 0
    let obtained = 0
    if (menu) {
      menu.Links?.forEach((link) => {
        link.Childrens?.forEach((child) => {
          const stars = child.indicators?.find((indicator) => indicator.id === 3)!
          if (stars) {
            max += stars.max!
            obtained += stars.obtained!
          }
        })
      })
      if (max > 0) {
        setUnitIndicator({
          id: 3,
          max,
          obtained,
          name: 'Estrellas',
          image: 'estrellas',
        })
      }
    }
  }, [menu])

  /**
   * Obtiene el título del menu de los subitems en mobile.
   */
  const getMobileTitle = useCallback(() => {
    if (!practice) {
      return {
        title: t('Resource'),
        mobile: '',
        icon: 'materials',
        name: 'navigation',
      }
    }
    const resource = menu?.Links?.find((link) => link.id === Number(resourceId))!
    if (resource && resource!.Childrens!.length > 0) {
      const total = resource.Childrens?.reduce(
        (acc, child, key) => {
          if (child.status?.style === 'completed') {
            acc.answered++
          }
          if (child.id === Number(answerId)) {
            acc.position = key + 1
          }
          acc.max++
          return acc
        },
        { answered: 0, max: 0, position: 1 }
      )

      return {
        title: t('ResourceExercise', {
          position: total?.position,
          total: total?.max,
        }),
        mobile: t('ResourceExercise', {
          context: 'mobile',
          position: total?.position,
          total: total?.max,
        }),
        icon: 'bullet-list',
        name: 'exercise',
      }
    } else {
      // si viene undefined es por el caché, por esto agregamos este texto por defecto
      return {
        title: t('ResourceExercise', { position: 0, total: 1 }),
        mobile: t('ResourceExercise', {
          context: 'mobile',
          position: 0,
          total: 1,
        }),
        icon: 'bullet-list',
        name: 'exercise',
      }
    }
  }, [menu, practice, resourceId, answerId, t])

  const totalOfQuestions: any = []
  isLevelTest &&
    levels.slice(0, level + 1).forEach((item) => {
      item.Stages[0].Answers.forEach((pp: any) => {
        totalOfQuestions.push(pp)
      })
    })

  const totalOfQuestionsLevelTest: any = []
  isLevelTest &&
    levels.forEach((item) => {
      item.Stages[0].Answers.forEach((pp: any) => {
        totalOfQuestionsLevelTest.push(pp)
      })
    })

  const totalTestAnswer = isLevelTest
    ? totalOfQuestionsLevelTest.reduce((acc, Stage) => {
        let answeredAccumulator = 0
        if (Stage?.state! >= 1) {
          answeredAccumulator++
        }
        return (acc = {
          answers: answeredAccumulator + (acc.answers || 0),
        })
      }, {})
    : {}

  const Navigations = [
    {
      id: 'test',
      name: testsStates.EVALUATED === state ? 'feedback' : 'test',
      text:
        testsStates.EVALUATED === state
          ? t('ResourceQuestionsParentesis', {
              answered,
              total: answers ? answers.length : null,
            })
          : t('ResourceQuestions', {
              answered,
              total: answers ? answers.length : null,
            }),
      icon: 'bullet-list',
      items: answers || [],
      header: title || '',
      btnMobile: t('ResourceQuestionsOf', {
        answered,
        total: answers ? answers.length : null,
      }),
    },
    {
      id: 'levels',
      levels: totalOfQuestionsLevelTest,
      name: testsStates.EVALUATED === state ? 'feedbackLevels' : 'levels',
      text: isLevelTest
        ? t('ResourceQuestions', {
            answered: totalTestAnswer.answers || 0,
            total: totalOfQuestions.length || '',
          })
        : testsStates.EVALUATED === state
        ? t('ResourceQuestionsParentesis', {
            answered: answered?.answers || 0,
            total: answered?.total || '',
          })
        : t('ResourceQuestions', {
            answered: answered?.answers || 0,
            total: answered?.total || '',
          }),
      icon: 'bullet-list',
      items: answers || [],
      header: title || '',
      btnMobile: isLevelTest
        ? t('ResourceQuestionsOf', {
            answered: totalTestAnswer.answers || 0,
            total: totalOfQuestions.length || '',
          })
        : t('ResourceQuestionsOf', {
            answered: answered?.answers || 0,
            total: answered?.total || '',
          }),
    },
    {
      id: 'navigation-pro',
      name: getMobileTitle().name,
      text: getMobileTitle().title,
      icon: getMobileTitle().icon,
      items: menu?.Links!,
      header:
        courseInfo.Course!.navigationId === NAVIGATION_GRID
          ? courseInfo.Course!.name
          : menu?.Unit && menu?.Unit.name,
      btnMobile: getMobileTitle().mobile,
      indicator: unitIndicator,
    },
  ]

  const DropType = Navigations.find((items) => {
    if (type && type.includes(items.id)) {
      return items
    }
    return null
  })!

  if (Object.keys(courseInfo).length === 0) {
    return <div>{t('Course information was not retrieved')}</div>
  }

  const isMicroLearning = courseInfo.Course?.navigationId === MICROLEARNING_NAVIGATION_ID

  return (
    <>
      {isMicroLearning ? <></> : <BreadCrumb className={isFixed} />}
      <Wrapper className={`ResourceNav ${isFixed}`} data-testid="Nav">
        <Container size="xl">
          <Box
            position="relative"
            display="flex"
            justifyContent="flex-end"
            pb="16px"
            pt="16px"
            gap="16px"
          >
            {showTime && (
              <TimeProgress
                config={config!}
                testActions={testActions}
                currentLevel={isLevelTest ? levels[level] : 0}
              />
            )}
            <Box className="ResourceNav__menu flex" gap="10px">
              {comment?.show && (
                <CommentButton
                  isMobile={isMobile}
                  onOpen={comment.onOpen}
                  page={comment.page}
                  setPage={comment.setPage}
                  total={comment.total}
                />
              )}

              {showAddQuestionButton && addQuestionInfo && !isMicroLearning && (
                <>
                  <Alert />
                  <ProvisionalProvider>
                    <AddQuestion
                      // @ts-ignore TODO: VALIDAR TYPE
                      type={addQuestionButtonModalType(config?.type)}
                      resourceName={resourceName}
                      addQuestionInfo={addQuestionInfo}
                    />
                  </ProvisionalProvider>
                </>
              )}
              {hasTopics && (
                <Box className="Objectives__Button">
                  <BtnSecondary
                    id={
                      DropType?.name === 'exercise' ? 'ExerciseObjective' : 'ResourceObjectiveModal'
                    }
                    leftIcon={
                      <Icon
                        name="objectives"
                        fill={UIVars.setColors.deepSkyBlue}
                        id={
                          DropType?.name === 'exercise'
                            ? 'ExerciseObjective'
                            : 'ResourceObjectiveModal'
                        }
                      />
                    }
                    onClick={() => toggleModal()}
                    size="small"
                  >
                    {!isMobile && t('ContentObjectives')}
                  </BtnSecondary>
                </Box>
              )}
              {podcastUrl && <Podcast url={podcastUrl} />}
              {![(testsStates.ALERT, testsStates.FINISHED, testsStates.REPETITIONS)].includes(
                state
              ) &&
              type &&
              loadingMenu ? (
                <DropdownLoading />
              ) : (
                DropType?.items &&
                DropType.items.length > 0 && (
                  <NewDropdown
                    levels={totalOfQuestionsLevelTest}
                    activeQuestion={activeQuestion}
                    btnIcon={DropType.icon}
                    btnTitle={DropType.text}
                    dropdownType={DropType.name}
                    headerTitle={DropType.header}
                    items={DropType.items}
                    btnTitleMobile={DropType.btnMobile}
                    indicator={DropType.indicator}
                    level={level}
                  />
                )
              )}
            </Box>
            {hasTopics && (
              <ObjectivesModal isOpen={isOpen} toggleModal={toggleModal} topics={topics} />
            )}
          </Box>
        </Container>
      </Wrapper>
    </>
  )
}

export default Nav

const DropdownLoading = () => {
  return (
    <Box
      sx={{
        'button.chakra-button': {
          mt: '2px',
          h: '32px',
          w: '84.3px',
          '@media screen and (min-width: 481px)': {
            w: '137.3px',
          },
        },
      }}
    >
      <BtnPrimary ariaLabel="Cargando" isLoading disabled size="small" />
    </Box>
  )
}
