import { UIVars } from 'app/Components/UI'

const Helpers = `
  .flex {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &-top {
      align-items: top;
      display: flex;
      justify-content: space-between;
    }

    &-center {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &-bottom {
      align-items: bottom;
      display: flex;
      justify-content: space-between;
    }

    &-end {
      align-items: center;
      display: flex;
      justify-content: flex-end;
    }
  }
  .noStyledList {
    list-style: none;
  }

  .noPadding {
    padding: 0;
  }

  .box {
    background: #eee;
    font-size: 1.2rem;
    padding: 15px;
  }

  .icon {
    color: #c9c9d3;
    font-size: 1.2857rem;
    margin-left: 5rem;
    vertical-align: middle;

    &-alert {
      font-size: 20px;
      color: ${UIVars.setColors.danger};
    }

    @media (min-width: 768px) {
      position: absolute;
      right: -1.3rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`

export default Helpers
