import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Types } from './types'
import { getPersonInfo } from 'app/Redux/Actions'

/**
 * Busco datos del usuario
 * @returns {data} datos del usuario
 * @returns {status} estatus de la petición
 */
export const useDataUser = () => {
  const dispatch = useDispatch()
  const {
    loggedUser: { user: data },
    ui: { programId, campusId },
  } = useSelector((state: Types.Store) => state)

  const [status, setStatus] = React.useState('INIT')

  /**
   * Busca la información extra del alumno y la guardo en la store
   */
  const getInfo = React.useCallback(async () => {
    setStatus('LOADING')
    const response = await dispatch<any>(getPersonInfo())
    if (response && response.status.success) {
      const { dataPersonalInfo, dataContactInfo, dataAcademyInfo } = response
      dispatch({
        type: 'MORE_USER_DATA',
        payload: {
          name: dataPersonalInfo.name,
          firstSurname: dataPersonalInfo.firstSurname,
          lastSurname: dataPersonalInfo.lastSurname,
          fullName: dataPersonalInfo.fullName,
          email: dataContactInfo.email,
          birthday: new Date(dataPersonalInfo.birthday + 'T00:00:00.000'),
          gender: dataPersonalInfo.gender,
          emailPrivate: dataContactInfo.private,
          documentNumber: dataPersonalInfo.documentNumber,
          documentNumberId: dataPersonalInfo.documentNumberId,
          nationId: dataPersonalInfo.nationId,
          professionalResume: dataAcademyInfo.professionalResume,
          phoneInfo: dataContactInfo.phoneInfo,
          address: dataContactInfo.addressInfo,
          academicTrainingInfo: dataAcademyInfo.academicTrainingInfo,
          jobInfo: dataAcademyInfo.jobInfo,
          studyLevelId: dataAcademyInfo.studyLevelId,
          timeZone: dataPersonalInfo.timeZone,
        },
      })
      setStatus('SUCCESS')
    }
  }, [dispatch])

  React.useEffect(() => {
    if (status === 'INIT') {
      getInfo()
    }
  }, [status, getInfo])

  return {
    data,
    status,
    programId,
    campusId,
  }
}
