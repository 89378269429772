// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { axiosError, getToken } from 'app/Services'
import { RouteParams } from 'Config/routes/types'

type Properties = {
  routeParams: RouteParams
  contentModule: {
    options: string[]
    provider: string
    id: number
  }
  textFeedback: string
  isCorrect: number
}

const createContentModuleVR = ({
  routeParams,
  contentModule,
  textFeedback,
  isCorrect,
}: Properties) => {
  const requestId = 'createContentModuleVR'

  return async (dispatch: any) => {
    getToken()

    const query = `
        mutation createContentModuleVR {
          createContentModuleVR(
            modifiedUserId: 0,
            studentId: ${routeParams.studentId},
            courseId: ${routeParams.courseId},
            unitId: ${routeParams.unitId},
            resourceId: ${routeParams.resourceId},
            contentModuleId: ${contentModule.id},
            provider: "${contentModule.provider}",
            textOptions: "${contentModule.options.join('|')}",
            textFeedback: "${textFeedback}",
            isCorrect: ${isCorrect}
          ) {
            id
          }
        }
      `

    return axios
      .post(`${apiURL}/graphql`, { query })
      .then((response) => {
        if (response.data.errors) {
          return {
            status: false,
            message: response.data.errors[0].message,
            data: [],
          }
        } else if (response.status === 200 && response.data.data) {
          return {
            status: true,
            message: response.statusText,
            data: response.data.data,
          }
        }
      })
      .catch((err: any) => {
        axiosError(err, requestId, dispatch)
      })
  }
}

export default createContentModuleVR
