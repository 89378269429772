import React from 'react'
import { useDispatch } from 'react-redux'
import { useFormContext } from 'react-hook-form'

import { getRegions, getLocations } from 'app/Redux/Actions'
import { Types } from '../types'
import { getOption, useListCountries, getValue } from '../Common'

export const useDataAddress = () => {
  const dispatch = useDispatch()
  const FormContext = useFormContext()
  const { getValues, setValue } = FormContext
  const { countries: storeCountries } = useListCountries()

  const [countries, setCountries] = React.useState<Types.select[]>([])
  const [regions, setRegions] = React.useState<Types.select[]>([])
  const [locations, setLocations] = React.useState<Types.select[]>([])
  const [status, setStatus] = React.useState('init')

  const handleOption = React.useCallback(
    (options, input) => {
      getOption({ FormContext, options, input })
    },
    [FormContext]
  )

  /**
   * Obtengo listado de países
   */
  React.useEffect(() => {
    if (storeCountries.length > 0 && countries.length === 0) {
      setCountries(storeCountries)
      handleOption(storeCountries, 'countryId')
    }
  }, [storeCountries, countries, handleOption])

  /**
   * Obtengo listado de regiones
   */
  const loadRegions = React.useCallback(async () => {
    setStatus('loading-Regions')
    const countryId = getValues().countryId
    if (countryId) {
      const response = await dispatch<any>(getRegions(getValue(countryId)))
      if (response && response.status.success) {
        const regionsList = response.data.map((region: Types.Regions) => {
          return { label: region.name, value: region.id }
        })
        setRegions(regionsList)
        handleOption(regionsList, 'regionId')
        setStatus('success-regions')
      } else {
        setStatus('error-regions')
      }
    }
  }, [dispatch, handleOption, getValues])

  /**
   * Obtengo listado de comunas
   */
  const loadLocations = React.useCallback(async () => {
    setStatus('loading-Location')
    const regionId = getValues().regionId
    if (regionId) {
      const response = await dispatch<any>(getLocations(getValue(regionId)))
      if (response && response.status.success) {
        const locationList = response.data.map((location: Types.Locations) => {
          return { label: location.name, value: location.id }
        })
        setLocations(locationList)
        handleOption(locationList, 'locationId')
        setStatus('success-location')
      } else {
        setStatus('error-location')
      }
    }
  }, [dispatch, handleOption, getValues])

  /**
   * llamo al listado de regiones, simpre que haya listado de países
   */
  React.useEffect(() => {
    if (countries.length > 0 && regions.length === 0) {
      loadRegions()
    }
  }, [countries, regions, loadRegions])

  /**
   * llamo al listado de comunas, simpre que haya listado de regiones
   */
  React.useEffect(() => {
    if (regions.length > 0 && locations.length === 0) {
      loadLocations()
    }
  }, [locations, regions, loadLocations])

  /**
   * Dependiendo del select que selecciono, limpio los parametros ya guardados (opciones y id seleccionados)
   * @param type {string}
   */
  const onChange = (type: string) => {
    if (type === 'countryId') {
      const cleanOptions = ['regionId', 'locationId']
      cleanOptions.forEach((option) => setValue(option, null))
      setRegions([])
      setLocations([])
    } else if (type === 'regionId') {
      setValue('locationId', null)
      setLocations([])
    }
  }

  return { countries, status, regions, locations, onChange }
}
