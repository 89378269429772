import { EvaluationCreatePayload } from '@eclass/api'
import { testsStates } from '../../testsStates'

/**
 * Da por finalizado el test y lo cambia al estado completado = true y state = 3.
 */
const finalize = (test: EvaluationCreatePayload, noRepeat = 0) => {
  test.Evaluation!.config.completed = true
  // @ts-ignore
  if (test.Evaluation!.config.needRevisionAfter) {
    test.Evaluation!.config.state = testsStates.PENDING_EVALUATION
  } else {
    test.Evaluation!.config.state = testsStates.FINISHED
  }
  // @ts-ignore
  test.Evaluation!.config.finalize = noRepeat
  return { ...test }
}

export default finalize
