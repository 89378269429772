import { FC, createContext, useContext } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { TimeZoneInfoModal } from 'app/Views/Users/Profile/FormModal/TimeZoneInfo'

interface ContextProps {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}

export const ContextTimeZone = createContext({} as ContextProps)

export const ProviderTimeZone: FC = ({ children }) => {
  const { isOpen, onClose: closed, onOpen: open } = useDisclosure()
  const dispatch = useDispatch()

  const onOpen = () => {
    // Si el centro de ayuda esta desplegado en el dropdown,
    // debo dejar el modal del cambio de zona horaria sobre todo
    const drop = document.querySelector('.dropdown-menu') as HTMLDivElement
    if (drop && drop.className === 'dropdown-menu show') {
      drop.style.zIndex = '10'
      const drop2 = document.querySelector('.dropdown.show') as HTMLDivElement
      if (drop2) {
        drop2.style.zIndex = '10'
      }
    }
    open()
  }

  const onClose = () => {
    closed()
    // Con esto evito que yendo al perfil me muestre la alerta de
    // los datos fueron guardado correctamente
    dispatch({
      type: 'PROFILE_ALERT',
      payload: 'closed',
    })
  }

  return (
    <ContextTimeZone.Provider value={{ isOpen, onClose, onOpen }}>
      {children}
      <Peep />
    </ContextTimeZone.Provider>
  )
}

const Peep = () => {
  const { isOpen, onClose } = useContext(ContextTimeZone)
  const { t } = useTranslation()
  return (
    <TimeZoneInfoModal
      isOpen={isOpen}
      toggleModal={onClose}
      modalName={t('ProfileTimeZoneChange')}
    />
  )
}
