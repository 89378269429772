export const Info = () => {
  return (
    <svg width="12" height="12" fill="none" viewBox="0 0 12 12">
      <g>
        <circle cx="6" cy="6" r="6" fill="#B0CFE0"></circle>
        <path fill="#fff" d="M7 6H5v4h2V6zM6 4a1 1 0 100-2 1 1 0 000 2z"></path>
      </g>
    </svg>
  )
}
