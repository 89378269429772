// @External Dependencies
import React, { useState } from 'react'
import { RequestStatus } from '@eclass/api'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

/**
 * Importamos páginas con errores
 */
import Wrapper from './Style'
import { Error404 } from './404'
import { Error500 } from './500'

import * as ReduxTypes from 'app/Redux/types'

const SENCE_NO_LOGIN = 504

type HandleErrorProps = {
  status: RequestStatus
}

/**
 * Maneja los errores 404 y 500
 */
export default function HandleError({ status }: HandleErrorProps) {
  const [code, updateCode] = useState(status?.code)
  const history = useHistory()
  const {
    courses: {
      getCourseInfo: { Course },
    },
  } = useSelector((store: ReduxTypes.Store) => store)

  const senceUrl = Course
    ? ['/login_sence', Course.programId, Course.studentId, Course.id].join('/')
    : ['/courses'].join('/')

  const renderError = () => {
    switch (code) {
      case 404:
        return <Error404 status={status} />
      case 500:
        return <Error500 status={status} />
      /**
       * Error de "No se ha iniciado la sesión en el curso".
       * Se presenta cuando la sesión SENCE ha sido cerrada por el CRON.
       */
      case SENCE_NO_LOGIN:
        history.push(senceUrl)
        return <></>
      case 503:
        history.push('/courses')
        return <></>
      default:
        updateCode(404)
        break
    }
  }

  return <Wrapper>{renderError()}</Wrapper>
}
