import ZendeskReact, { ZendeskAPI } from 'react-zendesk'

import config from '../../../Config/domains'
import { restrictedView } from './utils/restrictedView'

/**
 * Render de componente Zendesk.
 */
export const Zendesk = () => {
  const zendeskKey = config.keyZendesk || ''
  if (!zendeskKey || zendeskKey.length === 0) {
    return <></>
  }

  return (
    <ZendeskReact
      zendeskKey={zendeskKey}
      onLoaded={() => {
        if (restrictedView()) {
          ZendeskAPI('messenger', 'hide')
        }
      }}
    />
  )
}
