import React from 'react'

import { Option, Options } from '../../types'
import { Select } from 'app/Components/UI'
import { emojis } from './emojis'

export const MobileOptions = ({
  answer,
  changeOption,
  optionDisabled,
  options,
  selectedOption,
}: Options) => {
  const showEmojisNpsQuestion =
    answer?.config?.principalItemId === 15 || answer?.config?.principalItemId === 16

  const selectOptions = (values: Option[]) => {
    const formatOptions = values.map(({ tag, value }) => {
      const valueToNum = Number(value)
      return {
        nombre: `${showEmojisNpsQuestion ? emojis[valueToNum]?.emoji + ' -' : ''} ${value} ${
          tag !== ' ' ? '- ' + tag : ''
        }`,
        id: value,
        value,
      }
    })
    return formatOptions
  }

  return (
    <Select
      name="Scale__Select"
      id="Scale__Select"
      className="Scale__Select"
      disabled={optionDisabled}
      select={selectedOption}
      inputSize={12}
      onChange={(event: any) => changeOption(answer.config?.id, answer.state, event.target.value)}
      options={selectOptions(options)}
    />
  )
}
