import React from 'react'
import styled from 'styled-components/macro'

import UIVars from '../variables'
import { Icon } from 'app/Components/UI/Icons'
import { Props } from './types'

const { orangeyYellow, veryLightPink } = UIVars.setColors

/**
 * Componente de un ícono estrella
 */
const Star = ({ selected, size }: Props.Star) => (
  <Icon
    fill={selected ? orangeyYellow : veryLightPink}
    name={size === 'm' ? 'mediumStar' : 'stars'}
    width={size === 'm' ? 23 : 16}
    height={size === 'm' ? 23 : 16}
  />
)

/**
 * Componente con las estrellas de un set de ejercicios.
 * - max = 0: por defecto podemos asumir que el ejercicio no tiene estrellas.
 * - obtained: 0, el total de estrellas obtenidas.
 * - size: 's', tamaño de las estrellas. Small o Medium.
 */
const CountStars = ({ max = 0, obtained = 0, size = 's' }: Props.CountStars) => (
  <Wrapper>
    {[...Array(max)].map((n, i) => (
      <Star key={i} selected={i < obtained} size={size} />
    ))}
  </Wrapper>
)

const Wrapper = styled.div`
  width: max-content;
  svg {
    margin-right: 5px;
    &:last-child {
      margin-right: 0px;
    }
  }

  @media screen and (max-width: 480px) {
    svg {
      margin-right: 5px !important;
      &:last-child {
        margin-right: 0px !important;
      }
    }
  }
`

export default CountStars
