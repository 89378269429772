import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Store } from 'app/Redux/types'
import { getQuestion } from './getQuestion'
import { typeIn, selectInTheBlank, reorder, textAndMultimedia } from './idsQuetionTypes'
import { EvaluationConfigFeedback } from '@eclass/api'

export const exerciseWithAQuestion = [typeIn, selectInTheBlank, textAndMultimedia]
export const exerciseWithAnswerId = [reorder]

type Props = {
  action: any
  answers: any
  disabled: any
  isMobile: any
  evaluationState: any
  gridOptions: any
  showFeedback: any
  setActiveQuestion: any
  excercises: any
  hasLevelsStages?: boolean
  missingAnswers?: boolean
  stages: any[]
  stageIndex: number
  Feedback: EvaluationConfigFeedback
  levels?: any[]
}
export function QuestionWrapper({
  action,
  answers,
  disabled,
  isMobile,
  evaluationState,
  gridOptions,
  showFeedback,
  Feedback,
  setActiveQuestion,
  excercises,
  hasLevelsStages,
  missingAnswers,
  stages,
  stageIndex,
  levels,
}: Props) {
  const { t } = useTranslation()
  const {
    courses: { getCourseInfo: courseInfo },
  } = useSelector((state: Store) => state)

  const questions = getQuestion(
    answers,
    excercises,
    setActiveQuestion,
    evaluationState,
    showFeedback,
    action,
    disabled,
    isMobile,
    gridOptions,
    t,
    hasLevelsStages,
    missingAnswers,
    stages,
    stageIndex,
    courseInfo.Student?.dates?.canGenerateActivity,
    Feedback,
    levels
  )

  useEffect(() => {
    if (window && window.pageYOffset <= 100 && questions && questions.length > 0) {
      setActiveQuestion(`question-${questions[0].key}`)
    }
  }, [questions, setActiveQuestion])

  return <>{questions}</>
}
