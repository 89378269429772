import React, { useState, ChangeEvent, useEffect, useCallback, memo } from 'react'
import { Input, Label } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Icon } from 'app/Components/UI/Icons'
import { InputProps } from './types'
import { Wrapper } from './styles'
import validateInput from './validateInput'

export const InputText = memo((props: InputProps) => {
  const {
    disabled = false,
    autoFocus = false,
    type,
    placeholder = '',
    label,
    value,
    onChange,
    match = false,
    isSecure = false,
    id,
  } = props
  const [valueInput, setValueInput] = useState(value || '')
  const [icon, setIcon] = useState<JSX.Element>()
  const { t } = useTranslation()
  const [borderValid, setBorderValid] = useState(false)
  const [borderInvalid, setBorderInvalid] = useState(false)
  const [focus, setFocus] = useState(false)

  let timer: any
  const interval = 100
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValueInput(e.target.value)
    if (onChange !== undefined) {
      onChange(e)
    }
  }

  const onFocus = (e: any) => {
    setFocus(true)
    validateInput(type, e.currentTarget.value, validation, t)
  }

  const validation = useCallback(
    (regex: any, newValue: any, message: any) => {
      clearTimeout(timer)
      if (type === 'pass') {
        if (match && isSecure) {
          timer = setTimeout(() => {
            setIcon(
              <div className="valid_feedback">
                <Icon className="circularCheck" name="circularCheck" />
                {t('ProfileCorrectPassword')}
              </div>
            )
            setBorderValid(true)
            setBorderInvalid(false)
          }, interval)
        } else if (newValue === '') {
          timer = setTimeout(() => {
            setIcon(
              <div className="invalid_feedback">
                <Icon className="circularError" name="circularError" />
                {t('ProfileMandatory')}
              </div>
            )
            setBorderValid(false)
            setBorderInvalid(true)
          }, interval)
        } else if (!isSecure) {
          timer = setTimeout(() => {
            setIcon(
              <div className="invalid_feedback">
                <Icon className="circularError" name="circularError" />
                {t('Contraseña Insegura')}
              </div>
            )
            setBorderValid(false)
            setBorderInvalid(true)
          }, interval)
        } else {
          timer = setTimeout(() => {
            setIcon(
              <div className="invalid_feedback">
                <Icon className="circularError" name="circularError" />
                {t('ProfileNoMatch')}
              </div>
            )
            setBorderValid(false)
            setBorderInvalid(true)
          }, interval)
        }
      } else {
        if (value && `${value}`.match(regex)) {
          timer = setTimeout(() => {
            setIcon(
              <div className="valid_feedback">
                <Icon className="circularCheck" name="circularCheck" />
                {t('ProfileCorrect')}
              </div>
            )
            setBorderValid(true)
            setBorderInvalid(false)
          }, interval)
        } else if (value === '') {
          timer = setTimeout(() => {
            setIcon(
              <div className="invalid_feedback">
                <Icon className="circularError" name="circularError" />
                {t('ProfileMandatory')}
              </div>
            )
            setBorderValid(false)
            setBorderInvalid(true)
          }, interval)
        } else {
          timer = setTimeout(() => {
            setIcon(
              <div className="invalid_feedback">
                <Icon className="circularError" name="circularError" />
                {message}
              </div>
            )
            setBorderValid(false)
            setBorderInvalid(true)
          }, interval)
        }
      }
    },
    [setBorderValid, setBorderInvalid, setIcon, match, t, type, value]
  )

  useEffect(() => {
    if (type === 'pass' && focus) {
      validateInput(type, valueInput, validation, t)
    }
  }, [match, focus, type, valueInput, t, validation])

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    clearTimeout(timer)
    validateInput(type, e.currentTarget.value, validation, t)
  }

  const onKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    clearTimeout(timer)
  }

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    clearTimeout(timer)
  }
  useEffect(() => {
    if (value) {
      setValueInput(value)
    }
  }, [value])
  return (
    <Wrapper
      id={id}
      className={classNames({
        invalid: borderInvalid,
        valid: borderValid,
      })}
    >
      {label && <Label for="Input Text">{label}</Label>}
      <Input
        aria-label={type === 'pass' ? 'Password field' : 'Text field'}
        value={valueInput}
        onChange={handleChange}
        onFocus={onFocus}
        type={type === 'pass' ? 'password' : 'text'}
        onKeyUp={onKeyUp}
        onKeyDown={onKeydown}
        onBlur={onBlur}
        autoFocus={autoFocus || false}
        placeholder={placeholder}
        className={classNames({
          borderRed: borderInvalid,
          borderGreen: borderValid,
        })}
        disabled={disabled || false}
      />
      {icon}
    </Wrapper>
  )
})

export default InputText
