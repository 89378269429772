import { onError } from '@apollo/client/link/error'

export const errors = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      switch (message) {
        case 'UNAUTHORIZED':
          UNAUTHORIZED()
          break

        default:
          break
      }
      // console.log(`GraphQL Error: ${message}`)
    })
  }
  if (networkError) {
    // console.log(`Network Error: ${netwwindow.sessionStorage.setItemorkError.message}`)
  }
})

const UNAUTHORIZED = () => {
  window.location.assign('/logout/inactive')
}
