import { Box } from '@chakra-ui/react'
import { BtnSecondary } from '@eclass/ui-kit'
import { useTranslation } from 'react-i18next'

import { Pressed } from 'app/Components/UI'
import { Header } from '../Header'
import * as Style from '../style'
import { Types, Props } from '../types'

/**
 * Caja que muestra links a preguntas frecuentes,
 * incluye vista diferente para curso con sence
 */
export const FrequentQuestions = ({ data, isSence }: Props.FrequentQuestions) => {
  const { t } = useTranslation()

  let links: Types.Link[] = []
  if (data.links) {
    links = Array.from(data.links)
  }

  if (links.length === 0) {
    return null
  }

  return (
    <Style.Box data-testid="BoxFrequentQuestion" className="frequentQuestionsBox">
      <Header type="FRECUENT_QUESTIONS" title={data.title} className="faqHeader" />
      <Style.FrequentQuestions>
        <p>{t('DashboardUsefulInfo')}</p>
      </Style.FrequentQuestions>
      {links.length > 1 ? (
        <Style.List>
          {links.map((link, key) => {
            if (!link.name) return null
            return (
              <li key={key}>
                <Pressed dark>
                  <Style.Anchor className="flex" href={link.href} target="_blank">
                    {t(link.name)}
                  </Style.Anchor>
                </Pressed>
              </li>
            )
          })}
        </Style.List>
      ) : (
        <Box mb="32px" data-testid="helpCenter-buttonCustom">
          <BtnSecondary onClick={() => window.open(links[0].href, '_blank')}>
            {t(links[0].name)}
          </BtnSecondary>
        </Box>
      )}
    </Style.Box>
  )
}
