import React, { useState, useEffect } from 'react'
import { PropAvatar } from './types'
import { Wrapper } from './Styled'
import loadingAvatar from '../../../../assets/svg/animated/Avatar/loading-avatar-animado.svg'
import { avatarPlaceHolder } from 'assets/svg'

type ImgElementProps = {
  url: string
  fn: (state: boolean) => void
  idImg?: string
}

/**
 * Hace el render del Avatar o un Placeholder con las primeras 2 letras del nombre de la persona.
 */
const Avatar = ({
  alt,
  size,
  userId,
  picture,
  fullName,
  fontSize,
  id,
  borderDecoration,
}: PropAvatar) => {
  const [selectedColor, setColor] = useState('')
  const [validUrl, setValidUrl] = useState(false)
  const loadingSvg = <img src={loadingAvatar} alt="loadingAvatar" />

  useEffect(() => {
    const colors = [
      '#0076ba',
      '#229f9c',
      '#2cb26b',
      '#30baed',
      '#95569e',
      '#9bc03a',
      '#d84315',
      '#f5a623',
      '#f6712f',
      '#ff6363',
    ]

    let colorPosition = 0
    if (userId >= 0) {
      const numbers = userId.toString().split('')
      colorPosition = parseInt(numbers[numbers.length - 1], 10)
    } else {
      colorPosition = Math.floor(Math.random() * colors.length)
    }

    setColor(colors[colorPosition])
  }, [userId])

  if (fullName?.length === 0) {
    return (
      <Wrapper
        data-testid="Avatar"
        role="Avatar"
        fontSize={fontSize}
        size={size}
        selectedColor={'#fff'}
        className="RoundedImage"
        borderDecoration={borderDecoration}
      >
        <img src={avatarPlaceHolder} alt="email" id={id} />
      </Wrapper>
    )
  }

  const splitLetterFromName = (input: string) =>
    input &&
    input
      .split(' ')
      .map((letter) => letter[0])
      .slice(0, 2)
      .join('')

  const ImgElement = ({ url, fn, idImg }: ImgElementProps) => {
    return (
      <img
        className={validUrl ? ' ' : 'test-img'}
        alt={alt}
        src={url}
        onLoad={() => fn(true)}
        onError={() => fn(false)}
        id={idImg}
      />
    )
  }

  if (picture) {
    if (!validUrl) {
      return (
        <Wrapper
          role="Avatar"
          data-testid="Avatar"
          fontSize={fontSize}
          size={size}
          selectedColor={selectedColor}
          className="RoundedImage"
          borderDecoration={borderDecoration}
        >
          <span id={id}>{splitLetterFromName(fullName)}</span>
          <ImgElement
            url={picture}
            fn={(status: any) => {
              setValidUrl(status)
            }}
            idImg={id}
          />
        </Wrapper>
      )
    }
    return (
      <Wrapper
        role="Avatar"
        data-testid="Avatar"
        fontSize={fontSize}
        size={size}
        selectedColor={selectedColor}
        id={id}
        className="RoundedImage"
        borderDecoration={borderDecoration}
      >
        {selectedColor === '' ? loadingSvg : <img src={picture} alt={alt} id={id} />}
      </Wrapper>
    )
  }
  return (
    <Wrapper
      role="Avatar"
      data-testid="Avatar"
      fontSize={fontSize}
      size={size}
      selectedColor={selectedColor}
      className="PlaceholderName flex-center"
      borderDecoration={borderDecoration}
    >
      {selectedColor === '' ? loadingSvg : <span id={id}>{splitLetterFromName(fullName)}</span>}
    </Wrapper>
  )
}

Avatar.defaultProps = {
  alt: 'Rounded Image',
  fontSize: 14,
  size: 50,
}

export default Avatar
