import { FC } from 'react'
import { Box } from '@chakra-ui/react'
import { vars } from '@eclass/ui-kit'

export const EnterClass: FC = ({ children }) => {
  return (
    <Box
      alignItems="center"
      bg={vars('colors-neutral-cultured2')}
      border={`solid 1px ${vars('colors-neutral-platinum')}`}
      borderRadius=".625em"
      className="EnterClass"
      display="flex"
      flexDirection="column"
      gap="1.25rem"
      m="0 auto 2.5rem"
      p={'1.5rem'}
      textAlign="center"
      w="fit-content"
      sx={{
        '> button': {
          m: { base: '1rem 0 0 0', md: '0' },
        },
        '> .span': {
          fontWeight: 'bold',
        },
        '@media screen and (min-width: 500px)': {
          flexDirection: 'row',
        },
      }}
    >
      {children}
    </Box>
  )
}
