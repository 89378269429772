import { gql, useMutation, MutationHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const ResourceMeetAddFeedback = gql`
  ${STATUS_FIELDS}
  mutation ResourceMeetAddFeedback($ResourceMeetAddFeedbackInput: ResourceMeetAddFeedbackInput!) {
    ResourceMeetAddFeedback(input: $ResourceMeetAddFeedbackInput) {
      status {
        ...StatusFields
      }
    }
  }
`

export type ResourceMeetAddFeedbackPayload = Api.ResourceMeetAddFeedbackPayload

export type ResourceMeetAddFeedbackInput = Api.ResourceMeetAddFeedbackInput

interface ResourceMeetAddFeedbackPayloadApollo {
  ResourceMeetAddFeedback: ResourceMeetAddFeedbackPayload
}

interface ResourceMeetAddFeedbackInputApollo {
  ResourceMeetAddFeedbackInput: ResourceMeetAddFeedbackInput
}

export const useMutationResourceMeetAddFeedback = (
  options?: MutationHookOptions<
    ResourceMeetAddFeedbackPayloadApollo,
    ResourceMeetAddFeedbackInputApollo
  >
) => {
  return useMutation<ResourceMeetAddFeedbackPayloadApollo, ResourceMeetAddFeedbackInputApollo>(
    ResourceMeetAddFeedback,
    options
  )
}
