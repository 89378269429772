export const Morning = (props) => {
  return (
    <g {...props}>
      <path d="M9 0H7V2H9V0Z" fill="#B0CFE0" />
      <path
        d="M4.46458 3.12134L3.05078 1.70654L1.63598 3.12034L3.04978 4.53514L4.46458 3.12134Z"
        fill="#B0CFE0"
      />
      <path d="M2 7H0V9H2V7Z" fill="#B0CFE0" />
      <path
        d="M14.2942 3.05052L12.8794 1.63672L11.4656 3.05152L12.8804 4.46532L14.2942 3.05052Z"
        fill="#B0CFE0"
      />
      <path d="M16 7H14V9H16V7Z" fill="#B0CFE0" />
      <path
        d="M12.6 10C12.4 9.6 12.2 9.2 11.9 8.9C12 8.6 12 8.3 12 8C12 5.8 10.2 4 8 4C5.8 4 4 5.8 4 8C4 8.3 4 8.6 4.1 8.9C3.8 9.2 3.6 9.6 3.4 10C1.5 10 0 11.6 0 13.5C0 14.3 0.2 15 0.7 15.6L2.3 14.4C2.1 14.1 2 13.8 2 13.5C2 12.7 2.7 12 3.5 12C3.8 12 4.6 12.2 4.9 12.3L5.1 11.3C5.4 10 6.6 9 8 9C9.4 9 10.6 10 10.9 11.3L11.1 12.3C11.4 12.2 12.2 12 12.5 12C13.3 12 14 12.7 14 13.5C14 13.8 13.9 14.1 13.7 14.4L15.3 15.6C15.8 15 16 14.3 16 13.5C16 11.6 14.5 10 12.6 10Z"
        fill="#B0CFE0"
      />
    </g>
  )
}
