import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, useDisclosure } from '@chakra-ui/react'

import { PrerequisitesModal } from './PrerequisitesModal'
import { usePrerequisites } from './usePrerequisites'
import Types from './types'

export const Prerequisites = ({ materialName, prerequisites }: Types.PrerequisitesProps) => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { prerequisitesStatus } = usePrerequisites({ prerequisites })

  const [prerequisiteWithStatus, setPrerequisiteWithStatus] = React.useState<
    ReturnType<typeof prerequisitesStatus>
  >([])

  const prerequisitesHandler = (event) => {
    event.stopPropagation()

    setPrerequisiteWithStatus(prerequisitesStatus())
    onOpen()
  }

  return (
    <>
      <Button
        variant="link"
        color="main.deepSkyBlue"
        fontWeight="medium"
        fontSize="xs"
        mt="2"
        textDecoration="underline"
        _hover={{ textDecoration: 'none' }}
        border={0}
        background="none"
        onClick={prerequisitesHandler}
        data-testid="Prerequisites"
      >
        {t('ResourcePrerequisitesHeader')}
      </Button>
      {isOpen && (
        <PrerequisitesModal
          isOpen={isOpen}
          onClose={onClose}
          materialName={materialName}
          prerequisites={prerequisiteWithStatus}
        />
      )}
    </>
  )
}
