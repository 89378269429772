import React from 'react'
import { useQuery } from '@apollo/client'

import * as Apollo from 'app/Apollo'
import { Types } from '../types'
import { useSetSelect } from '../Common'

export const useIndustries = () => {
  const [state, setState] = React.useState<Types.select[]>([])
  const { setOption } = useSetSelect(state, 'industryId')

  const { data } = useQuery<Apollo.ALL_INDUSTRYS_TYPE>(Apollo.ALL_INDUSTRYS)

  const load = React.useCallback(() => {
    const list = data?.allIndustrys.map((x) => {
      return {
        label: x.name,
        value: x.id,
      }
    })
    if (list) {
      setOption(list)
      setState(list)
    }
  }, [data])

  React.useEffect(() => {
    if (data && data?.allIndustrys?.length > 0 && state.length === 0) {
      load()
    }
  }, [data, state])

  return { industries: state }
}
