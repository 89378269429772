import axios from 'axios'

/**
 * Parseo cualquier schema de URL (//, http, https, ftp, etc) a https
 */
const withHttps = (url) =>
  url.replace(/^(?:(.*:)?\/\/)?(.*)/i, (match, schemma, nonSchemmaUrl) =>
    schemma ? match : `https://${nonSchemmaUrl}`
  )

/**
 * Recibo la URL para convertir a base64.
 */
export async function getAxiosBase64(url: string) {
  return await axios
    .get(withHttps(url), {
      responseType: 'arraybuffer',
      headers: [],
    })
    .then((response) => Buffer.from(response.data, 'binary').toString('base64'))
}
