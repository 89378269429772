const JWT_EXPIRE_IN_MILIESECONS = 86400000 // 1 día
const DELAY_GET_FEEDBACK = 5000 // 5s

/**
 * Ejecuta el callback una vez se cumpla la fecha que se le envía
 */
export const setActivationDate = (date: Date, action: () => void) => {
  const start = new Date(date)
  const now = new Date()
  const difference = start.getTime() - now.getTime()
  // valido que el tiempo de activación no sea mayor al de la sesion del alumno
  if (difference > 0 && difference < JWT_EXPIRE_IN_MILIESECONS) {
    return setTimeout(() => {
      action()
    }, difference + DELAY_GET_FEEDBACK)
  } else {
    return setTimeout(() => {}, DELAY_GET_FEEDBACK)
  }
}
