export const MediumStar = (props) => {
  return (
    <path
      {...props}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9635 16.5101C10.6732 16.3582 10.3268 16.3582 10.0365 16.5101L5.52664 18.8693C4.7939 19.2526 3.93904 18.6315 4.07716 17.8162L4.92727 12.7981C4.982 12.475 4.87497 12.1456 4.64079 11.9164L1.00346 8.35632C0.412481 7.77789 0.739008 6.77294 1.55711 6.65235L6.59233 5.91016C6.91651 5.86237 7.19671 5.6588 7.34234 5.36525L9.60417 0.805819C9.97167 0.0650204 11.0283 0.0650202 11.3958 0.805819L13.6577 5.36525C13.8033 5.6588 14.0835 5.86237 14.4077 5.91016L19.4429 6.65235C20.261 6.77294 20.5875 7.77789 19.9965 8.35632L16.3592 11.9164C16.125 12.1456 16.018 12.475 16.0727 12.7981L16.9228 17.8162C17.061 18.6315 16.2061 19.2526 15.4734 18.8693L10.9635 16.5101Z"
    />
  )
}
