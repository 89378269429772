import * as Locale from 'date-fns/locale'
import { isValid } from 'date-fns'
import { utcToZonedTime, format } from 'date-fns-tz'
import { formats, TFormat, TLanguage } from './formats'

export interface IDateFormat {
  date: Date | string
  format?: TFormat
  timeZone?: string
  language?: TLanguage
}

export const msgError = 'Invalid date'
/**
 * Formateo fecha en formato Date a estructura definida con horario en su zona horaria correspondiente e idiomas necesarios,
 *
 * La fecha debe de llegar formateada como acontinuación.
 *
 * new Date('2022-03-16T13:00:00.000Z')
 *
 * importante al momento de generar la fecha pasarle la z para que la hora la transforme a GMT +0
 */
export function dateFormat({
  date,
  format: formatParams = 'dmy',
  timeZone = '',
  language = 'es',
}: IDateFormat) {
  try {
    let validate = date
    if (typeof date === 'string') {
      const demoDateString = '2022-03-25T13:00:00.000Z'
      validate = date.length === demoDateString.length ? new Date(date) : ''
    }
    if (!isValid(validate)) {
      throw new Error(msgError)
    }

    /**
     * En caso de que el alumno no tenga seleccionada su Zona horaria, llegará vacía.
     * Por lo tanto el parámetro por defecto se reemplazará igual por asociación si dejamos timeZone = 'America/Santiago' en los parametros de la función.
     * Y al no tener Zona horaria la parsea en GMT+0
     *
     * al mantener este IF siempre que no venga la zona horaria a la función o que el alumno no tenga su zona definida. Parsearemos con America/Santiago.
     */
    if (timeZone === '') {
      timeZone = 'America/Santiago'
    }

    /**
     * Cambio de hora quitar despues del Apruebo/Rechazo por el cambio de hora
     * CAMBIAR EL 12 de SEPTIEMBRE
     * QUITARLO AUNQUE NO HARÁ NADA
     */
    if (
      timeZone === 'America/Santiago' &&
      date >= '2022-09-04T00:00:00.000Z' &&
      date <= '2022-09-11T00:00:00.000Z'
    ) {
      timeZone = 'America/New_York'
    }

    const dateutcToZonedTime = utcToZonedTime(date, timeZone)
    // envio fecha con formato, zona horaria y idioma definido
    return format(dateutcToZonedTime, formats(formatParams, language), {
      timeZone,
      locale: Locale[language],
    })
  } catch (error) {
    return msgError
  }
}
