import { useTranslation } from 'react-i18next'

import Types from './types'
import * as Style from './style'
import { BtnPrimary, BtnSecondary, BtnTertiary } from '@eclass/ui-kit'
import { HStack } from '@chakra-ui/react'

/**
 * Footer con los botones(acciones) del modal.
 *
 * Crear, editar y eliminar elemento
 */
export const FooterActions = ({
  showDelete = false,
  showCancel = true,
  loading = false,
  cancel = () => {},
  handleDelete = () => {},
}: Types.FooterActions) => {
  const { t } = useTranslation()

  return (
    <Style.Wrapper>
      <HStack gap=".75rem" className="btn_container">
        <BtnPrimary isFullWidth type="submit" disabled={loading} isLoading={loading}>
          {t('ProfileSave')}
        </BtnPrimary>
        {showCancel && (
          <BtnSecondary isFullWidth onClick={() => !loading && cancel()} disabled={loading}>
            {t('ProfileCancel')}
          </BtnSecondary>
        )}
      </HStack>
      {showDelete && (
        <BtnTertiary
          m="24px 0 0 0"
          iconStatus="delete"
          withoutColor
          onClick={() => !loading && handleDelete()}
        >
          {t('ProfileDelete')}
        </BtnTertiary>
      )}
    </Style.Wrapper>
  )
}
