import styled from 'styled-components/macro'
import { UIVars } from 'app/Components/UI'
import { vars } from '@eclass/ui-kit'

// @Styles
export const Wrapper = styled.header`
  background: #222;
  padding-bottom: 16px;
  padding-top: 16px;
  &.fixed {
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 999;
  }

  .logo_Eclass {
    margin-right: 50px;
  }

  .label-logo {
    color: #fff;
    font-size: 14px;
    text-decoration: underline;
  }

  .Header__topbar {
    color: #fff;
    position: relative;

    .buttonsContainer {
      display: flex;

      .userWay {
        border-inline: 1px ${vars('colors-neutral-davysGrey')};
        border-style: none solid none none;
        margin-right: 16px;
        padding-right: 16px;
      }

      .btn-secondary {
        background: ${vars('colors-main-blueGrey')};
        border: 1px solid transparent;
        border-radius: 100%;
        height: 30px;
        width: 30px;

        &:hover {
          background-color: rgba(96, 121, 142, 0.8);
        }

        &.helpCenter {
          margin-right: 24px;
        }

        > svg {
          margin: 0;
        }

        .btn-secondary:not(:disabled):not(.disabled) .active,
        .btn-secondary:not(:disabled):not(.disabled):active,
        .show > .btn-secondary.dropdown-toggle,
        .btn-secondary.focus,
        .btn-secondary:focus,
        .btn-secondary:hover {
          background: none;
          border: none;
          box-shadow: none;
        }
      }
    }
  }

  .Header__programs {
    border-radius: 4px;
    border: 1px solid white;
    font-size: 14px;
    min-width: 185px;

    &.show:before {
      left: 10px;
    }

    .dropdown-item {
      &:last-child {
        padding: 0;
      }
    }

    & > button {
      font-size: 14px;
      padding: 6px 10px;
      width: 100%;
    }

    svg {
      margin: 0;
    }

    a {
      color: #2f2f2f;
      font-size: 14px;
      padding: 14px 20px;
      text-transform: none;

      svg {
        visibility: hidden;
      }

      &.active {
        font-weight: bold;

        svg {
          visibility: visible;
        }
      }
    }
  }

  .Header__user {
    .bell-icon,
    .comment-icon {
      cursor: pointer;
      margin-right: 30px;

      &:hover {
        path,
        g {
          fill: ${UIVars.setColors.primary};
        }
      }
    }
  }

  .card {
    border: 0;
    border-radius: 0;
    display: none;
    &-header {
      background: ${UIVars.setColors.whiteTwo};
      border: 0;
      border-radius: 0;
      cursor: pointer;
      font-size: 14px;
      padding: 16px 20px;
      .opened {
        transform: rotate(180deg);
      }
    }

    &-body {
      box-shadow: inset 0 -6px 11px 0 #f4f4f4;
      padding: 0;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        a {
          border-bottom: 1px solid #e6e6e6;
          display: block;
          font-size: 14px;
          padding: 15px 20px;

          svg {
            visibility: hidden;
          }

          &.active {
            font-weight: bold;
            svg {
              visibility: visible;
            }
          }
        }
      }
    }
  }

  .customToolTip {
    div {
      top: 11px !important;
      left: -10px !important;
      transform: translate3d(812px, 47px, 0px) !important;
    }
  }

  @media screen and (max-width: 480px) {
    .logo_Eclass {
      width: 80px;
    }
  }
`
