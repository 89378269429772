import React from 'react'
import toast from 'toasted-notes'
import { useTranslation } from 'react-i18next'

import { SideFlash } from 'app/Components/UI/Alert'

type statusTypes = {
  status: {
    code: string
    detail: string
    name: string
  }
}

const TestsAlerts = ({ status }: statusTypes) => {
  const { t } = useTranslation()

  if (status.code === 'success') {
    let text = t('ResourceSavedAnswer')
    if (status.detail === 'skip') {
      // Cuando la pregunta se omite cambia el texto
      text = t('ResourceQuestionOmitted')
    } else {
      if (status.detail === 'respond') {
        // Cuando decide responder una pregunta omitida cambia el texto
        text = t('You can answer now')
      }
    }
    return (
      <>
        {toast.notify(
          () => (
            <SideFlash margin={30} toasted={true} type="info">
              <span>{text}</span>
            </SideFlash>
          ),
          {
            position: 'top',
            duration: 1500,
          }
        )}
      </>
    )
  } else if (status.code === 'warning') {
    return (
      <>
        {toast.notify(
          () => (
            <SideFlash type="warning" toasted={true}>
              <>
                <strong>{t('A problem has occurred')}</strong> {t('Try again')}
              </>
            </SideFlash>
          ),
          {
            position: 'top',
            duration: 1500,
          }
        )}
      </>
    )
  } else if (status.code === 'timeExpired') {
    return (
      <>
        {toast.notify(
          () => (
            <SideFlash type="error" toasted={true}>
              <>
                <strong>{status.name}</strong> {status.detail}
              </>
            </SideFlash>
          ),
          {
            position: 'top-right',
            duration: 1500,
          }
        )}
      </>
    )
  } else {
    return null
  }
}

export default TestsAlerts
