import { lazy } from 'react'

import { Types } from './types'

// Componentes lazy
// Rutas privadas
const lazyCourses = lazy(() => import('app/Views/Courses/' /* webpackChunkName: "courses" */))
const lazyCourseClassmates = lazy(
  () => import('app/Views/Courses/Classmates' /* webpackChunkName: "classmates" */)
)
const lazyCourseHelpCenter = lazy(() => import('app/Views/Helper' /* webpackChunkName: "helper" */))
const lazyCourseLoginSence = lazy(
  () => import('app/Views/LoginSence' /* webpackChunkName: "login-sence" */)
)
const lazyCoursePerformance = lazy(
  () =>
    import(
      'app/Views/Courses/Performance/PerformanceView' /* webpackChunkName: "performance-view" */
    )
)
const lazyExerciseDebate = lazy(
  () => import('app/Views/Exercise/Debate' /* webpackChunkName: "exercise-debate" */)
)
const lazyExercisePractice = lazy(
  () => import('app/Views/Exercise/Practice' /* webpackChunkName: "exercise-practice" */)
)
const lazyHelperQuestionView = lazy(
  () => import('app/Views/Helper/Question' /* webpackChunkName: "helper-question" */)
)
const lazyPastCourses = lazy(
  () => import('app/Views/Courses/Past' /* webpackChunkName: "past-courses" */)
)
const lazyResourceView = lazy(
  () => import('app/Views/Resources' /* webpackChunkName: "resource-view" */)
)
const lazyResourcePDF = lazy(
  () => import('app/Views/Resources/PDF/ViewRender' /* webpackChunkName: "resource-pdf" */)
)
const lazyResumeExercise = lazy(
  () => import('app/Views/Exercise/Practice/Resume' /* webpackChunkName: "resume-exercise" */)
)
const lazyTermsAndConditions = lazy(
  () => import('app/Views/Terms' /* webpackChunkName: "terms-and-conditions" */)
)
const lazyTestsPending = lazy(
  () => import('app/Views/Tests/Pending' /* webpackChunkName: "tests-pending" */)
)
const lazyTestsResume = lazy(
  () => import('app/Views/Tests/Resume' /* webpackChunkName: "tests-resume" */)
)
const lazyUnitList = lazy(() => import('app/Views/Units' /* webpackChunkName: "units" */))
const lazyRequirements = lazy(
  () => import('app/Views/Requirements' /* webpackChunkName: "requirements" */)
)
const lazyTests = lazy(() => import('app/Views/Tests/Tests' /* webpackChunkName: "tests" */))
const lazyConfirmationPersonal = lazy(
  () =>
    import('app/Views/Users/Confirmation/Personal' /* webpackChunkName: "confirmation-personal" */)
)
const lazyConfirmationAcademicLaboral = lazy(
  () =>
    import(
      'app/Views/Users/Confirmation/AcademicLabor' /* webpackChunkName: "confirmation-academic-laboral" */
    )
)
const lazyProfile = lazy(
  () => import('app/Views/Users/Profile/Profile' /* webpackChunkName: "profile" */)
)
const lazyCEVSchedule = lazy(
  () => import('app/Views/CEV/Schedule/Schedule' /* webpackChunkName: "cev-schedule" */)
)
const lazyCEVDetail = lazy(
  () => import('app/Views/CEV/Detail/Detail' /* webpackChunkName: "cev-detail" */)
)
const lazyPrerequisites = lazy(
  () => import('app/Views/Prerequisites' /* webpackChunkName: "prerequistes" */)
)
const lazyLeaderBoard = lazy(
  () => import('app/Views/LeaderBoard' /* webpackChunkName: "prerequistes" */)
)

// Rutas publicas
const lazyUserLogin = lazy(
  () => import('app/Views/Users/Login/' /* webpackChunkName: "user-login-form" */)
)
const lazyUserLogout = lazy(
  () => import('app/Views/Users/Login/Logout/' /* webpackChunkName: "user-logout" */)
)
const lazyUserRecoveryPasswd = lazy(
  () => import('app/Views/Users/Login/Recovery' /* webpackChunkName: "user-recovery-password" */)
)
const lazyUserRecoveryPasswdChange = lazy(
  () =>
    import('app/Views/Users/Login/Password' /* webpackChunkName: "user-recovery-password-change" */)
)
const lazyPersonalMeeting = lazy(
  () => import('app/Views/PersonalMeeting' /* webpackChunkName: "user-recovery-password-change" */)
)
const lazyResourceQr = lazy(
  () => import('app/Views/Resources/Qr' /* webpackChunkName: "resource-qr" */)
)
const lazyLevelTestForm = lazy(
  () => import('app/Views/LevelTest/ContactForm' /* webpackChunkName: "test-level-form" */)
)
const lazyLevelTestCheckAudio = lazy(
  () => import('app/Views/LevelTest/CheckAudio' /* webpackChunkName: "test-level-check-audio" */)
)
const lazyLevelTestTest = lazy(
  () => import('app/Views/LevelTest/Test' /* webpackChunkName: "test-level-test" */)
)
const lazyLevelTestResume = lazy(
  () => import('app/Views/LevelTest/Resume' /* webpackChunkName: "test-level-test" */)
)
const lazyWork = lazy(() => import('app/Views/Work' /* webpackChunkName: "work-view" */))
const lazyWorkPDF = lazy(() => import('app/Views/Work/PDF' /* webpackChunkName: "work-pdf-view" */))

const CourseParams = ':programId/:studentId/:courseId'
const ResourceParams = ':programId/:studentId/:courseId/:unitId/:resourceId'
const ResourcesUnitParams = ':programId/:studentId/:courseId/:unitId'

/**
 * Ordena las rutas privadas de la App.
 *
 * Las rutas DEBEN estar ordenadas de la más específica a la menos específica, o el Router creará rutas en blanco.
 */
export const PrivateRoutes: Types.Route[] = [
  { path: '/profile', component: lazyProfile },
  { path: '/courses', component: lazyCourses },
  { path: '/courses-past', component: lazyPastCourses },
  { path: '/courses/:programId/:slug', component: lazyCourses },
  { path: `/units/${CourseParams}`, component: lazyUnitList },
  {
    path: `/login_sence/${CourseParams}/:err`,
    component: lazyCourseLoginSence,
  },
  {
    path: `/login_sence/${CourseParams}`,
    component: lazyCourseLoginSence,
  },
  {
    path: `/helper/view/${CourseParams}/:questionId`,
    component: lazyHelperQuestionView,
  },
  { path: `/helper/${CourseParams}`, component: lazyCourseHelpCenter },
  {
    path: `/performance/${CourseParams}`,
    component: lazyCoursePerformance,
  },
  {
    path: `/classmates/${CourseParams}`,
    component: lazyCourseClassmates,
  },
  {
    path: `/resource/view/${ResourceParams}`,
    component: lazyResourceView,
  },
  {
    path: `/resource/pdf/${ResourceParams}`,
    component: lazyResourcePDF,
  },
  {
    path: `/resource/pdf/${ResourcesUnitParams}`,
    component: lazyResourcePDF,
  },
  {
    path: `/resource/calendar/${ResourceParams}`,
    component: lazyCEVSchedule,
  },
  {
    path: `/resource/detail/${ResourceParams}`,
    component: lazyCEVDetail,
  },
  {
    path: `/resource/meet/${CourseParams}/:unitId`,
    component: lazyPersonalMeeting,
  },
  {
    path: `/test/alert/${ResourceParams}`,
    component: lazyTests,
  },
  {
    path: `/test/answer/${ResourceParams}`,
    component: lazyTests,
  },
  {
    path: `/test/review/${ResourceParams}/:evaluationId`,
    component: lazyTests,
  },
  {
    path: `/test/resume/${ResourceParams}/:evaluationId`,
    component: lazyTestsResume,
  },
  {
    path: `/test/pending/${ResourceParams}`,
    component: lazyTestsPending,
  },
  {
    path: `/exercise/view/${ResourceParams}`,
    component: lazyExerciseDebate,
  },
  {
    path: `/exercise/practice/${ResourceParams}/:answerId`,
    component: lazyExercisePractice,
  },
  {
    path: `/exercise/practice/${ResourceParams}`,
    component: lazyExercisePractice,
  },
  {
    path: `/exercise/resume/${ResourceParams}`,
    component: lazyResumeExercise,
  },
  { path: '/confirmation/personal', component: lazyConfirmationPersonal },
  {
    path: '/confirmation/academic-labor',
    component: lazyConfirmationAcademicLaboral,
  },
  { path: '/terms', component: lazyTermsAndConditions },
  { path: '/requirements', component: lazyRequirements, public: true },
  { path: `/prerequisites/${ResourceParams}`, component: lazyPrerequisites },
  { path: `/leaderboard/${CourseParams}`, component: lazyLeaderBoard },
  {
    path: `/work/${ResourceParams}`,
    component: lazyWork,
  },
  {
    path: `/pdf/work/${ResourceParams}`,
    component: lazyWorkPDF,
  },
  {
    path: `/pdf/rubric/${ResourceParams}`,
    component: lazyWorkPDF,
  },
]

/**
 * Lista las rutas públicas de la App.
 *
 * Las rutas DEBEN estar ordenadas de la más específica a la menos específica, o el Router creará rutas en blanco.
 *
 * Por ej, si ponemos el path: '/' al inicio, todas las rutas de abajo no se cargarán, porque solo reconocerá esa.
 */
export const PublicRoutes: Types.Route[] = [
  {
    path: '/users/recover_passwd/:token',
    component: lazyUserRecoveryPasswdChange,
  },
  {
    path: '/users/recover_passwd',
    component: lazyUserRecoveryPasswd,
  },
  { path: '/test-level/:testId', component: lazyLevelTestForm },
  { path: '/test-level/check-audio/:testId', component: lazyLevelTestCheckAudio },
  { path: '/test-level/resume/:testId/:evaluationId', component: lazyLevelTestResume },
  { path: '/test-level/answer/:testId', component: lazyLevelTestTest },
  {
    path: '/login',
    component: lazyUserLogin,
  },
  {
    path: '/logout/inactive',
    component: lazyUserLogout,
  },
  {
    path: '/qr/:studentId/:courseId/:moduleId',
    component: lazyResourceQr,
  },
  {
    path: '/',
    component: lazyUserLogin,
  },
]
