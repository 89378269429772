import { useSelector } from 'react-redux'

import { Store } from 'app/Redux/types'

/**
 * Devuelve si el alumno es provisional
 */
export const useIsProvisional = () => {
  const { courses } = useSelector((state: Store) => state)

  return {
    isProvisional: courses.getCourseInfo.Student?.provisional === 1,
  }
}
