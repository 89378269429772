import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import * as Apollo from 'app/Apollo'
import { RequestError } from 'app/Components'
import { RouteParamsTestLevel } from 'Config/routes/types'

export const useEvaluationCheckLevel = (evaluationId, currentLevel) => {
  const { testId } = useParams<RouteParamsTestLevel>()
  const dispatch = useDispatch()

  const [EvaluationCheckLevel, { loading }] = Apollo.useMutationEvaluationCheckLevel({
    variables: {
      EvaluationCheckLevelInput: {
        evaluationId: Number(evaluationId),
        levelId: currentLevel.id!,
        testId: Number(testId),
      },
    },
    onCompleted: (dataCompleted) => {
      if (!dataCompleted || !dataCompleted.EvaluationCheckLevel.status.success) {
        RequestError.handle(dispatch, dataCompleted.EvaluationCheckLevel)
      }
    },
  })

  return { loading, EvaluationCheckLevel }
}
