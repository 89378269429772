import { useReducer, useEffect } from 'react'

import { Modal } from '../components/Modal'
import { useIsProvisional } from '../hooks/useIsProvisional'
import { ProvisionalContext, ProvisionalReducer } from '.'

export interface ProvisionalState {
  status: boolean
  isProvisional: boolean
}

const PROVISIONAL_INITIAL_STATE: ProvisionalState = {
  status: false,
  isProvisional: false,
}

interface props {
  children: React.ReactNode
}

export const ProvisionalProvider = ({ children }: props) => {
  const [state, dispatch] = useReducer(ProvisionalReducer, PROVISIONAL_INITIAL_STATE)
  const { isProvisional } = useIsProvisional()

  useEffect(() => {
    dispatch({ type: 'Provisional - Status redux', payload: isProvisional })
  }, [])

  const openModal = () => {
    dispatch({ type: 'Provisional - Show modal', payload: true })
  }

  const closeModal = () => {
    dispatch({ type: 'Provisional - Show modal', payload: false })
  }

  return (
    <ProvisionalContext.Provider value={{ ...state, openModal, closeModal }}>
      {children}
      <Modal />
    </ProvisionalContext.Provider>
  )
}
