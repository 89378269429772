import { EvaluationCreatePayload } from '@eclass/api'
import setLevelsInitialState from '../../helpers/setLevelsInitialState'

/**
 * Da por finalizado el test y lo cambia al estado completado = true y state = 3.
 */
const embedResponse = (test: EvaluationCreatePayload, Evaluation: any) => {
  const { Levels, Answers } = setLevelsInitialState(Evaluation!.Levels!, Evaluation!.config)
  Evaluation!.Levels = Levels
  // @ts-ignore
  Evaluation!.Answers = Answers
  test.Evaluation = Evaluation
  return { ...test }
}

export default embedResponse
