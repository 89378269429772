import React from 'react'

import MenuItemTest from './MenuItemTest'
import * as Style from './Style'
import { numberOfQuestion } from 'app/Views/Tests/helpers/numberOfQuestion'

export const MenuItemLevel = ({ level, activeQuestion, items, levels }: any) => {
  const totalLevels = items.length || 0
  const hasDescription = items[0]?.description !== ''
  const totalStages = items[0]?.Stages.length || 0

  /**
   * Esto valida si estoy en un test de nivel con alumno anónimo
   */
  const isLevelTest = window.location.href.includes('test-level')

  return (
    <Style.Levels>
      {(totalLevels > 1 || hasDescription) && !isLevelTest && (
        <span className="levelName">{level?.name}</span>
      )}
      {level?.Stages.map((stage, key: number) => (
        <span key={key} className="itembox">
          {(totalStages > 1 || (stage.description !== '' && !isLevelTest)) && (
            <span className="stageName">{stage.name}</span>
          )}
          {stage.Answers.map((answer, index: number) => {
            return (
              <MenuItemTest
                activeQuestion={activeQuestion}
                key={answer.config.id}
                number={
                  numberOfQuestion({
                    stages: level?.Stages,
                    id: answer.config.id,
                    hasLevelsStages: true,
                    index,
                    stageIndex: key,
                    levels: isLevelTest ? levels : null,
                    isLevelTest,
                  }) - 1
                }
                answer={answer}
              />
            )
          })}
        </span>
      ))}
    </Style.Levels>
  )
}
