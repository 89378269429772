import { gql, useMutation, MutationHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const EVALUATION_CHECK_LEVEL = gql`
  ${STATUS_FIELDS}
  mutation EvaluationCheckLevel($EvaluationCheckLevelInput: EvaluationCheckLevelInput!) {
    EvaluationCheckLevel(input: $EvaluationCheckLevelInput) {
      status {
        ...StatusFields
      }
      isCompleted
      nextLevelId
      feedbackUrl
      internalId
    }
  }
`
// @ts-ignore
export type EvaluationCheckLevelPayload = Api.EvaluationCheckLevelPayload
// @ts-ignore
export type EvaluationCheckLevelInput = Api.EvaluationCheckLevelInput

interface EvaluationCheckLevelPayloadApollo {
  EvaluationCheckLevel: EvaluationCheckLevelPayload
}

interface EvaluationCheckLevelInputApollo {
  EvaluationCheckLevelInput: EvaluationCheckLevelInput
}

export const useMutationEvaluationCheckLevel = (
  options?: MutationHookOptions<EvaluationCheckLevelPayloadApollo, EvaluationCheckLevelInputApollo>
) => {
  return useMutation<EvaluationCheckLevelPayloadApollo, EvaluationCheckLevelInputApollo>(
    EVALUATION_CHECK_LEVEL,
    options
  )
}
