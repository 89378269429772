import { EvaluationCreatePayload } from '@eclass/api'

/**
 * Al cargar el componente inicializa el test y verifica si está completado lo pasa a `config.state = 3`.
 */
const loadTest = (payload: EvaluationCreatePayload) => {
  const { hasTime, timeRemaining, completed } = payload.Evaluation!.config

  /**
   * Forzamos que se finalice la prueba si es que tiene tiempo y se acabaron los minutos de rendiciòn.
   */
  const state =
    hasTime && timeRemaining < 0 && !completed
      ? 3 // Se finaliza y se evalúa.
      : payload.Evaluation!.config.state

  return {
    ...payload,
    Evaluation: {
      ...payload.Evaluation,
      config: {
        ...payload.Evaluation!.config,
        state: state,
      },
    },
  }
}

export default loadTest
