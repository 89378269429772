export const TrashNoX = (props) => {
  return (
    <g {...props} fillRule="nonzero">
      <path
        d="M13.9595376,4 L13.9595376,2 L9.59718208,2 L9.59718208,1 C9.59718208,0.44771525 9.20656346,0 8.72471098,0 L5.23482659,0 C4.75297411,0 4.36235549,0.44771525 4.36235549,1 L4.36235549,2 L0,2 L0,4 L13.9595376,4 Z"
        id="Path"
      ></path>
      <path
        d="M1.7449422,5 L1.7449422,14 C1.7449422,15.1045695 2.52617943,16 3.48988439,16 L10.4696532,16 C11.4333581,16 12.2145954,15.1045695 12.2145954,14 L12.2145954,5 L1.7449422,5 Z"
        id="Shape"
      ></path>
    </g>
  )
}
