interface Indicator {
  image: string
  name?: string
}

/** Función que recorre los indicadores de cada item y almacena sus nombres para usar en ariaLabel */
export const getIndicatorsText = (indicators: Indicator[], t): string => {
  let stars = ''
  const names: string[] = []

  if (indicators && Array.isArray(indicators)) {
    indicators.forEach((indicator) => {
      if (indicator?.image === 'estrellas') {
        const indicatorString = indicator?.name || ''
        const [completedStars, totalStars] = indicatorString.split('/')
        stars = t('ContentStars', { count: completedStars, total: totalStars })
      } else if (indicator?.image !== 'estrellas') {
        names.push(indicator?.name || '')
      }
    })
  }

  const namesText = names.length > 0 ? names.join(', ') : ''
  return stars && namesText ? `${namesText}, ${stars}` : stars || namesText
}

/** Función que recorre los items e identifica el estado de cada uno,
 * para agregarlo a la descripción de aria-label
 */
export const getItemState = (link, answerId) => {
  let text = ''
  if (link.status?.detail === 'completed') {
    text = 'Completado'
  } else if (link.status?.style === 'on' || link.id! === Number(answerId)) {
    text = 'En progreso'
  } else {
    text = ''
  }

  return text
}

/** Función que recorre los items e identifica el rol de cada uno */
export const getItemRole = (state, link, activeNavigation): string => {
  let role = ''
  if (
    (link.Childrens?.length === 0 && link.href !== '') ||
    (activeNavigation && state !== 'En progreso')
  ) {
    role = 'link'
  } else if (state === 'Completado' || state === 'En progreso') {
    role = 'menuitem'
  } else {
    role = 'button'
  }
  return role
}

/** Función que ejecuta las demás funciones que retornan data de accesibilidad */
export const getMenuItemProps = (link, t, answerId, activeNavigation) => {
  const indicators = link.indicators || []
  const indicatorsName = getIndicatorsText(indicators, t)
  const state = getItemState(link, answerId)
  const ariaLabelText =
    state === '' ? `${link.name}, ${indicatorsName}` : ` ${state}: ${link.name}, ${indicatorsName}`
  const role = getItemRole(state, link, activeNavigation)

  return { indicators, indicatorsName, state, ariaLabelText, role }
}
