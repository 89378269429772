/**
 * El factor permite obtener números desordenados con el factor de
 * conversión sin considerar el 0.
 * El rango será entre -0.69 y 0.31.
 */
const CONVERSION_FACTOR = 0.69

/**
 * Recibe el array de opciones de un reorder y lo devuelve desordenado.
 *
 * Esto se extrajo desde https://gitlab.com/eclass/v1prod-node/-/blob/63bab58fe36cdf049825120203d0cd906c12caf0/src/schema/Platform/Mutation/Evaluation/Create/Resolve/setAnswers/setReorder.js
 */
export const getOptionsRandom = (options) => {
  // Obtengo opciones que no esten fijas y las reordeno
  const fixedOptionsRandom = options
    .filter(({ draggable }) => draggable === '1')
    .sort(() => Math.random() - CONVERSION_FACTOR)

  // recorro array original reemplazando las no fijas por el array de las ordenadas random
  let randIndex = 0
  const ArrOptions = options.map((option, index) => {
    if (option.draggable === '1') {
      return fixedOptionsRandom[randIndex++]
    }
    return option
  })

  return ArrOptions
}
