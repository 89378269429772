import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  .Question__Content {
    max-width: 100%;
    .Article__video {
      margin-top: 0px !important;
    }
  }
`
