import styled from 'styled-components/macro'

export const Style = {
  BoxTerms: styled.div`
    max-height: 400px;
    overflow: auto;
    padding-right: 10px;
    margin-bottom: 20px !important;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 9px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 10px;
      border: 0px solid #ffffff;
      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    &::-webkit-scrollbar-track {
      background-color: #eeee;
    }

    @media screen and (max-width: 720px) {
      max-height: inherit;
      overflow: none;
      border: none;
      padding: 0px;
    }

    h2 {
      font-size: 18.72px !important;
      font-weight: bold;
    }
  `,
  Buttons: styled.div`
    display: flex;
    button {
      padding-left: 20px;
      padding-right: 20px;
    }
    .button__container {
      width: auto;
    }
    @media screen and (max-width: 530px) {
      flex-direction: column;
      button {
        margin-top: 10px;
      }
      .button__container {
        line-height: 18px;
      }
    }
  `,
}
