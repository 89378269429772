import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactHtmlParser from 'react-html-parser'
import { useHistory, useLocation } from 'react-router-dom'

import { Modal } from 'app/Components/UI'
import { flashSuccess } from 'assets/svg'
import { Style } from './style'

export const ConfirmationModalAlert = () => {
  const { t } = useTranslation()
  const [isOpen, setModal] = useState(true)
  const history = useHistory()
  const location: any = useLocation()

  // parametro que paso desde la vista confirmation/academic-labor
  const onlyShow = location.state && location.state.confirmationSuccess

  if (
    !isOpen ||
    history.action === 'POP' ||
    history.action === 'REPLACE' ||
    (history.action === 'PUSH' && !onlyShow)
  ) {
    return null
  }

  return (
    <>
      <Style.ModalWrapper />
      <Modal
        type={'full'}
        isOpen={isOpen}
        fade={true}
        btnClose={false}
        className="ConfirmationModalAlert"
        footer={
          <Style.AlertFooter onClick={() => setModal((prevState) => !prevState)}>
            {t('ProfileGoToMyCourses')}
          </Style.AlertFooter>
        }
      >
        <Style.AlertContent>
          <img className="image" src={flashSuccess} alt="Success" />
          <Style.AlertTitle>{ReactHtmlParser(t('ProfileSavedInfoStrong'))}</Style.AlertTitle>
          <Style.AlertMainText>{t('ProfileNowThatYouConfirmed')}</Style.AlertMainText>
        </Style.AlertContent>
      </Modal>
    </>
  )
}
