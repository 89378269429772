import './polyfill'
import './Config/devTools'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client/react'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from '@eclass/ui-kit'

// @App Dependencies
import AppRoutes from './Config/routes/'
import config from './Config/domains/'
import i18n from './app/I18n'
import { client } from 'app/Apollo/config'

import { store, persistor } from './app/Redux/'

// solution iframe in develop
import './index.css'
import reportWebVitals from 'reportWebVitals'
import { Branding } from 'app/Components/Branding'
import { Zendesk } from 'app/Components/Zendesk'

const AppWrapper = () => (
  <ChakraProvider theme={theme} resetCSS={false}>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Branding config={config} />
          <BrowserRouter forceRefresh={config.appMobile}>
            <I18nextProvider i18n={i18n}>
              <Zendesk />
              <AppRoutes />
            </I18nextProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  </ChakraProvider>
)

// @Render
// Se debe actualizar reactstrap para que pueda ser compatible con react 17-18
// Se debe de migrar componentes con class a hooks para compatibilidad react 18
ReactDOM.render(
  // <React.StrictMode>
  <AppWrapper />,
  // </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
