import { gql, useMutation, MutationHookOptions } from '@apollo/client'
// import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const createContentModuleVR = gql`
  ${STATUS_FIELDS}
  mutation createContentModuleVR($createContentModuleVRInput: createContentModuleVRInput!) {
    createContentModuleVR(input: $createContentModuleVRInput) {
      status {
        ...StatusFields
      }
    }
  }
`

export type createContentModuleVRPayload = any // Api.createContentModuleVRPayload

export type createContentModuleVRInput = any // Api.createContentModuleVRInput

interface createContentModuleVRPayloadApollo {
  createContentModuleVR: createContentModuleVRPayload
}

interface createContentModuleVRInputApollo {
  createContentModuleVRInput: createContentModuleVRInput
}

export const useMutationcreateContentModuleVR = (
  options?: MutationHookOptions<
    createContentModuleVRPayloadApollo,
    createContentModuleVRInputApollo
  >
) => {
  return useMutation<createContentModuleVRPayloadApollo, createContentModuleVRInputApollo>(
    createContentModuleVR,
    options
  )
}
