import { gql, useQuery, QueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

export const TEST_RESULT = gql`
  ${STATUS_FIELDS}
  query TestResult($TestResultInput: TestResultInput!) {
    TestResult(input: $TestResultInput) {
      status {
        ...StatusFields
      }
      Test {
        id
        title
      }
      scores {
        total
        correct
      }
      feedback {
        text
        embeds {
          id
          name
          configurations
          typeId
        }
      }
      levels {
        name
        description
        recommended
        canSelect
      }
      showButton
      username
    }
  }
`

// @ts-ignore
export type TestResultPayload = Api.TestResultPayload
// @ts-ignore
export type TestResultInput = Api.TestResultInput

interface TestResultPayloadApollo {
  TestResult: TestResultPayload
}

interface TestResultPayloadInputApollo {
  TestResultInput: TestResultInput
}

export const useQueryTestResult = (
  options?: QueryHookOptions<TestResultPayloadApollo, TestResultPayloadInputApollo>
) => {
  return useQuery<TestResultPayloadApollo, TestResultPayloadInputApollo>(TEST_RESULT, options)
}
