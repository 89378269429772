export const CourseIcon = (props) => {
  return (
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" {...props}>
      <g
        id="origen-pregunta-/-curso"
        transform="translate(0.000000, -1.000000)"
        fill="#B0CFE0"
        fillRule="nonzero"
      >
        <g id="save-for-later" transform="translate(0.000000, 1.000000)">
          <path
            d="M8.1,0 L1,0 C0.44771525,0 0,0.44771525 0,1 L0,13 C0,13.5522847 0.44771525,14 1,14 L13,14 C13.5522847,14 14,13.5522847 14,13 L14,1 C14,0.44771525 13.5522847,7.68579428e-16 13,0 L8.1,0 L8.1,0 Z M12,12 L2,12 L2,9 L12,9 L12,12 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  )
}
