import { branding } from 'assets/img'
import { ConfigType } from './types'
import { getDomainConfig } from '../getDomainConfig'

type Domains = {
  /**
   * @deprecated Progresivamente quitar los domains en duro y pasarlos a PlatBe
   */
  [url: string]: ConfigType
}
/**
 * Listado de Dominios
 */
export const domains: Domains = {
  default: {
    appMobile: false,
    campusId: 0,
    defaultLogo: 'eclass',
    pdfPrivacy: '',
    programId: 0,
    showHeaderLogo: true,
    byEclass: true,
    coursesCampus: false,
    branding: {
      text: '',
      textColor: 'white',
      day: branding.day,
      night: branding.night,
      login: branding.login,
      logos: [],
      loginTypes: [],
    },
    i18n: 'es-419',
    keyZendesk: '200aa6d0-e959-4b40-873e-6faf460a1df9',
    hideChangePassword: '',
    loginRedirect: '',
    urlRedirect: '',
    labelRedirect: '',
  },
  /**
   * Campus Demo v8 - Programa Demo v8
   * Es de uso interno.
   */
  'demov8.eclass.io': {
    campusId: 6,
    programId: 1956,
  },
  'deploy-translations-spa.eclass.com': {
    campusId: 6,
    programId: 1956,
  },
  'deploy-translations-eng.eclass.com': {
    campusId: 847,
    programId: 1957,
  },
  'deploy-translations-por.eclass.com': {
    campusId: 745,
    programId: 1958,
  },
  /** */
  'inglesapp.eclass.io': {
    campusId: 476,
    programId: 1956,
    appMobile: true,
  },
  'dev-inglesapp.eclass.io': {
    campusId: 99,
    programId: 1956,
    appMobile: true,
  },
  'platform.eclass.io': {
    campusId: 476,
    programId: 1956,
  },
  'openuandes.eclass.com': {
    campusId: 803,
    programId: 1837,
    showHeaderLogo: false,
    defaultLogo: 'uandes',
  },
  'open.eclass.com': {
    campusId: 815,
    programId: 1860,
  },
  'aguasandinasv8.eclass.com': {
    campusId: 259,
    programId: 1950,
  },
  'cajalosandesv8.eclass.com': {
    campusId: 250,
    programId: 1953,
  },
  'upla.eclass.com': {
    campusId: 30,
    programId: 1954,
  },
  'autonoma.eclass.com': {
    campusId: 641,
    programId: 1422,
  },
  'sjfarmsv8.eclass.com': {
    campusId: 765,
    programId: 1952,
  },
  'hunterdouglasv8.eclass.com': {
    campusId: 690,
    programId: 1955,
  },
  'campusinglesv8.eclass.com': {
    campusId: 24,
    programId: 1941,
  },
  'methanex.eclass.com': {
    campusId: 831,
    programId: 1925,
  },
  'compas.eclass.com': {
    campusId: 845,
    programId: 1949,
  },
  'mowi.eclass.com': {
    campusId: 712,
    programId: 1959,
  },
  'uddccp.eclass.com': {
    campusId: 50,
    programId: 1964,
  },
  'uddsantiago.eclass.com': {
    campusId: 50,
    programId: 1988,
  },
  'alumnosjpedianestlev8.eclass.com': {
    campusId: 743,
    programId: 1965,
  },
  'campuseclassv8.eclass.com': {
    campusId: 57,
    programId: 1976,
  },
  'agrosuperv8.eclass.com': {
    campusId: 583,
    programId: 1960,
  },
  'cornershopv8.eclass.com': {
    campusId: 722,
    programId: 1962,
  },
  'tanner.eclass.com': {
    campusId: 429,
    programId: 1986,
  },
  'programaseclassv8.eclass.com': {
    campusId: 484,
    programId: 1990,
  },
  'v8.diplomasunabonline.cl': {
    campusId: 800,
    programId: 2005,
    keyZendesk: '',
    showHeaderLogo: false,
  },
  'uniandesv8.eclass.com': {
    campusId: 123,
    programId: 1987,
  },
  'mastologia.eclass.com': {
    campusId: 770,
    programId: 2013,
  },
  'bboschv8.eclass.com': {
    campusId: 547,
    programId: 2008,
  },
  'nielsen.eclass.com': {
    campusId: 693,
    programId: 2014,
  },
  'tensuandes.eclass.com': {
    campusId: 498,
    programId: 2021,
  },
  'nexus.eclass.com': {
    campusId: 189,
    programId: 2026,
  },
  'carozziv8.eclass.com': {
    campusId: 497,
    programId: 2030,
  },
  'inacap.eclass.com': {
    campusId: 672,
    programId: 2042,
  },
  'inacapv8.eclass.com': {
    campusId: 672,
    programId: 2042,
  },
  'hdsv8.eclass.com': {
    campusId: 762,
    programId: 2022,
  },
  'mutualcapacitacionv8.eclass.com': {
    campusId: 529,
    programId: 1872,
  },
  'aiep.eclass.com': {
    campusId: 854,
    programId: 1994,
    showHeaderLogo: false,
    defaultLogo: 'empty',
  },
  'duocv8.eclass.com': {
    campusId: 398,
    programId: 2064,
  },
  'issworld.eclass.com': {
    campusId: 505,
    programId: 2061,
  },
  'clarochilev8.eclass.com': {
    campusId: 134,
    programId: 2081,
  },
  'columbus.eclass.com': {
    campusId: 880,
    programId: 2086,
  },
  'despegamipe.eclass.com': {
    campusId: 882,
    programId: 2088,
  },
  'bancodelarepublicav8.eclass.com': {
    campusId: 294,
    programId: 2087,
  },
  'fifv8.eclass.com': {
    campusId: 560,
    programId: 2090,
  },
  'chilematv8.eclass.com': {
    campusId: 737,
    programId: 2091,
  },
  'programasadvancedv8.eclass.com': {
    campusId: 876,
    programId: 2097,
  },
  'powerclassgalaxynote20v8.eclass.com': {
    campusId: 867,
    programId: 2050,
  },
  'usatv8.eclass.com': {
    campusId: 704,
    programId: 2102,
  },
  'bancodelanacionv8.eclass.com': {
    campusId: 449,
    programId: 2117,
  },
  'programaempleabilidadv8.eclass.com': {
    campusId: 891,
    programId: 2121,
  },
  'metsov8.eclass.com': {
    campusId: 723,
    programId: 2123,
  },
  'agrichile.eclass.com': {
    campusId: 888,
    programId: 2124,
  },
  'preumonteclaro.eclass.com': {
    campusId: 875,
    programId: 2068,
  },
  'ultramar.eclass.com': {
    campusId: 394,
    programId: 2129,
  },
  'pernodricard.eclass.com': {
    campusId: 481,
    programId: 2131,
  },
  'oneabbott.eclass.com': {
    campusId: 839,
    programId: 2051,
  },
  'vidagas.eclass.com': {
    campusId: 822,
    programId: 2138,
  },
  'marykay.eclass.com': {
    campusId: 813,
    programId: 1856,
  },
  'camaradecomerciodecucuta.eclass.com': {
    campusId: 760,
    programId: 2142,
  },
  'sierracolenergyv8.eclass.com': {
    campusId: 827,
    programId: 2140,
  },
  'universidaddechilev8.eclass.com': {
    campusId: 414,
    programId: 2135,
  },
  'uandesv8.eclass.com': {
    campusId: 498,
    programId: 2077,
  },
  'cursosuandesv8.eclass.com': {
    campusId: 498,
    programId: 2077,
  },
  'mintlab.eclass.com': {
    campusId: 577,
    programId: 2150,
  },
  'finning.eclass.com': {
    campusId: 401,
    programId: 2151,
  },
  'hortifrut.eclass.com': {
    campusId: 816,
    programId: 2158,
  },
  'epiroc.eclass.com': {
    campusId: 894,
    programId: 2146,
  },
  'udd.eclass.com': {
    campusId: 890,
    programId: 2119,
  },
  'hcuchv8.eclass.com': {
    campusId: 600,
    programId: 2159,
  },
  'gildemeisterv8.eclass.com': {
    campusId: 116,
    programId: 2067,
  },
  'campusuandesv8.eclass.com': {
    campusId: 738,
    programId: 2155,
  },
  'providav8.eclass.com': {
    campusId: 147,
    programId: 2167,
  },
  'prodalamv8.eclass.com': {
    campusId: 344,
    programId: 2163,
  },
  'bancobicev8.eclass.com': {
    campusId: 246,
    programId: 2170,
  },
  'consorciov8.eclass.com': {
    campusId: 138,
    programId: 2162,
  },
  'bancobasav8.eclass.com': {
    campusId: 860,
    programId: 2017,
  },
  'aesgener.eclass.com': {
    campusId: 563,
    programId: 2144,
  },
  'clustergas.eclass.com': {
    campusId: 610,
    programId: 2174,
  },
  'notco.eclass.com': {
    campusId: 899,
    programId: 2164,
  },
  'uach.eclass.com': {
    campusId: 820,
    programId: 2173,
  },
  'uddcolaboradores.eclass.com': {
    campusId: 901,
    programId: 2179,
  },
  'cfit.eclass.com': {
    campusId: 805,
    programId: 2145,
  },
  'bcentralv8.eclass.com': {
    campusId: 832,
    programId: 2166,
  },
  'academiapersonalv8.eclass.com': {
    campusId: 893,
    programId: 2136,
  },
  'cardif.eclass.com': {
    campusId: 355,
    programId: 2180,
  },
  'andinav8.eclass.com': {
    campusId: 178,
    programId: 2148,
  },
  'pavco_wavin.eclass.com': {
    campusId: 635,
    programId: 2185,
  },
  'lipigas.eclass.com': {
    campusId: 113,
    programId: 2190,
  },
  'cgcompass.eclass.com': {
    campusId: 568,
    programId: 2191,
  },
  'porsche.eclass.com': {
    campusId: 416,
    programId: 2196,
  },
  'gore.eclass.com': {
    campusId: 755,
    programId: 2184,
  },
  'stellantis.eclass.com': {
    campusId: 906,
    programId: 2203,
  },
  'afc.eclass.com': {
    campusId: 905,
    programId: 2200,
  },
  'bciv8.eclass.com': {
    campusId: 70,
    programId: 2212,
  },
  'mcdonalds.eclass.com': {
    campusId: 502,
    programId: 2213,
  },
  'principal.eclass.com': {
    campusId: 390,
    programId: 2218,
  },
  'corcoran.eclass.com': {
    campusId: 908,
    programId: 2217,
  },
  'indumotora.eclass.com': {
    campusId: 8,
    programId: 2226,
  },
  'fundacionpuente.eclass.com': {
    campusId: 598,
    programId: 2232,
  },
  'conchaytoro.eclass.com': {
    campusId: 669,
    programId: 2240,
  },
  'mtt.eclass.com': {
    campusId: 593,
    programId: 2197,
  },
  'achsv8.eclass.com': {
    campusId: 12,
    programId: 2246,
  },
  'gnlquintero.eclass.com': {
    campusId: 655,
    programId: 2258,
  },
  'toyota.eclass.com': {
    campusId: 99,
    programId: 2264,
  },
  'implementosperu.eclass.com': {
    campusId: 913,
    programId: 2272,
  },
  'flsmidthv8.eclass.com': {
    campusId: 588,
    programId: 2275,
  },
  'universidaditalcolv8.eclass.com': {
    campusId: 914,
    programId: 2278,
  },
  localhost: {
    // campus demo (6)
    campusId: 745,
    // programa demo v8 (1956)
    programId: 1958,
  },
}

const domainConfig = (): ConfigType => {
  const hostname = window.location.hostname
  const decodedConfig = getDomainConfig()

  return {
    ...domains.default,
    // deprecated, progresivamente quitar los domains en duro y pasarlos a PlatBe
    ...domains[hostname],
    ...decodedConfig,
  }
}

/**
 * Retorna la configuración de un dominio
 * @example
 *
 * ```js
 *   const config = domainConfig()
 * ```
 * @returns {ConfigType} Configuración del dominio
 */
export default domainConfig()
