export const Folio = (props) => {
  return (
    <g {...props} id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="I-G/-contenido" fill="#B0CFE0" fillRule="nonzero">
        <g id="Group">
          <rect id="Rectangle" x="0" y="0" width="2" height="16" />
          <rect id="Rectangle" x="14" y="0" width="2" height="16" />
          <rect id="Rectangle" x="3" y="0" width="2" height="11" />
          <rect id="Rectangle" x="11" y="0" width="2" height="11" />
          <polygon id="Path" points="10 11 6 11 6 0 10 0 10 0" />
        </g>
      </g>
    </g>
  )
}
