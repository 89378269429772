import React from 'react'
import { useTranslation } from 'react-i18next'
import { MeetEclassIcon, FaqIcon } from 'app/Components/UI/Icons'
import * as Style from './style'
import { Props } from './types'
import { AttentionTimeSchedule } from './CoordinatorBox/Components/AttentionTimeSchedule'

/**
 * Encabezado de la caja
 * LLeva el icono y la descripción
 */
export const Header = ({ type, title, attentionTime, className }: Props.Header) => {
  const { t } = useTranslation()
  return (
    <Style.Header data-testid="BoxHeader" className={className}>
      <figure>{type === 'COORDINATOR' ? <MeetEclassIcon /> : <FaqIcon />}</figure>
      <Style.Title>
        <strong>{t(title)}</strong>
      </Style.Title>
      {attentionTime ? <AttentionTimeSchedule /> : <></>}
    </Style.Header>
  )
}
