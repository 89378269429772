import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'
import { useParams, useHistory } from 'react-router-dom'
import { PersonAgreeTermConditionInput } from '@eclass/api'

import PersonAgreeTermCondition from 'app/Redux/Actions/Users/PersonAgreeTermCondition'
import { Modal } from 'app/Components/UI'
import { RequestError } from 'app/Components'
import { Style } from './style'
import { Store } from 'app/Views/types'
import { RouteParams } from 'Config/routes/types'
import { BtnPrimary, BtnSecondary } from '@eclass/ui-kit'
/**
 * Componente que dibuja el modal de terminos y condiciones
 * @example
 * <ModalTerms/>
 */
export const ModalTerms = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoadingAcept, setLoadingAcept] = useState(false)
  const [isLoadingNoAcept, setLoadingNoAcept] = useState(false)
  const [isOpen, setModal] = useState(true)
  const {
    courses: { getCourseInfo: courseInfo },
  } = useSelector((state: Store) => state)
  const { programId, studentId, courseId } = useParams<RouteParams>()
  const noParams = !programId && !studentId && !courseId
  const noTerms = !courseInfo.Terms || !courseInfo.Terms.length
  if (noParams || noTerms) {
    return null
  }
  /**
   * Guarda la aceptación/rechazo de los Terminos y condiciones del Curso.
   */
  const saveTerms = async (agree: boolean) => {
    // @ts-ignore
    const courseTerms = Array.from(courseInfo?.Terms!)
    const termConditionIds = courseTerms.map(({ id }) => id!)
    if (agree) {
      setLoadingAcept(true)
    } else {
      setLoadingNoAcept(true)
    }
    const params: PersonAgreeTermConditionInput = {
      studentId: Number(studentId),
      courseId: Number(courseId),
      termConditionIds,
      agree,
    }
    const resolve = await dispatch<any>(PersonAgreeTermCondition(params))
    if (!resolve || !resolve.status.success) {
      /**
       * Handling errors
       * @todo ¿Que pasa cuando el error es que ya los aceptó? Deberíamos forzar
       * actualizar caché.
       */
      RequestError.handle(dispatch, resolve)
    } else {
      dispatch({ type: 'COURSE_INFO_UPDATE_TERMS' })
      history.push(resolve.url)
    }
  }

  // @ts-ignore
  const terms = Array.from(courseInfo?.Terms!)

  return (
    <Modal
      type={'full'}
      isOpen={true}
      toggle={() => setModal(!isOpen)}
      btnClose={false}
      header={t('ContentTermsConditions')}
    >
      <p>{t('ContentShortly')}</p>
      <Style.BoxTerms>{terms.map((term) => ReactHtmlParser(term.text!))}</Style.BoxTerms>
      <Style.Buttons>
        <BtnPrimary
          onClick={() => saveTerms(true)}
          isLoading={isLoadingAcept}
          disabled={isLoadingNoAcept}
          m="0 24px 0 0"
        >
          {t('ContentIAccept')}
        </BtnPrimary>

        <BtnSecondary
          onClick={() => saveTerms(false)}
          isLoading={isLoadingNoAcept}
          disabled={isLoadingAcept}
        >
          {t('ContentDontAccept')}
        </BtnSecondary>
      </Style.Buttons>
    </Modal>
  )
}
