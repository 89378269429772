import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import userUpdateProfilePic from 'app/Redux/Actions/Users/updateProfilePicture'
import { Avatar } from 'app/Components/UI'

import { useData } from './useData'

import * as Style from './style'
import { BtnPrimary } from '@eclass/ui-kit'

/**
 * Componente export const ProfilePicture = ({}) => {
 que muestra el avatar y el botón de seleccionar imagen
 * @example
 * <export const ProfilePicture = ({}) => {
 name={'Juanita Bonita'}/>
 */
export const ProfilePicture = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { data, setData } = useData()

  const uploadImage = async (e: any) => {
    const file = e.target.files[0]

    /**
     * @todo faltan types del dispatch.
     */
    setLoading(true)
    const status = await dispatch<any>(
      userUpdateProfilePic(
        {
          file: {
            file: e.target.files[0],
            // @ts-ignore
            type: 'ANSWER',
            name: e.target.files[0].name,
            id: 0,
          },
        },
        file
      )
    )

    if (status.status.success) {
      setLoading(false)
      setData({
        picture: status.person.picture,
      })
    }
  }

  /**
   * @todo faltan las traducciones.
   */
  return (
    <Style.Wrapper>
      <div className="Header">
        <div className="UploadHeader">
          <Avatar
            fullName={data?.fullName || 'No Name'}
            picture={data?.picture || ''}
            userId={data?.id ? Number(data.id) : 0}
            size={100}
            fontSize={30}
          />
          <Style.FileZone>
            <h5>{t('ProfileProfilePic')}</h5>
            <BtnPrimary isLoading={loading} size="small">
              {data!.picture === '' ? t('ProfileSelectImage') : t('ProfileReplacePic')}
              <input
                type="file"
                accept="image/x-png,image/gif,image/jpeg"
                onChange={uploadImage}
              ></input>
            </BtnPrimary>
            <p>{t('ProfileDimensions')}</p>
          </Style.FileZone>
        </div>
      </div>
    </Style.Wrapper>
  )
}

export default ProfilePicture
