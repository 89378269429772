import React from 'react'
import { DropdownItem } from 'reactstrap'
import scrollmove from '@eclass/scrollmove'

import { Icon } from 'app/Components/UI/Icons'
import { UIVars } from 'app/Components/UI'
import { getQuestionStatus } from 'app/Views/Tests/helpers/questionStatus'
import { useTranslation } from 'react-i18next'
import { IconProps, ItemFeedbackProps } from './Types'
import * as ItemStyle from './ItemStyle'

const MenuItemFeedBack = ({
  answer: {
    state,
    config: { id },
  },
  numberQuestion,
}: ItemFeedbackProps) => {
  const { t } = useTranslation()
  const questionStatus: any = getQuestionStatus(t, state)

  const SetIcon = ({ link, index }: IconProps) => {
    if (link) {
      const common = {
        fill: '#fff',
        width: 16,
      }
      switch (link) {
        case t('ResourceCorrect'):
          return <Icon {...common} name="circularCheck" />
        case t('ResourceIncorrect'):
          return <Icon {...common} name="circularError" />
        case t('ResourceOmitted'):
          return <Icon {...common} name="circularNeutral" />
        case t('ResourceAnswered'):
          return null
        default:
          return index + 1
      }
    } else {
      return <Icon fill={UIVars.setColors.veryLightPink} name="blocked" />
    }
  }

  return (
    <ItemStyle.Feedback key={numberQuestion}>
      <DropdownItem
        className={questionStatus.className}
        onClick={() => scrollmove(`#question-${id}`)}
      >
        <span className="flex">
          <span>{`${t('ResourceQuestion')} ${numberQuestion + 1}`}</span>
          <span className={'status'}>
            <SetIcon link={questionStatus.status} index={numberQuestion} />
            <span className="Question__Status">{questionStatus.status}</span>
          </span>
        </span>
      </DropdownItem>
    </ItemStyle.Feedback>
  )
}

export default MenuItemFeedBack
