// @external dependencies
import { useTranslation } from 'react-i18next'

// @dependencies
import { useRedux } from './useRedux'
import { Modal as ModalUI, Button, Icon, UIVars } from 'app/Components/UI'
import { Props, Types } from './types'
import { ModalUnit as Style } from './style'

/**
 * Modal que contienes el listado de recursos a descargar
 * @param unit {Types.UnitBlock}
 * @param getResources {function}
 */
export const ModalUnit = ({ unit, getResources }: Props.ModalUnit) => {
  const { t } = useTranslation()
  const { modal, setModal } = useRedux()
  const show = modal === 'UNIT'

  if (!unit || !show) {
    return null
  }

  // listado de recursos que se pueden descargar
  const resources: Types.unitItem[] = Array.from(unit?.items!).filter(
    (item: Types.unitItem) => item.downloadPdf
  )

  const handleClose = () => setModal('NULL')

  return (
    <ModalUI
      isOpen={true}
      toggle={handleClose}
      closeModal={handleClose}
      type="full"
      header={t('ResourcePDF')}
    >
      <Style data-testid="modalDownload">
        <p>
          <strong>{t('PDFTheFollowing')}</strong>
        </p>
        <ul>
          {resources.map((resource: Types.unitItem) => (
            <li key={resource.id} data-testid="resourceName">
              {resource.name}
            </li>
          ))}
        </ul>
        <p className="textInfo">
          <Icon name="info" fill={UIVars.setColors.icons} height={16} width={16} />
          {t('PDFRemember')}
        </p>
        <footer>
          <Button className="confirmation" onClick={getResources}>
            <Icon name="download" fill={UIVars.setColors.whiteThree} height={16} width={14} />
            {t('PDFOk')}
          </Button>
          <Button text={t('ContentCanceled')} state="info" onClick={handleClose} />
        </footer>
      </Style>
    </ModalUI>
  )
}
