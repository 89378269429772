import { Types } from '../types'

interface getOptionParams {
  FormContext: any
  options: Types.select[]
  input: string
}

/**
 * Si es que el input a setear tiene el valor por defecto de la base de datos,
 * obtengo sus datos correspondientes desde el listado de options
 */
export const getOption = ({ FormContext, options, input }: getOptionParams) => {
  if (FormContext.getValues()[input]) {
    FormContext.setValue(
      input,
      options.find((option) => option.value === FormContext.getValues()[input])
    )
  }
}
