// @External Dependencies
import React, { Component } from 'react'

/* Editor wysiwyg */
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw } from 'draft-js'
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
/* Editor wysiwyg */

import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import Button from './Button'

// @Component
class DraftEditor extends Component {
  state = {
    btnDisabled: true,
    editorState: EditorState.createEmpty(),
  }

  onEditorStateChange = (editorState) => {
    const rawMessage = convertToRaw(editorState.getCurrentContent())
    if (this.props.onChange) {
      this.props.onChange(rawMessage.blocks[0].text)
    }

    this.setState({
      btnDisabled: !rawMessage.blocks[0].text,
      editorState,
    })
  }

  sendMessage = () => {
    const { editorState } = this.state

    const rawMessage = convertToRaw(editorState.getCurrentContent())
    const htmlMessage = draftToHtml(rawMessage)
    const messageIsEmpty = rawMessage.blocks[0].text === ''

    if (!messageIsEmpty) {
      this.setState({
        editorState: EditorState.createEmpty(),
        btnDisabled: true,
      })

      // Acá paso el contenido del editor con todo su html hacia el padre
      this.props.getMessage(htmlMessage.trim())
    }
  }

  render() {
    const { editorState, btnDisabled } = this.state
    const {
      btnState,
      btnText,
      btnCancel,
      children,
      className,
      disabled,
      editorClassName,
      label,
      options,
      placeholder,
      wrapperClassName,
    } = this.props

    return (
      <Wrapper className={`DraftEditor__component ${className}`}>
        {label && <label htmlFor="questionDescription">Descripción de la pregunta</label>}
        <Editor
          editorState={editorState}
          wrapperClassName={wrapperClassName}
          editorClassName={editorClassName}
          onEditorStateChange={this.onEditorStateChange}
          placeholder={placeholder}
          toolbar={{
            options: ['inline'],
            inline: {
              options,
            },
          }}
        />
        {children}
        <Button
          disabled={disabled || btnDisabled}
          state={btnState}
          text={btnText}
          onClick={this.sendMessage}
        />
        <Button
          text={btnCancel}
          className="cancel"
          testid="cancel-button"
          onClick={this.closeModal}
        />
      </Wrapper>
    )
  }
}

// @Proptypes
DraftEditor.propTypes = {
  btnState: PropTypes.string,
  btnText: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  editorClassName: PropTypes.string,
  getMessage: PropTypes.func.isRequired, // Este prop pasa hacia el padre el mensaje del editor con todo su html
  label: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  wrapperClassName: PropTypes.string,
}

DraftEditor.defaultProps = {
  btnState: 'primary', // Estados del botón
  btnText: 'Enviar mensaje', // Texto del botón
  className: '', // Clases extras para el Wrapper de este componente
  children: '', // Cualquier contenido que quieran pasarle al componente. Se dibujará entre el editor y el botón
  disabled: false, // Disabled para el botón
  editorClassName: 'DraftEditor', // Clase para el textarea del editor
  label: '', // Label para el componente
  options: ['bold', 'italic', 'underline'], // Opciones base del toolbar para el editor
  placeholder: 'Escribe una descripción...', // Placeholder del editor
  wrapperClassName: 'DraftWrapper', // Clase para todo el editor, incluyendo toolbar
}

export default DraftEditor

// @Styles
const Wrapper = styled.section`
  width: 100%;

  .DraftWrapper {
    background: white;
    border: 2px solid #c9c9d3;
    border-radius: 5px;
    margin-bottom: 15px;

    .DraftEditor {
      padding: 0 15px;
    }
  }
`
