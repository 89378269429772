import { gql, useQuery, QueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const GET_RESOURCE = gql`
  ${STATUS_FIELDS}
  query getResource($ResourceViewInput: ResourceViewInput!) {
    getResource(input: $ResourceViewInput) {
      status {
        ...StatusFields
      }
      Resource {
        header {
          title
          description
          boxType
          source {
            url
            poster
          }
        }
        config {
          duration
          download
          comments
          isRated
          License {
            name
            detail
            icon
            LicenseType {
              name
            }
          }
        }
        content {
          text
          audioUrl
          footnotes
          timelines {
            id
            title
            isPortrait
            Milestones {
              label
              text
              sortOrder
            }
          }
          embeds {
            id
            name
            configurations
            typeId
          }
        }
      }
      Menu {
        Links {
          id
          name
          href
          indicators {
            id
            name
            image
            obtained
            max
            startDate
          }
          status {
            name
            detail
            style
            enabled
          }
          Childrens {
            id
            name
            href
            status {
              name
              detail
              style
              enabled
            }
            indicators {
              id
              name
              image
              obtained
              max
            }
          }
        }
        Navigation {
          prev {
            href
            name
            status {
              detail
              style
              enabled
            }
          }
          next {
            href
            name
            status {
              detail
              style
              enabled
            }
          }
        }
        Unit {
          id
          name
          title
          downloadPdf
        }
      }
      Topics {
        colour
        initials
        title
        description
        LearningGoals {
          title
          description
        }
      }
      Evaluations {
        qualification {
          valueFormatted
          status {
            name
            detail
            style
          }
        }
        config {
          id
          testId
          hasTime
          timeRemaining
          state
          showAnswers
          score {
            total
            obtained
          }
          repeat {
            hideButton
            criterial
            total
            attempt
          }
          startDate
          endDate
          completedDate
          daysRemaining
          folio
          completed
          hasRevision
          showFeedback
          canAnswer
          showQualification
        }
        header {
          name
          embedTitle
          Type {
            id
            name
          }
        }
        Levels {
          id
          name
          Stages {
            id
            name
            Answers {
              instructions
              content
              answer
              embeds {
                id
                name
                configurations
                typeId
              }
              config {
                id
                evaluationId
                levelId
                stageId
                mandatory
                sortOrder
                ItemType {
                  id
                  name
                }
              }
              feedback {
                correct
                score {
                  total
                  obtained
                }
                skipped
                comment
                text
                status {
                  enabled
                  name
                  detail
                  style
                }
              }
            }
          }
        }
      }
    }
  }
`

export type GetResourcePayload = Api.ResourceViewPayload

export type GetResourceInput = Api.ResourceViewInput

interface GetResourcePayloadApollo {
  getResource: GetResourcePayload
}

interface GetResourceInputApollo {
  ResourceViewInput: GetResourceInput
}

export const useQueryGetResource = (
  options?: QueryHookOptions<GetResourcePayloadApollo, GetResourceInputApollo>
) => {
  return useQuery<GetResourcePayloadApollo, GetResourceInputApollo>(GET_RESOURCE, options)
}
