import { gql, useQuery, QueryHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const GET_PROGRAM_NOTICES = gql`
  ${STATUS_FIELDS}
  query getProgramNotices($ProgramNoticeInput: ProgramNoticeInput!) {
    ProgramNotices(input: $ProgramNoticeInput) {
      status {
        ...StatusFields
      }
      ProgramNotices {
        subject
        notice
        description
        noticeType
      }
    }
  }
`

export type GetProgramNoticesPayload = Api.ProgramNoticesPayload

export type GetProgramNoticesInput = Api.ProgramNoticesInput

interface GetProgramNoticesPayloadApollo {
  ProgramNotices: GetProgramNoticesPayload
}

interface GetProgramNoticesInputApollo {
  ProgramNoticeInput: GetProgramNoticesInput
}

export const useQueryGetProgramNotices = (
  options?: QueryHookOptions<GetProgramNoticesPayloadApollo, GetProgramNoticesInputApollo>
) => {
  return useQuery<GetProgramNoticesPayloadApollo, GetProgramNoticesInputApollo>(
    GET_PROGRAM_NOTICES,
    options
  )
}
