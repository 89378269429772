// @External Dependencies
import axios from 'axios'

// @Dependencies
import { PersonAddressesInput } from '@eclass/api'
import { apiURL } from '../../../../Config/getDomainConfig'
import { axiosError } from '../../../Services'

const PersonAddresses = (input) => {
  const requestId = 'PersonAddresses'
  return async (dispatch: PersonAddressesInput) => {
    const query = `
    mutation PersonAddresses($PersonAddressesInput: PersonAddressesInput!) {
        PersonAddresses(input: $PersonAddressesInput) {
          address {
            id
            addressTypeId
            countryId
            regionId
            locationId
            address
            number
            block
            apartment
            private
          }
          status {
            success
            name
            detail
            code
          }
        }
      }
      `

    const variables = {
      PersonAddressesInput: input,
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response) => response.data.data.PersonAddresses)
      .catch((err) => axiosError(err, requestId, dispatch))
  }
}

export default PersonAddresses
