import { gql, useMutation, MutationHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const UnitCancellationCEV = gql`
  ${STATUS_FIELDS}
  mutation UnitCancellationCEV($UnitCancellationCEVInput: UnitCancellationCEVInput!) {
    UnitCancellationCEV(input: $UnitCancellationCEVInput) {
      status {
        ...StatusFields
      }
    }
  }
`

export type UnitCancellationCEVPayload = Api.UnitCancellationCEVPayload

export type UnitCancellationCEVInput = Api.UnitCancellationCEVInput

interface UnitCancellationCEVPayloadApollo {
  UnitCancellationCEV: UnitCancellationCEVPayload
}

interface UnitCancellationCEVInputApollo {
  UnitCancellationCEVInput: UnitCancellationCEVInput
}

export const useMutationUnitCancellationCEV = (
  options?: MutationHookOptions<UnitCancellationCEVPayloadApollo, UnitCancellationCEVInputApollo>
) => {
  return useMutation<UnitCancellationCEVPayloadApollo, UnitCancellationCEVInputApollo>(
    UnitCancellationCEV,
    options
  )
}
