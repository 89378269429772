/** Función que define el id del botón en dos casos, si es recurso etc, o si es set de ejercicios, esto para llevar méticas de clic en google tag manager */
export const getIdButtonNavigation = (typeButton, position) => {
  const isExercise = typeButton === 'isExercise'
  if (isExercise && position === 'prev') {
    return 'ExerciseBack'
  } else if (isExercise && position === 'next') {
    return 'ExerciseNext'
  }
  if (position === 'prev') {
    return 'ResourceMaterialBack'
  } else if (position === 'next') {
    return 'ResourceMaterialNext'
  } else {
    return ''
  }
}
