import { Box, useMediaQuery } from '@chakra-ui/react'

export const Wrapper = ({ children }) => {
  const [breakerContainer] = useMediaQuery('(min-width: 840px)')

  return (
    <Box
      position="relative"
      className={breakerContainer ? 'breakerContainer' : ''}
      marginLeft="-15px"
      marginRight="-15px"
      h="100%"
    >
      {children}
    </Box>
  )
}
