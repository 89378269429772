import * as React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'

import { Icon } from 'app/Components/UI'
import { CommentSystem } from 'app/Components'
import { InputText } from 'app/Components/UI/Input'
import { TextEditor } from 'app/Components/UI/Forms/TextEditor'
import * as Types from './types'
import * as Style from './style'

export const FormQuestion = ({
  title,
  setTitle,
  value,
  setValue,
  visibility,
  setVisibility,
  canPublic,
  type,
}: Types.FormQuestion) => {
  const { t } = useTranslation()

  const [hasContent, setHasContent] = React.useState(0)

  const isVisible = type !== 'test' || !!canPublic

  const visibilityText = (visibilityOptions, typeModal) => {
    const visibilityTextOptions = {
      privateEvaluation: t('QuestionPrivateEvaluation'),
      private: t('QuestionPrivateOnly'),
      public: t('QuestionPublicAll'),
    }

    if (typeModal === 'test') {
      return visibilityTextOptions.privateEvaluation
    }

    if (typeModal === 'work') {
      return `<b>${t(
        'Esta pregunta es privada dado que pertenece a una evaluación, solo el cuerpo académico de eClass y tú la pueden ver y responder.'
      )}</b>`
    }

    return visibilityTextOptions[visibilityOptions]
  }

  return (
    <Style.FormWrapper>
      <Style.InputText>
        <InputText
          type="novalidate"
          label={`${t('QuestionWhatAbout')}`}
          placeholder={t('QuestionWriteSubject')}
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />
      </Style.InputText>
      <Style.Editor>
        <label>{t('QuestionDescription')}</label>
        <CommentSystem.Editor
          editor={{
            save: () => {},
            cancel: () => {},
            words: hasContent,
            loading: false,
            showCancel: false,
          }}
        >
          <TextEditor
            value={value}
            setValue={setValue}
            readOnly={false}
            setHasContent={setHasContent}
            placeholder={t('QuestionWriteHere')}
            toolbar="helper"
          />
        </CommentSystem.Editor>
      </Style.Editor>
      <Style.FormGroup>
        {/* @ts-ignore */}
        {type !== 'work' ? (
          <>
            {isVisible && <label>{t('QuestionVisibility')}</label>}
            <Style.RadioGroup>
              <Style.Radio isVisible={isVisible}>
                {isVisible && (
                  <input
                    type="radio"
                    id="lock"
                    name="question-visibility"
                    value="private"
                    defaultChecked={visibility === 'private'}
                    onChange={(e) => setVisibility(e.target.value)}
                  ></input>
                )}
                <label htmlFor="lock">
                  <Icon name={'lock'} fill="#b0cfe0" />
                  <span>{t('QuestionPrivate')}</span>
                </label>
              </Style.Radio>

              {isVisible && (
                <Style.Radio isVisible={isVisible}>
                  <input
                    type="radio"
                    id="unlocked"
                    name="question-visibility"
                    value="public"
                    defaultChecked={visibility === 'public'}
                    onChange={(e) => setVisibility(e.target.value)}
                  ></input>
                  <label htmlFor="unlocked">
                    <Icon name={'unlocked'} fill="#b0cfe0" />
                    <span>{t('QuestionPublic')}</span>
                  </label>
                </Style.Radio>
              )}
            </Style.RadioGroup>
          </>
        ) : (
          <>
            <Style.RadioGroup>
              <Style.Radio isVisible={isVisible} className="hideInput">
                <input
                  type="radio"
                  id="lock"
                  name="question-visibility"
                  value="private"
                  defaultChecked={visibility === 'private'}
                ></input>
                <label htmlFor="lock">
                  <Icon name={'lock'} fill="#b0cfe0" />
                  <span>{t('QuestionPrivate')}</span>
                </label>
              </Style.Radio>
            </Style.RadioGroup>
          </>
        )}
        <span className="brownText">{ReactHtmlParser(visibilityText(visibility, type))}</span>
      </Style.FormGroup>
    </Style.FormWrapper>
  )
}
