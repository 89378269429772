import React from 'react'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import es from 'react-phone-input-2/lang/es.json'

import { Types } from './types'
import { Wrapper } from './Styles'

export const PhoneInputCall = ({ value, onChange, country = 'cl', disabled }: Types.Props) => {
  const handleChange = (e: string, data: Types.dataFull) => {
    if (onChange !== undefined) {
      onChange(e, data)
    }
  }

  return (
    <Wrapper>
      <PhoneInput
        country={country}
        disabled={disabled}
        value={value}
        onChange={handleChange}
        inputStyle={{ height: '48px', width: '100%', fontSize: '16px' }}
        dropdownStyle={{ height: '200px' }}
        localization={es}
        preferredCountries={['cl', 'ar', 'bo', 'br', 'co', 'ec', 'mx', 'py', 'pe', 'uy', 've']}
        defaultMask={'. .... .... ..'}
      />
    </Wrapper>
  )
}

export default PhoneInputCall
