import { BaseIcons } from './BaseIcons'
import { IAudio } from '../Podcast'
import { vars } from '@eclass/ui-kit'

export const Forward = ({ audio }: IAudio) => {
  const onClick = () => {
    if (audio) {
      audio.currentTime += 5
    }
  }

  return (
    <BaseIcons onClick={onClick}>
      <svg width={14} height={16} fill="none">
        <path
          fill={vars('colors-main-blueGrey')}
          d="M10.954 9.498v1.342c.168-.024.337-.036.506-.036.82 0 1.448.221 1.885.664.436.443.655 1.048.655 1.813 0 .822-.26 1.482-.782 1.982-.513.491-1.245.737-2.195.737H9v-1.583h1.747c1.027 0 1.54-.387 1.54-1.16a.83.83 0 0 0-.322-.689c-.207-.17-.52-.254-.942-.254H9.322V8h4.218v1.498h-2.586Z"
        />
        <path
          fill={vars('colors-main-blueGrey')}
          d="M2 8.5c0 3 2 5.5 5 5.5v2c-4.1 0-7-3.4-7-7.5S3.4 1 7.5 1c1.7 0 3.2.5 4.5 1.5V0h2v6H8V4h2.7c-1-.6-2-1-3.2-1C4.5 3 2 5.5 2 8.5Z"
        />
      </svg>
    </BaseIcons>
  )
}
