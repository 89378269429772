export const ThumbsUp = (props) => {
  return (
    <g {...props} fillRule="nonzero">
      <path
        d="M3.33331 14C1.496 14 0 15.496 0 17.3333V28C0 29.8373 1.496 31.3333 3.33331 31.3333H7.33331C8.084 31.3333 8.77462 31.08 9.33331 30.6586V14H3.33331Z"
        fill="#0189FF"
      />
      <path
        d="M32.0001 19.0001C32.0001 18.1987 31.6827 17.4521 31.1321 16.9014C31.7547 16.2201 32.0721 15.3067 31.9854 14.3561C31.8294 12.6614 30.3027 11.3334 28.5081 11.3334H20.2721C20.6801 10.0947 21.3334 7.82406 21.3334 6.00006C21.3334 3.10806 18.8761 0.666748 17.3334 0.666748C15.9481 0.666748 14.9587 1.44675 14.9161 1.47875C14.7587 1.60544 14.6667 1.79744 14.6667 2.00006V6.52137L10.8267 14.8401L10.6667 14.9214V29.2121C11.7521 29.7241 13.1254 30.0001 14.0001 30.0001H26.2387C27.6907 30.0001 28.9614 29.0214 29.2601 27.6707C29.4134 26.9761 29.3241 26.2761 29.0187 25.6681C30.0041 25.1721 30.6667 24.1574 30.6667 23.0001C30.6667 22.5281 30.5587 22.0761 30.3534 21.6667C31.3387 21.1707 32.0001 20.1561 32.0001 19.0001Z"
        fill="#0189FF"
      />
    </g>
  )
}
