import React from 'react'
import { useTranslation } from 'react-i18next'

import { Select, Input, Date, CheckBox, useSetCountry } from '../Common'
import { useGender } from './useGender'
import { DocumentTypes } from './DocumentTypes'
import * as Style from './style'
import Types from '../types'

/**
 * Campos con la informacion principal
 */
export const PersonalInformation = ({ profile = false }: Types.formBase) => {
  const { t } = useTranslation()
  const { genderList } = useGender()
  const { countriesList } = useSetCountry('nationId')

  return (
    <Style.Wrapper className={`ProfileForms-PersonalInformation  ${profile ? 'profile' : ''}`}>
      <DocumentTypes />

      {/* NACIONALIDAD */}
      <Select
        name="nationId"
        label={t('ProfileNationality')}
        placeholder={t('ProfileSelect')}
        options={countriesList}
      />

      {/* NOMBRES */}
      <Input
        name="name"
        type="ALPHABETIC"
        label={t('ProfileName')}
        placeholder={t('ProfileEnterNames')}
      />

      {/* PRIMER APELLIDO */}
      <Input
        name="firstSurname"
        type="ALPHABETIC"
        label={t('ProfileFirstSurname')}
        placeholder={t('ProfileEnterSurname')}
      />

      {/* SEGUNDO APELLIDO */}
      <Input
        name="lastSurname"
        type="ALPHABETIC"
        label={t('ProfileSecondSurname')}
        placeholder={t('ProfilEnter2Surname')}
        required={false}
      />

      {/* FECHA DE NACIMIENTO */}
      <Date name="birthday" label={t('ProfileBirth')} />

      {/* GÉNERO */}
      <Select
        name="gender"
        label={t('ProfileGender')}
        placeholder={t('ProfileSelect')}
        options={genderList}
      />

      {/* CORREO ELECTRÓNICO */}
      <Input name="email" type="EMAIL" label="Email" placeholder={t('ProfileAddEmail')} />

      {/* INFORMACIÓN PRIVADA */}
      {profile && <CheckBox name="emailPrivate" text={t('ProfilePrivateInfo')} />}

      {/* RESUMEN */}
      {profile && (
        <Input
          name="professionalResume"
          type="TEXTAREA"
          label={t('ProfileProfessionalSummary')}
          placeholder={t('ProfileWriteSummary')}
          required={false}
        />
      )}
    </Style.Wrapper>
  )
}
