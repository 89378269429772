import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

export const GET_ONBOARDING_LIST = gql`
  ${STATUS_FIELDS}
  query getOnboardingList($OnboardingListInput: OnboardingListInput!) {
    getOnboardingList(input: $OnboardingListInput) {
      status {
        ...StatusFields
      }
      Onboardings {
        id
        name
        description
        code
        isViewed
      }
    }
  }
`

export type OnboardingListPayload = Api.OnboardingListPayload

export type OnboardingListInput = Api.OnboardingListInput

interface OnboardingListPayloadApollo {
  getOnboardingList: OnboardingListPayload
}

interface OnboardingListInputApollo {
  OnboardingListInput: OnboardingListInput
}

export const useQueryGetOnboardingList = (
  options?: QueryHookOptions<OnboardingListPayloadApollo, OnboardingListInputApollo>
) => {
  return useQuery<OnboardingListPayloadApollo, OnboardingListInputApollo>(
    GET_ONBOARDING_LIST,
    options
  )
}
