import { gql } from '@apollo/client'

import * as Type from '../types'

export const ALL_JOB_TYPES = gql`
  query allJobTypes {
    allJobTypes(orderBy: name_ASC) {
      name
      id
    }
  }
`

export interface ALL_JOB_TYPES_TYPE {
  allJobTypes: Type.list[]
}
