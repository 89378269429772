import { useSelector, useDispatch } from 'react-redux'

import { Types } from './types'

export const useRedux = () => {
  const dispatch = useDispatch()
  const {
    loggedUser,
    pdf: { modal, status },
    pdf: storePDF,
    courses: {
      getCourseInfo: { Course, Branding },
    },
  } = useSelector((state: Types.store) => state)

  const setModal = (modalStatus: string, id?: number) =>
    dispatch({
      type: 'PDF_MODAL',
      payload: {
        modal: modalStatus,
        id: id || 0,
      },
    })

  const setStatus = (payload: string) =>
    dispatch({
      type: 'PDF_STATUS',
      payload,
    })

  return {
    loggedUser,
    modal,
    setModal,
    status,
    setStatus,
    Course,
    Branding,
    storePDF,
  }
}
