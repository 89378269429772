import { gql } from '@apollo/client'

export const STATUS_FIELDS = gql`
  fragment StatusFields on RequestStatusTypeObject {
    success
    code
    name
    detail
    refCode
  }
`
