// External dependencies
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

// Local dependencies
import VisibilitySensor from 'app/Components/VisibilitySensor'
import { QuestionAlert } from '../../QuestionAlert/QuestionAlert'
import { ContentParser } from 'app/Components'
import { SideInfo } from '../../SideInfo'
import { Feedback } from '../../Feedback'
import { Wrapper } from './Style'
import { Question } from '../types'
import { FileZone } from './FileZone'
import { Skip, classOmitted } from '../../Skip'
import { LabelOmitted } from '../../LabelOmitted'
import { TinyAlert } from '@eclass/ui-kit'
import { Instruction } from '../Instruction'

export const File = ({
  id,
  answer,
  position,
  action,
  evaluationState,
  optionDisabled,
  showFeedback,
  setActiveQuestion,
  hasLevelsStages,
  missingAnswers,
  feedback,
}: Question) => {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const isMobile = useSelector((state: any) => state.ui.isMobile)
  const isResource = pathname.includes('resource/view')

  return (
    <VisibilitySensor setter={setActiveQuestion} id={`question-${answer.config?.id!}`}>
      <Wrapper
        className={`Question ${isMobile ? 'Mobile' : ''}`}
        id={`question-${answer.config?.id!}`}
      >
        <div className={`Question__Data ${classOmitted(answer.skipped!, evaluationState)}`}>
          <SideInfo
            status={answer.state}
            position={position}
            evaluationState={evaluationState}
            score={answer.feedback?.score}
          />
          <div className="Question__Content">
            {answer.config && !answer.config.mandatory && !isResource && (
              <TinyAlert status="warning" text={t('ResourceOptimalQuestion')} margin="0 0 4px" />
            )}
            <LabelOmitted answer={answer.state!} evaluation={evaluationState} />
            <QuestionAlert show={(hasLevelsStages && missingAnswers)!} answer={answer} />
            <Instruction>
              {ContentParser({
                content: answer.instructions!,
                embeds: answer.embeds,
              })}
            </Instruction>
            <FileZone
              id={id}
              isMobile={isMobile}
              action={action}
              answer={answer}
              optionDisabled={optionDisabled}
            />
            <Skip answer={answer} action={action} evaluationState={evaluationState} />
          </div>
        </div>
        <Feedback
          answer={answer}
          evaluationState={evaluationState}
          showFeedback={showFeedback}
          type={feedback}
        />
      </Wrapper>
    </VisibilitySensor>
  )
}
