import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useQuery, useLazyQuery } from '@apollo/client'

import * as Apollo from 'app/Apollo'
import { getValue, getOption, useSetSelect } from '../Common'
import { Types } from '../types'

export const useHeadingAndPositions = () => {
  const FormContext = useFormContext()
  const { getValues, setValue } = FormContext

  const [headings, setHeadings] = React.useState<Types.select[]>([])
  const { setOption } = useSetSelect(headings, 'headingId')
  const { data: dataHeadings } = useQuery<Apollo.ALL_HEADING_TYPE>(Apollo.ALL_HEADING)

  const [executivePositions, setExecutivePositions] = React.useState<Types.select[]>([])
  const [getExecutivePositions, { data: dataExecutivePositions }] =
    useLazyQuery<Apollo.ALL_EXECUTIVE_POSITIONS_TYPE>(Apollo.ALL_EXECUTIVE_POSITIONS, {
      fetchPolicy: 'no-cache',
    })

  const loadHeadings = React.useCallback(() => {
    const list = dataHeadings?.allHeadings.map((x) => {
      return {
        label: x.name,
        value: x.id,
      }
    })
    if (list) {
      setOption(list)
      setHeadings(list)
    }
  }, [dataHeadings])

  React.useEffect(() => {
    if (dataHeadings && dataHeadings?.allHeadings?.length > 0 && headings.length === 0) {
      loadHeadings()
    }
  }, [dataHeadings, headings])

  // Obtengo listado de opciones de executivePositions
  const loadExecutivePositions = React.useCallback(async () => {
    const headingValue = getValues().headingId
    if (headingValue) {
      getExecutivePositions({
        variables: { headingId: getValue(headingValue) },
      })
    }
  }, [getValues])

  // Si selecciona opción de headings pido los datos de executivePositions
  const headingId = getValues().headingId?.value || null
  React.useEffect(() => {
    if (headingId) {
      loadExecutivePositions()
    }
  }, [headingId])

  // Una vez que viene las opciones de posiciones ejecutivas las guardo en executivePositions
  React.useEffect(() => {
    if (dataExecutivePositions && dataExecutivePositions.allExecutivePositions.length > 0) {
      const list = dataExecutivePositions.allExecutivePositions.map((x) => {
        return {
          label: x.name,
          value: x.id,
        }
      })
      setExecutivePositions(list)
      getOption({ FormContext, options: list, input: 'executivePositionId' })
    }
  }, [dataExecutivePositions, getOption])

  // Evento al selecionar opción de headings
  const onChangeHeading = () => {
    setValue('executivePositionId', null)
    loadExecutivePositions()
  }

  return {
    headings,
    executivePositions,
    onChangeHeading,
  }
}
