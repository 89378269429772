// @External Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

// @Dependencies
import Info from './Info'
import Label from './Label'
import TooltipTrigger from './TooltipTrigger'
import Loader from './Loader'

// @Component
const BaseForm = ({
  children,
  icon,
  info,
  inputSize,
  name,
  label,
  required,
  tooltip,
  alert,
  loading,
  linkLabel,
  disabled,
}) => (
  <StyledBaseForm className="form-group row">
    {label && (
      <Label value={label} name={name} required={required} link={linkLabel} disabled={disabled} />
    )}
    <div className={`col-xl-${inputSize} container-input`}>
      <div className={icon ? 'input-group' : 'input-box'}>
        {icon && (
          <div className="input-group-addon">
            <span className={`nucleo-icon nucleo-icon-${icon}`} />
          </div>
        )}
        {children}
      </div>
      {(loading || tooltip) && (
        <div className={'complementaryMsg'}>
          {tooltip && <TooltipTrigger msg={tooltip} />}
          {loading && <Loader text={null} size="sm" />}
        </div>
      )}
      {alert && <TooltipTrigger isAlert msg={alert} />}
    </div>
    {info && <Info value={info} label={label} inputSize={inputSize} />}
  </StyledBaseForm>
)

// @Proptypes
BaseForm.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  info: PropTypes.node,
  inputSize: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  alert: PropTypes.string,
  linkLabel: PropTypes.node,
  disabled: PropTypes.bool,
}

BaseForm.defaultProps = {
  inputSize: 6,
  size: 'md',
}

// @Export Component
export default BaseForm

const StyledBaseForm = styled.div`
  .alert {
    margin-bottom: 0;
  }
  .complementaryMsg {
    width: 0;
    position: relative;
    display: flex;
    align-items: center;
    > span,
    > div {
      position: relative;
      top: inherit;
      left: inherit;
      right: inherit;
      transform: inherit;
      margin: 0 0 0 7px;
    }
  }
  i.btn-clean {
    right: 40px;
  }
`
