import { useEffect, useRef } from 'react'
import { Box, ChakraProps, useMediaQuery } from '@chakra-ui/react'
import { vars } from '@eclass/ui-kit'

const MARGIN_TOP_BAR = 40
const PADDING_BUTTOM_CONTENT = 24
/** Es el valor de la altura del nombre del hito, menos la altura del punto del hito, dividido dos */
const SPACE_POINT_AND_FINAL_CONTENT = 12

/**
 * ScrollBar vertical que marca el avance de los hitos
 * @example
 * <ScrollBar />
 */
export const ScrollBar = () => {
  const [isBigNav] = useMediaQuery('(min-width: 600px)')

  const bar = useRef<HTMLDivElement>(null)
  const advanceBar = useRef<HTMLDivElement>(null)

  const scrollListener = () => {
    if (advanceBar.current && bar.current) {
      // obtengo altura del nav
      const navArr = document.getElementsByClassName('ResourceNav')
      const navheight = navArr[0].clientHeight

      // separacion del nav con el punto el punto que comienza el sticky del primer hito
      const difference = isBigNav ? 16 : 0

      // posicion en el que se comienza la animacion
      const startAnimation = navheight - difference

      // primer hito
      const barObj = advanceBar.current.getBoundingClientRect()

      // posicion en el que se comienza la animacion
      const topBar = barObj.y - MARGIN_TOP_BAR

      if (topBar < startAnimation && barObj.bottom > 0) {
        // si el top del bar ya paso por el inicion de la animación, y si se encuentra visible la scrollBar

        // obtengo los px que se debe ir moviendo la barra gris
        const advance = -(topBar - startAnimation)
        bar.current.style.height = `${advance}px`
        // advanceBar.current.style.top = `${MARGIN_TOP_BAR + advance}px`
      } else if (topBar > startAnimation && bar.current.style.height !== `${0}px`) {
        // si estoy haciendo la animación al revés (volviendo a arriba) y llego al punto superior,
        // hago un reset al margin de la barra gris
        bar.current.style.height = `${0}px`
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollListener)
    return () => window.removeEventListener('scroll', scrollListener)
  })

  const styleCommon: ChakraProps = {
    maxH: `calc(100% - ${
      MARGIN_TOP_BAR + PADDING_BUTTOM_CONTENT + SPACE_POINT_AND_FINAL_CONTENT
    }px)`,
    left: '5px',
    mb: '2.5rem',
    position: 'absolute',
    sx: {
      '@media screen and (min-width: 1030px)': {
        left: '76px',
      },
    },
    top: `${MARGIN_TOP_BAR}px`,
    w: '2px',
  }

  return (
    <Box>
      <Box
        ref={bar}
        bgColor={vars('colors-main-deepSkyBlue')}
        {...styleCommon}
        zIndex={1}
        title="blue-line"
      />
      <Box
        ref={advanceBar}
        bgColor={vars('colors-neutral-silverSand')}
        {...styleCommon}
        h="100%"
        title="silver-line"
      />
    </Box>
  )
}
