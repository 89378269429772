const Modal = `
  .modal {
    .modal-dialog {
      .modal-content {
        max-width: 95%;
        margin: 20px auto 0 auto;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .modal-header{
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    border-radius: 0px;
    .modal {
      border-radius:0px;
      .modal-dialog {
        .modal-content {
          max-width: 100%;
          margin: 0px auto 0 auto;
          overflow-y: auto;
          border-radius: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
    .modal {
      .modal-dialog {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 425px) {
    .modal {
      .modal-dialog {
        .modal-content {
          border-radius: 0px;
        }
      }
      .modal-dialog.massive-alert {
        .modal-body {
          padding: 15px;
        }
      }
    }
  }
`

export default Modal
