import { Store } from 'app/Redux/types'
import { useSelector } from 'react-redux'
import { Props } from '../types'
import { AmazonVoiceRecognition } from './AmazonVoiceRecognition'
import SpeechToTextRecognition from './SpeechToTextRecognition'

export const Voice = ({ config, id }: Props.Voice) => {
  const {
    courses: {
      getCourseInfo: { Course },
    },
  } = useSelector((state: Store) => state)

  let provider
  // @ts-ignore
  if (Course?.principalProviderVr === 1) {
    // @ts-ignore
    provider = Course?.providerVr
    // @ts-ignore
  } else if (Course?.principalProviderVr === 0) {
    provider = config.provider
  }

  return (
    <>
      {provider === 'google' ? (
        <SpeechToTextRecognition config={config} id={id} />
      ) : (
        <AmazonVoiceRecognition config={config} id={id} />
      )}
    </>
  )
}
