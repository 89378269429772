import React from 'react'

export const Exercise = () => (
  <svg
    width="45"
    data-testid="ExerciseIcon"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 13.358C7.55228 13.358 8 13.8057 8 14.358V37.9106C8 38.5123 8.48774 39 9.08941 39H26.7599C27.3122 39 27.7599 39.4477 27.7599 40C27.7599 40.5523 27.3122 41 26.7599 41H9.08941C7.38317 41 6 39.6168 6 37.9106V14.358C6 13.8057 6.44772 13.358 7 13.358Z"
      fill="#08044F"
    />
    <path
      d="M29 20H24C23.4477 20 23 20.4477 23 21V23C23 23.5523 23.4477 24 24 24H29C29.5523 24 30 23.5523 30 23V21C30 20.4477 29.5523 20 29 20Z"
      fill="#1EBDAF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 22C16 20.3431 17.3431 19 19 19C20.6569 19 22 20.3431 22 22C22 23.6569 20.6569 25 19 25C17.3431 25 16 23.6569 16 22ZM19 21C18.4477 21 18 21.4477 18 22C18 22.5523 18.4477 23 19 23C19.5523 23 20 22.5523 20 22C20 21.4477 19.5523 21 19 21Z"
      fill="#08044F"
    />
    <path
      d="M29 27H17C16.4477 27 16 27.4477 16 28V30C16 30.5523 16.4477 31 17 31H29C29.5523 31 30 30.5523 30 30V28C30 27.4477 29.5523 27 29 27Z"
      fill="#FF554D"
    />
    <path
      d="M34 7H14C13.4477 7 13 7.44772 13 8V12C13 12.5523 13.4477 13 14 13H34C34.5523 13 35 12.5523 35 12V8C35 7.44772 34.5523 7 34 7Z"
      fill="#FFBC27"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9967 8.08941C13.9967 7.48774 14.4845 7 15.0861 7H32.8943C33.496 7 33.9837 7.48775 33.9837 8.08941V15H13.9967V8.08941ZM13.9967 17V31.9106C13.9967 32.5123 14.4845 33 15.0861 33H26.9102C27.4625 33 27.9102 33.4477 27.9102 34C27.9102 34.5523 27.4625 35 26.9102 35H15.0861C13.3799 35 11.9967 33.6168 11.9967 31.9106V8.08941C11.9967 6.38317 13.3799 5 15.0861 5H32.8943C34.6006 5 35.9837 6.38317 35.9837 8.08941V20C35.9837 20.5523 35.536 21 34.9837 21C34.4315 21 33.9837 20.5523 33.9837 20V17H13.9967Z"
      fill="#08044F"
    />
    <path
      d="M32 25C32 23.8954 32.8954 23 34 23H36C37.1046 23 38 23.8954 38 25V27H32V25Z"
      fill="#0189FF"
    />
    <path
      d="M32 29H38V39.3043C38 39.6576 37.9064 40.0046 37.7287 40.3101L35.8643 43.5144C35.4787 44.1772 34.5213 44.1772 34.1357 43.5144L32.2713 40.3101C32.0936 40.0046 32 39.6576 32 39.3043V29Z"
      fill="#0189FF"
    />
  </svg>
)
