// @External Dependencies
import axios from 'axios'
import * as Sentry from '@sentry/react'

// @Dependencies
import { getToken } from '../Auth/getToken'

/**
 * Default value para peticiones con error.
 */
const status = {
  success: false,
  name: 'Internal server error.',
  detail: 'Ha ocurrido un error cuando buscábamos tus datos.',
  code: 500,
}

/**
 * Captura un error cuando realizamos una búsqueda con axios
 *
 * @example
 * ```js
 *   return axios
 *     .post(`${apiURL}/graphql`, { query })
 *     .then(response => response)
 *     .catch(err => axiosError(err, requestId, dispatch))
 * ```
 * @param {Object} err - error
 * @param {string} requestId - requestId
 * @param {Function} dispatch
 */
export const axiosError = (err: any, requestId: string, dispatch: any) => {
  if (/401/.test(err.message)) {
    // console.error('Credenciales invalidas...')
  } else if (requestId && axios.isCancel(err)) {
    // tslint:disable:no-console
    console.log(`El request de ${requestId} fue cancelado...`) // eslint-disable-line
  } else if (err.response && err.response.status === 400) {
    const tokenDecoded = getToken()
    if (!tokenDecoded) {
      return err
    }
    const currentTime = new Date().getTime() / 1000

    if (currentTime > tokenDecoded.exp) {
      window.sessionStorage.setItem('session', 'expired')
      window.sessionStorage.setItem('lastURL', window.location.pathname)
      if (window.localStorage) {
        window.localStorage.removeItem('persist:platform')
        window.localStorage.removeItem('platform:apollo:token')
      }
      return dispatch({ type: 'LOGOUT' })
    } else {
      // eslint-disable-next-line no-console
      console.log({
        error: err,
        mensaje: err.response && err.response.data,
        code: err.response && err.response.status,
      })
      return {
        err,
        status,
      }
    }
  } else {
    // eslint-disable-next-line no-console
    console.log({
      error: JSON.stringify(err),
      mensaje: err.response && err.response.data,
      code: err.response && err.response.status,
      status: JSON.stringify(status),
      err,
    })
    if (process.env.NODE_ENV === 'production') {
      Sentry.captureException(err)
    }
    return {
      err,
      status,
    }
  }
}

export default axiosError
