import React from 'react'

export const ProfileIcon = () => (
  <svg
    width="45"
    height="45"
    role="ProfileIcon"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22.5" cy="22.5" r="22.5" fill="#E0EEFA" />
    <path
      d="M-1.74846e-07 29L-4.37114e-08 32C-1.95703e-08 32.5523 0.447715 33 1 33L14 33C14.5523 33 15 32.5523 15 32L15 29C15 28.4477 14.5523 28 14 28L1 28C0.447715 28 -1.98987e-07 28.4477 -1.74846e-07 29Z"
      fill="#0189FF"
    />
    <path
      d="M5 22L5 25C5 25.5523 5.44772 26 6 26L14 26C14.5523 26 15 25.5523 15 25L15 22C15 21.4477 14.5523 21 14 21L6 21C5.44772 21 5 21.4477 5 22Z"
      fill="#FF554D"
    />
    <path
      d="M-1.74846e-07 15L-4.37114e-08 18C-1.95703e-08 18.5523 0.447715 19 1 19L14 19C14.5523 19 15 18.5523 15 18L15 15C15 14.4477 14.5523 14 14 14L1 14C0.447715 14 -1.98987e-07 14.4477 -1.74846e-07 15Z"
      fill="#FFBC27"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 10C11 8.89543 11.8954 8 13 8H38C39.1046 8 40 8.89543 40 10V36C40 37.1046 39.1046 38 38 38H13C11.8954 38 11 37.1046 11 36V10ZM38 10L13 10V36H38V10Z"
      fill="#08044F"
    />
    <rect x="20" y="13" width="11" height="8" rx="1" fill="#1EBDAF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 18.5C18 14.3579 21.3579 11 25.5 11C29.6421 11 33 14.3579 33 18.5C33 22.6421 29.6421 26 25.5 26C21.3579 26 18 22.6421 18 18.5ZM25.5 13C22.4624 13 20 15.4624 20 18.5C20 21.5376 22.4624 24 25.5 24C28.5376 24 31 21.5376 31 18.5C31 15.4624 28.5376 13 25.5 13Z"
      fill="#08044F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1824 32.4242C19.0622 29.7548 22.078 28 25.5 28C28.922 28 31.9378 29.7548 33.8176 32.4242C34.1356 32.8758 34.0273 33.4996 33.5758 33.8176C33.1242 34.1356 32.5004 34.0273 32.1824 33.5758C30.6413 31.3874 28.2115 30 25.5 30C22.7885 30 20.3587 31.3874 18.8176 33.5758C18.4997 34.0273 17.8758 34.1356 17.4243 33.8176C16.9727 33.4996 16.8644 32.8758 17.1824 32.4242Z"
      fill="#08044F"
    />
  </svg>
)
