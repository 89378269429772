import { useEffect } from 'react'
import $ from 'jquery'

import './style/index.scss'
import { ResourceConfig } from '@eclass/api'

// @ts-ignore
window.jQuery = $

type ComponentProps = {
  id?: string
  children: JSX.Element
  className?: string
  config?: ResourceConfig
}

/**
 *
 * Posee los estilos y componentes funcionales del articulo ( video, carousel, imageZoom )
 *
 * @param {any} children
 * @param {string} className
 *
 * @example
 *   <ContentArticle>{children}</ContentArticle>
 */
export const ContentArticle = ({
  children,
  className = '',
  id = 'content-article',
  config,
}: ComponentProps) => {
  useEffect(() => {
    /** Si en la configuración está denegada la descarga se elimina la opción en los recursos audio y video */
    const denegateAccess = config?.download

    const elementVideotHtml = document.getElementsByTagName(
      'video'
    ) as HTMLCollectionOf<HTMLVideoElement>

    const elementAudioHtml = document.getElementsByTagName(
      'audio'
    ) as HTMLCollectionOf<HTMLAudioElement>

    if (elementVideotHtml && !denegateAccess) {
      for (let i = 0; i < elementVideotHtml.length; i++) {
        elementVideotHtml[i].setAttribute('controlsList', 'nodownload')
        elementVideotHtml[i].addEventListener('contextmenu', (e) => {
          e.preventDefault()
        })
      }
    }

    if (elementAudioHtml && !denegateAccess) {
      for (let i = 0; i < elementAudioHtml.length; i++) {
        elementAudioHtml[i].setAttribute('controlsList', 'nodownload')
        elementAudioHtml[i].addEventListener('contextmenu', (e) => {
          e.preventDefault()
        })
      }
    }
    /**
     * Scripts jQuery
     */
    $(document).ready(() => {
      const documentType: any = document || null
      const ArticleVideo = documentType.querySelector('.ResourceHeader__video__container video')

      if (ArticleVideo) {
        ArticleVideo.pause()
        ArticleVideo.load()
      }

      if ($(document).scrollTop() !== null) {
        if ($('.footnote a, .Article__references li a:not(.ext)').length) {
          $('.footnote a, .Article__references li a:not(.ext)').each(function () {
            $(this).click(function (e) {
              e.preventDefault()
              const strAncla: any = $(this).attr('href')
              if ($(strAncla).length) {
                $('body, html').animate(
                  {
                    scrollTop: $(strAncla).offset()!.top - 180,
                  },
                  {
                    duration: 900,
                  }
                )
              }
            })
          })
        }
      }

      const tooltipClick: any = $('[data-toggle="tooltip"]') || null
      tooltipClick.tooltip({
        trigger: 'click',
      })
      $(documentType).on('click', '.tooltip', function () {
        // @ts-ignore
        $(this).tooltip('hide')
      })
    })
    return () => {
      const conte: any = $('[data-toggle="tooltip"]') || null
      conte.tooltip('hide')
    }
  }, [config])

  return (
    <div className={`ContentArticle ${className}`} id="content-article">
      {children}
    </div>
  )
}
