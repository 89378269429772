import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { useTranslation } from 'react-i18next'

import SVGAnimation from './Animation'

type Props = {
  /**
   * Si hay un error entonces hace el render de esa vista (404, 500, etc)
   */
  error?: JSX.Element

  /**
   * Mensaje personalizado del Cargando...
   */
  message?: string

  /**
   * Define si se muestra en la pantalla completa o en una sección
   */
  fullScreen?: boolean
}

/**
 * Componente Loading
 * @example
 * <LoadingSVG
 *    error={error}
 *    title="Custom Title Loading SVG"
 *    message="Custom Message"
 * />
 */
const LoadingSVG = ({ error, message, fullScreen = true }: Props) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    return () => {
      setIsLoading(false)
    }
  }, [t])

  if (error) {
    return error
  }

  return (
    <Wrapper
      data-testid="LoadingSvg"
      className={`LoadingSVG flex-center ${fullScreen ? 'FullScreen' : ''}`}
    >
      {isLoading && fullScreen ? <IsLoadingOverFlow /> : <IsLoaded />}
      <SVGAnimation />

      <h5>{!message ? t('CrossLoading') : message}</h5>
    </Wrapper>
  )
}

const IsLoadingOverFlow = createGlobalStyle`
  body {
    overflow: hidden;
  }
`
const IsLoaded = createGlobalStyle`
  body {
    overflow: visible;
  }
`

const Wrapper = styled.div`
  flex-direction: column;
  padding: 15px;
  &.FullScreen {
    background: white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    height: 100%;
    left: 0;
  }

  svg {
    width: 197px;
  }

  h5 {
    color: #999;
    font-size: 18px;
    font-weight: 400;
    margin: 15px 0 0;
    text-align: center;
  }
`

export default LoadingSVG
