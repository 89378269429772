// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { axiosError } from '../../../Services'
// import { PersonPhoneNumberInput } from '@eclass/api'

// const PersonPhoneNumber = (input: PersonPhoneNumberInput) => {
const PersonPhoneNumber = (input) => {
  const requestId = 'PersonPhoneNumber'
  return async (dispatch: any) => {
    const query = `
      mutation PersonPhoneNumber($PersonPhoneNumberInput: PersonPhoneNumberInput!) {
        PersonPhoneNumber(input: $PersonPhoneNumberInput) {
          phone {
            id
            areaCode
            countryCode
            number
            private
            isWhatsapp
            canContactWhatsapp
            phoneTypeId
          }
          status {
            success
            name
            detail
            code
          }
        }
      }
    `

    const variables = {
      PersonPhoneNumberInput: input,
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response) => response.data.data.PersonPhoneNumber)
      .catch((err) => axiosError(err, requestId, dispatch))
  }
}

export default PersonPhoneNumber
