import { Box } from '@chakra-ui/layout'
import { FC } from 'react'

interface IInstruction {
  mb?: string
}

export const Instruction: FC<IInstruction> = ({ children, mb = '32px' }) => {
  return (
    <Box
      className="instruction-question"
      mb={mb}
      sx={{
        '.Article__audio audio': {
          display: 'block',
        },

        '& > *': {
          mb: '32px',
          '&:last-child': {
            mb: 0,
          },
        },
      }}
    >
      {children}
    </Box>
  )
}
