import React from 'react'
import * as io from 'socket.io-client'

import * as Type from '../types.d'
import { Props } from '../../types'
import { validate } from '../Services'
import { useCreateContentModuleVR } from '../useCreateContentModuleVR'

const sampleRate = 16000
const apiUrl = 'https://vrgoogle.eclass.com/'

const getMediaStream = () =>
  navigator.mediaDevices.getUserMedia({
    audio: {
      deviceId: 'default',
      sampleRate: sampleRate,
      sampleSize: 16,
      channelCount: 1,
    },
    video: false,
  })

export const useRecorderSpeechToText = ({ config, id }: Props.Voice) => {
  const [state, setState] = React.useState<Type.state>('INIT')
  const [transcript, setTranscript] = React.useState<string>()
  const initError = {
    show: false,
    isSupported: true,
  }
  const [alertError, setAlertError] = React.useState<Type.error>(initError)

  const browserSupport = !!navigator.mediaDevices.getUserMedia
  const [connection, setConnection] = React.useState<io.Socket>()
  const processorRef = React.useRef<any>()
  const audioContextRef = React.useRef<any>()
  const audioInputRef = React.useRef<any>()
  const [speach, setSpeach] = React.useState<WordRecognized>({ final: false, text: '' })

  const { createContentModuleVR } = useCreateContentModuleVR()
  interface WordRecognized {
    final: boolean
    text: string
  }

  /**
   * Evento al dar el click.
   * primero valido soporte de la API para grabar,
   * y luego el evento click que actua dependiendo del tipo de state que tenga
   */
  const onClick = () => {
    if (browserSupport) {
      if (state === 'INIT') {
        connect()
      } else if (state === 'RECORDING') {
        speechRecognized(speach)
        setState('LOADING')
      }
    } else {
      setState('NOT_SUPPORTED')
      setAlertError({
        show: true,
        isSupported: false,
      })
    }
  }

  /** Rehacer setea estado inicial */
  const redo = () => {
    setState('INIT')
    setAlertError(initError)
  }

  const closedError = () => {
    setAlertError(initError)
  }

  const speechRecognized = (data: WordRecognized, socket?: io.Socket) => {
    if (data.final) {
      /** Si detecta que la data ya es lo último que dijo el usuario se desconecta de google y del mic */
      socket?.emit('endGoogleCloudStream')
      socket?.disconnect()

      processorRef.current?.disconnect()
      audioInputRef.current?.disconnect()
      audioContextRef.current?.close()

      // TODO: falta apagar microfono
      setState('LOADING')

      /** Si detecta que no dice nada setea mensaje de no se escucha */
      if (data.text === '') {
        return setState('NOT_UNDERSTAND')
      }

      /** Valida que la respuesta esté dentro de las configuradas y setea el estado correcto  si no error */
      const isCorrect = validate(config.options, data.text)

      createContentModuleVR({
        contentModuleId: id,
        provider: 'google',
        textOptions: config.options,
        textFeedback: data.text,
        isCorrect,
      })

      if (isCorrect) {
        setConnection(undefined)
        return setState('SUCCESS')
      } else {
        setConnection(undefined)
        return setState('ERROR')
      }
    }
  }

  const connect = () => {
    connection?.disconnect()
    const socket = io.connect(apiUrl)
    /** se conecta al socket */
    socket.on('connect', () => {
      setConnection(socket)
    })
    setState('RECORDING')

    socket.emit('startGoogleCloudStream')

    socket.on('receive_message', () => {})

    /** recibe el audio_text y lo setea a un estado */
    socket.on('receive_audio_text', (data) => {
      setTranscript(data.text)
      speechRecognized(data, socket)
      setSpeach(data)
    })

    /** desconecta del socket */
    socket.on('disconnect', () => {})
  }

  React.useEffect(() => {
    ;(async () => {
      if (connection) {
        const stream = await getMediaStream()

        audioContextRef.current = new window.AudioContext()

        await audioContextRef.current.audioWorklet.addModule(
          '/worklets/recorderWorkletProcessor.js'
        )

        audioContextRef.current.resume()

        audioInputRef.current = audioContextRef.current.createMediaStreamSource(stream)

        processorRef.current = new AudioWorkletNode(audioContextRef.current, 'recorder.worklet')

        processorRef.current.connect(audioContextRef.current.destination)
        audioContextRef.current.resume()

        audioInputRef.current.connect(processorRef.current)

        processorRef.current.port.onmessage = (event: any) => {
          if (state === 'RECORDING') {
            const audioData = event.data
            connection.emit('send_audio_data', { audio: audioData })
          }
        }
        setState('RECORDING')
      } else {
        processorRef.current?.disconnect()
        audioInputRef.current?.disconnect()
      }
    })()
  }, [connection])

  return {
    browserSupport,
    state,
    onClick,
    redo,
    alertError,
    closedError,
    transcript,
  }
}
