import { vars } from '@eclass/ui-kit'

import { BaseIcons } from './BaseIcons'
import { IAudio } from '../Podcast'

export const Back = ({ audio }: IAudio) => {
  const onClick = () => {
    if (audio) {
      audio.currentTime -= 5
    }
  }

  return (
    <BaseIcons onClick={onClick}>
      <svg width={14} height={16} fill="none">
        <path
          fill={vars('colors-main-blueGrey')}
          d="M1.954 9.498v1.342c.168-.024.337-.036.506-.036.82 0 1.448.221 1.885.664.436.443.655 1.048.655 1.813 0 .822-.26 1.482-.782 1.982-.513.491-1.245.737-2.195.737H0v-1.583h1.747c1.027 0 1.54-.387 1.54-1.16a.83.83 0 0 0-.322-.689c-.207-.17-.52-.254-.942-.254H.322V8H4.54v1.498H1.954Z"
        />
        <path
          fill={vars('colors-main-blueGrey')}
          d="M12 8.5c0 3-2 5.5-5 5.5v2c4.1 0 7-3.4 7-7.5S10.6 1 6.5 1C4.8 1 3.3 1.5 2 2.5V0H0v6h6V4H3.3c1-.6 2-1 3.2-1 3 0 5.5 2.5 5.5 5.5Z"
        />
      </svg>
    </BaseIcons>
  )
}
