export const BlockedIsTest = (props) => {
  return (
    <g {...props}>
      <path
        fillRule="nonzero"
        d="M3.5 7h9a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-9a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1z"
      />
      <path d="M2.5 4h11v2h-11z" />
      <path d="M5.5 2a1 1 0 0 1 1 1v2h-2V3a1 1 0 0 1 1-1zM10.5 2a1 1 0 0 1 1 1v2h-2V3a1 1 0 0 1 1-1z" />
    </g>
  )
}
