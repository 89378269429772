import React from 'react'

import { Icon } from 'app/Components/UI/Icons'
import * as Style from './Style'
import { useLogic } from './useLogic'

import { Types } from './types'

export const Reorder = (props: any) => {
  const {
    status,
    layoutHorizontal,
    position,
    options,
    items,
    selectItem,
    handleInner,
    showListOfAlternatives,
    enunciatedShow,
  } = useLogic(props)

  return (
    <div className="Reorder">
      <Style.Enunciated status={enunciatedShow ? status.color : null}>
        {enunciatedShow && <Icon name={status.icon} fill={status.color} height={20} width={20} />}
        {position}.
      </Style.Enunciated>
      <Style.Content className={layoutHorizontal}>
        <Style.Slots>
          {options.map((item: Types.slot, index: number) => (
            <Style.Item key={index}>
              <Style.Inner
                className={handleInner.classCss(item)}
                onClick={() => handleInner.onclick(item)}
              >
                <span>{item.text}</span>
                {handleInner.showCross(item) && (
                  <Style.Close>
                    <Icon name="close" fill="#fff" />
                  </Style.Close>
                )}
              </Style.Inner>
            </Style.Item>
          ))}
        </Style.Slots>
        {showListOfAlternatives && (
          <Style.List>
            {items.map((item: Types.slot, index: number) => {
              if (item.draggable === '1') {
                return (
                  <Style.Item key={index}>
                    <Style.Inner className="unselected" onClick={() => selectItem(item, index)}>
                      <span>{item.text}</span>
                    </Style.Inner>
                  </Style.Item>
                )
              }
              return null
            })}
          </Style.List>
        )}
      </Style.Content>
    </div>
  )
}

export default Reorder
