import { RouteParams } from 'Config/routes/types'
import { useSelector } from 'react-redux'
import { useParams, Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { TLinkBreadcrumb } from './Breadcrumb-uikit/Breadcrumb'

export const useBreadcrumb = () => {
  const {
    courses: { getCourseInfo: courseInfo },
    units: { getUnitList },
  } = useSelector((state: any) => state)
  const { unitId, resourceId, courseId, programId, studentId } = useParams<RouteParams>()
  const history = useHistory()
  const { t } = useTranslation()

  const links: TLinkBreadcrumb[] = []

  const withOutUnits = courseInfo?.Course?.navigationId === 2
  const isMicrolearning = courseInfo?.Course?.navigationId === 5

  // si estoy dentro de un recurso
  if (resourceId || unitId) {
    if (isMicrolearning) {
      links.push({
        name: t('DashboardCourses'),
        router: {
          LinkComponent: Link,
          path: '/courses',
        },
      })
    } else {
      const urlCourse = `/units/${programId}/${studentId}/${courseId}`
      // agrego nombre del curso
      links.push({
        name: courseInfo?.Course?.name || t('ResourceCourse'),
        router: {
          LinkComponent: Link,
          path: courseInfo?.Course?.href || urlCourse,
        },
      })

      // Muestro el nombre de la unidad solo si el curso viene con la configuración de vistas con unidad
      if (!withOutUnits) {
        const unitConfig = getUnitList?.Units?.find((element) => element.id === Number(unitId))
        const unitNameDefect = 'Unidad'

        // encontro unidad, osea no es clase sincronicas
        if (unitConfig) {
          const isTransversal = getUnitList?.Units?.find(
            (element) => element.id === Number(resourceId)
          )

          // Se muestra la unidad, solo si no es un transversal
          if (!isTransversal) {
            links.push({
              name: unitConfig.name || unitNameDefect,
              router: {
                LinkComponent: Link,
                path: courseInfo?.Course?.href || urlCourse,
                state: unitConfig.id || unitId,
              },
            })
          }
        } else {
          let name = unitNameDefect
          let state = unitConfig?.id || unitId

          // Caso clase sincronicas, el unitId hace referencia al id de la clase, por lo que se deben recorrer todas las unidades
          if (getUnitList?.Units && getUnitList.Units.length > 0) {
            const units = getUnitList?.Units || []
            units.find((element) =>
              element.lessons?.some((lesson) => {
                if (lesson.id === Number(unitId)) {
                  name = element.name
                  state = element.id
                  return true
                }
                return false
              })
            )
          }

          links.push({
            name,
            router: {
              LinkComponent: Link,
              path: courseInfo?.Course?.href || urlCourse,
              state,
            },
          })
        }
      }
    }
  } else {
    // si estor dentro de un curso
    if (courseId) {
      links.push({
        name: t('DashboardCourses'),
        router: {
          LinkComponent: Link,
          path: '/courses',
        },
      })
    } else {
      // si estoy en otra vista y necesito volver a la vista anterior
      links.push({
        name: t('QuestionGoBack'),
        onClick: () => {
          history.goBack()
        },
      })
    }
  }

  return { links }
}
