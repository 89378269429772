import React from 'react'

export const ExerciseSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="31"
    viewBox="0 0 32 31"
    data-testid="ExerciseResourceIcon"
  >
    <path
      fill="#E0EEFA"
      fillRule="nonzero"
      d="M28 0H4a4 4 0 0 0-4 4v16a4 4 0 0 0 4 4h6.531L16 30.562 21.469 24H28a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4zM18 16H7v-2h11v2zm7-6H7V8h18v2z"
    />
  </svg>
)
