import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

interface IContent {
  title: string
  description: string
}

type keyActive = 'evaluation' | 'answer' | 'question' | 'cev'

export const useContainer = (): IContent => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  let active: keyActive = 'evaluation'

  const container = {
    evaluation: {
      title: t('ContentProvisional'),
      description: t('ContentProvisionalDetail'),
    },
    cev: {
      title: t('CEVProvisional'),
      description: t('CEVProvisionalDetail'),
    },
    question: {
      title: t('QuestionProvisionalQuestion'),
      description: t('QuestionProvisionalQuestionDetail'),
    },
    answer: {
      title: t('QuestionProvisionalAnswer'),
      description: t('QuestionProvisionalAnswerDetail'),
    },
  }

  const includes = (path: string) => pathname.includes(path)

  if (includes('helper/view')) {
    active = 'answer'
  } else if (includes('helper') || includes('resource/view')) {
    active = 'question'
  } else if (includes('resource/calendar')) {
    active = 'cev'
  }

  return container[active]
}
