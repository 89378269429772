import { useTranslation } from 'react-i18next'
import API from '@eclass/api'
import { vars } from '@eclass/ui-kit'
import { Box, HStack, Text, VStack } from '@chakra-ui/react'

import { Avatar } from 'app/Components/UI'
import { Icon } from 'app/Components/UI/Icons'
import { Feedback } from 'app/Views/CEV/Detail/Feedback'

interface props {
  teachers: API.ResourceMeetPayload['Teachers']
  hasFeedback?: boolean
}

/**
 * Componente que renderiza un array de profesores
 * @example <ClassTeacher teachers={teachers} />
 */
export const ClassTeacher = ({ teachers, hasFeedback }: props) => {
  const { t } = useTranslation()

  if (teachers?.length! <= 0) {
    return null
  }

  return (
    <Box mb={hasFeedback ? '0' : '16px'}>
      <Text lineHeight="normal" fontSize="20px" fontWeight="700" mb="24px">
        {t('CEVAboutClass')}
      </Text>
      <Box display="flex" flexDirection="column" gap="16px">
        {teachers?.map((teacher: any, key: number) => (
          <Box key={key} className="teacherInfo">
            <HStack as="header" spacing="1rem" alignItems="start">
              <Avatar
                fullName={teacher?.fullName}
                userId={teacher?.id}
                picture={teacher?.picture}
                size={28}
                fontSize={16}
              />
              <VStack alignItems="flex-start" justifyContent="flex-start">
                <Text
                  m={0}
                  fontSize=".75rem"
                  fontWeight="500"
                  color={vars('colors-neutral-spanishGrey')}
                  textTransform="uppercase"
                >
                  <Icon name={'board'} width={14} fill={vars('colors-main-ziggurat')} />
                  {t(`F2FTeacher`)}
                </Text>
                <Text mt="4px" lineHeight="1.2" fontWeight="700" fontSize="14px">
                  {teacher?.fullName}
                </Text>
              </VStack>
            </HStack>
            {hasFeedback && <Feedback teacher={teacher} />}
          </Box>
        ))}
      </Box>
    </Box>
  )
}
