// @External Dependencies
import axios from 'axios'

// @Dependencies
import { apiURL } from '../../../../Config/getDomainConfig'
import { axiosError, getToken } from '../../../Services'

/**
 * @typedef {Object} FilterType
 * @property {string} [search] - Filtra por nombre de Curso o Diploma
 * @property {number} [year] - Filtra por Año los cursos
 * @property {number} campusId - Campus de los Cursos
 * @property {number} programId - Programa de los Cursos
 * @property {number} [page] - Limita los cursos por página
 * @property {number} [limit] - Cantidad de Cursos totales
 */
/**
 * @typedef {Object} InputType
 * @property {string} [type] - PAST | PRESENT | FUTURE | PRESENT_FUTURE
 * @property {FilterType} filters - Filtros de los cursos
 */

/**
 * Lista los cursos de una persona según los filtros
 *
 * Ocupamos esta query para Dashboard de Cursos y Registro Académico
 *
 * @param {InputType} input
 * @example
 * ```js
 *    const input = {
 *      type: 'PAST'
 *    }
 *    const students = await this.props.getCourseList(input)
 * ```
 * @returns {Promise<import('@eclass/api').CourseListPayload>}
 */
const getCourseList = (input: any) => {
  const requestId = 'getCourseList'
  return async (dispatch: any) => {
    getToken()
    const query = `
      query getCourseList($CourseListInput: CourseListInput!) {
        getCourseList(input: $CourseListInput) {
          status {
            success
            name
            code
            detail
          }
          hasSence
          hasAcademicHistory
          Programs {
            id
            name
            slug
            url
          }
          AcademicList{
            id
            courseId
            studentId
            diplomaId
            productTypeId
            name
            background
            logo
            logoAlt
            hasFinanzeFreezed
            Profile{
              id
              name
            }
            status{
              id
              name
            }
            progress{
              type
              startDate
              endDate
              label
              icon
              progress
              isApproved
              finalGrade
            }
            dateStatus{
              startDate
              endDate
              hasDates
              init
              end
            }
            information{
              icon
              text
              href
            }
            action{
              enabled
              text
              hasIcon
              href
              targetBlank
            }
            labels{
              id
              text
            }
            hasScorm
            hasDjPending
            isMooc
          }
        }
      }
    `

    const variables = {
      CourseListInput: {
        type: input.type || 'PRESENT_FUTURE',
        scheme: 2,
        filter: {
          ...input.filters,
          /**
           * Lógica de Campus y Programa se omite
           * hasta tener definido el Branding y Agrupación de Cursos
           */
          programId: 0,
          limit: 999,
        },
      },
    }

    /**
     * Si viene el Programa entonces filtramos los cursos
     */
    const programId = parseInt(input.programId, 10)
    if (programId) {
      variables.CourseListInput.filter.programId = programId
    }

    return axios
      .post(`${apiURL}/graphql`, { query, variables })
      .then((response) => {
        let payload = null
        if (response.data.data.getCourseList) {
          payload = response.data.data.getCourseList.Programs
        }
        dispatch({ type: 'SET_PROGRAMS', payload })
        return response.data.data.getCourseList
      })
      .catch((err) => axiosError(err, requestId, dispatch))
  }
}

export default getCourseList
