import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { fetchWithTimeout } from 'app/Services'
import { Store } from 'app/Redux/types'
import { getCertificate } from 'app/Redux/Actions'
import { RequestError, ICertificateDownload } from 'app/Components'

export type TStatus = 'INIT' | 'DOWNLOAD' | 'SUCCESS' | 'ERROR_GET' | 'ERROR_DOWNLOAD' | 'GENERATED'

export const useDownload = (config: ICertificateDownload['config']) => {
  const dispatch = useDispatch()
  const { loggedUser } = useSelector((state: Store) => state)
  const { t } = useTranslation()

  const ApiPdf = process.env.REACT_APP_API_PDF_URL!
  const [status, setStatus] = useState<TStatus>('INIT')

  const getMeCertificate = async (fullQuery = true) => {
    const response = await dispatch<any>(
      getCertificate(
        {
          ...config,
          // Valido que sea número, ya que la query getCourseResume isCustomized me llega como boolean
          isCustomized: Number(config.isCustomized),
        },
        fullQuery
      )
    )
    if (!response || !response.status.success) {
      setStatus('ERROR_GET')
      RequestError.handle(dispatch, response)
    }
    if (response && response.status.success) {
      const html = response.html ?? ''
      const pdfConfig = response.pdfConfig ?? {}
      const body = {
        html,
        ...pdfConfig,
      }

      const handleError = (statusError: number = 400) => {
        setStatus('ERROR_DOWNLOAD')
        RequestError.handle(dispatch, {
          status: {
            success: false,
            name: t('AcademicHistoryErrorCertificate'),
            detail: t('AcademicHistoryErrorCertificateDetail', {
              state: statusError,
            }),
            code: statusError,
          },
        })
      }
      if (fullQuery) {
        try {
          const responsePDF = await fetchWithTimeout(ApiPdf, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              Authorization: `bearer ${loggedUser.token}`,
            },
            body: JSON.stringify(body),
          })
          if (responsePDF?.status > 400) {
            handleError(responsePDF.status)
          } else {
            const blob = await responsePDF.blob()
            const newBlob = new Blob([blob], { type: 'application/pdf' })
            // IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob. That function is deprecated and should be remove when IE is not support as a browser.
            // @ts-ignore
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              // @ts-ignore
              window.navigator.msSaveOrOpenBlob(newBlob)
            } else {
              // For other browsers: create a link pointing to the ObjectURL containing the blob.
              const objUrl = window.URL.createObjectURL(newBlob)
              const link = document.createElement('a')
              link.href = objUrl
              link.download = pdfConfig.file_name
              link.click()
              // For Firefox it is necessary to delay revoking the ObjectURL.
              setTimeout(() => {
                window.URL.revokeObjectURL(objUrl)
              }, 250)
            }
            setTimeout(() => {
              setStatus('SUCCESS')
            }, 1000)
          }
        } catch (error) {
          handleError()
        }
      } else {
        setStatus('GENERATED')
        return response.codeCertificate
      }
    }
  }

  const download = () => {
    setStatus('DOWNLOAD')
    getMeCertificate()
  }

  const getCode = (): Promise<string> => {
    return getMeCertificate(false)
  }

  return { status, download, getCode }
}
