import { gql, useMutation, MutationHookOptions } from '@apollo/client'
import * as Api from '@eclass/api'

import { STATUS_FIELDS } from '../fragments'

const RANKING_ACHIEVEMENT_COMPLETE = gql`
  ${STATUS_FIELDS}
  mutation RankingAchievementComplete(
    $RankingAchievementCompleteInput: RankingAchievementCompleteInput!
  ) {
    RankingAchievementComplete(input: $RankingAchievementCompleteInput) {
      status {
        ...StatusFields
      }
    }
  }
`

export type RankingAchievementCompletePayload = Api.RankingAchievementCompletePayload

export type RankingAchievementCompleteInput = Api.RankingAchievementCompleteInput

interface RankingAchievementCompletePayloadApollo {
  RankingAchievementComplete: RankingAchievementCompletePayload
}

interface RankingAchievementCompleteInputApollo {
  RankingAchievementCompleteInput: RankingAchievementCompleteInput
}

export const useMutationRankingAchievementComplete = (
  options?: MutationHookOptions<
    RankingAchievementCompletePayloadApollo,
    RankingAchievementCompleteInputApollo
  >
) => {
  return useMutation<
    RankingAchievementCompletePayloadApollo,
    RankingAchievementCompleteInputApollo
  >(RANKING_ACHIEVEMENT_COMPLETE, options)
}
