import React, { useEffect, useState } from 'react'
import { Box, Text, Center, Divider } from '@chakra-ui/react'
import { BtnLink } from '@eclass/ui-kit'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import { useHistory, useParams } from 'react-router-dom'
import { Modal } from 'app/Components/UI'
import { RouteParams } from 'Config/routes/types'
import { BaseMedal } from 'app/Views/LeaderBoard/Common/Medals/BaseMedal'
import { ModalStyleReset } from './ModalStyle'
import { Types } from './types'
import { useRankingAchievement } from 'app/Views/LeaderBoard/StudentResume/BtnInfoRanking/ModalHowItWorks/useRankingAchievement'
import { SliderCount } from 'app/Views/LeaderBoard/StudentResume/BtnInfoRanking/ModalHowItWorks/SliderArrow'

const ModalMedalAchieved = ({
  isOpen,
  toggle,
  medalData,
  type,
  total,
}: Types.ModalMedalAchievedProps) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { programId, studentId, courseId } = useParams<RouteParams>()
  const { mutationRankingAchievement } = useRankingAchievement()
  const [totalSlides, setTotalSlides] = useState(type !== 'queue' ? medalData?.length : 0)
  const [currentSlide, setCurrentSlide] = React.useState({ active: 1, total: totalSlides })
  const sliderRef = React.useRef<Slider | null>(null)

  const options = {
    dots: false,
    infinite: false,
    draggable: true,
    swipeToSlide: false,
    initialSlide: 0,
    slidesToShow: 1,
    beforeChange: (next, current) => setCurrentSlide({ active: current + 1, total: totalSlides }),
  }

  useEffect(() => {
    if (medalData) {
      setTotalSlides(medalData?.length)
    }
  }, [medalData])

  const multipleMutations = () => {
    if (type === 'pusher' && medalData) {
      mutationRankingAchievement({
        variables: {
          RankingAchievementInput: {
            studentId: Number(studentId),
            courseId: Number(courseId),
            achievementId: medalData?.data?.params.achievementId ?? 0,
          },
        },
      })
    } else {
      medalData?.forEach((element) => {
        mutationRankingAchievement({
          variables: {
            RankingAchievementInput: {
              studentId: Number(studentId),
              courseId: Number(courseId),
              achievementId: element.id,
            },
          },
        })
      })
    }
  }

  const handleCloseModal = (e) => {
    multipleMutations()
    toggle(e)
    if (history.location.pathname.includes('leaderboard')) {
      history.push({ state: 'refetch' })
    }
  }

  const handleViewMedals = (e) => {
    handleCloseModal(e)
    const modalState = history.location.pathname.includes('leaderboard')
      ? { state: 'refetch' }
      : {
          pathname: ['/leaderboard', programId, studentId, courseId].join('/'),
          state: 'modalMedals',
        }
    history.push(modalState)
    if (history.location.pathname.includes('leaderboard')) {
      document.getElementById('viewMedalsModal')?.click()
    }
  }

  return (
    <Modal
      type={'info'}
      isOpen={isOpen}
      backdrop={'static'}
      toggle={toggle}
      fade={true}
      btnClose={false}
      className="ModalMedals"
    >
      <ModalStyleReset />
      {type === 'pusher' && (
        <Center flexDir="column" p="32px" sx={{ '.date': { display: 'none' } }}>
          <Text fontSize="20px !important" mb="20px" textAlign="center" fontWeight={700}>
            {medalData?.subject}
          </Text>
          <BaseMedal
            /** este dato no viene en la notificación de pusher */
            total={medalData?.data?.total}
            medalInfo={{
              name: medalData?.data?.achievementName,
              description: medalData?.content,
              id: 9823932,
              type: medalData?.data?.type,
              isReviewed: false,
              isComplete: true,
            }}
          />
          <Box sx={{ button: { fontSize: '12px' } }}>
            <BtnLink as="button" role="button" onClick={(e) => handleViewMedals(e)}>
              {t('RankingViewMedals')}
            </BtnLink>
          </Box>
        </Center>
      )}
      {type === 'queue' && (
        <>
          <Slider ref={sliderRef} {...options}>
            {medalData?.map((item, key) => (
              <Center
                flexDir="column"
                key={key}
                p={medalData.length > 1 ? '32px 32px 0' : '32px'}
                sx={{ '.date': { display: 'none' } }}
              >
                <Text fontSize="20px !important" mb="20px" textAlign="center" fontWeight={700}>
                  {medalData.length > 1
                    ? t('RankingYouHaveWonNewMedals')
                    : t('RankingYouHaveWonMedal')}
                </Text>
                <BaseMedal
                  total={total}
                  medalInfo={{
                    name: item.name,
                    description: item?.description,
                    id: 9823932,
                    type: item?.type,
                    isReviewed: item?.isReviewed,
                    isComplete: item.isComplete,
                  }}
                />
                <Box textAlign="center" sx={{ button: { fontSize: '12px' } }}>
                  <BtnLink as="button" role="button" onClick={(e) => handleViewMedals(e)}>
                    {t('RankingViewMedals')}
                  </BtnLink>
                </Box>
              </Center>
            ))}
          </Slider>
          {medalData?.length! > 1 && (
            <SliderCount sliderReference={sliderRef} slides={currentSlide} />
          )}
        </>
      )}
      <Divider m="0" mb="1rem" borderColor="#E6E6E6" width="auto" />
      <Text
        onClick={handleCloseModal}
        textAlign="center"
        mb="1rem !important"
        fontSize="16px"
        fontWeight={500}
        cursor="pointer"
        color="#0189FF"
      >
        {t('RankingUnderstand')}
      </Text>
    </Modal>
  )
}

export default ModalMedalAchieved
