import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import { Icon } from 'app/Components/UI/Icons'
import * as Styles from './style'

type props = {
  error?: string
}

type typeRequired = {
  value: boolean
  message: any
}

export const ErrorMessage = ({ error }: props) => {
  const { t } = useTranslation()

  if (!error) {
    return null
  }

  return (
    <Styles.Error>
      <Icon className="circularError" name="circularError" />
      {ReactHtmlParser(t(error))}
    </Styles.Error>
  )
}

export const required: typeRequired = {
  value: true,
  message: <ErrorMessage error={'ProfileMandatory'} />,
}

type TypesError = {
  error?: string | JSX.Element
}

export const TypeError = ({ error }: TypesError) => {
  if (!error) {
    return null
  }

  if (typeof error === 'string') {
    return <ErrorMessage error={error} />
  }

  return error
}
