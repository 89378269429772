/**
 * Valido si se debe ocultar el chatBot de Zendesk dependiendo de la url en la que se esté
 */
export const restrictedView = () => {
  const pathname = window.location.pathname
  const visiblePaths = ['courses', 'courses-past', 'profile', 'units', 'performance']

  // Cubro login
  if (pathname === '/') {
    return false
  }

  return !visiblePaths.some((path) => {
    return pathname.includes(path)
  })
}
