import { vars } from '@eclass/ui-kit'
import styled from 'styled-components/macro'
import UIVars from '../variables'

type PropButtonDrop = {
  type: string
  icon?: string
}

export const ButtonDrop = styled.div<PropButtonDrop>`
  .dropdown-toggle {
    display: flex;
    text-align: center;
    align-items: center;
  }
  .btn-secondary {
    justify-content: center;
    &:hover {
      background-color: ${vars('colors-main-azureRadiance')};
    }
    &:not(:disabled) {
      &:not(.disabled) {
        &:active {
          box-shadow: none;
          background-color: #0062cc;
          border-color: none;
        }
      }
    }
  }

  .btn-secondary {
    ${({ type, icon }: PropButtonDrop) => {
      if (type === 'levels' || type === 'feedbackLevels') {
        return {
          width: '190px', // solo cuando sea levels y feedbackLevels el botón es de ancho fijo porque varian los tamaños y rompen la alineacion con el drop
        }
      }
      if (type === 'helpCenter') {
        return {
          marginRight: '24px',
        }
      } else {
        return {}
      }
    }}
    color: ${vars('colors-neutral-cultured')};
    background-color: ${vars('colors-main-deepSkyBlue')};
    padding: 0 8px 0;
    height: 32px;
    border: 0;
    font-size: 14px;
    font-weight: 500;
    &:after {
      display: none;
    }

    &:focus {
      box-shadow: none;
    }

    .btn-secondary:not(:disabled):not(.disabled).active,
    .btn-secondary:not(:disabled):not(.disabled):active,
    .show > .btn-secondary.dropdown-toggle {
      box-shadow: none;
      background-color: #0062cc;
      border-color: none;
    }
  }

  .Desktop_title {
    display: block;
  }

  .Mobile_title {
    display: none !important;
  }

  @media screen and (max-width: 480px) {
    .btn-secondary {
      padding: 0px 10px;
      width: auto;
    }

    .Mobile_title {
      display: block !important;
    }

    .Desktop_title {
      display: none;
    }
  }
`

export const HeaderDrop = styled.div`
  padding: 15px 20px;
  background-color: ${vars('colors-neutral-davysGrey')};
  cursor: pointer;

  .dropdown-header {
    padding: 0;
    font-size: 15px;
    font-weight: 500;
    color: ${vars('colors-neutral-cultured')};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:focus {
      outline: none;
    }
  }

  .title_container {
    display: flex;
    text-align: center;
    justify-content: space-between;
    gap: 16px;

    .title_back {
      display: flex;
      max-width: 305px;

      &.no_stars {
        max-width: 350px;
      }

      .title {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .stars {
      font-weight: normal;
      display: flex;
      font-size: 13px;
      align-items: center;

      span {
        line-height: 0px;
      }

      svg {
        margin-right: 0px;
        margin-left: 4px;
        margin-top: 2px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding: 15px;

    .title_container {
      justify-content: center;
      .title_back {
        max-width: 340px;
        &.no_stars {
          max-width: 390px;
        }
      }
    }
  }
  @media screen and (max-width: 375px) {
    .title_container {
      .title_back {
        max-width: 290px;
        &.no_stars {
          max-width: 340px;
        }
      }
    }
  }

  @media screen and (max-width: 320px) {
    .title_container {
      .title_back {
        max-width: 240px;
        &.no_stars {
          max-width: 290px;
        }
      }
    }
  }
`

export const AvatarDrop = styled.div`
  .btn-secondary {
    background-color: transparent;
    border: 0;
    padding: 0;
    font-size: 14px;
    font-weight: bold;

    &:after {
      display: none;
    }

    &:focus {
      box-shadow: none;
      border-color: none;
    }

    &:active {
      box-shadow: none;
      border-color: none;
    }

    .btn-secondary:not(:disabled):not(.disabled).active,
    .btn-secondary:not(:disabled):not(.disabled):active,
    .show > .btn-secondary.dropdown-toggle {
      box-shadow: none;
      background-color: #0062cc;
      border-color: none;
    }
  }
`

type PropDropdownBox = {
  type: string
  icon: string
}

export const DropdownBox = styled.div<PropDropdownBox>`
  .dropdown-menu {
    ${({ type }: PropDropdownBox) => {
      if (type === 'avatar') {
        return {
          width: '290px',
          transform: 'translate3d(-240px, 38px, 0px) !important',
          zIndex: 99999,
        }
      }
      if (type === 'helpCenter') {
        const oneItem = document.getElementsByClassName('1_items')
        return {
          width: 'max-content',
          zIndex: 99999,

          transform: oneItem.length
            ? 'translate3d(-334px, 38px, 0px) !important'
            : 'translate3d(-634px, 38px, 0px) !important',

          '@media screen and (max-width: 768px)': {
            width: '375px',
            transform: 'translate3d(-292px, 38px, 0px) !important',
          },

          '@media screen and (min-width: 769px) ': {
            transform: oneItem.length
              ? 'translate3d(-292px, 38px, 0px) !important'
              : 'translate3d(-682px,38px,0px) !important',
          },
        }
      }

      if (['levels', 'navigation', 'feedbackLevels', 'test', 'feedback'].includes(type)) {
        return {
          left: 'inherit !important',
          right: 0,
          top: 'inherit !important',
          margin: 0,
          width: '395px',
          transform: 'translate3d(12px, 10px, 0px) !important',
        }
      } else {
        return {
          width: '395px',
          transform: 'translate3d(-287px, 38px, 0px) !important',
        }
      }
    }}
    padding: 0px;
    border-radius: 10px;
    overflow: hidden;
    border: none;
    box-shadow: -1px 6px 40px 0 rgba(47, 47, 47, 0.2);

    &:focus {
      outline: none;
    }
  }

  @media screen and (max-width: 480px) {
    .dropdown-menu {
      width: 100%;
      background: white;
      border-radius: 0 !important;
      box-shadow: none;
      left: -15px !important;
      position: absolute !important;
      top: 36px !important;
      transform: translate3d(-252px, 36px, 9px) !important;
      width: 100vw;
    }
  }
`
type ItemDropProps = {
  items?: any
  type: string
}

export const Itemdrop = styled.div<ItemDropProps>`
  overflow: auto;
  /* Espacio blanco al final de la lista del dropdown (en controles y recursos) */
  ${({ type, items }: ItemDropProps) => {
    if (['navigation', 'exercise', 'test'].includes(type) && items && items.length > 4) {
      return {
        paddingBottom: '30px',
      }
    } else {
      return {}
    }
  }}

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 9px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    border: 0px solid #ffffff;
    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &::-webkit-scrollbar-track {
    background-color: #eeee;
  }

  .scrollbar-container {
    ${({ type }: ItemDropProps) => {
      if (type === 'navigation' || type === 'exercise') {
        return {
          paddingBottom: '30px',
        }
      } else {
        return {}
      }
    }}
  }

  .dropdown-item {
    ${({ type }: ItemDropProps) => {
      if (type === 'avatar') {
        return {
          padding: '10px 20px',
        }
      }
      if (type === 'navigation' || type === 'exercise') {
        return {
          padding: '0px 20px',
          height: '54px',
          display: 'flex',
          alignItems: 'center',
        }
      } else {
        return {
          padding: '15px 20px',
        }
      }
    }}
    border-bottom: 1px solid ${vars('colors-neutral-platinum')};
    font-size: 14px;
    &:active,
    &:focus,
    &:hover {
      outline: none;
      background-color: ${vars('colors-neutral-cultured2')};
      color: ${vars('colors-neutral-darkCharcoal')};
    }
  }

  @media screen and (max-width: 480px) {
    ${({ items }: ItemDropProps) => {
      if (items && items.length > 4) {
        return {
          height: 'calc(100vh - 105px + 3px)',
        }
      } else {
        return {}
      }
    }}

    .dropdown-item {
      padding: 15px;
      &:last-child {
        border-bottom: 1px solid ${vars('colors-neutral-platinum')};
      }
      &:hover {
        outline: none;
        background-color: ${UIVars.setColors.whiteThree};
        color: ${vars('colors-neutral-darkCharcoal')};
      }
    }

    .ps__rail-y {
      opacity: 0.8;
    }
  }

  @media screen and (min-width: 480px) {
    ${({ items, type }: ItemDropProps) => {
      if (items && items.length > 4 && type !== 'levels' && type !== 'feedbackLevels') {
        return {
          height: '265px',
        }
      } else {
        return {}
      }
    }}
  }
`

type WrapperProps = {
  type: string
  titleMobile?: string
}

export const Wrapper = styled.div<WrapperProps>`
  .Pressed__content {
    position: unset;
  }

  .dropdown {
    .rtr-root {
      z-index: 0;
    }

    &.show {
      .btn-secondary {
        background-color: ${vars('colors-main-deepSkyBlue')};

        &:hover {
          background-color: ${vars('colors-main-deepSkyBlue')};
          @media screen and (min-width: 901px) {
            background-color: ${vars('colors-main-azureRadiance')};
          }
        }
      }

      &:before {
        ${({ type, titleMobile }) => {
          if (type === 'avatar') {
            return {
              marginLeft: '4px',
            }
          }
          if ((type === 'navigation' || type === 'exercise') && titleMobile === 'materials') {
            return {
              marginLeft: '55px',
            }
          }
          if (type === 'helpCenter') {
            return {
              marginLeft: '5px',
            }
          } else {
            return {
              marginLeft: '75px',
            }
          }
        }}
        border-color: transparent transparent ${vars('colors-neutral-davysGrey')}
          transparent;
        border-style: solid;
        border-width: 0 11px 10px 11px;
        bottom: -10px;
        content: '';
        height: 0;
        position: absolute;
        z-index: 99999;
      }
    }
  }

  @media screen and (max-width: 480px) {
    svg {
      transition: all ease-in-out 0.2s;
      ${({ type, titleMobile }) => {
        if (type === 'avatar') {
          return {
            marginRight: '10px',
          }
        }
        if ((type === 'navigation' || type === 'exercise') && titleMobile === 'materials') {
          return {
            marginRight: '0px',
          }
        } else {
          return {}
        }
      }}
    }

    .dropdown {
      &.show {
        position: static !important;
        &:before {
          ${({ type, titleMobile }) => {
            if (type === 'avatar') {
              return {
                right: '4px',
                bottom: '-10px',
              }
            }
            if (type === 'helpCenter') {
              return {
                bottom: '-10px',
              }
            }
            if ((type === 'navigation' || type === 'exercise') && titleMobile === 'materials') {
              return {
                right: '7px',
                bottom: '6px',
              }
            } else {
              return {
                right: '33px',
                bottom: '5px',
              }
            }
          }}
          left: initial;
        }
      }

      &-menu {
        width: 100%;
        background: white;
        border-radius: 0 !important;
        box-shadow: none;
        left: -15px !important;
        position: absolute !important;
        top: 36px !important;
        ${({ type }) => {
          if (type === 'avatar' || type === 'helpCenter') {
            return {
              transform: 'translate3d(-1px, 2px, 0) !important',
            }
          } else {
            return {
              transform: 'translate3d(-1px,19px,0) !important',
            }
          }
        }}
        width: 100vw;
        min-height: 100vh;
      }
    }
  }

  @media screen and (max-width: 990px) and (min-width: 480px) {
    .dropdown {
      &-menu {
        ${({ type }) => {
          if (type === 'avatar') {
            return {
              transform: 'translate3d(-249px,38px,0px) !important',
            }
          } else {
            return {}
          }
        }}
      }
    }
  }

  /* @media screen and (min-width: 480px) and (max-width: 1060px) {
    .dropdown-menu{
      left: -55px!important;
    }
  } */
`

export const Levels = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 265px;
  .levelName {
    padding: 15px 20px 0px 20px;
    font-weight: bold;
    font-size: 15px;
  }
  .stageName {
    display: block;
    padding-left: 20px;
    margin-bottom: 10px;
    text-transform: capitalize;
    font-size: 12px;
    color: ${UIVars.setColors.brownGrey};
  }

  .itembox {
    margin-top: 16px;
    &:last-child {
      padding-bottom: 25px;
    }
  }
  @media screen and (max-width: 481px) {
    max-height: inherit;
    height: 100%;
    .levelName {
      padding: 15px 15px 8px 15px;
    }
    .stageName {
      padding-left: 15px;
    }
  }
`
