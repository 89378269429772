// @External Dependencies
import React from 'react'
import Tooltip from 'reactstrap/lib/Tooltip'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { darken, transparentize } from 'polished'

// @Dependencies
import variables from '../variables'
import Pressed from '../Pressed'
import { DelayLink, LoaderAsync } from '../'

// @Component
const Button = ({
  id,
  border,
  disabled,
  form,
  icon,
  link,
  blank,
  loading,
  onClick,
  opacityPressed,
  size,
  state,
  text,
  tooltip,
  type,
  className,
  tabIndex,
  testid,
  children = {},
}) => {
  const handleClick = (e) => {
    if (disabled && loading) {
      e.preventDefault()
    }
    if (!disabled && !loading && onClick) {
      onClick(e)
    }
  }
  const renderButton = () => (
    <StyledButton
      role="Button"
      data-testid={testid}
      id={id}
      className={className}
      border={border}
      disabled={disabled}
      form={form}
      loading={loading === true ? 'true' : 'false'}
      onClick={handleClick}
      size={size}
      text={text}
      state={state}
      tabIndex={tabIndex}
      type={type}
    >
      <Pressed
        disabled={disabled}
        state={state}
        border={border}
        opacity={opacityPressed}
        color={colorPressed()}
      >
        {loading ? <LoaderAsync /> : null}
        {icon && (
          <StyledIcon icon={icon} text={text} className={`nucleo-icon nucleo-icon-${icon}`} />
        )}
        {(text || (children && children.length)) && (
          <div id={id} className="button__container">
            {text || children}
          </div>
        )}
      </Pressed>
    </StyledButton>
  )

  const colorPressed = () => {
    if (border || state === 'link') {
      return buttonState(state)
    }
    return undefined
  }

  if (link && !tooltip) {
    return blank ? (
      <a target="_blank" rel="noopener noreferrer" href={link}>
        {renderButton()}
      </a>
    ) : (
      <DelayLink to={link}>{renderButton()}</DelayLink>
    )
  } else if (!link && tooltip) {
    return (
      <Tooltip
        placement="right"
        isOpen={this.state.tooltipOpen}
        target="TooltipExample"
        toggle={this.toggle}
      >
        {renderButton()}
      </Tooltip>
    )
  } else if (link && tooltip) {
    return blank ? (
      <a target="_blank" rel="noopener noreferrer" href={link}>
        <Tooltip
          placement="right"
          isOpen={this.state.tooltipOpen}
          target="TooltipExample"
          toggle={this.toggle}
        >
          {renderButton()}
        </Tooltip>
      </a>
    ) : (
      <DelayLink to={link}>
        <Tooltip
          placement="right"
          isOpen={this.state.tooltipOpen}
          target="TooltipExample"
          toggle={this.toggle}
        >
          {renderButton()}
        </Tooltip>
      </DelayLink>
    )
  } else {
    return renderButton()
  }
}

// @Proptypes
Button.propTypes = {
  id: PropTypes.string,
  border: PropTypes.bool,
  disabled: PropTypes.bool,
  form: PropTypes.string,
  icon: PropTypes.string,
  link: PropTypes.string,
  blank: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  opacityPressed: PropTypes.number,
  size: PropTypes.string,
  state: PropTypes.string,
  text: PropTypes.string,
  tooltip: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  testid: PropTypes.string,
}

Button.defaultProps = {
  loading: false,
  state: 'primary',
  tabIndex: 0,
}

// @External Dependencies
export default Button

// @Styles
const StyledButton = styled.button`
  background: ${(props) => buttonBackground(props)};
  border: none;
  border-radius: 4px;
  color: ${(props) => buttonColor(props)};
  margin-right: 10px;
  overflow: hidden;
  padding: 0;
  transition: all 0.25s;
  .Pressed__content {
    position: relative;
  }
  &:hover {
    ${(props) => buttonActionColor('hover', props)}
  }
  &:active,
  &:focus {
    outline: none;
  }
  &[disabled] {
    ${(props) => {
      const { loading, border } = props
      if (loading === 'false' && !border) {
        return {
          background: variables.setColors.veryLightPink,
          borderColor: variables.setColors.veryLightPink,
        }
      } else if (loading === 'true' && border) {
        return css`
          svg path {
            fill: ${buttonState(props.state)};
          }
        `
      }
      return {}
    }}
    cursor: no-drop;
    ${(props) => isDisabled(props)}
    .button__container {
      ${(props) => isDisabled(props)}
    }
    &:hover {
      box-shadow: none;
    }
    &:hover,
    &:active {
      background: ${(props) => {
        if (props.loading === 'false') {
          return !props.border ? variables.setColors.veryLightPink : 'transparent'
        }
        return {}
      }};
      .button__container {
        ${(props) => isDisabled(props)}
      }
    }
  }
  .button__container {
    align-items: center;
    display: flex;
    justify-content: center;
    ${(props) => buttonSize(props.size, props.text)}
    padding: ${({ text }) => (text ? '0 10px' : '0')};
    position: relative;
    text-align: center;
    font-weight: 500;

    ${(props) => buttonBorder(props)};
    border-radius: 4px;
  }
  .LoaderAsync {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .button__container,
  .nucleo-icon {
    color: ${(props) => (props.loading === 'true' ? 'transparent' : buttonColor(props))};
  }
`

const StyledIcon = styled.span`
  line-height: 0;
  margin-right: ${(props) => (props.icon && props.text ? '5px' : '0')};
  vertical-align: middle;
`

const buttonColor = (props) => {
  if (props.border) {
    return buttonState(props.state)
  } else if (['link', 'info'].includes(props.state)) {
    return variables.setColors.primary
  } else {
    return variables.setColors.whiteThree
  }
}

const buttonBackground = (props) => {
  if (props.border || props.state === 'link') {
    return 'transparent'
  } else {
    return buttonState(props.state)
  }
}

const buttonState = (state) => {
  let color

  switch (state) {
    case 'primary':
      color = variables.setColors.primary
      break
    case 'info':
      color = variables.setColors.info
      break
    case 'success':
      color = variables.setColors.buttons.success
      break
    case 'warning':
      color = variables.setColors.warning
      break
    case 'danger':
      color = variables.setColors.danger
      break
    default:
      color = variables.setColors.default
  }
  return color
}

const buttonSize = (size, text) => {
  let btnSize

  switch (size) {
    case 'lg':
      btnSize = { height: '30px', fontSize: '14px', width: 'auto' }
      break
    case 'sm':
      btnSize = { height: '30px', fontSize: '14px', width: 'auto' }
      break
    case 'xs':
      btnSize = { height: '30px', fontSize: '12px', width: 'auto' }
      break
    default:
      btnSize = {
        height: text ? '44px' : 'auto',
        fontSize: '1rem',
        width: text ? '180px' : '100%',
      }
  }
  return btnSize
}

const buttonActionColor = (action, props) => {
  let background
  let borderColor
  let color
  let percent
  const colorState = buttonState(props.state)
  if (props.border || props.state === 'link') {
    borderColor = colorState
    color = colorState
    background = transparentize(0.95, colorState)
  } else {
    color = 'white'
    if (action === 'hover') {
      percent = 0.03
    } else if (action === 'active') {
      percent = 0.08
    }
    background = darken(percent, colorState)
    borderColor = darken(percent, colorState)
  }
  return {
    background,
    borderColor,
    color,
  }
}

const buttonBorder = (props) => {
  if (props.border || props.state === 'link') {
    const color = buttonColor(props)
    return {
      border: `1px solid ${color}`,
    }
  }
}

const isDisabled = (props) => {
  if (props.loading === 'false') {
    if (props.border) {
      return {
        borderColor: variables.setColors.veryLightPink,
        color: variables.setColors.veryLightPink,
      }
    } else if (props.state === 'info') {
      return {
        background: variables.setColors.veryLightPink,
        color: variables.setColors.whiteThree,
      }
    }
    return {
      background: variables.setColors.veryLightPink,
    }
  }
  return {}
}
