// @Dependencies
import { BtnPrimary } from '@eclass/ui-kit'
import { useTranslation } from 'react-i18next'

import { Icon } from 'app/Components/UI/Icons'
import './style.scss'

type Doc = {
  titulo: string
  ruta: string
  extension: string
}

type Props = {
  doc: Doc
  id: number
}

export const DownloadFile = ({ doc, id }: Props) => {
  const { t } = useTranslation()

  return (
    <div className="ComponentDownloadFile DownloadFiles" data-testid="DownloadFile">
      <div id={`${id}`} className="File flex">
        <div>
          <strong>{`${doc.titulo}`}</strong>
          <span>{`(${doc.extension})`}</span>
        </div>

        <BtnPrimary
          ariaLabel={t('ResourceDownloadFiles')}
          onClick={() => window.open(doc.ruta, '_blank')}
          rightIcon={<Icon name="download" width={14} height={16} fill="white" />}
          size="small"
        />
      </div>
    </div>
  )
}
