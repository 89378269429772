// Traduccion de variables
const profile = {
  'Show me online to my other classmates': 'Muestrame conectad{{gender}} a mis compañeros',
}

const spa = {
  ResourceWeInviteYou: '¡Te invitamos a continuar con tu curso!',
  'Previous unit pending': 'Pendiente unidad anterior', // SE BORRARA EVENTUALMENTE
  'Final test': 'Examen final', // SE BORRARA EVENTUALMENTE
  'Take test': 'Rendir examen', // SE BORRARA EVENTUALMENTE
  'Continue test': 'Continuar examen', // SE BORRARA EVENTUALMENTE
  Bad: 'Malo', // SE BORRARA EVENTUALMENTE
  Good: 'Bueno', // SE BORRARA EVENTUALMENTE
  Excellent: 'Excelente', // SE BORRARA EVENTUALMENTE
  'What did you think of this material?': '¿Qué te pareció este material?', // SE BORRARA EVENTUALMENTE
  Sending: 'Enviando', // SE BORRARA EVENTUALMENTE

  'Invalid token': 'Token inválido',

  'Did you finish': 'Finalizaste', // pendiente

  ErrorLookingForMaterial: 'Ha ocurrido un error al buscar el material de estudio.',
}

const LevelsStages = {
  'Not available': 'No disponible',
  'You can answer now': 'Ya puedes responder',
}

const PresencialClass = {
  Attend: 'Asistió',
  Absent: 'No asistió',
}

const spaKeys = {
  ...profile,
  ...spa,
  ...LevelsStages,
  ...PresencialClass,
}

export default spaKeys
